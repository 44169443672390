import React, { useContext } from 'react'
import { Label } from 'semantic-ui-react'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'

const _colors = {
  section: 'black',
  matchCount: 'blue',
  matchZeroCount: 'black',
  matchCompleted: 'blue',
  matchEqual: 'green',
  matchToday: 'blue',
  matchMissing: 'yellow'
}

const _circulars = {
  matchCount: false,
  playoff: false,
  edit: false,
  today: true,
}

const _labelSize = 'tiny'

const MatchCount = (props) => {

  const { matchCounts } = props
  const { allowEdit, matchDate, matchCount, nrCount, completedMatchesCount, endCaption, _isToday, isPlayoffs, showMediaCount, mediaCount } = matchCounts ?? {}

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { handleEditDate } = sportsSeason_handlers ?? {}

  const color = 'black'

  let color_count = matchCount > 0 ? _colors.matchCount : _colors.matchZeroCount
  let color_remaining = completedMatchesCount > 0 ? _colors.matchCompleted : _colors.matchZeroCount

  if (matchCount > 0 && (matchCount === completedMatchesCount)) {
    color_count = _colors.matchEqual
    color_remaining = _colors.matchEqual
  }

  const missingLabel = () => <Label size={_labelSize} color={_colors.matchMissing} circular={_circulars.matchCount} title={'Missing Matches Count'}>{nrCount}</Label>
  const countLabel = (matchCount, color, title, cn) => <Label size={_labelSize} color={color} circular={_circulars.matchCount} title={title ? title : 'Match Count'} className={cn ? cn : null}>{matchCount}</Label>
  const playoffLabel = () => <Label size={_labelSize} color={'green'} circular={_circulars.playoff} title={'Playoff'}>{'P'}</Label>

  const labelsToday = () => {
    return <Label color={color} style={{ padding: '.0em' }} size='small' className='lbls-lbl' >
      <Label size={_labelSize} color={color_count} style={{ marginRight: '1em' }} title={'Match Count'} >
        {matchCount}
      </Label>
      {endCaption}
      <Label size={_labelSize} color={color_remaining} style={{ marginLeft: '1em' }} title={'Completed Count'}>
        {completedMatchesCount}
      </Label>
    </Label>
  }

  const labelsSchedele = () => {
    let cn = 'lbls-lbl'
    if (allowEdit) { cn += ' ae' }
    if (nrCount > 0) {
      return <Label color={color} style={{ padding: '.0em' }} size='small' className={cn} onClick={(e) => { allowEdit && handleEditDate(e, matchDate) }}>
        {isPlayoffs && playoffLabel()}
        {showMediaCount && mediaCount > 0 && countLabel(mediaCount, 'green', 'Media Results Count')}
        {missingLabel()}
        {countLabel(matchCount, _isToday ? _colors.matchToday : _colors.matchZeroCount)}
      </Label>
    } else {
      return countLabel(matchCount, _isToday ? _colors.matchToday : _colors.matchZeroCount, null, 'ae')
    }

  }

  return endCaption ? labelsToday() : labelsSchedele()

}

export default MatchCount