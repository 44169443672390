import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { List, Message, Segment } from 'semantic-ui-react';
import { hashmark, useHash } from '../../../App';
import { AppSettingsContext } from '../../cnr/contexts/AppSettingsContext';
import { AppUserContext } from '../../cnr/contexts/AppUserContext';
import { PageContext } from '../../cnr/contexts/PageContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import { HomeSettingsContext } from '../../cnr/contexts/SettingsDataContext';
import { StartContext } from '../../cnr/contexts/StartContext';
import { createHandlers, createInitialState, createReducer, createTypes } from '../../cnr/reducers/CreateReducer';
import { startTypes } from '../../cnr/reducers/StartReducer';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { _settingsFs } from '../actions/getSettings';

const CreateFeedEvent = () => {

  const { search } = useLocation()

  const eventId = new URLSearchParams(search).get('eventId')
  const clientId = new URLSearchParams(search).get('clientId')
  const mode = new URLSearchParams(search).get('mode')

  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state } = appSettingsContext ?? {}
  const { appConfigSettings } = appSettings_state ?? {}
  const { isMeProd } = appConfigSettings ?? {}

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUser } = appUser_state ?? {}

  // homeSettingsContext
  const homeSettingsContext = useContext(HomeSettingsContext)
  const { homeSettings_state } = homeSettingsContext ?? {}
  const { homeSettings } = homeSettings_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);

  // pageContext
  const pageContext = useContext(PageContext);
  const { homeSettings_global } = pageContext ?? {}
  const { globalAppSettings } = homeSettings_global ?? {}
  const { allowEventCreationFromFeed } = globalAppSettings ?? {}

  // startContext
  const startContext = useContext(StartContext);
  const { start_handlers } = startContext ?? {}
  const { handleAmmend_startDimmer } = start_handlers ?? {}

  const _baseUrl = isMeProd ? 'https://mobile.events.meetingevolution.net/' : 'https://mobiledev.events.meetingevolution.net/'

  // 5BED79621F72A

  const initInfo = {
    caption: 'pending...',
    clientMobileKey: clientId,
    clientId: clientId,
    eventId: eventId,
    updated: false,
    openUrl: useHash ? _baseUrl + hashmark + '/clients/' + clientId + '/events/' + eventId + '/landing' :
      _baseUrl + clientId + '/events/' + eventId + '/landing'
  }

  const componentContexts = { homeSettings, papsContext, pageContext }

  // reducer 
  const create_initState = { componentContexts, initInfo, createType: createTypes.createEvent }
  const [create_state, create_dispatch] = useReducer(createReducer, createInitialState(create_initState));
  const create_handlers = createHandlers(create_dispatch)
  const { info, urlError, fetchConfirmation, confirmation, fetch_success } = create_state

  if (urlError) { info.caption = 'There was an error fetching the data from registration.' + urlError }

  const createTestEvent = () => {
    handleAmmend_startDimmer(startTypes.createEvent)
    if (clientId) {
      getSettingsRegUrl(clientId).then(clientRegUrl => {
        create_handlers.handleCreate_eventFromFetch(mode, clientRegUrl, { clients: clientId })
      })
    } else {
      create_handlers.handleCreate_eventFromFetch(mode)
    }
  }

  useEffect(() => {
    handleAmmend_startDimmer(startTypes.finish)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    if (fetchConfirmation) { handleAmmend_startDimmer(startTypes.finish) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchConfirmation]);

  useEffect(() => {
    if (confirmation) { handleAmmend_startDimmer(startTypes.finish) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  useEffect(() => {
    if (!fetch_success) { handleAmmend_startDimmer(startTypes.finish) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch_success]);

  const handleGo = () => window.location.replace(info.openUrl)
  const handleCancel = () => { }

  const listItem = (caption, content) => {
    return <List.Item>
      <List.Header>{caption}</List.Header>
      <List.Description>{content}</List.Description>
    </List.Item>
  }

  const infoList = () => {
    return <Segment><List divided relaxed>
      {listItem('Client ID', info.clientId)}
      {listItem('Event ID', info.eventId)}
      {listItem('Reg Url', info.regUrl)}
    </List></Segment>
  }

  const message = (conf) => {
    // eslint-disable-next-line 
    const messageItems = Object.keys(conf).map(key => {
      switch (key) {
        case 'caption':
        case 'success':
        case 'updated':
          break;
        case 'content':
          return <Message.Item>{conf[key]}</Message.Item>
        case 'title':
          break;
        default:
          return <Message.Item>{key}{': '}{conf[key]}</Message.Item>
      }
    })

    let icon;
    if (conf) {
      icon = conf && conf.success ? 'check circle' : 'exclamation circle'
    } else {
      icon = 'check'
    }

    return <Segment>
      <Message
        icon={icon}
        positive={conf && conf.success ? true : false}
        negative={conf && !conf.success ? true : false}
        color={conf && conf.success ? 'green' : 'red'}
        header={conf.title}
        list={messageItems}
      />
    </Segment>
  }

  const content = () => {
    if (allowEventCreationFromFeed) {
      if (appUser && appUser.loggedIn) {
        return <React.Fragment>
          {info && infoList()}
          {fetchConfirmation && message(fetchConfirmation)}
          {confirmation && message(confirmation)}
        </React.Fragment>
      } else {
        return <div>{'Please sign in to the App before creating any new events.'}</div>
      }
    } else {
      return <div>{'You do not have permission to access this page.'}</div>
    }
  }

  const footer = () => {
    if (allowEventCreationFromFeed && appUser && appUser.loggedIn) {
      if (confirmation && confirmation.success && info && confirmation && confirmation.success) {
        return <UiSaveButtons cancel={{ oc: handleCancel }} save={{ oc: handleGo, caption: 'Go' }} />
      } else {
        return <UiSaveButtons cancel={{ oc: handleCancel }} save={{ oc: createTestEvent, caption: _.startCase('createEvent') }} />
      }
    } else {
      return <div></div>
    }
  }

  const wrapper = () => {
    return <Segment className={'create-event-container'}>
      <Wrapper
        header={_.startCase(mode) + ' Event'}
        content={content()}
        footer={footer()}
        css={{ header: 'create-event-header' }}
        wrapperType={wrapperTypes.padded}
      />
    </Segment>
  }

  return wrapper()

}

// Returns the regUrl for the client, if any, from settins
const getSettingsRegUrl = async (clientId) => {
  const _getRef = createRefPath([_settingsFs.root, clientId])
  fs_db.get_data({ refPath: _getRef }).then(settings => {
    const { global } = settings ?? {}
    const { appSettings } = global ?? {}
    const { clientRegistrationUrl, useClientRegistrationUrl } = appSettings ?? {}
    return useClientRegistrationUrl && clientRegistrationUrl ? clientRegistrationUrl : null
  })
}

export default CreateFeedEvent