import _ from 'lodash'
import { dataFix } from "../../../common/dataFix"
import { createRefPath, createRefPath_client, createRefPath_event } from "../../appData/appRefPaths"
import { fs_set_doc, fs_update_doc } from "../../appData/fsData"
import { deleteField } from 'firebase/firestore'
import { fs_db } from '../../appData/fsAppData'
import { copyObj } from '../../../common_web/copy'
import { removeFromObjects } from '../../../common/sorting'
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps'

const _allowDocumentUpdates = true
const _documentDoc = '_documents'

export const updateDocumentHelpers = {
  delete_itemInDocuments: (documentCollection, selectedItems, pathViews) => delete_itemInDocuments(documentCollection, selectedItems, pathViews),
  /**
 * gets all the documents in the _documents collection
 * @param {*} pathViews 
 * @param {*} callback 
 */
  get_allDocuments: (pathViews, callback) => get_allDocuments(pathViews, callback),
  update_allDocuments: (pathViews, documentsData) => update_allDocuments(pathViews, documentsData),
  update_dataToDocument: (pathViews, collection_pk, data, dataRestrictions, dispatch) => update_dataToDocument(pathViews, collection_pk, data, dataRestrictions, dispatch),
  update_dataToDocuments: (pathViews, dataCollections, dispatch) => update_dataToDocuments(pathViews, dataCollections, dispatch),
  update_itemInDocuments: (pathViews, documentName, documentKey, itemData, callback) => update_itemInDocuments(pathViews, documentName, documentKey, itemData, callback),
  update_itemsInDocument: (pathViews, documentName, data) => update_itemsInDocument(pathViews, documentName, data),
}

const update_allDocuments = async (pathViews, documentsData) => {
  const xxx = await updateDocumentsPromise(pathViews, documentsData)
  return xxx
}


const get_allDocuments = (pathViews, callback) => {
  const _refPath = createRefPath_event(pathViews, [_documentDoc])
  fs_db.get_data({ refPath: _refPath, callback, opts: { ignoreId: true } })
}

const update_itemInDocuments = (pathViews, documentName, documentKey, dataToUpdate, callback) => {
  const baseRef = createRefPath_event(pathViews)
  const _refPathDoc = createRefPath([_documentDoc, documentName], baseRef)
  const docToUpdate = {
    [documentKey]: dataFix.createDeleteFields(dataToUpdate)
  }
  if (_allowDocumentUpdates) {
    fs_set_doc(_refPathDoc, docToUpdate, true, callback)
  } else {
    console.log('update_itemInDocuments', _refPathDoc, docToUpdate)
  }
}

const update_itemsInDocument = (documentName, data, pathViews) => {
  const baseRef = createRefPath_event(pathViews)
  const _refPathDoc = createRefPath([_documentDoc, documentName], baseRef)
  const docsToUpdate = {}
  _.each(data, (itemData, itemKey) => {
    docsToUpdate[itemKey] = dataFix.createDeleteFields(itemData)
  })
  if (_allowDocumentUpdates) {
    fs_update_doc(_refPathDoc, docsToUpdate)
  } else {
    console.log('updates_itemsInDocument', _refPathDoc, docsToUpdate)
  }
}

const delete_itemInDocuments = (documentCollection, selectedItems, pathViews) => {
  const baseRef = createRefPath_event(pathViews)
  const _refPathDoc = createRefPath([_documentDoc, documentCollection], baseRef)
  const docsToUpdate = {}
  _.each(selectedItems, (selectedItem, itemKey) => {
    docsToUpdate[itemKey] = deleteField()
  })
  if (_allowDocumentUpdates) {
    fs_update_doc(_refPathDoc, docsToUpdate)
  } else {
    console.log('delete_itemInDocuments', _refPathDoc, docsToUpdate)
  }
}

const update_dataToDocuments = async (pathViews, dataCollections, dispatch) => {

  const updateDocuments = () => {
    const promises = []

    Object.keys(dataCollections).forEach(key => {
      promises.push(update_dataToDocument(pathViews, key, dataCollections[key]))
    })

    return Promise.all(promises)
  }

  await updateDocuments()
  dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })

}

const update_dataToDocument = async (pathViews, collection_pk, data, dataRestrictions, dispatch) => {

  const _data = copyObj(data)
  removeFromObjects(_data, ['_itemKey', 'id', 'key', '_new', '_sv', '_dateInfo'])

  const _useDeepDoc = true

  // 
  // const documentData = { ... _data }
  const documentData = _useDeepDoc ? _data : { [collection_pk]: _data }
  const documentName = _useDeepDoc ? collection_pk : '_' + collection_pk

  let _allow = true

  if (dataRestrictions && dataRestrictions.createSingleDocument) {
    _allow = false
  }

  const _refPath = pathViews.events ? createRefPath_event(pathViews, [documentName, collection_pk]) : createRefPath_client(pathViews, [documentName, collection_pk])
  const _refPathDeep = pathViews.events ? createRefPath_event(pathViews, [_documentDoc, documentName]) : createRefPath_client(pathViews, [_documentDoc, documentName])

  if (!_allow) {
    if (_useDeepDoc) {
      console.log('update_dataToDocument', _refPathDeep, documentData)
    } else {
      console.log('update_dataToDocument', _refPath, documentData)
    }
    dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  } else {
    if (_useDeepDoc) {
      fs_set_doc(_refPathDeep, documentData, true, dispatch)
    } else {
      fs_set_doc(_refPath, documentData, true, dispatch)
    }
  }

  return false

}

const updateDocumentsPromise = (pathViews, documentsData) => {
  const promises = []
  _.each(documentsData, (documentData, documentKey) => {
    const _refPathDoc = createRefPath_event(pathViews, [_documentDoc, documentKey])
    const docToUpdate = {}
    _.each(documentData, (data, itemKey) => {
      docToUpdate[itemKey] = dataFix.createDeleteFields(data)
    })
    if (_allowDocumentUpdates) {
      promises.push(fs_update_doc(_refPathDoc, docToUpdate))
    } else {
      console.log('updateDocumentsPromise', _refPathDoc, docToUpdate)
    }
  })
  return Promise.all(promises)
}
