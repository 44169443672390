import _ from 'lodash';
import { _sportLevels } from '../../../projectSpecific/sports/helpers/league';
import { fsfn_fetchTableData } from '../../functions/fbFetchFunctions';
import axios from 'axios'
import { removeEmptyObjectArrays } from '../../common/convert';
import { _mediaHeaders } from '../../../projectSpecific/sports/cnr/reducers/SportsDataReducer';

export const getMediaResults = async (dateGroupKeys, forPlayoffs, links_score, callback) => {
  const callData = { dateGroupKeys, forPlayoffs, links_score }
  const _mediaResults = await (getMediaScores(callData))
  const _mResults = removeEmptyObjectArrays(_mediaResults)
  const _resultsNormal = getMergedMatches(_mResults)
  callback(_resultsNormal.mergedMatches, forPlayoffs)
}

const getMergedMatches = (mediaResults) => {

  const mergedMatches = [].concat(...Object.values(mediaResults));
  const filteredMatches = _.filter(mergedMatches, (match) => match.section !== _mediaHeaders.nonDistrict);
  const groupedMatches = _.groupBy(filteredMatches, (item) => `${item.level}_${item.section}`);

  const teams = []

  Object.keys(groupedMatches).forEach(lsKey => {
    const lsKey_split = lsKey.split('_')
    const levels = lsKey_split[0]
    const sections = lsKey_split[1]
    const matches = groupedMatches[lsKey]
    const homeTeams = _.uniq(_.map(matches, 'home'));
    const awayTeams = _.uniq(_.map(matches, 'away'));
    const allTeams = _.uniq([...homeTeams, ...awayTeams]);
    allTeams.forEach(team => {
      teams.push({
        name: team,
        levels: levels,
        sections: sections,
      })
    })

  })

  const groupedTeams = _.groupBy(teams, (item) => `${item.levels}_${item.sections}`);

  return { mergedMatches, teams, groupedTeams }

}

/**
 * 
 * @param {object} callData 
 * @returns all the results from a media scrape
 */
const getMediaScores = async (callData) => {
  const { dateGroupKeys, forPlayoffs, links_score } = callData
  const promises = []
  if (dateGroupKeys) {
    dateGroupKeys.forEach(async d => {
      promises.push(getMediaData(d, forPlayoffs, links_score))
    })
  }
  return Promise.all(promises)
}

function formatDate(inputDate) {
  // Split the input date into components
  var dateComponents = inputDate.split('/');

  // Extract month, day, and year
  var month = dateComponents[0];
  var day = dateComponents[1];
  var year = dateComponents[2];

  // Create a new date in 'yyyy-mm-dd' format
  var formattedDate = year + '-' + month + '-' + day;

  return formattedDate;
}

const getMediaLocal = async (urlDataF) => {
  const { url } = urlDataF ?? {}
  const response = await axios({
    url: url,
    method: 'get',
    timeout: 8000,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const { data: responseData } = response;
  const result = await responseData;
  return { success: true, result }
}

const getMediaData = async (date, forPlayoffs, links_score) => {

  const _date = formatDate(date)

  let { mediaUrl } = links_score

  // mediaUrl = 'https://tribhssn.triblive.com/scores/TODAYSDATE/5/2/All+Schools/'
  const { mediaUrlDateReplace } = links_score
  const _url = mediaUrl && mediaUrlDateReplace ? mediaUrl.replace(mediaUrlDateReplace, _date) : null

  // console.log('_url', _url)
  // https://hssports.post-gazette.com/sports/25/1-football/schedule/2023-11-06/

  if (_url) {
    const urlDataF = { url: _url }

    return await getMediaLocal(urlDataF).then(response_data => {

      // return await fsfn_fetchTableData(urlDataF).then(response_data => {

      const { result } = response_data ?? {}

      const parser = new DOMParser()
      const doc = parser.parseFromString(result, 'text/html')

      const selectors = {
        doc: doc,
        table: doc.querySelectorAll('table.schedule'),
        trib: doc.querySelectorAll('div.page-content')
      }

      selectors.trs = selectors.table[0] ? selectors.table[0].querySelectorAll('tr') : null

      selectors.trs_playoffLevel = selectors.table[0] ? selectors.table[0].querySelectorAll('td.special-game') : null
      selectors.trs_playoffs = selectors.table[0] ? selectors.table[0].querySelectorAll('tr.game-type-playoffs') : null
      selectors.playoffs = selectors.table[0] ? selectors.table[0].querySelectorAll('td.playoffs') : null

      const _isPlayoff = selectors.playoffs && selectors.playoffs.length > 0 ? true : false

      let sportLevel;

      if (selectors.trs_playoffLevel && selectors.trs_playoffLevel.length > 0) {
        Object.keys(_sportLevels).forEach(sl => {
          if (selectors.trs_playoffLevel[0].innerText.indexOf(sl) >= 0) {
            sportLevel = sl
          }
        })
      }

      const pgData = []

      if (!forPlayoffs && !_isPlayoff) {
        if (selectors.trs) {

          let levels;
          let sections;

          selectors.trs.forEach(tr => {

            const tds_level = tr.querySelectorAll('td.class-name')
            const tds_section = tr.querySelectorAll('td.conference-name')

            if (tds_level && tds_level[0] && tds_level[0].innerText) {
              levels = tds_level[0].innerText.replace('Class', '').trim()
            }

            const _replacementText = '(' + levels + ')'

            if (tds_section && tds_section[0] && tds_section[0].innerText) {
              const _section = tds_section[0].innerText
              switch (_section) {
                case 'Tournaments':
                  levels = _mediaHeaders.tournament
                  sections = _mediaHeaders.tournament
                  break;
                case 'Non Conference':
                  // levels = _mediaHeaders.nonDistrict
                  // sections = _mediaHeaders.nonDistrict
                  break;
                default:
                  sections = _.camelCase(_section.replace(_replacementText, '').trim())
              }
            }

            const tds = tr.querySelectorAll('td')
            if (tds.length === 3) {
              // this is a match row
              const m = getMediaMatch(false, tds, date, levels, sections)
              pgData.push(m)
            }
          })
        }
      } else if (forPlayoffs) {
        if (selectors.trs_playoffs) {
          selectors.trs_playoffs.forEach(tr => {
            const tds = tr.querySelectorAll('td')
            if (tds.length === 3) {
              const m = getMediaMatch(true, tds, date, sportLevel)
              m.isPlayoff = true
              pgData.push(m)
            }
          })
        }
      }
      return pgData
    }).catch(error => {
      console.log('error', error)
      return {}
    })
  } else {
    return {}
  }

  // let urlDataF;

  // switch (sportsModeKey) {
  //   case 'mensFootball':
  //     urlDataF = { url: 'https://hssports.post-gazette.com/sports/25/1-' + sportsModeSport + '/schedule/' + _date + '/' }
  //     break;
  //   case 'mensSoccer':
  //     urlDataF = { url: 'https://hssports.post-gazette.com/sports/25/8-boys-varsity-soccer/schedule/' + _date + '/' }
  //     break;
  //   case 'womensSoccer':
  //     urlDataF = { url: 'https://hssports.post-gazette.com/sports/25/9-girls-varsity-soccer/schedule/' + _date + '/' }
  //     break;
  // } 
}


/**
 * 
 * @param {boolean} forPlayoffs 
 * @param {object} tds 
 * @param {string} date 
 * @param {string} levels 
 * @param {string} sections 
 * @returns 
 * @description there should be 3 tds: time, teams, and result
 */
const getMediaMatch = (forPlayoffs, tds, date, levels, sections) => {

  const teamSplit = forPlayoffs ? ' vs ' : ' at '

  const m = {
    startDate: date,
    teams: tds[1].innerText,
    result: tds[2].innerText,
    level: levels,
  }

  if (sections) { m.section = sections }

  const _teams_s = m.teams.split(teamSplit)
  const _result_s = m.result.split('-')
  const _result_ot_s = m.result.split(' ')

  if (_teams_s.length === 2) {
    m.away = _teams_s[0]
    let _home = _teams_s[1]
    if (forPlayoffs) {
      const homeSplit = _home.split(' at ')
      m.home = homeSplit[0]
      m.location = homeSplit[1]
    } else {
      m.home = _teams_s[1]
    }

    if (_result_s.length === 2) {
      m.awayScore = _result_s[0].trim()
      m.homeScore = _result_s[1].trim()
      if (_result_ot_s.length === 2 && _result_ot_s[1]) {
        m.extra = _result_ot_s[1]
        m.homeScore = m.homeScore.replace(m.extra, '')
        m.homeScore = m.homeScore.trim()
      }
    }
  }

  delete m.teams
  delete m.result

  return m
}