import React from "react";
import AppAccessProvider from "../cnr/contexts/AppAccessContext";
import AppAdminProvider from "../cnr/contexts/AppAdminContext";
import AppComponentProvider from "../cnr/contexts/AppComponentContext";
import AppDataProvider from "../cnr/contexts/AppDataContext";
import AppFormsProvider from "../cnr/contexts/AppFormsContext";
import AppMonitorProvider from "../cnr/contexts/AppMonitor";
import AppNotificationsProvider from "../cnr/contexts/AppNotificationsContext";
import AppUserAuthProvider from "../cnr/contexts/AppUserAuthContext";
import AppUserProvider from "../cnr/contexts/AppUserContext";
import ConversationProvider from "../cnr/contexts/AppUserDataContext";
import BaseSettingsProvider from '../cnr/contexts/BaseSettingsContext';
import ClientInfoProvider from "../cnr/contexts/ClientInfoContext";
import CredentialingProvider from "../cnr/contexts/CredentialingContext";
import EventInfoProvider from "../cnr/contexts/EventInfoContext";
import FirestoreProvider from "../cnr/contexts/FirestoreContext";
import GoogleScriptsProvider from "../cnr/contexts/GoogleScriptsContext";
import GoogleSheetsProvider from "../cnr/contexts/GoogleSheetsContext";
import HeaderProvider from "../cnr/contexts/HeaderContext";
import LocationsProvider from "../cnr/contexts/LocationContext";
import PageProvider from "../cnr/contexts/PageContext";
import ParentProvider from "../cnr/contexts/ParentContext";
import PreviewProvider from "../cnr/contexts/PreviewContext";
import RoutesProvider from "../cnr/contexts/RoutesContext";
import StartDataProvider from "../cnr/contexts/StartDataContext";
import StorageProvider from "../cnr/contexts/StorageContext";
import TransitionProvider from "../cnr/contexts/TransitionContext";
import ValidationProvider from "../cnr/contexts/ValidationContext";
import VideoConferenceProvider from "../cnr/contexts/VideoConferenceContext";
import { googleSheetsTypes } from "../cnr/reducers/GoogleSheetsReducer";
import AppPage from './AppPage';

const transitionType = 'css'

/** Starting point fo the App 
@includes PageProvider  
@includes AppAccessProvider   
@includes AppUserProvider  
@includes StorageProvider   
@includes ClientInfoProvider
@includes StaticViewsProvider   
@includes AppNotificationsProvider  
@includes ConversationProvider  
@includes UpdateProvider  
@includes AppFormsProvider  
@includes AppDataProvider  
@includes AppPage  
*/
const AppView = (props) => {
  return (
    <PreviewProvider>
      <PageProvider {...props} >
        <RoutesProvider>
          <AppUserProvider>
            <BaseSettingsProvider>
              <AppFormsProvider>
                <ValidationProvider>
                  <AppAccessProvider>
                    <ClientInfoProvider>
                      <AppDataProvider>
                        <EventInfoProvider>
                          <StorageProvider>
                            <AppUserAuthProvider>  {/* Auth Only and authOn */}
                              <AppMonitorProvider>
                                <AppNotificationsProvider>
                                  <VideoConferenceProvider>
                                    <StartDataProvider>
                                      <TransitionProvider>
                                        <AppAdminProvider>
                                          <ParentProvider>
                                            <LocationsProvider>
                                              <CredentialingProvider>
                                                <GoogleScriptsProvider>
                                                  <GoogleSheetsProvider googleSheetsType={googleSheetsTypes.app}>
                                                    <ConversationProvider>
                                                      <FirestoreProvider>
                                                        <HeaderProvider>
                                                          <AppComponentProvider>
                                                            {/* <AppDummy /> */}
                                                            <AppPage transitionType={transitionType} />
                                                          </AppComponentProvider>
                                                        </HeaderProvider>
                                                      </FirestoreProvider>
                                                    </ConversationProvider>
                                                  </GoogleSheetsProvider>
                                                </GoogleScriptsProvider>
                                              </CredentialingProvider>
                                            </LocationsProvider>
                                          </ParentProvider>
                                        </AppAdminProvider>
                                      </TransitionProvider>
                                    </StartDataProvider>
                                  </VideoConferenceProvider>
                                </AppNotificationsProvider>
                              </AppMonitorProvider>
                            </AppUserAuthProvider>
                          </StorageProvider>
                        </EventInfoProvider>
                      </AppDataProvider>
                    </ClientInfoProvider>
                  </AppAccessProvider>
                </ValidationProvider>
              </AppFormsProvider>
            </BaseSettingsProvider>
          </AppUserProvider>
        </RoutesProvider>
      </PageProvider>
    </PreviewProvider>
  )
}

export default AppView