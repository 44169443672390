
import _ from 'lodash';
import { grts } from './reducerHelpers/dispatchProps';

const rst = {
  handle_track: 'handle_track',
  ...grts
}

export const trackerReducer = (state, action) => {

  let { type } = action ?? {}

  switch (type) {
    case rst.handle_track:
      const _fsCalls = [...state.fsCalls]
      _fsCalls.push(action.fsCall)
      return { ...state, fsCalls: _fsCalls }

    default:
      return { ...state }
  }
}

export const trackerHandlers = (dispatch) => {
  return {
    handle_track: (fsCall) => { dispatch({ type: rst.handle_track, dispatch, fsCall }) },
  }
}

export const trackerInitialState = (initState) => {
  return { ...initState, fsCalls: [] }
};
