import _ from 'lodash';
import { currentHelpers } from '../../redirection/current';
import { grts, responseHandlers } from './reducerHelpers/dispatchProps';
import { sendMessageToServiceWorker } from './reducerHelpers/serviceWorkerHelpers';
import { fsUpdate_timeStamp } from '../../firestoreData/updates/subUpdates/fsTimeStamps';

const _showConsoleLog = false
const _sw_normal = 'service-worker.js';

export const _cacheNames = {
  cache_settings: 'cache_settings',
  cache_data: 'cache_data',
  cache_main: 'cache_main',
  other: 'other',
  test: 'test',
}

export const _cacheTypes = {
  data: 'data',
  globalData: 'globalData',
  settings: 'settings',
}

export const _serviceWorkerMessageTypes = {
  skipWaiting: 'SKIP_WAITING',
  checkForUpdate: 'CHECK_FOR_UPDATE',
  newVersionAvailable: 'NEW_VERSION_AVAILABLE',
  newVersionNumber: 'NEW_VERSION_NUMBER',
  ammendAppSettings: 'ammendAppSettings',
}

const rts = {
  handleAlert_newServiceWorker: 'handleAlert_newServiceWorker',
  handleAmmend_currentAppVersion: 'handleAmmend_currentAppVersion',
  handleAmmend_serviceWorkerMessage: 'handleAmmend_serviceWorkerMessage',
  handleAmmend_cacheNeedsUpdate: 'handleAmmend_cacheNeedsUpdate',
  handleCheckForUpdate: 'handleCheckForUpdate',
  handleGet_cache: 'handleGet_cache',
  handleInit_serviceWorker: 'handleInit_serviceWorker',
  handleSend_messageToServiceWorker: 'handleSend_messageToServiceWorker',
  handleSet_deferredPrompt: 'handleSet_deferredPrompt',
  handleSet_registration: 'handleSet_registration',
  handleSet_registrationSuccess: 'handleSet_registrationSuccess',
  handleSet_cache: 'handleSet_cache',
  ...grts
}

export const serviceWorkerReducer = (state, action) => {

  const { type, dispatch } = action
  const { registration } = state

  const { handleSet_registration, handleGet_cache, handleSet_cache, handleSend_messageToServiceWorker, handleSet_registrationSuccess, handleAlert_newServiceWorker, handleSet_deferredPrompt, handleAmmend_serviceWorkerMessage } = serviceWorkerHandlers(dispatch)

  switch (type) {

    case rts.handleInit_serviceWorker:
      registerServiceWorker(handleSet_registration, handleSet_registrationSuccess, handleSend_messageToServiceWorker)
      return state

    case rts.handleGet_cache:
      fetchCache(handleSet_cache)
      return state

    case rts.handleSet_cache:
      console.log('action', action)
      return { ...state, cacheApp: action.cacheApp };

    case rts.handleAmmend_currentAppVersion:
      return { ...state, currentAppVersion: action.currentAppVersion }

    case rts.handleAmmend_cacheNeedsUpdate:
      return { ...state, cacheNeedsUpdate: action.cacheNeedsUpdate }

    case rts.handleSet_registration:
      _showConsoleLog && console.log('handleSet_registration', action.reg)
      addListener_updateFound(action.reg, handleAlert_newServiceWorker)
      addListner_beforeInstallPrompt(handleSet_deferredPrompt)
      addListener_navMessage(handleAmmend_serviceWorkerMessage)
      return { ...state, registration: action.reg, registrationSuccess: true };

    // triggered from  
    case rts.handleSet_registrationSuccess:
      _showConsoleLog && console.error('Service Worker registration failed:', action.error);
      return { ...state, registrationSuccess: action.success, registrationError: action.error };

    case rts.handleSet_deferredPrompt:
      _showConsoleLog && console.log('handleSet_deferredPrompt', action.deferredPrompt)
      return { ...state, deferredPrompt: action.deferredPrompt };

    case rts.handleAmmend_serviceWorkerMessage:
      const lastSeen = currentHelpers.storageItem_get('hasSeenUpdatePromptRecently')
      const currentTime = new Date().getTime();
      // let allowPrompt;
      // if (!lastSeen || currentTime - parseInt(lastSeen) > 24 * 60 * 60 * 1000) {
      //   allowPrompt = true
      // }
      const { message } = action
      const { currentVersion, newVersion } = message ?? {}
      _showConsoleLog && console.log('currentVersion, newVersion', currentVersion, newVersion)
      if (lastSeen && newVersion && newVersion !== currentVersion) {
        return { ...state, newVersionAvailable: action.newVersionAvailable, newVersionNumber: newVersion }
      } else {
        return { ...state }
      }

    case rts.handleCheckForUpdate:
      if (registration) {
        registration.update();
      }
      return state;

    case rts.handleAlert_newServiceWorker:
      _showConsoleLog && console.log('handleAlert_newServiceWorker', action)
      return state;

    case rts.handleSend_messageToServiceWorker:
      const { settings, cacheType, pathViews, vit } = action
      const success = sendMessageToServiceWorker(settings)
      if (success) {
        console.log('success', 'sendMessageToServiceWorker')
        // handleGet_cache()
        // if (pathViews && vit) {
        //   switch (cacheType) {
        //     case _cacheTypes.data:
        //       fsUpdate_timeStamp(pathViews, vit)
        //   }
        // }

      }
      return state

    default:
      return state;
  }
}

export const serviceWorkerHandlers = (dispatch) => {

  return {
    handleAlert_newServiceWorker: (response) => { dispatch({ type: rts.handleAlert_newServiceWorker, dispatch, response }) },
    handleAmmend_serviceWorkerMessage: (message) => { dispatch({ type: rts.handleAmmend_serviceWorkerMessage, dispatch, message }) },
    handleAmmend_cacheNeedsUpdate: (cacheNeedsUpdate) => { dispatch({ type: rts.handleAmmend_cacheNeedsUpdate, dispatch, cacheNeedsUpdate }) },
    handleCheckForUpdate: () => { dispatch({ type: rts.handleCheckForUpdate, dispatch }) },
    handleGet_cache: () => { dispatch({ type: rts.handleGet_cache, dispatch }) },
    handleInit_serviceWorker: (pathViews) => { dispatch({ type: rts.handleInit_serviceWorker, dispatch, pathViews }) },
    handleSend_messageToServiceWorker: (settings, cacheType, pathViews, vit) => { dispatch({ type: rts.handleSend_messageToServiceWorker, dispatch, settings, cacheType, pathViews, vit }) },
    handleSet_cache: (cacheApp) => { dispatch({ type: rts.handleSet_cache, dispatch, cacheApp }) },
    handleSet_currentAppVersion: (currentAppVersion) => { dispatch({ type: rts.handleAmmend_currentAppVersion, dispatch, currentAppVersion }) },
    handleSet_deferredPrompt: (deferredPrompt) => { dispatch({ type: rts.handleSet_deferredPrompt, dispatch, deferredPrompt }) },
    handleSet_registration: (reg) => { dispatch({ type: rts.handleSet_registration, dispatch, reg }) },
    handleSet_registrationSuccess: (success, error) => { dispatch({ type: rts.handleSet_registrationSuccess, dispatch, success, error }) },
    ...responseHandlers(dispatch)
  }
}

export const serviceWorkerInitialState = (initState) => {
  return { ...initState }
};

const registerServiceWorker = async (handleSet_registration, handleSet_registrationSuccess, handleSend_messageToServiceWorker) => {

  const sw_normal_url = `${process.env.PUBLIC_URL}/${_sw_normal}`;

  try {
    const reg = await navigator.serviceWorker.register(sw_normal_url);
    console.log('!! Registration Set !!')
    handleSet_registration(reg);
  } catch (error) {
    handleSet_registrationSuccess(false, error);
  }
}

const addListener_updateFound = (registration, callback) => {
  registration.addEventListener('updatefound', () => {
    const newWorker = registration.installing;
    newWorker.addEventListener('statechange', () => {
      if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
        callback('New service worker installed, waiting to be activated')
      }
    });
  });
}

const addListner_beforeInstallPrompt = (callback) => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    callback(e)
  });
}

/**
 * Sets a `message` listener. If a message is sent from the service-worker.js file, it will be picked up here.
 * @param {object} callback 
 */
const addListener_navMessage = (callback) => {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data) {
      switch (event.data.type) {
        case _serviceWorkerMessageTypes.newVersionAvailable:
          callback(event.data)
          break;
        case _serviceWorkerMessageTypes.newVersionNumber:
          console.log('addListener_navMessage', event.data)
          callback(event.data)
          break;
        default:
          callback(true)
          break;
      }
    }
  });
}

/**
 * Gets all the items in Cache storage
 * @param {function} callback 
 */
const fetchCache = async (callback) => {

  try {

    const items = await listCachedItems();

    const _cacheApp = {
      cachedItems: {},
      cacheFetched: false
    }

    const _cachedItems = {};

    _.forEach(items, (item, key) => {
      const cacheMappings = [
        { cacheKey: _cacheNames.cache_data, typeKey: 'cache_data' },
        { cacheKey: _cacheNames.cache_settings, typeKey: null },
        { cacheKey: 'main-cache', typeKey: 'cache_main' }
      ];

      let _typeKey, _key;

      _.some(cacheMappings, ({ cacheKey, typeKey }) => {
        if (key.includes(cacheKey)) {
          _typeKey = typeKey;
          _key = key.replace(`${cacheKey}-`, '');
          return true; // Break loop once matched
        }
        return false;
      });

      if (_typeKey) {
        _.set(_cachedItems, [_typeKey, _key], item);
      } else {
        _cachedItems[_key || key] = item; // If no match, fall back to original key
      }
    });

    _cacheApp.cacheFetched = true
    _cacheApp.cachedItems = _cachedItems

    callback(_cacheApp)

  } catch (error) {
    console.error('Failed to fetch cached items:', error);
  }
};

const listCachedItems = async () => {
  try {
    const cacheNames = await caches.keys(); // Get all cache names 
    const allCachedItems = {}; // Initialize an empty object

    await Promise.all(_.map(cacheNames, async (cacheName) => {
      const cache = await caches.open(cacheName);
      const cachedRequests = await cache.keys(); // Get all requests in the cache

      await Promise.all(_.map(cachedRequests, async (request) => {
        const response = await cache.match(request); // Get the response for each request
        const _response = response ? await convertResponse(response) : 'No Response';

        // Extract key/name from the request URL
        const key = extractKeyFromUrl(request.url);

        if (!allCachedItems[cacheName]) { allCachedItems[cacheName] = {} }
        allCachedItems[cacheName][key] = _response
      }));
    }));

    return allCachedItems;
  } catch (error) {
    console.error('Failed to list cached items:', error);
    return {};
  }
};

const convertResponse = async (response) => {
  try {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      return await response.text();
    }
  } catch (error) {
    console.error('Error converting response:', error);
    return 'Error';
  }
};

const extractKeyFromUrl = (url) => {
  try {
    // Assuming your URL pattern is /somepath/key
    const urlParts = new URL(url).pathname.split('/');
    // Extract the key from the URL
    return urlParts[urlParts.length - 1] || 'default-key';
  } catch (error) {
    console.error('Error extracting key from URL:', error);
    return 'default-key';
  }
};
