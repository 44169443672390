import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { appSettingsHandlers, appSettingsInitialState, appSettingsReducer } from '../reducers/AppSettingsReducer';
import { _showStates } from '../../../App';
import { ServiceWorkerContext, _appVersion } from './ServiceWorkerContext';
import { AuthContext } from './AuthContext';
import { getAppConfigSettings } from '../../../project/appConfiguration';
import { _cacheNames } from '../reducers/ServiceWorkerReducer';

export const AppSettingsContext = createContext();

/**
 * Simple wrapper to handle app settings changes
 */
const AppSettingsProvider = (props) => {

  const { hp } = props ?? {}

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state } = serviceWorkerContext ?? {}
  const { cacheApp } = serviceWorker_state ?? {}
  const { cacheFetched, cachedItems } = cacheApp ?? {}
  const { cache_settings } = cachedItems ?? {}

  // authContext
  const authContext = useContext(AuthContext)
  const { auth_state } = authContext ?? {}
  const { currentAuthUser } = auth_state ?? {}
  const { uid: authUid } = currentAuthUser ?? {}

  const init_state = { hp, authUid, cache_settings }
  const [appSettings_state, appSettings_dispatch] = useReducer(appSettingsReducer, appSettingsInitialState(init_state))
  const appSettings_handlers = appSettingsHandlers(appSettings_dispatch)

  if (_showStates.any && _showStates.appSettings) {
    console.log(Object.keys(appSettings_state))
    console.log(Object.keys(appSettings_handlers))
  }

  const checkAppSetting = () => {
    console.log('checkSetting', appSettings_state)
  }

  const fns = { checkAppSetting }

  return <AppSettingsContext.Provider value={{ appSettings_state, appSettings_handlers, appSettings_fns: fns, appVersion: _appVersion }}>
    {props.children}
  </AppSettingsContext.Provider>
};

export default AppSettingsProvider