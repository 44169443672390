import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Label, List } from 'semantic-ui-react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { TopicContext } from '../cnr/contexts/TopicContext';
import { uniqueKey } from '../common/keys';
import { sortObj } from '../common/sorting';
import { getFcmIcon } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import { notificationMethodTypes } from '../functions/fbNotifications';

export const _genericTopicKey = 'token_test'

const _labelSize = 'large'

const WAIT_INTERVAL = 3000

const FcmList = (props) => {

  const { selectedAppUser } = props
  const { fcmTokens, _itemKey } = selectedAppUser ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state } = states
  const { pathViews } = paps_state ?? {}

  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { topMenu } = aps_global ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  // topicContext
  const topicContext = useContext(TopicContext);
  const { topic_state, topic_handlers } = topicContext ?? {}
  const { dbTopics, confirmation, pending, updating } = topic_state ?? {}

  const [subsribedTopics, setSubsribedTopics] = useState()

  const itemRefs = useRef([]);

  useEffect(() => {
    if (fcmTokens) {
      const _subscribedTopics = {}
      Object.keys(gEnums.adminTopicTypes).forEach(k => {
        const _selectedDbTopic = dbTopics ? dbTopics[k] : {}
        const { fcmTokens: selectedFcmTokens } = _selectedDbTopic ?? {}
        let _userIsSubscribed = true
        if (selectedFcmTokens) {
          Object.keys(fcmTokens).forEach(k => {
            const fcmToken = fcmTokens[k]
            if (!selectedFcmTokens.includes(fcmToken.tokenKey)) {
              _userIsSubscribed = false
            }
          })
        } else {
          _userIsSubscribed = false
        }
        _subscribedTopics[k] = _userIsSubscribed
      })
      setSubsribedTopics(_subscribedTopics)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcmTokens, dbTopics]);

  useEffect(() => {
    if (pending && pending.result) {
      const timer = setTimeout(() => topic_handlers.handlePending_clear(), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending]);

  const handleSubscribe = (e, topic) => {
    e.stopPropagation()
    topic_handlers.handlePending_start({ type: 'subscribing', key: topic })
    topic_handlers.handleSubscribe(topic, fcmTokens, subsribedTopics[topic])
  }

  const handleSendFcmToTopic = (e, _tokenType, tokenKey) => handleSendFcm(e, _tokenType, tokenKey, true)

  const handleSendFcm = (e, _tokenType, tokenKey, toTopic) => {

    e.stopPropagation()

    let title = mainFixedCaption
    title += toTopic ? ' Topic Test' : ' Test'

    const notificationData = { title: title, body: 'This is just a test from ' + mainFixedCaption }
    if (pathViews.clients) { notificationData.clientKey = pathViews.clients }
    if (pathViews.events) { notificationData.eventKey = pathViews.events }

    topic_handlers.handlePending_start({ type: 'sending', key: tokenKey })

    if (tokenKey) {
      // notificationMethodType, notificationData, fcmTokens, topic, fcmToken
      if (toTopic) {
        topic_handlers.handleSend_fcm(notificationMethodTypes.firebaseApi, notificationData, [tokenKey], _genericTopicKey)
      } else {
        topic_handlers.handleSend_fcm(notificationMethodTypes.fetch, notificationData, null, null, tokenKey)
      }
    }
  }

  const handleSubscribeFcm = (e, tokenType, tokenKey) => {
    e.stopPropagation()
    topic_handlers.handlePending_start({ type: 'subscribing', key: tokenKey })
    topic_handlers.handleSubscribeToTopic(tokenKey, _genericTopicKey, false, _itemKey)
  }

  const handleUnSubscribeFcm = (e, tokenType, tokenKey) => {
    e.stopPropagation()
    topic_handlers.handlePending_start({ type: 'subscribing', key: tokenKey })
    topic_handlers.handleSubscribeToTopic(tokenKey, _genericTopicKey, true, _itemKey)
  }

  const handleClearFcm = (e, tokenType, tokenKey) => {
    e.stopPropagation()
    topic_handlers.handlePending_start({ type: 'clearing', key: tokenKey })
    const _fcmTokens = fcmTokens ? { ...fcmTokens } : {}
    delete _fcmTokens[tokenType]
    topic_handlers.handleClear_fcm(selectedAppUser._itemKey, _fcmTokens, tokenType, tokenKey)
  }

  const ammendLblProps = (lblProps, pending) => {
    console.log('pending', pending)
    console.log('confirmation', confirmation)
    lblProps.disabled = true
    if (pending.result) {
      if (pending.result === 'SUCCESS') {
        lblProps.color = 'green'
        lblProps.icon = 'check'
      } else {
        lblProps.color = 'black'
        lblProps.icon = 'ban'
      }
    } else {
      lblProps.color = 'teal'
      lblProps.icon = 'spinner'
      lblProps.loading = true
    }
  }

  const topicListItems = () => Object.keys(gEnums.adminTopicTypes).map(topic => {
    const lblProps = subsribedTopics[topic] ? { color: 'blue', icon: 'check circle' } : { color: 'grey', icon: 'circle outline' }
    if (updating && pending && pending.type === 'subscribing' && pending.key === topic) {
      ammendLblProps(lblProps, pending)
    }
    return <Label key={uniqueKey('fcm', 'tli', topic)} size='tiny' color={lblProps.color} onClick={(e) => !lblProps.disabled && handleSubscribe(e, topic)} > <Icon name={lblProps.icon} loading={lblProps.loading} />{_.startCase(topic)}</Label>
  })

  const lblIcon = (key, caption, icon, color, tokenType, tokenKey, index, type, oc) => {
    const lblProps = { color, icon }

    if (updating && pending && pending.type === type && pending.key === tokenKey) {
      ammendLblProps(lblProps, pending)
    }

    return <Label key={uniqueKey('fcm', 'c', key)}
      size='tiny'
      color={lblProps.color}
      onClick={(e) => oc && !lblProps.disabled && oc(e, tokenType, tokenKey, index)}>
      <Icon name={lblProps.icon} loading={lblProps.loading} />
      {_.startCase(caption)}
    </Label>
  }

  const fcmList = () => {
    if (fcmTokens) {
      const _fcmTokens = sortObj(fcmTokens)
      const lis = Object.keys(_fcmTokens).map((tokenType, index) => {
        const iconName = getFcmIcon(tokenType)
        const fcmToken = fcmTokens[tokenType]
        const { tokenKey } = fcmToken ?? {}
        return <List.Item key={uniqueKey('fcm', 'i', index)} >
          <List.Icon color="blue" size={_labelSize} name={iconName} />
          <List.Content>
            <List.Header><div ref={el => (itemRefs[index] = el)}>{tokenKey}</div></List.Header>
            <List.Description style={{ paddingTop: '1em' }} className='lbl-list'>
              {lblIcon('delete', 'delete', 'ban', 'red', tokenType, tokenKey, index, 'clearing', handleClearFcm)}
              {lblIcon('subscribe', 'subscribeTo' + _.startCase(_genericTopicKey), 'sign-in', 'blue', tokenType, tokenKey, index, 'subscribing', handleSubscribeFcm)}
              {lblIcon('unsubscribe', 'unSubscribeTo' + _.startCase(_genericTopicKey), 'sign-out', 'blue', tokenType, tokenKey, index, 'subscribing', handleUnSubscribeFcm)}
              {lblIcon('send', 'test topic', 'send', 'blue', tokenType, tokenKey, index, 'sending', handleSendFcmToTopic)}
              {lblIcon('send', 'test', 'send', 'blue', tokenType, tokenKey, index, 'sending', handleSendFcm)}
            </List.Description>
          </List.Content>
        </List.Item>
      })

      return <List className={'fcm-container'} divided relaxed>{lis}</List>

    }
  }

  return <div>
    {fcmList()}
    <div>
      {subsribedTopics && topicListItems()}
    </div>
  </div>

}

export default FcmList