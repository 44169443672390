import { serverTimestamp } from 'firebase/firestore';
import _ from 'lodash';
import { removeArrayItem } from '../../common/sorting';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../firestoreData/appData/fsData';

const rts = {
  handleAddQuestion: 'handleAddQuestion',
  handleAddResponse: 'handleAddResponse',
  handleInit_qAndA: 'handleInit_qAndA',
  handleQuestion_like: 'handleQuestion_like',
  handleQuestion_approve: 'handleQuestion_approve',
  handleQuestion_markAsRead: 'handleQuestion_markAsRead',
  handleSetQuestionsAndAnswers: 'handleSetQuestionsAndAnswers',
  handleUpdateQandA: 'handleUpdateQandA',

  handleSet_activeQuestions: 'handleSet_activeQuestions',
  handleSet_currentGroupedQuestions: 'handleSet_currentGroupedQuestions',
  handleSet_currentQandA: 'handleSet_currentQandA',
  handleSet_currentQuestions: 'handleSet_currentQuestions',
  handlesShow_itemQuestions: 'handlesShow_itemQuestions',
}

const _allowUpdates = true

export const _collectionName = '_questionsAndAnswers'

export const questionsAndAnswersReducer = (state, action) => {

  const {
    allowQuestionsAndAnswers,
    pathViews,
  } = state

  const { dispatch, type } = action

  // eslint-disable-next-line
  const { handleSetQuestionsAndAnswers } = questionsAndAnswersHandlers(dispatch)

  switch (type) {

    case rts.handleInit_qAndA:
      if (allowQuestionsAndAnswers && pathViews.events) {
        const _refPath = createRefPath_event(pathViews, [_collectionName])
        fs_db.get_data({ refPath: _refPath, callback: handleSetQuestionsAndAnswers, opts: { listen: true } })
      }
      return { ...state, questionsAndAnswers: {} }

    // sets the data after the handleInit_qAndA call
    case rts.handleSetQuestionsAndAnswers:
      const _active = _.filter(action.qas, { questionActive: true })
      return { ...state, questionsAndAnswers: action.qas, questionsAndAnswersActive: _active }

    case rts.handleUpdateQandA:
      updateQandA(pathViews, action.viewItem_key, action.itemKey, action.name, action.data, action.callback)
      return { ...state }

    case rts.handleAddQuestion:
      updateQandA_question(pathViews, action)
      return { ...state }

    case rts.handleAddResponse:
      updateQandA_response(pathViews, action)
      return { ...state }

    case rts.handleQuestion_like:
      updateQandA_like(pathViews, action)
      return { ...state }

    case rts.handleQuestion_approve:
      updateQandA_approve(pathViews, action)
      return { ...state }

    case rts.handleQuestion_markAsRead:
      updateQandA_markAsRead(pathViews, action)
      return { ...state }

    case rts.handleSet_activeQuestions:
      return { ...state }

    case rts.handleSet_currentGroupedQuestions:
      return { ...state }

    case rts.handleSet_currentQandA:
      return { ...state }

    case rts.handleSet_currentQuestions:
      return { ...state }

    default:
      return { ...state }
  }
}

export const questionsAndAnswersInitialState = (initState) => {
  return { ...initState }
};

export const questionsAndAnswersHandlers = (dispatch) => {
  return {
    handleAddQuestion: (uid, question, createQuestionItem, qAndA, callback) => { dispatch({ type: rts.handleAddQuestion, dispatch, uid, question, createQuestionItem, qAndA, callback }) },
    handleAddResponse: (itemKey, qAndA, question, response, callback) => { dispatch({ type: rts.handleAddResponse, dispatch, itemKey, qAndA, question, response, callback }) },
    handleQuestion_approve: (uid, itemKey, qAndA, question, callback) => { dispatch({ type: rts.handleQuestion_approve, dispatch, itemKey, qAndA, question, uid, callback }) },
    handleQuestion_markAsRead: (uid, itemKey, qAndA, question, callback) => { dispatch({ type: rts.handleQuestion_markAsRead, dispatch, itemKey, qAndA, question, uid, callback }) },
    handleInit_qAndA: () => { dispatch({ type: rts.handleInit_qAndA, dispatch }) },
    handleQuestion_like: (uid, itemKey, qAndA, question, callback) => { dispatch({ type: rts.handleQuestion_like, dispatch, itemKey, qAndA, question, uid, callback }) },
    handleSetQuestionsAndAnswers: (qas) => { dispatch({ type: rts.handleSetQuestionsAndAnswers, dispatch, qas }) },
    handleUpdateQandA: (viewItem_key, itemKey, name, data, callback) => { dispatch({ type: rts.handleUpdateQandA, dispatch, viewItem_key, itemKey, name, data, callback }) },

    handleSet_activeQuestions: () => { dispatch({ type: rts.handleSet_activeQuestions, dispatch }) },
    handleSet_currentGroupedQuestions: () => { dispatch({ type: rts.handleSet_currentGroupedQuestions, dispatch }) },
    handleSet_currentQandA: () => { dispatch({ type: rts.handleSet_currentQandA, dispatch }) },
    handleSet_currentQuestions: () => { dispatch({ type: rts.handleSet_currentQuestions, dispatch }) },
  }
}

const updateQandA = async (pathViews, viewItem_key, item_key, name, data, callback) => {
  if (pathViews && item_key && data) {
    data._collectionKey = viewItem_key
    data._name = name
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, item_key])
    if (_allowUpdates) {
      fs_set_doc(_updateRef2, data, true, callback)
    } else {
      console.log('updateQandA', _updateRef2, data)
    }
  }
}

const updateQandA_question = async (pathViews, action) => {

  const { uid, question, createQuestionItem, callback } = action
  const { id: itemKey, questions } = createQuestionItem ?? {}

  if (pathViews && itemKey && question) {
    const _questions = questions ?? {}
    const _questionCount = questions ? Object.keys(questions).length + 1 : 1
    _questions['question' + _questionCount] = { uid, question, timestamp: serverTimestamp(), questionNumber: _questionCount }
    const data = { questions: _questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    if (_allowUpdates) {
      fs_set_doc(_updateRef2, data, true, callback)
    } else {
      console.log('updateQandA_question', _updateRef2, data)
    }
  }
}

const updateQandA_response = async (pathViews, action) => {
  const { itemKey, qAndA, question, response, callback } = action
  if (pathViews && itemKey && response) {
    const { questions } = qAndA ?? {}
    question.response = response
    const data = { questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    if (_allowUpdates) {
      fs_set_doc(_updateRef2, data, true, callback)
    } else {
      console.log('updateQandA_response', _updateRef2, data)
    }
  }
}

const updateQandA_approve = async (pathViews, action) => {
  const { itemKey, qAndA, question, uid, callback } = action
  if (pathViews && itemKey && uid) {
    const { questions } = qAndA ?? {}
    const { approved } = question ?? {}
    question.approved = !approved
    const data = { questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    if (_allowUpdates) {
      fs_set_doc(_updateRef2, data, true, callback)
    } else {
      console.log('updateQandA_approve', _updateRef2, data)
    }
  }
}

const updateQandA_like = async (pathViews, action) => {
  const { itemKey, qAndA, question, uid, callback } = action
  if (pathViews && itemKey && uid) {
    const { questions } = qAndA ?? {}
    const { likes } = question ?? {}
    const _likes = likes ? likes : []
    if (_likes.includes(uid)) {
      removeArrayItem(_likes, uid)
    } else {
      _likes.push(uid)
    }
    question.likes = _likes
    const data = { questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    if (_allowUpdates) {
      fs_set_doc(_updateRef2, data, true, callback)
    } else {
      console.log('updateQandA_like', _updateRef2, data)
    }
  }
}

const updateQandA_markAsRead = async (pathViews, action) => {
  const { itemKey, qAndA, question, uid, callback } = action
  if (pathViews && itemKey && uid) {
    const { questions } = qAndA ?? {}
    const { read } = question ?? {}
    question.read = !read
    const data = { questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    if (_allowUpdates) {
      fs_set_doc(_updateRef2, data, true, callback)
    } else {
      console.log('updateQandA_markAsRead', _updateRef2, data)
    }
  }
}