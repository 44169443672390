import React, { createContext, useContext, useEffect, useState } from 'react';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fsfn_settings } from '../../functions/fbSettingsFunctions';
import { AppSettingsContext } from './AppSettingsContext';

const useFunctions = false

/** Returns all the forms (objects) needed for the App */
export const AppFormsContext = createContext();


/** This will get the homeSettings and clientSettings */
const AppFormsProvider = (props) => {

  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_handlers } = appSettingsContext ?? {}

  const [appForms, setAppForms] = useState()
  const [formsUpdated, setFormsUpdated] = useState()

  const getForms = () => {
    const vvProps = {}
    if (useFunctions) {
      fsfn_settings.getAppForms(vvProps).then(appForms => {
        setAppForms(appForms)
        setFormsUpdated(true)
      })
    } else {
      const cb = (res) => {
        const { error } = res
        if (error) {
          appSettings_handlers.handleError_app(error)
        } else {
          setAppForms(res)
        }
      }
      getAppForms(cb, setFormsUpdated, vvProps)
    }
  }

  useEffect(() => {
    getForms()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const providerContext = () => <AppFormsContext.Provider value={{ appForms_state: { appForms: appForms, formsUpdated }, appForms_fns: { getForms } }}>
    {props.children}
  </AppFormsContext.Provider>

  return providerContext()
  // return appForms ? providerContext() : <div></div>

}

export default AppFormsProvider

const getAppForms = (callback, callback2, vvProps) => {
  const docName = 'appForms'
  fs_db.get_data({ refPath: createRefPath(['_appForms', docName]), opts: { returnFirstObject: true, ignoreId: true } }).then(appForms => {
    const { error } = appForms ?? {}
    if (error) {
      callback(error)
    } else {
      if (appForms && Object.keys(appForms).length === 0) {
        fsfn_settings.getAppForms(vvProps).then(appForms => {
          callback2(appForms)
          callback(true)
        })
      } else {
        callback(appForms ? appForms : {})
        callback2(true)
      }
    }
  }).catch(err => {
    console.error('getAppForms', err)
    callback({ error: err })
  })
}