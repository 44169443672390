import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { helpHandlers, helpInitialState, helpReducer } from '../reducers/HelpReducer';
import { ParentContext } from './ParentContext';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { gEnums } from '../../enums/globalEnums';
// import { FirestoreContext } from './FirestoreContext';

/** 
@provides handleUpdateHelp 
*/
/**
 * help_state (helpDocs)- helpActions, helpDocuments, helpDocumentz, helpSettings, helpTopics
 * help_handlers (handleUpdate_help, handleUpdate_allHelp)
 */
export const HelpContext = createContext();

const HelpProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state } = states ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  const init_state = {}
  const [help_state, help_dispatch] = useReducer(helpReducer, init_state, helpInitialState)
  const help_handlers = helpHandlers(help_dispatch, help_state)

  useEffect(() => {
    if (appUserAccess) {
      const { accessLevel } = appUserAccess
      if (accessLevel >= gEnums.accessLevels.appDataAdmin.value) {
        // help_handlers.handleInit_help()
      }
    }

    // eslint-disable-next-line
  }, [appUserAccess]) // run once

  const providerContext = () => <HelpContext.Provider value={{ help_state, help_handlers }}>
    {props.children}
  </HelpContext.Provider>

  return (
    <React.Fragment>
      {providerContext()}
    </React.Fragment>
  )
}

export default HelpProvider