import { callFunction } from "../../../global/functions/fbAll"

const sportsGenericTypes = {
  sportsGeneric: 'sportsGeneric',
}

const sportFunctionTypes = {
  updateScoresFromGoogleSheets: 'updateScoresFromGoogleSheets',
  getSportsData_glsScores: 'getSportsData_glsScores',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(sportsGenericTypes.sportsGeneric, callData, true)
  return data
}

export const fsfn_sports = {
  updateScoresFromGoogleSheets: (spreadsheetId, sheetName, currentEmail, matchStatusType, pojoOnly, showLogs, callback) => updateScoresFromGoogleSheets(spreadsheetId, sheetName, currentEmail, matchStatusType, pojoOnly, showLogs, callback),
  getSportsDataGlsScores: (data, callback) => getSportsDataGlsScores(data, callback),
}

/**
 * 
 * @param {string} title  
 * @returns response_data {success, result, error}
 */
const updateScoresFromGoogleSheets = async (spreadsheetId, sheetName, currentEmail, matchStatusType, pojoOnly, showLogs, callback) => {
  try {
    const callData = { sportFunctionType: sportFunctionTypes.updateScoresFromGoogleSheets, spreadsheetId, sheetName, currentEmail, matchStatusType, pojoOnly, showLogs }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData)// OK
    console.log('response_data', response_data)
    if (callback) {
      callback(response_data)
    } else {
      return response_data
    }
  } catch (error) {
    console.error(error)
  }
}

const getSportsDataGlsScores = async (data, callback) => {
  try {
    const callData = {
      sportFunctionType: sportFunctionTypes.getSportsData_glsScores,
      ...data,
    }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData)// OK
    console.log('response_data', response_data)
    if (callback) {
      callback(response_data)
    } else {
      return response_data
    }
  } catch (error) {
    console.error(error)
  }
}
