import _ from 'lodash'
import React, { createContext, Suspense, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Icon, Menu, Segment, Sidebar } from 'semantic-ui-react'
import { getDataManagementOptions, getDataSidebarOptions } from '../../auth/actionAccessPermissions'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import DataProvider, { DataContext } from '../../cnr/contexts/DataContext'
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext'
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent'
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { dataManagementHandlers, dataManagementInitialState, dataManagementReducer, viewDataModes } from '../../cnr/reducers/DataManagmentReducer'
import { helpTypes } from '../../cnr/reducers/HelpReducer'
import { getGoogleSheetDataValues } from '../../common/convert'
import { uniqueKey } from '../../common/keys'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import { appIconTypes } from '../../enums/appIconTypes'
import { updateSettings } from '../../firestoreData/settings/updateSettings'
import { fsfn_sheets } from '../../functions/fbSheetsFuntions'
import { listList } from '../../lists/lists'
import DataManagementContent from '../../pageItem/modification/DataManagementContent'
import DataSidebarContent from '../../pageItem/modification/DataSidebarContent'
import UiDataModifications, { dataModificationDisplayTypes } from '../../pageItem/modification/UiDataModifications'
import SettingsViewer from '../../viewSettings/actions/SettingsViewer'
import { dataSidebarActionTypes } from '../../viewSettings/enums/itemActionTypes'
import SettingsFormEdit from "../../viewSettings/subComponents/SettingsFormEdit"
import FullPageWrapper from '../../wrappers/FullPageWrapper'
import TransitionWrapper, { transitionWrapperTypes } from '../../wrappers/TransitionWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import SuspenseDimmer from '../alerts/SuspenseDimmer'
import { getHeaders } from '../headers/getHeaders'
import Help from '../help/Help'
import DataSidebar from '../swipers/DataSidebar'
import AlphaTableViewer from "./AlphaTableViewer"
import { appViewerColors } from './AppDashboard'
import DataManagementOptions from './DataManagementOptions'
import DataManagementTop from './DataManagementTop'
import JsonViewer from "./JsonViewer"
import TableViewer from "./TableViewer"
import { clickOriginTypes } from '../../enums/globalEnums'

export const DataManagementContext = createContext();

const _alphaLimit = 75
const _defaultMenuItem = 'collectionOptions'

/**
 * 
 * @param {object} props (clickOriginType, uivi, viewItem, viewListData)
 * @returns 
 */
const DataManagementViewer = (props) => {

  const {
    clickOriginType,
    fromAppDocuments,
    uivi: uivi_direct,
    viewItem: viewItem_direct,
    viewListData: viewListData_direct
  } = props ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
      _fromDataViewer = true
    default:
    //nothing
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, eventInfo_state } = states ?? {}
  const { page_fns, database_fns } = fns ?? {}
  const { pathViews, viewKey } = paps_state ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // homeSettingsContext 
  const { homeSettings } = settings ?? {}
  const { global } = homeSettings ?? {}
  const { permissionsConsole: permissionsConsole_home_global } = global ?? {}
  const { dataManagementSettings } = permissionsConsole_home_global ?? {}

  // eventInfoContext  
  const { staticViews: staticViews_app, appDataDocuments } = eventInfo_state ?? {}

  // pageSettings
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_views } = pageSettings ?? {}
  const { appDataManagement, dataOptions, productionSettings, googleSheets, dataRestrictions } = aps_global ?? {}
  const { dateFormat } = dataOptions ?? {}
  const { googleSheetsId } = googleSheets ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { fullMode } = framework_state ?? {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers } = firestoreContext ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ?? {}
  const { updating, confirmation } = googleSheets_state ?? {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ?? {}
  const { uivi, viewItem, viewListData, fsr } = data_state ?? {}
  const { dataSource } = viewItem ?? {}
  const { altDataCollectionName } = dataSource ?? {}

  let _uivi = altDataCollectionName ? altDataCollectionName : uivi
  if (uivi_direct) { _uivi = uivi_direct }

  let _viewItem = viewItem_direct ? viewItem_direct : viewItem
  if (!_viewItem) { _viewItem = {} }
  const _viewListData = viewListData_direct ? viewListData_direct : viewListData
  const { key: key_viewItem } = _viewItem ?? {}

  const init_state = { database_fns, fsr, dataRestrictions, productionSettings, firestore_handlers, page_fns, _defaultMenuItem, dataManagementType: _defaultMenuItem, viewListData: _viewListData, staticViews_app, dateFormat, pathViews, viewKey, viewItem: _viewItem, aps_viewItems, clickOriginType }
  const [dataManagement_state, fullData_dispatch] = useReducer(dataManagementReducer, dataManagementInitialState(init_state));
  const dataManagement_handlers = dataManagementHandlers(fullData_dispatch)
  const { handleDataActionSelect } = dataManagement_handlers ?? {}

  const {
    // activeTab, // triggered from DataTabs
    appData,
    dataCount,
    fixedData,
    dataManagementType,
    selectedItem,
    showExport,
    showHelp,
    tabData,
    viewDataMode,
    viewItem_previewTemp,
  } = dataManagement_state ?? {}

  const [dataActionOptions, setDataActionOptions] = useState()
  const [showAlpha, setShowAlpha] = useState()
  const [dataManagementOptions, setDataManagementOptions] = useState()
  const [dataSidebarOptions, setDataSidebarOptions] = useState()

  const tableRef = useRef()

  useEffect(() => {
    let _dataManagementOptions = getDataManagementOptions(dataManagementSettings, appDataManagement, appUserAccess)
    let _dataSidebarOptions = getDataSidebarOptions(dataManagementSettings, appDataManagement, appUserAccess)
    setDataManagementOptions(_dataManagementOptions)
    setDataSidebarOptions(_dataSidebarOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_uivi, _viewListData, viewItem]);


  useEffect(() => {
    if (selectedItem) {
      console.log('selectedItem', selectedItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    if (_viewItem) {
      const ada = []
      if (dataManagementOptions) {
        Object.keys(dataManagementOptions).forEach(daKey => {
          const _dmo = dataManagementOptions[daKey]
          switch (daKey) {
            case 'manageData':
            case 'viewData':
              break;
            default:
              if (_dmo.allowAction) {
                ada.push(daKey)
              }
          }
        })
      }
      setDataActionOptions(listList(ada, null, null, null, _defaultMenuItem))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataManagementOptions, _viewListData]);

  useEffect(() => {
    if (_viewListData) {
      dataManagement_handlers.handleAppItemData(_viewItem, _viewListData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_uivi, _viewListData]);

  useEffect(() => {
    if (appData) {
      const dataCount = appData ? Object.keys(appData).length : 0
      if (dataCount > _alphaLimit) {
        setShowAlpha(true)
      } else {
        setShowAlpha(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  useEffect(() => {
    if (fixedData) {
      dataManagement_handlers.handleActiveTab(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedData]);

  const [showDataOptions, setShowDataOptions] = useState()

  // shortcuts for dataManagement_handlers
  const handlePreview = (data) => dataManagement_handlers.handleSettingsFormUpdate(data)
  const handleRowSelect = (item) => dataManagement_handlers.handleRowSelect(item) // changes viewDataMode
  const handleSettingsFormUpdate = (data, save) => dataManagement_handlers.handleSettingsFormUpdate(data, save)
  const handleShowDataOptions = () => setShowDataOptions(!showDataOptions)
  const handleShowHelp = () => dataManagement_handlers.handleShowHelp()
  const handleViewDataMode = (opts) => dataManagement_handlers.handleViewDataMode(opts)
  const handleShowExport = (opts) => dataManagement_handlers.handleShowExport()

  const handleSelectActionOption = (key) => {
    setShowDataOptions()
    dataManagement_handlers.handleSelect_dataActionOption(key)
  }

  /**
   * 
   * @returns a menu with all of the dataActionOptions
   * @description onClick will triggner handleSelectActionOption
   */
  const dataManagementMenu = () => {
    const mis = []
    dataActionOptions.forEach(ao => {
      const { value, key, text } = ao
      mis.push(<Menu.Item
        key={uniqueKey('dv', 'mi', key)}
        onClick={() => { handleSelectActionOption(value) }}>
        {_.startCase(text)}
        <Icon color={'blue'} name={appIconTypes[value]} />
      </Menu.Item>)
    })

    return <TransitionWrapper>
      {/* <Segment inverted basic> */}
      <Menu vertical fluid inverted className={'dmv-menu'}>{mis}</Menu>
      {/* </Segment> */}
    </TransitionWrapper>
  }

  const handleExportToGoogle = () => {
    const { headers } = getHeaders(appData)
    const { values, tabName } = getGoogleSheetDataValues(Object.values(appData), headers, _.startCase(uivi))
    console.log('tabName', googleSheetsId, tabName, values)
    fsfn_sheets.updateGoogleSheetValues(googleSheetsId, tabName, values)
  }

  const footer_export = () => {
    const tabName = _.startCase(uivi)
    const btns = [
      { caption: _.startCase('exportToGoogleSheets') + ' (' + tabName + ')', oc: handleExportToGoogle, icon: 'save' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'green'}
    />
  }

  /**
   * 
   * @returns an editable form
   */
  const settingsForm = () => <SettingsFormEdit
    settingsType={viewDataMode}
    formData={selectedItem}
    useWrapper={true}
    previewForm={handlePreview}
    updateForm={viewItem_previewTemp && handleSettingsFormUpdate}
    closeForm={handleViewDataMode}
    isGlobal={false}
    inSidebar={true}
  />

  /**
   * 
   * @returns the content for the sidebar based on the `viewDataMode`
   * @description this will only be shown if `viewDataMode` is set.
   */
  const rightSidebarContent = () => {
    if (dataSidebarActionTypes[viewDataMode]) {
      return <DataSidebarContent />
    } else {
      switch (viewDataMode) {
        case viewDataModes.dataSource:
          return settingsForm()
        case viewDataModes.viewJson:
          return <JsonViewer json={tabData} name={viewDataMode} />
        case viewDataModes.dataEdit:
          return <UiDataModifications dataModificationDisplayType={dataModificationDisplayTypes.direct} clickOriginType={clickOriginType} />
        case viewDataModes.fixSettings:
          const projectSettings = updateSettings.cleanUpSettings(_uivi, aps_views, aps_viewItems)
          return <SettingsViewer createInfo={{ projectSettings }} />
        default:
          return <div>No View Data Mode</div>
      }
    }
  }

  const wrapper_sidebarRight = () => <Suspense fallback={<SuspenseDimmer origin={'DataManagementViewer'} />}>
    <Wrapper
      header={_.startCase(viewDataMode)}
      content={rightSidebarContent()}
      wrapperType={wrapperTypes.paddedHeader}
      css={{ container: 'data-sidebar' }}
    ></Wrapper>
  </Suspense>

  const wrapper_exportRight = () => <Suspense fallback={<SuspenseDimmer origin={'DataManagementViewer'} />}>
    <Wrapper
      header={_.startCase('exportToGoogleSheets') + ': ' + googleSheetsId}
      content={<JsonViewer json={appData} />}
      footer={footer_export()}
      wrapperType={wrapperTypes.paddedHeader}
      css={{ container: 'data-sidebar' }}
    ></Wrapper>
  </Suspense>

  /** The button that triggers the dataOptions to be shown */
  const dataOptionsButton_top = () => {
    const btns = [
      { caption: _.startCase(dataManagementType), oc: handleShowDataOptions, icon: 'unordered list' },
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.dmv}
    />
  }

  const topper = () => <div className={'topper-flex'}>
    {_.startCase(uivi_direct)}
  </div>

  const header = () => <div className={'header-flex head'}>
    {dataOptionsButton_top()}
    <DataManagementTop />
  </div>

  const footer = () => {
    switch (dataManagementType) {
      case _defaultMenuItem:
        return <div className={'header-flex foot'}>
          <DataManagementOptions
            dataSidebarOptions={dataSidebarOptions}
            setShowDataOptions={setShowDataOptions}
            key_viewItem={key_viewItem}
            _uivi={_uivi}
          />
        </div>
      default:
        return null
    }
  }

  const tableViewer = () => {
    const _jsonData = fromAppDocuments && appDataDocuments ? appDataDocuments[uivi_direct] : appData
    return <TransitionWrapper key={uivi_direct} transitionWrapperType={transitionWrapperTypes.fade}>
      <TableViewer
        handleRowSelect={handleRowSelect}
        isAppData={true}
        jsonData={_jsonData}
        tableRef={tableRef}
        usePropAdjustments={true}
        viewItem={_viewItem}
        showItemKey={true}
      />
    </TransitionWrapper>
  }


  const alphaTableViewer = () => <AlphaTableViewer appData={appData} handleRowSelect={handleRowSelect} tableRef={tableRef} viewItem={_viewItem} showItemKey={true} />
  const content_table = () => showAlpha ? alphaTableViewer() : tableViewer()

  /**
   * 
   * @returns DataManagementContent bases on the `dataManagementType`
   */
  const content_dataManagementItem = () => <Suspense fallback={<SuspenseDimmer origin={'DataManagementViewer'} />}>
    <Wrapper
      content={<DataManagementContent />}
    />
  </Suspense>

  /**
   * 
   * @returns dataContent OR dataManagementContent
   */
  const content_data = () => {
    switch (dataManagementType) {
      case _defaultMenuItem:
        return content_table()
      default:
        return content_dataManagementItem()
    }
  }

  /**
   * 
   * @returns the content in a wrapper
   */
  const wrapper_content = () => {
    let cn = 'wrp-sub'
    if (selectedItem) { cn += ' selected' }
    if (showHelp) {
      return <Help helpType={helpTypes.actions} handleCancel={handleShowHelp} />
    } else {
      return _viewItem ?
        <Wrapper
          topper={topper()}
          header={fullMode ? header() : header()}
          content={content_data()}
          footer={fullMode ? footer() : footer()}
          wrapperType={wrapperTypes.paddedFooter}
          updating={updating}
          confirmation={confirmation}
          css={{ container: cn }}
        />
        :
        <Segment basic>No View</Segment>
    }
  }

  const sidebar_topLeft = (visible) => <DataSidebar direction={'left'} visible={visible} content={visible && dataManagementMenu()} onHide={handleShowDataOptions} />
  const sidebar_right = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && wrapper_sidebarRight()} onHide={handleViewDataMode} />
  const sidebar_export = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && wrapper_exportRight()} onHide={handleShowExport} />

  /**
   * 
   * @returns a Sidebar containing both a left and right sidebar
   */
  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_topLeft(showDataOptions ? true : false)}
    {sidebar_right(viewDataMode ? true : false)}
    {sidebar_export(showExport)}
    <Sidebar.Pusher dimmed={viewDataMode ? true : false} className='h100' >
      {wrapper_content()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleDataActionSelect}
    topperCaption={'Data Management'}
    topperCaption2={dataCount ? _.startCase(_uivi) + '(' + dataCount + ')' : _.startCase(_uivi)}
  />

  return _viewItem ? <DataManagementContext.Provider value={{ dataManagement_state, dataManagement_handlers }}>
    <DataProvider uivi={_uivi} clickOriginType={clickOriginType}>
      {_fromDataViewer ? sidebar() : fullPageWrapper()}
    </DataProvider>
  </DataManagementContext.Provider>
    :
    <div>THIS MAY NOT BE NEEDED</div>
}

export default DataManagementViewer