import React, { createContext, useState } from 'react';

/** 
 * @state dataWrapper_state (`data`, `item_data_temp`)
 * @functions dataWrapper_fns (`setPageTempData`) 
 */
export const DataWrapperContext = createContext();

const DataWrapperProvider = (props) => {

  const [data] = useState()
  const [item_data_temp, setTempItemData] = useState()

  const dataWrapper_state = { data, item_data_temp }

  const dataWrapper_fns = {
    setPageTempData: (data) => {
      setTempItemData(data)
    },
  }

  // setPageData
  const providerContext = () => <DataWrapperContext.Provider value={{ dataWrapper_state, dataWrapper_fns }}>
    {props.children}
  </DataWrapperContext.Provider>

  return providerContext()

}

export default DataWrapperProvider
