import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { DragNDropContext } from '../cnr/contexts/DragNDropContext';
import { convertHelpers } from '../common/convert';
import { uniqueKey } from '../common/keys';
import DragContext from './DragContext';
import { dragDropperTypes } from './DragDropper';
import DroppableElement from './DroppableElement';

const _items = [
  { id: '1', content: 'Item 1' },
  { id: '2', content: 'Item 2' },
  { id: '3', content: 'Item 3' },
];

const _ddKey = 'teams'

const SortableList = (props) => {

  const { menuItems, css } = props ?? {}
  const { menu: css_menu } = css ?? {}

  const dragNDropContext = useContext(DragNDropContext)
  const { dragNDrop_state, dragNDrop_handlers } = dragNDropContext ?? {}
  const { dndColumns } = dragNDrop_state ?? {}
  const { handleCreate_dndGroups } = dragNDrop_handlers ?? {}
  const ddColumn = dndColumns && dndColumns[_ddKey] ? dndColumns[_ddKey] : {}
  const { dndGroups } = ddColumn ?? {}
  const dndGroup = dndGroups ? dndGroups[_ddKey] : {}

  const [listItems, setListItems] = useState();
  const [portal, setPortal] = useState()

  useEffect(() => {
    if (menuItems) {
      const _menuItems = { ...menuItems }
      convertHelpers.createItemKeys(_menuItems, 'id')
      setListItems(_.sortBy(_menuItems, 'name'))
      handleCreate_dndGroups({ menuItems, ddKey: _ddKey })
    } else {
      setListItems(_items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {
    const p = document.createElement('div');
    p.classList.add('ddp');
    p.classList.add(dragDropperTypes.simple);
    setPortal(p)
    return () => {
      // document.body.removeChild(portal);
      // Anything in here is fired on component unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const droppable = (index, allow_sort) => <DroppableElement
    allowSort={allow_sort}
    columnKey={_ddKey}
    // ddKey={ddKey}
    group={dndGroup}
    groupKey={_ddKey}
    // horizontal={horizontal}
    index={index}
    portal={portal}
  // portalCn={portalCn}
  // wrapperCns={wrapperCns}
  ></DroppableElement>

  console.log('ssssssssssssssssssssssss')

  const dragDropContent = () => <DragContext
    groupKey={_ddKey}
    content={dndGroup && <Menu vertical inverted className={css_menu}>
      {droppable(1, true)}
    </Menu>}
  ></DragContext>

  if (portal && listItems) {
    document.body.appendChild(portal);
    return dragDropContent()
  } else {
    return <div></div>
  }

};

export default SortableList;
