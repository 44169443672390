import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Icon, Label, Menu, Segment } from 'semantic-ui-react';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { PageContext } from '../../cnr/contexts/PageContext';
import { PageDataContext } from '../../cnr/contexts/PageDataContext';
import SwiperProvider, { SwiperContext } from '../../cnr/contexts/SwiperContext';
import { getTabView, setHorizontalScroll } from '../../cnr/reducers/reducerHelpers/swipeHelpers';
import { g_cns } from '../../common/cns';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import { clickOriginTypes, gEnums } from '../../enums/globalEnums';
import { setStyleAndClass } from '../../styles/formatting';
import { projectStyles } from '../../styles/projectStyles';
import { AlphaContext } from '../../cnr/contexts/AlphaContext';
import FullPageContent from '../layout/FullPageContent';

const _userIconColor = 'grey'
const _adminIconColor = 'yellow'

/** Using SwipeableViews
 * @param `items` - if passed in, these will be the swipe items. If not, `viewItems` from `PageContext` will be the swipe items.
  */
const UiSwiper = (props) => {

  const { fromAppUserProfile } = props ?? {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}

  // pageDataContext
  const pageDataContext = useContext(PageDataContext)
  const { pageData_state } = pageDataContext ?? {}
  const { itemDataCounts } = pageData_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { desktopMobileOn, showFullPage, fullPageType } = framework_state ?? {}

  // swiperContext
  const swiperContext = useContext(SwiperContext)
  const { swiper_handlers, swiper_state, swiper_fns } = swiperContext ?? {}
  const { swipeContentType, groupType, swiperContent, tabIndex, layoutType, useStartCaseHeaders, currentSwipeItems } = swiper_state ?? {}
  const { fn_getContent } = swiper_fns ?? {}

  // alphaContext
  const alphaContext = useContext(AlphaContext);
  const { alpha_state, alpha_handlers } = alphaContext ?? {}
  const { groupedInfo } = alpha_state ?? {}

  let _loadSwipeItemData = false // swipeContentType === gEnums.swipeContentTypes.pageAreaContent

  switch (layoutType) {
    case gEnums.layoutTypes.swipeableHidden:
      _loadSwipeItemData = true
      break;
    default:
  }

  // note > swiperItems: {content, loaded}
  const { swiperItems, swiperTabs, tabContainerId } = swiperContent ?? {}

  const viewCount = swiperTabs ? swiperTabs.length : null

  useEffect(() => {
    if (swiperTabs) {
      const tvw = getTabView(tabContainerId, 'mi')
      tvw.setTab(swiperTabs.length)
      setHorizontalScroll(tvw, tabContainerId, tabIndex)
      if (fn_getContent) {
        if (_loadSwipeItemData) {
          const _swiperItems = { ...swiperItems }
          Object.keys(_swiperItems).forEach((sik, index) => {
            _swiperItems[sik] = fn_getContent(sik, index, _loadSwipeItemData ? false : true)
          })
          swiper_handlers.handleAmmend_swiperItems(_swiperItems, tvw)
        } else {
          const indexKey = Object.keys(swiperItems)[tabIndex]
          const _swiperItems = { ...swiperItems }
          if (_swiperItems[indexKey] && !_swiperItems[indexKey].loaded) {
            _swiperItems[indexKey] = fn_getContent(indexKey, tabIndex, _loadSwipeItemData ? true : true)
            swiper_handlers.handleAmmend_swiperItems(_swiperItems, tvw)
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [tabContainerId, swiperTabs, tabIndex]);

  useEffect(() => {
    if (_.isNumber(tabIndex)) {
      const { groups } = currentSwipeItems ?? {}
      const group = groups ? groups[tabIndex] : null
      const { alphaInfo } = group ?? {}
      if (alphaInfo) {
        alpha_handlers.handleAmmend_fromGroupInfo(alphaInfo)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [tabIndex, groupedInfo]);

  const menuOnTop = true

  const menuPropsAndStyles = {
    widths: menuOnTop ? null : viewCount,
    labeled: menuOnTop ? null : 'labeled',
    borderless: menuOnTop ? true : false,
    useStyle: menuOnTop ? true : false,
    styles: menuOnTop ? projectStyles().menuHorizontal : projectStyles().menuHorizontalBottom
  }

  const tabsSnC = setStyleAndClass(aps_global, fromAppUserProfile ? gEnums.projectStyles.tabsAppUser : gEnums.projectStyles.tabs)
  const tabsSnC_active = setStyleAndClass(aps_global, fromAppUserProfile ? gEnums.projectStyles.tabsAppUser : gEnums.projectStyles.tabs, null, null, true)
  const tabsSnC_line = setStyleAndClass(aps_global, fromAppUserProfile ? gEnums.projectStyles.tabsAppUser : gEnums.projectStyles.tabs, null, null, false, true)

  const activeSnC = { className: 'swipe-tab' }
  activeSnC.style = { ...tabsSnC_line.style, ...tabsSnC_active.style }

  const { style: activeStyle } = activeSnC ?? {}

  const _activeSac = activeSnC ?
    activeSnC :
    {
      className: activeSnC.className,
      style: {
        color: activeStyle.color ? activeStyle.color : null,
        borderBottom: '2px solid ' + activeStyle.borderColor
      }
    }

  const menuItem = (swipeTab, index, asHeader) => {
    // set the style here 
    let active = parseInt(tabIndex, 10) === parseInt(index, 10) ? true : false
    const itemSaC = active ? _activeSac : tabsSnC
    const { caption, showTabItemCount, adminOnly, userOnly, dataCount } = swipeTab ?? {}
    const _caption = useStartCaseHeaders ? _.startCase(caption) : caption
    return asHeader ?
      <Segment>{_caption}</Segment>
      : <Menu.Item
        {...itemSaC}
        key={uniqueKey('swmi', index)}
        id={'mi_' + index}
        value={index}
        active={active}
        onClick={() => { swiper_handlers.handleSwipe_changeIndex(index) }}
      >
        {adminOnly && <Icon color={_adminIconColor} name={'user secret'} title={'You are only seeing this item because you are an admin'}>{dataCount}</Icon>}
        {userOnly && <Icon color={_userIconColor} size={'small'} name={'user'}>{dataCount}</Icon>}
        {_caption}
        {showTabItemCount && <Label>{dataCount}</Label>}
      </Menu.Item>
  }

  const menuItems = () => swiperTabs.map((swipeTab, index) => {
    if (_loadSwipeItemData) {
      const { key } = swipeTab
      const visible = itemDataCounts && itemDataCounts[key] && itemDataCounts[key] > 0 ? true : false
      if (visible) {
        return menuItem(swipeTab, index)
      }
    } else {
      return menuItem(swipeTab, index)
    }
  })


  const itemContent = () => (Object.values(swiperItems).map(item => (item.content)))

  const tabMenu = () => (
    <Menu pointing secondary
      {...tabsSnC}
      className={'swipe-tab-container'}
      id={tabContainerId}
      widths={menuPropsAndStyles.widths}
    >
      {viewCount > 0 && menuItems()}
    </Menu>
  )

  const swipeableContent = () => <SwipeableViews
    enableMouseEvents={true}
    index={parseInt(tabIndex)}
    threshold={2}
    onChangeIndex={swiper_handlers.handleSwipe_changeIndex}
    className={'swvs'}
    animateTransitions={!desktopMobileOn}
  >
    {itemContent()}
  </SwipeableViews>

  const verticalMenu = () => {
    const acci = []
    Object.keys(swiperItems).forEach((key, index) => {
      const si = swiperItems[key]
      const cn = tabIndex !== index ? 'dis-none' : ''
      acci.push(
        <Menu.Item key={uniqueKey('vmi', index)} className={'acc-header'}>
          <Menu.Header onClick={() => swiper_handlers.handleSwipe_changeIndex(index)} >
            <div>{key}</div>
            <Icon name={'caret down'} />
          </Menu.Header>
          <Menu.Menu className={cn}>
            {si.content && si.content}
          </Menu.Menu>
        </Menu.Item>
      )
    })
    return <Menu vertical fluid >{acci}</Menu>
  }

  const addSegs = (x, si, index) => {
    let caption = si.key
    if (_.isDate(si.key)) { caption = formatItem(formatTypes.fullDate, si.key) }
    x.push(<div key={uniqueKey('swas', index, 1)} className={'seg-header'} style={tabsSnC.style}>{caption}</div>)
    x.push(<div key={uniqueKey('swas', index, 2)} className={'seg-content'}>{si.content}</div>)
  }

  const segments = () => {
    const x = []
    Object.keys(swiperItems).forEach((key, index) => {
      addSegs(x, swiperItems[key], index)
    })
    return <div className={'seg-container'}  >{x}</div>
  }

  const content = () => {
    if (showFullPage && fullPageType) {
      return <FullPageContent fullPageType={fullPageType} handleCancel={framework_handlers.handleShow_fullPage} clickOriginType={clickOriginTypes.iconGrid} />
    } else {
      switch (groupType) {
        case gEnums.groupTypes.swipeableTab:
          return <div className={g_cns.app_swipe_container}>
            <div className={'app-swipe-menu st'}>{tabMenu()}</div>
            <div className={'app-swipe-content h100_of ' + swipeContentType}>{swiperItems && swipeableContent()}</div>
          </div>
        case gEnums.groupTypes.swipeableGroup:
          return <div className={g_cns.app_swipe_container}>
            <div className={'app-swipe-content h100_of sg'}>{swiperItems && swipeableContent()}</div>
          </div>
        case gEnums.groupTypes.accordion:
          return <div className={'app-accordion-container'}>
            <div className={'app-accordion-content sa'}>{swiperItems && verticalMenu()}</div>
          </div>
        case gEnums.groupTypes.schedule:
        case gEnums.groupTypes.scheduleTop:
          return <div className={'app-segment-container'}>
            <div className={'app-segment-content sc'}>{'SCHEDULE'}</div>
          </div>
        case gEnums.groupTypes.segment:
          return <div className={'app-segment-container'}>
            <div className={'app-segment-content se'}>{swiperItems && segments()}</div>
          </div>
        default:
          return <div className={g_cns.app_swipe_container}>
            <div className={'app-swipe-menu'}>{tabMenu()}</div>
            <div className={'app-swipe-content h100_of ' + swipeContentType}>{swiperItems && swipeableContent()}</div>
          </div>
      }
    }

  }

  return content()
}


const UiSwiperWithProvider = (props) => {
  const { keyy, swipeContentType, swipeItems, view, fn_getContent, fromAppUserProfile, layoutType, isAdminHidden } = props
  const uk = uniqueKey('swp', keyy)
  return <SwiperProvider
    keyy={uk}
    swipeItems={swipeItems}
    view={view}
    swipeContentType={swipeContentType}
    fn_getContent={fn_getContent}
    layoutType={layoutType}
    isAdminHidden={isAdminHidden}
  >
    <UiSwiper keyy={uniqueKey(keyy)} fromAppUserProfile={fromAppUserProfile} />
  </SwiperProvider>
}

export { UiSwiper, UiSwiperWithProvider };

