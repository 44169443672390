import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { createInitials } from '../../../../global/common/dataAdjust';
import { formatItem, formatTypes, timeConvertor } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import { useElementOnScreen } from '../../../../global/components/sem/visibleElements';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext, _resultsProp, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext';
import { _matchLsTypes } from '../../cnr/reducers/SportsDataReducer';
import { CreateSportsContext } from '../../create/CreateSports';
import { getSectionColor } from '../../create/sectionColors';
import MatchScoreDisplay from './MatchScoreDisplay';
import MatchScoreTable from './MatchScoreTable';
import { MatchSummaryContext } from './MatchSummary';
import { sportsMatchViewTypes } from './MatchWrapper';
import PoolMatch from './PoolMatch';
import MatchOptions from './MatchOptions';

const key_match = 'ms-match-score'
const key_away = 'ms-match-away'
const key_home = 'ms-match-home'
const key_loc = 'ms-match-loc'
const _usePlayoff = false
const _opponentOnly = false
const _showTeamAsHome = false

const MatchSingle = (props) => {

  // match is the actual match
  // current match is the selectedMatch
  const { match, teamKey, playoffDates, tournamentType, sportsMatchViewType, forOverview } = props
  let { wcn } = props

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state } = states
  const { appUsers } = appUser_state ?? {}
  const { pathViews } = paps_state ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { itemAccess } = appUserAccess ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { sportsKey, mediaMatches, matches_info, useScoreBlock, useScoreTeamBlock, teams_info, rtSettings, showRankingsInStandings } = sportsSeason_state ?? {}
  const { teams } = teams_info ?? {}
  const { rtMatchDataView } = rtSettings ?? {}
  const { _matchStatusGroups } = mediaMatches ? mediaMatches : {}
  const { allUpdates } = _matchStatusGroups ? _matchStatusGroups : {}  // sportsSeasonContex  

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const { matchScheduleType, isTournament, selectedOpponent } = seasonMatches_state ?? {}
  const { handleMatch_editClick, handleFilter_matches } = seasonMatches_handlers ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, match_edit, showRtScoring, showMatchScoreTable, matchAllows, showMatchOptions, matchItemKey, realtimeMatchData } = sportsMatch_state ?? {}
  const { edit: _allowEdit, scores: _allowScores, matchAccessLevel } = matchAllows ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext)
  const _allowMatchClick = !createSportsContext

  // matchSummaryContext
  const matchSummaryContext = useContext(MatchSummaryContext)
  const { matchSummary_state } = matchSummaryContext ?? {}
  const { isSummary } = matchSummary_state ?? {}

  const [mediaResults, setMediaResults] = useState()

  const { match_rt } = realtimeMatchData ?? {}

  const [divRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 1.0 })

  // match_true
  const {
    _itemKey,
    _flipHa,
    key,
    away,
    team,
    opponent,
    cancelled,
    home,
    isConsolation,
    isPlayoff,
    location,
    ls,
    matchNumber,
    pending,
    poolKey,
    postponed,
    results_edit,
    _status,
    seeds,
    startDate,
    startTime,
  } = match_true ?? {}

  const results = match_true ? match_true[_resultsProp] : undefined;

  const { isMissing, isPast, isBye } = _status ?? {}

  let isPending = (!home || !away) ? true : false

  const { home: home_pending, away: away_pending } = pending ?? {}
  const _isPlayoff = isPlayoff && _usePlayoff ? true : false
  const _rstz = results_edit ? results_edit : results
  const { complete } = _rstz ?? {}
  const { winner, hasScores, score, scoreDisplays, scoreDisplays_flip, tied } = _rstz ?? {}
  const _scoreDisplays = _flipHa ? scoreDisplays_flip : scoreDisplays
  const { normal: scoreDisplay, scores: scoreDisplay_scores } = _scoreDisplays ?? {}

  const _updatedResults = allUpdates && allUpdates[_itemKey]
  const _hasResults = _rstz ? true : false

  const _showMatchOptions = showMatchOptions && matchItemKey === _itemKey

  const teamScores = {
    away: {
      score: score ? score.away : useScoreBlock ? '' : '-',
      color: score && score.away > score.home ? 'green' : 'grey',
      winner: score && score.away > score.home ? 'winner' : ''
    },
    home: {
      score: score ? score.home : useScoreBlock ? '' : '-',
      color: score && score.home > score.away ? 'green' : 'grey',
      winner: score && score.home > score.away ? 'winner' : ''
    }
  }

  let _ls = ls

  if (isPlayoff) {
    _ls = _.startCase(poolKey)
    if (isConsolation) {
      _ls = _ls.replace('Finals', 'Consulation')
    } else if (isPlayoff) {
      if (poolKey !== 'finals') {
        _ls += ' - #' + matchNumber
      }
    }
  }

  let lsColor;

  switch (match._matchCollectionKey) {
    case _matchLsTypes.exhibition:
      lsColor = 'orange'
      break;
    case _matchLsTypes.tournament:
      lsColor = 'purple'
      _ls = 'Tournament'
      break;
    case _matchLsTypes.nonDistrict:
      lsColor = 'grey'
      break;
    case _matchLsTypes.non:
    case 'non':
      lsColor = 'brown'
      break;
    default:
      lsColor = isPlayoff ? 'grey' : 'blue'
  }

  const _teamKey = teamKey ? teamKey : pathViews.teams
  const teamOnly = teamKey || (pathViews && pathViews.teams) ? true : false
  const matchOnly = pathViews && pathViews.matches ? true : false

  useEffect(() => {
    if (_showTeamAsHome) {
      if (match && match.home && match.home.id === _teamKey) {
        match.opponent = match.away
        match.team = match.home
      } else if (match && match.away && match.away.id === _teamKey) {
        match.opponent = match.home
        match.team = match.away
      }
    }
    sportsMatch_handlers.handleSet_trueMatch(match)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_edit, match]);

  useEffect(() => {
    // these are all of the realtimeMatches
    if (_updatedResults) {
      const { results: _resultsMedia } = _updatedResults
      if (_resultsMedia) {
        setMediaResults(_resultsMedia)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_updatedResults]);

  const handleShow_matchOptions = (e) => {
    e && e.stopPropagation()
    sportsMatch_handlers.handleShow_matchOptions(match_true)
  }

  const handleFilterOpponent = (e, t) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    const opponentKey = match[t].id
    if (_teamKey !== opponentKey) {
      handleFilter_matches({ selectedOpponent: selectedOpponent ? null : opponentKey })
    }
  }

  const teamSetLabel = (score, t) => <Label className={matchScheduleType === matchScheduleTypes.latest ? 'team-score-' + t : t} key={uniqueKey('ms', 'tsl', t)} size={'tiny'}>{score.score}</Label>
  const playoffIcon = () => <Icon color={'grey'} name={'caret right'} />
  const locationIcon = (home) => <Icon color={home.id === pathViews.teams ? 'teal' : 'orange'} name={home.id === _teamKey ? 'home' : 'bus'} />
  const locationPlayoffIcon = () => <Icon color={'grey'} name={'map marker alternate'} />
  const trophyIcon = () => <Icon color={'grey'} name={'trophy'} />
  const seedLabel = (seed, section) => <Label size={'mini'} circular color={getSectionColor(section)}>{seed}</Label>

  const handle_matchClick = (key, d) => {
    if (_allowMatchClick) {
      if (matchScheduleType === matchScheduleTypes.latest) {
        handleMatch_editClick({ key: 'matches' }, key, null, d)
      } else {
        handleMatch_editClick({ key: 'matches' }, key, null, d)
      }
    }
  }

  const _matchCns = {
    awayTeam: 'match-away',
    homeTeam: 'match-home',
    opponentFull: 'match-opponent-full',
    opponent: 'match-opponent',
    matchDate: 'match-date',
    sectionLevel: 'match-sl',
    lastDate: 'match-last-date',
    status: 'match-score-status',
    awayScore: 'match-away-score',
    homeScore: 'match-home-score',
    locationIcon: 'match-location',
    trophy: 'match-trophy',
    result: 'match-result',
    media: 'match-media',
  }

  const divsToShow = {
    dateAndSection: (teamOnly || matchOnly) && !_isPlayoff
  }

  const scoreDiv = (value, cn, icon) => {
    if (icon) {
      return <div key={uniqueKey('ms', 'sd', _itemKey)} className={cn}><Icon name={icon} /></div>
    } else {
      return <div key={uniqueKey('ms', 'sd', _itemKey)} className={cn}>{value}</div>
    }
  }

  const teamScoreBlockDiv = (display, _opponent, _matchIcon) => {
    const divs = []
    divs.push(scoreDiv(_opponent, _matchCns.opponentFull))
    divs.push(scoreDiv(null, _matchCns.locationIcon, _matchIcon))
    divs.push(scoreDiv(display, _matchCns.result))
    return divs
  }

  const scoreBlockDiv = () => {
    const divs = []
    divs.push(<div key={uniqueKey('ms', 'sbd', _itemKey, 1)} className={_matchCns.awayScore}>{teamScores.away.score}</div>)
    divs.push(<div key={uniqueKey('ms', 'sbd', _itemKey, 2)} className={_matchCns.homeScore}>{teamScores.home.score}</div>)
    divs.push(<div key={uniqueKey('ms', 'sbd', _itemKey, 3)} className={_matchCns.status}>{complete ? 'Final' : startTime}</div>)
    return divs
  }

  const matchInProgressDisplay = (display) => <MatchScoreDisplay keyy={key} display={display} origin={'matchSingle'} />

  const getOpponent = () => {
    let opponent;
    if (home && home.id && away && away.id) {
      if (home && home.id && _teamKey === home.id) {
        opponent = away.name
      } else if (away && away.id && _teamKey === away.id) {
        opponent = home.name
      }
    }
    return opponent
  }

  /**
   * 
   * @param {boolean} asLabel 
   * @param {boolean} allowScoreEdits 
   * @returns the score display for the match
   */
  const matchDisplayDiv = (asLabel) => {

    const _allowScore_edits = _allowScores && _allowMatchClick && (sportsMatchViewType !== sportsMatchViewTypes.singleLevel) && !forOverview

    const _opponent = getOpponent()
    const _matchIcon = home && home.id === _teamKey ? 'home' : 'bus'

    let cn_matchDisplay = 'match-display'
    if (mediaResults) { cn_matchDisplay += ' mmr' }

    const _start_time = startTime ? startTime : '07:00 PM'

    let _startTime;
    const { hour, min } = _start_time ? timeConvertor(_start_time) : {}
    const dt = new Date()
    hour && dt.setHours(hour)
    min && dt.setMinutes(min)
    _startTime = dt ? formatItem(formatTypes.ampmShort, dt) : '--'

    let display = scoreDisplay ? scoreDisplay : _startTime

    if (postponed) { display = 'ppd' }
    if (cancelled) { display = 'canc' }
    if (!display) { display = 'res' }

    if (teamOnly && complete) {
      if (useScoreTeamBlock) {
        return teamScoreBlockDiv(display, _opponent, _matchIcon)
      } else {
        if (asLabel) {
          return <div key={uniqueKey(key_match, key)} className={cn_matchDisplay} ><Label onClick={(e) => { _allowScore_edits && handleShow_matchOptions(e) }}>{display}</Label></div>
        } else {
          return <div key={uniqueKey(key_match, key)} className={cn_matchDisplay} onClick={(e) => { _allowScore_edits && handleShow_matchOptions(e) }}>{display}</div>
        }
      }
    } else {
      if (useScoreBlock) {
        return scoreBlockDiv()
      } else {
        switch (sportsMatchViewType) {
          case sportsMatchViewTypes.singleLevel:
            if (_hasResults) {
              return matchInProgressDisplay('Final', _allowScore_edits)
            } else {
              return matchInProgressDisplay(display, _allowScore_edits)
            }
          default:
            return matchInProgressDisplay(display, _allowScore_edits)
        }
      }
    }
  }

  const getDisplayScores = (score) => {
    const numbers = score.split(',').map(item => item.trim());

    const highlightGreaterScore = number => {
      const parts = number.split('-');
      const firstScore = parseInt(parts[0]);
      const secondScore = parseInt(parts[1]);
      if (firstScore >= 25 || secondScore >= 25) {
        if (firstScore > secondScore) {
          return `<span class="highlight">${number}</span>`;
        } else {
          return `<span class="highlight">${secondScore}-${firstScore}</span>`;
        }
      } else {
        return number;
      }
    };

    const coloredNumbers = numbers.map(number => highlightGreaterScore(number));
    const newText = coloredNumbers.join(', ');

    return newText
  }

  const matchDisplayScores = (scores) => <div key={uniqueKey(key_away, 'md', key)} className={'match-scores'}><span>{scores}</span></div>
  const rankLabel = (rank) => <Label className={'lbl-rnk'} circular size='mini'>{rank}</Label>

  const teamResult = (teamType) => {
    if (_hasResults) {
      switch (sportsMatchViewType) {
        case sportsMatchViewTypes.singleLevel:
          const _winner = _rstz && _rstz.winner ? _rstz.winner : null
          let isWinner;
          switch (teamType) {
            case 'away':
              isWinner = _winner && _winner.id === away.id
              break;
            case 'home':
              isWinner = _winner && _winner.id === home.id
              break;
            default:
            // nothing 
          }
          return <Label className='msr' size='mini' color={isWinner ? 'green' : 'grey'}>{teamScores[teamType].score}</Label>
        default:
        // nothing
      }
    }
  }

  /**
  * 
  * @param {string} cnHome
  * @param {string} key 
  * @param {string} rank 
  * @returns Name/Seed or Section
  */
  const awayDiv = (cnAway, key, rank) => {
    const _away = team ? team : away
    if (isPlayoff) {
      return <div key={uniqueKey(key_away, key)} className={cnAway}>
        {!_flipHa && seedLabel(_away.seed ? _away.seed : '-', _away.sections)}
        <div>{_away.name}</div>
        {_flipHa && seedLabel(_away.seed ? _away.seed : '-', _away.sections)}
      </div>
    } else {
      switch (sportsMatchViewType) {
        case sportsMatchViewTypes.singleLevel:
          return <div key={uniqueKey(key_away, key)} className={cnAway} onClick={(e) => { _teamKey && handleFilterOpponent(e, 'away') }}>
            <div>{showRankingsInStandings && rank ? rankLabel(rank) : null}</div>
            <div>{_away.name}</div>
            {teamResult('away')}
          </div>
        default:
          return <div key={uniqueKey(key_away, key)} className={cnAway} onClick={(e) => { _teamKey && handleFilterOpponent(e, 'away') }}>
            <div>{_away.name}</div>
            <div>{showRankingsInStandings && rank ? rankLabel(rank) : null}</div>
          </div >
      }
    }
  }

  /**
   * 
   * @param {string} cnHome
   * @param {string} key 
   * @param {string} rank 
   * @returns Name/Seed or Section
   */
  const homeDiv = (cnHome, key, rank) => {
    const _home = opponent ? opponent : home
    if (isPlayoff) {
      switch (sportsMatchViewType) {
        case sportsMatchViewTypes.singleLevel:
          return <div key={uniqueKey(key_home, key)} className={cnHome}>
            {seedLabel(_home.seed ? _home.seed : '-', _home.sections)}
            <div>{_home.name}</div>
          </div>
        default:
          return <div key={uniqueKey(key_home, key)} className={cnHome}>
            {_flipHa && seedLabel(_home.seed ? _home.seed : '-', _home.sections)}
            <div>{_home.name}</div>
            {!_flipHa && seedLabel(_home.seed ? _home.seed : '-', _home.sections)}
          </div>
      }
    } else {
      return <div key={uniqueKey(key_home, key)} className={cnHome} onClick={(e) => { _teamKey && handleFilterOpponent(e, 'home') }}>
        <div>{showRankingsInStandings && rank ? rankLabel(rank) : null}</div>
        <div>{_home.name}</div>
        {teamResult('home')}
      </div>
    }
  }

  const pendingAwayDiv = (pending, cnAway, key) => {
    const { poolName, matchNumber, place, originalSeed } = pending
    const value = poolName + ' #' + matchNumber + '/' + place
    return <div key={uniqueKey(key_away, key)} className={cnAway}>{seedLabel(originalSeed ? originalSeed : '-')}{value}</div>
  }

  const pendingHomeDiv = (pending, cnHome, key) => {
    const { poolName, matchNumber, place, originalSeed } = pending
    const value = poolName + ' #' + matchNumber + '/' + place
    switch (sportsMatchViewType) {
      case sportsMatchViewTypes.singleLevel:
        return <div key={uniqueKey(key_home, key)} className={cnHome}>{seedLabel(originalSeed ? originalSeed : '-')}{value}</div>
      default:
        return <div key={uniqueKey(key_home, key)} className={cnHome}>{value}{seedLabel(originalSeed ? originalSeed : '-')}</div>
    }
  }

  const playoffDiv = () => {
    const playoffCaption = poolKey === 'finals' ? '' : '/#' + matchNumber
    let loc = location ? location.name : null
    if (!loc) {
      if (isPlayoff) {
        loc = 'TBD'
      } else {
        loc = home ? home.name : '---'
      }
    }
    const divs = []
    divs.push(<div key={uniqueKey(key_loc, _itemKey, 'ipl')} className={_matchCns.trophy}>{trophyIcon()}{'Playoff'}</div>)
    divs.push(<div key={uniqueKey(key_loc, _itemKey, 'mmr')} className={_matchCns.media} onClick={(e) => { _allowEdit && _updatedResults && handleShow_matchOptions(e) }}>{_updatedResults ? 'mr' : ''}</div>)
    divs.push(<div key={uniqueKey(key_loc, _itemKey)} className={_matchCns.locationIcon}>{locationPlayoffIcon()}{loc}</div>)
    return divs
  }

  const dateAndSectionDivs = () => {
    return [
      <div key={uniqueKey('ms', 'sm', 1)} className={_matchCns.matchDate}>{teamOnly ? locationIcon(home) : null} {formatItem(formatTypes.dateDayMonth, startDate)}</div>,
      <div key={uniqueKey('ms', 'sm', 2)} className={_matchCns.sectionLevel}><Label color={lsColor} size={'mini'}>{_ls}</Label></div>
    ]
  }

  const matchScoreTable = () => <div key={uniqueKey('full', 'score', key_match)} className={'match-full-score'}>
    <MatchScoreTable />
  </div>

  const match_away = (rank) => {
    if (away) {
      return awayDiv(_matchCns.awayTeam, key, rank)
    } else if (away_pending) {
      return pendingAwayDiv(away_pending, _matchCns.awayTeam, key)
    }
  }

  const match_home = (rank) => {
    if (home) {
      return homeDiv(_matchCns.homeTeam, key, rank)
    } else if (home_pending) {
      return pendingHomeDiv(home_pending, _matchCns.homeTeam, key)
    }
  }

  const mediaResultsDiv = () => {
    const { score, scores } = mediaResults
    const { away, home } = score
    return <div className='media-media'>
      <div>{away}</div>
      <div>{home}</div>
    </div>
  }

  /**
   * 
   * @param {object} ranks 
   * @param {boolean} allowMatchEdit 
   * @returns 
   */
  const match_sectionSingleLine = (ranks) => {
    const m = []
    isPlayoff && m.push(<div key={uniqueKey('ms', 'sm', 2)} className={_matchCns.sectionLevel}><Label color={lsColor} size={'mini'}>{_ls}</Label></div>)
    m.push(match_away(ranks.away))
    !_isPlayoff && m.push(matchDisplayDiv())
    m.push(match_home(ranks.home))

    scoreDisplay_scores && m.push(matchDisplayScores(scoreDisplay_scores))

    if (mediaResults) {
      m.push(mediaResultsDiv())
    }

    if (_showMatchOptions) {
      m.push(<MatchOptions />)
    }

    return m
  }

  const match_sectionSingleLevelLine = (ranks, allowMatchEdit) => {
    const m = []
    isPlayoff && m.push(<div key={uniqueKey('ms', 'sm', 2)} className={_matchCns.sectionLevel}><Label color={lsColor} size={'mini'}>{_ls}</Label></div>)
    m.push(match_away(ranks.away))
    !_isPlayoff && m.push(matchDisplayDiv())
    m.push(match_home(ranks.home))
    // if (showRtScoring) {
    //   m.push(<MatchScoreButtons match_rt={match_rt} matchScheduleType={matchScheduleType} />)
    // }
    if (_showMatchOptions) {
      m.push(<MatchOptions />)
    }
    return m
  }

  const match_teamSingleLine = (ranks) => {
    const m = []
    m.push(dateAndSectionDivs())
    m.push(match_away(ranks.away))
    !_isPlayoff && m.push(matchDisplayDiv())
    m.push(match_home(ranks.home))
    scoreDisplay_scores && m.push(matchDisplayScores(scoreDisplay_scores))
    return m
  }

  const ammendCn = (allowMatchEdits, teamIsHome) => {
    if (winner) {
      wcn += winner.id === _teamKey ? ' win' : ' loss'
      wcn += winner.id === home.id ? ' wh' : ' wa'
    }

    if (tied && tied.length === 2) {
      wcn += ' tie'
    }

    if (!complete) { wcn += ' future' }
    if (complete) { wcn += ' complete' }
    if (allowMatchEdits.any) { wcn += ' ame' }
    if (allowMatchEdits.sport) { wcn += ' ase' }
    if (rtMatchDataView && match_rt && !complete) { wcn += ' rt' }
    if (_allowScores && !forOverview) { wcn += ' ae' }
    if (matchAccessLevel) { wcn += ' mal-' + matchAccessLevel }
    if (isBye) { wcn += ' bye' }
    if (isPast) { wcn += ' past' }
    if (isPending) { wcn += ' pending' }
    if (isPlayoff) { wcn += ' playoff' }
    if (_showMatchOptions) { wcn += ' mopts' }
    if (mediaResults) { wcn += ' mmr' }
    if (isMissing) { wcn += ' missing' }

    if (teamIsHome) {
      wcn += ' tih'
    } else {
      wcn += ' tia'
    }

    if (_opponentOnly) { wcn += ' oo' }
    if (rtMatchDataView && showRtScoring) { wcn += ' rtm' }
    if (scoreDisplay_scores && !forOverview) { wcn += ' scrs' }

    return wcn
  }

  const singleMatch = () => {

    const segs = []

    if ((isTournament || playoffDates || match_true.matchNumber) && tournamentType) {
      const pm = <PoolMatch match={match_true} match_edit={match_edit} wcn={wcn} />
      segs.push(pm)
      return segs
    }

    const m = []

    if ((home || home_pending) && (away || away_pending)) {

      const _home = home_pending ? home_pending : home
      const _away = away_pending ? away_pending : away

      const homeSeed = seeds ? seeds['home'] : null
      const awaySeed = seeds ? seeds['away'] : null

      const _opponent = getOpponent()

      const teamIsHome = _home.id === _teamKey
      const away_upset = awaySeed > homeSeed ? true : false

      const allowMatchEdits = {
        match: (itemAccess && ((_home && itemAccess.includes(_home.id)) || (_away && itemAccess.includes(_away.id)))) ? true : false,
        sport: (itemAccess && itemAccess.includes(sportsKey)) ? true : false,
        any: false
      }

      if (allowMatchEdits.match || allowMatchEdits.sport) { allowMatchEdits.any = true }

      const ranks = {
        home: null,
        away: null
      }

      if (!isPast && _home && _home.id && teams && teams[_home.id] && teams[_home.id].currentRank) { ranks.home = teams[_home.id].currentRank }
      if (!isPast && _away && _away.id && teams && teams[_away.id] && teams[_away.id].currentRank) { ranks.away = teams[_away.id].currentRank }

      switch (matchScheduleType) {
        case matchScheduleTypes.latest:
          const _opponentName = createInitials(_opponent, 3)
          m.push(matchDisplayDiv())
          { !isSummary && m.push(<div key={uniqueKey('ms', 'ml', _itemKey)} className={_matchCns.lastDate}>{formatItem(formatTypes.dateDayMonth, startDate)}</div>) }
          m.push(<div key={uniqueKey('ms', 'ml', 'opp', _itemKey)} className={_matchCns.opponent}>{_opponentName}</div>)
          break;

        default:
          if (winner && winner.id === away.id) {
            _matchCns.awayTeam += ' winner'
            if (_isPlayoff && away_upset) { _matchCns.awayTeam += ' upset' }
          }

          if (winner && winner.id === home.id) {
            _matchCns.homeTeam += ' winner'
          }

          if (teamOnly) {
            m.push(match_teamSingleLine(ranks))
          } else {
            switch (sportsMatchViewType) {
              case sportsMatchViewTypes.singleLevel:
                m.push(match_sectionSingleLevelLine(ranks, allowMatchEdits.any))
                break;
              default:
                m.push(match_sectionSingleLine(ranks, allowMatchEdits.any))
            }
          }

          // if (match_rt_scorer) { m.push(match_rt_scorer) }

          if (isPlayoff) {
            m.push(playoffDiv(true))
            hasScores && showMatchScoreTable && m.push(matchScoreTable())
          }
      }

      ammendCn(allowMatchEdits, teamIsHome)

      segs.push(<div
        ref={divRef}
        key={uniqueKey('ms', 'd', _itemKey)}
        className={wcn}
        onClick={() => { handle_matchClick(_itemKey, { itemData: match_true }) }}>
        {m}
      </div>)
    }

    return segs
  }

  return match_true ? singleMatch() : <div>---</div>

}

export default MatchSingle