import _ from 'lodash';
import { calculateByteSize, convertHelpers } from '../../../../global/common/convert';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_add_doc, fs_update_doc } from '../../../../global/firestoreData/appData/fsData';
import { _sportsOriginTypes } from './SportsReducer';
import { getSportsData_parentKeyCollections } from './reducerHelpers/seasonSportsData';

const dataItems_organization = {
  schools: { collectionName: 'schools' },
}

const rts = {
  handleResults_parentKeyCollections: 'handleResults_parentKeyCollections',
  handleSet_sportsOrganization: 'handleSet_sportsOrganization',
  handleInit_sportsOrganization: 'handleInit_sportsOrganization',
  handleUpdateDistrictSports: 'handleUpdateDistrictSports',
  handleUpdate_organizationSchools: 'handleUpdate_organizationSchools',
}

const _allowUpdate = true

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsOrganizationReducer = (state, action) => {

  const {
    pathViews,
    _cacheData,
    database_fns
  } = state

  const { type, dispatch } = action

  const { organization_data: organization_data_cache } = _cacheData ?? {}

  const sportsOrganization_handlers = sportsOrganizationHandlers(dispatch)
  const { handleResults_parentKeyCollections, handleSet_sportsOrganization } = sportsOrganization_handlers

  switch (type) {

    case rts.handleInit_sportsOrganization:

      const _refPath = createRefPath_event(action.pathViews, ['organizations', action.pathViews.organizations])
      database_fns.get_doc({ refPath: _refPath, callback: handleSet_sportsOrganization })

      const additionalDataParents = []

      const pkcProps = {
        pathViews: action.pathViews,
        dataItems: dataItems_organization,
        dataParents: action.dataParents,
        additionalDataParents,
        handleSetDataResults: handleResults_parentKeyCollections,
        data_cache: organization_data_cache,
        database_fns,
      }

      getSportsData_parentKeyCollections(_sportsOriginTypes.organization, pkcProps)

      return { ...state, pathViews: action.pathViews }

    case rts.handleSet_sportsOrganization:
      const { organization } = action
      const { sports, districts } = organization ? organization : {}
      return { ...state, organization, sports, districts }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleResults_parentKeyCollections:

      const { data_pure, parentDataKeys } = action

      const _rstz = {}

      Object.keys(dataItems_organization).forEach(diKey => {
        const itemPure = data_pure[diKey]
        const { data: dataResult } = itemPure
        _rstz[diKey] = dataResult[diKey]
        convertHelpers.createItemKeys(_rstz[diKey])
      })

      return {
        ...state,
        ..._rstz,
        organization_data: _rstz,
        parentDataKeys,
      }

    case rts.handleUpdate_organizationSchools:
      // pathViews, sportsYearCollectionItem, selectedDistrictKey, selectedDistrictSchools, schools
      updateOrganizationSchools(pathViews, action.sportsYearCollectionItem, action.selectedDistrictKey, action.selectedDistrictSchools, action.schools).then(res => {
        action.cb && action.cb()
      })
      return { ...state }

    case rts.handleUpdateDistrictSports:
      updateDistrictsPromise(pathViews, action.districts).then(
        action.callback()
      )
      return { ...state }

    default:
      return { ...state }
  }
}

export const sportsOrganizationHandlers = (dispatch) => {
  return {
    handleInit_sportsOrganization: (pathViews, dataParents) => { dispatch({ type: rts.handleInit_sportsOrganization, dispatch, pathViews, dataParents }) },
    handleResults_parentKeyCollections: (data_pure, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys) => { dispatch({ type: rts.handleResults_parentKeyCollections, dispatch, data_pure, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys }) },
    handleSet_sportsOrganization: (organization) => { dispatch({ type: rts.handleSet_sportsOrganization, dispatch, organization }) },
    handleUpdateDistrictSports: (districts, callback) => { dispatch({ type: rts.handleUpdateDistrictSports, dispatch, districts, callback }) },
    handleUpdate_organizationSchools: (sportsYearCollectionItem, selectedDistrictKey, selectedDistrictSchools, schools, cb) => { dispatch({ type: rts.handleUpdate_organizationSchools, dispatch, sportsYearCollectionItem, selectedDistrictKey, selectedDistrictSchools, schools, cb }) },
  }
}

export const sportsOrganizationInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {} }
};

// const updateDistricts = async (pathViews, districts) => {
//   updateDistrictsPromise(pathViews, districts)
// }

const updateDistrictsPromise = async (pathViews, districts) => {
  const promises = []
  if (districts) {
    Object.keys(districts).forEach(key => {
      const district = districts[key]
      const _refPath = createRefPath_event(pathViews, ['districts', key])
      promises.push(fs_update_doc(_refPath, district, null, true))
    })
  }
  return Promise.all(promises)
}

const updateOrganizationSchools = async (pathViews, sportsYearCollectionItem, selectedDistrictKey, selectedDistrictSchools, schools) => {

  if (selectedDistrictSchools && selectedDistrictKey) {

    const _schools_existing = {}
    const _schools_new = {}

    selectedDistrictSchools.forEach(school => {
      const { name, fullName, address, phoneNumber, schoolKey, webSite, sportLevels, contacts, enrollmentTotal, enrollmentMale, enrollmentFemale } = school ?? {}
      const _schoolExistingKey = _.findKey(schools, function (s) { return (s.name === name) })
      const _school = {
        name: _schoolExistingKey ? schools[_schoolExistingKey].name : name,
        fullName,
        webKey: schoolKey,
      }
      if (address) { _school.address = address }
      if (phoneNumber) { _school.phoneNumber = phoneNumber }
      if (webSite) { _school.webSite = webSite }
      if (contacts) { _school.contacts = contacts }
      if (enrollmentTotal) { _school.enrollmentTotal = parseInt(enrollmentTotal.replace(',', ''), 10) }
      if (enrollmentMale) { _school.enrollmentMale = parseInt(enrollmentMale.replace(',', ''), 10) }
      if (enrollmentFemale) { _school.enrollmentFemale = parseInt(enrollmentFemale.replace(',', ''), 10) }
      if (sportLevels) { _school.sportLevels = sportLevels }
      if (sportLevels) {
        if (_schoolExistingKey) {
          _schools_existing[_schoolExistingKey] = _school
        } else {
          _schools_new[createFsDocKey()] = _school
        }
      }
    })

    console.log('_schools_existing', _schools_existing)
    console.log('_schools_new', _schools_new)

    // console.log('_schools_existing', _.sortBy(_schools_existing, 'name'))
    // console.log('_schools_new', _.sortBy(_schools_new, 'name'))
    // console.log('_schools_new', _.sortBy(_schools_new, 'name'))

    // const xxx = _.filter(_schools_existing, (s) => s && s.sportLevels && s.sportLevels[2024] && s.sportLevels[2024].mensVolleyball === '3A')

    // const allSportNames = _.sortBy(_.uniq(_.flatMap(_schools_existing, school => {
    //   const sportsForYear = _.get(school, 'sportLevels.2024', {});
    //   return _.keys(sportsForYear);
    // })));

    // const allSportLevels = _.sortBy(_.uniq(_.flatMap(_schools_existing, school => {
    //   const sportsForYear = _.get(school, 'sportLevels.2024', {});
    //   return _.values(sportsForYear);
    // })));

    // console.log('allSportNames', allSportNames)
    // console.log('allSportLevels', allSportLevels)

    const sizeAllowance = calculateByteSize(_schools_existing)
    const { valid } = sizeAllowance ?? {}

    if (valid) {
      if (_schools_existing && Object.keys(_schools_existing).length > 0) { await updateSchools(pathViews, sportsYearCollectionItem, selectedDistrictKey, _schools_existing) }
      // if (_schools_new && Object.keys(_schools_new).length > 0) { await updateSchools(pathViews, sportsYearCollectionItem, selectedDistrictKey, _schools_new) }
      return true
    }



    // if (_schools_new) {
    //   Object.keys(_schools_new).forEach(nk => {
    //     const school_new = _schools_new[nk]
    //     const _ref = createRefPath_event(pathViews, ['teams', nk])
    //     schoolNews.push({
    //       _ref,
    //       school_new
    //     })
    //     // fs_update_doc(_ref)
    //   })
    // }

    // console.log('schoolUpdates', schoolUpdates)
    // console.log('schoolNews', schoolNews)
  }
}

const updateSchools = async (pathViews, sportsYearCollectionItem, selectedDistrictKey, schoolsToUpdate) => {

  const _collectionName = 'schoolsByState'

  const callback = (res) => {
    console.log('res', res)
  }

  const dataToUpdate = { schools: {} }

  Object.keys(schoolsToUpdate).forEach(nk => {
    const school_existing = schoolsToUpdate[nk]
    dataToUpdate.schools[nk] = school_existing
    dataToUpdate.parentKeys = {
      organizations: pathViews.organizations,
      districts: selectedDistrictKey,
    }
  })

  if (!sportsYearCollectionItem) {
    const _refPath = createRefPath_event(pathViews, [_collectionName])
    console.log('dataToAdd', _refPath, dataToUpdate)
    if (_allowUpdate) {
      await fs_add_doc(_refPath, dataToUpdate, callback)
    }
  } else {
    const { _itemKey } = sportsYearCollectionItem ?? {}
    const _refPath = createRefPath_event(pathViews, [_collectionName, _itemKey])
    console.log('dataToUpdate', _refPath, dataToUpdate)
    if (_allowUpdate) {
      await fs_update_doc(_refPath, dataToUpdate, callback)
    }
  }
}
