import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Button, Icon } from 'semantic-ui-react'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { uniqueKey } from '../../common/keys'
import { currentHelpers } from '../../redirection/current'
import Wrapper from '../../wrappers/Wrapper'
import { DimmerPending } from '../alerts/DimmerWrap'
import { motion } from 'framer-motion';

const _useSwipeable = true
const _useOnChange = false
const _useMotion = false

/**
 * 
 * @param {object} props (swiper)
 * @description swiper is consists of 2 objects (sidebarItems and swipeItems) and a function (handleSwiperIndexChange)
 * sidebarItems [items, selected].
 * selected (index, item, itemKey, visible)
 * swipeItems is an array of (caption, index, key and content)
 * @returns 
 */
const SwiperWrapper = (props) => {

  const { swiper, extraButtons, disableSwipe } = props ?? {}
  const { swipeItems, sidebarItems, handleSwiperIndexChange, searchComponent } = swiper ?? {}
  const { selected, keyAs } = sidebarItems ?? {}
  const { index } = selected ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states
  const { storageItemPage } = paps_state ?? {}

  const [currentIndex, setCurrentIndex] = useState(index ? index : 0)
  const [changing, setChanging] = useState()

  useEffect(() => {
    setCurrentIndex(index)
    //  -disable-next-line react-hooks/exhaustive-deps 
  }, [index]);

  /**
   * Triggers when the tab is clicked or changed
   * @param {number} index 
   * @param {object} item 
   */
  const handleChange = (index, item) => {
    const _item = item ? item : swipeItems[index]
    setCurrentIndex(index)
    handleSwiperIndexChange && handleSwiperIndexChange(index, _item)
  }

  /**
   * 
   * @param {object} e 
   * @param {object} item 
   * @param {number} index 
   */
  const handleButtonClick = (e, item, index) => {
    _useOnChange && setChanging(true)
    currentHelpers.storageItem_set(storageItemPage + '_ms_' + keyAs, item.key)
    handleChange(index, item)
  }

  const handleIndexChange = (index, indexLatest, meta) => meta && meta.reason === 'swipe' && handleChange(index)
  const handleGetItemCaption = (item) => _.startCase(item)
  const handleExtraClick = (eb) => eb.oc(eb.clickProps)
  const handleTransitionEnd = () => setChanging(false)

  const slides_nonMotion = () => swipeItems && swipeItems.map((item, index) => (item.content))

  const slides_motion = () =>
    swipeItems &&
    swipeItems.map((item, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0 }}
        animate={index === currentIndex ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {index === currentIndex && item.content}
      </motion.div>
    ));

  const slides = () => _useMotion ? slides_motion() : slides_nonMotion()

  /**
   * 
   * @returns the buttons from the top, one for each item
   */
  const buttons = () => swipeItems && swipeItems.map((item, index) => (
    <Button
      key={uniqueKey('sw', 'btn', index)}
      color={index === currentIndex ? 'blue' : null}
      className={index === currentIndex ? 'btn-sel' : null}
      onClick={e => handleButtonClick(e, item, index)}>
      {item.caption ? item.caption : handleGetItemCaption(item.key)}
    </Button>))

  const button_extra = (eb) => <Button icon inverted={eb.inverted}
    key={uniqueKey('sw', 'btn', 's', eb.icon)}
    onClick={e => handleExtraClick(eb)}>
    <Icon name={eb.icon} />
  </Button>

  const extras = () => extraButtons.map(eb => (button_extra(eb)))

  const swipeableButtons = () => <div className='ms-button-group'>
    <Button.Group className='swipe-tab-container sw' size='mini'>
      {buttons()}
      {extraButtons && extras()}
    </Button.Group>
  </div>

  const swipeableViews = () => searchComponent ? searchComponent : <SwipeableViews
    enableMouseEvents
    className='swiper-sub'
    index={currentIndex}
    onChangeIndex={handleIndexChange}
    onTransitionEnd={handleTransitionEnd}
    disabled={disableSwipe}
    animateTransitions={false}
  >
    {_.isNumber(currentIndex) ? slides() : <div></div>}
  </SwipeableViews>

  const content = () => _useSwipeable ? swipeableViews() : slides()

  const wrapper = () => <Wrapper
    header={swipeableButtons()}
    content={swipeItems ? content() : <DimmerPending />}
    css={{ content: 'killOf' }}
  ></Wrapper>

  return wrapper()

}

export default SwiperWrapper

const SlideContent = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 2 }}
  >
    {children}
  </motion.div>
);