import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Image, Label, Menu, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { getAuthIcon } from '../auth/authPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import { allPageTypes } from '../enums/allPageTypes';
import { gEnums } from '../enums/globalEnums';
import { PageItemOptionsMenu } from '../sidebars/PageItemOptionsMenu';

/**
 * 
 * @returns a navigation menu for the app
 */
const PageNavigation = () => {

  const navigate = useNavigate()

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, appSettings_state, page_state, paps_state } = states
  const { pageSettings, pageNav } = page_state ?? {}
  const { navOptions } = pageNav ?? {}
  const { aps_global, aps_styles, aps_viewItems, aps_views, aps_page, aps_appUserSettings } = pageSettings ?? {}
  const { events: events_aps } = aps_views ?? {}
  const { viewItems: viewItems_events } = events_aps ?? {}
  const { storage_fns } = fns
  const { appUsers } = appUser_state ?? {}

  // appUserAccess
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserSessionKey, appUserSession, accessLevel } = appUserAccess ?? {}
  const { desktopOn } = appSettings_state ?? {}
  const { view, pathViews, lastRootPath } = paps_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { additionalNavigationItems: additionalNavigationItems_fw, desktopMode, homePageCaption } = framework_state ?? {}
  const { handleShow_appBottomSidebar, handleClick_contentItem } = framework_handlers ?? {}
  const desktopView = desktopMode || desktopOn ? true : false

  // pageContext  
  const { navigation, themedItems, pageOptions } = aps_global ?? {}
  const { navigationItems, includeHome, includeSlideout, homeIconImage, useHomeIconImage, showItemActionInNavigation } = navigation ?? {}
  const { showInNavigation, allowGeoList, allowGeoLocation, allowFeeds, allowImageLinks, allowTwitter, allowVideoLinks } = pageOptions ?? {}

  const _showInNavigation = showInNavigation && (allowGeoList || allowGeoLocation || allowFeeds || allowImageLinks || allowTwitter || allowVideoLinks)
  const { appUserCollection, showFavoritesInNavigation, showMyProfileLinkInNavigation, allowChat, showChatInNavigation, allowNotes, showNotesInNavigation } = aps_appUserSettings ?? {}

  const { navigation: navigation_ti } = themedItems ?? {}
  const { iconSize } = navigation_ti ?? {}

  let { showCaptions } = navigation ?? {}

  const [allowedNavigationItems, setAllowedNavigationItems] = useState()
  const [additionalNavigationItems, setAdditionalNavigationItems] = useState()
  const [showPageItemOptions] = useState()

  const additionalCount = additionalNavigationItems ? Object.keys(additionalNavigationItems).length : 0

  const handleGoTo = (opts) => navigate('/' + opts.path)

  const handleMenuItemClick = (item, isAppUser) => {
    const { oc, opts } = item ?? {}
    if (oc) {
      oc(opts)
    } else {
      handleClick_contentItem(item, null, isAppUser)
    }
  }

  useEffect(() => {
    if (navigationItems && viewItems_events) {
      const _allowNavigationItems = []
      navigationItems.forEach(navigationItem => {
        if (viewItems_events[navigationItem]) {
          const vie = viewItems_events[navigationItem]
          const { accessManagement } = vie ?? {}
          const { accessLevel: accessLevel_nav } = accessManagement ?? {}
          if (!accessLevel_nav) {
            _allowNavigationItems.push(navigationItem)
          } else {
            if (accessLevel >= accessLevel_nav) {
              _allowNavigationItems.push(navigationItem)
            }
          }
        }
      })
      setAllowedNavigationItems(_allowNavigationItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (additionalNavigationItems_fw && viewItems_events) {
      setAdditionalNavigationItems(additionalNavigationItems_fw)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalNavigationItems_fw]);


  const handleShowBottomSidebar = () => handleShow_appBottomSidebar(menu_additional())

  const getPagePath = (name) => {
    let pagePath = ''
    let done = false
    const spl = lastRootPath.split('/')
    spl.forEach(sp => {
      if (sp) {
        if (sp === name) {
          pagePath += sp + '/'
          pagePath += pathViews[name]
          done = true
        } else if (!done) {
          pagePath += sp + '/'
        }
      }
    })
    return pagePath
  }

  const pageNavHome = {
    page: null,
    path: null,
    icon: null,
  }

  if (pathViews) {
    Object.keys(pathViews).forEach(pv => {
      if (pv !== view) {
        if (aps_viewItems && aps_viewItems[pv]) {
          const { pageNavigation } = aps_viewItems[pv]
          const { showPageHome, pageHomeIcon } = pageNavigation ?? {}
          if (showPageHome) {
            pageNavHome.path = getPagePath(pv)
            pageNavHome.page = pv
            pageNavHome.icon = pageHomeIcon
          }
        }
      }
    })
  }

  if (desktopView) { showCaptions = true }


  // from view  
  const { layout: layout_page } = aps_page ?? {}
  const { layoutType } = layout_page ?? {}

  const menuSaC = aps_styles ? aps_styles[gEnums.projectStyles.navigation] : {}
  const menuSaC_ai = aps_styles ? aps_styles[gEnums.projectStyles.actionItems] : {}

  if (layoutType === 'icons') { return null }

  const label = (count) => <Label color='blue' floating style={{ top: '-.3em', marginLeft: '-1.8em' }}>{count}</Label>

  const iconOrImage = (item, isAdditional) => {
    const { icon, iconImage, useIconImage } = item.display ?? {}
    const iconImageUrls = storage_fns.getIconUrl(useIconImage, iconImage)
    const { urls } = iconImageUrls ?? {}
    const { full, thumbnail } = urls ?? {}
    const src = thumbnail ? thumbnail : full
    const { name, color } = icon ?? {}
    let _size = iconSize ? iconSize : null
    if (isAdditional) { _size = null }
    if (src) {
      return <Image src={src} alt={iconImage} className={'icon-image'} />
    } else {
      if (name) {
        return <Icon size={_size} name={name.trim()} color={color ? color : null} />
      } else {
        return <Icon size={_size} name={'file alternate'} />
      }
    }
  }

  const iconLabel = (item, count) => {
    const { icon } = item.display ?? {}
    const { name } = icon ? icon : {}
    return <React.Fragment>
      <Icon name={name} />
      <Label circular attached='top right' color={'green'} size={'tiny'} >
        {count}
      </Label>
    </React.Fragment>
  }

  const navMenuItem = (item, il, count, isAppUser, isAdditional) => {

    // IMPORTANT: match the key with the view so that the item will be active    
    let lbl = null

    const { messageCount, key, index, display } = item ?? {}
    const { caption } = display ?? {}

    if (messageCount && (messageCount > 0)) { lbl = label(messageCount) }

    return <Menu.Item
      key={uniqueKey('pn', 'mi', key, index)}
      name={key}
      active={key === paps_state.viewAs}
      onClick={() => { handleMenuItemClick(item, isAppUser) }}
    >
      {!il && iconOrImage(item, isAdditional)}
      {il && iconLabel(item, count)}
      {(showCaptions || isAdditional) && lbl}
      {(showCaptions || isAdditional) && caption && _.startCase(caption)}
    </Menu.Item>
  }

  const menu_additional = () => {
    const mis = []
    Object.keys(additionalNavigationItems).forEach((k, index) => {
      const _ani = additionalNavigationItems[k]
      mis.push(navMenuItem({
        key: allPageTypes.media + index,
        oc: _ani.oc,
        display: { icon: { name: _ani.icon }, caption: _ani.caption },
        opts: _ani.opts
      },
        null,
        null,
        null,
        true))
    })

    return <Menu
      {...menuSaC_ai}
      fluid
      inverted
      vertical
      key={uniqueKey('pn', 'm')} >
      {mis}
    </Menu>
  }

  /** Return a list of menu items for the page navigation */
  const menuItems = () => {

    let count = 0
    const mis = []

    if (includeSlideout && !desktopView) {
      mis.push(navMenuItem({ key: 'slideout', display: { icon: { name: 'content' }, caption: 'options' } }))
      count++
    }

    if (includeHome) {
      mis.push(navMenuItem({ key: paps_state.landingView, display: { icon: { name: 'home' }, caption: paps_state.appHome, iconImage: homeIconImage, useIconImage: useHomeIconImage } }))
      count++
    }

    if (allowedNavigationItems) {
      allowedNavigationItems.forEach((item, index) => {
        let allow = true
        const vi = aps_viewItems && aps_viewItems[item] ? aps_viewItems[item] : {}
        const { display } = vi ?? {}
        const { defaultIcon } = display ?? {}
        const icon = { name: defaultIcon }
        if (allow) {
          count++
          mis.push(navMenuItem({ index, key: item, display: { ...display, icon } }))
        }
      })
    }

    if (pageNavHome.page) {
      const vi = aps_viewItems && aps_viewItems[pageNavHome.page] ? aps_viewItems[pageNavHome.page] : {}
      const { display } = vi ?? {}
      count++
      const _display = homePageCaption ? { ...display, caption: homePageCaption } : display
      mis.push(navMenuItem({
        key: pageNavHome.page,
        display: { ..._display, icon: { name: pageNavHome.icon } },
        opts: pageNavHome,
        oc: handleGoTo
      }))
    }

    if (showFavoritesInNavigation) {
      mis.push(navMenuItem({ key: allPageTypes.favorites, display: { icon: { name: 'star outline' }, caption: 'Favorites' } }))
      count++
    }

    if (showMyProfileLinkInNavigation && appUserSessionKey && appUserSession) {
      const icon = getAuthIcon(appUserAccess ? appUserAccess.accessLevel : 0)
      mis.push(navMenuItem({ key: appUserCollection, display: { icon: { ...icon, color: null }, caption: 'appUser' } }, null, null, true))
      count++
    }

    if (allowNotes && showNotesInNavigation) {
      mis.push(navMenuItem({ key: allPageTypes.notes, display: { icon: { name: 'edit outline' }, caption: 'Notes' } }))
      count++
    }

    if (allowChat && showChatInNavigation) {
      mis.push(navMenuItem({ key: allPageTypes.chat, display: { icon: { name: 'conversation' }, caption: 'Chat' } }))
      count++
    }

    if (showItemActionInNavigation && navOptions) {
      mis.push(navMenuItem({ key: allPageTypes.navOptions, display: { icon: { name: 'options' }, caption: 'Options' } }))
      count++
    }

    if (_showInNavigation) {
      mis.push(navMenuItem({ key: allPageTypes.media, display: { icon: { name: 'newspaper outline' }, caption: 'Media' } }))
      count++
    }

    if (additionalCount > 0) {
      mis.push(navMenuItem({ key: allPageTypes.media, oc: handleShowBottomSidebar, display: { icon: { name: 'content' }, caption: 'More' } }))
      count++
    }

    // mis.push(menuItem({ key: allPageTypes.media, display: { icon: { name: 'newspaper outline' }, caption: 'Media' } }))
    // count++

    // if (navItemOptions) {
    //   mis.push(menuItem({ key: 'navItemOptions', display: { icon: { name: 'cog' }, caption: 'Item Options' } }))
    //   count++
    // }

    return {
      count: count,
      items: mis
    }
  }

  const fpw_navOptions = () => <PageItemOptionsMenu />

  const styAndC = Object.assign({}, menuSaC)

  const menu = () => {

    const navItems = menuItems()

    return <Menu
      {...styAndC}
      inverted
      icon={showCaptions ? 'labeled' : null}
      widths={!desktopView && navItems.count}
      key={uniqueKey('pn', 'm')} >
      {navItems.items}
    </Menu>
  }

  if (!includeSlideout && !includeHome && !allowedNavigationItems) {
    return <Segment basic inverted>
      {'No bottom menus set'}
    </Segment>
  } else {
    if (showPageItemOptions) {
      return fpw_navOptions()
    } else {
      return menu()
    }
    // if (showNotes) {
    //   return <UserNotes fullPage={true} handleCancel={handleShowNotes} />
    // } else if (showNotifications) {

    // } else {

    // }
  }

}

export default PageNavigation
