import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Message, Segment } from 'semantic-ui-react';
import { CreateContext } from '../../cnr/contexts/CreateContext';
import { PageContext } from '../../cnr/contexts/PageContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { updateSettingsTypes } from '../../cnr/reducers/ViewSettingsReducer';
import { filterHelpers } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import SettingViewerMenu from '../../components/sem/SettingViewerMenu';
import JsonViewer from '../../components/viewers/JsonViewer';
import PickSelect from '../../genericControls/PickSelect';
import ConfirmationButton from '../../viewSettings/actions/buttons/ConfirmationButton';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { settingsActionTypes } from '../enums/itemActionTypes';
import { vsComponentTypes } from '../enums/vsComponentTypes';

export const settingsViewerTypes = {
  add: 'add',
  createClient: 'createClient',
  settingsOnly: 'settingsOnly',
  updateEvent: 'updateEvent',
}

/** Return UI formatted values for the settings of an event
 * by eventKey, selected or googleSheets
 * This component contains the ConfirmationButton
 */
const SettingsViewer = (props) => {

  const {
    actionName,
    createInfo,
    createType,
    ignoreSingleUpdates,
    handleUpdateClick,
    handleConfirmClicked,
    inputPane,
    jsonError,
    googleUpdateStatus,
    showNewOnly,
    noSave,
    updateDescription,
    viewItemDataActions_handlers,
    viewItemDataActions_state,
    updating
  } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state } = states ?? {}
  const { appUser } = appUser_state ?? {}

  // createContext
  const createContext = useContext(CreateContext)
  const { create_handlers } = createContext ?? {}

  const { handleCommitViewSettingsAction } = viewItemDataActions_handlers ? viewItemDataActions_handlers : {}
  const { confirmation: confirmation_settings } = viewItemDataActions_state ? viewItemDataActions_state : {}

  const { projectData, projectCollection, projectSettings, initialData } = createInfo ?? {}
  const { global, viewItems, views } = projectSettings ?? {}
  const { clientData, eventData, dataCollections } = projectData ?? {}
  const { fieldNames } = initialData ? initialData : {}
  const { name: eventName } = eventData ? eventData : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}

  // pageContext 
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems, aps_views } = pageSettings ?? {}

  const [selectedPick, setSelectedPick] = useState()

  /** commits the action */
  const handleUpdateSetingsClick = () => {
    if (handleConfirmClicked) { handleConfirmClicked(actionName) }
    if (handleUpdateClick) { handleUpdateClick() }
  }

  const handlePick = (_e, d) => {
    setSelectedPick(d.value)
    const nv = getNewSettings(d.value)
    create_handlers.handleSettingsViewPick({ item: d.value, ...nv })
  }

  const getNewSettings = (selectedItem) => {

    const { projectData, projectSettings } = createInfo ?? {}
    const { viewItems, views } = projectSettings ?? {}
    const { dataCollections } = projectData ?? {}

    const newSettings = {
      views: {},
      viewItems: {},
      dataCollections: {}
    }

    if (views) {
      const newViews = {}
      Object.keys(views).forEach(vKey => {
        if (!aps_views[vKey] && vKey.indexOf('_list') < 0) { newViews[vKey] = { key: vKey } }
        if (selectedItem && selectedItem === vKey && !aps_views[vKey]) {
          newSettings.views[vKey] = views[vKey]
          newSettings.views[vKey + '_list'] = views[vKey + '_list']
        }
      })
    }

    if (viewItems) {
      Object.keys(viewItems).forEach(viKey => {
        if (selectedItem && selectedItem === viKey && !aps_viewItems[viKey]) {
          newSettings.viewItems[viKey] = viewItems[viKey]
        }
      })
    }

    if (dataCollections) {
      Object.keys(dataCollections).forEach(dKey => {
        if (selectedItem && selectedItem === dKey) {
          newSettings.dataCollections[dKey] = dataCollections[dKey]
        }
      })
    }
    return newSettings
  }

  // IMPORTANT: Create Settings
  /** Updates the settings from the confirmation button */
  const commitUpdateSettings = (buttonClicked, dk, opts) => {

    const { replaceData } = opts ?? {}

    const { projectSettings, projectData } = createInfo ?? {}
    const { global, viewItems, views } = projectSettings ?? {}

    let updateJson;
    let dataToUpdate;

    switch (buttonClicked) {
      case updateSettingsTypes.global:
        updateJson = global
        break;
      case updateSettingsTypes.views:
        updateJson = views
        break;
      case updateSettingsTypes.viewItems:
        updateJson = viewItems
        break;

      case updateSettingsTypes.dataCollections:
        dataToUpdate = projectData.dataCollections && dk ? projectData.dataCollections[dk] : null
        break;
      default:
      // nothing
    }

    if (updateJson) {
      const commitProps = {
        appUser,
        actionName: settingsActionTypes.updateSettingsByType,
        updateType: buttonClicked,
        paps_state,
        previewInfo: updateJson,
      }
      handleCommitViewSettingsAction(commitProps)
    } else if (dataToUpdate && dk) {
      const commitProps = {
        appUser,
        actionName: settingsActionTypes.updateDataByType,
        dataKey: dk,
        dataToUpdate,
        updateType: buttonClicked,
        paps_state,
        previewInfo: updateJson,
        replaceData
      }
      handleCommitViewSettingsAction(commitProps)
    }
  }

  const errorMessage = (content) => (
    <Message
      className={'user-notification'}
      header={'Error'}
      content={content}
      attached
    />
  )

  const jsonViewer = (ph, json, oc) => <div key={uniqueKey('sv', 'jv')} className={'create-content'}>
    {oc && jsonError && errorMessage(jsonError)}
    <JsonViewer json={json} name={ph} ignoreSortKeys={false} allowDelete={true} />
  </div>

  /** Button that will trigger the  `commitUpdateSettings`*/
  const confirmationButton = (ph, dataCollectionKey, caption, opts) => <ConfirmationButton
    caption={caption}
    buttonClicked={ph}
    opts={opts}
    dataCollectionKey={dataCollectionKey}
    handleConfirm={commitUpdateSettings}
    disabled={false}
    confirmation={confirmation_settings}
  />

  /** confirmation buttons for the wrapper */
  const wrapperFooter = (ph, dataCollectionKey) => <div>
    {confirmationButton(ph, dataCollectionKey, 'Update ' + _.startCase(dataCollectionKey ? dataCollectionKey : ph) + (dataCollectionKey ? ' Data' : ''))}
    {dataCollectionKey && confirmationButton(ph, dataCollectionKey, 'Replace Existing and Update ' + _.startCase(dataCollectionKey) + ' Data', { replaceData: true })}
  </div>

  const wrapper_jsonViewer = (ph, json, oc, dataCollectionKey) => <Wrapper
    content={jsonViewer(ph, json, oc)}
    footer={!ignoreSingleUpdates && wrapperFooter(ph, dataCollectionKey)}
    wrapperType={wrapperTypes.padded}
  />

  const groupItem = (groupKey, content, count, checked) => {
    return {
      groupKey,
      content,
      checked,
      count,
    }
  }

  const settingsGroups = (rgs) => {
    global && rgs.push(groupItem('globalAppSettings', wrapper_jsonViewer(updateSettingsTypes.global, global), null, global ? true : false))
    viewItems && rgs.push(groupItem('viewItemsSettings', wrapper_jsonViewer(vsComponentTypes.viewItems, viewItems), null, viewItems ? true : false))
    views && rgs.push(groupItem('viewsSettings', wrapper_jsonViewer(updateSettingsTypes.views, views), null, views ? true : false))
  }

  const settingsJson = (rgs) => {
    global && rgs.push(groupItem('globalAppSettings', jsonViewer(updateSettingsTypes.global, global), null, global ? true : false))
    viewItems && rgs.push(groupItem('viewItemsSettings', jsonViewer(updateSettingsTypes.viewItems, viewItems), null, viewItems ? true : false))
    views && rgs.push(groupItem('viewsSettings', jsonViewer(updateSettingsTypes.views, views), null, views ? true : false))
  }

  const viewSettingsJson = (rgs) => {
    viewItems && rgs.push(groupItem('viewItemsSettings', jsonViewer(updateSettingsTypes.viewItems, viewItems), null, viewItems ? true : false))
    views && rgs.push(groupItem('viewsSettings', jsonViewer(updateSettingsTypes.views, views), null, views ? true : false))
  }

  /** Creates menuItems for each menu group */
  const menuGroup = () => {

    let ig;
    let pickInput;

    const rgs = []

    switch (createType) {

      case settingsViewerTypes.add:
        ig = inputPane
        if (views) {
          const newViews = {}
          Object.keys(views).forEach(vKey => {
            if (!aps_views[vKey] && vKey.indexOf('_list') < 0) { newViews[vKey] = { key: vKey } }
            if (selectedPick && selectedPick === vKey && !aps_views[vKey]) {
              const vws = { [vKey]: views[vKey] }
              rgs.push(groupItem('viewsSettings', jsonViewer(updateSettingsTypes.views, vws), null, true))
            }
          })

          // Adding a select for any of the new views.
          if (newViews && Object.keys(newViews).length > 0) {
            pickInput = <PickSelect name={'Selected Page'} items={newViews} selected={selectedPick} oc={handlePick} />
          } else {
            pickInput = <Segment inverted basic>No new items</Segment>
          }
        }

        if (viewItems) {
          Object.keys(viewItems).forEach(viKey => {
            if (selectedPick && selectedPick === viKey && !aps_viewItems[viKey]) {
              const vis = { [viKey]: viewItems[viKey] }
              rgs.push(groupItem('viewItemsSettings', jsonViewer(updateSettingsTypes.viewItems, vis), null, true))
            }
          })
        }

        if (dataCollections) {
          Object.keys(dataCollections).forEach(dKey => {
            if (selectedPick && selectedPick === dKey) {
              const dataCollection = dataCollections[dKey]
              const _dataCollection = showNewOnly ? _.filter(dataCollection, { _new: true }) : dataCollection
              const dcs = { [dKey]: _dataCollection }
              rgs.push(groupItem('dataCollections', jsonViewer(dKey, dcs), Object.keys(_dataCollection).length))
            }
          })
        }
        break;

      case settingsViewerTypes.updateEvent:

        switch (actionName) {
          case settingsActionTypes.updateDataFromGoogleData:
          case settingsActionTypes.updateDataFromGoogleSheets:
            settingsGroups(rgs)
            if (dataCollections) {
              Object.keys(dataCollections).forEach(key => {
                const dataCollection = dataCollections[key]
                const _dataCollection = showNewOnly ? filterHelpers.filter(dataCollection, '_new', true, null, '_itemKey') : dataCollection
                rgs.push(groupItem(key, wrapper_jsonViewer(updateSettingsTypes.dataCollections, _dataCollection, null, key), Object.keys(_dataCollection).length))
              })
            }
            break;

          case 'recreateSettings':
          case settingsActionTypes.updateSettingsFromGoogleSheets:
            settingsGroups(rgs)
            break;

          default:
            // adding globalAppSettings, viewItemsSettings, viewsSettings 
            settingsGroups(rgs)
            if (dataCollections) {
              Object.keys(dataCollections).forEach(key => {
                let allow = true
                if (projectCollection) {
                  allow = projectCollection === key
                }
                if (allow) {
                  const dataCollection = dataCollections[key]
                  const _dataCollection = showNewOnly ? filterHelpers.filter(dataCollection, '_new', true, null, '_itemKey') : dataCollection
                  rgs.push(groupItem(key, wrapper_jsonViewer(updateSettingsTypes.dataCollections, _dataCollection, null, key), Object.keys(_dataCollection).length))
                }
              })
            }
            fieldNames && rgs.push(groupItem('fieldNames', jsonViewer('fieldNames', fieldNames)))
        }
        break;

      case settingsViewerTypes.createClient:
        ig = inputPane
        settingsJson(rgs)
        clientData && rgs.push(groupItem('clientData', jsonViewer(updateSettingsTypes.clientData, clientData), _.isEmpty(clientData) ? 0 : 1))
        dataCollections && rgs.push(groupItem('dataCollections', jsonViewer(updateSettingsTypes.dataCollections, dataCollections)))
        break;

      case settingsViewerTypes.settingsOnly:
        ig = inputPane
        viewSettingsJson(rgs)
        break;

      default:
        ig = inputPane
        settingsJson(rgs)
        clientData && rgs.push(groupItem('clientData', jsonViewer(updateSettingsTypes.clientData, clientData), _.isEmpty(clientData) ? 0 : 1))
        eventData && rgs.push(groupItem('eventData', jsonViewer(updateSettingsTypes.eventData, eventData), _.isEmpty(eventData) ? 0 : 1))
        if (dataCollections) {
          Object.keys(dataCollections).forEach(key => {
            const dataCollection = dataCollections[key]
            const _dataCollection = showNewOnly ? filterHelpers.filter(dataCollection, '_new', true, null, '_itemKey') : dataCollection
            rgs.push(groupItem(key, jsonViewer(key, _dataCollection), Object.keys(_dataCollection).length))
          })
        }
    }

    return {
      pickInput,
      inputGroup: ig,
      resultGroups: rgs,
    }
  }

  const footer = () => {
    if (projectCollection) {
      return <UiSaveButtons save={{ oc: handleUpdateSetingsClick, caption: _.startCase(actionName) + ' (' + _.startCase(projectCollection) + ')' }} />
    } else {
      return <UiSaveButtons save={{ oc: handleUpdateSetingsClick, caption: _.startCase(actionName) }} />
    }
  }

  const wrapper = () => <Wrapper
    header={updateDescription}
    content={<SettingViewerMenu menuGroup={menuGroup()} eventName={eventName} googleUpdateStatus={googleUpdateStatus} noMid={false} />}
    footer={!noSave && footer()}
    updating={updating}
    wrapperType={wrapperTypes.padded}
  />

  return wrapper()

}

export default SettingsViewer