import { deleteField } from 'firebase/firestore';
import _ from 'lodash';
import { removeEmptyObjectArrays } from '../../../../../global/common/convert';
import { removeFromObject } from '../../../../../global/common/sorting';
import { createFsDocKey } from '../../../../../global/firestoreData/appData/fsAppData';
import { _matchLsTypes, _mediaHeaders, matchStatusTypes } from '../SportsDataReducer';
import { _deleteMatchProps } from './seasonalSportsData';
import { sportsHelpers } from './sportsHelpers';
import { _resultProp, _resultsProp } from '../../contexts/SportsSeasonContext';

const _removeProps = [
  '_itemKey',
  'parentKeys',
  'position',
  'combined',
]

export const getMatchUpdates = (matches_db, matches_gs, teams, forPlayoffs) => {

  const teamNames = Object.values(teams).map(team => team.name);

  const matches_gsf = _.filter(matches_gs, match =>
    teamNames.includes(match.away) || teamNames.includes(match.home)
  );

  matches_gsf.forEach(m => {

    const { levelAndSection, _existing } = getLevelAndSection(m, teams)

    const { level: _levelName, section: _sectionName } = levelAndSection ?? {}
    const { home: _home, away: _away } = _existing ?? {}

    m.section = _sectionName
    m.level = _levelName

  })

  const matchCompares = {
    cancelled: [],
    exhibition: [],
    dateChanged: [],
    duplicates: [],
    duplicated: [],
    inversed: [],
    locationChanged: [],
    new: [],
    postponed: [],
    scoreChanged: [],
    scoresChanged: [],
    scoreNew: [],
    scoresNew: [],
    tournament: [],
  }

  const _matchDataUpdates = {
    allUpdates: {},
    cancelled: {},
    exhibition: {},
    dateChanged: {},
    duplicates: {},
    inversed: {},
    locationChanged: {},
    new: {},
    postponed: {},
    scoreChanged: {},
    scoresChanged: {},
    scoreNew: {},
    scoresNew: {},
    tournament: {},
  }

  const _matchStatusGroups = {
    allUpdates: {},
    cancelled: {},
    exhibition: {},
    dateChanged: {},
    duplicates: {},
    duplicated: {},
    existing: {},
    inversed: {},
    locationChanged: {},
    new: {},
    postponed: {},
    scoreChanged: {},
    scoresChanged: {},
    scoreNew: {},
    scoresNew: {},
    tournament: {},
  }

  const getExistingMatchCompares = () => {
    const existingMatches = matches_gsf.map(match_gs => {
      const match_existing = _.find(matches_db, function (m) {
        return forPlayoffs ? matchTeamsSame_playoffs(m, match_gs) : matchTeamsSame(m, match_gs)
      })
      // add the existng match
      if (match_existing) { _matchStatusGroups.existing[match_existing._itemKey] = sportsHelpers.getMatch_flat(match_existing) }
      return match_existing ? { match_existing, match_gs } : null
    })
    return existingMatches.filter(match => match !== null)
  }

  const getDuplicateMatches = () => {
    const dups = findDuplicateMatches()
    if (dups) {
      dups.forEach(dup => {
        if (!dup._matchStatusTypes) { dup._matchStatusTypes = [] }
        dup._matchStatusTypes.push(matchStatusTypes.duplicates)
      })
    }
    _matchStatusGroups.duplicates = dups
    const groupedDuplicateMatches = groupDuplicateMatches(dups);
    _matchStatusGroups.duplicated = groupedDuplicateMatches
    return groupedDuplicateMatches
  }

  const findDuplicateMatches = () => {
    const groupedMatches = _.groupBy(matches_gsf, (match) => `${match.home}-${match.away}`);
    const duplicateGroups = _.filter(groupedMatches, (group) => {
      return group.length > 1 && !_.some(group, { exhibition: 'TRUE' }) && !_.some(group, { section: _mediaHeaders.tournament });
    });
    const duplicateMatches = _.flatten(duplicateGroups);
    return duplicateMatches;
  };

  function groupDuplicateMatches(matches) {
    const groupedDuplicateMatches = _.groupBy(matches, (match) => `${match.home}-${match.away}`);
    const filteredMatches = _.filter(groupedDuplicateMatches, (matches) => {
      return matches.every(match => match.home.section !== match.away.section);
    });
    return filteredMatches
    // return groupedDuplicateMatches;
  }

  const getNewMatches = () => {
    matches_gsf.map(match_gs => {
      const { cancelled } = match_gs
      if (cancelled !== 'TRUE') {
        const match_existing = _.find(matches_db, function (m) {
          return forPlayoffs ? matchTeamsSame_playoffs(m, match_gs) : matchTeamsSame(m, match_gs)
        })
        if (!match_existing && !matchPending(match_gs) && !matchTournament(match_gs)) {
          matchCompares.new.push({ match_existing: null, match_gs })
        }
      }
    })
  }

  const getTournamentMatches = () => {
    matches_gsf.map(match_gs => {
      const { level } = match_gs
      if (level === _mediaHeaders.tournament) {
        const match_existing = _.find(matches_db, function (m) {
          return forPlayoffs ? matchTeamsSame_playoffs(m, match_gs) : matchTeamsSame(m, match_gs)
        })
        if (!match_existing) {
          matchCompares.tournament.push({ match_existing: null, match_gs })
        }
      }
    })
  }

  const getChangeProps = (matchCompare) => {
    const { match_existing, match_gs } = matchCompare
    const { isPlayoff } = match_existing ?? {}
    const sameLs = isPlayoff ? matchLevelSame(match_existing, match_gs) : matchLevelSectionSame(match_existing, match_gs)
    const hasScore_gs = matchHasScore_gs(match_gs)
    const hasScores_gs = matchHasScores_gs(match_gs)
    const hasScore_existing = matchHasScore_existing(match_existing)
    const hasScores_existing = matchHasScores_existing(match_existing)
    const hasBothGsAndExistingScores = hasScore_gs && hasScore_existing
    const dateChanged = matchDateChanged(match_existing, match_gs)
    const matchIsSame = matchTeamsSame(match_existing, match_gs)
    const dateIsSame = matchDateSame(match_existing, match_gs)
    const scoreDifferent = hasBothGsAndExistingScores && matchScoreDifferent(match_existing, match_gs)
    const scoresDifferent = hasBothGsAndExistingScores && matchScoresDifferent(match_existing, match_gs)
    const matchIsPending = matchPending(match_gs)

    const cancelled = matchCancelled(match_gs) && !matchIsPending
    const dataChanged = matchIsSame && dateChanged && sameLs && !matchIsPending
    const exhibition = matchExhibition(match_gs) && !matchIsPending
    const inversed = matchTeamsInversed(match_existing, match_gs) && !matchIsPending
    const postponed = matchPostponed(match_gs) && !matchIsPending
    const scoreChanged = hasBothGsAndExistingScores && !dataChanged && scoreDifferent && sameLs && !matchIsPending
    const scoresChanged = hasBothGsAndExistingScores && !dataChanged && scoresDifferent && sameLs && !matchIsPending
    const scoreNew = dateIsSame && hasScore_gs && !hasScore_existing && !matchIsPending
    const scoresNew = dateIsSame && hasScores_gs && !hasScores_existing && !matchIsPending
    const locationChanged = matchLocationDifferent(match_existing, match_gs)

    return {
      cancelled,
      dataChanged,
      dateChanged,
      exhibition,
      inversed,
      locationChanged,
      matchIsPending,
      postponed,
      scoreChanged,
      scoreNew,
      scoresNew,
      scoresChanged,
    }
  }

  const getChanges = (existingMatchCompares) => {
    if (existingMatchCompares) {
      existingMatchCompares.forEach(matchCompare => {
        const {
          dateChanged,
          cancelled,
          exhibition,
          inversed,
          locationChanged,
          postponed,
          scoreChanged,
          scoreNew,
          scoresNew,
          scoresChanged,
        } = getChangeProps(matchCompare)

        if (cancelled) { matchCompares.cancelled.push(matchCompare) }
        if (dateChanged) { matchCompares.dateChanged.push(matchCompare) }
        if (exhibition) { matchCompares.exhibition.push(matchCompare) }
        if (inversed) { matchCompares.inversed.push(matchCompare) }
        if (locationChanged) { matchCompares.locationChanged.push(matchCompare) }
        if (postponed) { matchCompares.postponed.push(matchCompare) }
        if (scoreChanged) { matchCompares.scoreChanged.push(matchCompare) }
        if (scoresChanged) { matchCompares.scoresChanged.push(matchCompare) }
        if (scoreNew) { matchCompares.scoreNew.push(matchCompare) }
        if (scoresNew) { matchCompares.scoresNew.push(matchCompare) }

      })
    }
  }

  const _existingMatchCompares = getExistingMatchCompares()

  getDuplicateMatches()
  getTournamentMatches()
  getNewMatches()

  getChanges(_existingMatchCompares)

  _.each(matchCompares, (matchCompare, compareType) => {
    if (matchCompare) {
      matchCompare.forEach(mc => {
        ammendMatch(_matchDataUpdates, _matchStatusGroups, mc, compareType, teams, forPlayoffs)
      })
    }
  })

  return {
    matchCompares: removeEmptyObjectArrays(matchCompares),
    _matchDataUpdates: removeEmptyObjectArrays(_matchDataUpdates),
    _matchStatusGroups: removeEmptyObjectArrays(_matchStatusGroups)
  }
}

const getLevelAndSection = (match_gs, teams) => {

  const { home: _homeName, away: _awayName, section, level, isPlayoff, poolKey, _matchCollectionKey } = match_gs

  const _isTournament = level === _mediaHeaders.tournament

  const _existing = {
    home: _.find(teams, { name: _homeName }),
    away: _.find(teams, { name: _awayName }),
    onlyOneTeam: false
  }

  _existing.onlyOneTeam = (_existing.away && !_existing.home) || (!_existing.away && _existing.home)

  const { sections: section_home, levels: level_home } = _existing.home ?? {}
  const { sections: section_away, levels: level_away } = _existing.away ?? {}

  const validation = {
    section: !_isTournament && level_home && level_away && section_home && section_away && (level_home === level_away) && (section_home === section_away),
    level: !_isTournament && level_home && level_away && (level_home === level_away)
  }

  const levelAndSection = {
    section: validation.section ? section_home : section,
    level: validation.level ? level_home : level,
  }

  if (_isTournament) {
    levelAndSection.section = 'tournament'
    levelAndSection.level = 'tournament'
  } else {
    if (_existing.onlyOneTeam) {
      levelAndSection.level = matchStatusTypes.nonDistrict
      levelAndSection.section = 'Non'
    } else {
      if (!validation.section) {
        levelAndSection.section = 'Non'
        levelAndSection.level = (isPlayoff || poolKey) ? levelAndSection.level : 'Non'
      }
      if (!validation.level) {
        levelAndSection.level = 'Non'
      }
    }
  }

  match_gs.level = levelAndSection.level
  match_gs.section = match_gs.section
  match_gs._matchCollectionKey = levelAndSection.level

  if (isPlayoff) { match_gs._matchCollectionKey = _matchLsTypes.playoffs }

  if (!match_gs._matchCollectionKey) {
    match_gs._matchCollectionKey = _matchCollectionKey
  }

  return { levelAndSection, _existing }

}

const ammendMatch = (_matchDataUpdates, _matchStatusGroups, matchCompare, matchType, teams, forPlayoffs) => {

  const { match_existing, match_gs } = matchCompare

  const { _matchCollectionKey, _itemKey } = match_existing ?? {}
  const { home: _homeName, away: _awayName, awayScore, homeScore, location: _locationName, startDate, exhibition } = match_gs

  let newItemKey;
  let newLevelKey;

  let _match_existing = {
    ...match_existing,
    _matchStatusTypes: []
  }

  if (_match_existing._matchStatusTypes) {
    _match_existing._matchStatusTypes.push(matchStatusTypes.existing)
  } else {
    _match_existing._matchStatusTypes.push(matchStatusTypes.existing)
  }

  switch (matchType) {

    case matchStatusTypes.locationChanged:
      _match_existing._matchStatusTypes.push(matchStatusTypes.locationChanged)
      const _location = _.find(teams, { name: _locationName })
      _match_existing.location = _location
      break;

    case matchStatusTypes.cancelled:
      _match_existing.cancelled = true
      _match_existing._matchStatusTypes.push(matchStatusTypes.cancelled)
      break;

    case matchStatusTypes.dateChanged:
      _match_existing._matchStatusTypes.push(matchStatusTypes.dateChanged)
      _match_existing._matchStatusTypes.push(matchStatusTypes.allUpdates)
      _match_existing.old = { startDate: match_existing.startDate }
      _match_existing.startDate = startDate
      ammendMatchResults(_match_existing, match_gs, matchType)
      break;

    case matchStatusTypes.duplicated:
    case matchStatusTypes.duplicates:
      _match_existing._matchStatusTypes.push(matchStatusTypes.duplicated)
      break;

    case matchStatusTypes.exhibition:
      _match_existing.exhibition = true
      _match_existing._matchStatusTypes.push(matchStatusTypes.exhibition)
      break;

    case matchStatusTypes.inversed:
      _match_existing._matchStatusTypes.push(matchStatusTypes.inversed)
      _match_existing.old = {
        away: _match_existing.away,
        home: _match_existing.home
      }
      _match_existing.away = _match_existing.old.home
      _match_existing.home = _match_existing.old.away
      break;

    case matchStatusTypes.notFound:
      _match_existing._matchStatusTypes.push(matchStatusTypes.notFound)
      break;

    case matchStatusTypes.postponed:
      _match_existing.postponed = true
      _match_existing._matchStatusTypes.push(matchStatusTypes.postponed)
      break;

    case matchStatusTypes.scoreChanged:
      _match_existing._matchStatusTypes.push(matchStatusTypes.scoreChanged)
      _match_existing._matchStatusTypes.push(matchStatusTypes.allUpdates)
      _match_existing[_resultsProp].score.away = parseInt(awayScore)
      _match_existing[_resultsProp].score.home = parseInt(homeScore)

      // results from _results
      _match_existing[_resultProp] = _match_existing[_resultsProp]

      checkScores(_match_existing, match_gs)
      ammendMatchResults(_match_existing, match_gs, matchType)
      break;

    case matchStatusTypes.scoresChanged:
      _match_existing._matchStatusTypes.push(matchStatusTypes.scoresChanged)
      _match_existing._matchStatusTypes.push(matchStatusTypes.allUpdates)
      checkScores(_match_existing, match_gs)
      ammendMatchResults(_match_existing, match_gs, matchType)
      break;

    case matchStatusTypes.scoreNew:
    case matchStatusTypes.scoresNew:
      _match_existing._matchStatusTypes.push(matchType)
      if (!_match_existing._flipHa) {
        _match_existing[_resultsProp] = {
          score: {
            away: parseInt(awayScore),
            home: parseInt(homeScore),
          }
        }
        _match_existing.results = _match_existing[_resultsProp]
      } else {
        _match_existing[_resultsProp] = {
          score: {
            home: parseInt(awayScore),
            away: parseInt(homeScore),
          }
        }
        _match_existing.results = _match_existing[_resultsProp]
      }
      checkScores(_match_existing, match_gs)
      checkLocation(_match_existing, match_gs, teams)
      break;

    case matchStatusTypes.new:
    case matchStatusTypes.tournament:

      let { section, level, isPlayoff: is_playoff } = match_gs

      const { _existing, levelAndSection } = getLevelAndSection(match_gs, teams)
      const { level: _levelName, section: _sectionName } = levelAndSection ?? {}
      const { home: _home, away: _away } = _existing ?? {}

      newLevelKey = _levelName

      switch (matchType) {
        case matchStatusTypes.tournament:
          _match_existing._matchStatusTypes.push(matchStatusTypes.tournament)
        default:
          if (!_home && !_away) {
            _match_existing._matchStatusTypes.push(matchStatusTypes.notFound)
          } else {
            _match_existing._matchStatusTypes.push(matchStatusTypes.new)
          }
      }

      if (exhibition === 'TRUE') {
        newLevelKey = matchStatusTypes.exhibition
        section = section
        level = level
      }

      _match_existing = {
        startDate: startDate,
        startTime: '7:00 PM',
        sections: is_playoff ? _matchLsTypes.non : section,
        levels: level,
        home: _home ? createTeam(_home) : { name: _homeName, levels: 'Non', sections: 'Non' },
        away: _away ? createTeam(_away) : { name: _awayName, levels: 'Non', sections: 'Non' },
        teams: [_home ? _home._itemKey : '', _away ? _away._itemKey : ''],
        _matchCollectionKey: is_playoff ? _matchLsTypes.playoffs : newLevelKey
      }

      ammendMatchResults(_match_existing, match_gs, matchType)

      newItemKey = createFsDocKey()
  }

  const matchKey = newItemKey ? newItemKey : _itemKey
  let collection_key = newLevelKey ? newLevelKey : _matchCollectionKey
  if (!collection_key) { collection_key = _matchCollectionKey }
  // if (forPlayoffs || _isPlayoff) { collection_key = _matchLsTypes.playoffs }

  if (!_matchDataUpdates[matchType][collection_key]) { _matchDataUpdates[matchType][collection_key] = {} }

  _matchStatusGroups[matchType][matchKey] = sportsHelpers.getMatch_flat(_match_existing, true)

  fixMatchProps(_match_existing)

  if (!_match_existing._matchCollectionKey) {
    _match_existing._matchCollectionKey = _matchCollectionKey
  }

  const _matchPrefix = forPlayoffs ? 'playoffMatches' : 'matches'

  if (_match_existing.cancelled) {
    _matchDataUpdates[matchType][collection_key][_matchPrefix + '.' + matchKey] = deleteField()
  } else {
    _matchDataUpdates[matchType][collection_key][_matchPrefix + '.' + matchKey] = _match_existing

    switch (matchType) {
      case matchStatusTypes.dateChanged:
      case matchStatusTypes.new:
      case matchStatusTypes.nonDistrict:
      case matchStatusTypes.scoreChanged:
      case matchStatusTypes.scoresChanged:
      case matchStatusTypes.scoreNew:
      case matchStatusTypes.scoresNew:
        if (!_matchDataUpdates.allUpdates) { _matchDataUpdates.allUpdates = {} }
        if (!_matchDataUpdates.allUpdates[collection_key]) { _matchDataUpdates.allUpdates[collection_key] = {} }
        _matchDataUpdates.allUpdates[collection_key][_matchPrefix + '.' + matchKey] = _match_existing
        _matchStatusGroups.allUpdates[matchKey] = sportsHelpers.getMatch_flat(_match_existing, true)
    }
  }
}

const matchCancelled = (match_gs) => (match_gs.cancelled === 'TRUE')
const matchLevelSame = (match_existing, match_gs) => (match_existing.levels === match_gs.level)
const matchLevelSectionSame = (match_existing, match_gs) => ((match_existing.levels === match_gs.level) && (match_existing.sections === match_gs.section))
const matchDateChanged = (match_existing, match_gs) => ((match_existing.startDate !== match_gs.startDate) && !match_gs.exhibition && !match_gs.tournament)
const matchDateSame = (match_existing, match_gs) => (match_existing.startDate.toUpperCase() === match_gs.startDate.toUpperCase())
const matchExhibition = (match_gs) => (match_gs.exhibition === 'TRUE')
const matchHasScore_existing = (match_existing) => !_.isNil(match_existing[_resultsProp]) && !_.isNil(match_existing[_resultsProp].score)
const matchHasScores_existing = (match_existing) => !_.isNil(match_existing[_resultsProp]) && !_.isNil(match_existing[_resultsProp].scores)
const matchHasScore_gs = (match_gs) => !_.isNil(match_gs.homeScore) && !_.isNil(match_gs.awayScore)
const matchHasScores_gs = (match_gs) => !_.isNil(match_gs.home3) && !_.isNil(match_gs.away3)
const matchLocationDifferent = (match_existing, match_gs) => (match_existing.location && match_existing.location.name !== match_gs.location)
const matchPostponed = (match_gs) => (match_gs.postponed === 'TRUE')
const matchScoreDifferent = (match_existing, match_gs) => {
  const results = match_existing ? match_existing[_resultsProp] : undefined;
  const { score } = results ?? {}
  return (score.away !== parseInt(match_gs.awayScore)) || (score.home !== parseInt(match_gs.homeScore))
}
const matchScoresDifferent = (match_existing, match_gs) => {
  let diff = false
  if (match_existing[_resultsProp].scores) {
    Object.keys(match_existing[_resultsProp].scores).forEach(sn => {
      const sc = match_existing[_resultsProp].scores[sn]
      const { away, home } = sc
      const away_gs = match_gs['away' + sn]
      const home_gs = match_gs['home' + sn]
      if (parseInt(away) && parseInt(away_gs) && parseInt(home) && parseInt(home_gs)) {
        if ((parseInt(away) !== parseInt(away_gs)) || (parseInt(home) !== parseInt(home_gs))) {
          diff = true
        }
      }
    })
  }
  return diff
}
const matchTeamsInversed = (match_existing, match_gs) => (match_existing.home && match_existing.away && match_existing.home.name === match_gs.away) && (match_existing.away.name === match_gs.home)
// const matchTeamsSame = (match_existing, match_gs) => (match_existing.home && match_existing.away && match_existing.home.name === match_gs.home) && (match_existing.away.name === match_gs.away)
const matchTeamsSame = (match_existing, match_gs) =>
  (match_existing.home && match_existing.away && match_existing.home.name === match_gs.home) && (match_existing.away.name === match_gs.away)

const matchTeamsSame_playoffs = (match_existing, match_gs) => (match_existing.home && match_existing.away &&
  ((match_existing.home.name === match_gs.home) && (match_existing.away.name === match_gs.away) ||
    (match_existing.home.name === match_gs.away) && (match_existing.away.name === match_gs.home)))

const matchPending = (match_gs) => (match_gs.away.startsWith('winner') || match_gs.home.startsWith('winner') || match_gs.away.startsWith('loser') || match_gs.home.startsWith('loser'))

const matchTournament = (match_gs) => (match_gs.level === _mediaHeaders.tournament)

const fixMatchProps = (m) => {
  removeFromObject(m, _deleteMatchProps)
  removeFromObject(m, _removeProps)
  removeFromObject(m.away, _removeProps)
  removeFromObject(m.home, _removeProps)
  m.old && removeFromObject(m.old.away, _removeProps)
  m.old && removeFromObject(m.old.home, _removeProps)
}

const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}

/**
 * Update the match results ONLY
 * @param {object} _match_existing 
 * @param {object} match_gs 
 * @param {string} matchType 
 */
const ammendMatchResults = (_match_existing, match_gs, matchType) => {
  if (match_gs) {
    const { awayScore, homeScore, extra } = match_gs
    const _scores = getScores(match_gs)
    if (match_gs && awayScore && homeScore) {
      if (!_match_existing._matchStatusTypes) {
        _match_existing._matchStatusTypes = [matchType, matchStatusTypes.scoreChanged]
      } else {
        _match_existing._matchStatusTypes.push(matchStatusTypes.scoreChanged)
      }
      if (!_match_existing[_resultsProp]) { _match_existing[_resultsProp] = {} }

      _match_existing[_resultsProp] = {
        score: {
          away: parseInt(awayScore),
          home: parseInt(homeScore),
        }
      }

      if (!_.isEmpty(_scores)) {
        _match_existing[_resultsProp].scores = _scores
        if (!_match_existing.results) { _match_existing.results = {} }
        _match_existing.results.scores = _scores
      }
    }

    if (extra) {
      _match_existing[_resultsProp].extra = extra
    }
  }
}

const checkLocation = (match, match_gs, teams) => {
  if (match.location && match.location !== match_gs.location) {
    const _location = _.find(teams, { name: match_gs.location })
    if (_location) {
      delete _location.record
      delete _location.sections
      delete _location.levels
      delete _location.seed
      delete _location.position
    }
    match.location = _location ? _location : match.location
  }
}

const checkScores = (match_existing, match_gs) => {
  for (var i = 1; i <= 5; i++) {
    if (match_gs['away' + i] && match_gs['home' + i]) {
      if (!match_existing[_resultsProp]) { match_existing[_resultsProp] = {} }
      if (!match_existing[_resultsProp].scores) { match_existing[_resultsProp].scores = {} }
      match_existing[_resultsProp].scores[i] = { away: 0, home: 0 }
      if (match_gs['away' + i]) { match_existing[_resultsProp].scores[i].away = match_gs['away' + i] }
      if (match_gs['home' + i]) { match_existing[_resultsProp].scores[i].home = match_gs['home' + i] }
    }
  }
}

const getScores = (match_gs) => {
  const scores = {}
  for (var i = 1; i <= 5; i++) {
    if (match_gs['away' + i] && match_gs['home' + i]) {
      if (!scores[i]) { scores[i] = {} }
      if (match_gs['away' + i]) { scores[i].away = match_gs['away' + i] }
      if (match_gs['home' + i]) { scores[i].home = match_gs['home' + i] }
    }
  }
  return scores
}

const getTeamMatchInfo = (teams, matches_db, matches_gs) => {

  const _teams = {};

  const getTeamKeyByName = (teamName) => {
    return Object.keys(teams).find((key) => teams[key].name === teamName);
  };

  // Iterate through team names in the teams object to initialize each team object
  Object.keys(teams).forEach((teamKey) => {
    const _t = teams[teamKey]
    // Initialize team object with name and empty arrays
    _teams[teamKey] = {
      name: _t.name,
      existing_db: [],
      existing_gs: [],
      opponents_db: [],
      opponents_gs: [],
    };
  });

  // Iterate through matches_db to populate existingDb arrays
  Object.keys(matches_db).forEach((matchId) => {
    const match = matches_db[matchId];

    // Check if home or away name matches with any team
    Object.keys(teams).forEach((teamKey) => {
      if (match.home.name === teams[teamKey].name || match.away.name === teams[teamKey].name) {
        _teams[teamKey].existing_db.push(match);
        const opponentName = match.home.name === teams[teamKey].name ? match.away.name : match.home.name;
        const opponentKey = getTeamKeyByName(opponentName);
        if (opponentKey) {
          if (!_teams[teamKey].opponents_db[opponentKey]) {
            _teams[teamKey].opponents_db[opponentKey] = {
              name: opponentName,
              matches: [],
            };
          }
          _teams[teamKey].opponents_db[opponentKey].matches.push(match);
        }
      }
    });
  });

  // Iterate through matches_gs to populate existingGs arrays
  matches_gs.forEach((match) => {
    // Check if home or away matches with any team name
    Object.keys(teams).forEach((teamKey) => {
      if (match.level !== 'tournament') {
        if (match.home === teams[teamKey].name || match.away === teams[teamKey].name) {
          _teams[teamKey].existing_gs.push(match);
          const opponentName = match.home === teams[teamKey].name ? match.away : match.name;
          const opponentKey = getTeamKeyByName(opponentName);
          if (opponentKey) {
            if (!_teams[teamKey].opponents_gs[opponentKey]) {
              _teams[teamKey].opponents_gs[opponentKey] = {
                name: opponentName,
                matches: [],
              };
            }
            _teams[teamKey].opponents_gs[opponentKey].matches.push(match);
          }
        }
      }
    });
  });

  const sameAh = []

  Object.keys(_teams).forEach(teamKey => {
    const _team = _teams[teamKey]
    const { name, opponents_gs, opponents_db } = _team ?? {}
    if (opponents_gs) {
      Object.keys(opponents_gs).forEach(oppKey => {
        const _opponent = opponents_gs[oppKey]
        const { matches } = _opponent ?? {}
        if (matches) {
          const groupedMatches = _.groupBy(matches, 'home')
          const hasSameHomeName = _.some(groupedMatches, group => group.length > 1);
          if (hasSameHomeName) {
            sameAh.push({
              opponent: _opponent.name,
              matches: groupedMatches
            })
            // console.log('*********** ' + name + ' ***********')
            // console.log(_opponent.name)
            // console.log('opponents_gs', groupedMatches)
            const x = opponents_db[oppKey]
            // console.log('opponents_db', x)
          }
        }
      })
    }
  })

  return _teams
}