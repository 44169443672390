import { serverTimestamp } from "firebase/firestore"
import _ from 'lodash'
import { createRefPath_event } from "../../firestoreData/appData/appRefPaths"
import { fs_db } from "../../firestoreData/appData/fsAppData"
import { fs_update_doc } from "../../firestoreData/appData/fsData"
import { fsfn_notifications, notificationMethodTypes } from "../../functions/fbNotifications"
import { gEnums } from "../../enums/globalEnums"

const rts = {
  handleCheckIn_appUser: 'handleCheckIn_appUser',
  handleGet_checkIns: 'handleGet_checkIns',
  handleGet_scannedAppUser: 'handleGet_scannedAppUser',
  handleHide_message: 'handleHide_message',
  handleResponse_checkIn: 'handleResponse_checkIn',
  handleScanner_close: 'handleScanner_close',
  handleScanner_show: 'handleScanner_show',
  handleSet_scannedAppUser: 'handleSet_scannedAppUser',
  handleSet_checkIns: 'handleSet_checkIns',
  handleShow_selectedAppUser: 'handleShow_selectedAppUser',
}

export const _collectionName = '_credentialing'

export const credentialingReducer = (state, action) => {

  const {
    allowCredentialing,
    allowDirectScanValidation,
    appUserCollection,
    checkIns,
    mainFixedCaption,
    notifyAdmins,
    pathViews,
  } = state

  const { dispatch, type, currentAppUser } = action

  // eslint-disable-next-line
  const { handleSet_checkIns, handleSet_scannedAppUser, handleResponse_checkIn } = credentialingHandlers(dispatch)

  switch (type) {

    case rts.handleGet_checkIns:
      if (allowCredentialing) {
        handleGet_checkIns(pathViews, handleSet_checkIns)
        return { ...state }
      } else {
        return { ...state, checkIns: {} }
      }

    case rts.handleSet_checkIns:
      return { ...state, checkIns: action.checkIns, currentTotals: getCounts(action.checkIns) }

    case rts.handleGet_scannedAppUser:
      handleGet_scannedAppUser(pathViews, appUserCollection, action.appUserKey, handleSet_scannedAppUser)
      return { ...state }

    case rts.handleSet_scannedAppUser:
      const _checkIns = { ...checkIns }
      const _checkIn = _checkIns[currentAppUser._itemKey]
      const _currentAppUser = { ...currentAppUser, ..._checkIn }
      _checkIns[currentAppUser._itemKey] = _currentAppUser
      return {
        ...state,
        checkIns: _checkIns,
        currentTotals: getCounts(_checkIns),
        appUser_selected: _currentAppUser,
        appUser_scanned: action.currentAppUser,
        showScanner: allowDirectScanValidation ? state.showScanner : false
      }

    case rts.handleCheckIn_appUser:
      handleCheckIn(pathViews, currentAppUser, checkIns, handleResponse_checkIn)
      return { ...state }

    case rts.handleResponse_checkIn:
      if (notifyAdmins) {
        const { response } = action
        const { dataToUpdate } = response
        notifyTheAdmins(dataToUpdate, mainFixedCaption)
      }
      return { ...state, appUser_selected: null, showMessage: false }

    case rts.handleHide_message:
      return { ...state, showMessage: false }

    case rts.handleScanner_show:
      return { ...state, showScanner: !state.showScanner }

    case rts.handleScanner_close:
      return { ...state, showScanner: null }

    case rts.handleShow_selectedAppUser:
      return { ...state, appUser_selected: currentAppUser }

    default:
      return { ...state }
  }
}

export const credentialingInitialState = (initState) => {
  return { ...initState }
};

export const credentialingHandlers = (dispatch) => {
  return {
    handleCheckIn_appUser: (currentAppUser) => { dispatch({ type: rts.handleCheckIn_appUser, dispatch, currentAppUser }) },
    handleGet_checkIns: () => { dispatch({ type: rts.handleGet_checkIns, dispatch }) },
    handleGet_scannedAppUser: (appUserKey) => { dispatch({ type: rts.handleGet_scannedAppUser, dispatch, appUserKey }) },
    handleHide_message: () => { dispatch({ type: rts.handleHide_message, dispatch }) },
    handleResponse_checkIn: (response) => { dispatch({ type: rts.handleResponse_checkIn, dispatch, response }) },
    handleScanner_close: () => { dispatch({ type: rts.handleScanner_close, dispatch }) },
    handleScanner_show: () => { dispatch({ type: rts.handleScanner_show, dispatch }) },
    handleSet_scannedAppUser: (currentAppUser) => { dispatch({ type: rts.handleSet_scannedAppUser, dispatch, currentAppUser }) },
    handleSet_checkIns: (checkIns) => { dispatch({ type: rts.handleSet_checkIns, dispatch, checkIns }) },
    handleShow_selectedAppUser: (currentAppUser) => { dispatch({ type: rts.handleShow_selectedAppUser, dispatch, currentAppUser }) },
  }
}

const getCounts = (checkIns) => {
  const ci = checkIns ? _.filter(checkIns, { checkedIn: true }) : {}
  return { checkedIn: ci.length, total: Object.keys(checkIns).length }
}

const handleGet_checkIns = (pathViews, callback) => {
  const _refPath = createRefPath_event(pathViews, [_collectionName])
  fs_db.get_data({ refPath: _refPath, callback, opts: { listen: true } })
}

const handleGet_scannedAppUser = (pathViews, appUserCollection, appUserKey, callback) => {
  const _refPath = createRefPath_event(pathViews, [appUserCollection, appUserKey])
  fs_db.get_data({ refPath: _refPath, callback, opts: { listen: true, returnFirstObject: true } })
}

const handleCheckIn = (pathViews, currentAppUser, checkIns, callback) => {
  const { _itemKey, firstName, lastName } = currentAppUser ?? {}
  const checkIn = checkIns ? checkIns[_itemKey] : {}
  const { checkedIn } = checkIn ?? {}
  const _checkedIn = !checkedIn
  const _ref = createRefPath_event(pathViews, [_collectionName, _itemKey])
  fs_update_doc(_ref, { firstName, lastName, checkedIn: _checkedIn, updateDate: serverTimestamp() }, callback)
}

const notifyTheAdmins = (appUser, mainFixedCaption) => {
  const { firstName, lastName } = appUser ?? {}
  if (firstName && lastName) {
    const _name = firstName + ' ' + lastName
    const notificationData = { title: _name + ' has signed in', body: _name + ' has signed in to ' + mainFixedCaption }
    fsfn_notifications.sendNotificationByData(notificationMethodTypes.firebaseApi, notificationData, null, null, null, gEnums.adminTopicTypes.appUserCheckIn)
  }
}