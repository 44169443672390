import _ from 'lodash';
import { gEnums } from '../../enums/globalEnums';
import { compareData, getNonExistingItems } from '../../firestoreData/helpers/compareData';
import { updateFirestoreData } from '../../firestoreData/updates/dataUpdates';
import { handleDataTablePaste } from '../../viewSettings/importing/pasteHelpers';
import { responseReducers, grts } from './reducerHelpers/dispatchProps';
// import { convertToWYSIWYG } from '../../common/convert';

const rts = {
  handleGridPaste: 'handleGridPaste',
  handlePaste: 'handlePaste',
  getPasteData: 'getPasteData',
  handleSuccess: 'handleSuccess',
  handleError: 'handleError',
  handleCommitData: 'handleCommitData',
  handleUpdatePaste: 'handleUpdatePaste',
  handlePasteError: 'handlePasteError',
  handlePasteResults: 'handlePasteResults',
  handleUpdateFirestoreFromPaste: 'handleUpdateFirestoreFromPaste',
  handleUpdatePasteFromFirestore: 'handleUpdatePasteFromFirestore',
  ...grts
}

export const pasteReducer = (state, action) => {

  const { paps_state, viewItem, viewListData, itemData, page_fns, allInfo, database_fns } = state
  const { type, dispatch, pasteValue, view, updatedData } = action

  switch (type) {

    case rts.handleCommitData:

      const ufProps = {
        dataUpdateType: gEnums.dataUpdateTypes.mergeData,
        allInfo,
        itemData,
        vit: viewItem.key,
        view: view
      }

      if (ufProps.dataUpdateType) {
        updateFirestoreData(dispatch, paps_state, ufProps, page_fns, null, null, null, database_fns)
      }

      return { ...state }

    case rts.handleUpdatePaste:
      return {
        ...state,
        itemData: updatedData,
      }

    case rts.handleGridPaste:
      // const itemz = handleGridPaste(pasteValue)
      return { ...state }

    case rts.handlePaste:
      try {
        // const xxx = convertToWYSIWYG(pasteValue)
        // console.log('xxx', xxx)
        const parsed = JSON.parse(pasteValue)
        const all = getAllItems(parsed)
        const combinedResults = viewItem && viewListData ? compareData(viewItem, viewListData, all) : null
        const { combinedItems } = combinedResults ?? {}
        return {
          ...state,
          pasteValue,
          jsonData: parsed,
          itemData: all,
          tableItems: null,
          combinedItems
        }
      } catch (error) {
        try {
          const items = handleDataTablePaste(pasteValue)
          const all = getAllItems(items.items)
          const combinedResults = viewItem && viewListData ? compareData(viewItem, viewListData, all) : null
          const { combinedItems } = combinedResults ?? {}

          const { nonExistingItems, existingCount } = getNonExistingItems(action.existingItems, all, ['firstName', 'lastName'])

          return {
            ...state,
            tableItems: items,
            jsonData: null,
            itemData: all,
            uniqueItems: nonExistingItems,
            existingCount,
            combinedItems
          }
        } catch (error2) {
          console.log('error2', error2)
        }
      }

      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })


    default:
      return { ...state }
  }
}

export const pasteInitialState = (paps_state, viewItem, viewListData, page_fns) => {
  return { paps_state, viewItem, viewListData, page_fns }
};

export const pasteHandlers = (dispatch) => {
  return {
    handleGridPaste: (pasteValue, existingItems) => { dispatch({ type: rts.handleGridPaste, dispatch, pasteValue, existingItems }) },
    handlePaste: (pasteValue, existingItems) => { dispatch({ type: rts.handlePaste, dispatch, pasteValue, existingItems }) },
    handleCommitData: (view) => { dispatch({ type: rts.handleCommitData, dispatch, view }) },
    handleSuccess: (fn_response) => { dispatch({ type: rts.updateSuccess, dispatch, fn_response }) },
    handleError: (fn_response) => { dispatch({ type: rts.updateError, dispatch, fn_response }) },
    handleUpdatePaste: (updatedData) => { dispatch({ type: rts.handleUpdatePaste, dispatch, updatedData }) },
  }
}
const getAllItems = (data) => {
  const all = {}
  Object.keys(data).forEach(key => {
    const item = data[key]
    const realKey = item.id ? item.id : key
    all[realKey] = {}
    Object.keys(item).forEach(itemKey => {
      if (item[itemKey] && itemKey !== 'id') {
        all[realKey][_.camelCase(itemKey)] = item[itemKey]
      }
    })
  })
  return all
}

// function formatToWYSIWYG(text) {
//   // Replace line breaks with <br> tags
//   var formattedText = text.replace(/\n/g, '<br>');

//   // Replace multiple spaces with &nbsp; entities
//   formattedText = formattedText.replace(/ {2,}/g, function (space) {
//     return Array(space.length).join('&nbsp;') + ' ';
//   });

//   // Replace leading spaces with &nbsp; entities
//   formattedText = formattedText.replace(/^ /g, '&nbsp;');

//   // Replace consecutive spaces at the end of lines with &nbsp; entities
//   formattedText = formattedText.replace(/ +<br>/g, function (space) {
//     return Array(space.length - 4).join('&nbsp;') + '<br>';
//   });

//   return formattedText;
// }


