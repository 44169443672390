import React from 'react'
import { Icon, Label, Popup, Segment } from 'semantic-ui-react'

const _usePopup = false
const _size = 'small'

const StandingsLegend = (props) => {

  const { playoffTeamsPerSection } = props ?? {}

  const content = () => <Segment basic className='standings-legend' inverted>
    <Label size={_size}>{'Playoff Locked'}</Label>
    <Label size={_size}>{'Playoff Position'}{' '}{'(Top ' + playoffTeamsPerSection + ')'}</Label>
    <Label size={_size}>Non Playoff Position</Label>
    <Label size={_size}>Non Playoff</Label>
    <Label size={_size}>W - Wins</Label>
    <Label size={_size}>L - Losses</Label>
    <Label size={_size}>{'P - Points'}{' '}{'(*not is use for official standings)'}</Label>
    <Label size={_size}>Win 3-0 or 3-1: 3-Points</Label>
    <Label size={_size}>Win 3-2: 2-Points</Label>
    <Label size={_size}>Loss 3-2: 1-Point</Label>
    <Label size={_size}>Loss 3-0 or 3-1: 0-Points</Label>
  </Segment>

  return _usePopup ? <Popup
    className='standings-legend-pop'
    content={content()}
    on='click'
    position='left center'
    trigger={<Icon className='standings-legend-icn' name='info' inverted circular color={'blue'} />}
  /> : content()
}

export default StandingsLegend
