import { collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, query, where } from "firebase/firestore";
import _ from 'lodash';
import { _globalCollectionName } from "../../../projectSpecific/sports/dbActions/globals";
import { convertSnapshot } from "../../cnr/contexts/contextHelpers";
import { isOdd } from "../../common/filtering";
import { doc_set } from "./fsData";
import { createRefPath, createRefPath_client, createRefPath_event } from "./appRefPaths";
import { fbAppConfigs } from "../../../project/appConfigs";
import { initializeApp } from "firebase/app";
import { dispatchProps } from "../../cnr/reducers/reducerHelpers/dispatchProps";
import { _projectAppNames } from "../../../project/appConfiguration";

const _showFsLogs = false
const _showQueries = false
const _showFsErrorLogs = true
const _usePojo = false

export const _dataOptions = {
  ignoreId: 'ignoreId',
  listen: 'listen',
  returnFirstObject: 'returnFirstObject'
}

const getFs = (init_alt, fsdb) => {

  let _init_alt;

  const _host_doc = document.location.host

  if (_host_doc === 'thumbstat.com') {
    const altDb = _usePojo ? _projectAppNames.pojo : _projectAppNames.meetingevolution
    const environment = 'dev'
    const _fsKey = altDb && environment ? altDb + '_' + environment : null
    const _config_alt = fbAppConfigs[altDb][environment]
    _init_alt = initializeApp(_config_alt, _fsKey)
    return getFirestore(_init_alt)
  }

  return getFirestore(init_alt)
}

export const fs_db = {
  get_fs: (init_alt, fsdb) => getFs(init_alt, fsdb), // 32
  get_collection: (props, fsdb) => fs_get_collection(props, fsdb), // 2
  get_collectionExists: (props) => fs_get_collectionExists(props), // 2
  get_data_any: (props) => fs_get_data_any(props), // 17
  get_data: (props, fsdb, cb) => fs_get_data(props, fsdb, cb), // 148
  get_doc: (props, fsdb) => fs_get_doc(props, fsdb), // 13
  get_docs: (prom, opts) => get_docs(prom, opts), // 2
  get_eventData_global: (pathViews, collectionName, returnFirstObject, callback, listen, cbProps) => fs_get_eventData_global(pathViews, collectionName, returnFirstObject, callback, listen, cbProps), // 4
  get_rootData: (collectionName, listen, docKey, filters, pathViewFilters, callback) => fs_get_rootData(collectionName, listen, docKey, filters, pathViewFilters, callback), // 1
}

export const createFsDocKey = () => {
  const fs = getFs()
  const ssd = doc(collection(fs, 'generic'));
  // console.log('createFsDocKey', keyType, collectionKey)
  return ssd.id
}

export const arrayString = (arr) => {
  let path = ''
  arr.forEach((item, index) => {
    path += item
    if (index < arr.length - 1) {
      path += '/'
    }
  })
  return path
}

const _callbackOpts = (data, cbProps, ref, cbData, error) => {
  return {
    data,
    cbProps,
    ref,
    cbData,
    error
  }
}

/**
 * 
 * @param {string} collectionName the name of collection to retrieve
 * @param {boolean} listen 
 * @param {string} docKey 
 * @param {array} filters 
 * @param {array} pathViewFilters 
 * @param {funtion} callback 
 * @description Gets data from the `root` level
 */
const fs_get_rootData = (collectionName, listen, docKey, filters, pathViewFilters, callback) => {

  let _refPath = createRefPath([collectionName])
  const wheres = []

  if (filters) {
    filters.forEach(whereProp => {
      wheres.push(where(whereProp.prop, '==', whereProp.value))
    })
  } else if (pathViewFilters) {
    pathViewFilters.forEach(whereProp => {
      wheres.push(where('pathViews.' + whereProp.prop, '==', whereProp.value))
    })
  } else if (docKey) {
    _refPath = createRefPath([docKey], _refPath)
  }

  if (listen) {
    fs_db.get_data({ refPath: _refPath, opts: { returnFirstObject: true }, callback })
  } else {
    fs_db.get_data({ refPath: _refPath, opts: { returnFirstObject: true }, callback })
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {string} collectionName 
 * @param {funtion} callback 
 * @param {boolean} listen 
 * @description Gets data from a global collection at the `events` level
 */
const fs_get_eventData_global = (pathViews, collectionName, returnFirstObject, callback, listen, cbProps) => {
  let _refPath = createRefPath_event(pathViews, [_globalCollectionName, collectionName])
  fs_db.get_data({ refPath: _refPath, callback, opts: { ignoreId: true, listen, returnFirstObject, cbProps } })
}

/**
 * 
 * @param {*} refPath 
 * @param {*} data 
 * @param {*} dispatch 
 * @param {*} forPromise 
 * @description Gets all the documents in a collection
 */
const fs_get_collection = async (props, fsdb) => {

  const { refPath, forPromise, callback, fs } = props

  if (refPath) {
    const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
    const _altFs = fsdb ? fsdb : fs
    const _fs = _altFs ? _altFs : getFs()
    const docsRef = collection(_fs, ps)
    if (forPromise) { return docsRef }
    try {
      const qs = await getDocs(docsRef)
      const docs = convertSnapshot(qs)
      callback && callback({ type: dispatchProps.success, callback })
      return docs
    } catch (error) {
      callback && callback({ type: dispatchProps.error, callback, error })
      return { error }
    }
  }

  return {}
}

const fs_get_collectionExists = async (props) => {

  const { refPath, callback, fs } = props

  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const _fs = fs ? fs : getFs()
  const docsRef = collection(_fs, ps)
  const q = query(docsRef, null, limit(1))
  try {
    const qs = await getDocs(q)
    const docs = convertSnapshot(qs)
    callback && callback({ type: dispatchProps.success, callback })
    return docs
  } catch (error) {
    console.log('error', docsRef, error)
  }
  return {}
}


/** 
 * @param {object} props (refPath, wheres, callback, opts, cbData, cbo, fs)
 * @props {string} refPath
 * @props {array} wheres
 * @props {function} callback (data, cbProps, ref, cbData, error) 
 * @props {object} opts (cbProps, listen, ignoreId)
 * @returns gets date from firestore
 * @description options
 */
const fs_get_data = async (props, fsdb) => {

  const { refPath, wheres, callback, opts, fs } = props
  const { listen, altDb } = opts ?? {}

  if (!fsdb) {
    console.log('fsdb NO', refPath)
  }

  if (!refPath) { return {} }

  let _init_alt;

  const _altDb = _projectAppNames.meetingevolution

  if (altDb) {
    const environment = 'dev'
    const _fsKey = altDb && environment ? altDb + '_' + environment : null
    const _config_alt = fbAppConfigs[altDb][environment]
    _init_alt = initializeApp(_config_alt, _fsKey)
  }

  const _altFs = fsdb ? fsdb : fs
  const _fs = _altFs ? _altFs : getFs(_init_alt)

  let ps = _.isArray(refPath) ? arrayString(refPath) : refPath

  const count = ps.split('/').length
  const isCollection = isOdd(count) ? true : false

  // do we have a refPath
  if (refPath && refPath.length > 0) {
    // are there any wheres
    if (wheres && wheres.length > 0) {
      if (isCollection) {
        // collection query
        if (callback || listen) {
          return collection_listen_query(_fs, ps, wheres, callback, opts)
        } else {
          return collection_get_query(_fs, ps, wheres, opts)
        }
      } else {
        // document query
        if (callback || listen) {
          return doc_listen(_fs, ps, callback, opts)
        } else {
          return doc_get(_fs, ps, opts)
        }
      }
    } else {
      if (isCollection) {
        // collection
        if (callback || listen) {
          return collection_listen(_fs, ps, callback, opts)
        } else {
          return collection_get(_fs, ps, opts)
        }
      } else {
        // document 
        if (callback || listen) {
          return doc_listen(_fs, ps, callback, opts)
        } else {
          return doc_get(_fs, ps, opts)
        }
      }
    }
  }
}

/**
 * 
 * @param {*} pathViews 
 * @param {*} collectionName 
 * @param {*} callback 
 * @param {*} opts {cbProps, clientPath, dataParents, docKey, filters, forPromise, forPromise, listen}
 * @returns data from fs_get_data 
 */
const fs_get_data_any = (props) => {

  const { pathViews, collectionName, callback, opts } = props ?? {}
  const { cbProps, clientPath, dataParents, docKey, filters, forPromise, sportsKey } = opts ?? {}

  const wheres = []

  let _refPath;
  if (pathViews.events && !clientPath) {
    _refPath = createRefPath_event(pathViews, [collectionName])
  } else if (pathViews.clients) {
    _refPath = createRefPath_client(pathViews, [collectionName])
  } else {
    _refPath = [collectionName]
  }

  if (filters) {
    filters.forEach(whereProp => {
      wheres.push(where(whereProp.prop, '==', whereProp.value))
    })
  } else if (docKey) {
    _refPath += '/' + docKey
  }

  if (dataParents) {
    dataParents.forEach(dp => {
      if (pathViews[dp]) {
        switch (dp) {
          case 'sports':
            if (sportsKey) {
              wheres.push(where('parentKeys.' + dp, '==', sportsKey))
            } else {
              wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            }
            break;
          default:
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
        }
      }
    })
  }

  if (forPromise) {
    return doc_set(_refPath)
  } else {
    fs_db.get_data({ refPath: _refPath, wheres, callback, opts, cbProps })
  }
}

/**
 * 
 * @param {*} refPath 
 * @param {*} data 
 * @param {*} dispatch 
 * @param {*} forPromise 
 * @description Gets a single document
 */
const fs_get_doc = async (props, fsdb) => {
  const { refPath, callback, forPromise, fs } = props
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const _altFs = fsdb ? fsdb : fs
  const _fs = _altFs ? _altFs : getFs()
  if (forPromise) { return doc(_fs, ps) }
  const ref_d = doc(_fs, ps)
  const docSnap = await (getDoc(ref_d))
  if (docSnap.exists()) {
    const data = convertSnapshot(docSnap, true, { returnFirstObject: true })
    callback && callback(data)
    return data
  } else {
    callback && callback({})
    return {}
  }
}

/** returns firestore data from `getDoc` and converts the data using convertSnapshot
 * @param prom - the promise
 * @param {object} opts - the options(uivi, isCombinedData, ignoreId, returnFirstObject)
 */
const get_docs = async (prom, opts) => {
  const data = await getDoc(prom);
  const ssd = convertSnapshot(data, true, opts)
  _showFsLogs && console.log('get_docs', ssd)
  return ssd
}

/**
 *  @param {object} fs - the firestore object. This can be null.
 * @param {string or array} ps - the path
 * @param {object} opts - the options(uivi, isCombinedData, ignoreId, returnFirstObject)
 * @returns firestore data from `getDoc` and converts the data using convertSnapshot
 */
const doc_get = async (fs, ps, opts) => {
  const _fs = fs ? fs : getFs()
  if (ps.startsWith('/')) { ps = ps.substring(1) }
  try {
    if (ps && ps.split('/').length > 1 && !isOdd(ps.split('/'))) {
      const ref_d = doc(_fs, ps)
      if (opts && opts.refOnly) { return ref_d }
      const data = await getDoc(ref_d);
      const ssd = convertSnapshot(data, true, opts)
      _showFsLogs && console.log('doc_get', ps, ssd)
      return ssd
    }
  } catch (error) {
    _showFsErrorLogs && console.error('error', error, ps)
    return { error }

  }
}

/** returns firestore data from `onSnapshot` and converts the data using convertSnapshot
 * @param {object} fs - the firestore object. This can be null.
 * @param {string or array} ps - the path
 * @param cbProps - callback props that will be passed into the callback function
 * @param {object} opts - the options(uivi, isCombinedData, ignoreId, returnFirstObject)
 * @description - this HAS a callback. The data will NOT returned
 */
const doc_listen = async (fs, ps, callback, opts) => {
  const { cbData, cbProps, cbo, refOnly, returnFsr } = opts ?? {}
  const _fs = fs ? fs : getFs()
  // listen
  const ref_d = doc(_fs, ps)
  if (refOnly) { return ref_d }
  onSnapshot(ref_d, (snapshot) => {
    const ssd = convertSnapshot(snapshot, true, opts)
    _showFsLogs && console.log('doc_listen', ps, ssd, opts)
    // data, cbProps, ref, cbData, error 
    if (callback) {
      if (cbo) {
        callback(_callbackOpts(ssd, cbProps, returnFsr ? ref_d : null, cbData))
      } else {
        callback(ssd, cbProps, returnFsr ? ref_d : null, cbData)
      }
    }
    return ssd
  },
    (error) => {
      _showFsErrorLogs && console.error('error', error, ps)
      if (callback) {
        if (cbo) {
          callback(_callbackOpts({}, cbProps, returnFsr ? ref_d : null, cbData, error))
        } else {
          callback({}, cbProps, returnFsr ? ref_d : null, cbData, error)
        }
      }
    });
}

/**
 * 
 * @param {object} fs - the firestore object. This can be null.
 * @param {string or array} ps - the path
 * @param {object} opts 
 * @returns 
 */
const collection_get = async (fs, ps, opts) => {
  const { refOnly } = opts ?? {}
  const _fs = fs ? fs : getFs()
  const ref_c = collection(_fs, ps)
  if (refOnly) { return ref_c }
  const data = await getDocs(ref_c);
  const ssd = convertSnapshot(data, false, opts)
  _showFsLogs && console.log('collection_get', ps, ssd, opts)
  return ssd
}

const collection_get_query = async (fs, ps, wheres, opts) => {
  const { dataLimit, refOnly } = opts ?? {}
  const _fs = fs ? fs : getFs()
  const ref_c = collection(_fs, ps)
  const q = dataLimit ? query(ref_c, ...wheres, limit(dataLimit)) : query(ref_c, ...wheres)
  if (refOnly) { return q }
  const data = await getDocs(q)
  const ssd = convertSnapshot(data, false, opts)
  _showFsLogs && console.log('collection_get', ps, wheres, opts)
  _showQueries && showQ(q)
  return ssd
}

const collection_listen = (fs, ps, callback, opts) => {
  const { cbData, cbProps, cbo, dataLimit, refOnly, returnFsr } = opts ?? {}
  const _fs = fs ? fs : getFs()
  const ref_c = collection(_fs, ps)
  const q = dataLimit ? query(ref_c, limit(dataLimit)) : ref_c
  if (refOnly) { return ref_c }
  onSnapshot(q, (snapshot) => {
    const ssd = convertSnapshot(snapshot, false, opts)
    _showFsLogs && console.log('collection_listen', ps, ssd, opts)
    _showQueries && showQ(q)
    if (callback) {
      if (cbo) {
        callback(_callbackOpts(ssd, cbProps, returnFsr ? ref_c : null, cbData))
      } else {
        callback(ssd, cbProps, returnFsr ? ref_c : null, cbData)
      }
    }
    return ssd
  },
    (error) => {
      _showFsErrorLogs && console.error('error', ps, error)
      if (callback) {
        if (cbo) {
          callback(_callbackOpts({}, cbProps, returnFsr ? ref_c : null, cbData, error))
        } else {
          callback({}, cbProps, returnFsr ? ref_c : null, cbData, error)
        }
      }
      return {}
    });
}

const collection_subCollection = async (data, ps, subCollection, opts, cbProps, callback) => {

  const dataCount = data ? Object.keys(data).length : 0
  const subItems = {}

  const cb_sub = (data_sub, opts_sub) => {
    const { dk, parentKeys, pks } = opts_sub
    subItems[dk] = data_sub
    subItems[dk]._parentKeys = parentKeys
    if (Object.keys(subItems).length === dataCount) {
      callback(subItems, { ...cbProps, parentKeys, pks })
    }
  }

  if (data) {
    Object.keys(data).forEach(dk => {
      const dataItem = data[dk]
      const { _itemKey, parentKeys } = dataItem ?? {}
      const _ps = ps + '/' + _itemKey + '/' + subCollection
      const _opts = { ...opts, cbProps: { dk, parentKeys, pks: opts.pks } }
      delete _opts.subCollection
      fs_db.get_data({ refPath: _ps, callback: cb_sub, opts: _opts })
    })
  }
}

/** Returns a collection (listen, query)  */
const collection_listen_query = async (fs, ps, wheres, callback, opts) => {
  const { cbData, cbProps, cbo, dataLimit, _orderBy, refOnly, returnFsr, subCollection } = opts ?? {}
  const _fs = fs ? fs : getFs()
  const ref_c = collection(_fs, ps)
  if (_orderBy) {
    console.log('_orderBy', dataLimit, _orderBy)
  }
  let q;
  if (dataLimit && _orderBy) {
    q = query(ref_c, ...wheres, limit(dataLimit), _orderBy)
  } else if (dataLimit) {
    q = query(ref_c, ...wheres, limit(dataLimit))
  } else {
    q = query(ref_c, ...wheres)
  }
  if (refOnly) { return q }
  onSnapshot(q, (snapshot) => {
    const ssd = convertSnapshot(snapshot, false, opts)
    _showFsLogs && console.log('collection_listen_query', ps, ssd, wheres, opts)
    _showQueries && showQ(q)
    if (subCollection) {
      collection_subCollection(ssd, ps, subCollection, opts, cbProps, callback)
    } else {
      if (callback) {
        if (cbo) {
          callback(_callbackOpts(ssd, { ...cbProps }, returnFsr ? q : null))
        } else {
          callback(ssd, { ...cbProps }, returnFsr ? q : null)
        }
      }
    }
    return ssd
  },
    (error) => {
      _showFsErrorLogs && console.error('error', ps, error)
      if (callback) {
        if (cbo) {
          callback(_callbackOpts({}, cbProps, returnFsr ? ref_c : null, cbData, error))
        } else {
          callback({}, cbProps, returnFsr ? ref_c : null, cbData, error)
        }
      }
      return {}
    });
}

const showQ = (q) => {
  const { _query } = q
  const { filters } = _query ?? {}
  if (filters) { showF(filters) }
}

const showF = (filters) => {
  filters.forEach((f, index) => {
    const { field, op, value } = f
    const { segments } = field ?? {}
    const { stringValue, arrayValue } = value ?? {}
    const { values } = arrayValue ?? {}
    if (stringValue) {
      console.log('_filter', index, segments, op, stringValue);
    } else {
      console.log('_filter', index, segments, op, values);
    }
  })
}