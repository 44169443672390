import _ from 'lodash'
import { frameworkRightTypes } from "../cnr/reducers/FrameworkReducer"
import { rightMenuTriggerTypes, rightMenuTypes } from '../cnr/reducers/RightMenuReducer'
import { appIconTypes } from "../enums/appIconTypes"
import { settingsIconTypes } from '../enums/settingsIconTypes'
import { dataModificationTypes } from '../viewSettings/enums/itemActionTypes'

const _useDataModification = true

export const appOptions_right = {
  home: (handlers, settings) => options_page_home(handlers, settings),
  page: (handlers, settings) => options_page(handlers, settings),
  data: (handlers, settings) => options_data(handlers, settings),
  subAction: (handlers, settings) => options_subAction(handlers, settings),
  subActions: (handlers, settings) => options_subActions(handlers, settings),
  propz: (handlers, settings) => options_propz(handlers, settings)
}

const getOptions = (oc) => ({
  propOverride: {
    text: 'Props Override',
    icon: 'wizard',
    color: 'grey',
    oc: oc,
    dividerBefore: true,
    rmt: rightMenuTypes.propsOverride,
  },
  subAction: {
    text: 'Actions',
    icon: 'wizard',
    color: 'grey',
    oc: oc,
    dividerBefore: true,
    rmt: rightMenuTypes.subActions,
  },
});

const options_subAction = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  const opts = options_subActions(handlers, settings, true)
  if (opts.length > 0) {
    return [{ ...getOptions(hs_cmt).subAction }]
  }
  return []
}

const options_page_home = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const { isEvent, _allowDatabase, showRightMenuCaptions } = settings ?? {}
  const opts = []
  isEvent && opts.push({ text: 'Props Override', icon: 'unordered list', color: 'grey', oc: hs_cmt, rmt: rightMenuTypes.propsOverride, dividerBefore: true })
  isEvent && _allowDatabase && opts.push({ text: 'Database', icon: 'database', oc: h_ta, rmt: rightMenuTriggerTypes.openDb, color: 'orange', iconCorner: 'arrow right', iconCornerColor: 'grey', dividerBefore: true })
  opts.push({ text: showRightMenuCaptions ? 'Close Menu' : 'Show Menu', icon: showRightMenuCaptions ? 'arrow right' : 'arrow left', oc: h_ta, rmt: rightMenuTriggerTypes.toggle, color: 'grey', dividerBefore: true })
  return opts
}

const options_page = (handlers, settings) => {

  const { frAllows, isLanding, key_viewItem, allowSd } = settings ?? {}
  const { handle_triggerAction: h_ta } = handlers ?? {}

  const rmt = rightMenuTriggerTypes.frameworkRight

  const opts = []

  opts.push({ text: 'Settings', icon: appIconTypes.setting, oc: h_ta, rmt: frameworkRightTypes.settings, color: 'blue' })

  if (!isLanding) {
    const _iconColor = frAllows.isTab ? 'green' : null
    opts.push({ text: 'Props (' + _.startCase(key_viewItem) + ')', icon: 'list alternate outline', oc: h_ta, rmt, frt: frameworkRightTypes.props, dividerBefore: true })
    opts.push({ text: 'Display (' + _.startCase(key_viewItem) + ')', icon: 'television', oc: h_ta, rmt, frt: frameworkRightTypes.display })
    opts.push({ text: 'Data Constraints (' + _.startCase(key_viewItem) + ')', icon: 'shield alternate', oc: h_ta, rmt, frt: frameworkRightTypes.dataConstraints, color: _iconColor, iconCorner: frAllows.constraints ? 'check' : null, dividerBefore: true })
    // opts.push({ text: 'Filters (' + _.startCase(key_viewItem) + ')', icon: 'filter', oc: h_ta, rmt, frt: frameworkRightTypes.filters, dmt: frameworkRightTypes.filters, color: frAllows.constraints ? 'green' : _iconColor, iconCorner: frAllows.constraints ? 'check' : null, })
  }

  allowSd && opts.push({ text: 'Sports Scores', icon: 'calendar alternate outline', oc: h_ta, rmt, frt: frameworkRightTypes.pageMedia, dividerBefore: true })

  return opts
}

const options_data = (handlers, settings) => {

  const { allowSd, navigationOptions, dataOrigin } = settings ?? {}
  const { pageNavOptions } = navigationOptions ?? {}
  const { dataModification } = pageNavOptions ?? {}
  const { appData, cacheData } = dataOrigin ?? {}
  const { handle_triggerAction: h_ta } = handlers ?? {}

  const rmt = rightMenuTriggerTypes.frameworkRight

  const opts = []

  if (dataModification && _useDataModification) {
    _.each(dataModification, (_dm, dmKey) => {
      const _icon = settingsIconTypes[dmKey]
      switch (dmKey) {
        case dataModificationTypes.add:
        case dataModificationTypes.edit:
          opts.unshift({ text: _.startCase(dmKey), icon: _icon, oc: h_ta, rmt, frt: frameworkRightTypes.dataModification, dmt: dmKey, dividerBefore: true })
          break;
        case dataModificationTypes.subAction:
          break;
        default:
          opts.push({ text: _.startCase(dmKey), icon: _icon, oc: h_ta, rmt, frt: frameworkRightTypes.dataModification, dmt: dmKey })
      }
    })
    opts.unshift({ icon: 'content', text: 'Data Options', color: cacheData ? 'green' : 'grey', dividerBefore: true, headerBefore: true, iconCorner: cacheData ? 'check' : null, })
  }

  allowSd && opts.push({ text: 'Sports Scores', icon: 'calendar alternate outline', oc: h_ta, rmt, frt: frameworkRightTypes.pageMedia, dividerBefore: true })

  return opts
}


const options_subActions = (handlers, settings, forTest) => {

  const { navigationOptions } = settings ?? {}
  const { pageNavOptions } = navigationOptions ?? {}
  const { dataModification } = pageNavOptions ?? {}
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}

  const rmt = rightMenuTriggerTypes.frameworkRight

  const opts = []

  if (dataModification && _useDataModification) {
    _.each(dataModification, (dm, dmKey) => {
      const _icon = settingsIconTypes[dmKey]
      switch (dmKey) {
        case dataModificationTypes.subAction:
          const { subActionTypes } = dm ?? {}
          if (subActionTypes) {
            _.each(subActionTypes, (subActionType) => {
              opts.push({ text: _.startCase(subActionType), icon: _icon, oc: h_ta, rmt, frt: frameworkRightTypes.dataModification, dmt: subActionType, showCaption: true })
            })
          }
          break;
        default:
      }
    })
  }
  !forTest && opts.unshift(...closers(getOptions(hs_cmt).subAction, hs_cmt))
  return opts
}

// overridesChanged
const options_propz = (handlers, settings) => {
  const { overridesChanged } = settings ?? {}
  const opts = []
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  opts.push({ text: 'Save', showCaption: true, icon: 'save', color: 'green', disabled: !overridesChanged, oc: h_ta, rmt: rightMenuTriggerTypes.propsSave, frt: frameworkRightTypes.props, })
  // opts.unshift(...closers('Props Override', hs_cmt))
  opts.unshift(...closers(getOptions(hs_cmt).propOverride, hs_cmt))
  return opts
}

const closers = (options, hs_cmt) => {
  const { text } = options ?? {}
  const opts = []
  opts.push({ text: text, icon: appIconTypes.delete, oc: hs_cmt, color: 'red', showCaption: true })
  return opts
}