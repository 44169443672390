import { createContext, useReducer } from "react";
import { trackerHandlers, trackerInitialState, trackerReducer } from "../reducers/TrackerReducer";

export const TrackerContext = createContext();

const TrackerProvider = (props) => {

  const init_state = trackerInitialState({})
  const [tracker_state, dispatch] = useReducer(trackerReducer, init_state);
  const tracker_handlers = trackerHandlers(dispatch)

  return (
    <TrackerContext.Provider value={{ tracker_handlers, tracker_state }}>
      {props.children}
    </TrackerContext.Provider>
  )
}

export default TrackerProvider