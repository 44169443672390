import _ from 'lodash'
import React, { useContext } from 'react'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { dataMergeTypes, dvLabelColors } from '../../cnr/reducers/DataManagmentReducer'
import { copyObj } from '../../common_web/copy'
import { appIconTypes } from '../../enums/appIconTypes'
import { updateSettings } from '../../firestoreData/settings/updateSettings'
import { openExternal } from '../../viewSettings/helpers/settingsLinks'
import DropdownOptionsMenu from '../forms/elements/DropdownOptionsMenu'
import { DataManagementContext } from './DataManagementViewer'

const DataManagementOptions = (props) => {

  const { _uivi, key_viewItem, dataSidebarOptions, setShowDataOptions } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}
  const { pathViews, viewKey } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems } = pageSettings ?? {}
  const { googleSheets } = aps_global ?? {}
  const { allowGoogleSheets, googleSheetsId } = googleSheets ?? {}

  // dataManagementContent
  const dataManagementContent = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContent ?? {}
  const { _defaultMenuItem, activeTab, ammendedItem, dataManagementType, diffData, viewListData } = dataManagement_state ?? {}
  const { ammendedViewItem, ammendedKeys } = ammendedItem ?? {}

  const _showSettingsOpts = viewListData && Object.keys(viewListData).length === 0 ? true : false

  const allowDiffSave = diffData && Object.keys(diffData).length > 0

  const handleApplyExisting = () => dataManagement_handlers.handleDiffUpdates(dataMergeTypes.existingValue)
  const handleApplyNew = () => dataManagement_handlers.handleDiffUpdates(dataMergeTypes.newValue)
  const handleDatabaseOpen = () => openExternal.db(pathViews, null, _uivi, viewKey)
  const handleGetDiff = (opts) => dataManagement_handlers.handleGetDiff(opts)
  const handleGetNew = (opts) => dataManagement_handlers.handleGetNew(opts)
  const handleOpenGoogleSheets = () => openExternal.googleSheets(googleSheetsId)
  const handleShowExport = () => dataManagement_handlers.handleShowExport()

  const handleSelectSidebarOption = (opts) => {
    setShowDataOptions()
    dataManagement_handlers.handleViewDataMode(opts)
  }

  const handleFixProps = () => {
    const viewItems = copyObj(aps_viewItems)
    viewItems[key_viewItem] = ammendedViewItem
    updateSettings.updateViewItemsToDb(pathViews, viewItems, ammendedKeys)
  }

  // const handleDownloadToGoogleSheets = () => {
  //   const _itemData = convertViewListDataToGoogleSheetsData(appData, viewItem_previewTemp, staticViews_app, true)
  //   googleSheets_handlers.handleUpdate_googleSpreadsheetTab(_.startCase(_uivi) + ' App', _itemData)
  //   // fsfn_updateGooglsSheetValues(googleSheetsId, { key: _uivi + ' App' }, _itemData)
  // }

  // tabName, fetchFirestoreData, values
  // const handleCreateSpreadsheetTab = () => {
  //   googleSheets_handlers.handleStartUpdate()
  //   const _itemData = convertViewListDataToGoogleSheetsData(appData, viewItem_previewTemp, staticViews_app, false)
  //   googleSheets_handlers.handleCreate_googleSpreadsheetTab(_.startCase(_uivi) + ' App', false, _itemData)
  // }


  // const handleExportToGoogle = () => {
  //   const { headers } = getHeaders(appData)
  //   console.log('headers', headers)
  //   console.log('appData', appData)
  //   const { values, tabName } = getGoogleSheetDataValues(Object.values(appData), headers, _.startCase(uivi_direct))
  //   console.log('values', tabName, values)
  //   // if (matches_flat_season && Object.keys(matches_flat_season).length > 0) {
  //   //   const _matches = _.sortBy(matches_flat_season, '_itemKey')
  //   //   const headers = ['id', '_itemKey', 'startDate', 'section', 'level', 'combined', 'away', 'awayScore', 'home', 'homeScore']
  //   //   console.log('google export', tabName, values)
  //   //   fsfn_updateGoogleSheetValues(gsValues.googleSheetsId, tabName, values)
  //   // }
  // }

  const btns_dd = [];
  const btns_google = [];
  const btns_data = [];
  const btns_settings = [];

  switch (dataManagementType) {
    case _defaultMenuItem:
      switch (activeTab) {
        case 0:
          ammendedViewItem && btns_dd.push({ caption: 'Fix View Item Settings', color: 'blue', oc: handleFixProps, icon: appIconTypes.wizard })
          btns_dd.push({ caption: 'Open Database', oc: handleDatabaseOpen, color: 'orange', icon: appIconTypes.externalAlternate })
          if (allowGoogleSheets) {
            btns_google.push({ caption: 'Open Google Sheets', color: 'orange', oc: handleOpenGoogleSheets, icon: appIconTypes.externalAlternate })
            btns_google.push({ caption: 'Download to Google Sheets', color: 'orange', oc: handleShowExport, icon: appIconTypes.arrowRight })
          }
          if (dataSidebarOptions) {
            Object.keys(dataSidebarOptions).forEach(dsoKey => {
              const dataSidebarOption = dataSidebarOptions[dsoKey]
              const { allowAction } = dataSidebarOption ?? {}
              if (allowAction) {
                btns_data.push({ caption: _.startCase(dsoKey), oc: handleSelectSidebarOption, opts: { viewDataMode: dsoKey } })
              }
            })
          }

          break;
        case 1:
          btns_dd.push({ caption: 'Get Differences', oc: handleGetDiff, icon: appIconTypes.arrowRight, opts: { mergeWith: 'regData' } })
          btns_dd.push({ caption: 'Get New', oc: handleGetNew, icon: appIconTypes.arrowRight, opts: { mergeWith: 'regData' } })
          break;
        case 2:
          btns_dd.push({ caption: 'Get Differences', oc: handleGetDiff, icon: appIconTypes.arrowRight, opts: { mergeWith: 'googleData' } })
          break;
        case 3:
          btns_dd.push({ caption: 'Apply New Data', disabled: !allowDiffSave, oc: handleApplyNew, icon: 'check', color: dvLabelColors.googleData })
          btns_dd.push({ caption: 'Apply Existing Data', disabled: !allowDiffSave, oc: handleApplyExisting, icon: 'check', color: dvLabelColors.existingItems })
          break;
        default:
          break;
      }

      const subButtons = [
        { caption: 'Data Options', btns: btns_data },
        { caption: 'Google Options', btns: btns_google }
      ]

      if (_showSettingsOpts) {
        btns_settings.push({ caption: 'Fix Settings', oc: handleSelectSidebarOption, opts: { viewDataMode: 'fixSettings' } })
        subButtons.push({ caption: 'Settings Options', btns: btns_settings })
      }

      if (btns_dd.length > 0) {
        return <DropdownOptionsMenu
          menuButtons={btns_dd}
          subButtons={subButtons}
          caption={'Actions'}
          color={'blue'}
          icon={'unordered list'}
        />
      } else {
        return <div></div>
      }
    default:
      return <div></div>
  }


}

export default DataManagementOptions