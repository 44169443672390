import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Menu, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { PageDataContext } from '../../cnr/contexts/PageDataContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { appUserFormTypes } from '../../enums/appFormTypes';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import UiSaveButtons from '../buttons/UiSaveButtons';
import AppForm from '../forms/appForms/AppForm';
import ImageGallery from '../imaging/ImageGallery';
import ProfileImage from '../imaging/ProfileImage';
import UserFamily from './UserFamily';

const _aupst = gEnums.appUserProfilePageTypes

const AppUserPages = (props) => {

  const { useFullPageWrapper, menuItemsOnly, handleCancel } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { appUserProfilePages } = aps_appUserSettings ?? {}
  const { appUsers } = appUser_state ?? {}

  const { appUser_handlers } = handlers ?? {}

  // appUserAccess
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserAccessType } = appUserAccess ?? {}

  // pageDataContext
  const pageDataContext = useContext(PageDataContext);
  const { pageData_state } = pageDataContext ?? {}
  const { currentPageData } = pageData_state ?? {}

  let profileType;

  switch (appUserAccessType) {
    case gEnums.appUserAccessTypes.admin:
    case gEnums.appUserAccessTypes.superAdmin:
      profileType = gEnums.profileTypes.adminProfiles
      break;
    default:
      profileType = gEnums.profileTypes.clientProfiles
  }

  const [allowFormSave, setAllowFormSave] = useState({});
  const [allowProfileEdit, setAllowProfileEdit] = useState()
  const [allowRefresh, setAllowRefresh] = useState()
  const [confirmation, setConfirmation] = useState()
  const [currentFormData, setCurrentFormData] = useState()
  const [formTypes, setFormTypes] = useState()
  const [profileData, setProfileData] = useState();
  const [refreshing, setRefreshing] = useState()
  const [selectedAppFormType, setSelectedAppFormType] = useState()
  const [selectedMenuItem, setSelectedMenuItem] = useState()
  const [showEdit, setShowEdit] = useState()
  const [updating, setUpdating] = useState()
  const [appUserData, setAppUserData] = useState(profileData);

  const { displayName, aboutMe, bio, email } = profileData ?? {}

  const _cb = (result) => {
    const { email } = result ?? {}
    setProfileData(result)
    setAppUserData(result)
    setAllowProfileEdit((appUserAccess.email === email))
  }

  useEffect(() => {
    // if the currentPageData changes, get the profile data from the client profiles collections
    if (currentPageData) {
      const { email } = currentPageData
      const _refPath = createRefPath_client(pathViews, ['profiles', email])
      fs_db.get_data({ refPath: _refPath, callback: _cb, opts: { returnFirstObject: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageData, appUserAccess]);

  useEffect(() => {
    if (appUserProfilePages) {
      const aufts = {}
      Object.keys(appUserFormTypes).forEach(auft => {
        const _auft = auft.replace('appUser', '')
        if (appUserProfilePages.includes(_.camelCase(_auft))) {
          aufts[auft] = appUserFormTypes[auft]
        }
      })
      setFormTypes(aufts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserProfilePages]);

  const callback_save = (result) => {
    if (result && result.error) {
      const confirmation = {
        ...result,
      }
      setConfirmation(confirmation)
    } else {
      setUpdating()
      setShowEdit()
      setSelectedMenuItem()
    }
  }

  /** Save the tournament to the database */
  const handleSave = () => {
    setUpdating(true)
    appUser_handlers.handleUpdateAppUserProfile(appUserData, profileType, callback_save)
  }

  const handleMenuItemSelect = (smi) => {
    if (smi === selectedMenuItem) {
      setSelectedMenuItem()
      setAllowFormSave(false)
    } else {
      let ft = 'appUser' + _.startCase(smi)
      ft = ft.replace(' ', '')
      setSelectedAppFormType(ft)
      setSelectedMenuItem(smi)
      handleAllowEdit(smi)

      let _currentFormData;

      switch (ft) {
        case appUserFormTypes.appUserPrivacy:
          _currentFormData = appUserData.locationSettings
          break;
        case appUserFormTypes.locationSettings:
          _currentFormData = appUserData.locationSettings
          break;
        case appUserFormTypes.notificationSettings:
          _currentFormData = appUserData.notificationSettings
          break;
        case appUserFormTypes.permissions:
          _currentFormData = appUserData.permissionSettings
          break;
        default:
          _currentFormData = appUserData
      }
      setCurrentFormData(_currentFormData)
    }
  }

  const handleAllowEdit = (smi) => {
    let _allowSave = false;
    let _allowRefresh = false;
    switch (smi) {
      case _aupst.appUserProfile:
        _allowSave = true
        break;
      case _aupst.appUserProfileImage:
      case _aupst.appUserGallery:
        _allowSave = false
        _allowRefresh = true
        break;
      default:
        break;
    }
    setAllowFormSave(_allowSave)
    setAllowRefresh(_allowRefresh)
  }

  const handleShowLocalEdit = () => {
    if (showEdit) {
      setShowEdit(false)
    } else {
      setShowEdit(true)
      let ft = 'appUser' + _.startCase(selectedMenuItem)
      ft = ft.replace(' ', '')
      setSelectedAppFormType(ft)
    }
  }

  const handleRefresh = () => setRefreshing(!refreshing)

  const handleUpdateLocalForm = (d) => {
    let _appUserData;
    switch (selectedAppFormType) {
      case appUserFormTypes.notificationSettings:
        _appUserData = { ...appUserData }
        _appUserData['notificationSettings'] = d
        break;
      case appUserFormTypes.permissions:
        _appUserData = { ...appUserData }
        _appUserData['permissionSettings'] = d
        break;
      default:
        _appUserData = { ...appUserData, ...d }
    }
    setAppUserData(_appUserData)
  }

  // a single menuItem
  const menuItem = (aft) => {
    const _aft = aft.replace('appUser', '')
    return <Menu.Item key={uniqueKey('sml', aft)} onClick={(e) => { handleMenuItemSelect(aft) }}>
      <Menu.Header>
        <Icon name={appIconTypes[_.camelCase(_aft)]} style={{ marginRight: '1em' }} inverted circular color={'blue'} />
        {_.startCase(_aft)}
      </Menu.Header>
    </Menu.Item>
  }

  // the menuItems that will appear
  const menuItems = () => {
    const _appUserProfileSettings = []
    if (appUserProfilePages) {
      appUserProfilePages.forEach(aup => {
        switch (aup) {
          case _aupst.appUserFamily:
          case _aupst.appUserGallery:
          case _aupst.appUserProfileImage:
            _appUserProfileSettings.push(aup)
            break;
          // these are appUser settings. Do not display
          case _aupst.appUserProfile:
            if (allowProfileEdit) {
              _appUserProfileSettings.push(aup)
            }
            break;
          default:
          // nothing 
        }
      })
    }

    return _appUserProfileSettings.map(aups => (menuItem(aups)))
  }

  // The menu
  const appUserProfileSettingsMenu = () => <Menu
    key={uniqueKey('usm')}
    vertical={true}
    secondary
    fluid
    className='menu-app-settings'>
    {menuItems()}
  </Menu>

  /**
   * 
   * @param {string} saft 
   * @returns an appForm
   */
  const appUserAppFormContent = (saft) => {
    const _selectedAppFormType = saft ? saft : selectedAppFormType
    switch (_selectedAppFormType) {
      // case appUserFormTypes.appUserProfileImage:
      //   return <ProfileImage />
      default:
        // appForm has its own save button  
        return <AppForm
          appFormType={_selectedAppFormType}
          handleSave={allowProfileEdit && handleSave}
          handleUpdateParentData={handleUpdateLocalForm}
          parentData={currentFormData}
          updating={updating}
          confirmation={confirmation}
        />
    }
  }

  const selectedContent = () => {

    let mmc;

    switch (selectedMenuItem) {

      case _aupst.aboutMe:
        mmc = aboutMe
        break;

      case _aupst.bio:
        mmc = bio
        break;
      case appUserFormTypes.profile:
        mmc = ''
        break;

      case 'notifications':
      case 'notificationSettings':
        return appUserAppFormContent(appUserFormTypes.notificationSettings)

      case 'permissions':
        return appUserAppFormContent(appUserFormTypes.permissions)

      case _aupst.family:
        return <UserFamily />

      case _aupst.appUserGallery:
        return <ImageGallery
          storageLocationType={gEnums.storageLocationTypes.profileGallery}
          storageType={gEnums.storageTypes.image}
          email={email}
          refreshing={refreshing}
        />

      case _aupst.appUserProfileImage:
        return <ProfileImage email={appUserData.email} />

      default:
        break;
    }
    return mmc ? <Segment basic>{mmc}</Segment> : <NoData altCaption={_.startCase(selectedMenuItem)} />
  }

  const footer = () => <UiSaveButtons
    save={{ caption: 'Edit', oc: handleShowLocalEdit, color: 'blue', icon: appIconTypes.arrowRight }}
  ></UiSaveButtons>

  const selectedContentWrapper = () => <Wrapper
    content={selectedContent()}
    footer={allowProfileEdit && allowFormSave && footer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  const fpw_selectedItem = () => <FullPageWrapper
    content={selectedContentWrapper()}
    topperCaption={_.startCase(selectedMenuItem)}
    topperCaption2={displayName}
    handleCancel={handleMenuItemSelect}
    handleRefresh={allowRefresh && handleRefresh}
    isBack={true}
  ></FullPageWrapper >

  const fpw_selectedEdit = () => <FullPageWrapper
    content={appUserAppFormContent()}
    topperCaption={displayName}
    topperCaption2={selectedMenuItem}
    handleCancel={handleShowLocalEdit}
    isBack={true}
  ></FullPageWrapper >

  const fpw_menu = () => <FullPageWrapper
    content={formTypes && appUserProfileSettingsMenu()}
    topperCaption={'Settings'}
    topperCaption2={displayName}
    handleCancel={handleCancel}
  ></FullPageWrapper >

  if (useFullPageWrapper) {
    if (selectedAppFormType) {
      return fpw_selectedEdit(selectedAppFormType)
    } else {
      return fpw_menu()
    }
  } else if (menuItemsOnly) {
    if (formTypes) {
      if (selectedMenuItem) {
        if (showEdit) {
          return fpw_selectedEdit()
        } else {
          return fpw_selectedItem()
        }
      } else {
        return menuItems()
      }
    } else {
      return <div></div>
    }
  } else {
    return appUserAppFormContent()
  }
}

export default AppUserPages