import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Icon, Label, Menu, Ref, Segment, Sidebar } from 'semantic-ui-react'
import { getAppUserLinks } from '../auth/accessPermissions'
import { getAppUserAccess } from '../auth/appUserAccessPermissions'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { UiItemContext } from '../cnr/contexts/UiItemContext'
import { uniqueKey } from '../common/keys'
import SegIconGroup from '../components/layout/gridItems/SegIconGroup'
import AlphabetMenu from '../components/menus/AlphabetMenu'
import { appIconTypes } from '../enums/appIconTypes'
import { gEnums } from '../enums/globalEnums'
import { itemActionIcons, itemActionTypes } from '../viewSettings/enums/itemActionTypes'
import { openExternal } from '../viewSettings/helpers/settingsLinks'
import { PageAreaContext } from '../cnr/contexts/PageAreaContext'
import { DataContext } from '../cnr/contexts/DataContext'

/**
 * Displays a `Sidebar`at the bottom of Item UI with options based on which actions are permitted
 * @param {object} props (content, item_state, item_handlers, componentContexts) 
 * @returns 
 */
const UiItemSidebar = (props) => {

  const asMenu = true
  const _allowSingleItemPopup = false

  // keep as is
  const { content, fromNavigation, handleMouseMove, handleAlphaChange } = props

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ?? {}
  const { pageItemsShown } = pageArea_state ? pageArea_state : {}

  // dataContext
  const dataContext = useContext(DataContext)
  const { data_state } = dataContext ?? {}
  const { viewListData } = data_state ?? {}
  const vldCount = viewListData ? Object.keys(viewListData).length : 0

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { viewItem_key, dataItemCount, modifySidebarOpen, settingsSidebarOpen, viewItemStatus, _groupingOpts, _dataModificationOpts, itemData: _itemData } = item_state ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings } = parentContext ?? {}
  const { appAccess_state, appUser_state, page_state, paps_state, searchParam_state } = states
  const { appUser_fns } = fns
  const { appUser_handlers, appSettings_handlers } = handlers

  const { isGoogleSheetMode } = searchParam_state ?? {}

  // homeSettingsContext 
  const { homeSettings } = settings ?? {}
  const { global } = homeSettings ?? {}
  const { permissionsConsole } = global ?? {}
  const { dataManagementSettings } = permissionsConsole ?? {}

  // authContext  
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserSessionKey } = appUserAccess ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_styles, aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection, appUserViewTypeProp, allowLinks, links, appUserDataModifications } = aps_appUserSettings ?? {}

  // papsContext 
  const { viewKey, view, pathViews } = paps_state ?? {}

  // appAccessContext  
  const { accessRequests } = appAccess_state ?? {}

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionItems] : {}
  const defaultSnC = aps_styles ? aps_styles[gEnums.projectStyles.gridIcons] : {}
  const backgroundSaC = aps_styles ? aps_styles[gEnums.projectStyles.background] : {}

  const viewItem = pageItemsShown ? pageItemsShown[viewItem_key] : {}
  const { add, sorting } = _dataModificationOpts ?? {}

  const { alphabetActive, alphaDisplayType, alphaFilterAfter, alphaFilterType } = _groupingOpts ?? {}

  const { appFormType } = add ? add : {}
  const { createListAs } = sorting ? sorting : {}

  const [appUserActionItems, setAppUserActionItems] = useState()
  const [showSeasons, setShowSeasons] = useState()
  const [allActionItems, setAllActionItems] = useState({ appUserDirect: null, data: null, dataManagement: null, dataModification: null, ready: false })

  useEffect(() => {
    const appUserLinkItems = []

    if (_itemData && allowLinks && links && appUserSessionKey) {
      getAppUserLinks(appUserLinkItems, appUserCollection, links, _itemData, viewKey, viewItem, _itemData.id)
    }


    // IMPORTANT: ItemPopup - Gets all the action items (appUserDirect, dataManagement, dataModification) rom the uiItem 
    const _actionItems = appUser_fns.validateAccess_uiAction({
      accessRequests,
      appUserAccess,
      appUserDataModifications,
      dataManagementSettings,
      itemData: _itemData,
      pageSettings,
      view,
      viewItem_key,
      viewItem,
      viewItemStatus,
      viewKey,
    })

    let aip = false
    if (!aip && appUserLinkItems.length > 0) { aip = true }
    appUserLinkItems.length > 0 && setAppUserActionItems(appUserLinkItems)

    setAllActionItems(
      {
        ..._actionItems,
        ready: true
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess, aps_appUserSettings, viewItem_key]);

  /**
   * Handles the click of a NON user menu item
   * @param {string} pat 
   * @param {string} sat 
   * @param {number} arrayIndex 
   * @description this will call the item_handlers.handleSet_modifyAction function, which amoung other settings,
   * sets the modifyActionType. If this is set within the UiItem, it will show the contents of the <UiItemAction/>
   */
  const handleClick_menuItem = (pat, sat, arrayIndex) => {

    const ct = { modifyActionType: pat }

    switch (pat) {

      case itemActionTypes.refreshGoogleSheets:
        appSettings_handlers.handleDatabaseRefresh()
        break;

      case itemActionTypes.openDatabase:
        item_handlers.handleShow_itemSidebar()
        openExternal.firebaseDb(pathViews, view, viewKey)
        break;

      case itemActionTypes.connectToAppUser:
        item_handlers.handleShow_itemSidebar()
        item_handlers.handleSet_modifyAction(ct)
        break;

      case itemActionTypes.dataManagement:
        const dodm = { modifyActionType: pat, dataFieldName: sat, dataFieldIndex: arrayIndex }
        item_handlers.handleSet_modifyAction(dodm)
        break;

      case itemActionTypes.signInAs:
        if (_itemData) {
          const actionItem = { dataActionType: 'clone', cloneId: _itemData.id, cloneVit: appUserCollection, appUserViewTypeProp: appUserViewTypeProp }
          appUser_handlers.handleClone(actionItem, _itemData, paps_state, appUserCollection)
          item_handlers.handleShow_itemSidebar()
        }
        break;

      case itemActionTypes.subAction:
        const st = { modifyActionType: pat, subActionType: sat }
        item_handlers.handleSet_modifyAction(st)
        break;

      case itemActionTypes.add:
        const at = { modifyActionType: pat, appFormType }
        item_handlers.handleSet_modifyAction(at)
        break;

      default:
        item_handlers.handleSet_modifyAction(ct)
    }
  }

  /**
   * Handles the click of a user menu item
   * @param {object} e 
   * @param {object} actionItem 
   */
  const handleClick_userAction = (e, actionItem) => {
    actionItem.remove = actionItem.checked
    actionItem.actionProps.appUserAccess = appUserAccess
    item_handlers.handleUpdate_linkItem(actionItem, _itemData)
  }

  const contentRef = useRef()

  /** returns a menuItem based on the pat and sat */

  /**
   * 
   * @param {string} pat 
   * @param {string} sat 
   * @param {number} arrayIndex 
   * @param {boolean} forData 
   * @returns a Menu.Item base on the input paraments
   */
  const menuItem = (pat, sat, arrayIndex, forData) => {

    let icon = itemActionIcons[pat] ? itemActionIcons[pat] : 'question'

    let title = _.startCase(pat)
    let titleExtra = ''
    let disabled = false
    let labeled = null

    const cn = forData ? 'mi-data' : 'mi-norm'

    switch (pat) {
      case itemActionTypes.signInAs:
        if (_itemData && !_itemData.email) {
          disabled = true
          titleExtra = ' (Not available - No Email)'
        } else {
          if (_itemData) {
            title = title + ' (' + _itemData.email + ')'
          }
        }
        break;

      case itemActionTypes.appUserInfo:
        if (sat) { title = 'View ' + _.startCase(sat) }
        break;

      case itemActionTypes.add:
        icon = appIconTypes.add
        if (appFormType && appFormType !== gEnums.customAddTypes.none) {
          title = 'Add ' + _.startCase(appFormType)
        }
        break;

      case itemActionTypes.imageUpload:
        if (sat) { title = 'Link ' + _.startCase(sat) }
        break;

      case itemActionTypes.itemLinking:
        if (sat) { title = sat }
        icon = appIconTypes.dataLinking
        break;

      case itemActionTypes.createGlobalData:
        if (sat) { title = 'Create Global Data (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.createList:
        if (createListAs) { title = _.startCase(createListAs) }
        break;

      case itemActionTypes.dataLinking:
        title = 'Link ' + _.startCase(viewItem_key) + ' to this page (' + _.startCase(view) + ')'
        icon = appIconTypes.dataLinking
        break;

      case itemActionTypes.imageMapping:
        if (sat) { title = 'Image Mapping (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.openDatabase:
        icon = appIconTypes.externalAlternate
        break;

      case itemActionTypes.showItemCount:
        title = 'Item Count'
        labeled = vldCount
        break;

      case itemActionTypes.sorting:
        if (sat) { title = 'Sort (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.subAction:
        if (sat) { title = _.startCase(sat) }
        break;

      case itemActionTypes.ticketing:
        title = 'Passes' // 'Ticketing'
        break;

      default:
      // nothing
    }

    const keyy = sat ? sat : pat

    if (asMenu) {
      if (disabled) {
        return <Menu.Item key={uniqueKey('uias', keyy)} className={cn}>
          <Icon key={uniqueKey('uias', keyy, 'i')} name={'ban'} />
          {title + titleExtra}
        </Menu.Item>
      } else if (labeled) {
        return <Menu.Item key={uniqueKey('uias', keyy)} className={cn}>
          <Label key={uniqueKey('uias', keyy, 'l')} color={'blue'}>{labeled}</Label>
          {title + titleExtra}
        </Menu.Item>
      } else {
        return <Menu.Item key={uniqueKey('uias', keyy)} className={cn} onClick={() => { handleClick_menuItem(pat, sat, arrayIndex) }}>
          <Icon key={uniqueKey('uias', keyy, 'i')} name={icon} />
          {title}
        </Menu.Item>
      }
    } else {
      return <Grid.Column className={'user-mi'} color={'blue'} key={uniqueKey('gc', 'uias', keyy)} >
        <div style={{ padding: '2em' }}>
          <Icon name={icon} />
          <div>{title}</div>
        </div>
      </Grid.Column>
    }
  }

  /** returns the valid menuItems */
  const getUserMenuItems = () => {

    const mis = []

    // loop all the itemActionTypes
    if (allActionItems.appUserDirect) {

      // loog the itemActionTypes
      Object.keys(itemActionTypes).forEach(mat => {

        if (allActionItems.appUserDirect[mat]) {

          const iat = allActionItems.appUserDirect[mat]
          const { actionCaption } = iat ? iat : {}

          switch (mat) {

            case itemActionTypes.dataLinking:
              mis.push(menuItem(mat, viewItem_key))
              break;

            case itemActionTypes.ticketing:
              mis.push(menuItem(mat, viewItem_key))
              break;

            default:
              mis.push(menuItem(mat, actionCaption))
          }
        }
      })
    }
    return mis
  }

  const userMenuItems = () => {
    const mis = []
    const { appUserSessionKey } = appUserAccess ?? {}
    if (_itemData && appUserActionItems && appUserActionItems.length > 0) {
      appUserActionItems.forEach(actionItem => {
        const ad = _itemData[appUserCollection]
        const checked = _.includes(ad, appUserSessionKey) ? true : false
        const { text, key } = actionItem
        const icon = checked ? 'check square outline' : 'check square'
        const iconColor = checked ? 'green' : 'grey'
        actionItem.checked = checked
        if (asMenu) {
          mis.push(<Menu.Item className={'user-mi'} color={'blue'} key={uniqueKey('uias', 'mi', key)} onClick={(e) => { handleClick_userAction(e, actionItem) }}>
            <Icon key={uniqueKey('uias', 'mi', key, 1)} name={'user'} color={iconColor} />
            <Icon key={uniqueKey('uias', 'mi', key, 2)} name={icon} color={iconColor} />
            {text}
          </Menu.Item>)
        } else {
          const segProps = { caption: text, icon }
          mis.push(<Grid.Column className={'user-mi'} color={'blue'} key={uniqueKey('uias', 'mi', key)} onClick={(e) => { handleClick_userAction(e, actionItem) }}>
            <SegIconGroup item={actionItem} segProps={segProps} defaultSnC={{}} handleIconClick={handleClick_userAction} />
          </Grid.Column>)
        }
      })
    }
    return mis
  }

  const PopupSidebar = ({ menuItems, animation, direction, visible }) => {
    styleAndClass.className = 'action-sidebar'
    return <Sidebar
      key={uniqueKey('uias', 'psbr')}
      {...styleAndClass}
      as={asMenu ? Menu : Segment}
      animation={animation}
      direction={direction}
      inverted
      vertical
      visible={visible}
      onHide={() => item_handlers.handleShow_itemSidebar()}
      target={contentRef}
      width='thin'
      size={'tiny'}
    >
      {menuItems}
    </Sidebar>
  }

  const gridContainer = (mis) => {
    return <div key={uniqueKey('uias', 'gc')} {...backgroundSaC}>
      <div className={'grid-icons-container'}>
        <div className={defaultSnC.className}>
          <Grid columns={3} textAlign='center'>{mis}</Grid>
        </div>
      </div>
    </div>
  }

  const getMenuItems = () => {

    const mis = getUserMenuItems()
    const misu = userMenuItems()

    // combine mis and misu
    const _menuItems = [...mis, ...misu]

    if (isGoogleSheetMode) {
      _menuItems.push(menuItem(itemActionTypes.refreshGoogleSheets))
    }

    if (allActionItems.dataManagement && Object.keys(allActionItems.dataManagement).length > 0) {
      _menuItems.push(menuItem(itemActionTypes.dataManagement, null, null, true))
    }

    // add a menuItem for the dataModifications
    if (allActionItems.dataModification && Object.keys(allActionItems.dataModification).length > 0) {
      if (Object.keys(allActionItems.dataModification).length === 1 && _allowSingleItemPopup) {
        Object.keys(allActionItems.dataModification).forEach(dmi => {
          _menuItems.push(menuItem(dmi, null, null, true))
        })
      } else {
        _menuItems.push(menuItem(itemActionTypes.dataModifications, null, null, true))
      }
    }

    return _menuItems
  }
  /**
   * 
   * @returns menuItems within a sidebar
   */
  const menuContent = () => {

    let _showAlpha;

    if (alphabetActive) {
      switch (alphaDisplayType) {
        case gEnums.alphaDisplayTypes.vertical:
        case gEnums.alphaDisplayTypes.verticalFull:
          _showAlpha = true //groupActive ? false : true
          break;
        default:
        // nothing
      }
    }

    switch (alphaFilterType) {
      case gEnums.alphaFilterTypes.afterLimit:
        if (dataItemCount < alphaFilterAfter) {
          _showAlpha = false
        }
        break;
      default:
      // nothing
    }

    const _menuItems = getMenuItems()

    if (fromNavigation) {
      return asMenu ? _menuItems : gridContainer(_menuItems)
    } else {
      return _menuItems.length > 0 || _showAlpha ?
        <Sidebar.Pushable className={'sidebar-item'} >
          <PopupSidebar
            key={uniqueKey('uias')}
            menuItems={asMenu && _menuItems.length > 0 ? _menuItems : gridContainer(_menuItems)}
            animation={'overlay'}
            direction={'bottom'}
            visible={modifySidebarOpen}
            className={'test'}
          />
          <Ref innerRef={contentRef}>
            <Sidebar.Pusher key={uniqueKey('uias', 'sp')} className={'pusher-container'} dimmed={modifySidebarOpen || settingsSidebarOpen}>
              {content}
              {_showAlpha && <AlphabetMenu
                origin={'UiSidebar'}
                handleAlphaChange={handleAlphaChange}
                handleMouseMove={handleMouseMove}
              />}
            </Sidebar.Pusher>
          </Ref>
        </Sidebar.Pushable>
        :
        content
    }
  }

  return allActionItems.ready ? menuContent() : <div></div>

}

export default UiItemSidebar