import { getISOWeek } from 'date-fns';
import { arrayRemove, arrayUnion, increment, serverTimestamp } from 'firebase/firestore';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { isLocalhost } from '../../../project/appConfiguration';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { updateAppProfileDirect } from '../../auth/profiles/updateProfile';
import { signUpEmailDirect } from '../../auth/signInActions/signUpEmail';
import { createInitials } from '../../common/dataAdjust';
import { _developerAdminEmail } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_set_doc, fs_update_doc } from '../../firestoreData/appData/fsData';
import { updateProfilePageFavorite } from '../../firestoreData/favorites/updatePageFavorite';
import { getClonedAppUser } from '../../firestoreData/profiles/getClonedAppUser';
import { getAppUserInfo } from "../../firestoreData/profiles/getProfile";
import { currentHelpers } from '../../redirection/current';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

export const _currentUserIdProp = 'currentAppUserId'
export const _currentAppUserCountsCollection = '_currentAppUserCounts'
export const _currentAppUserCountsCollection_xxx = '_currentAppUsers'
export const _appUserPresenceCollection = '_appUserPresence'
export const _appUserPresenceDoc = 'appUsers'

export const rts = {
  handleAccessClone: 'handleAccessClone',
  handleAmmend_clonedAppUser: 'handleAmmend_clonedAppUser',
  handleAmmend_favs: 'handleAmmend_favs',
  handleAmmend_myLocation: 'handleAmmend_myLocation',
  handleAmmend_newLocation: 'handleAmmend_newLocation',
  handleAmmend_notificationRequest: 'handleAmmend_notificationRequest',
  handleAmmend_pageFavorite: 'handleAmmend_pageFavorite',
  handleAmmend_pageFavs: 'handleAmmend_pageFavs',
  handleAmmend_fcmTokens: 'handleAmmend_fcmTokens',
  handleAppUserPageData: 'handleAppUserPageData',
  handleClone: 'handleClone',
  handleConfirm_accessCode: 'handleConfirm_accessCode',
  handleConnectToAppUser: 'handleConnectToAppUser',
  handleGet_appUserLocation: 'handleGet_appUserLocation',
  handleGet_profile: 'handleGet_profile',
  handleGetAppUserEventInfo: 'handleGetAppUserEventInfo',
  handleInit_currentAuthUser: 'handleInit_currentAuthUser',
  handleLink_appUserData: 'handleLink_appUserData',
  handleResetAccessLevel: 'handleResetAccessLevel',
  handleResponse_addToHomePage: 'handleResponse_addToHomePage',
  handleResponse_disclaimers: 'handleResponse_disclaimers',
  handleSave_currentAppUserId: 'handleSave_currentAppUserId',
  handleSend_EmailToEmail: 'handleSend_EmailToEmail',
  handleSet_appUser: 'handleSet_appUser',
  handleSet_eventAppUsers: 'handleSet_eventAppUsers',
  handleSet_tempAccessLevel: 'handleSet_tempAccessLevel',
  handleUpdate_presenceStatus: 'handleUpdate_presenceStatus',
  handleUpdateAccess: 'handleUpdateAccess',
  handleUpdateAppUserEventInfo: 'handleUpdateAppUserEventInfo',
  handleUpdateAppUserProfile: 'handleUpdateAppUserProfile',
  ...grts
}

export const appUserReducerInitialState = (initState) => {
  return { ...initState, appUsers: { current: {}, cloned: null } }
};

export const appUserReducer = (state, action) => {

  const { pathViews, aps_appUserSettings, appUser: appUser_state, accessConfirmed, currentAuthUser, appUsers, eventAppUsers } = state

  const { type, dispatch, email, appUser: appUser_action, paps_state, itemData } = action

  const { events: eventKey } = pathViews
  const { appUserCollection } = aps_appUserSettings

  const appUserAccess = appUser_state ? getAppUserAccess(null, appUser_action) : {}
  const { appUserSession } = appUserAccess ?? {}
  const { _itemKey, appUserSessionKey } = appUserSession ?? {}

  const _appUsers = { ...appUsers }

  const _accessConfirmed = eventKey ? currentHelpers.storageItem_get('accessConfirmed-' + eventKey) : accessConfirmed

  const appUser_handlers = appUserHandlers(dispatch)

  const { handleAmmend_clonedAppUser, handleSet_appUser } = appUser_handlers
  const { handleGet_profile, handleUpdateAppUserEventInfo } = appUserHandlers(dispatch)


  switch (type) {
    case rts.handleSave_currentAppUserId:
      const newId = uuidv4();
      currentHelpers.storageItem_set(_currentUserIdProp, newId);
      const today = new Date();
      const year = today.getFullYear();
      const weekNumber = getISOWeek(today)
      const refPathI = createRefPath_event(pathViews, [_currentAppUserCountsCollection, year])
      const dataToUpdateI = { [`week${weekNumber}`]: increment(1) }
      fs_update_doc(refPathI, dataToUpdateI)
      return { ...state }

    case rts.handleInit_currentAuthUser:
      handleGet_profile()
      return { ...state, currentAuthUser: action.currentAuthUser }

    case rts.handleGet_profile:
      getAppUserInfo(state, handleSet_appUser, handleUpdateAppUserEventInfo)
      return { ...state }

    case rts.handleSet_appUser:

      const _appUser_action = { ...appUser_action }
      _appUsers.current = _appUser_action

      if (_appUser_action && _appUser_action.profileData) {
        _appUser_action.pageFavz = getFavs(_appUser_action.profileData, pathViews)
      }

      const _state2 = {
        ...state,
        appUsers: _appUsers,
        appUser: _appUser_action,
        appUserChecked: true,
        isDeveloper: isDeveloper(_appUser_action),
      }
      if (eventKey) { _state2.accessConfirmed = _accessConfirmed }
      if (!_state2.profileData && _appUser_action.profileData) {
        _state2.profileData = _appUser_action.profileData
      }

      return { ..._state2 }

    case rts.handleAmmend_fcmTokens:
      const { pushResult } = action
      const { notificationRequestGranted, fcmTokens } = pushResult ?? {}
      const _appUser_state = { ...appUser_state }
      if (_appUser_state && _appUser_state.appUserAccess) {
        _appUser_state.appUserAccess.fcmTokens = fcmTokens
      }
      return { ...state, notificationRequestGranted, appUser: _appUser_state, tokenAsked: true }

    case rts.handleUpdate_presenceStatus:
      if (currentAuthUser && currentAuthUser.uid) {
        const _ref = createRefPath_event(pathViews, [_appUserPresenceCollection, _appUserPresenceDoc])
        const _dataToUpdate = {
          [currentAuthUser.uid]: {
            displayName: currentAuthUser.displayName,
            status: action.presenceStatus,
            email: currentAuthUser.email,
            timestamp: serverTimestamp(),
          }
        }
        fs_set_doc(_ref, _dataToUpdate, true)
      }

      return { ...state }

    case rts.handleAmmend_notificationRequest:
      return { ...state, notificationRequestGranted: action.notificationRequestGranted }

    case rts.handleConfirm_accessCode:
      currentHelpers.storageItem_set('accessConfirmed-' + eventKey, action.passcode)
      return { ...state, accessConfirmed: action.passcode }

    case rts.handleAmmend_favs:
      const { sports } = action
      const { profileData } = state
      const _profileData = profileData ? { ...profileData } : {}
      const { pageFavs } = _profileData ?? {}
      const { teams } = pageFavs ?? {}
      if (teams && sports) {
        _.each(teams, (team, indexOrKey) => {
          if (team && team.sports && sports[team.sports]) {
            team.sports = sports[team.sports].name
          }
        })
      }
      const _ausf = { ...appUser_state }
      if (_ausf.appUserAccess) {
        _ausf.appUserAccess.pageFavs = pageFavs
      }
      return { ...state, profileData: _profileData, appUser: _ausf }

    case rts.handleAmmend_myLocation:
      return { ...state, appUserLocation: action.location, appUserLocationGranted: action.locationGranted }

    case rts.handleAmmend_newLocation:
      return { ...state, appUserLocation: action.location }

    case rts.handleSet_eventAppUsers:
      return { ...state, eventAppUsers: action.eventAppUsers }

    case rts.handleUpdateAppUserProfile:
      const { appUserData } = action
      updateAppProfileDirect(appUserSession, pathViews, appUserData, dispatch)
      return { ...state }

    case rts.handleUpdateAppUserEventInfo:
      if (state.appUser && state.appUser.appUserAccess_cloned && state.appUser.appUserAccess_cloned) {
        const _appUser = { ...state.appUser }
        _appUser.appUserAccess_cloned.appUserSession = action.result
        return { ...state, appUser: _appUser }
      }
      return { ...state }

    case rts.handleAmmend_pageFavs:
      if (appUser_state && appUser_state.appUserAccess) {
        appUser_state.appUserAccess.pageFavs = action.pageFavs
      }
      return { ...state, appUser: appUser_state }

    case rts.handleUpdateAccess:
      const _ausa = { ...appUser_state }
      if (_ausa.appUserAccess) {
        _ausa.appUserAccess.eventAccess = action.eventAccess
      }
      return { ...state, appUser: _ausa }

    case rts.handleResponse_addToHomePage:
      return { ...state, homePageResponse: action.homePageResponse, homePageResponded: true }

    case rts.handleSet_tempAccessLevel:
      if (action.tempAccessLevel === 0 || action.tempAccessLevel === null) {
        _appUsers.cloned = null
        return { ...state, appUsers: _appUsers, isClone: false }
      } else {
        _appUsers.cloned = {
          appUserAccess: getAppAccess(action.tempAccessLevel, action.pathViews)
        }
        return { ...state, appUsers: _appUsers, isClone: true }
      }

    case rts.handleResetAccessLevel:
      _appUsers.cloned = null
      return { ...state, appUser: appUser_state, appUsers: _appUsers, isClone: false }

    case rts.handleSend_EmailToEmail:
      const { authGlobalPassword } = action
      signUpEmailDirect(dispatch, email, authGlobalPassword, itemData, paps_state)
      return { ...state }

    case rts.handleClone:
      getClonedAppUser(itemData, paps_state, handleAmmend_clonedAppUser, eventAppUsers, aps_appUserSettings)
      return { ...state }

    case rts.handleAmmend_clonedAppUser:
      const { appUser_cloned } = action
      _appUsers.cloned = appUser_cloned
      return {
        ...state,
        appUser: appUser_state,
        appUsers: _appUsers,
        isClone: true
      }

    case rts.handleAccessClone:
      const _accessClone = state.accessClone && state.accessClone.name === action.accessClone.name ? null : action.accessClone
      return { ...state, accessClone: _accessClone }

    case rts.handleConnectToAppUser:
      return { ...state }

    case rts.handleAppUserPageData:
      return { ...state, [action.dataCollectionName]: action.dataValue }

    case rts.handleAmmend_pageFavorite:
      updateProfilePageFavorite(dispatch, appUser_state, appUsers, paps_state, action.pageDataCaption, action.favItem)
      return { ...state }

    case rts.handleResponse_disclaimers:
      const disclaimerData = { disclaimerResponses: action.disclaimerResponses }
      updateAppProfileDirect(appUserSession, pathViews, disclaimerData, handleGet_profile)
      return { ...state }

    case rts.handleLink_appUserData:

      if (appUserSession) {

        const _appUserKey = appUserSessionKey ? appUserSessionKey : _itemKey
        const _data1 = appUserSession[action.view]
        const _dataRef1 = createRefPath_event(pathViews, [action.view, action.viewKey])
        const _dataRef2 = createRefPath_event(pathViews, [appUserCollection, _appUserKey])

        const { dataToUpdate1, dataToUpdate2 } = getDataItemToUpdate(_data1, appUserCollection, _appUserKey, action.view, action.viewKey)

        console.log('_dataRef1', _dataRef1, dataToUpdate1)
        console.log('_dataRef2', _dataRef2, dataToUpdate2)

        // fs_update_doc(_dataRef1, dataToUpdate1).then(res1 => {
        //   fs_update_doc(_dataRef2, dataToUpdate2).then(res2 => {
        //     if (appUser_state.appUserAccess_cloned) {
        //       handleGetAppUserEventInfo(true)
        //       action.callback()
        //     }
        //   })
        // })
      }
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUserHandlers = (dispatch) => {

  return {
    handleAccessClone: (accessClone) => { dispatch({ type: rts.handleAccessClone, dispatch, accessClone }) },
    handleAmmend_clonedAppUser: (appUser_cloned) => { dispatch({ type: rts.handleAmmend_clonedAppUser, dispatch, appUser_cloned }) },
    handleAmmend_favs: (sports) => { dispatch({ type: rts.handleAmmend_favs, dispatch, sports }) },
    handleAmmend_myLocation: (location, locationGranted) => dispatch({ type: rts.handleAmmend_myLocation, dispatch, location, locationGranted }),
    handleAmmend_newLocation: (location) => dispatch({ type: rts.handleAmmend_newLocation, dispatch, location }),
    handleAmmend_notificationRequest: (notificationRequestGranted) => { dispatch({ type: rts.handleAmmend_notificationRequest, notificationRequestGranted }) },
    handleAmmend_pageFavorite: (paps_state, pageDataCaption, favItem) => { dispatch({ type: rts.handleAmmend_pageFavorite, paps_state, pageDataCaption, favItem, dispatch }) },
    handleAmmend_pageFavs: (pageFavs) => { dispatch({ type: rts.handleAmmend_pageFavs, dispatch, pageFavs }) },
    handleAmmend_fcmTokens: (pushResult) => { dispatch({ type: rts.handleAmmend_fcmTokens, pushResult }) },
    handleAppUserPageData: (dataCollectionName, dataValue) => { dispatch({ type: rts.handleAppUserPageData, dispatch, dataCollectionName, dataValue }) },
    handleClone: (item, itemData, paps_state, appUserCollection) => { dispatch({ type: rts.handleClone, dispatch, item, itemData, paps_state, appUserCollection }) },
    handleConfirm_accessCode: (passcode) => dispatch({ type: rts.handleConfirm_accessCode, dispatch, passcode }),
    handleConnectToAppUser: (view, dataItem) => { dispatch({ type: rts.handleConnectToAppUser, dispatch, view, dataItem }) },
    handleGet_appUserLocation: () => { dispatch({ type: rts.handleGet_appUserLocation, dispatch }) },
    handleGet_profile: () => { dispatch({ type: rts.handleGet_profile, dispatch }) },
    handleGetAppUserEventInfo: (forClone) => { dispatch({ type: rts.handleGetAppUserEventInfo, dispatch, forClone }) },
    handleInit_currentAuthUser: (currentAuthUser) => { dispatch({ type: rts.handleInit_currentAuthUser, dispatch, currentAuthUser }) },
    handleLink_appUserData: (view, viewKey, callback) => { dispatch({ type: rts.handleLink_appUserData, dispatch, view, viewKey, callback }) },
    handleResetAccessLevel: () => { dispatch({ type: rts.handleResetAccessLevel }) },
    handleResponse_addToHomePage: (homePageResponse) => { dispatch({ type: rts.handleResponse_addToHomePage, dispatch, homePageResponse }) },
    handleResponse_disclaimers: (disclaimerResponses) => dispatch({ type: rts.handleResponse_disclaimers, dispatch, disclaimerResponses }),
    handleSave_currentAppUserId: (currentUserId) => { dispatch({ type: rts.handleSave_currentAppUserId, dispatch, currentUserId }) },
    handleSend_EmailToEmail: (paps_state, email, itemData, authGlobalPassword) => { dispatch({ type: rts.handleSend_EmailToEmail, paps_state, dispatch, email, itemData, authGlobalPassword }) },
    handleSet_appUser: (appUser, appUserAccess, userAccessRequests) => { dispatch({ type: rts.handleSet_appUser, dispatch, appUser, appUserAccess, userAccessRequests }) },
    handleSet_eventAppUsers: (eventAppUsers) => { dispatch({ type: rts.handleSet_eventAppUsers, dispatch, eventAppUsers }) },
    handleSet_tempAccessLevel: (tempAccessLevel, pathViews) => { dispatch({ type: rts.handleSet_tempAccessLevel, dispatch, tempAccessLevel, pathViews }) },
    handleUpdate_presenceStatus: (presenceStatus) => { dispatch({ type: rts.handleUpdate_presenceStatus, dispatch, presenceStatus }) },
    handleUpdateAccess: (eventAccess) => { dispatch({ type: rts.handleUpdateAccess, dispatch, eventAccess }) },
    handleUpdateAppUserEventInfo: (result) => { dispatch({ type: rts.handleUpdateAppUserEventInfo, dispatch, result }) },
    handleUpdateAppUserProfile: (appUserData, callback) => { dispatch({ type: rts.handleUpdateAppUserProfile, dispatch, appUserData, callback }) },
    ...responseHandlers(dispatch)
  }
}

const getAppAccess = (accessLevel, pathViews) => {
  const x = _.find(gEnums.accessLevels, { 'key': accessLevel })
  const aa = {
    accessLevel: accessLevel,
    appUserColor: x.color,
    appUserAccessType: _.camelCase(x.caption),
    clientAccessKey: pathViews ? pathViews.clients : null,
    events: pathViews ? { [pathViews.events]: true } : null,
    initials: createInitials(_.startCase(x.caption), 2),
    isAdminOrSuper: accessLevel >= gEnums.accessLevels.admin.value,
    isSuperAdmin: accessLevel >= gEnums.accessLevels.superAdmin.value,
    loggedIn: true,
    settingsAuthLevel: accessLevel > gEnums.authLevels.appSubAdmin.value ? accessLevel : 0,
  }
  console.log('aa', aa)
  return aa
}

const getFavs = (profileData, pathViews) => {
  const { pageFavs } = profileData
  const _sortedFavs = {}
  if (pageFavs) {
    // loop the faves
    Object.keys(pageFavs).forEach(key_fav => {
      const pf = pageFavs[key_fav]
      Object.keys(pf).forEach(keyy => {
        const pff = pf[keyy]
        const { name, pathName } = pff ?? {}
        if (pathName) {
          const pathNameS = pathName ? pathName.split('/') : ''
          const pathKeys = currentHelpers.convertPathToKeys(pathNameS)
          let allow = pathKeys.events === pathViews.events
          if (allow) {
            if (!_sortedFavs[keyy]) { _sortedFavs[keyy] = [] }
            _sortedFavs[keyy].push(
              {
                key: keyy,
                name,
                pathName,
                ...pathKeys,
              }
            )
          }
        }
      })
    })
  }
  return _sortedFavs
}

const getDataItemToUpdate = (existingData, appUserCollection, appUserKey, view, viewKey) => {

  let dataToUpdate1;
  let dataToUpdate2;

  if (existingData) {
    if (existingData.includes(viewKey)) {
      dataToUpdate1 = { [appUserCollection]: arrayRemove(appUserKey) }
      dataToUpdate2 = { [view]: arrayRemove(viewKey) }
    } else {
      dataToUpdate1 = { [appUserCollection]: arrayUnion(appUserKey) }
      dataToUpdate2 = { [view]: arrayUnion(viewKey) }
    }
  } else {
    dataToUpdate1 = { [appUserCollection]: [appUserKey] }
    dataToUpdate2 = { [view]: [viewKey] }
  }

  return { dataToUpdate1, dataToUpdate2 }

}

const getMyLocation = (callback_location) => {

  const cb_success = (position) => {
    const { coords } = position
    const _location = {
      latitude: coords.latitude,
      longitude: coords.longitude
    }
    callback_location(_location, true)
  }

  const cb_error = (e) => {
    callback_location(null, false)
    console.error("Error Code = " + e.code + " - " + e.message);
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(cb_success, cb_error);
  }
}

const isDeveloper = (appUser) => {
  return isLocalhost() && appUser && appUser.email === _developerAdminEmail ? true : false
}


const getDateValue = (dateValue) => {

  // const seconds = dateValue.seconds;
  // const nanoseconds = dateValue.nanoseconds / 1e9; // Convert nanoseconds to seconds
  // const milliseconds = seconds * 1000 + nanoseconds;
  // const utcDate = new Date(milliseconds);

  // Format the date as "mm/dd/yyyy"
  return dateValue.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}