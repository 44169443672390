import { doc, where } from 'firebase/firestore';
import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { FirestoreContext } from '../../../../global/cnr/contexts/FirestoreContext';
import { FrameworkContext } from '../../../../global/cnr/contexts/FrameworkContent';
import { HeaderContext } from '../../../../global/cnr/contexts/HeaderContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { _currentUserIdProp } from '../../../../global/cnr/reducers/AppUserReducer';
import { frameworkRightTypes } from '../../../../global/cnr/reducers/FrameworkReducer';
import { creatingHelpers } from '../../../../global/common/creating';
import { formatItem, formatTypes } from "../../../../global/common/dateFormatting";
import { getArrayIndex, sortObj, sortObject } from "../../../../global/common/sorting";
import { gEnums } from '../../../../global/enums/globalEnums';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { currentHelpers } from '../../../../global/redirection/current';
import { sortDB } from '../../helpers/league';
import { sportsSeasonHandlers, sportsSeasonInitialState, sportsSeasonReducer } from '../reducers/SportsSeasonReducer';
import { SportsDistrictContext } from './SportsDistrictContext';
import { SportsOrganizationContext } from './SportsOrganizationContext';
import { _cacheNames, _cacheTypes } from '../../../../global/cnr/reducers/ServiceWorkerReducer';
import { TrackerContext } from '../../../../global/cnr/contexts/TrackerContext';

const _useScoreBlock = false
const _useScoreTeamBlock = false
const _startHour = 19
const _usePlayoffBrackets = true

const _sportsButtonProps = {
  color: 'teal',
  icon: 'snowflake outline',
}

const _sportFormProps = {
  access: {
    accessLevel: 'accessLevel',
  },
  glsAccess: {
    googleSheetsId: 'googleSheetsId',
    schoolsAccessSheet: 'schoolsAccessSheet',
    sportsAccessSheet: 'sportsAccessSheet',
    teamsAccessSheet: 'teamsAccessSheet',
    schoolsContactSheet: 'schoolsContactSheet',
    districtContactSheet: 'districtContactSheet',
  },
  glsDistrict: {
    googleSheetsId: 'googleSheetsId',
  },
  glsScores: {
    districtLink: 'districtLink',
    googleSheetsId: 'googleSheetsId',
    rankingsGoogleSheetsId: 'rankingsGoogleSheetsId',
    rosterGoogleSheetsId: 'rosterGoogleSheetsId',
    mediaUrl: 'mediaUrl',
    mediaUrlDateReplace: 'mediaUrlDateReplace',
    playoffScoresSheet: 'playoffScoresSheet',
    rankingsSheet: 'rankingsSheet',
    scoresSheet: 'scoresSheet',
    validAdmins: 'validAdmins',
    scoreEmails: 'scoreEmails',
    allowScoreUpdateFromGs: 'allowScoreUpdateFromGs',
    allowScheduledScoreUpdateFromGs: 'allowScheduledScoreUpdateFromGs',
    sortStandingsByPoints: 'sortStandingsByPoints',
  },
  rankingLinks: {
    googleSheetsId: 'googleSheetsId',
    rankingsSheet: 'rankingsSheet',
  }
}

export const _sportsFormProps = {
  access: {
    accessLevel: 'accessLevel',
  },
  accessTeam: {
    accessTeam: 'accessTeam',
  },
  accessSport: {
    accessSport: 'accessSport',
  },
  scoreAccess: {
    scoreAccess: 'scoreAccess',
  },
  accessSchools: {
    accessSchools: 'accessSchools',
  },
  contactsSchools: {
    email: 'email',
    pushApi: 'pushApi',
    pullUrl: 'pullUrl',
  },
  contactsDistrict: {
    email: 'email',
    pushApi: 'pushApi',
    pullUrl: 'pullUrl',
  },
  emailSchools: {
    pushApi: 'email',
  },
  glsTeam: {
    googleSheetsId: 'googleSheetsId',
    rosterSheet: 'rosterSheet',
    staffSheet: 'staffSheet',
    newsSheet: 'newsSheet',
    youTubeLink: 'youTubeLink',
    twitterLink: 'twitterLink',
  },
  sportsSettings: {
    allowScoreUpdateFromGs: 'allowScoreUpdateFromGs',
  },
  tournament: {
    name: 'name',
    startDate: 'startDate',
    startTime: 'startTime',
    results: 'results',
    url: 'url',
    link: 'link',
  },
  news: {
    title: 'title',
    body: 'body',
  },
  updateDistrictSeasonScores: { ..._sportFormProps.glsScores },
  glsAccess: { ..._sportFormProps.glsAccess },
  glsDistrict: { ..._sportFormProps.glsDistrict },
  glsScores: { ..._sportFormProps.glsScores },
  scoreLink: { ..._sportFormProps.glsScores },
  seasonResults: { ..._sportFormProps.glsScores },
  seasonRankings: { ..._sportFormProps.glsScores },
  useasonRankings: { ..._sportFormProps.rankingLinks },
  createSeasonFromGs: {
    googleSheetsId: 'googleSheetsId',
    sheetName: 'sheetName',
  }
}

export const matchScheduleTypes = {
  date: 'date',
  latest: 'latest',
  next: 'next',
  overview: 'overview'
}

export const _resultsProp = '_results'
export const _resultProp = 'results'

export const SportsSeasonContext = React.createContext()

export default function SportsSeasonProvider(props) {

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states, handlers, fns, parent_fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, serviceWorker_state } = states ?? {}
  const { appUser_handlers, serviceWorker_handlers } = handlers ?? {}
  const { database_fns } = fns ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { frameworkRightType } = framework_state ?? {}

  const trackerContext = useContext(TrackerContext)
  const { tracker_state, tracker_handlers } = trackerContext ?? {}
  const { handle_track } = tracker_handlers ?? {}

  // sportsOrganizationContext
  const sportsOrganizationContext = useContext(SportsOrganizationContext)
  const { sportsOrganization_state } = sportsOrganizationContext ?? {}
  const { districts: districts_org, schools: schools_org, useAppDarkMode } = sportsOrganization_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { sports, schools: schools_district, gls_scores, sportsAccess_district } = sportsDistrict_state ?? {}
  const { sports: access_sports, schools: access_schools } = sportsAccess_district ?? {}

  // appUserContext
  const { appUser, appUsers, accessClone } = appUser_state ?? {}
  const { uid, email: email_appUser } = appUser ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isAdminOrSuper, isAppUser, accessLevel } = appUserAccess ?? {}
  const _appUserCanModify = accessLevel >= gEnums.accessLevels.appProfile.value

  // papsContext 
  const { view, pathViews, viewKey } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_global } = pageSettings ?? {}
  const { sportSettings, sportPermissions } = aps_global ?? {}

  const _currentUserId = currentHelpers.storageItem_get(_currentUserIdProp)

  const { cacheApp } = serviceWorker_state ?? {}
  const { cachedItems } = cacheApp ?? {}
  const { cache_data } = cachedItems ?? {}

  const {
    rtAdminOnly,
    rtAppUserOnly,
    rtMatchData,
    rtMatchDataView,
    rtMatchUpdates,
  } = sportPermissions ?? {}

  const {
    matchScheduleDisplayType,
    showExpandIcon,
    showRankingsInStandings,
    showTodayIcon,
    useScoringCollection,
    useScoringCollectionOnly,
    useScrollTo,
  } = sportSettings ?? {}

  const getRtInfo = () => {
    let _rtInfo = {
      rtMatchData: rtMatchData,
      rtMatchDataView: rtMatchDataView,
      rtMatchUpdates: rtMatchUpdates,
    }

    if (rtAdminOnly) {
      _rtInfo = {
        rtMatchData: isAdminOrSuper && rtMatchData,
        rtMatchDataView: isAdminOrSuper && rtMatchDataView,
        rtMatchUpdates: isAdminOrSuper && rtMatchUpdates,
      }
    }

    if (rtAppUserOnly) {
      _rtInfo = {
        rtMatchData: isAppUser && rtMatchData,
        rtMatchDataView: isAppUser && rtMatchDataView,
        rtMatchUpdates: isAppUser && rtMatchUpdates,
      }
    }
    return _rtInfo
  }

  const _matchScheduleDisplayType = matchScheduleDisplayType ? matchScheduleDisplayType : gEnums.matchScheduleDisplayTypes.selectedDate

  const headerContext = useContext(HeaderContext)
  const { header_handlers } = headerContext ?? {}

  const { viewItems: viewItems_page } = aps_page ?? {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ?? {}
  const { dataParents, useSeasonals } = dataSource ?? {}

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const sportsMode = {
    organizations: pathViews && pathViews.organizations && !pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    districts: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    matches: pathViews && pathViews.matches ? true : false,
    schools: pathViews && pathViews.schools ? true : false,
    sports: pathViews && pathViews.sports && !pathViews.teams ? true : false,
    teams: pathViews && pathViews.teams ? true : false,
    sportLanding: pathViews && pathViews.sports && !pathViews.teams && !pathViews.matches
  }

  const _cacheName = _cacheNames.cache_data + '-' + viewKey
  const _cacheData = cache_data && cache_data[viewKey]

  const rtSettings = getRtInfo()

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)
  const { firestore_fns } = firestoreContext ?? {}

  const init_state = {
    _startHour,
    appUserCanModify: _appUserCanModify,
    currentUserId: _currentUserId,
    dataParents,
    firestore_fns,
    inverted: false,
    matchScheduleDisplayType: _matchScheduleDisplayType,
    pathViews,
    rtSettings,
    showExpandIcon,
    showRankingsInStandings,
    showTodayIcon,
    sportPermissions,
    sportsButtonProps: _sportsButtonProps,
    sportsMode,
    uid, ownerPath,
    useAppDarkMode,
    usePlayoffBrackets: _usePlayoffBrackets,
    useScoreBlock: _useScoreBlock,
    useScoreTeamBlock: _useScoreTeamBlock,
    useScoringCollection,
    useScoringCollectionOnly,
    useScrollTo,
    useSeasonals,
    viewItem,
    cssOpts: { name: 'ssc-normal' },
    _cacheData,
    handle_track,
    database_fns,
  }

  const [sportsSeason_state, sportsSeason_dispatch] = useReducer(sportsSeasonReducer, init_state, sportsSeasonInitialState)
  const sportsSeason_handlers = sportsSeasonHandlers(sportsSeason_dispatch, sportsSeason_state)

  const {
    _sportsModeKey,
    gameScoringType,
    latestSeason,
    matches_info,
    seasonal_info,
    sports_data,
    sports_info,
    sportsAccess_sports,
    sportsKey,
    teams_info,
    tempSelectedSeason,
    access_teams,
  } = sportsSeason_state ?? {}

  const { teams } = teams_info ?? {}
  const { matches } = matches_info ?? {}

  const findItemsByName = (obj, name) => {
    let allowedItems = {};

    function search(obj, parentKey) {
      _.forEach(obj, (value, key) => {
        if (_.isObject(value)) {
          if (value.name === name) {
            allowedItems[parentKey] = value;
          }
          search(value, key);
        }
      });
    }

    search(obj);

    return allowedItems;
  }

  useEffect(() => {
    if (sports_data && !_cacheData) {
      serviceWorker_handlers.handleSend_messageToServiceWorker({
        cacheName: _cacheName,
        cacheType: 'sports_data',
        cacheData: sports_data,
      }, _cacheTypes.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sports_data])

  useEffect(() => {
    if (sports_info && !_cacheData) {
      serviceWorker_handlers.handleSend_messageToServiceWorker({
        cacheName: _cacheName,
        cacheType: 'sports_info',
        cacheData: sports_info,
      }, _cacheTypes.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sports_info])

  useEffect(() => {
    if (access_teams) {
      serviceWorker_handlers.handleSend_messageToServiceWorker({
        cacheName: _cacheName,
        cacheType: 'access_teams',
        cacheData: access_teams,
      }, _cacheTypes.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [access_teams])

  useEffect(
    () => {
      if (frameworkRightType) {
        switch (frameworkRightType) {
          case frameworkRightTypes.pageMedia:
            const { levelMatches } = matches_info ?? {}
            const matches = {
              ...levelMatches
            }
            framework_handlers.handleShow_frameworkRightData(matches, view, viewItem.key, viewItem)
            break;
          default:
          // nothing
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [frameworkRightType, matches_info]
  )

  useEffect(() => {
    parent_fns.addToContexts({ sportsSeason_handlers: { ...sportsSeason_handlers } })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  // useEffect(() => {
  //   getGlsFromSportsSettings(pathViews)
  // }, [])

  useEffect(() => {
    let _smk;
    if (sports) {
      if (pathViews.sports) {
        if (sports && sports[pathViews.sports]) {
          const x = sports[pathViews.sports]
          _smk = _.camelCase(x.name)
        } else {
          _smk = pathViews.sports
        }
      } else {
        _smk = _sportsModeKey
      }
      Object.keys(sportsMode).forEach(smKey => {
        if (sportsMode[smKey]) {
          const _sportsMode = smKey
          sportsSeason_handlers.handleSet_sportsMode(_sportsMode, _smk)
        }
      })
      // if (pageFavs) {
      //   const _pageFavs = copyObj(pageFavs)
      //   if (_pageFavs.teams) {
      //     _.each(_pageFavs.teams, (team) => {
      //       const { sports: sport_fav } = team
      //       if (sports[sport_fav]) {
      //         team.sportsName = sports[sport_fav].name
      //       }
      //     })
      //   }
      //   appUser_handlers.handleAmmend_pageFavs(pageFavs)
      // }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sports, pathViews.teams]);

  useEffect(() => {
    if (gameScoringType && pathViews.sports) {
      sportsSeason_handlers.handleGet_sportsSeasonInit(pathViews, tempSelectedSeason)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [gameScoringType, pathViews.sports, tempSelectedSeason])

  useEffect(() => {
    if (districts_org) {
      sportsSeason_handlers.handleSet_state('organization', districts_org, 'districts_info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [districts_org])

  useEffect(() => {
    if (schools_org) {
      sportsSeason_handlers.handleSet_state('organization', schools_org, 'schools_info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [schools_org])

  useEffect(() => {
    if (schools_district) {
      sportsSeason_handlers.handleSet_state('district', schools_district, 'schools_info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [schools_district])

  useEffect(() => {
    if (latestSeason && header_handlers) {
      const _latest = `${latestSeason - 1}/${latestSeason}`;
      if (sportsMode.sportLanding) {
        header_handlers.handleSportsCaption(_latest)
      } else {
        header_handlers.handleSportsCaption(_.startCase(_sportsModeKey) + ' ' + _latest)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [latestSeason, pathViews])

  useEffect(() => {
    if ((access_sports && sportsKey && access_sports[sportsKey]) || sportsAccess_sports) {
      const { name: accessEmail } = accessClone ?? {}
      const _email = accessEmail ? accessEmail : email_appUser
      sportsSeason_handlers.handleSet_state('access_sports', access_sports && access_sports[sportsKey])
      const teamsAccess = findItemsByName(sportsAccess_sports, _email)
      const sportsAccess = findItemsByName(access_sports, _email)
      const schoolsAccess = findItemsByName(access_schools, _email)
      const appUserAccess = {
        teamsAccess,
        sportsAccess,
        schoolsAccess
      }
      appUser_handlers.handleUpdateAccess(appUserAccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [access_sports, access_schools, sportsAccess_sports, sportsKey, accessClone])

  useEffect(() => {
    if (gls_scores && sportsKey && gls_scores[sportsKey]) {
      sportsSeason_handlers.handleSet_state('links_score', gls_scores[sportsKey])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [gls_scores, sportsKey])

  const getNextMatch = (matches) => {
    const sortProp = 'startDateUTC'
    const today = new Date();
    const firstAfterToday = _.takeRight(_.filter(_.orderBy(matches, [sortProp], ['desc']), item => new Date(item.startDate).setHours(_startHour, 0, 0, 0) >= today), 1);
    return firstAfterToday ? firstAfterToday[0] : null
  }

  const getLatestMatches = (matches, latestMatchesCount) => {
    const sortProp = 'startDateUTC'
    const today = new Date();
    const result = _.takeRight(_.filter(_.orderBy(matches, [sortProp], ['asc']), match => (match[_resultsProp] && match[_resultsProp].complete === true) && new Date(match.startDate).setHours(_startHour, 0, 0, 0) < today), latestMatchesCount);
    return result
  }

  const getMissing = () => {
    const { matches } = sportsSeason_state
    if (matches) {
      const missing = _.filter(Object.values(matches), function (m) {
        const today = new Date()
        const yesterday = today.setDate(today.getDate() - 1)
        return (
          new Date(m.startDate) < yesterday && !m[_resultsProp]
        );
      })
      if (missing) {
        const m_s = creatingHelpers.createKeyedObjectFromKey(missing)
        return m_s
      }
    }
  }

  const sportsSeason_fns = {

    getMatchDateGroups: (sortedMatches) => {

      const today = new Date()
      const todayF = formatItem(formatTypes.fullDate, today)

      // let districtMatchDates = _.groupBy(sortedMatches, 'districts')

      let matchDates = _.groupBy(sortedMatches, 'startDate')
      const x = sortObject(matchDates)

      const groups = []

      let groupKeys = Object.keys(x)
      groupKeys = _.sortBy(groupKeys, function (value) { return new Date(value); })

      groupKeys.forEach(gk => {
        groups.push({
          matchDate: gk,
          matches: creatingHelpers.createKeyedObjectFromKey(x[gk])
        })
      })

      if (groupKeys[todayF]) {
        groups.unshift({
          matchDate: todayF,
          matches: creatingHelpers.createKeyedObjectFromKey(x[todayF])
        })
      }

      const mz = getMissing()
      if (mz && Object.keys(mz).length > 0) {
        groups.push({
          matchDate: 'Missing',
          matches: mz
        })
      }

      return groups
    },

    getSelectedDateMatches: (matchDateGroups, ms_matchDates, ms_district, ms_level, team_favs, previewMatches) => {

      const { selected: date_selected } = ms_matchDates ? ms_matchDates : {}
      const { item: matchDate } = date_selected ? date_selected : {}

      // eslint-disable-next-line 
      const { selected: district_selected, items: district_items } = ms_district ? ms_district : {}
      const { item: district } = district_selected ? district_selected : {}

      let _district;
      if (districts_org && district) {
        const _d = _.findKey(districts_org, { name: district })
        if (_d) { _district = _d }
      }

      const { selected: level_selected } = ms_level ? ms_level : {}
      const { item: level } = level_selected ? level_selected : {}

      let _allMatches = {}
      if (matchDateGroups) {
        Object.keys(matchDateGroups).forEach(k => {
          const dgms = matchDateGroups[k].matches
          _allMatches = { ..._allMatches, ...dgms }
        })
      }

      const matchDateGroup = _.find(matchDateGroups, { matchDate: matchDate })
      const { matches: dateMatches, matchDate: selectedMatchDate } = matchDateGroup ? matchDateGroup : {}

      let sectionDateMatches;
      let levelDateMatches;

      if (_district) {
        switch (level) {
          case 'All':
            sectionDateMatches = dateMatches
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          case 'Non':
            sectionDateMatches = _.filter(dateMatches, { ls: 'Non-Section' })
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          default:
            sectionDateMatches = _.filter(dateMatches, { districts: _district, levels: level })
            levelDateMatches = _.groupBy(dateMatches, 'levels')
            break;
        }
      } else {
        switch (level) {
          case 'All':
            sectionDateMatches = dateMatches
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          case 'Non':
            sectionDateMatches = _.filter(dateMatches, { ls: 'Non-Section' })
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          default:
            sectionDateMatches = _.filter(dateMatches, { levels: level })
            levelDateMatches = _.groupBy(dateMatches, 'levels')
            break;
        }
      }

      let favMatches;

      if (team_favs && dateMatches && !previewMatches && matchDate !== 'Missing') {
        Object.keys(team_favs).forEach(pfKey => {
          Object.keys(dateMatches).forEach(key_match => {
            const dm = dateMatches[key_match]
            if (dm.teams) {
              if (dm.teams.includes(pfKey)) {
                if (!favMatches) { favMatches = {} }
                favMatches[key_match] = dm
              }
            }
          })
        })
      }

      return { sectionDateMatches, levelDateMatches, matchDate: selectedMatchDate, favMatches, allMatches: _allMatches }
    },

    getFilteredMatches: (sortedMatched, teamKey, selectedLocation, selectedResult, selectedSection, selectedOpponent) => {
      let sms = sortedMatched
      if (selectedLocation) { sms = _.filter(sms, function (m) { return m[selectedLocation] && m[selectedLocation].id === teamKey; }) }
      if (selectedResult) {
        sms = _.filter(sms, function (m) {
          if (selectedResult === 'tie') {
            return m[_resultsProp] && m[_resultsProp].tied && m[_resultsProp].tied.includes(teamKey)
          } else {
            return m[_resultsProp] && m[_resultsProp][selectedResult === 'win' ? 'winner' : 'loser'] && m[_resultsProp][selectedResult === 'win' ? 'winner' : 'loser'].id === teamKey;
          }
        })
      }
      let _selectedOpponent = selectedOpponent
      if (_selectedOpponent === 'none') { _selectedOpponent = null }
      if (selectedSection) { sms = _.filter(sms, function (m) { return selectedSection === 'non' ? (m.sections === 'Non' || m.sections === 'tournament') : (m.sections !== 'Non' && m.sections !== 'tournament'); }) }
      if (_selectedOpponent) { sms = _.filter(sms, function (m) { return m.away.id === _selectedOpponent || m.home.id === _selectedOpponent }) }
      return sms
    },

    getMatchesByDate: (selectedDate) => {
      const f = _.pickBy(matches, function (m) {
        if (m) { return m.startDate === selectedDate }
      })
      if (f) {
        Object.keys(f).forEach(k => { f[k].key = k })
        const fs = _.sortBy(f, 'startDateUTC')
        return fs
      }
    },

    getTeamsAvailableByDate: (selectedDate) => {
      const teams_available = {}
      const f = _.pickBy(matches, function (m) {
        if (m) { return m.startDate === selectedDate }
      })
      if (f) {
        Object.keys(teams).forEach(teamKey => {
          const ta = _.pickBy(f, function (m) {
            if (m) { return m.teams.includes(teamKey) }
          })
          if (Object.keys(ta).length === 0) {
            teams_available[teamKey] = teams[teamKey]
          }
        })
        return teams_available
      }
    },

    getTeamsAvailableByAllDates: () => {

      const dates_available = {}
      const { firstMatchDate, lastMatchDate } = seasonal_info

      const startDate = new Date(firstMatchDate);
      const endDate = new Date(lastMatchDate);

      // Create a loop to iterate through the dates
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        // Do something with currentDate
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are zero-based
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${month}/${day}/${year}`

        const teams_available = {}

        const f = _.pickBy(matches, function (m) {
          if (m) {
            const _startDate = new Date(m.startDate);
            const _currentDate = new Date(currentDate);
            return _startDate.getDate() === _currentDate.getDate()
          }
        })

        if (f) {
          Object.keys(teams).forEach(teamKey => {
            const ta = _.pickBy(f, function (m) {
              if (m) { return m.teams.includes(teamKey) }
            })
            if (Object.keys(ta).length === 0) {
              teams_available[teamKey] = teams[teamKey]
            }
          })
          dates_available[formattedDate] = teams_available
        }

        // Increment currentDate by one day
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates_available
    },
    getMatchesByTeam: (sortedMatched, teamKey, completedOnly, matchScheduleType, latestMatchesCount) => {
      const tms = completedOnly ?
        _.filter(Object.values(sortedMatched), function (m) { return m.teams && m.teams.includes(teamKey) && (m[_resultsProp] && m[_resultsProp].complete === true); })
        :
        _.filter(Object.values(sortedMatched), function (m) { return m.teams && m.teams.includes(teamKey); })

      switch (matchScheduleType) {
        case matchScheduleTypes.next:
          const nextMatch = getNextMatch(tms)
          return [nextMatch]
        case matchScheduleTypes.latest:
          const latestMatches = getLatestMatches(tms, latestMatchesCount)
          return latestMatches
        default:
          const fs = _.sortBy(tms, 'startDateUTC')
          return fs
      }
    },
    getMissingMatchResults: () => {
      const { matches } = sportsSeason_state
      const missing = _.filter(Object.values(matches), function (m) {
        return (
          new Date(m.startDate) < new Date()
          && !m[_resultsProp]
          && !m.cancelled
          && !m.postponed
        );
      })
      if (missing) {
        const m_s = creatingHelpers.createKeyedObjectFromKey(missing)
        return m_s
      }
    },
    getMatchesByLevel: (sortedMatched, level) => {
      return _.filter(Object.values(sortedMatched), function (m) { return m.levels === level; })
    },
    getMatchesBySection: (sortedMatched, section) => {
      return _.filter(Object.values(sortedMatched), function (m) { return m.sections === section; })
    },
    getMatch: (sortedMatched, matchKey) => {
      return sortedMatched && sortedMatched[matchKey] ? [sortedMatched[matchKey]] : {}
      // return _.filter(Object.values(sortedMatched), function (m) { return m.id === matchKey; })
    },
    getTeamsByLevel: (level) => {
      const st = _.pickBy(teams, function (t) {
        if (t) { return t.levels === level }
      })
      if (st) {
        Object.keys(st).forEach(k => {
          st[k].key = k
        })
        return st
      }
    },
    getTeamsBySection: (level, section) => {
      const st = _.pickBy(teams, function (t) {
        if (t) { return t.levels === level && t.sections === section }
      })
      if (st) {
        Object.keys(st).forEach(k => {
          st[k].key = k
        })
        return st
      }
    },
    getTeamsByMatch: (home, away) => {
      const { sections: hs, levels: hl } = home ? home : {}
      const { sections: as, levels: al } = away ? away : {}
      const st = _.pickBy(teams, function (t) {
        const { levels: lt, sections: st } = t
        if (t) {
          return (
            (lt === hl && st === hs) ||
            (lt === al && st === as)
          )
        }
      })
      if (st) {
        Object.keys(st).forEach(k => {
          st[k].key = k
        })
        return st
      }
    },
    getTeamRpis: (teams, matches) => {

      const rpis = {}

      const getTeamInfo = (teamKey) => {
        const team = teams[teamKey]
        const { name, record } = team
        const { w, l } = record ? record : {}
        const mwp = (w && l) ? (w / (w + l)).toFixed(3) : 0
        const ret = { name, w, l, mwp }
        return ret
      }

      Object.keys(teams).forEach(teamKey => {
        const team = teams[teamKey]
        const { record } = team
        const { w, l } = record ? record : {}
        const mwp = (w && l) ? (w / (w + l)).toFixed(3) : 0
        rpis[teamKey] = { name: teams[teamKey].name, mwp: mwp, opponents: {} }
        const matches_team = sportsSeason_fns.getMatchesByTeam(matches, teamKey, true)
        if (matches_team) {
          matches_team.forEach(match_team => {
            const { away, home } = match_team
            if (away.id === teamKey) { rpis[teamKey].opponents[home.id + 'home'] = getTeamInfo(home.id) }
            if (home.id === teamKey) { rpis[teamKey].opponents[away.id + 'away'] = getTeamInfo(away.id) }
          })
        }
      })

      Object.keys(rpis).forEach(oppKey => {
        const opp = rpis[oppKey]
        const { opponents } = opp
        if (opponents) {
          let ws = 0
          let ls = 0
          Object.keys(opponents).forEach(key => {
            const _opp = opponents[key]
            const { w, l } = _opp
            if (w && l) {
              ws += w
              ls += l
            }
          })
          opp.oppW = ws
          opp.oppL = ls
          opp.omwp = (ws && ls) ? (ws / (ws + ls)).toFixed(3) : 0
          opp.rpi = (opp.mwp * 0.25) + (opp.omwp * 0.50) // + (OOWP * 0.25)
        }
      })
      return rpis
    },
    sortSectionStandings: (teams, playoffTeamsPerSection, sportsModeSport, forPlayoff, sortStandingsByPoints) => sortDB.sortSectionStandings(teams, playoffTeamsPerSection, sportsModeSport, forPlayoff, sortStandingsByPoints),
    sortLevelStandings: (teams) => sortDB.sortLevelStandings(teams),
    addTeamInfoToAthletes: (athletes) => {
      const { teams: allTeams } = teams_info ?? {}
      // adding the parentKey info to each of the data items
      if (athletes) {
        Object.keys(athletes).forEach(key => {
          const athlete = athletes[key]
          const { teams } = athlete ? athlete : {}
          if (allTeams) {
            const t = _.find(allTeams, { id: teams })
            if (t) {
              const { name, levels, sections } = t
              if (levels) { athlete.levels = levels }
              if (sections) { athlete.sections = sections }
              if (name) { athlete.teams = name }
            }
          }
        })
      }
    },

    getMatchAthletes: async (pathViews, collectionName, teamId, callback, teams) => {
      const _team = teams && teams[teamId] ? teams[teamId] : null
      const _getRef = createRefPath_event(pathViews, [collectionName])
      const wheres = [where('parentKeys.teams', '==', teamId)]
      const _teamRoster = await database_fns.get_data({ refPath: _getRef, wheres, opts: { returnFirstObject: true } })
      if (_teamRoster) {
        const _data = creatingHelpers.createKeys(_teamRoster['athletes'])
        const athletes = _.sortBy(_data, 'lastName')
        if (athletes && _team) {
          Object.keys(athletes).forEach(key => {
            athletes[key].teams = _team.name
          })
        }
        if (athletes) { callback(athletes) }
      }
    },

    updateTeamBests: (rankings, teams) => {
      if (teams && rankings) {
        Object.keys(teams).forEach(teamKey => {
          const team = teams[teamKey]
          team.weeklyRankings = {
            best: null,
            byWeek: []
          }
          const teamName = team.name
          const rankingsSorted = sortObj(rankings)
          Object.keys(rankingsSorted).forEach(weekKey => {
            const week = rankings[weekKey]
            Object.keys(week).forEach(levelKey => {
              if (team.levels === levelKey) {
                const weekLevelTeams = week[levelKey]
                const teamRank = getArrayIndex(weekLevelTeams, teamName)
                if (teamRank >= 0) {
                  const teamRankThisWeek = teamRank + 1
                  team.weeklyRankings.byWeek.push(teamRankThisWeek)
                  if (team.weeklyRankings.best) {
                    if (teamRankThisWeek < team.weeklyRankings.best) {
                      team.weeklyRankings.best = teamRankThisWeek
                    }
                  } else {
                    team.weeklyRankings.best = teamRankThisWeek
                  }
                } else {
                  team.weeklyRankings.byWeek.push(0)
                }
              }
            })
          })
        })
      }
    },

    getTeamRecords: (teams, matches, levelKey) => getTeamRecords(teams, matches, levelKey),
    getTeamRecord: (team, matches, levelKey) => getTeamRecord(team, matches, levelKey),
  }

  return sportsSeason_state ?
    <SportsSeasonContext.Provider
      value={{ sportsSeason_state, sportsSeason_handlers, sportsSeason_fns, appComponent: 'sportsSeason' }}>
      {props.children}
    </SportsSeasonContext.Provider>
    :
    <div></div>
}

const getTeamRecord = (team, matches) => {

  const { name, _itemKey, record } = team

  const matches_team = _.filter(matches, function (m) {
    return m && m.home && m.away && (m.home.id === _itemKey || m.away.id === _itemKey);
  })

  const _rstz = {
    section: {
      wins: getFilteredMatchResults(matches_team, _itemKey, 'win', true),
      losses: getFilteredMatchResults(matches_team, _itemKey, 'lost', true),
    },
    nonSection: {
      wins: getFilteredMatchResults(matches_team, _itemKey, 'win'),
      losses: getFilteredMatchResults(matches_team, _itemKey, 'lost'),
    }
  }

  const _nonResults = {
    section: getFilteredMatchNonResults(matches_team, _itemKey, true),
    nonSection: getFilteredMatchNonResults(matches_team, _itemKey, false),
  }

  const teamResults = {
    section: {
      wins: _.map(_rstz.section.wins, match => ({
        opponent: match.away.id === _itemKey ? match.home : match.away,
        score: match[_resultsProp].score,
        sections: match.sections
      })),
      losses: _.map(_rstz.section.losses, match => ({
        opponent: match.away.id === _itemKey ? match.home : match.away,
        score: match[_resultsProp].score,
        sections: match.sections
      })),
    },
    nonSection: {
      wins: _.map(_rstz.nonSection.wins, match => ({
        opponent: match.away.id === _itemKey ? match.home : match.away,
        score: match[_resultsProp].score,
        sections: match.sections
      })),
      losses: _.map(_rstz.nonSection.losses, match => ({
        opponent: match.away.id === _itemKey ? match.home : match.away,
        score: match[_resultsProp].score,
        sections: match.sections
      })),
    }
  };

  // const results = {
  //   section: {
  //     wins: _.map(_rstz.section.wins, (match) => {
  //       return match.away.id === _itemKey ? { opponent: match.home, score: match.results.score, sections: match.sections } : { opponent: match.away, score: match.results.score, sections: match.sections }
  //     }),
  //     losses: _.map(_rstz.section.losses, (match) => {
  //       return match.away.id === _itemKey ? { opponent: match.home, score: match.results.score, sections: match.sections } : { opponent: match.away, score: match.results.score, sections: match.sections }
  //     }),
  //   },
  //   nonSection: {
  //     wins: _.map(_rstz.nonSection.wins, (match) => {
  //       return match.away.id === _itemKey ? { opponent: match.home, score: match.results.score, sections: match.sections } : { opponent: match.away, score: match.results.score, sections: match.sections }
  //     }),
  //     losses: _.map(_rstz.nonSection.losses, (match) => {
  //       return match.away.id === _itemKey ? { opponent: match.home, score: match.results.score, sections: match.sections } : { opponent: match.away, score: match.results.score, sections: match.sections }
  //     }),
  //   }
  // }

  // const nonResults = {
  //   section: _.map(_nonResults.section, (match) => {
  //     return match.away.id === _itemKey ? { opponent: match.home, sections: match.sections, startDate: match.startDate } : { opponent: match.away, sections: match.sections, startDate: match.startDate }
  //   }),
  //   nonSection: _.map(_nonResults.nonSection, (match) => {
  //     return match.away.id === _itemKey ? { opponent: match.home, sections: match.sections, startDate: match.startDate } : { opponent: match.away, sections: match.sections, startDate: match.startDate }
  //   })
  // }

  const mapNonResults = matches => {
    return _.map(matches, match => ({
      opponent: match.away.id === _itemKey ? match.home : match.away,
      sections: match.sections,
      startDate: match.startDate
    }));
  };

  const nonResults = {
    section: mapNonResults(_nonResults.section),
    nonSection: mapNonResults(_nonResults.nonSection)
  };

  return {
    _itemKey,
    name,
    teamResults,
    nonResults,
    record
  }

}

const getTeamRecords = (teams, matches, levelKey) => {

  const ts = {}

  const _teams = _.filter(teams, function (t) {
    return t && t.levels === levelKey;
  })

  _teams.forEach(team => {
    const { _itemKey, name, teamResults, nonResults, record } = getTeamRecord(team, matches)

    ts[_itemKey] = {
      _itemKey,
      name,
      teamResults,
      nonResults,
      record
    }
  })

  return ts
}

const getFilteredMatchResults = (sortedMatched, teamKey, selectedResult, inSection) => {
  let sms = sortedMatched
  if (selectedResult) {
    sms = _.filter(sms, function (m) {
      if (selectedResult === 'tie') {
        return m[_resultsProp] && m[_resultsProp].tied && m[_resultsProp].tied.includes(teamKey)
      } else {
        if (inSection) {
          return m.sections !== 'Non' && m[_resultsProp] && m[_resultsProp][selectedResult === 'win' ? 'winner' : 'loser'] && m[_resultsProp][selectedResult === 'win' ? 'winner' : 'loser'].id === teamKey;
        } else {
          return m.sections === 'Non' && m[_resultsProp] && m[_resultsProp][selectedResult === 'win' ? 'winner' : 'loser'] && m[_resultsProp][selectedResult === 'win' ? 'winner' : 'loser'].id === teamKey;
        }
      }
    })
  }
  return sms
}

const getFilteredMatchNonResults = (sortedMatched, teamKey, inSection) => {
  let sms = sortedMatched
  sms = _.filter(sms, function (m) {
    if (inSection) {
      return m.sections !== 'Non' && !m[_resultsProp] && (m.away.id === teamKey || m.home.id === teamKey);
    } else {
      return m.sections === 'Non' && !m[_resultsProp] && (m.away.id === teamKey || m.home.id === teamKey);
    }
  })
  return sms
}

// const getGlsFromSportsSettings = async (pathViews) => {
//   const refPath = createRefPath_event(pathViews, ['_seasonals'])
//   const wheres = []
//   wheres.push(where('sportsSettings.allowScheduledScoreUpdateFromGs', '==', true))
//   const data = await database_fns.get_data({ refPath, wheres, opts: { returnFirstObject: true } })
//   const { parentKeys } = data ?? {}
//   if (parentKeys) {
//     getScheduledFromGlsScores(pathViews, parentKeys)
//   }
// }

// const getScheduledFromGlsScores = async (pathViews, parentKeys) => {
//   const { sports } = parentKeys
//   const refPath = createRefPath_event(pathViews, ['_gls_scores'])
//   const wheres = [where(`gls_scores.${sports}`, "!=", null)]
//   const data = await database_fns.get_data({ refPath, wheres, opts: { returnFirstObject: true } })
//   if (data && data.gls_scores && data.gls_scores[sports]) {
//     console.log('gls_score', data.gls_scores[sports])
//   }
// }

// cjU4sycDK-54bn5Cfb6lZJ:APA91bEdlAYCod7KdB7rqE9szIkvR9SGziQFLUBH7JNu4IWH0aWWQyFTQiHdjISQCBhWFgzayHGde4fo2-B8XmXl62xc3DmJGRA0XXlobYKVWKC7UbS7DabM9pawkrIk8haQ54cIgnDl
// cjU4sycDK-54bn5Cfb6lZJ:APA91bEdlAYCod7KdB7rqE9szIkvR9SGziQFLUBH7JNu4IWH0aWWQyFTQiHdjISQCBhWFgzayHGde4fo2-B8XmXl62xc3DmJGRA0XXlobYKVWKC7UbS7DabM9pawkrIk8haQ54cIgnDl
// cjU4sycDK-54bn5Cfb6lZJ:APA91bEdlAYCod7KdB7rqE9szIkvR9SGziQFLUBH7JNu4IWH0aWWQyFTQiHdjISQCBhWFgzayHGde4fo2-B8XmXl62xc3DmJGRA0XXlobYKVWKC7UbS7DabM9pawkrIk8haQ54cIgnDl
// cjU4sycDK-54bn5Cfb6lZJ:APA91bEdlAYCod7KdB7rqE9szIkvR9SGziQFLUBH7JNu4IWH0aWWQyFTQiHdjISQCBhWFgzayHGde4fo2-B8XmXl62xc3DmJGRA0XXlobYKVWKC7UbS7DabM9pawkrIk8haQ54cIgnDl