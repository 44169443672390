import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Button, Header, Icon, Label, Menu, Portal, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { HeaderContext } from '../cnr/contexts/HeaderContext';
import { PapsContext } from '../cnr/contexts/PapsContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { PreviewContext } from '../cnr/contexts/PreviewContext';
import { uniqueKey } from '../common/keys';
import { appIconTypes } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import UiAppUserAction from '../pageItem/modification/userActions/UiAppUserAction';
import { currentHelpers } from '../redirection/current';
import TopMenuIcons from './TopMenuIcons';

// https://codepen.io/gpsn/pen/PPqrZw

/** PageTopHeader`.  
 * @description Returns a menu component for the app.
 */
const PageTopHeader = () => {

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appSettings_state, appUser_state, page_state, paps_state, startData_state, appNotifications_state } = states
  const { pushPayload, showPayload } = appNotifications_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles, aps_page, aps_appUserSettings } = pageSettings ?? {}
  const { display: display_global } = aps_global ?? {}
  const { showPageTypeBreadcrumb: showPageTypeBreadcrumb_g } = display_global ?? {}
  const { appUser_handlers, appNotifications_handlers } = handlers

  const { appConfigSettings } = appSettings_state ?? {}
  const { appNames } = appConfigSettings ?? {}
  const { appNameSc } = appNames ?? {}

  const { allowFavorites, favoriteCollections } = aps_appUserSettings ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  const { loggedIn, pageFavs } = appUserAccess ?? {}

  // previewContext
  const previewContext = useContext(PreviewContext)
  const { preview_state } = previewContext ?? {}
  const { settingsPreviewHasChanged } = preview_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { isMobileDevice } = framework_state ?? {}

  const { pageDatas } = startData_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { navigate } = papsContext ?? {}
  const { otherView, view, viewKey, pathViews, isHomePage, pathName } = paps_state

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_state } = headerContext ?? {}
  const { appCaptions, itemCount, allowItemLinking, actionCaption, itemLinked, headerFontSizes } = header_state ?? {}
  const { main: caption_main, sub: caption_sub, year: caption_year } = appCaptions ?? {}

  // main PAGE caption
  const { menu: menu_page } = aps_page ?? {}
  const { showPageTypeBreadcrumb, showParentBreadcrumbs } = menu_page ?? {}

  const _showFavorite = viewKey && allowFavorites && favoriteCollections && favoriteCollections.includes(view)

  // DATA caption
  const { pageDataCaption } = page_state ?? {}

  // local state   
  const [fontSizes, setFontSizes] = useState({ title: 1, subTitle: .8, hideSubTitle: false })

  const [showAction, setShowAction] = useState()
  const [_main, set_main] = useState()
  const [_sub, set_sub] = useState()
  const [_year, set_year] = useState()

  const isPageFav = pageFavs && pageFavs[view] && pageFavs[view][viewKey] ? true : false

  useEffect(() => {
    if (isHomePage) {
      set_main(_.startCase(appNameSc) + ' ' + caption_main)
    } else {
      if (caption_main === 'Events') {
        set_main(_.startCase(view))
      } else {
        set_main(caption_main)
      }
    }

    set_year(pathViews.sports ? caption_year : null)
    set_sub(caption_sub)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [view, viewKey, caption_main, caption_sub, caption_year, settingsPreviewHasChanged]);

  useEffect(() => {
    if (headerFontSizes) {
      setFontSizes(headerFontSizes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [headerFontSizes]);

  const topSac = aps_styles ? aps_styles[gEnums.projectStyles.topMenu] : {}

  let { className, style } = topSac ?? {}

  if (isMobileDevice) {
    className += ' imd'
  }

  const p = {}
  const { myNotificationsU } = p

  if (style && !style.backgroundColor) { className = 'bc-main' }

  const handleShowAction = () => setShowAction(!showAction)

  const handlePageFavorite = () => {
    const favItem = {
      [view]: {
        [viewKey]: {
          name: _main ? _main : pageDataCaption,
          pathName: pathName
        }
      }
    }
    appUser_handlers.handleAmmend_pageFavorite(paps_state, _main, favItem)
  }

  const seg_payload = () => {
    const { title, body, url } = pushPayload ?? {}
    return <Portal onClose={appNotifications_handlers.handleClose_payload} open={true}>
      <Segment className={'seg-payload'}       >
        <Header>{title}</Header>
        <p>{body}</p>
        <p>{url}</p>
        <Button
          size='small'
          content='Close'
          floated='right'
          onClick={appNotifications_handlers.handleClose_payload}
        />
      </Segment>
    </Portal>
  }

  const breadcrumbs = () => {
    let bcrs = []
    if (view !== 'landing' && (showPageTypeBreadcrumb || (viewKey && showPageTypeBreadcrumb_g))) { bcrs.push({ key: view, content: _.startCase(view), link: false }) }
    if (showParentBreadcrumbs && pageDatas) {
      if (pathViews) {
        Object.keys(pathViews).forEach(pv => {
          if (pageDatas[pv] && pv !== view) {
            bcrs.push({ key: pageDatas[pv], content: pageDatas[pv], link: false })
          }
        })
      }
    }
    return bcrs && bcrs.length > 0 && <Breadcrumb className={'item-breadcrumb'} icon='right angle' sections={bcrs} style={{ fontSize: fontSizes.subTitle + 'rem', display: fontSizes.hideSubTitle ? 'none' : null }} />
  }

  const miSidebar = () => {
    if (myNotificationsU && myNotificationsU.length > 0) {
      return <Menu.Item key={uniqueKey('pth', 'n')} onClick={() => framework_handlers ? framework_handlers.handleShow_appSidebar() : null} >
        <Icon.Group>
          <Icon name={appIconTypes.content} />
          <Icon corner name={appIconTypes.add} size='large' color='red' />
        </Icon.Group>
      </Menu.Item>
    } else {
      return <Menu.Item key={uniqueKey('pth', 'n')} onClick={() => framework_handlers ? framework_handlers.handleShow_appSidebar() : null} >
        <Icon name={appIconTypes.content} />
      </Menu.Item>
    }
  }

  const miBackOrLogo = () => <Menu.Item kkey={uniqueKey('pth', 'mi', 'b')} icon={appIconTypes.arrowLeft} onClick={() => { currentHelpers.pushBack(navigate) }} ></Menu.Item>
  const miFavorite = () => <Menu.Item key={uniqueKey('pth', 'mi', 'f')} icon={isPageFav ? 'star' : 'star outline'} onClick={() => { handlePageFavorite() }} ></Menu.Item>
  const miPayload = () => <Menu.Item key={uniqueKey('pth', 'mi', 'pl')} icon={'bell'} onClick={() => { appNotifications_handlers.handleShow_payload() }} ></Menu.Item>
  const miItemCount = () => <Menu.Item key={uniqueKey('pth', 'mi', 'ic')}><Label circular color={'grey'}>{itemCount}</Label></Menu.Item>
  const miAction = () => {
    if (actionCaption) {
      return <Menu.Item key={uniqueKey('pth', 'mi', 'ac')}><Label size={'mini'} circular color={itemLinked ? 'green' : 'grey'} onClick={() => { handleShowAction() }}>{actionCaption}</Label></Menu.Item>
    } else {
      return <Menu.Item key={uniqueKey('pth', 'mi', 'ac')}><Icon name={'check'} circular inverted color={itemLinked ? 'green' : 'grey'} onClick={() => { handleShowAction() }}></Icon></Menu.Item>
    }
  }

  const miTitle = () => <Menu.Item
    className={'menu-title'}
    key={uniqueKey('pth', 'mi', 't')}
    style={{ fontSize: fontSizes.title + 'rem' }}
  >
    <div style={{}}>{_main}</div>
    {_year && <div>{_year}</div>}
    {_sub && <div>{_sub}</div>}
    {breadcrumbs()}
  </Menu.Item>

  const menuRight = () => {
    return <Menu.Menu position='right'>
      {itemCount && miItemCount()}
      {loggedIn && _showFavorite && miFavorite()}
      {pushPayload && miPayload()}
      {allowItemLinking && miAction()}
      {loggedIn && <TopMenuIcons />}
    </Menu.Menu>
  }

  let includeBack = true

  switch (view) {
    case 'home':
    case 'clients':
    case 'events':
    case 'landing':
      if (otherView) {
        includeBack = true
      } else {
        includeBack = false
      }
      break;
    default:
      includeBack = true
  }

  const content = () => <Menu
    style={style}
    className={className}
    borderless
    inverted
  >
    {!includeBack && miSidebar()}
    {includeBack && miBackOrLogo()}
    {miTitle()}
    {menuRight()}
    {showPayload && seg_payload()}
  </Menu>

  if (showAction) {
    return <UiAppUserAction handleCancel={handleShowAction} itemLinked={itemLinked} />
  } else {
    return content()
  }
}

export default PageTopHeader