import _ from 'lodash'
import React, { useContext } from 'react'
import { Label } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { SportsSeasonContext, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext'
import MatchScoreButtons from './MatchScoreButtons'
import MatchOptions from './MatchOptions'
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext'

const key_match = 'ms-match-score'

/**
 * 
 * @param {object} props 
 * @returns The score display portion of the match UI
 */
const MatchScoreDisplay = (props) => {

  const {
    display,
    keyy,
    mediaResults,
    origin,
  } = props

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { rtSettings } = sportsSeason_state ?? {}
  const { rtMatchDataView, rtMatchUpdates } = rtSettings ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state } = seasonMatchesContext ?? {}
  const { matchScheduleType } = seasonMatches_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, matchAllows, showRtScoring, showMatchOptions, realtimeMatchData } = sportsMatch_state ?? {}
  const { match_rt } = realtimeMatchData ?? {}
  const { scores: allowScoreEdits } = matchAllows ?? {}
  const { handleShow_rtScoring, handleShow_scoreEdit, handleShow_matchOptions } = sportsMatch_handlers ?? {}

  const _allowScoreEdits = allowScoreEdits && origin === 'matchSingle'

  if (match_rt) {
    console.log('match_rt', match_rt)
  }

  const handleMatchClick_rt = (e) => {
    e.stopPropagation()
    if (rtMatchUpdates) {
      handleShow_rtScoring()
    } else {
      handleShow_scoreEdit(match_true)
    }
  }

  const handleMatchClick_edit = (e) => {
    e.stopPropagation(e)
    handleShow_matchOptions(match_true)
  }

  const teamSetLabel = (score, t) => {
    let cn = matchScheduleType === matchScheduleTypes.latest ? 'team-score-' + t : t
    return <Label className={cn} key={uniqueKey('ms', 'tsl', t)} >{score.score}</Label>
  }

  const teamScores = (score, team, t) => teamSetLabel(score, t)

  const matchLabel = (setNumber, display) => <Label
    className={matchScheduleType === matchScheduleTypes.latest ? 'match-display-score' : null}
    key={uniqueKey(key_match, keyy)}
    onClick={(e) => { _allowScoreEdits && handleMatchClick_rt(e) }}>
    <div>{'Set ' + setNumber}</div>
    <div>{display}</div>
  </Label>

  const matchSets = (setNumber, display) => matchLabel(setNumber, display)

  const matchDisplay_rt = () => {

    const { score, scores, setNumber, set_complete_possible } = match_rt
    const { home, away } = score ? score : {}
    const _away = away ? away : 0
    const _home = home ? home : 0
    const _display = _away + '-' + _home

    const rtScores = {
      home: 0,
      away: 0,
      setNumber: 0
    }

    if (scores) {
      Object.keys(scores).forEach((sk, index) => {
        const _score = scores[sk]
        if (_score.home || _.isNumber(_score.home)) {
          rtScores.home = {
            score: _score.home,
            color: _score.home > _score.away ? 'green' : _score.home < _score.away ? 'red' : 'blue',
            cn: _score.home > _score.away ? 'up' : _score.home < _score.away ? 'down' : 'tie'
          }
          rtScores.setNumber = index
        }
        if (_score.away || _.isNumber(_score.away)) {
          rtScores.away = {
            score: _score.away,
            color: _score.away > _score.home ? 'green' : _score.away < _score.home ? 'red' : 'blue',
            cn: _score.away > _score.home ? 'up' : _score.away < _score.home ? 'down' : 'tie'
          }
          rtScores.setNumber = index
        }
      })
    }

    let cn = 'match-rt'
    if (set_complete_possible) { cn += ' scp' }

    return <div key={uniqueKey(key_match, keyy)} className={cn} onClick={(e) => { _allowScoreEdits && handleMatchClick_rt(e) }}>
      {teamScores(rtScores.away, 'away', 'away ' + rtScores.away.cn)}
      {matchSets(setNumber, _display)}
      {teamScores(rtScores.home, 'home', 'home ' + rtScores.home.cn)}

    </div>
  }

  const matchDisplay_normal = (cn) => <div
    key={uniqueKey(key_match, keyy)}
    className={cn}
    onClick={(e) => { _allowScoreEdits && handleMatchClick_edit(e) }}>
    {display}
    {/* {mediaResults && <div>TEST</div>} */}
  </div>

  const matchDisplay = () => {
    let cn = 'match-display'
    if (mediaResults) { cn += ' mmrr' }
    if (match_rt && rtMatchDataView) {
      return <React.Fragment>
        {matchDisplay_rt()}
        {showRtScoring && <MatchScoreButtons match_rt={match_rt} matchScheduleType={matchScheduleType} />}
        {showMatchOptions && <MatchOptions />}
      </React.Fragment>
    } else {
      return matchDisplay_normal(cn)
    }
  }

  return matchDisplay()

}

export default MatchScoreDisplay