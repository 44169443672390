import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { AppUserContext } from '../../../../global/cnr/contexts/AppUserContext';
import { createInitials } from '../../../../global/common/dataAdjust';
import { formatItem, formatTimeAmPm, formatTypes } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import { TimePickerDirect } from '../../../../global/components/forms/elements/TimePicker';
import { gEnums } from '../../../../global/enums/globalEnums';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext, _resultsProp } from '../../cnr/contexts/SportsSeasonContext';
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { CreatePlayoffContext } from '../../create/CreatePlayoff';
import { getSectionColor } from '../../create/sectionColors';
import { tournamentTypes } from '../../create/subs/CreateTournamentSchedule';
import TeamList from '../teams/TeamList';
import MatchScoreDisplay from './MatchScoreDisplay';

const nameLimit = 18

const PoolMatch = (props) => {

  const { match_edit, match, wcn, forGrid, tournamentType } = props

  let cn_matchGrid = wcn
  if (match && match.sectionMeeting) { cn_matchGrid += ' sm' }

  const results = match ? match[_resultsProp] : undefined;
  const { scoreDisplays } = results ?? {}
  const { normal: scoreDisplay } = scoreDisplays ?? {}

  const appUserContext = useContext(AppUserContext);
  const { appUser_fns } = appUserContext ?? {}
  const accessLevel = appUser_fns.get_accessLevel()
  const allowScoreEdits = accessLevel >= gEnums.accessLevels.superAdminDeveloper.value ? true : false

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { rtSettings } = sportsSeason_state ?? {}
  const { allowRtMatchUpdates } = rtSettings ?? {}
  const allowRealTime = allowRtMatchUpdates

  // createPlayoffContext
  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state, createPlayoff_handlers } = createPlayoffContext ?? {}
  const { showPlayoffMatchLocation, showPlayoffMatchTime } = createPlayoff_state ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const { matchScheduleType } = seasonMatches_state ?? {}
  const { handleMatch_editClick } = seasonMatches_handlers ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, realtimeMatchData, showMatchOptions, fromLevelDates, matchAllows } = sportsMatch_state ?? {}
  // const { handleShow_scoreEdit } = sportsMatch_handlers ?? {}
  const { _itemKey } = match_true ?? {}
  const { match_rt } = realtimeMatchData ?? {}
  const { edit: _allowEdit, scores: _allowScores, matchAccessLevel } = matchAllows ?? {}

  const _showMatchOptions = showMatchOptions

  // sportsMatchContext
  // const sportsRtMatchContext = useContext(SportsRtMatchContext)
  // const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  // const { match_rt } = sportsRtMatch_state ?? {}

  const handleShowEdit = (e, m) => {
    e.stopPropagation()
    if (allowScoreEdits) {
      const opts = { sportsShowType: sportsShowTypes.updateResults, matchKey: _itemKey }
      sportsSeason_handlers.handleShow_sportsElem(opts)
    }
  }

  const handleShowLocation = (m) => allowScoreEdits && createPlayoff_handlers && createPlayoff_handlers.handleShow_playoffLocation(m)
  const handleShowTime = (m) => allowScoreEdits && createPlayoff_handlers && createPlayoff_handlers.handleShow_playoffMatchTime(m)
  const handleSetLocation = (team) => allowScoreEdits && createPlayoff_handlers && createPlayoff_handlers.handleUpdate_matchLocation(team)
  const handleSetTime = (t) => allowScoreEdits && createPlayoff_handlers && createPlayoff_handlers.handleUpdate_matchTime(t.formatted12)

  useEffect(() => {
    // const tm = match_edit && (match_edit.key === match.key) ? match_edit : match 
    sportsMatch_handlers && sportsMatch_handlers.handleSet_trueMatch(match)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_edit, match]);

  const handle_matchClick = () => {
    switch (tournamentType) {
      case tournamentTypes.newPlayoff:
        break;
      default:
        return handleMatch_editClick({ key: 'matches' }, _itemKey, null, match_true)
    }
  }

  const getPendingMatch = (pending, pendingType) => {
    if (pending) {
      const { round, roundKey, pool, place, poolName, matchNumber } = pending ?? {}
      if (roundKey === 'playoffs') {
        return poolName + ' #' + matchNumber + '/' + place
      } else {
        return 'R' + round + '/' + (poolName ? poolName + '-' + matchNumber : 'P' + pool) + '/' + place
      }
    } else {
      return null
    }
  }

  const matchInProgressDisplay = (display) => <MatchScoreDisplay keyy={_itemKey} origin={'poolMatch'} />

  const locationPlayoffIcon = (isBye, locationNotConfirmed) => isBye ? ' ' : <Icon color={locationNotConfirmed ? 'yellow' : 'blue'} name={'map marker alternate'} />

  const locationPlayoffText = (isBye, locationName, homeName) => {
    let locationText = ''
    let locationNotConfirmed = false
    if (isBye) {
      locationText = ' '
    } else {
      if (!locationName) {
        locationText = homeName ? '? ' + homeName + ' ?' : 'TBD'
        locationNotConfirmed = true
      } else {
        locationText = locationName
      }
    }
    return { locationText, locationNotConfirmed }
  }

  const teamDivs = (teamDivProps) => {
    const { _winner, _awayName, _awaySeed, away_cn, awayResult, _homeName, _homeSeed, home_cn, homeResult, seedLabel, seedColors, resultLabel } = teamDivProps
    const divs = []
    divs.push(<div key={uniqueKey('pm', 'ht')} className={away_cn}>{seedLabel(_awaySeed, seedColors.result.away)}<span>{_awayName}</span></div>)
    divs.push(<div key={uniqueKey('pm', 'at')} className={home_cn}>{seedLabel(_homeSeed, seedColors.result.home)}<span>{_homeName}</span></div>)
    divs.push(resultLabel('away', awayResult, seedColors.result.away, _winner === 'away' ? 'w' : null))
    divs.push(resultLabel('home', homeResult, seedColors.result.home, _winner === 'home' ? 'w' : null))
    return divs
  }

  const poolMatch = () => {

    const { home, away, work, matchNumber, seeds, courtNumber, pending, location, name: matchName, startDate, startTime, _flipHa, isPlayoff, isConsolation, ls, poolKey, sectionMeeting } = match_true ?? {}

    let _ls = ls

    if (isPlayoff) {
      _ls = _.startCase(poolKey)
      if (isConsolation) {
        _ls = _ls.replace('Finals', 'Consulation')
      } else if (isPlayoff) {
        if (poolKey !== 'finals') {
          _ls += ' - #' + matchNumber
        }
      }
    }

    const results_true = match_true ? match_true[_resultsProp] : undefined;
    const matchHasResults = !_.isEmpty(results_true)

    const _rstz = allowRealTime && match_rt ? match_rt : results_true

    const _startTime = formatTimeAmPm(startTime)

    const { home: home_pending, away: away_pending, work: work_pending } = pending ?? {}
    const { name: locationName } = location ?? {}
    const { score, scores, winner, setNumber, scoreDisplays, complete } = _rstz ?? {}
    const { scores: scoreDisplay_scores } = scoreDisplays ?? {}
    const { id: winnerId } = winner ?? {}

    const _currentSetScore = scores && setNumber && scores[setNumber] ? scores[setNumber] : score

    const homeName = home ? home.name : getPendingMatch(home_pending)
    const awayName = away ? away.name : getPendingMatch(away_pending)
    const workName = work ? work.name : getPendingMatch(work_pending)

    const _homeName = homeName.length > nameLimit ? createInitials(homeName, 3) : homeName
    const _awayName = awayName.length > nameLimit ? createInitials(awayName, 3) : awayName

    const homeSeed = seeds ? seeds['home'] : null
    const awaySeed = seeds ? seeds['away'] : null

    const awayResult = score ? score.away : '-'
    const homeResult = score ? score.home : '-'

    const seedLabel = (seed, color) => <Label key={uniqueKey('pm', 'sl', seed)} color={color} circular size={'mini'}>{seed}</Label>
    const resultLabel = (team, teamScore, color, cn) => <div key={uniqueKey('pm', 'sl', team, teamScore)} className={'result-' + team + ' ' + cn} >{teamScore}</div>

    // const scoreLabel = (team, score, cn) => <div key={uniqueKey('pm', 'rl', team, score)} className={'score-' + team + ' ' + cn} >{score}</div>

    if (_homeName && _awayName) {

      const _awaySeed = away && away.seed && away.seed !== awaySeed ? away.seed : awaySeed
      const _homeSeed = home && home.seed && home.seed !== homeSeed ? home.seed : homeSeed

      let away_upset = false;
      let home_upset = false;

      if (!_flipHa) {
        away_upset = matchHasResults && away && away.id === winnerId ? true : false
        home_upset = matchHasResults && home && home.seed > homeSeed ? true : false
      } else {
        away_upset = matchHasResults && home && home.id === winnerId ? true : false
        home_upset = matchHasResults && away && away.seed > awaySeed ? true : false
      }

      const _winner = away && away.id === winnerId ? 'away' : 'home'

      const isBye = _awayName === 'BYE'

      const { locationText, locationNotConfirmed } = locationPlayoffText(isBye, locationName, _homeName)

      if (isBye) { cn_matchGrid += ' bye' }
      if (isConsolation) { cn_matchGrid += ' consolation' }

      const m = []

      let match_cn = 'pool-match'
      let away_cn = 'match-away'
      let home_cn = 'match-home'

      if (complete || isBye) { cn_matchGrid += ' complete' }

      if (forGrid) {

        let cn_matchDates = 'match-dates'
        if (matchAccessLevel) { cn_matchDates += ' mal-' + matchAccessLevel }

        const showResults = scoreDisplay_scores && !fromLevelDates

        if (showResults || 1 === 1) { cn_matchDates += ' results' }

        const seedColors = {
          result: {
            away: 'grey',
            home: 'grey',
          },
          score: {
            away: 'grey',
            home: 'grey',
          }
        }

        const { sections: section_away } = away ?? {}
        const { sections: section_home } = home ?? {}

        if (section_away) { seedColors.result.away = getSectionColor(section_away) }
        if (section_home) { seedColors.result.home = getSectionColor(section_home) }

        if (away_upset) (away_cn += ' upset')
        if (home_upset) (home_cn += ' upset')

        if (winnerId && _winner === 'away') { away_cn += ' w' }
        if (winnerId && _winner === 'home') { home_cn += ' w' }

        if (allowRealTime && match_rt && _currentSetScore) {
          match_cn += ' rt'
          seedColors.score.away = _currentSetScore.home > _currentSetScore.away ? 'up' : _currentSetScore.home < _currentSetScore.away ? 'down' : 'tie'
          seedColors.score.home = _currentSetScore.away > _currentSetScore.home ? 'up' : _currentSetScore.away < _currentSetScore.home ? 'down' : 'tie'
        }

        const teamDivProps = { _winner, _awayName, _awaySeed, away_cn, awayResult, _homeName, _homeSeed, home_cn, homeResult, seedLabel, seedColors, resultLabel, _flipHa }

        let cn_teams = 'teams'
        if (_flipHa) { cn_teams += ' flip' }

        m.push(<div className={match_cn} key={uniqueKey('pool-match')} onClick={() => { !isBye && handle_matchClick() }}>
          <div className={'match-ls'}><Label color='grey' size='tiny'>{_ls}</Label></div>
          <div className={cn_teams}>
            {teamDivs(teamDivProps)}
          </div>
          {startDate && startTime && <div key={uniqueKey('pm', 'mdt')} className={cn_matchDates} onClick={(e) => { handleShowEdit(e, match_true) }}>
            {startDate && <div key={uniqueKey('pm', 'md')} className={'match-date'}>{isBye ? '' : formatItem(formatTypes.dateDayMonth, startDate)}</div>}
            {startTime && <div key={uniqueKey('pm', 'mt')} className={'match-time'} onClick={() => { handleShowTime(match_true) }}>{isBye ? '' : _startTime}</div>}
            <div key={uniqueKey('pm', 'mw')} className={'match-location plo'} onClick={() => { handleShowLocation(match_true) }}>{locationPlayoffIcon(isBye, locationNotConfirmed)}{locationText}</div>
            {showResults && <div key={uniqueKey('pm', 'mr')} className={'match-results'}>{scoreDisplay_scores ? scoreDisplay_scores : '---'}</div>}
          </div>}
        </div>)
      } else {
        if (courtNumber) {
          m.push(<div className={'pool-header'}>
            {!location && <div key={uniqueKey('pm', 'mc')} className={'match-court'}>{'Match #'}{matchNumber}{' - '}{'court '}{courtNumber}</div>}
            {!location && <div key={uniqueKey('pm', 'mw')} className={'match-work'}>{'Work: '}{workName}</div>}
          </div>)
        } else {
          m.push(<div className={'pool-header'}>
            {<div key={uniqueKey('pm', 'mc')} className={'match-court'}>{matchName}{' #'}{matchNumber}</div>}
            {locationName && <div key={uniqueKey('pm', 'mw')} className={'match-work'}>{'Location: '}{locationName}</div>}
          </div>)
        }
        m.push(<div className={'pool-match'}>
          <div key={uniqueKey('pm', 'pm', 'at')} className={away_cn}>{<Label color={'green'} size={'mini'}>{awaySeed}</Label>}{_awayName}</div>
          <div key={uniqueKey('pm', 'pm', 'ht')} className={home_cn}>{_homeName}<Label color={'green'} size={'mini'}>{homeSeed}</Label></div>
        </div>)
      }

      if (match_rt) {

        m.push(matchInProgressDisplay(scoreDisplay))

      }

      return <div
        key={uniqueKey('pm', 'pm')}
        className={cn_matchGrid}
      >
        {m}
      </div>
    }
  }

  if (showPlayoffMatchLocation) {
    return <TeamList handleCancel={handleShowLocation} handleSelect={handleSetLocation} />
  } else if (showPlayoffMatchTime) {
    return <TimePickerDirect handleCancel={handleShowTime} initialValue={match.startTime} handleDone={handleSetTime} />
  } else {
    return match_true ? poolMatch() : <div></div>
  }

}

export default PoolMatch