import { where } from "firebase/firestore";
import _ from "lodash";
import { gEnums } from '../../enums/globalEnums';
import { getStartEnd } from './getRefProps';
import { currentHelpers } from "../../redirection/current";

/**
 * 
 * @param {string} queryType 
 * @returns the corresponding firbase array query string
 */
export const getConstraintType = (queryType) => {
  switch (queryType) {
    case gEnums.queryTypes.arrayContains:
      return 'array-contains'
    case gEnums.queryTypes.arrayContainsAny:
      return 'array-contains-any'
    case gEnums.queryTypes.equalTo:
      return '=='
    case gEnums.queryTypes.greaterThan:
      return '>'
    case gEnums.queryTypes.greaterThanOrEqualTo:
      return '>='
    case gEnums.queryTypes.in:
      return 'in'
    case gEnums.queryTypes.lessThan:
      return '<'
    case gEnums.queryTypes.lessThanOrEqualTo:
      return '<=>'
    case gEnums.queryTypes.notEqualTo:
      return '!='
    case gEnums.queryTypes.notIn:
      return 'not-in'
    default:
      return '=='
  }
}

/**
 * 
* @param {object} acProps 
* @returns {whereOpts, orders}
* @description `!=` where("capital", "!=", false))
* @description `array-contains` where("regions", "array-contains", "west_coast")
* @description `in` where('country', 'in', ['USA', 'Japan'])
* @description `not-in` where('country', 'not-in', ['USA', 'Japan'])
* @description `array-contains-any` where('regions', 'array-contains-any', ['west_coast', 'east_coast']) > the given field is an array
* @note in, not-in, and array-contains-any support up to 10 comparison values.
* @note You can use at most one array-contains clause per query. You can't combine array-contains with array-contains-any.
* @note You can use at most one in, not-in, or array-contains-any clause per query. You can't combine these operators in the same query.
* @note You can't combine not-in with not equals !=.
* @note You can't order your query by a field included in an equality (==) or in clause
 */
export const getWhereConditionals = (acProps) => {

  let { alphabetValue, orders } = acProps

  const {
    appUserAccess,
    currentPageData,
    dataFilters_alt,
    dataFilters,
    dataOverrideOn,
    dataConstraints,
    dataSource,
    grouping,
    hideInactiveItems_do,
    logging,
    nonLandingView,
    pathViews,
    searchingValue,
    sortProp,
    trueUivi,
    viewKey,
    whereOpts,
    _useStartDataCollections,
  } = acProps

  whereOpts.forVi = trueUivi

  const { appUserSession, accessLevel } = appUserAccess ?? {}
  const { _itemKey: appUserSessionId, email: appUserEmail } = appUserSession ?? {}
  const { fetchByAlphabet } = grouping ?? {}

  const _dataFilters = dataFilters_alt ? dataFilters_alt : dataFilters

  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  const {
    dataParents,
    subDataCollectionName,
    useSubDataCollection,
  } = dataSource ?? {}

  const {
    allowConstraintOverrides, hideInactiveItems, filterActive, filterName, ignoreConstraintsAdminLevel, ignoreConstraintsAppUserList,
    ignoreConstraintsList, maskActive, maskProp, useAppUserDataKeysConstraint,
    usePageConstraint, usePostPageConstraint, pageConstraintType, pageConstraintTypeProp,
    useDataConstraints, usePostDataConstraint, dataConstraintItems,
    useDataMatchConstraints, usePostDataMatchConstraint, dataMatchConstraintItems,
    useAppUserPageConstraint, usePostAppUserPageConstraint, appUserPageConstraintType, appUserPageConstraintTypeProp,
    useAppUserDataConstraints, usePostAppUserDataConstraint, appUserDataConstraintItems
  } = _dataConstraints ?? {}

  const pageProps = { usePageConstraint, usePostPageConstraint, pageConstraintType, pageConstraintTypeProp }
  const dataProps = { useDataConstraints, usePostDataConstraint, dataConstraintItems }
  const dataMatchProps = { useDataMatchConstraints, usePostDataMatchConstraint, dataMatchConstraintItems }
  const appUserPageProps = { useAppUserPageConstraint, usePostAppUserPageConstraint, appUserPageConstraintType, appUserPageConstraintTypeProp }
  const appUserDataProps = { useAppUserDataConstraints, usePostAppUserDataConstraint, appUserDataConstraintItems }

  const showRefLog = logging && logging.allowLogging && logging.logRefProps
  let _dataConstraintItems = dataConstraintItems ? dataConstraintItems : []

  const _where_page = []
  const _where_appUserPage = []
  const _where_appUserData = []
  const _where_data = []

  getDataParentKeysConstraints(useSubDataCollection, subDataCollectionName, dataParents, pathViews, _where_page)
  getPageConstraints(pageProps, acProps, _where_page, showRefLog)
  getMaskConstraints(appUserAccess, maskActive, maskProp, _where_page)
  getFilterConstraints(filterActive, filterName, _dataFilters, _where_page)
  getImageConstraints(trueUivi, nonLandingView, viewKey, _where_page)

  getAppUserPageConstraints(appUserPageProps, acProps, _where_appUserPage)

  let _useConstraints = true
  if (allowConstraintOverrides && ignoreConstraintsAdminLevel && (accessLevel >= ignoreConstraintsAdminLevel)) { _useConstraints = false }

  const _dcInactive = _dataConstraintItems ? _.find(_dataConstraintItems, { propValue: 'inactive' }) : null
  const _hideInactiveItems = hideInactiveItems_do || hideInactiveItems
  let _usePostDataConstraint = _hideInactiveItems;

  if (_hideInactiveItems && !_dcInactive && !dataOverrideOn) {
    dataProps.useDataConstraints = true
    dataProps.usePostDataConstraint = true
    if (!dataProps.dataConstraintItems) { dataProps.dataConstraintItems = [] }
    dataProps.dataConstraintItems.push(
      { dataPropName: 'itemStatusType', propValue: 'inactive', queryType: gEnums.queryTypes.notEqualTo, fromGlobal: true }
    )
    _useConstraints = hideInactiveItems_do ? true : false
  }

  if (ignoreConstraintsAppUserList && ignoreConstraintsAppUserList.includes(appUserSessionId)) { _useConstraints = false }
  if (ignoreConstraintsList && ignoreConstraintsList.includes(appUserEmail)) { _useConstraints = false }

  if (_useConstraints && !dataOverrideOn) { getDataConstraints(dataProps, _where_data) }
  if (_useConstraints && !dataOverrideOn) { getDataMatchConstraints(dataMatchProps, currentPageData, _where_data) }
  if (_useConstraints && !dataOverrideOn) { getAppUserDataConstraints(appUserDataProps, _where_appUserData) }

  // LOOK
  if (fetchByAlphabet) {
    const { trueUivi } = acProps ?? {}
    let storageItem = 'alpha-item-'
    storageItem += viewKey ? trueUivi + '-' + viewKey : trueUivi
    const _alphaItem = currentHelpers.storageItem_get(storageItem)
    if (!alphabetValue) { alphabetValue = _alphaItem ? _alphaItem : 65 }
    const se = getStartEnd(alphabetValue)
    _where_page.push(where('lastName', ">=", se.start))
    _where_page.push(where('lastName', "<", se.end))
  }

  if (useAppUserDataKeysConstraint) {
    const { clientAccessKey, events: events_clients } = appUserAccess ?? {}
    if (pathViews.clients === clientAccessKey && events_clients) {
      const aucks = Object.keys(events_clients)
      whereOpts.dataKeys = aucks
    }
  }

  if (searchingValue) {
    showRefLog && console.log('searchingValue', sortProp, searchingValue)
    _where_page.push(where(sortProp, "==", searchingValue))
  }

  ammendWhereOpts(whereOpts, _where_page, usePostPageConstraint, _useStartDataCollections)
  ammendWhereOpts(whereOpts, _where_appUserPage, usePostAppUserPageConstraint, _useStartDataCollections)
  ammendWhereOpts(whereOpts, _where_appUserData, usePostAppUserDataConstraint, _useStartDataCollections)
  ammendWhereOpts(whereOpts, _where_data, usePostDataConstraint || _usePostDataConstraint, _useStartDataCollections)

  return {
    whereOpts,
    orders
  }
}

const ammendWhereOpts = (whereOpts, wheres, usePost, _useStartDataCollections) => {
  if (usePost || _useStartDataCollections) {
    wheres.forEach(w => {
      whereOpts.posts.push(w)
    })
  } else {
    wheres.forEach(w => {
      whereOpts.priors.push(w)
    })
  }
}

/**
 * adds a `where` for each of the `dataParents` if `useSubDataCollection` and 'subDataCollectionName' are true
 * @param {boolean} useSubDataCollection 
 * @param {string} subDataCollectionName 
 * @param {object} dataParents 
 * @param {object} pathViews 
 * @param {array} wheres 
 */
const getDataParentKeysConstraints = (useSubDataCollection, subDataCollectionName, dataParents, pathViews, wheres) => {
  if (useSubDataCollection && subDataCollectionName && dataParents) {
    dataParents.forEach(dp => {
      switch (dp) {
        default:
          if (pathViews[dp]) {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
          }
      }
    })
  }
}

/**
 * adds a `where` based on the `pageConstraintType`
 * @param {object} acProps 
 * @param {array} wheres 
 * @param {boolean} showRefLog 
 */
const getPageConstraints = (pageProps, acProps, wheres) => {

  const { usePageConstraint, pageConstraintType, pageConstraintTypeProp } = pageProps
  const { pathViews, viewKey, nonLandingView, dataSource } = acProps
  const { dataParents, ignorePathViewNulls } = dataSource ?? {}

  if (!usePageConstraint) { return null }

  const whereProps = {
    constraintType: pageConstraintType,
    constraintTypeProp: pageConstraintTypeProp,
    ignorePathViewNulls,
    dataParents,
    nonLandingView,
    pathViews,
    whereValue: viewKey
  }

  ammendWhereItem(wheres, whereProps)

}


/**
 * 
 * @param {object} appUserPageProps (appUserPageConstraintType, appUserPageConstraintTypeProp)
 * @param {object} acProps (currentPageData, pathViews, nonLandingView)
 * @param {array} wheres 
 */
const getAppUserPageConstraints = (appUserPageProps, acProps, wheres) => {

  const { appUserPageConstraintType, appUserPageConstraintTypeProp } = appUserPageProps
  const { currentPageData, pathViews, nonLandingView } = acProps

  if (currentPageData && appUserPageConstraintTypeProp) {
    if (appUserPageConstraintTypeProp && currentPageData[appUserPageConstraintTypeProp]) {
      const appUserDataValue = currentPageData[appUserPageConstraintTypeProp]

      const whereProps = {
        constraintType: appUserPageConstraintType,
        constraintTypeProp: appUserPageConstraintTypeProp,
        nonLandingView,
        pathViews,
        whereValue: appUserDataValue
      }

      ammendWhereItem(wheres, whereProps)

    }
  }
}

/**
 * adds a `where` for each of the `dataConstraintItems` if `useDataConstraints` is true
 * @param {object} dataProps (useDataConstraints, dataConstraintItems)
 * @param {array} wheres 
 */
const getDataConstraints = (dataProps, wheres) => {

  const { useDataConstraints, dataConstraintItems } = dataProps

  if (useDataConstraints) {
    if (dataConstraintItems) {
      dataConstraintItems.forEach(ci => {
        const { dataPropName, queryType, propValue, propValues, valueIsString, propStringValue } = ci
        const qt = getConstraintType(queryType)
        if (propValues) {
          wheres.push(where(dataPropName, qt, propValues))
        } else if (propValue && !valueIsString) {
          if (_.isBoolean(propValue)) {
            if (propValue.toLowerCase() === 'true') {
              wheres.push(where(dataPropName, qt, true))
            } else if (propValue.toLowerCase === 'false') {
              wheres.push(where(dataPropName, qt, false))
            }
          } else {
            wheres.push(where(dataPropName, qt, propValue))
          }
        } else if (propStringValue && valueIsString) {
          wheres.push(where(dataPropName, qt, propStringValue))
        }
      })
    }
  }
}

const getDataMatchConstraints = (dataMatchProps, currentPageData, wheres) => {

  const { useDataMatchConstraints, dataMatchConstraintItems } = dataMatchProps

  if (useDataMatchConstraints) {
    if (dataMatchConstraintItems) {
      dataMatchConstraintItems.forEach(ci => {
        const { dataPropName, queryType, matchingDataPropName } = ci
        const qt = getConstraintType(queryType)
        const matchingValue = currentPageData[dataPropName]
        wheres.push(where(matchingDataPropName, qt, matchingValue))
      })
    }
  }
}

/**
 * 
 * @param {object} appUserDataProps (useAppUserDataConstraints, appUserDataConstraintItems)
 * @param {array} wheres 
 */
const getAppUserDataConstraints = (appUserDataProps, wheres) => {

  const { useAppUserDataConstraints, appUserDataConstraintItems } = appUserDataProps

  if (useAppUserDataConstraints && appUserDataConstraintItems) {
    appUserDataConstraintItems.forEach(ci => {
      const { dataPropName, queryType, propValue, propValues } = ci
      const qt = getConstraintType(queryType)
      if (propValues) {
        wheres.push(where(dataPropName, qt, propValues))
      } else if (propValue) {
        wheres.push(where(dataPropName, qt, propValue))
      }
    })
  }
}

const getMaskConstraints = (appUserAccess, maskActive, maskProp, wheres) => {
  if (maskActive && maskProp) {
    const { appUserSession } = appUserAccess ?? {}
    const maskValue = appUserSession ? appUserSession[maskProp] : null
    if (maskValue) {
      wheres.push(where(maskProp, 'array-contains-any', maskValue))
    }
  }
}

const getFilterConstraints = (filterActive, filterName, _dataFilters, wheres) => {
  if (filterActive) {
    if (filterName && _dataFilters && _dataFilters[filterName]) {
      const pifl = _dataFilters[filterName]
      const { filters } = pifl ?? {}
      if (filters) {
        filters.forEach(f => {
          const { prop: filter_prop, value: filter_value } = f
          wheres.push(where(filter_prop, "==", filter_value))
        })
      }
    } else {
      wheres.push(where('status', "==", 'active'))
    }
  }
}

const getImageConstraints = (trueUivi, nonLandingView, viewKey, wheres) => {
  switch (trueUivi) {
    case 'imageLinks':
      wheres.push(where('parentKeys.view', '==', nonLandingView))
      wheres.push(where('parentKeys.viewKey', '==', viewKey))
      break;
    default:
      break;
  }
}

const ammendWhereItem = (wheres, whereProps) => {

  const {
    constraintType,
    constraintTypeProp,
    ignorePathViewNulls,
    dataParents,
    nonLandingView,
    pathViews,
    whereValue,
  } = whereProps

  // console.log('whereProps', whereProps)

  switch (constraintType) {
    // direct doc match
    case gEnums.pageConstraintTypes.equalTo:
      if (nonLandingView && whereValue) {
        wheres.push(where(nonLandingView, '==', whereValue))
      }
      break;

    // array contains
    case gEnums.pageConstraintTypes.arrayContains:
      if (nonLandingView && whereValue) {
        if (_.isArray(whereValue)) {
          wheres.push(where(nonLandingView, 'array-contains-any', whereValue))
        } else {
          wheres.push(where(nonLandingView, 'array-contains', whereValue))
        }
      }
      break;

    // direct prop match
    case gEnums.pageConstraintTypes.propEqualTo:
      if (constraintTypeProp && whereValue) {
        wheres.push(where(constraintTypeProp, '==', whereValue))
      }
      break;

    case gEnums.pageConstraintTypes.arrayContainsProp:
      if (constraintTypeProp && whereValue) { // && !pageConstraintTypePropToAppUser) {
        wheres.push(where(constraintTypeProp, 'array-contains', whereValue))
      }
      break;

    case gEnums.pageConstraintTypes.dataParentKeys:
      // if we have dataParents, add some wheres 
      // dataParents are connected to the parentKey
      if (dataParents) {
        dataParents.forEach(dp => {
          switch (dp) {
            default:
              if (pathViews[dp]) {
                wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
              } else {
                if (!ignorePathViewNulls) {
                  wheres.push(where('parentKeys.' + dp, '==', 'unknown'))
                }
              }
          }
        })
      }
      break;

    default:
  }
}