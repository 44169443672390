import _ from 'lodash';
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { fs_db } from '../../appData/fsAppData';
import { getBaseRef } from '../../helpers/getBaseRef';
import { addPromise } from '../../promises/addPromise';
import { pushPromise } from '../../promises/pushPromise';

/** Copies data from `copyFrom` to `copyTo` to firestore */
export const copyFirestore = (dispatch, paps_state, ufProps, page_fns) => {

  const { getGviDeps } = page_fns ?? {}
  const { copyFrom, copyTo } = ufProps ?? {}

  const { pathViews } = paps_state
  const viFromDeps = getGviDeps(copyFrom)
  const baseFromRef = getBaseRef(viFromDeps, pathViews)
  const { refPath: refPath_from } = baseFromRef

  const viToDeps = getGviDeps(copyTo)
  const baseToRef = getBaseRef(viToDeps, pathViews)
  const { refPath: refPath_to, directCollection: directToCollection } = baseToRef

  fs_db.get_data({ refPath: refPath_from }).then(items => {
    if (!_.isEmpty(items)) {
      addPromise(refPath_to, copyTo, directToCollection, items).then(() => {
        dispatch({ type: dispatchProps.success })
      }).catch(error => {
        dispatch({ type: dispatchProps.error, error })
      })
    }
  })
}

// LOOK
export const pushFirestore = (dispatch, paps_state, ufProps, page_fns) => {

  const { getGviDeps } = page_fns ?? {}
  const { pushFrom, pushTo } = ufProps ?? {}

  const { pathViews } = paps_state
  const viFromDeps = getGviDeps(pushFrom)
  const baseFromRef = getBaseRef(viFromDeps, pathViews)
  const { refPath: refPath_from } = baseFromRef

  const viToDeps = getGviDeps(pushTo)
  const baseToRef = getBaseRef(viToDeps, pathViews)
  const { refPath: refPath_to, directCollection: directToCollection } = baseToRef

  fs_db.get_data({ refPath: refPath_from }).then(items => {
    if (!_.isEmpty(items)) {
      pushPromise(refPath_to, pushFrom, pushTo, directToCollection, items).then(() => {
        dispatch({ type: dispatchProps.success })
      }).catch(error => {
        dispatch({ type: dispatchProps.error, error })
      })
    }
  })
} 