import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { copyObj } from '../../common_web/copy';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { gEnums } from '../../enums/globalEnums';
import { getRefPropz } from '../../firestoreData/helpers/getRefProps';
import { updateLinkHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_dataLInks';
import { fsfn_create } from '../../functions/fbCreate';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import { dataHandlers, dataInitialState, dataReducer } from '../reducers/DataReducer';
import { googleModeTypes } from '../reducers/GoogleSheetsReducer';
import { AppComponentContext } from './AppComponentContext';
import { FirestoreContext } from './FirestoreContext';
import { FrameworkContext } from './FrameworkContent';
import { GoogleSheetsContext } from './GoogleSheetsContext';
import { PageDataContext } from './PageDataContext';
import { ParentContext } from './ParentContext';
import { ViewSettingsContext } from './ViewSettingsContext';

/** gets the data for the uiItem.
 @returns viewListData,  
 @returns confirmation, 
 @returns globalFiles 
*/
export const DataContext = createContext();

const DataProvider = (props) => {

  const {
    diKey,
    fieldProps,
    forAppViewer,
    isAppData,
    isSettingData,
    itemListData,
    uivi,
    updateProps,
  } = props

  let { itemProp } = fieldProps ?? {}
  const { storageType } = itemProp ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings } = parentContext ?? {}

  const {
    appData_state,
    appSettings_state,
    appUser_state,
    eventInfo_state,
    page_state,
    paps_state,
    preview_state,
    serviceWorker_state,
    storage_state,
    transition_state,
  } = states

  const { appUser_handlers, appData_handlers } = handlers
  const { page_fns, database_fns } = fns ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_views, aps_page } = pageSettings ?? {}
  const { dataOverrideOn, dataRefreshOn, tempDataSource } = appSettings_state ?? {}
  const { previews } = preview_state ?? {}
  const { dataConstraints: dataConstraints_preview } = previews ?? {}

  // papsContext  
  const { cacheDataName, pathViews, otherView, view, viewKey, eventPageKey, showStatus } = paps_state ?? {}

  // serviceWorkerContext 
  const { cacheApp } = serviceWorker_state ?? {}
  const { cachedItems } = cacheApp ?? {}
  const { cache_data } = cachedItems ?? {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)
  const { firestore_fns } = firestoreContext ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdmin } = appUserAccess ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext)
  const { framework_state, framework_handlers } = frameworkContext
  const { tempSettings } = framework_state ?? {}
  const _tempSettings_vi = tempSettings && tempSettings[uivi]
  const { dataConstraints: dataConstraints_temp } = _tempSettings_vi ?? {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.data] : null
  const { showTransition } = transition ?? {}

  // storageContext 
  const { pageItemImages } = storage_state ?? {}
  const pageImages = pageItemImages && pageItemImages[view] ? pageItemImages[view] : {}

  // viewSettingsSwipeContext - THIS has all the info
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state } = viewSettingsContext ?? {}
  const { settingsStatus } = viewSettings_state ?? {}

  // pageContext  
  const { dataOptions, appDataSource, display } = aps_global ?? {}
  const { fetchExistingData, useStartDataCollections, getAllDataCollections, appDataSourceType, useSingleCollectionDocuments, autoUpdateAppDataLinks, autoUpdateAppDataDocuments, autoUpdateStaticViews } = appDataSource ?? {}
  const { onDemand, hideInactiveItems: hideInactiveItems_do } = dataOptions ?? {}
  let { timeZone } = display ?? {}
  const { menu, viewItems: viewItems_page } = aps_page ?? {}
  const _useStartDataCollections = useStartDataCollections && getAllDataCollections

  // pageDataContext
  const pageDataContext = useContext(PageDataContext);
  const { pageData_state, pageData_handlers } = pageDataContext ?? {}
  const { currentPageData } = pageData_state ?? {}

  // appContext
  const appContext = useContext(AppComponentContext)
  const { appComponent: appComponent_context } = appContext ?? {}

  // eventInfoContext 
  const { globalAppData } = appData_state ?? {}

  // eventInfoContext 
  const { staticViews, eventCollectionsData, appDataLinks, appDataDocuments, eventCache_info } = eventInfo_state ?? {}

  // googleSheetsContext 
  const { googleModeType, googleSheetsData, staticViews: staticViews_gs, googlePreviewType } = googleSheets_state ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ?? {}
  const { viewItem_preview } = dataManagement_state ?? {}

  const _appDataSourceType = tempDataSource ? tempDataSource : appDataSourceType

  // test for other settings
  let isCombinedData = false
  let cbnVis = {}
  let returnFsr;

  let viewItem_page = viewItems_page ? viewItems_page[uivi] : {}

  if (viewItem_preview) {
    viewItem_page = viewItem_preview
    returnFsr = true
  }

  const { combinedGroups, dataConstraints, dataSource, grouping, } = viewItem_page ?? {}

  const { dataSourceType: dataSourceType_page, pageDataCollection, appComponent, appComponentStateCollection, dataCollectionName, dataCollectionNames } = dataSource ?? {}
  const { usePostDataConstraint, usePostAppUserPageConstraint, usePostAppUserDataConstraint, usePostPageConstraint } = dataConstraints ?? {}
  const _usePostConstraint = usePostDataConstraint || usePostAppUserPageConstraint || usePostAppUserDataConstraint || usePostPageConstraint

  const { fetchByAlphabet } = grouping ?? {}

  if (combinedGroups) {
    cbnVis[viewItem_page.key] = viewItem_page
    Object.keys(combinedGroups).forEach(key => {
      cbnVis[key] = combinedGroups[key]
    })
    isCombinedData = true
  }

  let viewItem_g;
  if (aps_viewItems && aps_viewItems[uivi]) { viewItem_g = aps_viewItems[uivi] }
  const { dataConnection } = viewItem_g ?? {}
  const { dataSourceType: dataSourceType_global, documentDependencies, altCollectionName } = dataConnection ?? {}

  const getDataSourceType = () => {
    // the the page does not have a dataSourceType, use the global
    let _dst = dataSourceType_page ? dataSourceType_page : dataSourceType_global

    if (!_dst) { _dst = _appDataSourceType }

    switch (_appDataSourceType) {
      case gEnums.dataSourceTypes.firebaseDocument:
        if (useSingleCollectionDocuments) {
          _dst = _appDataSourceType
        }
        break;
      default:
      // nothing
    }

    if (googleModeType === googleModeTypes.app || googleSheetsData) {
      _dst = gEnums.dataSourceTypes.googleSpreadSheet
    }

    if (googleModeType === googleModeTypes.sheets || googleSheetsData) {
      _dst = gEnums.dataSourceTypes.googleSpreadSheet
    }

    return _dst
  }

  const _dataSourceType = getDataSourceType()

  let dataCollection = uivi
  let trueViewKey = viewKey

  if (altCollectionName) {
    dataCollection = altCollectionName
    trueViewKey = null
  }

  let delayData = onDemand ? true : false

  // IMPORTANT: Data - Get the data for the viewItem
  const dataInit_state = {
    database_fns,
    diKey,
    firestore_fns,
    isCombinedData,
    isSettingData,
    itemListData,
    pageImages,
    uivi,
    updateProps,
    viewItem: viewItem_page,
  }

  const [data_state, data_dispatch] = useReducer(dataReducer, dataInitialState(dataInit_state));
  const data_handlers = dataHandlers(data_dispatch, data_state)

  const { dataResults, alphaValue, alphabetValue, fetchData } = data_state ?? {}

  if (onDemand && alphaValue) { delayData = false }

  let viewListData = (data_state && dataCollection && data_state[dataCollection]) ? data_state[dataCollection] : null

  if (showStatus && viewListData) { console.log('data_state ready', dataCollection, data_state) }

  /**
   * 
   * @returns dProps and menu
   */
  const getGetProps = () => {

    const { dataOverrideOn } = appSettings_state ?? {}
    const { nonLandingView, pathViews, pathName } = paps_state ?? {}
    const { dataOptions, googleSheets } = aps_global ?? {}
    const { dataLimit, limitDevelopmentData, hideInactiveItems: hideInactiveItems_do } = dataOptions ?? {}
    const { googleSheetsId } = googleSheets ?? {}

    const dProps = {
      stateProps: {
        appUserAccess,
        aps_viewItems,
        dataLimit,
        dataOverrideOn,
        googleSheetsId,
        hideInactiveItems_do,
        limitDevelopmentData,
        logging,
        nonLandingView,
        page_fns,
        pathViews,
        staticViews,
        timeZone,
        paps_state,
        _useStartDataCollections,
      },
      actionProps: {
        alphabetValue,
        alphaItem: alphaValue,
        currentPageData,
        dataCollectionName,
        dataCollectionNames,
        dataConstraints_preview,
        dataConstraints_temp,
        isAppData,
        pathName,
        singleDirect: false,
        storageType,
        trueViewKey,
        uivi,
        uivi: dataCollection,
        useSingleCollectionDocuments,
        view,
        viewItem_g,
        viewItem: viewItem_page,
        viewKey,
        appDataSource,
      }
    }
    return getRefPropz(dProps, menu)
  }

  const { dProps, dataCaptionProps } = getGetProps()

  const { refProps } = dProps ?? {}

  const _dataResultProps = { name: dataCollection, singleDataItem: false }
  const eventItemData = _useStartDataCollections && eventCollectionsData && eventCollectionsData[uivi]

  const getDataProps = () => {
    const _getProps = {
      _appDataSourceType,
      _dataResultProps,
      _dataSourceType,
      _usePostConstraint,
      alphabetValue,
      appComponent_name: appComponent_context,
      appComponent,
      appComponentStateCollection,
      appContext,
      appDataDocuments,
      appDataLinks,
      autoUpdateStaticViews,
      currentPageData,
      dataCaptionProps,
      dataCollection,
      dataCollectionName,
      dataCollectionNames,
      dataOverrideOn,
      delayData,
      documentDependencies,
      dProps,
      eventCollectionsData,
      eventItemData,
      fetchByAlphabet,
      fetchData,
      fetchExistingData,
      globalAppData,
      googleModeType,
      googleSheetsData,
      hideInactiveItems_do,
      isAppData,
      isSuperAdmin,
      otherView,
      pageData_state,
      pageDataCollection,
      pathViews,
      refProps,
      returnFsr,
      staticViews_gs,
      staticViews,
      trueViewKey,
      uivi,
      useSingleCollectionDocuments,
      view,
      viewItem_page,
      viewItem_g,
      viewKey,
      appDataSource,
      eventCache_info,
      cache_data,
    }
    return _getProps
  }

  // get the data
  useEffect(() => {
    data_handlers.handleGet_data(getDataProps())
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    [
      _appDataSourceType,
      appDataDocuments,
      alphabetValue,
      appUserAccess,
      dataConstraints_temp,
      dataConstraints_preview,
      dataOverrideOn,
      dataRefreshOn,
      googlePreviewType,
      eventPageKey,
      settingsStatus,
      viewItem_page,
      uivi,
    ])

  useEffect(
    () => {
      if (dataResults) {

        const { _postData, dataValue, dataResultProps, missingSvData, excludedData, refProps, dataCollection, existingDatas } = dataResults

        framework_handlers.handleAmmend_dataOrigin(existingDatas)

        if (!viewKey) {
          const { cache_eventTimeStamps } = eventCache_info ?? {}
          const cache_eventDataTimeStamp = cache_eventTimeStamps && cache_eventTimeStamps[uivi]
          const { cacheUpdateNeeded } = cache_eventDataTimeStamp ?? {}

          if (cacheUpdateNeeded || !existingDatas) {
            console.log('cacheUpdateNeeded', cache_eventDataTimeStamp)
            // sending the data to cache storage
            // serviceWorker_handlers.handleSend_messageToServiceWorker({
            //   cacheName: cacheDataName,
            //   cacheType: dataCollection,
            //   cacheData: dataValue,
            // }, _cacheTypes.data, pathViews, uivi);
          }
        }

        data_handlers.handleLoad_data(_postData, dataResultProps, refProps, excludedData, missingSvData)

        const { name: _dataCollectionName } = dataResultProps ?? {}

        const _dataConstraints = dataConstraints_preview ? dataConstraints_preview : dataConstraints
        const { includeInAppUserData } = _dataConstraints ?? {}
        const { refKey } = refProps ?? {}

        if (refKey) {
          // dataCollection, refKey, data, missingSvData 
          appData_handlers.handleSet_appData(pathViews, dataCollection, view, viewKey, eventPageKey, refKey, dataValue, missingSvData)
        }

        // add this dataValue to the appUser page data
        if (includeInAppUserData && appUser_handlers && appUser_handlers.handleAppUserPageData) {
          appUser_handlers.handleAppUserPageData(_dataCollectionName, dataValue)
        }

        if (pageData_handlers) {
          pageData_handlers.handleItemDataCounts(uivi, _dataCollectionName, _postData ? Object.keys(_postData).length : 0)
        }

        if (viewItem_page && view === viewItem_page.key && !viewKey) {
          if (dataValue && _.size(dataValue) > 0 && (autoUpdateStaticViews || autoUpdateAppDataDocuments || autoUpdateAppDataLinks)) {
            if (autoUpdateStaticViews) {
              const { key, dataConnection } = viewItem_g ?? {}
              const staticView = staticViews ? copyObj(staticViews[key]) : {}
              const { uniqueProps } = dataConnection ?? {}
              data_handlers.handleUpdate_staticView({ pathViews, staticView, viewListData: dataValue, uniqueProps, viewItem: viewItem_page })
            }

            if (autoUpdateAppDataDocuments) {
              updateLinkHelpers.update_appLinkedData(pathViews, viewItem_page.key, dataValue)
            }

            if (autoUpdateAppDataLinks) {
              // getAppLinkedData(pathViews, viewItem_page.key, viewListData, staticViews, true)
            }
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [dataResults]
  )

  useEffect(
    () => {
      if (viewListData && viewItem_g && viewItem_g.props) {
        const prs = { ...viewItem_g.props }
        const prks = Object.keys(prs)
        const newProps = getNewPropsFromData(viewListData, prks)
        if (newProps && Object.keys(newProps).length > 0) {
          fsfn_create.createViewItemProps(newProps, prs, 'details').then(() => {
            viewItem_g.props = prs
            getNewPropsToData(dataCollection, viewItem_g, aps_views)
          })
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [viewListData]
  )

  const viewDataProviderContext = () => <DataContext.Provider value={{ data_state, data_handlers }}>
    {props.children}
  </DataContext.Provider>

  const itemDataProviderContext = () => <DataContext.Provider value={{ data_state, data_handlers }}>
    {props.children}
  </DataContext.Provider>

  const content = () => {
    if (viewItem_page && viewListData) {
      return viewDataProviderContext()
    } else if (itemListData) {
      return itemDataProviderContext()
    } else {
      if (forAppViewer && viewListData) {
        return viewDataProviderContext()
      } else {
        return <div></div>
      }
    }
  }

  if (showTransition) {
    return <MotionComponent transition={transition}>
      {content()}
    </MotionComponent>
  } else {
    return content()
  }
}

export default DataProvider


const getNewPropsFromData = (viewListData, propKeys) => {
  const newPropKeys = {}
  if (viewListData) {
    Object.keys(viewListData).forEach(vldKey => {
      const vks = Object.keys(viewListData[vldKey])
      vks.forEach(vk => {
        if (!propKeys.includes(vk) && !newPropKeys[vk]) {
          switch (vk) {
            case 'parentKeys':
            case 'id':
            case 'uid':
            case '_gs':
              // ignore
              break;
            default:
              newPropKeys[vk] = {}
          }
        }
      })
    })
  }
  return newPropKeys
}

const getNewPropsToData = (uivi, viewItem_g, aps_views) => {
  const globalViPropKeys = Object.keys(viewItem_g.props)
  // loop the views
  Object.keys(aps_views).forEach(viewKey => {
    const view = aps_views[viewKey]
    if (view && view.viewItems) {
      Object.keys(view.viewItems).forEach(vk => {
        if (vk === uivi) {
          if (view.viewItems[vk].props) {
            const viewPropKeys = Object.keys(view.viewItems[vk].props)
            globalViPropKeys.forEach(gpk => {
              if (!viewPropKeys.includes(gpk)) {
                // add prop
                switch (vk) {
                  case 'parentKeys':
                  case 'id':
                  case 'uid':
                  case '_gs':
                    // ignore
                    break;
                  default:
                    view.viewItems[vk].props[gpk] = viewItem_g.props[gpk]
                }
              }
            })
          }
        }
      })
    }
  })
} 