import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { convertHelpers } from '../../common/convert';
import { gEnums } from '../../enums/globalEnums';
import { getAllAppUserUrls } from '../../storage/storageHelpers';
import { eventInfoHandlers, eventInfoInitialState, eventInfoReducer } from '../reducers/EventInfoReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUsersContext } from './AppUsersContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { ServiceWorkerContext } from './ServiceWorkerContext';
import { ParentContext } from './ParentContext';
import { DatabaseContext } from './DatabaseContext';
import { _cacheTypes } from '../reducers/ServiceWorkerReducer';

/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const EventInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const EventInfoProvider = (props) => {

  // ParentContext not available

  const aat = gEnums.appAreaTypes
  const svc = [aat.clients, aat.clubs, aat.districts, aat.event, aat.leagues, aat.schools, aat.organizations, aat.client]

  // databaseContext
  const databaseContext = useContext(DatabaseContext)
  const { database_fns } = databaseContext ?? {}

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state, serviceWorker_handlers } = serviceWorkerContext ?? {}
  const { cacheApp } = serviceWorker_state ?? {}
  const { cachedItems } = cacheApp ?? {}
  const { cache_data } = cachedItems ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state } = appSettingsContext ?? {}
  const { timeStamps } = appSettings_state ?? {}

  // appUsersContext
  const appUsersContext = useContext(AppUsersContext);
  const { appUsers_handlers } = appUsersContext
  const { handleSet_eventAppUsers } = appUsers_handlers ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { appArea, pathViews, cacheDataName } = paps_state ?? {}

  const isOrganization = pathViews.organizations ? true : false

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings, aps_viewItems } = pageSettings ?? {}
  const { dataOverview, appDataSource, topMenu } = aps_global ?? {}
  const { appUserCollection, appUserCollections, appUserViewTypeProp, allowPresenceTracking } = aps_appUserSettings ?? {}
  const { useStartDataCollections, getAllDataCollections, startDataCollections } = dataOverview ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  const _appUserCollections = appUserCollections ? appUserCollections : []
  if (_appUserCollections.length === 0 && appUserCollection) { _appUserCollections.push(appUserCollection) }

  // eventInfoInitialState
  const initState = eventInfoInitialState({ pathViews, database_fns })
  const [eventInfo_state, dispatch] = useReducer(eventInfoReducer, initState);
  const eventInfo_handlers = eventInfoHandlers(dispatch)

  const { staticViews, staticEventKey, appDataDocuments, appGlobalData } = eventInfo_state ?? {}
  const { attendees } = staticViews ?? {}

  const getStaticName = (staticView, staticValue, returnFound) => {
    const _staticView = staticView === 'guests' ? 'attendees' : staticView
    if (staticViews && staticViews[_staticView] && staticViews[_staticView][staticValue]) {
      const svv = staticViews[_staticView][staticValue]
      if (returnFound) {
        return { sv: convertHelpers.getItemName(svv) }
      } else {
        return convertHelpers.getItemName(svv)
      }
    } else {
      if (returnFound) {
        return { sv: staticValue, notFound: true }
      } else {
        return staticValue
      }
    }
  }

  useEffect(
    () => {

      const eventCache_info = {
        cache_eventData: cache_data ? cache_data[pathViews.events] : null,
        cache_eventTimeStamps: {}
      }

      const { appGlobalData } = eventCache_info.cache_eventData ?? {}

      if (eventCache_info.cache_eventData) {

        const { _timeStamps } = eventCache_info.cache_eventData

        _.forEach(_.keys(eventCache_info.cache_eventData), (key) => {
          switch (key) {
            case '_timeStamps':
              break;
            default:
              const timeStamp = timeStamps && timeStamps[key]
              const _timeStamp = _timeStamps && _timeStamps[key]
              eventCache_info.cache_eventTimeStamps[key] = {
                cacheUpdateNeeded: timeStamp !== _timeStamp,
                ts_cache: _timeStamp,
                ts_data: timeStamp,
              }
          }
        });
      }

      eventInfo_handlers.handleInit_event({ appUserCollections, aps_viewItems, pathViews, appDataSource, mainFixedCaption, eventCache_info })

      if (svc.includes(appArea)) {
        if (staticEventKey !== pathViews.events && !isOrganization) {
          eventInfo_handlers.handleGet_eventStaticViews(pathViews)
        }
      }

      if (useStartDataCollections && (startDataCollections || getAllDataCollections)) {
        const _collectionItems = aps_viewItems ? Object.keys(aps_viewItems) : []
        eventInfo_handlers.handleGet_startDataCollections(startDataCollections, getAllDataCollections, _collectionItems, pathViews)
      }

      if (allowPresenceTracking) {
        eventInfo_handlers.handleGet_appUserPresence(pathViews)
      }

      eventInfo_handlers.handleGet_appGlobalData(pathViews, appGlobalData)

    },
    // eslint-disable-next-line
    [pathViews.events, appDataSource, timeStamps]
  )

  useEffect(
    () => {
      if (appGlobalData) {
        serviceWorker_handlers.handleSend_messageToServiceWorker({
          cacheName: cacheDataName,
          cacheType: 'appGlobalData',
          cacheData: appGlobalData,
        }, _cacheTypes.globalData);
      }
    },
    // eslint-disable-next-line
    [appGlobalData]
  )

  useEffect(
    () => {
      if (attendees && (appUserViewTypeProp === gEnums.appUserProfileImageTypes.profile)) {
        getAllAppUserUrls(attendees, eventInfo_handlers.handleSet_appUserUrls)
      }
    },
    // eslint-disable-next-line
    [attendees]
  )

  useEffect(
    () => {
      if (appDataDocuments && appUserCollection && appDataDocuments[appUserCollection]) {
        handleSet_eventAppUsers(appDataDocuments[appUserCollection])
      }
    },
    // eslint-disable-next-line
    [appDataDocuments]
  )

  const eventInfo_fns = {
    getStaticName: (staticView, staticValue, returnFound) => getStaticName(staticView, staticValue, returnFound)
  }

  if (svc.includes(appArea)) {
    if (staticEventKey) {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    } else {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    }
  } else {
    return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
      {props.children}
    </EventInfoContext.Provider>
  }
}

export default EventInfoProvider