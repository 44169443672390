import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { firestoreFunctions, firestoreHandlers, firestoreInitialState, firestoreReducer } from '../../cnr/reducers/FirestoreReducer';
import { ParentContext } from './ParentContext';

export const FirestoreContext = createContext();

const FirestoreProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appSettings_state, appUser_state, page_state, paps_state, eventInfo_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { dataRestrictions, appDataSource } = aps_global ?? {}
  const { page_fns, database_fns } = fns

  // authContext 
  const { appUser } = appUser_state ?? {}

  // eventInfoContext 
  const { staticViews } = eventInfo_state ?? {}

  // paps_state, staticViews, page_fns, authAppUser, appSettings_state, dataRestrictions, appDataSource
  const init_state = firestoreInitialState({ paps_state, staticViews, page_fns, appUser, appSettings_state, dataRestrictions, appDataSource, database_fns })
  const [firestore_state, dispatch] = useReducer(firestoreReducer, init_state);
  const firestore_handlers = firestoreHandlers(dispatch)
  const firestore_fns = firestoreFunctions(firestore_handlers)
  const { fsCalls } = firestore_state ?? {}

  useEffect(() => {
    // console.log('fsCalls', fsCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsCalls]);

  return (
    <FirestoreContext.Provider value={{ firestore_handlers, firestore_state, firestore_fns }}>
      {props.children}
    </FirestoreContext.Provider>
  )
}

export default FirestoreProvider