import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { analyticsHandlers, analyticsInitialState, analyticsReducer } from '../reducers/AnalyticsReducer';
import { ParentContext } from './ParentContext';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getAnalytics } from 'firebase/analytics';
// import ReactGA from 'react-ga';

export const AnalyticsContext = createContext();

/**
 * Simple wrapper to handle app settings changes
 */
const AnalyticsProvider = (props) => {

  const analytics = getAnalytics();

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { googleAnalytics } = aps_global ?? {}
  const { allowGoogleAnalytics } = googleAnalytics ?? {}

  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdmin } = appUserAccess ?? {}

  const init_state = { allowGoogleAnalytics, isSuperAdmin }
  const [analytics_state, analytics_dispatch] = useReducer(analyticsReducer, analyticsInitialState(init_state))
  const analytics_handlers = analyticsHandlers(analytics_dispatch)

  useEffect(() => {
    analytics_handlers.handleInit_analytics(analytics, allowGoogleAnalytics, isSuperAdmin)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [analytics, allowGoogleAnalytics, isSuperAdmin]);

  return <AnalyticsContext.Provider value={{ analytics_state, analytics_handlers }}>
    {props.children}
  </AnalyticsContext.Provider>
};

export default AnalyticsProvider