
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { serviceWorkerHandlers, serviceWorkerInitialState, serviceWorkerReducer } from '../reducers/ServiceWorkerReducer';
import { PapsContext } from './PapsContext';

export const _appVersion = '2.9.1';

const _useSwCache = true

export const ServiceWorkerContext = createContext();

const ServiceWorkerProvider = (props) => {

  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { appArea, pathViews } = paps_state ?? {}
  const { events: eventKey, clients: clientKey } = pathViews ?? {}

  const init_state = { useSwCache: _useSwCache }
  const [serviceWorker_state, serviceWorker_dispatch] = useReducer(serviceWorkerReducer, serviceWorkerInitialState(init_state))
  const serviceWorker_handlers = serviceWorkerHandlers(serviceWorker_dispatch)
  const { cacheApp } = serviceWorker_state ?? {}
  const { cacheFetched } = cacheApp ?? {}

  const { serviceWorker } = navigator ?? {}

  useEffect(() => {
    if (serviceWorker) {
      serviceWorker_handlers.handleInit_serviceWorker(pathViews)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceWorker]);

  useEffect(() => {
    serviceWorker_handlers.handleGet_cache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appArea, clientKey, eventKey]);

  return cacheFetched ? <ServiceWorkerContext.Provider value={{ serviceWorker_state, serviceWorker_handlers, appVersion: _appVersion }}>
    {props.children}
  </ServiceWorkerContext.Provider> : <div></div>
};

export default ServiceWorkerProvider