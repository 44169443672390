import _ from 'lodash';
import { ammendGoogleResult, appendProjectData, appendProjectDataNames, updateViewItemViews } from '../firestoreData/helpers/sheetsDataAdjust';
import { callFunction } from "./fbAll";
import { copyObj } from '../common_web/copy';

const sheetsGenericTypes = {
  sheetsGeneric: 'sheetsGeneric',
}

const sheetsFunctionTypes = {
  addSheetRange: 'addSheetRange',
  addSheetRanges: 'addSheetRanges',
  addSheets: 'addSheets',
  addSheetsValidation: 'addSheetsValidation',
  addSheetValidation: 'addSheetValidation',
  addTab: 'addTab',
  createProjectFromSheets: 'createProjectFromSheets',
  createSpreadsheet: 'createSpreadsheet',
  getDocs: 'getDocs',
  getFullSheetRange: 'getFullSheetRange',
  getGoogleSheetsDoc: 'getGoogleSheetsDoc',
  getGoogleSheetsSettingsDoc: 'getGoogleSheetsSettingsDoc',
  getSheetRange: 'getSheetRange',
  getSheetsRange: 'getSheetsRange',
  updateSheetRangeValues: 'updateSheetRangeValues',
  updateSheetValues: 'updateSheetValues',
  updateSheetValue: 'updateSheetValue',
  updateSheetRow: 'updateSheetRow',
  updateSheetRowById: 'updateSheetRowById',
  createTeamSheets: 'createTeamSheets'
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(sheetsGenericTypes.sheetsGeneric, callData, true)
  return data
}

export const fsfn_sheets = {
  addGoogleSheetRange: (googleSheetsId, paps_state, viewItem) => fsfn_addGoogleSheetRange(googleSheetsId, paps_state, viewItem),
  addGoogleSheetRanges: (googleSheetsId, createValidation) => fsfn_addGoogleSheetRanges(googleSheetsId, createValidation),
  addGoogleSheets: (googleSheetsId, paps_state, viKeys) => fsfn_addGoogleSheets(googleSheetsId, paps_state, viKeys),
  addGoogleSheetValidation: (googleSheetsId, paps_state, viewItem) => fsfn_addGoogleSheetValidation(googleSheetsId, paps_state, viewItem),
  createGoogleSpreadsheet: (title) => fsfn_createGoogleSpreadsheet(title),
  createGoogleSpreadsheetTab: (googleSheetsId, name, fetchFirestoreData, values) => fsfn_createGoogleSpreadsheetTab(googleSheetsId, name, fetchFirestoreData, values),
  createProjectFromSheets: (sheetProps, appArea, googleSheetsImportOptions, logging, staticViews_app, forDataManagement, aps_viewItems) => fsfn_createProjectFromSheets(sheetProps, appArea, googleSheetsImportOptions, logging, staticViews_app, forDataManagement, aps_viewItems),
  createTeamSheets: (googleSheetsId, teams, showLogs) => fsfn_createTeamSheets(googleSheetsId, teams, showLogs),
  getGoogleSheetRange: (formData, handleSheetResults, handleSheetError, ignoreStartCase, team, latestSeason, forPlayoffs, forMedia) => fsfn_getGoogleSheetRange(formData, handleSheetResults, handleSheetError, ignoreStartCase, team, latestSeason, forPlayoffs, forMedia),
  getGoogleSheetsRange: (sheetProps, appArea) => fsfn_getGoogleSheetsRange(sheetProps, appArea),
  getSheetsFullRange: (googleSheetsId, sheetName) => fsfn_getSheetsFullRange(googleSheetsId, sheetName),
  googleDoc: (documentId) => fsfn_googleDoc(documentId),
  updateGoogleSheetRow: (match_edit, handleSheetResults, handleSheetError, team) => fsfn_updateGoogleSheetRow(match_edit, handleSheetResults, handleSheetError, team),
  updateGoogleSheetRowById: (googleSheetsId, viewItemKey, itemData, useGsid, handleSheetResults, handleSheetError) => fsfn_updateGoogleSheetRowById(googleSheetsId, viewItemKey, itemData, useGsid, handleSheetResults, handleSheetError),
  updateGoogleSheetValue: (googleSheetsId, range, value) => fsfn_updateGoogleSheetValue(googleSheetsId, range, value),
  updateGoogleSheetValues: (googleSheetsId, name, values) => fsfn_updateGoogleSheetValues(googleSheetsId, name, values),
  updateSheetRangeValues: (googleSheetsId, sheetName, cellValue, rowData) => fsfn_updateSheetRangeValues(googleSheetsId, sheetName, cellValue, rowData),
}

/**
 * 
 * @param {string} title  
 * @returns response_data {success, result, error}
 */
export const fsfn_createTeamSheets = async (googleSheetsId, teams, showLogs, callback) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.createTeamSheets, googleSheetsId, teams, showLogs }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData)// OK
    console.log('response_data', response_data)
    if (callback) {
      callback(response_data)
    } else {
      return response_data
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} title  
 * @returns response_data {success, result, error}
 */
export const fsfn_getSheetsFullRange = async (googleSheetsId, sheetName, callback) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.getFullSheetRange, googleSheetsId, sheetName, showLogs: true }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData)// OK
    console.log('response_data', response_data)
    if (callback) {
      callback(response_data)
    } else {
      return response_data
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets the data from a googleSheet using the firebase function `getSheets`
 * @param {object} sheetProps 
 * @param {string} appArea 
 * @param {object} googleSheetsImportOptions 
 * @param {object} _logging 
 * @returns data
 */
const fsfn_createProjectFromSheets = async (sheetProps, appArea, googleSheetsImportOptions, _logging, staticViews_app, forDataManagement, aps_viewItems) => {

  try {
    const { formData, pathViews } = sheetProps
    const { eventName, googleSheetsId } = formData
    const { allowMultiColumnArrays, allowMultiColumnArraysAsObjects } = googleSheetsImportOptions ?? {}

    const callData = {
      sheetFunctionType: sheetsFunctionTypes.createProjectFromSheets,
      eventName, googleSheetsId,
      createViews: true, // this will create the fullProject with the views
      appArea,
      fromRegUrl: true,
      keepRowIds: true,
      opts: {
        allowMultiColumnArrays,
        allowMultiColumnArraysAsObjects,
      },
      showLogs: true,
    }

    const response_data = await callFsFunction(callData) // OK 

    // each item contains a _gsid, which is the key of the data item

    const { success, result } = response_data
    const { result: trueResult } = result ?? {}
    const { projectSettings } = trueResult ?? {}

    getViewItemsSubs(projectSettings)
    console.log('trueResult', copyObj(trueResult))

    if (success && trueResult) {
      if (trueResult.projectData && !trueResult.projectData.clientData) { trueResult.projectData.clientData = { clientMobileKey: pathViews.clients } }
      if (trueResult.projectData && !trueResult.projectData.eventData) { trueResult.projectData.eventData = { name: eventName } }

      const _trueResult = await ammendGoogleResult(trueResult, googleSheetsImportOptions, staticViews_app, true, forDataManagement, aps_viewItems)

      appendProjectDataNames(_trueResult)

      const ret = {
        success,
        result: _trueResult,
      }
      return ret
    } else {
      return {
        success: false,
        result: {}
      }
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

/**
 * 
 * @param {string} documentId 
 * @returns response_data {success, result, error}
 * @deprecated
 */
const fsfn_googleDoc = async (documentId) => {
  try {
    const callData = { sheetsFunctionType: sheetsFunctionTypes.getDocs, documentId }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} title  
 * @returns response_data {success, result, error}
 */
const fsfn_createGoogleSpreadsheet = async (title) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.createSpreadsheet, title, showLogs: true }
    const response_data = await callFsFunction(callData)// OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * adds a sheet (if it doesn't already exist) to an existing spreedsheet and adds the data from the firebase storage
 * @param {*} googleSheetsId 
 * @param {*} paps_state 
 * @param {*} viewItem 
 * @returns response_data {success, result, error} 
 */
const fsfn_createGoogleSpreadsheetTab = async (googleSheetsId, name, fetchFirestoreData, values) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addTab, googleSheetsId, name, fetchFirestoreData, values }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** Updates a single row in a single range/sheet */
const fsfn_updateSheetRangeValues = async (googleSheetsId, sheetName, cellValue, rowData) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.updateSheetRangeValues, googleSheetsId, sheetName, cellValue, rowData, showLogs: true }
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} googleSheetsId 
 * @param {object} viewItem 
 * @returns 
 */
const fsfn_updateGoogleSheetValue = async (googleSheetsId, range, value) => {

  const callData = { sheetFunctionType: sheetsFunctionTypes.updateSheetValue, googleSheetsId, range, value }
  try {
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK 
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 
 * @param {string} googleSheetsId 
 * @param {object} viewItem 
 * @returns 
 */
const fsfn_updateGoogleSheetValues = async (googleSheetsId, name, values) => {

  const callData = { sheetFunctionType: sheetsFunctionTypes.updateSheetValues, googleSheetsId, name, values }
  try {
    const response_data = await callFsFunction(callData) // OK 
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Creates a named range on the 2nd column (name) for sheet (viewItem.key) within the spreadsheet
 * @param {string} googleSheetsId 
 * @param {object} paps_state 
 * @param {object} viewItem 
 * @returns response_data {success, result, error}
 */
const fsfn_addGoogleSheetRange = async (googleSheetsId, paps_state, viewItem) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheetRange, googleSheetsId, pathViews: paps_state.pathViews, viewItem }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Creates a named range on the 2nd column (name) for each of the sheets within the spreadsheet
 * @param {string} googleSheetsId 
 * @param {boolean} createValidation 
 * @returns response_data {success, result, error}
 */
const fsfn_addGoogleSheetRanges = async (googleSheetsId, createValidation) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheetRanges, googleSheetsId, createValidation }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * fsfn_addGoogleSheetValidation
 * @param {*} googleSheetsId 
 * @param {*} paps_state 
 * @param {*} viewItem 
 * @returns response_data {success, result, error}
 */
const fsfn_addGoogleSheetValidation = async (googleSheetsId, paps_state, viewItem) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheetsValidation, googleSheetsId, pathViews: paps_state.pathViews, viewItem }
    const response_data = await callFsFunction(callData) // OK
    const { data } = response_data
    const { success, result } = data
    if (success) {
      const { ssResult, ssViews } = result
      const { data: data_result } = ssResult
      const { namedRanges } = data_result
      ssViews.forEach(ssView => {
        const { data: sheetData } = ssView
        const { range, values } = sheetData
        const rangeSplit = range.split('!')
        const rangeName = rangeSplit[0]
        const headers = values ? values[0] : null // values are the rows
        if (headers && rangeName) {
          headers.forEach((header, _index) => {
            const headerRangeName = '_' + _.camelCase(header)
            const nr = _.find(namedRanges, { name: headerRangeName })
            if (nr) {
              console.log('nr', nr)
              // const { name, namedRangeId } = nr
            }
          })
        }
      })
    }
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** 
 * @param {string} googleSheetsId 
 * @param {object} paps_state 
 * @param {array} viKeys 
 * @returns response_data {success, result, error}
 */
const fsfn_addGoogleSheets = async (googleSheetsId, paps_state, viKeys) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheets, googleSheetsId, pathViews: paps_state.pathViews, viKeys }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} formData (sheetName, googleSheetsId)
 * @param {*} handleSheetResults 
 * @param {*} handleSheetError 
 */
const fsfn_getGoogleSheetRange = async (formData, handleSheetResults, handleSheetError, ignoreStartCase, team, latestSeason, forPlayoffs, forMedia) => {
  try {
    let sheetName = ignoreStartCase ? formData.sheetName : _.startCase(formData.sheetName)
    if (latestSeason) { sheetName += ' ' + latestSeason }
    if (forMedia) { sheetName += ' Media' }
    const callData = { sheetFunctionType: sheetsFunctionTypes.getSheetsRange, googleSheetsId: formData.googleSheetsId, sheetName, fromRegUrl: true }
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ?? {}
    console.log('result', result)
    const { error, errors } = result ?? {}
    if (handleSheetResults && success === true) { handleSheetResults(result, team, forPlayoffs) }
    if (handleSheetError && success === false) { handleSheetError(error) }
    if (handleSheetError && errors && success === false) { handleSheetError(errors[0]) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} sheetProps 
 * @param {string} appArea 
 * @returns ret
 * @deprecated
 */
const fsfn_getGoogleSheetsRange = async (sheetProps, appArea) => {
  try {
    const { formData, pathViews } = sheetProps
    const { eventName, googleSheetsId } = formData
    const callData = { sheetFunctionType: sheetsFunctionTypes.getSheetRange, eventName, googleSheetsId, createViews: true, appArea, fromRegUrl: true }
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ?? {}
    if (success) {
      const trueResult = result.result
      if (!trueResult.projectData.clientData) { trueResult.projectData.clientData = { clientMobileKey: pathViews.clients } }
      if (!trueResult.projectData.eventData) { trueResult.projectData.eventData = { name: eventName } }

      updateViewItemViews(trueResult)
      appendProjectData(trueResult)

      const ret = {
        data: {
          success,
          result: trueResult
        }
      }
      return ret
    } else {
      return {
        data: {
          success
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_getGoogleSheetsRange_simple = async (googleSheetsId, sheetName, callback) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.getSheetRange, googleSheetsId, view: sheetName }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ?? {}
    if (success) {
      const trueResult = result.result
      const ret = {
        data: {
          success,
          result: trueResult
        }
      }
      callback(ret)
    } else {
      callback({
        data: {
          success
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
}

const fsfn_updateGoogleSheetRowById = async (googleSheetsId, viewItemKey, itemData, useGsid, handleSheetResults, handleSheetError) => {
  try {
    const sheetName = viewItemKey
    const callData = { showLogs: true, sheetFunctionType: sheetsFunctionTypes.updateSheetRowById, googleSheetsId, sheetName, itemData, useGsid, allowUpdate: true }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ?? {}
    console.log('result', result)
    const { error, errors } = result ?? {}
    if (handleSheetResults && success === true) { handleSheetResults(result) }
    if (handleSheetError && success === false) { handleSheetError(error) }
    if (handleSheetError && errors && success === false) { handleSheetError(errors[0]) }
  } catch (error) {
    console.error(error)
  }
}

const fsfn_updateGoogleSheetRow = async (match_edit, handleSheetResults, handleSheetError, team) => {
  try {
    const { away, home, startDate, results } = match_edit ?? {}
    const { score } = results ?? {}
    const matchData = {
      away: away.name,
      home: home.name,
      homeScore: score.home,
      awayScore: score.away,
      startDate: startDate

    }
    const sheetName = 'Test Results 2024'
    const callData = { showLogs: true, sheetFunctionType: sheetsFunctionTypes.updateSheetRow, googleSheetsId: '17wW4AqQOkVkHTAQ9ttl__AIQQhHadfVf4OppcAZSkLg', sheetName, matchData, allowUpdate: true }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ?? {}
    console.log('result', result)
    const { error, errors } = result ?? {}
    if (handleSheetResults && success === true) { handleSheetResults(result, team) }
    if (handleSheetError && success === false) { handleSheetError(error) }
    if (handleSheetError && errors && success === false) { handleSheetError(errors[0]) }
  } catch (error) {
    console.error(error)
  }
}

const getViewItemsSubs = async (projectSettings) => {

  const { viewItems } = projectSettings ?? {}

  const _viewItems = {}

  if (viewItems) {
    if (Object.keys(viewItems).length > 0) {
      // Loop through each view
      _.forEach(viewItems, (viewItem, viewKey) => {
        _viewItems[viewKey] = { subVis: [] }
        const { props } = viewItem
        _.forEach(props, (_prop, propKey) => {
          if (viewItems[propKey]) {
            _viewItems[viewKey].subVis.push(propKey)
          }
        })
      });
      _.forEach(_viewItems, (viewItem, viewKey) => {
        const { subVis } = viewItem ?? {}
        _.forEach(subVis, (subViKey) => {
          if (_viewItems[subViKey] && !_viewItems[subViKey].subVis.includes(viewKey)) {
            _viewItems[subViKey].subVis.push(viewKey)
          }
        })
      })
    }
  }
}
