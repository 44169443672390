import { serverTimestamp } from "firebase/firestore";
import _ from 'lodash';
import { seasonalHelpers } from "../../../../projectSpecific/sports/cnr/reducers/reducerHelpers/seasonalSportsData";
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { dataFix } from "../../../common/dataFix";
import { createRefPath, createRefPath_client, createRefPath_event } from "../../appData/appRefPaths";
import { createFsDocKey, fs_db } from '../../appData/fsAppData';
import { fs_add_doc, fs_update_doc } from '../../appData/fsData';
import { addPromise } from '../../promises/addPromise';
import { updateSettings } from "../../settings/updateSettings";
import { updateDocumentHelpers } from "./fsUpdate_document";
import { fsUpdate_updateRelatedCollections } from "./fsUpdate_updateRelatedCollections";

const _allowSubUpdate = true

export const fsUpdate_addData = async (props) => {

  const {
    baseRef,
    dispatch,
    opts,
    pathViews,
    ufProps,
    database_fns,
  } = props

  const { refPath, directCollection } = baseRef
  let { vit, itemData, selectedDataItems, viDeps, dataRestrictions, appUser, storagePaths, autoUpdateCollectionRelationships, staticViewKeys, useTimestamps, appDataSource } = ufProps ?? {}
  let { useSubDataCollection, subDataCollectionName } = viDeps ? viDeps : {}

  const { useAppDataDocuments, useAppDataDocumentsOnly, updateAppDataDocuments } = appDataSource ?? {}

  let _updateAppDataDocuments = updateAppDataDocuments || useAppDataDocuments

  const _itemData = dataFix.removeAllEmpties(itemData)
  const pks = seasonalHelpers.getPks(pathViews)

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.addData)) {
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  if (useSubDataCollection && subDataCollectionName) {
    fsUpdate_subCollection(database_fns, pathViews, pks, vit, _itemData, dispatch)
  } else {

    const callback_add = (results) => {

      if (opts && opts.settingsProps) {
        updateSettings.createNewViewAndViewItemsSettingsDB(opts.settingsProps, pathViews).then(
          dispatch && dispatch({ type: dispatchProps.success, dispatch })
        )
      }

      const { dataToUpdate, res } = results ?? {}
      const { id: viewKey } = res ?? {}

      autoUpdateCollectionRelationships && fsUpdate_updateRelatedCollections(pathViews, vit, null, dataToUpdate, viewKey, staticViewKeys)

      if (_updateAppDataDocuments) {
        updateDocumentHelpers.update_itemInDocuments(pathViews, vit, viewKey, _itemData)
      }

      dispatch && dispatch({ ...results, dispatch })
    }

    if (useTimestamps) {
      itemData._ts = { _updateDate: serverTimestamp() }
      if (appUser) {
        itemData._uid = appUser.uid
        itemData._user = appUser.displayName
      }
    }

    if (_itemData && (!selectedDataItems || Object.keys(selectedDataItems).length === 0)) {
      switch (vit) {
        default:
          const _addRef = createRefPath([vit], refPath)
          fs_add_doc(_addRef, _itemData, callback_add, false, appUser, storagePaths, vit)
      }
    } else {
      if (selectedDataItems) {
        if (!useAppDataDocumentsOnly) {
          addPromise(refPath, vit, directCollection, selectedDataItems).then((newItem) => {
            callback_add({ type: dispatchProps.success, addPath: refPath, newItem })
            // if (opts && opts.settingsProps) {
            //   createNewViewAndViewItemsSettingsDB(opts.settingsProps, pathViews).then(
            //     dispatch({ type: dispatchProps.success, dispatch })
            //   )
            // } else {
            //   callback_add({ type: dispatchProps.success, addPath: refPath })
            //   // dispatch({ type: dispatchProps.success, dispatch })
            // }
          }).catch(error => {
            console.log('addData', error)
            dispatch({ type: dispatchProps.error, error, dispatch })
          })
        } else {
          if (_updateAppDataDocuments) {
            const docKey = createFsDocKey()
            updateDocumentHelpers.update_itemInDocuments(pathViews, vit, docKey, _itemData, dispatch)
          }
        }

      }
    }
  }
}


/**
 * 
 * @param {object} pathViews 
 * @param {object} pks 
 * @param {string} documentFieldName 
 * @param {object} data 
 * @param {string} collectionName ** with _
 * @param {function} callback 
 * @description Updates the `_seasonalCollectionName` or the collectionName prefixed with an `_`
 */
export const fsUpdate_subCollection = async (database_fns, pathViews, pks, collectionName, data, callback) => {

  const subCollectionName = '_' + collectionName

  let _collectionItem;

  if (pks && !_.isEmpty(pks)) {
    _collectionItem = await seasonalHelpers.getSports_collectionItem(database_fns, pathViews, pks, subCollectionName, true)
  } else {
    if (pathViews.events) {
      const _refPath = createRefPath_event(pathViews, [subCollectionName, collectionName])
      _collectionItem = await fs_db.get_doc({ refPath: _refPath })
    } else {
      const _refPath = createRefPath_client(pathViews, [subCollectionName, collectionName])
      _collectionItem = await fs_db.get_doc({ refPath: _refPath })
    }
  }

  if (_collectionItem && !_.isEmpty(_collectionItem)) {

    const { _itemKey } = _collectionItem

    let _refPath;

    if (pathViews.events) {
      _refPath = createRefPath_event(pathViews, [subCollectionName, _itemKey])
    } else {
      _refPath = createRefPath_client(pathViews, [subCollectionName, _itemKey])
    }

    const refKey = createFsDocKey('subCollection')

    const dataToUpdate = { [collectionName + '.' + refKey]: data }

    _allowSubUpdate && fs_update_doc(_refPath, dataToUpdate, callback)

  } else {
    const _refPath = createRefPath_client(pathViews, [subCollectionName])

    console.log('_refPath', _refPath)

    // if (_refPath) {
    //   const dataToAdd = {
    //     parentKeys: pks,
    //     [collectionName]: data
    //   }

    //   _allowSubUpdate && fs_add_doc(_refPath, dataToAdd, callback)
    // }

  }
} 