import { gEnums } from "../../../global/enums/globalEnums"
import { newPropEditItem } from "../../../global/viewSettings/helpers/settingsHelpers"

export const createSportsFormProps = (props) => {

  const { formPropType } = props ?? {}

  let appFormItems;

  switch (formPropType) {
    case 'sportsSettings':
      appFormItems = {
        allowScheduledScoreUpdateFromGs: newPropEditItem('allowScheduledScoreUpdateFromGs', gEnums.formItemTypes.checkbox),
        allowScoreUpdateFromGs: newPropEditItem('allowScoreUpdateFromGs', gEnums.formItemTypes.checkbox),
        sortStandingsByPoints: newPropEditItem('sortStandingsByPoints', gEnums.formItemTypes.checkbox),
      }
      break;
    case 'glsScores':
      appFormItems = {
        districtLink: newPropEditItem('allowScheduledScoreUpdateFromGs', gEnums.formItemTypes.text),
        googleSheetsId: newPropEditItem('googleSheetsId', gEnums.formItemTypes.text),
        rankingsGoogleSheetsId: newPropEditItem('rankingsGoogleSheetsId', gEnums.formItemTypes.text),
        rosterGoogleSheetsId: newPropEditItem('rosterGoogleSheetsId', gEnums.formItemTypes.text),
        mediaUrl: newPropEditItem('mediaUrl', gEnums.formItemTypes.text),
        mediaUrlDateReplace: newPropEditItem('mediaUrlDateReplace', gEnums.formItemTypes.text),
        playoffScoresSheet: newPropEditItem('playoffScoresSheet', gEnums.formItemTypes.text),
        rankingsSheet: newPropEditItem('rankingsSheet', gEnums.formItemTypes.text),
        scoresSheet: newPropEditItem('scoresSheet', gEnums.formItemTypes.text),
        validAdmins: newPropEditItem('validAdmins', gEnums.formItemTypes.listAdd),
        scoreEmails: newPropEditItem('scoreEmails', gEnums.formItemTypes.listAdd),
        allowScoreUpdateFromGs: newPropEditItem('allowScoreUpdateFromGs', gEnums.formItemTypes.checkbox),
        allowScheduledScoreUpdateFromGs: newPropEditItem('allowScheduledScoreUpdateFromGs', gEnums.formItemTypes.checkbox),
        sortStandingsByPoints: newPropEditItem('sortStandingsByPoints', gEnums.formItemTypes.checkbox),
      }
      break;
    default:
    // nothing
  }

  return appFormItems

}