import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';

import { AppWithProvider } from './App';

const errorStrings = {
  defaultProps: 'Support for defaultProps',
  findDOMNode: 'findDOMNode is deprecated',
  uniqueKeys: 'Each child in a list should have a unique'
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const RootComponent = () => {

  const originalWarn = console.warn;
  console.warn = (message, ...args) => {
    let showMessage = true
    if (typeof message === 'string') {
      Object.keys(errorStrings).forEach(key => {
        if (message.includes(errorStrings[key])) {
          showMessage = false
        }
      })
    }
    originalWarn(message, ...args);
  };

  const originalError = console.error;
  console.error = (message, ...args) => {
    let showMessage = true
    if (typeof message === 'string') {
      Object.keys(errorStrings).forEach(key => {
        if (message.includes(errorStrings[key])) {
          showMessage = false
        }
      })
    }
    showMessage && originalError(message, ...args);
  };
  return <AppWithProvider />
};

root.render(<RootComponent />); 