import _ from 'lodash';
import React, { createContext, Suspense, useContext, useEffect, useReducer } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { getDataManagementOptions } from '../../auth/actionAccessPermissions';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getAuthIcon } from '../../auth/authPermissions';
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { dataActionsHandlers, dataActionsInitialState, dataActionsReducer } from '../../cnr/reducers/DataActionsReducer';
import { getClassNames } from '../../common/retrieve';
import { sortObj } from '../../common/sorting';
import SuspenseDimmer from '../../components/alerts/SuspenseDimmer';
import { appIconTypes } from '../../enums/appIconTypes';
import { dataManagementTypes } from '../../viewSettings/enums/itemActionTypes';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import DataManagementContent from './DataManagementContent';
import { ItemActionContext } from './UiItemAction';

const _dataManagementMenusAllows = [
  dataManagementTypes.createNotification,
  dataManagementTypes.imageMapping,
  dataManagementTypes.manageData,
  dataManagementTypes.googleSheetLink,
]


export const DataActionsContext = createContext();

/**
 * Wrapper for the selected dataManagment item.
 * @returns One of the dataManagement items depending on the dataManagementType
 */
const UiDataManagement = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { pageSettings, pageNav, navUiItemContext } = page_state ?? {}
  const { navOptions } = pageNav ?? {}
  const { pageNavOptions } = navOptions ?? {}
  const { dataManagement: dataActionItems_pio } = pageNavOptions ?? {}
  const { aps_global } = pageSettings ?? {}
  const { dataRestrictions, imageMapping } = aps_global ?? {}
  let { appDataManagement } = aps_global ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // homeSettingsContext 
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { permissionsConsole, settingsConsole: logging } = homeSettings_global ?? {}
  const { dataManagementSettings } = permissionsConsole ?? {}

  // firestoreContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}

  // itemActionContext
  const itemActionContext = useContext(ItemActionContext);
  const { uiItemContext: uiItemContext_alt } = itemActionContext ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);

  // LOOK
  let _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext
  _uiItemContext = uiItemContext_alt ? uiItemContext_alt : _uiItemContext

  const { item_handlers, item_state } = _uiItemContext ?? {}
  const { viewItem } = item_state ?? {}
  const { handleClose_openedItem } = item_handlers ?? {}
  const { key: key_viewItem } = viewItem ?? {}

  const { allowCollectionMapping, collectionNames, locationProps } = imageMapping ?? {}
  const _allowMapping = allowCollectionMapping && collectionNames && collectionNames.includes(viewItem.key)

  if (_allowMapping && locationProps) {
    if (!appDataManagement) { appDataManagement = {} }
    appDataManagement[dataManagementTypes.imageMapping] = { allowAction: true }
  }

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers } = firestoreContext ?? {}

  const _initState = { handleClose_openedItem, firestore_handlers, paps_state, viewItem, dataRestrictions, logging, framework_handlers }
  const [dataActions_state, dataActionsDispatch] = useReducer(dataActionsReducer, dataActionsInitialState(_initState));
  const dataActions_handlers = dataActionsHandlers(dataActionsDispatch, dataActions_state)

  const { dataActionItems, dataManagementType } = dataActions_state ?? {}

  const _dataActionItems = dataActionItems_pio ? dataActionItems_pio : dataActionItems

  const allowedMenuItems = (mis) => {
    const _allowedMenuItems = {}
    Object.keys(mis).forEach(key => {
      const item = mis[key]
      const { accessLevel } = item ?? {}
      const icon = getAuthIcon(accessLevel, true)
      const cn = icon ? 'iconed' : null
      if (_dataManagementMenusAllows.includes(key)) {
        _allowedMenuItems[key] = {
          icon,
          cn
        }
      }
    })
    return _allowedMenuItems
  }


  const _dataManagement = getDataManagementOptions(dataManagementSettings, appDataManagement, appUserAccess)


  // this creates the allowed list of dataManagement 
  useEffect(() => {
    const divs = document.getElementsByTagName('div')
    getClassNames(divs)
    if (_dataManagement) {
      const _das = sortObj(_dataManagement)
      const _dataActionKeys = {}
      Object.keys(_das).forEach(key => {
        if (dataManagementTypes[key] && _das[key].allowAction) {
          switch (key) {
            case 'dataOptions':
              // nothing - this is the root menuItem
              break;
            default:
              _dataActionKeys[key] = _das[key]
              break;
          }
        }
      })
      dataActions_handlers.handleDataManagementItems(_dataActionKeys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleCommit = (name) => dataActions_handlers.handleCommit(name)

  const menuItem = (key, item) => {
    const { accessLevel } = item ?? {}
    const icon = getAuthIcon(accessLevel, true)
    const cn = icon ? 'iconed' : null
    if (_dataManagementMenusAllows.includes(key)) {
      const cc = _.startCase(key)
      return <Menu.Item
        key={key}
        className={cn}
        onClick={() => { dataActions_handlers.handleDataManagementSelect(key) }}
      >
        {icon && <Icon name={icon.name} color={icon.color} ></Icon>}
        <Icon name={appIconTypes.arrowRight} color={'blue'}></Icon>
        {cc}
      </Menu.Item>
    }
  }



  /**
   * 
   * @returns a menu with the dataManagement options
   */
  const menu = () => {
    const elements = []
    Object.keys(_dataActionItems).forEach(key => {
      elements.push(menuItem(key, _dataActionItems[key]))
    })
    return <Menu vertical fluid className={'menu-slim'}>
      {elements}
    </Menu>
  }

  const wrapper = () => <Wrapper
    content={_dataActionItems ? menu() : <div></div>}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleClose_openedItem}
    topperCaption={'Data Management'}
    topperCaption2={_.startCase(key_viewItem)}
  />

  const contentWithContext = () => <DataActionsContext.Provider value={{ dataActions_state, dataActions_handlers }}>
    <Suspense fallback={<SuspenseDimmer origin={'UiDataManagement'} />}>
      {dataManagementType ?
        <DataManagementContent dataManagementType={dataManagementType} handleItemClose={handleClose_openedItem} handleCommit={handleCommit} />
        :
        fullPageWrapper()}
    </Suspense>
  </DataActionsContext.Provider>

  return contentWithContext()

}

export default UiDataManagement