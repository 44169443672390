import { getDownloadURL, list, ref } from "firebase/storage";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { _showStates } from "../../../App";
import { allowAppStorageAndFunctions } from "../../../project/appConfiguration";
import { manifestSizes } from "../../components/imaging/createManifestIcons";
import { ammendManifestSettings } from "../../manifest/updateManifestSettings";
import { getFirebaseStorage } from "../../storage/storageHelpers";
import { PapsContext } from './PapsContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from "./SettingsDataContext";
import { AppSettingsContext } from "./AppSettingsContext";

/** 
@state manifest_state(`manifestIcons`, `startIcon`)
@functions manifest_fns (`getManifestUrl`)
@description Returns the `manifest icons` from storage base on the `storageManifestPath`
 */
export const ManifestContext = createContext();

/** Returns the `manifest icons` from storage base on the `storageManifestPath` */
const ManifestProvider = (props) => {

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ?? {}
  const { appConfigSettings } = appSettings_state ?? {}
  const { appNames } = appConfigSettings ?? {}
  const { appName } = appNames ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { settingsDocName, pathViews } = paps_state ?? {}

  const homeSettingsContext = useContext(HomeSettingsContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventSettingsContext = useContext(EventSettingsContext);

  const { homeSettings_state } = homeSettingsContext ?? {}
  const { homeSettings } = homeSettings_state ?? {}

  const { clientSettings_state } = clientSettingsContext ?? {}
  const { clientSettings } = clientSettings_state ?? {}

  const { eventSettings_state } = eventSettingsContext ?? {}
  const { eventSettings } = eventSettings_state ?? {}

  const _hceSettings = {
    events: eventSettings,
    clients: clientSettings,
    home: homeSettings,
  }

  // local state 
  const [manifestIcons, setManifestIcons] = useState()
  const [manifestReady, setManifestReady] = useState()
  const [startIcon, setStartIcon] = useState()
  const [trueGlobal, setTrueGlobal] = useState()

  const manifest_state = { manifestIcons, startIcon }

  const manifest_fns = {
    getManifestUrl: (size) => {
      return manifestIcons && manifestIcons['manifest_' + size] ? manifestIcons['manifest_' + size].fileUrl : null
    }
  }

  const getManifestIcons = async (storageRef, callback) => {
    try {
      const res = await list(storageRef, { maxResults: 10 });
      manifestPromise(res).then(result => {
        const mis = {}
        let startI;
        result.forEach((r, index) => {
          mis['manifest_' + index] = {
            size: manifestSizes[index],
            fileUrl: r
          }
          if (index === 0) {
            startI = {
              size: manifestSizes[index],
              fileUrl: r
            }
          }
        })
        callback(mis)
        setStartIcon(startI ? startI : {})
      }).catch(error => {
        console.error(error)
        callback({})
        setStartIcon({})
      })
    } catch (error) {
      console.error(error)
      callback({})
      setStartIcon({})
    }
  }

  if (_showStates.any && _showStates.manifest) {
    console.log(Object.keys(manifest_state).sort())
    console.log(Object.keys(manifest_fns).sort())
  }

  useEffect(
    () => {

      let allowManifest = allowAppStorageAndFunctions()

      if (allowManifest) {
        const cb_manifestIcons = (mis) => {
          if (mis && Object.keys(mis).length > 0) {
            setManifestIcons(mis)
          } else {
            setManifestIcons({})
          }
        }

        let _settings;
        let _useClient;
        if (pathViews.events && _hceSettings.events) {
          const { settings, useClient } = getTrueSettings(_hceSettings)
          _settings = settings
          _useClient = useClient
        } else if (pathViews.clients && _hceSettings.clients) {
          _settings = _hceSettings.clients
        } else {
          _settings = _hceSettings.home
        }

        let smp2 = getManifestPath(pathViews, _useClient)

        try {
          const storage = getFirebaseStorage()
          const storageRef = ref(storage, smp2)
          setTrueGlobal(_settings.global)
          try {
            getManifestIcons(storageRef, cb_manifestIcons)
          } catch (error) {
            console.log('e', error)
          }
        } catch (error) {
          console.error('manifest', error)
        }
      }

    },
    // eslint-disable-next-line
    [settingsDocName]
  )

  useEffect(
    () => {
      if (manifestIcons && trueGlobal) {
        ammendManifestSettings(trueGlobal, { manifestIcons }, appName, setManifestReady)
      }
    },
    // eslint-disable-next-line
    [trueGlobal, manifestIcons]
  )

  const providerContext = () => <ManifestContext.Provider value={{ manifest_state, manifest_fns }}>
    {props.children}
  </ManifestContext.Provider>

  return providerContext()

}

export default ManifestProvider

const manifestPromise = async (res) => {

  const { items } = res ?? {}

  const promises = []

  items.forEach(function (itemRef) {
    const { _location } = itemRef
    const { path_ } = _location
    const storage = getFirebaseStorage()
    const iconRef = ref(storage, path_)
    promises.push(getDownloadURL(iconRef))
  });

  return Promise.all(promises)

}

const getTrueSettings = (hceSettings) => {
  const { clients: settings_client, events: settings_event } = hceSettings
  const { global: global_client } = settings_client ?? {}
  const { global: global_event } = settings_event ?? {}
  const { appSettings: appSettings_client } = global_client ?? {}
  const { parentDefaults } = global_event ?? {}
  const { useClientManifest } = appSettings_client ?? {}
  const { useClientManifestIcon } = parentDefaults ?? {}
  if (useClientManifest || useClientManifestIcon) {
    return { settings: settings_client, useClient: true }
  } else {
    return { settings: settings_event, useClient: false }
  }
}

export const getManifestPath = (pathViews, useClient) => {
  let mp = ''
  if (pathViews.events) {
    mp += 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/manifests'
  } else if (pathViews.clients) {
    mp += 'clients/' + pathViews.clients + '/manifests'
  } else {
    return '/manifests'
  }
  return mp
} 