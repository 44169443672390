import _ from 'lodash'
import { updateGlobal } from "../../../projectSpecific/sports/dbActions/globals"
import { removeArrayItem } from '../../common/sorting'
import { gEnums } from "../../enums/globalEnums"
import { fs_db } from '../../firestoreData/appData/fsAppData'
import { fs_update_dataCollection } from '../../firestoreData/appData/fsData'

const rts = {
  handleAdd_newZone: 'handleAdd_newZone',
  handleAmmend_location: 'handleAmmend_location',
  handleAmmend_locationName: 'handleAmmend_locationName',
  handleAmmend_svgElements: 'handleAmmend_svgElements',
  handleAmmend_updateFieldData: 'handleAmmend_updateFieldData',
  handleChange_floormapUrl: 'handleChange_floormapUrl',
  handleFloormapUrlResponse: 'handleFloormapUrlResponse',
  handleGet_floormapImageFromStorage: 'handleGet_floormapImageFromStorage',
  handleInit_imageMap: 'handleInit_imageMap',
  handleRemove_currentZone: 'handleRemove_currentZone',
  handleRemove_zone: 'handleRemove_zone',
  handleSet_currentFloormap: 'handleSet_currentFloormap',
  handleSet_currentLocation: 'handleSet_currentLocation',
  handleSet_currentMapFieldData: 'handleSet_currentMapFieldData',
  handleSet_currentZone: 'handleSet_currentZone',
  handleSet_imageMapType: 'handleSet_imageMapType',
  handleSet_locationData: 'handleSet_locationData',
  handleSet_locations: 'handleSet_locations',
  handleSet_selectedLocationData: 'handleSet_selectedLocationData',
  handleSet_selectedLocationKey: 'handleSet_selectedLocationKey',
  handleSet_zonedFloormaps: 'handleSet_zonedFloormaps',
  handleUpdate_fieldDataToDb: 'handleUpdate_fieldDataToDb',
  handleUpdate_zonesToDb: 'handleUpdate_zonesToDb',
  handleUpdated: 'handleUpdated',
}

const _zoneCollectionName = '_zones'

export const imageMapReducer = (state, action) => {

  const { appGlobalData, imageMapType, pathViews, collectionName, _zonedFloormaps, currentElements, currentFloormap, currentZone, currentLocation, mapFieldName, globalLocationKeys, locationsData, selectedGlobalData } = state
  const { type, value, img, newItem, dispatch } = action

  const {
    handleFloormapUrlResponse,
    handleGet_floormapImageFromStorage,
    handleSet_currentMapFieldData,
    handleSet_locationData,
    handleSet_zonedFloormaps,
    handleUpdated,
  } = imageMapHandlers(dispatch)

  switch (type) {

    case rts.handleInit_imageMap:

      // gets the zones 
      // get the data from _zones >> _zonedFloormaps 
      fs_db.get_data_any({ pathViews, collectionName: _zoneCollectionName, callback: handleSet_zonedFloormaps }) // _zonedFloormaps  

      const dbl = (d, opts) => {
        const { locationKey } = opts ?? {}
        handleSet_locationData(locationKey, d)
      }

      if (globalLocationKeys) {
        globalLocationKeys.forEach(dfn => {
          fs_db.get_data_any({ pathViews, collectionName: dfn, callback: dbl, opts: { cbProps: { locationKey: dfn } } })
        })
      }

      switch (imageMapType) {
        case gEnums.imageMapTypes.itemLocation:
        case gEnums.imageMapTypes.locationMapping:
        case gEnums.imageMapTypes.zoneMapping:
          fs_db.get_eventData_global(pathViews, mapFieldName, false, handleSet_currentMapFieldData)
          break;
        default:
        // nothing
      }
      return { ...state }

    case rts.handleSet_locationData:
      const _locationsData = { ...locationsData }
      _locationsData[action.locationKey] = value
      return { ...state, locationsData: _locationsData }

    case rts.handleSet_zonedFloormaps:
      return { ...state, _zonedFloormaps: value }

    case rts.handleSet_imageMapType:
      return { ...state, imageMapType: action.imageMapType }

    case rts.handleAmmend_locationName:
      const { locationName } = action
      if (selectedGlobalData && Object.keys(selectedGlobalData).length > 0) {
        const { imageKey, currentZone: cz, _lm } = getLocationProps(selectedGlobalData, locationName, _zonedFloormaps)
        handleGet_floormapImageFromStorage(imageKey)
        return { ...state, locationName: locationName, currentLocation_mapped: _lm, currentZone: cz } //, currentFloormap: imageKey 
      }
      return { ...state, locationName: locationName }

    case rts.handleSet_currentLocation:
      const { imageKey, currentZone: cl, _lm } = getLocationProps(selectedGlobalData, action.location.name, _zonedFloormaps)
      handleGet_floormapImageFromStorage(imageKey)
      return { ...state, currentLocation: action.location, currentLocation_mapped: _lm, currentZone: cl }

    case rts.handleUpdated:
      return { ...state, updating: false }

    case rts.handleSet_currentMapFieldData:
      if (mapFieldName && value && value[mapFieldName]) {
        return { ...state, selectedGlobalData: value[mapFieldName] }
      } else {
        return { ...state, selectedGlobalData: value }
      }

    case rts.handleSet_currentFloormap:
      return { ...state, currentFloormap: value }

    case rts.handleSet_currentZone:
      return { ...state, currentZone: value }

    case rts.handleAmmend_svgElements:
      return { ...state, currentElements: value }

    case rts.handleGet_floormapImageFromStorage:
      if (action.currentFloormap) {
        state.storage_fns.getImageUrl(action.currentFloormap, true, gEnums.storageTypes.floormap, handleFloormapUrlResponse)
        return { ...state, currentFloormap: action.currentFloormap }
      }
      return { ...state }

    case rts.handleFloormapUrlResponse:
      const { url } = img ?? {}
      return { ...state, floormapUrl: url }

    case rts.handleChange_floormapUrl:

      // this triggers when the floormapUrl has changed 
      let nzs;
      let _currentElements = []

      if (_zonedFloormaps && currentFloormap && _zonedFloormaps[currentFloormap]) {
        const x = _zonedFloormaps[currentFloormap]
        const { zones } = x ?? {}
        if (zones) {
          const { namedZones, combined } = getSvgCombined(zones)
          // update the nzones
          nzs = namedZones
          _currentElements = combined
        }
      }
      return { ...state, currentElements: _currentElements, currentZones: nzs }

    // newItem
    case rts.handleAdd_newZone:
      const { _svgElements, _zones } = addNewZone(state, newItem)
      return { ...state, currentZones: _zones, currentElements: _svgElements, currentZone: null }

    case rts.handleRemove_zone:
      const { _svgElementsR, _zonesR } = removeZone(state, newItem)
      return { ...state, currentZones: _zonesR, currentElements: _svgElementsR, currentZone: null }

    case rts.handleRemove_currentZone:
      return { ...state, currentZone: null }

    case rts.handleAmmend_location:

      switch (imageMapType) {

        case gEnums.imageMapTypes.itemLocation:
          const currZone = getCurrentZone(state.locationName, _zonedFloormaps, selectedGlobalData, state.itemLocation)
          if (currZone) {
            return { ...state, currentZone: currZone }
          }
          break;
        default:
        // nothing
      }

      return { ...state }

    // database
    case rts.handleUpdate_zonesToDb:
      const svge = [...currentElements]
      fs_update_dataCollection(pathViews, collectionName, currentFloormap, { zones: svge }, handleUpdated)
      return { ...state, updating: true }

    case rts.handleUpdate_fieldDataToDb:
      const { selectedGlobalData: sgd, selectedLocationData: sld, selectedLocationKey: slk } = state
      if (sgd && sld && slk) {
        const _locationKey = _.findKey(sgd, { name: currentLocation.name })
        if (sgd[_locationKey]) {
          sgd[_locationKey].zone = {
            imageKey: currentFloormap,
            zoneIndex: currentZone.text
          }
          updateGlobal(state.pathViews, slk, sgd, handleUpdated)
        }
      }
      return { ...state, updating: true }

    case rts.handleAmmend_updateFieldData:
      const _mapFieldData = { ...state.selectedGlobalData }
      const { fieldItem: fi, currentFloormap: fmsi, zoneIndex: zid } = action.updateProps
      if (_mapFieldData && fi) {
        const _itemKey = _.findKey(_mapFieldData, { name: fi })
        if (_mapFieldData[_itemKey]) {
          _mapFieldData[_itemKey].zone = {
            imageKey: fmsi,
            zoneIndex: zid
          }
        }
      }
      return { ...state, selectedGlobalData: _mapFieldData }

    case rts.handleSet_selectedLocationKey:
      return {
        ...state,
        selectedLocationKey: action.selectedLocationKey,
        mapFieldName: action.selectedLocationKey,
        selectedGlobalData: appGlobalData[action.selectedLocationKey]
      }

    case rts.handleSet_selectedLocationData:
      return { ...state, selectedLocationData: action.selectedLocationData }

    default:
      return state
  }
}

export const imageMapInitialState = ({ appGlobalData, storage_fns, imageMapType, mapFieldName, globalLocationKeys, collectionName, itemLocation, setInit, pathViews, isItemMapping }) => {
  return {
    collectionName,
    imageMapType,
    itemLocation,
    selectedGlobalData: {},
    mapFieldName,
    globalLocationKeys,
    pathViews,
    setInit,
    storage_fns,
    currentElements: [],
    currentZones: [],
    locationsData: {},
    isItemMapping,
    appGlobalData,
  }
};

export const imageMapHandlers = (dispatch) => {
  return {
    handleAdd_newZone: (newItem) => { dispatch({ type: rts.handleAdd_newZone, dispatch, newItem }) },
    handleAmmend_location: () => { dispatch({ type: rts.handleAmmend_location, dispatch }) },
    handleAmmend_locationName: (locationName) => { dispatch({ type: rts.handleAmmend_locationName, locationName, dispatch }) },
    handleAmmend_svgElements: (value) => { dispatch({ type: rts.handleAmmend_svgElements, dispatch, value }) },
    handleAmmend_updateFieldData: (updateProps) => { dispatch({ type: rts.handleAmmend_updateFieldData, dispatch, updateProps }) },
    handleChange_floormapUrl: () => { dispatch({ type: rts.handleChange_floormapUrl, dispatch }) },
    handleFloormapUrlResponse: (img, currentFloormap) => { dispatch({ type: rts.handleFloormapUrlResponse, dispatch, img, currentFloormap }) },
    handleGet_floormapImageFromStorage: (currentFloormap) => { dispatch({ type: rts.handleGet_floormapImageFromStorage, dispatch, currentFloormap }) },
    handleInit_imageMap: (staticView) => { dispatch({ type: rts.handleInit_imageMap, dispatch, staticView }) },
    handleRemove_currentZone: () => { dispatch({ type: rts.handleRemove_currentZone, dispatch }) },
    handleRemove_zone: () => { dispatch({ type: rts.handleRemove_zone, dispatch }) },
    handleSet_currentFloormap: (value) => { dispatch({ type: rts.handleSet_currentFloormap, dispatch, value }) },
    handleSet_currentLocation: (location) => { dispatch({ type: rts.handleSet_currentLocation, dispatch, location }) },
    handleSet_currentMapFieldData: (value) => { dispatch({ type: rts.handleSet_currentMapFieldData, dispatch, value }) },
    handleSet_currentZone: (value) => { dispatch({ type: rts.handleSet_currentZone, dispatch, value }) },
    handleSet_imageMapType: (imageMapType) => { dispatch({ type: rts.handleSet_imageMapType, dispatch, imageMapType }) },
    handleSet_locationData: (locationKey, value) => { dispatch({ type: rts.handleSet_locationData, dispatch, locationKey, value }) },
    handleSet_selectedLocationData: (selectedLocationData) => { dispatch({ type: rts.handleSet_selectedLocationData, dispatch, selectedLocationData }) },
    handleSet_selectedLocationKey: (selectedLocationKey) => { dispatch({ type: rts.handleSet_selectedLocationKey, dispatch, selectedLocationKey }) },
    handleSet_zonedFloormaps: (value) => { dispatch({ type: rts.handleSet_zonedFloormaps, dispatch, value }) },
    handleUpdate_fieldDataToDb: () => { dispatch({ type: rts.handleUpdate_fieldDataToDb, dispatch }) },
    handleUpdate_zonesToDb: () => { dispatch({ type: rts.handleUpdate_zonesToDb, dispatch }) },
    handleUpdated: () => { dispatch({ type: rts.handleUpdated, dispatch }) },
  }
}

const getSvgCombined = (_zonedFloormaps) => {
  const be = []
  const elems = []
  // create the zones
  _zonedFloormaps.forEach((zone, index) => {
    const i = index + 1
    zone.text = i // 'Zone ' + i
    zone.zone = 'zone ' + i
    elems.push(zone)
  })
  // create the namedZones
  const combined = [...be, ...elems]
  const namedZones = {}
  if (elems) {
    elems.forEach((elem, index) => {
      const i = index + 1
      const key = 'zone ' + i
      namedZones[key] = elem
      namedZones[key].zoneIndex = index + 1
    })
  }
  return { namedZones, combined }
}

const getLastZoneNumber = (currentZones) => {

  const zoneKeys = currentZones ? Object.keys(currentZones) : [];

  // Using lodash to get the last key in the object
  const lastZoneKey = _.last(zoneKeys);

  // Getting the value of the last zone's "zone" property and incrementing it
  let newZoneValue;
  if (lastZoneKey && currentZones[lastZoneKey] && currentZones[lastZoneKey].zone) {
    const lastZoneNumber = parseInt(currentZones[lastZoneKey].zone.split(' ')[1]);
    newZoneValue = lastZoneNumber + 1;
  } else {
    newZoneValue = 1;
  }

  return newZoneValue
}

const addNewZone = (state, newItem) => {
  const { currentZones, currentElements } = state
  const _zones = { ...currentZones }
  const _svgElements = [...currentElements]
  const _newZoneNumber = getLastZoneNumber(currentZones)
  const key = 'zone ' + _newZoneNumber

  newItem.color = 'green'
  newItem.text = _newZoneNumber
  newItem.zone = 'zone ' + _newZoneNumber

  _zones[key] = newItem
  _svgElements.push(newItem)

  return { _zones, _svgElements }

}

const removeZone = (state) => {
  const { currentZone, currentZones, currentElements } = state
  const _zonesR = { ...currentZones }
  const _svgElementsR = [...currentElements]
  delete _zonesR[currentZone.zone]
  const _item = _.find(_svgElementsR, { zone: currentZone.zone })
  if (_item) { removeArrayItem(_svgElementsR, _item) }
  return { _zonesR, _svgElementsR }

}

const getCurrentZone = (locationName, zonedFloormaps, selectedGlobalData) => {

  let currZone;

  if (zonedFloormaps && selectedGlobalData) {
    const _itemKey = _.findKey(selectedGlobalData, { name: locationName })
    if (selectedGlobalData[_itemKey]) {
      const _location_mapped = selectedGlobalData[_itemKey]
      const { zone } = _location_mapped ?? {}
      const { imageKey, zoneIndex } = zone ?? {}
      const item = zonedFloormaps[imageKey]
      const { zones } = item ?? {}
      if (zones) { currZone = _.find(zones, function (currZone) { return currZone.zoneIndex === zoneIndex || currZone.text === zoneIndex; }) }
    }
  }
  return currZone
}

const getLocationProps = (selectedGlobalData, locationName, _zonedFloormaps) => {
  const _lm = selectedGlobalData && locationName ? selectedGlobalData[locationName] : null
  const { zone } = _lm ?? {}
  const { imageKey } = zone ?? {}
  const currentZone = getCurrentZone(locationName, _zonedFloormaps, selectedGlobalData)
  return { imageKey, currentZone, _lm }
}