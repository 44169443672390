import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { _checkComponentTimes } from '../../App';
import { subAppTypes } from '../../AppSub';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import PageAreaProvider from '../cnr/contexts/PageAreaContext';
import PageAreaParentProvider from '../cnr/contexts/PageAreaParentContext';
import PageDataProvider from '../cnr/contexts/PageDataContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import RssProvider from '../cnr/contexts/RssContext';
import { startTypes } from '../cnr/reducers/StartReducer';
import { swipePropNew } from '../cnr/reducers/reducerHelpers/swipeHelpers';
import { filterHelpers } from '../common/filtering';
import { uniqueKey } from '../common/keys';
import { sortObject } from '../common/sorting';
import PageError, { pageErrorReasonTypes } from '../components/alerts/PageError';
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import PendingWait from '../components/alerts/pendings/PendingWait';
import ErrorBoundary from '../components/errorHandling/ErrorBoundery';
import ImageMapping from '../components/swipe/ImageMapping';
import { UiSwiperWithProvider } from '../components/swipers/UiSwiper';
import { userProfileItems } from '../components/user/userProfileItems';
import { allPageTypes } from '../enums/allPageTypes';
import { gEnums } from '../enums/globalEnums';
import { currentHelpers } from '../redirection/current';
import UiWrapper from '../wrappers/UiWrapper';
import PageContent from './PageContent';
import PageItem from './PageItem';
import PageMedia from './PageMedia';
// import { fsfn_srapeUrl } from '../functions/fbFetchFunctions';
// import UiPaokeSwiperGeneric from '../components_npm/UiPaokeSwiper';

// lazy these. They are not normally used
// const { ImageMap, imageMapTypes } = React.lazy(() => import("../../components/swipe/ImageMap"));

// GOOD TO GO
const AppUserProfilePagesWithProvider = React.lazy(() => import("../components/user/AppUserProfileItems"));
const DirectTicket = React.lazy(() => import("../../projectSpecific/ticketing/components/DirectTicket"));
const EmailSignInConfirmation = React.lazy(() => import("../validation/EmailSignInConfirmation"));
const GolfEventWrapper = React.lazy(() => import("../../projectSpecific/golf/pages/GolfEventWrapper"));
const GolfTournamentWrapper = React.lazy(() => import("../../projectSpecific/golf/pages/GolfTournamentWrapper"));
const HomePage = React.lazy(() => import("./HomePage"));
const NotificationForeground = React.lazy(() => import("../components/alerts/NotificationForeground"));
const PageAreaContentAlt = React.lazy(() => import("./PageAreaContentAlt"));
const SignIn = React.lazy(() => import("../components/auth/SignIn"));
const Waiver = React.lazy(() => import("../../globalAlt/page/Waiver"));

const _allowHomePage = false

/**
 * 
 * @returns The main content of the app.
 * @description Mostly returns `PageContent`, but depending on certain 'views', it will return something else.
 */
const PageAreaContent = () => {

  const _apits = gEnums.additionalPageItemTypes

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ?? {}
  const { appNotifications_state, appUser_state, page_state, paps_state, start_state, questionsAndAnswers_state } = states
  const { start_handlers } = handlers
  const { appUser_fns, start_fns } = fns
  const { pageSettings } = page_state ?? {}
  const { startType } = start_state ?? {}
  const { aps_global, aps_page, aps_appUserSettings, isGlobalStatic, pageSettingsKey, missingSettings } = pageSettings ?? {}

  // authContext 
  const { appUser, appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel, email: appUserEmail, loggedIn, appUserSession } = appUserAccess ?? {}
  const { appUserSessionKey } = appUserSession ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}
  const { handleShow_signIn } = framework_handlers ?? {}

  // questionsAndAnswersContext 
  const { questionsAndAnswers } = questionsAndAnswers_state ?? {}

  // papsContext 
  const { homePageSeen, ddGroupIndex, fullSettingsKey, nonLandingView, otherView, pageKey, pathViews, permissionType, view, viewKey } = paps_state ?? {}
  const { events: eventKey } = pathViews ?? {}

  const _isAppUserPage = appUserSessionKey === viewKey

  const _questionsAndAnswers = questionsAndAnswers && viewKey ? questionsAndAnswers[viewKey] : null

  // pageContext  
  const { desktop, homePage, waiver, pageOptions, projectModules, appGallery, appNotifications } = aps_global ?? {}
  const { allowGallery, galleryPages } = appGallery ?? {}
  const { appUserCollection, showAppUserPersonalPages, showAppUserProfilePages, showAppUserPrivacyPages, groupAppUserPages, groupCaption, showProfileSettingsAsPublic } = aps_appUserSettings ?? {}
  const { showHomePage, homePageText } = homePage ?? {}
  const { showWaiver, waiverText } = waiver ?? {}
  const { useDefaultLayoutType, defaultLayoutType } = desktop ?? {}
  const { showNotificationsOnForeground } = appNotifications ?? {}

  const _showAppUserPages = showAppUserPersonalPages || showAppUserProfilePages || showAppUserPrivacyPages
  const { newPayload, notifications_appUser } = appNotifications_state ?? {}
  const { hasNew } = notifications_appUser ?? {}

  // same source 
  const { displayType, viewType, viewItems: viewItems_page, layout: layout_page, desktopLayout: desktopLayout_page } = aps_page ?? {}
  const { feeds: feeds_asp, geoList: geoList_asp, geoLocation: geoLocation_asp, imageLinks: imageLinks_asp, questionsAndAnswers: questionsAndAnswers_asp, twitter: twitter_asp, videoLinks: videoLinks_asp, voting: voting_asp } = aps_page ?? {}
  const { feedPageItemPosition } = feeds_asp ?? {}

  const { allowQuestionsAndAnswers: allowQuestionsAndAnswers_g, showOnPage: showOnPage_qAndA } = questionsAndAnswers_asp ?? {}

  const layout = desktopMode && desktopLayout_page ? desktopLayout_page : layout_page
  const { layoutType, pageIcons: uiSelectedIcons, selectedItems: uiSelectedItems, fixFirstViewItem, gridTemplateRows, showHiddenToAdmins } = layout ?? {}

  let _layoutType = desktopMode && useDefaultLayoutType && defaultLayoutType ? defaultLayoutType : layoutType
  let _isAdminHidden;

  switch (_layoutType) {
    case gEnums.layoutTypes.swipeableHidden:
      if (showHiddenToAdmins) {
        _layoutType = gEnums.layoutTypes.swipeable
        _isAdminHidden = true
      }
      break;
    default:
    // nothing
  }

  const _seenItem_hp = eventKey ? 'homePageSeen-' + eventKey : 'homePageSeen'
  const _homePageSeen = currentHelpers.storageItem_get(_seenItem_hp)

  const seenItem_w = eventKey ? 'waiverSeen-' + eventKey : 'waiverSeen'
  const _waiverSeen = currentHelpers.storageItem_get(seenItem_w)

  // local state
  const [profileItems, setProfileItems] = useState()
  const [pageItemsShown, setViewPageItemsShown] = useState()

  _checkComponentTimes && start_fns.useTimeMeasurement('PageAreaContent', pageItemsShown)

  useEffect(() => {
    if (startType !== startTypes.finish) {
      start_handlers.handleAmmend_startDimmer(startTypes.finish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [startType, appUserAccess]);

  useEffect(() => {

    const _shownPageItems = getShownPageItems()
    const _profileItems = getAppUserProfileItems()

    setProfileItems(_profileItems)

    let _viewItems_page_shown;

    if (otherView && viewItems_page) {
      switch (otherView) {
        case 'add':
          _viewItems_page_shown = { [view]: viewItems_page[view] }
          break;
        case 'edit':
          _viewItems_page_shown = { [view]: viewItems_page[view] }
          break;
        case 'media':
          _viewItems_page_shown = { [view]: viewItems_page[view] }
          break;
        default:
        // nothing
      }
    } else {
      switch (_layoutType) {
        default:
          _viewItems_page_shown = _shownPageItems
      }
    }

    addOtherPageItems(_viewItems_page_shown)
    setViewPageItemsShown(_viewItems_page_shown)

    // return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullSettingsKey, pathViews, viewItems_page, appUserEmail, accessLevel]);

  /** returns the viewItems/pageItems for the page where `show` is set to true */
  const getShownPageItems = () => {

    // get only viewItems marked with show = true
    const showProp = desktopMode ? gEnums.showTypes.showDesktop : gEnums.showTypes.show

    // list of pageItems with their props filter to shown only

    let pageItems_adjusted = filterHelpers.filterObject(viewItems_page, showProp)

    appUser_fns.validateAccess_items(pageItems_adjusted)

    let splitItems;

    if (pageItems_adjusted) {
      Object.keys(pageItems_adjusted).forEach(key => {
        const pageItemAdjusted = pageItems_adjusted[key]
        const { combine, adminOnly } = pageItemAdjusted ?? {}

        if (adminOnly) {
          if (!accessLevel || accessLevel < gEnums.accessLevels.admin.value) {
            delete pageItems_adjusted[key]
          }
        }

        const { useCombine, combineWith } = combine ?? {}

        // remove combined pageItems
        if (useCombine && combineWith && combineWith.length > 0) {
          pageItemAdjusted.combinedGroups = {}
          combineWith.forEach(key => {
            pageItemAdjusted.combinedGroups[key] = pageItems_adjusted[key]
            delete pageItems_adjusted[key]
          })
        }
      })
      pageItems_adjusted = sortObject(pageItems_adjusted, 'position')
    }

    if (splitItems) {
      Object.keys(splitItems).forEach(k => {
        pageItems_adjusted[k] = splitItems[k]
      })
    }

    return pageItems_adjusted
  }

  const appUserSwipeItems = (pis) => {
    const keyy = groupCaption ? _.camelCase(groupCaption) : 'profile'
    const x = <AppUserProfilePagesWithProvider key={uniqueKey('aupwp')} />
    pis[keyy] = swipePropNew(keyy, x, false, true)
  }

  /** returns the profile items for the page, if the view/page === appUserCollection */
  const getAppUserProfileItems = () => {
    let pis = {};
    if (appUserCollection === view && (_showAppUserPages)) {
      const aaua = appUser_fns.validateAccess_appUser({ pathViews, appUserCollection, view, viewKey, appUserAccess })
      if (showProfileSettingsAsPublic || aaua) {
        if (groupAppUserPages) {
          appUserSwipeItems(pis)
        } else {
          pis = userProfileItems(aps_global, aps_appUserSettings, appUser, appUsers)
        }
      }
    } else if (view === 'clients' && loggedIn) {
      // appUserSwipeItems(pis)
    }
    return pis
  }

  /** returns an other items for the page */
  const addOtherPageItem = (displayType, componentProps, existingCount, caption) => {
    return {
      key: displayType,
      position: existingCount ? existingCount : 2,
      show: true,
      display: {
        caption: caption ? caption : _.startCase(displayType),
        displayType: displayType,
      },
      _viewPermissionType: gEnums.viewPermissionTypes.allow,
      componentProps
    }
  }

  /** Adds additional pageItems
   * @add feeds, imageLinks, twitter, videoLinks, photos
   */
  const addOtherPageItems = (vpias) => {

    const { allowQuestionsAndAnswers, allowVoting } = projectModules ?? {}
    const { allowGeoList, allowGeoLocation, allowFeeds, allowImageLinks, allowTwitter, allowVideoLinks } = pageOptions ?? {}

    const count = vpias ? Object.keys(vpias).length : 0

    if (vpias) {
      if (allowQuestionsAndAnswers && allowQuestionsAndAnswers_g && showOnPage_qAndA && _questionsAndAnswers && _questionsAndAnswers.questionActive) { vpias[_apits.questionsAndAnswers] = addOtherPageItem(gEnums.displayTypes.questionsAndAnswers, questionsAndAnswers_asp, count, 'Q and A') }
      if (allowVoting && voting_asp && voting_asp.allowVoting) { vpias[_apits.voting] = addOtherPageItem(gEnums.displayTypes.questionsAndAnswers, questionsAndAnswers_asp, count, 'Q and A') }
      if (allowFeeds && feeds_asp && feeds_asp.showFeed) { vpias[_apits.feeds] = addOtherPageItem(gEnums.displayTypes.feeds, feeds_asp, feedPageItemPosition ? feedPageItemPosition : count, 'Media') }
      if (allowGeoList && geoList_asp && geoList_asp.showGeoList && _apits && vpias) { vpias[_apits.geoList] = addOtherPageItem(gEnums.displayTypes.geoList, imageLinks_asp, count, geoList_asp.geoListCaption ? geoList_asp.geoListCaption : null) }
      if (allowGeoLocation && geoLocation_asp && geoLocation_asp.showGeoLocation && _apits && vpias) { vpias[_apits.geoLocation] = addOtherPageItem(gEnums.displayTypes.geoLocation, imageLinks_asp, count) }
      if (allowImageLinks && imageLinks_asp && imageLinks_asp.showImageLinks) { vpias[_apits.imageLinks] = addOtherPageItem(gEnums.displayTypes.imageLinks, imageLinks_asp, count) }
      if (allowTwitter && twitter_asp && twitter_asp.showTwitter) { vpias[_apits.twitter] = addOtherPageItem(gEnums.displayTypes.twitter, twitter_asp, count) }
      if (allowVideoLinks && videoLinks_asp && videoLinks_asp.showVideoLinks) { vpias[_apits.videoLinks] = addOtherPageItem(gEnums.displayTypes.videoLinks, videoLinks_asp, count) }
    }

    if (allowGallery && galleryPages && galleryPages.includes(view) && _apits && viewKey) {
      if (!vpias) { vpias = {} }
      vpias[_apits.photos] = addOtherPageItem(gEnums.displayTypes.gallery, appGallery, count)
    }
  }

  const viCount = pageItemsShown ? Object.keys(pageItemsShown).length : 0

  const getPageItem = (key, tabIndex, visible) => {
    return { loaded: true, visible, content: <PageItem key={uniqueKey('sipi', key)} uivi={key} tabIndex={tabIndex} from={'swipeItem_pageItem'} /> }
  }

  const pageAreaSwiper = () => <UiSwiperWithProvider
    keyy={'pac'}
    swipeContentType={gEnums.swipeContentTypes.pageAreaContent}
    view={view}
    fn_getContent={getPageItem}
    fromAppUserProfile={false}
    layoutType={_layoutType}
    isAdminHidden={_isAdminHidden}
  />

  const swiper = () => pageAreaSwiper()

  const pageContent = () => {

    const isTicketing = pathViews.ticketing ? true : false
    const isMedia = view === allPageTypes.media ? true : false
    const isImageMapping = view === allPageTypes.imageMapping ? true : false

    if (nonLandingView === subAppTypes.emailSignInConfirmation) {
      return <EmailSignInConfirmation />
    } else if (isTicketing) {
      return <DirectTicket />
    } else if (isMedia) {
      return <PageMedia />
    } else if (isImageMapping) {
      return <ImageMapping imageMapType={gEnums.imageMapTypes.zoneMapping} />
    } else {
      if (_allowHomePage && !_homePageSeen && !homePageSeen && showHomePage && homePageText) {
        return <HomePage />
      } else if (!_waiverSeen && showWaiver && waiverText) {
        return <Waiver />
      } else {
        switch (view) {
          case allPageTypes.appProfile:
          case allPageTypes.appUserInfo:
          case allPageTypes.appUserRequests:
          case allPageTypes.appUsersManager:
          case allPageTypes.appProfilesManager:
          case allPageTypes.conversations:
          case allPageTypes.credentialScan:
          case allPageTypes.favorites:
          case allPageTypes.gallery:
          case allPageTypes.media:
          case allPageTypes.meetingsManager:
          case allPageTypes.notifications:
          case allPageTypes.notificationsManager:
          case allPageTypes.photos:
          case allPageTypes.ticketingManager:
          case allPageTypes.ticketingScan:
          case allPageTypes.topicsManager:
          case allPageTypes.voting:
            return <PageAreaContentAlt view={view} />

          default:
            switch (viewType) {
              default:
                switch (displayType) {
                  case gEnums.viewDisplayTypes.signIn:
                    return <SignIn origin={'PageAreaContent'} handleCancel={handleShow_signIn} />

                  case gEnums.viewDisplayTypes.appProfile:
                  case allPageTypes.appUserInfo:
                    return UiWrapper(<AppUserProfilePagesWithProvider />)

                  default:
                    switch (permissionType) {
                      default:
                        switch (_layoutType) {
                          case gEnums.layoutTypes.swipeable:
                          case gEnums.layoutTypes.swipeableHidden:
                            if (viCount > 1 && !ddGroupIndex) {
                              return swiper()
                            } else {
                              return <PageContent />
                            }

                          case gEnums.layoutTypes.horizontalSliders:
                          case gEnums.layoutTypes.icons:
                          case gEnums.layoutTypes.pageIcons:
                          case gEnums.layoutTypes.pageList:
                          case gEnums.layoutTypes.singlePage:
                            return <PageContent />

                          case gEnums.layoutTypes.golfEvent:
                            return <GolfEventWrapper />

                          case gEnums.layoutTypes.golfTournament:
                            return <GolfTournamentWrapper />

                          default:
                            return <PageError reason={pageErrorReasonTypes.noLayoutType} />
                        }
                    }
                }
            }
        }
        // }
      }
    }
  }

  const content = () => <ErrorBoundary origin={'pageAreaContent'}>
    <PageDataProvider>
      <PageAreaProvider
        fixFirstViewItem={fixFirstViewItem}
        gridTemplateRows={gridTemplateRows}
        profileItems={profileItems}
        uiGroupedItems={uiSelectedIcons}
        uiSelectedItems={uiSelectedItems}
        pageItemsShown={pageItemsShown}
        pageViewItemKeys={pageItemsShown ? Object.keys(pageItemsShown) : []}
        isAppUserPage={_isAppUserPage}
      >
        <PageAreaParentProvider>
          <RssProvider>
            <Suspense fallback={<SuspenseDimmer origin={'Page Area Content'} />}>
              {pageContent()}
              {(isGlobalStatic || missingSettings) && <PageError reason={pageErrorReasonTypes.noSettings} />}
              {(showNotificationsOnForeground && (newPayload || hasNew)) && <NotificationForeground />}
            </Suspense>
          </RssProvider>
        </PageAreaParentProvider>
      </PageAreaProvider>
    </PageDataProvider>
  </ErrorBoundary>

  // IMPORTANT: Wait for the pageSetting to be correct
  if (pageItemsShown && pageSettingsKey === pageKey) {
    return content()
  } else {
    return <PendingWait />
  }
}

export default PageAreaContent