export const sendMessageToServiceWorker = (settings) => {
  const { cacheName, cacheType, cacheData, merge } = settings ?? {}
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    // Sends data from the main thread (React app) to the service worker. In service-worker.js
    console.log('------- >>> postMessage', cacheName, cacheType)
    navigator.serviceWorker.controller.postMessage({
      cacheName,
      cacheType,
      cacheData,
      merge,
    });
    return true
  } else {
    console.log('------- XXX postMessage', cacheName, cacheType)
    return false
  }
}