import React, { useContext, useEffect } from 'react';
import { Label } from 'semantic-ui-react';
import { AppComponentContext } from '../../../../global/cnr/contexts/AppComponentContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import JsonViewer from '../../../../global/components/viewers/JsonViewer';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';

const SeasonalData = (props) => {

  const { fromDashboard, handleCancel, sportsKey, currentItem, currentItemKey, showDatasDuel } = props
  const { subCollection } = currentItem ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsContext
  const sportsContext = useContext(AppComponentContext)
  const { sports_state } = sportsContext ?? {}
  const { sports: sports_all } = sports_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { sportSeasonData, sportSeasonMatches, updating, seasonalDatas, seasonalDatas_alt, seasonalDataType } = sportsDistrict_state ?? {}
  const { sport, year } = sportSeasonData ?? {}

  const _seasonalData = seasonalDatas ? seasonalDatas[seasonalDataType] : {}
  const _seasonalDataAlt = seasonalDatas_alt ? seasonalDatas_alt[seasonalDataType] : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { parentDataKeys } = sportsSeason_state ?? {}

  const _sportAndYear = sport + ' ' + year
  const _isSport = pathViews && pathViews.sports ? true : false

  // get the _matches_seasonal data
  useEffect(() => {
    if (currentItemKey) {
      let itemKey;
      if (_isSport) {
        const _currentItemKey = currentItemKey.substring(1)
        if (parentDataKeys && parentDataKeys[_currentItemKey]) {
          itemKey = parentDataKeys[_currentItemKey]
        }
      }
      if (seasonalDatas && seasonalDatas[currentItemKey]) {
        sportsDistrict_handlers.handleSet_districtSeasonalDataExisting(currentItemKey)
      } else {
        sportsDistrict_handlers.handleSet_sportsProps(sports_all, sportsKey, subCollection)
        sportsDistrict_handlers.handleGet_districtSeasonalData(currentItemKey, itemKey)
        if (showDatasDuel) {
          sportsDistrict_handlers.handleGet_districtSeasonalData(currentItemKey, itemKey, true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemKey]);

  useEffect(() => {
    if (currentItemKey && showDatasDuel) {
      let itemKey;
      if (_isSport) {
        const _currentItemKey = currentItemKey.substring(1)
        if (parentDataKeys && parentDataKeys[_currentItemKey]) {
          itemKey = parentDataKeys[_currentItemKey]
        }
      }
      if (seasonalDatas_alt && seasonalDatas_alt[currentItemKey]) {
        sportsDistrict_handlers.handleSet_districtSeasonalDataExisting(currentItemKey)
      } else {
        sportsDistrict_handlers.handleGet_districtSeasonalData_alt(currentItemKey, itemKey, true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemKey, showDatasDuel]);

  const handleSportSeasonData = (ssd) => {
    sportsDistrict_handlers.handleStartUpdate()
    sportsDistrict_handlers.handleSet_sportSeasonData(ssd)
  }

  const contentItem = (ss, sportSeason) => <Label onClick={(e) => { handleSportSeasonData(ss) }} color='blue'>{sportSeason}</Label>

  const content_sportsWithYears = (data) => {
    const divs = []
    if (data) {
      Object.keys(data).forEach(sportName => {
        const sportsSeason = data[sportName]
        const subDivs = []
        Object.keys(sportsSeason).forEach(sportSeason => {
          const ss = sportsSeason[sportSeason]
          subDivs.push(contentItem(ss, sportSeason))
        })
        divs.push(<div>
          <div>{sportName}</div>
          <div>{subDivs}</div>
        </div>)
      })
    }
    return divs
  }

  const content_json = (headerText) => <Wrapper
    header={headerText}
    content={<JsonViewer json={sportSeasonMatches} name={_sportAndYear} />}
    wrapperType={wrapperTypes.padded}
    updating={updating}
  />

  const content_split = (data, headerText) => <div className='table-split-container'>
    <div> {content_sportsWithYears(data)}</div>
    {sportSeasonMatches && content_json(headerText)}
  </div>

  const content = () => {
    if (showDatasDuel) {
      return <div className='table-split-container'>
        {content_split(_seasonalData, 'Normal')}
        {content_split(_seasonalDataAlt, 'Alt')}
      </div>
    } else {
      return content_split(_seasonalData, 'Normal')
    }
  }

  const wrapper = () => <Wrapper
    content={_seasonalData ? content() : <div>No Seasonal Matches</div>}
    wrapperType={wrapperTypes.paddedFooter}
    css={{ header: 'bcg', content: 'sports-seasons' }}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Sports Season'}
    handleCancel={handleCancel}
  />

  return fromDashboard ? wrapper() : fullPageWrapper()

}

export default SeasonalData