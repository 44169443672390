import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Icon, Label, Menu, Segment, Sidebar } from 'semantic-ui-react'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { uniqueKey } from '../../../../global/common/keys'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import { appFormTypes } from '../../../../global/enums/appFormTypes'
import { clickOriginTypes } from '../../../../global/enums/globalEnums'
import { openExternal } from '../../../../global/viewSettings/helpers/settingsLinks'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import TransitionWrapper from '../../../../global/wrappers/TransitionWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import CreateSports from '../../create/CreateSports'

const AppDataTransferDirectWithProvider = React.lazy(() => import('../../../../global/pageItem/modification/dataModifications/AppDataTransferDirect'));
const JsonViewer = React.lazy(() => import('../../../../global/components/viewers/JsonViewer'));
const MatchDataUpdate = React.lazy(() => import('../matches/MatchDataUpdate'));
const MatchPlayoffUpdate = React.lazy(() => import('../matches/MatchPlayoffUpdate'));
const OrganizationSchools = React.lazy(() => import('../../create/subs/OrganizationSchools'));
const SeasonalData = React.lazy(() => import('../../create/subs/SeasonalData'));
const SeasonRankingsUpdate = React.lazy(() => import('../matches/SeasonRankingsUpdate'));
const SportsDataIssues = React.lazy(() => import('../../create/subs/SportsDataIssues'));
const SportsDataSidebar = React.lazy(() => import('../../create/SportsDataSidebar'));
const UiDashboardForm = React.lazy(() => import('../../../../global/pageItem/modification/dataModifications/UiDashboardForm'));
const UiSubAction = React.lazy(() => import('../../../../global/pageItem/modification/dataModifications/UiSubAction'));
const ValidateSports = React.lazy(() => import('../../create/subs/ValidateSports'));

export const _sdm = {
  sports: {
    menu: 'menu',
    glsTeam: 'glsTeam',
    accessTeams: 'accessTeams',
    // backupSeasons: 'backupSeasons',
    createPlayoff: 'createPlayoff',
    createSeason: 'createSeason',
    createSportsTeams: 'createSportsTeams',
    editSeason: 'editSeason',
    editSeasonDetails: 'editSeasonDetails',
    editTryoutRoster: 'editTryoutRoster',
    news: 'news',
    playoffPendingMatches: 'playoffPendingMatches',
    seasonRankings: 'seasonRankings',
    seasonResults: 'seasonResults',
    sportsSettings: 'sportsSettings',
    // seasonScores: 'seasonScores',
    sportsData: 'sportsData',
    teamRosters: 'teamRosters',
    tournamentResults: 'tournamentResults',
  },
  schools: {
    menu: 'menu',
    sportsDashboard: 'sportsDashboard',
  },
  district: {
    menu: 'menu',
    glsAccess: 'glsAccess',
    glsDistrict: 'glsDistrict',
    glsScores: 'glsScores',
    accessSchools: 'accessSchools',
    accessSport: 'accessSport',
    appUserCount: 'appUserCount',
    contactsDistrict: 'contactsDistrict',
    contactsSchools: 'contactsSchools',
    dataIssues: 'dataIssues',
    districtSchools: 'districtSchools',
    sportsDashboard: 'sportsDashboard',
    sportsData: 'sportsData',
    transferData: 'transferData',
    updateDistrictSeasonScores: 'updateDistrictSeasonScores',
  },
  organization: {
    menu: 'menu',
    organizationSchools: 'organizationSchools'
  }
}

const _caption = 'Sports Dashboard'

const SportsDashboard = (props) => {

  const { handleCancel } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states, handlers } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { analytics_handlers } = handlers ?? {}
  const { pathModes, pathViews } = paps_state ?? {}
  const { handleLogging_event } = analytics_handlers ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { currentUserCounts, currentUserCount } = sportsDistrict_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { latestSeason, sportsKey, _sportsModeKey, parentDataKeys, latestSeason_full } = sportsSeason_state ?? {}
  const { matches_seasonal: pks_matches_seasonal } = parentDataKeys ? parentDataKeys : {}

  const [captions, setCaptions] = useState({ topper: _caption, topper2: 'Sports Data' })
  const [activeMenuItems, setActiveMenuItems] = useState({})
  const [mode, setMode] = useState()
  const [sportsDataMode, setSportsDataMode] = useState()

  useEffect(() => {
    sportsDistrict_handlers && sportsDistrict_handlers.handleGet_currentUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleLogging_event && handleLogging_event("screen_view", {
      screen_name: "SportsDashboard", // Replace with your screen name or page identifier
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    let _menuItems = {}
    let modes = {}
    let firstMode = _sdm.sports.editSeason;

    if (pathModes.sport) {
      modes = _sdm.sports
      firstMode = _sdm.sports.menu
    } else if (pathModes.school) {
      modes = _sdm.schools
      firstMode = _sdm.schools.menu
    } else if (pathModes.district) {
      modes = _sdm.district
      firstMode = _sdm.district.menu
    } else if (pathModes.organization) {
      modes = _sdm.organization
      firstMode = _sdm.organization.menu
    }

    Object.keys(modes).forEach(k => {
      _menuItems[k] = k
    })

    setSportsDataMode(firstMode)
    setCaptions({ topper: _caption })
    setActiveMenuItems(_menuItems)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsKey, _sportsModeKey, currentUserCount]);

  const handleMode = (adm) => setMode(adm)

  const handleOpenDb = (key, value) => {
    openExternal.firebaseDb(pathViews, '_' + key, value)
  }

  const handleDataMode = (adm) => {
    const _adm = adm ? adm : _sdm.district.glsScores
    setSportsDataMode(_adm)
    setCaptions({ topper: _caption })
    setMode()
  }

  const handleClose = () => {
    if (pathModes.sport) {
      setSportsDataMode(_sdm.sports.editSeason)
    } else if (pathModes.district) {
      setSportsDataMode(_sdm.district.sportsDashboard)
    } else if (pathModes.organization) {
      setSportsDataMode(_sdm.organization.menu)
    }
  }

  const getIcon = (key) => {

    let _icon = 'file alternate outline'

    switch (key) {
      case _sdm.district.accessSchools:
      case _sdm.district.accessSport:
      case _sdm.district.contactsDistrict:
      case _sdm.district.contactsSchools:
      case _sdm.district.glsScores:
      case _sdm.district.sportsData:
      case _sdm.district.updateDistrictSeasonScores:
      case _sdm.sports.accessTeams:
      case _sdm.sports.glsTeam:
      case _sdm.sports.news:
      case _sdm.sports.teamRosters:
      case _sdm.sports.tournamentResults:
        _icon = 'list'
        break;

      default:
      // nothing
    }

    return _icon
  }

  /**
   * 
   * @returns a menu of with a list of the activeMenuItems
   */
  const menu_left = () => {
    const mis = []
    Object.keys(activeMenuItems).forEach(key => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', key)} onClick={() => { handleDataMode(key) }}>{_.startCase(key)}<Icon name={getIcon(key)} /></Menu.Item>)
    })
    mis.push(<Menu.Item key={uniqueKey('dv', 'auc')} onClick={() => { handleDataMode(_sdm.district.appUserCount) }}>{'Current Users Count: ' + currentUserCount}</Menu.Item>)
    return <TransitionWrapper>
      <Menu vertical fluid inverted className={'av-menu sd'}>{mis}</Menu>
    </TransitionWrapper>
  }

  const sidebar_left = (visible) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='vsr-container sports'
      onHide={() => handleMode()}
    >
      {menu_left()}
    </Sidebar>
  )

  const modeButton = () => {
    const btns = [
      { caption: _.startCase(sportsDataMode), oc: handleMode, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'black'}
    />
  }

  const dropdown = () => {

    if (parentDataKeys) {
      // const _options = _.map(parentDataKeys, (item, key) => (
      //   { description: item, text: key }
      // ))
      const ddis = _.map(parentDataKeys, (item, key) => {
        switch (key) {
          case 'details':
            break;
          default:
            return <Dropdown.Item description={item} text={key} onClick={() => { handleOpenDb(key, item) }} />
        }
      })

      return <Dropdown
        text='Parent Data Keys'
        floating
        labeled
        button
        icon='filter'
        className='icon mini ddl-full black'
        direction='left'
      // options={_options}
      >
        <Dropdown.Menu>
          {ddis}
        </Dropdown.Menu>
      </Dropdown>
    }
  }

  const label_icon = (icon, text) => <Label color='blue'>
    <Icon name={icon} /> {text}
  </Label>

  const statusDiv = () => <div>
    {label_icon('trophy', _.startCase(_sportsModeKey))}
    {label_icon('key', sportsKey)}
    {label_icon('sitemap ', pks_matches_seasonal)}
    {label_icon('users', currentUserCount)}
    {label_icon('calendar outline', latestSeason_full)}
  </div>

  const content = () => {

    switch (sportsDataMode) {

      case _sdm.district.menu:
      case _sdm.schools.menu:
      case _sdm.sports.menu:
        return <Segment basic>Please select a menu item</Segment>

      case _sdm.sports.sportsSeasons:
        return <SeasonalData fromDashboard={true} sportsKey={sportsKey} />

      case _sdm.district.accessSchools:
      case _sdm.district.accessSport:
      case _sdm.district.contactsDistrict:
      case _sdm.district.contactsSchools:
      case _sdm.district.glsScores:
      case _sdm.district.sportsData:
      case _sdm.district.updateDistrictSeasonScores:
      case _sdm.sports.accessTeams:
      case _sdm.sports.glsTeam:
      case _sdm.sports.news:
      case _sdm.sports.teamRosters:
      case _sdm.sports.tournamentResults:
        return <SportsDataSidebar sportsDataMode={sportsDataMode} forSportsManagement={true} />

      case _sdm.district.dataIssues:
        return <SportsDataIssues fromDashboard={true} />

      case _sdm.district.sportsDashboard:
        return <ValidateSports fromDashboard={true} />

      case _sdm.district.glsScores:
      case _sdm.district.glsAccess:
      case _sdm.district.glsDistrict:
        return <UiDashboardForm sportsDataMode={sportsDataMode} forSportsManagement={true} />

      case _sdm.sports.playoffPendingMatches:
        return <MatchPlayoffUpdate />

      case _sdm.sports.seasonResults:
        return <MatchDataUpdate forSportsManagement={true} sportsDataMode={sportsDataMode} />

      case _sdm.sports.seasonRankings:
        return <SeasonRankingsUpdate forSportsManagement={true} sportsDataMode={sportsDataMode} />

      // case _sdm.sports.backupSeasons:
      case _sdm.sports.createPlayoff:
      case _sdm.sports.createSeason:
      case _sdm.sports.createSportsTeams:
      case _sdm.sports.editSeasonDetails:
      case _sdm.sports.editTryoutRoster:
      case _sdm.sports.editSeason:
      case _sdm.sports.sportsSettings:
        return <CreateSports
          fromDashboard={true}
          subActionType={sportsDataMode}
          latestSeason={latestSeason}
          handleCancel={handleClose}
        />

      case _sdm.district.transferData:
        return <AppDataTransferDirectWithProvider clickOriginType={clickOriginTypes.dataViewer} appFormTransferType={appFormTypes.transferDataDirect} />

      case _sdm.district.districtSchools:
      case _sdm.organization.organizationSchools:
        return <OrganizationSchools sportsDataMode={sportsDataMode} />

      case _sdm.district.appUserCount:
        return <JsonViewer json={currentUserCounts} name={'App User Count:' + currentUserCount} />

      default:
        return <div>{'sportsDataMode ' + sportsDataMode + ' is not recognized'}</div>
    }
  }

  const header = () => <div className={'header-flex'}>
    {modeButton()}
    {dropdown()}
  </div>

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ container: 'wrp' }}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_left(mode ? true : false)}
    <Sidebar.Pusher dimmed={mode ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleCancel}
    topperCaption={captions.topper}
    topperCaption2={captions.topper2}
    fullMode={true}
    css={{ container: 'sports-dashboard' }}
  />

  return fullPageWrapper()

}

export default SportsDashboard