import { where } from 'firebase/firestore';
import _ from 'lodash';
import { dispatchProps } from '../../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { convertHelpers } from '../../../../../global/common/convert';
import { dataFix } from '../../../../../global/common/dataFix';
import { formatItem, formatTypes } from '../../../../../global/common/dateFormatting';
import { addParentKeysToDataItem } from '../../../../../global/firestoreData/appData/ammendData';
import { createRefPath, createRefPath_event } from '../../../../../global/firestoreData/appData/appRefPaths';
import { fs_db } from '../../../../../global/firestoreData/appData/fsAppData';
import { fs_add_doc, fs_delete_doc, fs_set_doc, fs_update_doc } from '../../../../../global/firestoreData/appData/fsData';
import { getSportsYear } from '../../../../../global/firestoreData/helpers/years';
import { matchStatusTypes } from '../SportsDataReducer';
import { _matchSeasonalCollection } from '../SportsSeasonReducer';
import { _sportCollections } from '../SportsSidebarReducer';
import { sportsHelpers } from './sportsHelpers';

const _seasonalCollectionName = '_seasonals'

export const _deleteMatchProps = [
  '_itemKey',
  '_matchCollectionKey',
  '_matchStatusTypes',
  '_results_fromScores',
  '_existingInfo',
  '_exists',
  '_full',
  '_results',
  '_status',
  'isPast',
  'isToday',
  'key',
  'ls',
  'matchName',
  'setsToWin',
  'startDateUTC',
  'startTimeUTC',
]

export const _deleteMatchResultProps = [
  '_results_fromScores',
  '_results',
  '_status',
  'match_complete_possible',
  'scoreDisplay_scores',
  'scoreDisplay',
  'scoreDisplays',
  'scoresCompleted',
  'setWinners',
  'tempScoreCount',
  'winner',
]

const _pksScores = ['organizations', 'districts', 'sports', 'sportsYears']


export const seasonalUpdateTypes = {
  allStarTeams: 'allStarTeams',
  athletes: 'athletes',
  backupMatches: 'backupMatches',
  details: 'details',
  googleLinks: 'googleLinks',
  history: 'history',
  matches: 'matches',
  matchesCreate: 'matchesCreate',
  matchesList: 'matchesList',
  playoffBracket: 'playoffBracket',
  playoffDates: 'playoffDates',
  playoffBrackets: 'playoffBrackets',
  playoffSchedule: 'playoffSchedule',
  playoffSeeds: 'playoffSeeds',
  playoffTeams: 'playoffTeams',
  rankings: 'rankings',
  rosters: 'rosters',
  rtMatches: 'rtMatches',
  scheduleDates: 'scheduleDates',
  sectionCounts: 'sectionCounts',
  sectionCrossovers: 'sectionCrossovers',
  sectionDates: 'sectionDates',
  sportsSettings: 'sportsSettings',
  sports: 'sports',
  staff: 'staff',
  sportsAccess: 'sportsAccess',
  teams: 'teams',
  teamRosters: 'teamRosters',
  tournamentResults: 'tournamentResults',
  news: 'news',
}

export const seasonalUpdateCollections = {
  allStarTeams: 'allStarTeams',
  athletes: 'athletes',
  backupMatches: '_backup_matches',
  details: '_seasonals',
  googleLinks: 'googleLinks',
  history: '_history',
  matches: '_matches',
  matchesCreate: 'matchesCreate',
  matchesList: 'matchesList',
  playoffBracket: '_playoffs',
  playoffDates: '_playoffs',
  playoffBrackets: '_playoffs',
  playoffSchedule: '_playoffs',
  playoffSeeds: '_playoffs',
  playoffTeams: '_playoffs',
  rankings: '_rankings',
  rosters: '_rosters',
  scheduleDates: '_seasonals',
  sectionCounts: '_seasonals',
  sectionDates: '_seasonals',
  sportsSettings: '_seasonals',
  sports: '_sports',
  staff: '_staff',
  sportsAccess: 'sportsAccess',
  teams: '_teams',
  tournamentResults: '_tournaments',
  news: '_news',
}

export const allowSeasonalUpdates = {
  any: false,
  allStarTeams: false,
  athletes: true,
  backupMatches: false,
  details: true,
  googleLinks: false,
  history: false,
  matches: true,
  matchesCreate: true,
  matchesList: true,
  news: false,
  parentKeySportsData: true,
  playoffBracket: false,
  playoffBrackets: true,
  playoffDates: true,
  playoffSchedule: false,
  playoffSeeds: true,
  playoffTeams: true,
  rankings: true,
  rosters: true,
  rtMatches: true,
  scheduleDates: true,
  sectionCounts: true,
  sectionDates: true,
  sports: true,
  sportsAccess: true,
  sportsSettings: false,
  staff: false,
  teamRosters: true,
  teams: true,
  tournamentResults: true,
}

export const allowSportsUpdates = {
  seasonal: false,
  matches: true,
  athletes: false
}

const _seasonalCollections = {
  districts: 'districts',
  organizations: 'organizations',
  schools: 'schools',
  sports: 'sports',
  teams: 'teams',
  tournaments: 'tournaments',
}

const modifyParentKeys = (pks) => {
  if (!pks) return false;
  _.each(pks, (pk, key) => {
    if (!_pksScores.includes(key)) {
      delete pks[key]
    }
  })
}

const checkParentKeys = (pks) => {
  let allow = true
  if (Object.keys(pks).length === _pksScores.length) {
    _pksScores.forEach(pk => {
      if (!pks[pk]) {
        allow = false
      }
    })
    return allow
  } else {
    return false
  }
}

export const seasonalHelpers = {
  getUpdateProps: (sportPermissions, pathViews, pks, callback, collectionName, documentFieldName, data, opts, dataInfos) => getUpdateProps(sportPermissions, pathViews, pks, callback, collectionName, documentFieldName, data, opts, dataInfos),
  ammendUpdateProps: (updateProps, pks, callback) => ammendUpdateProps(updateProps, pks, callback),
  ammendUpdateNameProps: (updateProps, collectionName, documentFieldName, data, opts) => ammendUpdateNameProps(updateProps, collectionName, documentFieldName, data, opts),
  backupSeasonal: (database_fns, pathViews, dataParents, altSeasonalName, dataRestrictions, dispatch) => backupSeasonal(database_fns, pathViews, dataParents, altSeasonalName, dataRestrictions, dispatch),
  cleanDataToUpdate: (dataToUpdate) => cleanMatchDataToUpdate(dataToUpdate),
  deleteSeasonalData: (pathViews, dataParents, dataType, data, callback, altData, altSeasonalName) => deleteSeasonalData(pathViews, dataParents, dataType, data, callback, altData, altSeasonalName),
  getPks: (pathViews, sportsYears, sportsKey, ignores) => getPks(pathViews, sportsYears, sportsKey, ignores),
  getSeasonalData: (database_fns, pathViews, sportsKey, latestSeason, callback, sportsSeason_handlers, opts) => getSeasonalData(database_fns, pathViews, sportsKey, latestSeason, callback, sportsSeason_handlers, opts),
  getSports_collectionItem: (database_fns, pathViews, parentKeys, collectionName, returnFirstObject, cbProps, callback) => getSports_collectionItem(database_fns, pathViews, parentKeys, collectionName, returnFirstObject, cbProps, callback),
  isValidPks: (pks) => isValidPks(pks),
  updateAllSeasonalMatchesByLevel: (updateProps) => updateAllSeasonalMatchesByLevel(updateProps),
  updateSeasonalMatches: (seasonalProps) => updateSeasonalMatches(seasonalProps),
  updateSeasonalMatchesByLevel: (updateProps) => updateSeasonalMatchesByLevel(updateProps),
  updateSeasonalOrGlobal: (seasonalUpdateType, updateProps, data_new) => updateSeasonalOrGlobal(seasonalUpdateType, updateProps, data_new),
  updateSeasonalPlayoffMatchesByLevel: (_playoffMatchesFromMatches, pathViews, pks) => updateSeasonalPlayoffMatchesByLevel(_playoffMatchesFromMatches, pathViews, pks),
  updateSportsCollectionItemsWithParentKeys: (seasonalUpdateType, updateProps, collectionName, data) => updateSportsCollectionItemsWithParentKeys(seasonalUpdateType, updateProps, collectionName, data),
}

const getUpdateProps = (sportPermissions, pathViews, pks, callback, collectionName, documentFieldName, data, opts, dataInfos) => {
  const updateProps = { sportPermissions, pathViews }
  if (pks) { updateProps.pks = pks }
  if (callback) { updateProps.callback = callback }
  if (collectionName) { updateProps.collectionName = collectionName }
  if (documentFieldName) { updateProps.documentFieldName = documentFieldName }
  if (data) { updateProps.data = data }
  if (opts) { updateProps.opts = opts }
  if (dataInfos) { updateProps.dataInfos = dataInfos }
  updateProps.forSeasonal = !updateProps.collectionName ? true : false
  return updateProps
}

const ammendUpdateProps = (updateProps, pks, callback) => {
  updateProps.pks = pks
  updateProps.callback = callback
}

const ammendUpdateNameProps = (updateProps, collectionName, documentFieldName, data, opts) => {
  if (collectionName) { updateProps.collectionName = collectionName }
  if (documentFieldName) { updateProps.documentFieldName = documentFieldName }
  if (data) { updateProps.data = data }
  if (opts) { updateProps.opts = opts }
  updateProps.forSeasonal = !updateProps.collectionName ? true : false
}

/**
 * returns data from a collection based on the seasonalCollectionName/altSeasonalName where the parentKey constraints match
 * @param {array} pathViews 
 * @param {array} dataParents 
 * @param {boolean} useSeasonals 
 * @param {function} callback 
 * @param {function} sportsSeason_handlers 
 * @param {string} altSeasonalName 
 * @returns data from a collection based on the seasonalCollectionName/altSeasonalName where the parentKey constraints match
 * pojoSports
 * 
 */
const getSeasonalData = async (database_fns, pathViews, sportsKey, latestSeason, callback, sportsSeason_handlers, opts) => {

  const { altSeasonalName, getSeasonalMatchesOnly } = opts ?? {}

  const collectionName = altSeasonalName ? altSeasonalName : _seasonalCollectionName
  const sy = getSportsYear(latestSeason)

  const pks = getPks(pathViews, sy, sportsKey)

  if (pks) {

    const parentKeys = {}

    const _baseRef = createRefPath_event(pathViews, [collectionName])
    const wheres = []

    Object.keys(pks).forEach(pk => {
      wheres.push(where('parentKeys.' + pk, '==', pks[pk]))
      parentKeys[pk] = pathViews[pk]
    })

    // AWAIT THE DATA
    const { item: retData, fk } = await database_fns.get_data({ refPath: _baseRef, wheres, opts: { returnFirstObject: true, ignoreId: true, returnKey: true } })

    if (getSeasonalMatchesOnly) {
      const refPathM = createRefPath_event(pathViews, [collectionName, fk, 'matches', 'matches'])
      const { item: retData_n } = await database_fns.get_data({ refPath: refPathM, opts: { returnFirstObject: true, ignoreId: true, returnKey: true } })
      callback(retData_n)
      return
    }

    if (retData && retData.id) {
      const _baseRef2 = createRefPath([retData.id, 'details'], _baseRef)
      const retData2 = await database_fns.get_data({ refPath: _baseRef2, opts: { ignoreId: true } })
      if (callback) {
        callback(retData2, fk)
        return retData2
      } else {
        return retData2
      }
    }

    if (retData && retData._itemKey) {
      const _baseRef2 = createRefPath([retData._itemKey, 'details'], _baseRef)
      const retData2 = await database_fns.get_data({ refPath: _baseRef2 })
      if (callback) {
        callback(retData2, fk)
        return retData2
      } else {
        return retData2
      }
    }

    if (sportsSeason_handlers) {

      const si = retData

      if (si && si.matches && si.parentKeys) {
        addParentKeysToDataItem(si.parentKeys, si.matches)
      }
      if (si && si.teams && si.parentKeys) { addParentKeysToDataItem(si.parentKeys, si.teams) }
      if (altSeasonalName) {
        sportsSeason_handlers.setPlayoffMatches(si)
      } else {
        sportsSeason_handlers.handleSet_sportsSeasonData(si)
      }
    } else {
      if (callback) {
        callback(retData, fk)
      } else {
        return retData
      }
    }
  } else {
    if (callback) {
      callback(null)
    } else {
      return null
    }
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {object} pks 
 * @param {string} documentFieldName 
 * @param {object} data 
 * @param {string} collectionName ** with _
 * @param {function} callback 
 * @description Updates the `_seasonalCollectionName` or the collectionName prefixed with an `_`
 */
const updateSeasonalOrGlobal = async (seasonalUpdateType, updateProps, data_new) => {

  const {
    callback,
    collectionName,
    data,
    dataInfos,
    documentFieldName,
    forSeasonal,
    opts,
    pathViews,
    pks,
    sportPermissions,
    database_fns,
  } = updateProps

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]

  const { dtu, dta } = dataInfos ? dataInfos : {}

  console.log('-----------')
  console.log('allowUpdate', allowUpdate, seasonalUpdateType)
  // clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports/_matches_seasonal/5T1gT83TrTywHchxkqGD/undefined/1A
  const { subCollectionName, subDocumentKey, fullData, ignoreSeason, updateToMain } = opts ?? {}

  if (updateToMain) {
    // pathViews, collectionName, parentKeys, data
    if (allowUpdate) {
      updateSportsCollectionItemsWithParentKeys(seasonalUpdateType, updateProps, documentFieldName, dtu ? dtu : data, callback)
    }
  } else {
    if (pks.sportsYears || ignoreSeason) {

      let _collectionName = forSeasonal ? _seasonalCollectionName : '_' + collectionName
      let _subCollectionName;

      switch (_collectionName) {
        case '_matches':
          _subCollectionName = 'matches'
          break;
        case '_playoffMatches':
          _subCollectionName = 'playoffMatches'
          break;
        case '_scores':
          _subCollectionName = 'scores'
          break;
        case '_playoffScores':
          _subCollectionName = 'playoffScores'
          break;
        case '_backup_matches':
          _subCollectionName = 'matches'
          break;
        default:
        // nothing
      }

      switch (_collectionName) {
        case '_matches':
        case '_playoffMatches':
        case '_scores':
        case '_playoffScores':
          _collectionName = _sportCollections._matches_seasonal
          break;
        default:
        // nothing
      }

      const _sportsYearCollectionItem = await getSports_collectionItem(database_fns, pathViews, pks, _collectionName, true)

      if (_sportsYearCollectionItem && !_.isEmpty(_sportsYearCollectionItem)) {
        const { _itemKey } = _sportsYearCollectionItem

        let _refPath = subCollectionName ? createRefPath_event(pathViews, [_collectionName, _itemKey, subCollectionName, subCollectionName]) : createRefPath_event(pathViews, [_collectionName, _itemKey])

        if (_subCollectionName) {
          _refPath = createRefPath_event(pathViews, [_collectionName, _itemKey, _subCollectionName, subDocumentKey])
        }
        // switch (_collectionName) {
        //   case '_' + _matchSeasonalCollection:
        //     _refPath = createRefPath_event(pathViews, [_collectionName, _itemKey, _subCollectionName, subDocumentKey])
        //     break;
        //   default:
        //   // nothing
        // }

        const _data = fullData ? fullData : data

        switch (seasonalUpdateType) {
          case seasonalUpdateTypes.playoffSeeds:
          case seasonalUpdateTypes.playoffTeams:
          case seasonalUpdateTypes.playoffBrackets:
            const _dataToSet = dta ? dta : _data
            const dataToSet = documentFieldName ? { [documentFieldName]: _dataToSet } : _dataToSet
            if (allowUpdate) {
              fs_set_doc(_refPath, dataToSet, true, callback)
            } else {
              console.log('Data to UPDATE', '_refPath', _refPath, dataToSet)
            }
            break;

          case seasonalUpdateTypes.matchesCreate:
          case seasonalUpdateTypes.matchesList:
          case seasonalUpdateTypes.backupMatches:

            const _matchesToUpdate = dtu ? dtu : _data

            convertHelpers.createItemKeys(_matchesToUpdate)

            const _levelsToUpdate = _.groupBy(_matchesToUpdate, 'levels')

            Object.keys(_levelsToUpdate).forEach(async levelKey => {
              const _levelMatches = _levelsToUpdate[levelKey]
              const _refPathS = createRefPath_event(pathViews, [_collectionName, _itemKey, _subCollectionName, levelKey])
              await removeAndUpdateLevelMatches(_refPathS, documentFieldName, _levelMatches, allowUpdate)
            })
            break;

          default:
            const _dataToUpdate = dtu ? dtu : _data
            let dataToUpdate = documentFieldName ? { [documentFieldName]: _dataToUpdate } : _dataToUpdate
            dataToUpdate = dataFix.removeEmpties(dataToUpdate)
            if (allowUpdate) {
              console.log('Data to UPDATE', _refPath, dataToUpdate)
              allowUpdate && fs_update_doc(_refPath, dataToUpdate, callback)
            } else {
              console.log('Data to UPDATE', _refPath, dataToUpdate)
            }
        }

      } else {

        // NEW DATA
        const _refPath = createRefPath_event(pathViews, [_collectionName])

        let _data = dta ? dta : data

        switch (_collectionName) {
          case '_matches_seasonal':
          case '_backup_matches':

            // add to the main doc 
            const dataToAdd_m = { parentKeys: pks }
            if (allowUpdate) {
              const res = await fs_add_doc(_refPath, dataToAdd_m)
              if (res && res.id) {
                await createMatchesNew(allowUpdate, pathViews, documentFieldName, _data, _collectionName, res.id)
                callback()
              }
            } else {
              console.log('_refPath', _refPath)
              console.log('dataToAdd_m', dataToAdd_m)
            }
            break;
          default:
            delete _data._itemKey
            const dataToAdd = getDataToAdd(pks, collectionName, documentFieldName, _data, fullData, data_new)
            if (allowUpdate) {
              allowUpdate && fs_add_doc(_refPath, dataToAdd, callback)
            } else {
              try {
                console.log('Data to ADD', _refPath, dataToAdd)
              } catch (error) {

              }
            }
        }
      }
    }
  }
}

const updateAllSeasonalMatchesByLevel = async (updateProps) => {
  const { matchDataUpdates } = updateProps
  Object.keys(matchDataUpdates).forEach(async mdKey => {
    switch (mdKey) {
      case matchStatusTypes.cancelled:
      case matchStatusTypes.dateChanged:
      case matchStatusTypes.new:
      case matchStatusTypes.postponed:
      case matchStatusTypes.scoresChanged:
      case matchStatusTypes.scoreChanged:
        const _matchDateUpdates = matchDataUpdates[mdKey]
        if (_matchDateUpdates) {
          updateProps.dataToUpdate = _matchDateUpdates
          await updateSeasonalMatchesByLevel(updateProps)
        }
        break;
      default:
      // nothing
    }
  })
}

const updateSeasonalMatchesByLevel = async (updateProps) => {

  const {
    callback,
    dataToUpdate,
  } = updateProps

  const dataLength = Object.keys(dataToUpdate).length
  const _rstz = []

  const cb = (res) => {
    _rstz.push(res)
    if (_rstz.length === dataLength) {
      callback({ done: true })
    }
  }

  Object.keys(dataToUpdate).forEach(async levelKey => {
    const _dataToUpdate = dataToUpdate[levelKey]
    const seasonalProps = { ...updateProps, levelKey, dataToUpdate: _dataToUpdate }
    await updateSeasonalMatches(seasonalProps)
  })
}

const updateSeasonalPlayoffMatchesByLevel = async (_playoffMatchesFromMatches, pathViews, pks) => {

  const groupedPlayoffMatches = _.groupBy(_playoffMatchesFromMatches, 'levels')

  _.each(groupedPlayoffMatches, (levelMatches, levelKey) => {
    const matches = {}
    _.each(levelMatches, (match) => {
      const { _itemKey } = match
      matches[_itemKey] = match
    })
    const seasonalProps = {
      seasonalUpdateType: seasonalUpdateTypes.playoffBrackets,
      pathViews,
      pks,
      dataToUpdate: matches,
      collectionName: _matchSeasonalCollection,
      replaceData: true,
      levelKey,
      forPlayoffs: true
    }
    updateSeasonalMatches(seasonalProps)
  })
}

const updateSeasonalMatches = async (seasonalProps) => {

  const {
    callback,
    collectionName,
    dataToUpdate,
    forPlayoffs,
    levelKey,
    pathViews,
    pks,
    replaceData,
    seasonalUpdateType,
    useMatchField,
    useScoringCollection,
    sportPermissions,
    database_fns,
  } = seasonalProps

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]
  const _subCollection = forPlayoffs ? 'playoffMatches' : 'matches'
  const _subCollection_scores = forPlayoffs ? 'playoffScores' : 'scores'

  console.clear()
  console.log('--- updateSeasonalMatches ---')
  console.log('allowUpdate', allowUpdate, seasonalUpdateType, levelKey)

  if (pks.sportsYears) {

    const _collectionName = collectionName ? '_' + collectionName : _seasonalCollectionName

    modifyParentKeys(pks)
    const allowPks = checkParentKeys(pks)

    if (allowPks) {
      const _sportsYearCollectionItem = await getSports_collectionItem(database_fns, pathViews, pks, _collectionName, true)

      if (_sportsYearCollectionItem && !_.isEmpty(_sportsYearCollectionItem)) {

        const { _itemKey } = _sportsYearCollectionItem
        const _refPath = createRefPath_event(pathViews, [_collectionName, _itemKey, _subCollection, levelKey])

        cleanMatchDataToUpdate(dataToUpdate)

        const _cb_local = (opts) => {
          if (useScoringCollection) {
            const _refPath_scores = createRefPath_event(pathViews, [_collectionName, _itemKey, _subCollection_scores, levelKey])
            const _scores = sportsHelpers.getMatchesScores(dataToUpdate, forPlayoffs ? 'playoffScores' : 'scores')
            // console.log('_refPath_scores', _refPath_scores)
            // console.log('_scores', _scores)
            if (allowUpdate) {
              fs_update_doc(_refPath_scores, _scores, callback)
            } else {
              callback && callback(opts)
            }
          } else {
            callback && callback(opts)
          }
        }

        if (allowUpdate) {
          if (replaceData) {
            const _dataToUpdate = { playoffMatches: dataToUpdate }
            fs_set_doc(_refPath, _dataToUpdate, false, _cb_local)
          } else {
            const result = await fs_db.get_doc({ refPath: _refPath })
            if (_.isEmpty(result) || useMatchField) {
              const m = createMatchesFromDot(dataToUpdate)
              const _dataToUpdate = { matches: m }
              console.log('level does not exists', _dataToUpdate)
              await fs_update_doc(_refPath, _dataToUpdate, _cb_local)
            } else {
              await fs_update_doc(_refPath, dataToUpdate, _cb_local)
            }
            // fs_update_doc(_refPath, dataToUpdate, callback)
          }
        } else {
          console.log('_sportsYearCollectionItem', _sportsYearCollectionItem._itemKey)
          console.log('_refPath', _refPath)
          console.log('dataToUpdate', dataToUpdate)
          _cb_local({ type: dispatchProps.success, callback: _cb_local })
        }
      } else {
        const _refPath = createRefPath_event(pathViews, [_collectionName])
        const dataToAdd = {
          parentKeys: pks,
          [collectionName]: dataToUpdate,
        }
        console.log('_refPath', seasonalUpdateType, _refPath)
        console.log('dataToAdd', dataToAdd)
        allowUpdate && fs_add_doc(_refPath, dataToAdd, callback)
      }
    } else {
      callback({ type: dispatchProps.error, error: 'Bad Pks', dispatch: callback })
    }
  }
}

const createMatchesNew = async (allowUpdate, pathViews, documentFieldName, matchesToUpdate, collectionName, itemKey) => {

  if (itemKey) {
    convertHelpers.createItemKeys(matchesToUpdate)

    const _levelsToUpdate = _.groupBy(matchesToUpdate, 'levels')

    await Promise.all(
      _.map(_levelsToUpdate, async (_levelMatches, levelKey) => {
        _levelMatches = dataFix.removeEmpties(_levelMatches)
        const _refPathS = createRefPath_event(pathViews, [collectionName, itemKey, 'matches', levelKey])
        await removeAndUpdateLevelMatches(_refPathS, documentFieldName, _levelMatches, allowUpdate)
      })
    );
  } else {
    console.log('createMatchesNew Error: no itemKey',)
  }

  // Object.keys(_levelsToUpdate).forEach(async levelKey => {
  //   let _levelMatches = _levelsToUpdate[levelKey]
  //   _levelMatches = dataFix.removeEmpties(_levelMatches)
  //   const _refPathS = createRefPath_event(pathViews, [collectionName, itemKey, 'matches', levelKey])
  //   await removeAndUpdateLevelMatches(_refPathS, documentFieldName, _levelMatches, allowUpdate)
  // })
}

const createMatchesFromDot = (dataToUpdate) => {
  const _matches = {}
  Object.keys(dataToUpdate).forEach(k => {
    const _itemKey = k.replace('matches.', '')
    _matches[_itemKey] = dataToUpdate[k]
  })
  return _matches
}

const backupSeasonal = async (database_fns, pathViews, dataParents, altSeasonalName, dataRestrictions, dispatch) => {

  // https://medium.com/@BrodaNoel/how-to-backup-firebase-firestore-8be94a66138c

  const collectionName = altSeasonalName ? altSeasonalName : _seasonalCollectionName

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.backupSeasonals)) {
    dispatch && dispatch({ type: dispatchProps && dispatchProps.successAlt ? dispatchProps.successAlt : dispatchProps.error })
    return true
  }

  if (dataParents) {
    const parentKeys = {}
    dataParents.forEach(dp => {
      if (pathViews[dp]) {
        parentKeys[dp] = pathViews[dp]
      }
    })

    const sy = getSportsYear()
    parentKeys['sportsYears'] = sy

    getSeasonalForUpdate(database_fns, pathViews, dataParents, collectionName).then(seasonalItem => {
      if (seasonalItem) {
        const si = seasonalItem[Object.keys(seasonalItem)[0]]

        if (si) {
          const seasonalData = si
          const _updateRef = createRefPath_event(pathViews, [collectionName + '_backup', si.id])
          fs_set_doc(_updateRef, seasonalData, true, dispatch)
        }
      }
    })
  }
}

const deleteSeasonalData = async (pathViews, dataParents, dataType, data, callback, altData, altSeasonalName) => {

  const collectionName = altSeasonalName ? altSeasonalName : _seasonalCollectionName

  if (dataParents) {
    const parentKeys = {}
    dataParents.forEach(dp => {
      switch (dp) {
        // case 'allSportsYears':
        //   const sy = getSportsYear()
        //   parentKeys['sportsYears'] = sy
        //   break;
        default:
          if (pathViews[dp]) {
            parentKeys[dp] = pathViews[dp]
          }
      }
    })

    const sy = getSportsYear()
    parentKeys['sportsYears'] = sy

    const seasonalData = altData
      ? altData :
      {
        parentKeys,
        [dataType]: data
      }

    getSeasonalForUpdate(pathViews, dataParents, collectionName).then(seasonalItem => {
      if (seasonalItem) {
        const si = seasonalItem[Object.keys(seasonalItem)[0]]

        const _updateRef = createRefPath_event(pathViews, [collectionName])
        if (si) {
          if (altData) {
            fs_update_doc(_updateRef, altData, callback)
          } else {
            fs_set_doc(_updateRef, seasonalData, true, callback)
          }
        } else {
          fs_set_doc(_updateRef, seasonalData, false, callback)
        }
      }
    })
  }
}

const removeAndUpdateLevelMatches = async (refPath, documentFieldName, matches, allowUpdate) => {

  const callback = (r) => {
    console.log('r', r)
  }

  const _matches = {}

  _.each(matches, (m, keyOrIndex) => {
    _matches[m._itemKey] = m
  })

  const dataToUpdate = documentFieldName ? { [documentFieldName]: _matches } : _matches

  allowUpdate && fs_delete_doc(refPath).then(
    fs_update_doc(refPath, dataToUpdate, callback)
  )
}


// eslint-disable-next-line 
const ammendStartDate = (items) => {
  if (items) {
    Object.keys(items).forEach(key => {
      items[key].key = key
      if (items[key].startDateUTC) { items[key].startDate = formatItem(formatTypes.date, items[key].startDateUTC) }
      if (items[key].startTimeUTC) { items[key].startTime = '7:00 PM' }
    })
  }
}

/**
 * Gets the data based on the refPath_event and the collectionName with dataParents creating wheres
 * @param {string} pathViews 
 * @param {object} dataParents 
 * @param {string} collectionName 
 * @returns 
 */
const getSeasonalForUpdate = async (database_fns, pathViews, dataParents, collectionName) => {

  if (dataParents) {
    const _refPath = createRefPath_event(pathViews, [collectionName])
    const wheres = []

    dataParents.forEach(dp => {
      switch (dp) {
        case 'allSportsYears':
          const sy = getSportsYear()
          wheres.push(where('parentKeys.sportsYears', '==', sy))
          // firestoreRef = firestoreRef.where('parentKeys.sportsYears', '==', sy)
          break;
        default:
          if (pathViews[dp]) {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            // firestoreRef = firestoreRef.where('parentKeys.' + dp, '==', pathViews[dp])
          }
      }
    })

    const retData = await database_fns.get_data({ refPath: _refPath, wheres })
    return retData
  } else {
    return null
  }
}

/**
 * 
 * @param {object} parentKeys 
 * @param {array} dataParents 
 * @param {string} collectionName 
 * @returns the data from the collectionName or _seasonalCollectionName via callback
 */
const getSports_collectionItem = async (database_fns, pathViews, parentKeys, collectionName, returnFirstObject, cbProps, callback) => {

  const cb = (data) => {
    const cbd = {
      collectionName,
      parentKeys,
      sportsSeasonalData: data
    }
    callback(cbd, { cbProps })
  }

  const _collectionName = collectionName ? collectionName : _seasonalCollectionName

  const _refPath = createRefPath_event(pathViews, [_collectionName])

  const wheres = []

  Object.keys(parentKeys).forEach(pk => {
    switch (pk) {
      // case 'organzations':
      // case 'districts':
      // case 'sports':
      //   wheres.push(where('parentKeys.' + pk, '==', parseInt(parentKeys[pk])))
      //   break;
      case 'sportsYears':
        wheres.push(where('parentKeys.' + pk, '==', parseInt(parentKeys[pk])))
        break;
      default:
        wheres.push(where('parentKeys.' + pk, '==', parentKeys[pk]))
    }
  })

  if (database_fns) {
    if (callback) {
      database_fns.get_data({ refPath: _refPath, callback: cb, wheres, opts: { returnFirstObject } })
    } else {
      const retData = await database_fns.get_data({ refPath: _refPath, wheres, opts: { returnFirstObject } })
      return retData
    }
  } else {
    if (callback) {
      fs_db.get_data({ refPath: _refPath, callback: cb, wheres, opts: { returnFirstObject } })
    } else {
      const retData = await fs_db.get_data({ refPath: _refPath, wheres, opts: { returnFirstObject } })
      return retData
    }
  }

}

const updateSportsCollectionItemsWithParentKeys = async (seasonalUpdateType, updateProps, collectionName, data) => {
  const { callback } = updateProps
  if (data && Object.keys(data).length > 0) {
    const result = await updateSportsCollectionItemsPromise(seasonalUpdateType, updateProps, collectionName, data)
    callback && callback(result)
    return {}
  } else {
    callback && callback({})
    return {}
  }
}

const updateSportsCollectionItemsPromise = async (seasonalUpdateType, updateProps, collectionName, data) => {

  const { sportPermissions, pathViews, pks } = updateProps
  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]

  const promises = []
  Object.keys(data).forEach(k => {
    const _dataItem = data[k]
    _dataItem.parentKeys = pks
    delete _dataItem.year
    delete _dataItem.position
    delete _dataItem.uniformNumber
    const refPath = createRefPath_event(pathViews, [collectionName, k])
    if (allowUpdate) {
      promises.push(fs_set_doc(refPath, _dataItem, false, null, true))
    } else {
      console.log('refPath', refPath, _dataItem)
    }
  })
  return Promise.all(promises)
}

const getPks = (pathViews, sportsYears, sportsKey, ignores) => {
  const _pks = {}
  Object.keys(pathViews).forEach(pv => {
    let allow = true
    if (ignores && ignores.includes(pv)) {
      allow = false
    }
    if (allow) {
      switch (pv) {
        case _seasonalCollections.districts:
        case _seasonalCollections.organizations:
        case _seasonalCollections.schools:
        case _seasonalCollections.sports:
        case _seasonalCollections.teams:
        case _seasonalCollections.tournaments:
          if (pathViews[pv]) {
            _pks[pv] = pathViews[pv]
          }
          break;
        default:
        // nothing
      }
    }
  })

  if (sportsKey) { _pks['sports'] = sportsKey }
  if (sportsYears) { _pks['sportsYears'] = parseInt(sportsYears) }
  return _pks
}

const isValidPks = (pks) => (pks.districts && pks.organizations && pks.sports && pks.sportsYears) ? true : false

const cleanMatchDataToUpdate = (dataToUpdate) => {
  if (dataToUpdate) {
    Object.keys(dataToUpdate).forEach(k => {
      _deleteMatchProps.forEach(dmp => {
        delete dataToUpdate[k][dmp]
      })
      if (dataToUpdate[k].results) {
        _deleteMatchResultProps.forEach(dmp => {
          delete dataToUpdate[k].results[dmp]
        })
      }
    })
  }
}

const getDataToAdd = (pks, collectionName, documentFieldName, _data, fullData, data_new) => {
  let dataToAdd;
  if (fullData) {
    dataToAdd = fullData
  } else if (data_new) {
    if (documentFieldName) {
      dataToAdd = {
        parentKeys: pks,
        [documentFieldName]: data_new
      }
    } else {
      dataToAdd = {
        parentKeys: pks,
        [collectionName]: data_new
      }
    }
  } else {
    if (documentFieldName) {
      dataToAdd = {
        parentKeys: pks,
        [documentFieldName]: _data
      }
    } else {
      dataToAdd = {
        parentKeys: pks,
        ..._data
      }
    }
  }
  dataToAdd = dataFix.removeEmpties(dataToAdd)
  return dataToAdd
}