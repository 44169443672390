import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { createInitials } from '../../../../global/common/dataAdjust';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';
import { Keypad } from './Keypad';
import { uniqueKey } from '../../../../global/common/keys';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { _resultsProp } from '../../cnr/contexts/SportsSeasonContext';

export const _keypadTypes = {
  normal: 'normal',
  realtime: 'realtime',
  mini: 'mini',
}

const keyPadIcons = {
  down: 'arrow down',
  up: 'arrow up',
}

const WAIT_INTERVAL = 1000

/** Keypad for realtime scoring
 * @description Called only from MatchSetScoring
 */
const MatchScoreKeypad = (props) => {

  const { keypadType } = props

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true } = sportsMatch_state ?? {}
  // const { home, away, _status } = match_true ?? {}

  // sportsMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { match_edit, sportPermissions } = sportsEditMatch_state ?? {}
  const { home, away, _status } = match_edit ?? {}
  const { setsToWin } = _status ?? {}
  const _setsToWin = setsToWin ? setsToWin : 5

  // sportsMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  const { match_rt: rtm } = sportsRtMatch_state ?? {}

  const [match_rt, setMatch_rt] = useState()
  const [directScoresOn, setDirectScoresOn] = useState()
  const [currentSetNumber, setCurrentSetNumber] = useState()
  const [showUpdate, setShowUpdate] = useState()
  const [showRemove, setShowRemove] = useState()
  const [restrictAccess, setRestrictAccess] = useState()

  const _allowUpdate = sportPermissions && sportPermissions.any && sportPermissions.matches

  const [matchResults, setMatchResults] = useState()
  const { scores, setNumber, setsCompleted, match_complete_possible, setsDisplayed } = matchResults ?? {}
  const _setsCompleted = setsCompleted ? setsCompleted : 0
  const _setsDisplayed = setsDisplayed ? setsDisplayed : _setsToWin

  useEffect(() => {
    const _match = match_edit ? match_edit : match_true
    const results = _match[_resultsProp]
    setMatchResults(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match_edit]);

  useEffect(() => {
    setMatch_rt(rtm)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [rtm]);

  useEffect(() => {
    if (restrictAccess) {
      const timer = setTimeout(() => setRestrictAccess(false), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictAccess]);

  const allowItemChange = (e, rtType, setNumber) => {
    e.stopPropagation()
    setDirectScoresOn(rtType)
    if (setNumber) { setCurrentSetNumber(setNumber) }
  }

  const handleShowUpdateScore = (e) => {
    e.stopPropagation()
    setShowUpdate(!showUpdate)
  }

  const handleRtUpdateScore = (e) => {
    e.stopPropagation()
    sportsMatch_handlers.handleUpdate_rtMatch(match_rt)
  }

  const handleShowRemoveScore = (e) => {
    e.stopPropagation()
    setShowRemove(!showRemove)
  }

  const handleRtRemoveScore = (e) => {
    e.stopPropagation()
    sportsRtMatch_handlers.handleRtDelete_scores()
  }

  const handleRealTimeChange = (e, rtType, add) => {
    e.stopPropagation()
    setRestrictAccess(true)
    !restrictAccess && sportsRtMatch_handlers.handleRt_updateScore(match_rt, rtType, add, currentSetNumber)
  }

  const handleSetScoreChange = (score) => {

    let _match;

    switch (keypadType) {
      case _keypadTypes.normal:
        _match = match_edit
        break;
      case _keypadTypes.mini:
      case _keypadTypes.realtime:
        _match = rtm
        break;
      default:
      // nothing
    }

    const _scores = score ? score.split('-') : []
    const _matchToUpdate = { ..._match }
    let { setNumber: sn, results_edit } = _matchToUpdate

    if (!results_edit) { results_edit = {} }

    if (currentSetNumber) { sn = currentSetNumber }

    if (!results_edit.scores) { results_edit.scores = {} }

    results_edit.scores[sn] = {
      away: _scores[0] ? _scores[0].trim() : 0,
      home: _scores[1] ? _scores[1].trim() : 0,
    }
    _matchToUpdate.results_edit = results_edit

    if (!_matchToUpdate.scores) { _matchToUpdate.scores = {} }
    _matchToUpdate.scores[sn] = {
      away: _scores[0] ? _scores[0].trim() : 0,
      home: _scores[1] ? _scores[1].trim() : 0,
    }

    switch (keypadType) {
      case _keypadTypes.normal:
        sportsEditMatch_handlers.handleAmmend_editMatch(_matchToUpdate)
        break;

      case _keypadTypes.mini:
      case _keypadTypes.realtime:
        sportsRtMatch_handlers.handleRtChange_setScores(_matchToUpdate)
        break;
      default:
      // nothing
    }

    setDirectScoresOn()
  }

  const gc = (text) => <div color={'black'}>{text}</div>
  const gcl = (text, color, rtType, setNumber, srKey, keyy, disabled, active) => {
    let cn = 'keypad-score'
    if (active) { cn += ' blink_me_2' }
    return <div key={uniqueKey(srKey, keyy)} className={cn} color={color ? color : 'blue'} onClick={(e) => { !disabled && allowItemChange(e, rtType, setNumber) }}>{text ? text : 0}</div>
  }
  const gci = (name, rtType, add, allow) => <div color={allow && !restrictAccess ? 'blue' : 'grey'} onClick={(e) => { allow && handleRealTimeChange(e, rtType, add) }}><Icon size="big" name={name} /></div>

  const gcu = () => <div color={'black'} onClick={(e) => { handleShowUpdateScore(e) }}>{'Update'}</div>
  const gcuc = () => <div color={_allowUpdate ? 'green' : 'blue'} onClick={(e) => { handleRtUpdateScore(e) }}>{'Confirm Update'}</div>

  const gcUpdate = () => showUpdate ? gcuc() : gcu()

  const gcr = () => <div color={'black'} onClick={(e) => { handleShowRemoveScore(e) }}>{'Remove'}</div>
  const gcrc = () => <div color={_allowUpdate ? 'green' : 'blue'} onClick={(e) => { handleRtRemoveScore(e) }}>{'Confirm Remove'}</div>

  const teamInitials = (name) => {
    let tt = createInitials(name)
    if (tt.length === 1) { tt = name.substring(0, 4) }
    return tt
  }

  const getSetProps = (setScores) => {

    let home = 'dark-grey'
    if (setScores.home > setScores.away) { home = 'green' }
    if (setScores.home < setScores.away) { home = 'red' }

    let away = 'dark-grey'
    if (setScores.away > setScores.home) { away = 'green' }
    if (setScores.away < setScores.home) { away = 'red' }

    let allowSetUp = !setNumber || (setNumber <= _setsCompleted)
    let allowSetDown = setNumber > 1

    let allowAwayDown = setScores.away > 0
    let allowHomeDown = setScores.home > 0

    return { away, home, allowSetUp, allowSetDown, allowAwayDown, allowHomeDown }
  }

  const realtimeGrid = () => {

    const setScores = scores && setNumber && scores[setNumber] ? scores[setNumber] : {}

    const setProps = getSetProps(setScores)

    let cn = 'scorepad-container msk'
    if (match_complete_possible) { cn += ' done' }

    const headerRow = () => [
      gc(away && teamInitials(away.name)),
      gc('Set #'),
      gc(home && teamInitials(home.name))
    ]

    const upRow = () => [
      gci(keyPadIcons.up, 'away', true, setNumber),
      gci(keyPadIcons.up, 'set', true, setProps.allowSetUp),
      gci(keyPadIcons.up, 'home', true, setNumber)
    ]

    const scoreRow = (setCount, _setScores, _setProps, srKey, _disabled, _active) => [
      gcl(_setScores ? _setScores.away : setScores.away, _setProps ? _setProps.away : setProps.away, 'away', setCount, srKey, 'ssa', _disabled),
      gcl(setCount ? setCount : setNumber, _disabled ? 'grey' : 'black', 'set', setCount, srKey, 'sc', _disabled, _active),
      gcl(_setScores ? _setScores.home : setScores.home, _setProps ? _setProps.home : setProps.home, 'home', setCount, srKey, 'ssh', _disabled)
    ]

    const downRow = () => [
      gci(keyPadIcons.down, 'away', false, setProps.allowAwayDown),
      gci(keyPadIcons.down, 'set', false, setProps.allowSetDown),
      gci(keyPadIcons.down, 'home', false, setProps.allowHomeDown)
    ]

    const scoresRow = () => Array.from({ length: _setsDisplayed }, (_, index) => {
      const _setCount = index + 1
      const _setScores = scores && _setCount && scores[_setCount] ? scores[_setCount] : {}
      const _setProps = getSetProps(_setScores)
      const _disabled = _setCount > _setsCompleted + 1
      const _active = _setsCompleted === index
      const srKey = uniqueKey('msk', 'sr', _setCount)
      return scoreRow(_setCount, _setScores, _setProps, srKey, _disabled, _active)
    });

    switch (keypadType) {
      case _keypadTypes.normal:
        return <div className={cn}>
          {headerRow()}
          {scoresRow()}
        </div>

      case _keypadTypes.realtime:
        return <div className={cn}>
          {headerRow()}
          {upRow()}
          {scoreRow()}
          {downRow()}
          {match_complete_possible && gcUpdate()}
          {showRemove ? gcrc() : gcr()}
        </div>
      default:
      // nothing
    }
  }

  if (directScoresOn) {
    return <Keypad
      setScoresOn={setDirectScoresOn}
      maxValues={2}
      maxDigits={2}
      setNumber={currentSetNumber}
      handleUpdate={handleSetScoreChange}
      match={match_edit}
    />
  } else {
    switch (keypadType) {
      case _keypadTypes.realtime:
        return realtimeGrid()
      default:
        return realtimeGrid()
    }
  }
}

export default MatchScoreKeypad