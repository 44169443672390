import _ from 'lodash';
import React, { useContext } from 'react';
import { Label } from 'semantic-ui-react';
import { sidebarMenuTypes } from '../../cnr/reducers/SidebarReducer';
import { createInitials } from '../../common/dataAdjust';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import { getArrayIndex } from '../../common/sorting';
import { CalendarContext } from './Calendar';

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const _calMonthProps = {
  labelSize: 'small',
  labelColor: 'blue',
  formatType: formatTypes.shortestDate
}

const CalMonth = (props) => {

  const { currentDate, selectedDate, sbProps, dateKeys, dateKeyz, itemsData, clickType, teamKey, crossoverOn } = props

  // calendarContext
  const calendarContext = useContext(CalendarContext)
  const { calendar_state, calendar_handlers } = calendarContext ?? {}
  const { fixedDates, selectedDates } = calendar_state ?? {}

  const _currentDate = formatItem(formatTypes.shortestDate, new Date())

  const { handleItemClick, sidebar_handlers, type: sidebarMenuType } = sbProps ?? {}
  const { handleGenericItemClick } = sidebar_handlers ?? {}

  const _selectedDate = selectedDate ? selectedDate : currentDate

  const fixed_dates = dateKeys ? dateKeys : fixedDates

  /**
   * handles the click of a date
   * @param {array} dv 
   */
  const handleDateClick = (dv) => {
    if (handleItemClick) {
      handleItemClick(null, null, sidebarMenuType ? sidebarMenuType : sidebarMenuTypes.date, dv)
    } else if (handleGenericItemClick) {
      handleGenericItemClick(null, null, clickType ? clickType : sidebarMenuTypes.date, dv)
    } else if (calendar_handlers && calendar_handlers.handleDateSelect) {
      calendar_handlers.handleDateSelect(dv)
    } else if (props.handleClick) {
      props.handleClick(dv)
    }
  }

  const dateSpan = (d, dc, lblColor, lblTitle) => {
    try {
      if (dc) {
        return <React.Fragment>
          <div key={uniqueKey('cm', 'd', d)}>{d}</div>
          <Label key={uniqueKey('cm', 'dl', d)} circular title={lblTitle} color={lblColor ? lblColor : _calMonthProps.labelColor} size={_calMonthProps.labelSize}>{dc}</Label>
        </React.Fragment>
      } else {
        return <React.Fragment >
          <div key={uniqueKey('cm', 'd1', d)}>{d}</div>
          <div key={uniqueKey('cm', 'd2', d)}></div>
        </React.Fragment>
      }
    } catch (error) {
      return <div key={uniqueKey('cm', 'd1', d)}>{'xxx'}</div>
    }
  }

  const calDates = () => {

    const cds = []

    const _firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    let _firstDayOfMonth = _firstDateOfMonth.getDay() + 1

    const months = 1
    const weeks = 6
    const days = 7
    let dd = 0
    let go = false
    let month = 0

    for (var wd = 1; wd <= days; wd++) {
      cds.push(<div key={uniqueKey('cm', 'wd', wd)} className='dow'>{dateSpan(weekday[wd - 1])}</div>)
    }

    // loop the months
    for (var m = 1; m <= months; m++) {
      // loop the weeks
      for (var w = 1; w <= weeks; w++) {
        const ow = []
        let owdn = 0
        // loop the days
        for (var d = 1; d <= days; d++) {
          if (d >= _firstDayOfMonth && !go) {
            go = true
            month = currentDate.getMonth()
          }
          if (go) {
            dd++

            const _thisDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), dd)

            if (month === _thisDayOfMonth.getMonth()) {

              const _selDate = formatItem(_calMonthProps.formatType, _selectedDate)
              const _thisDate = formatItem(_calMonthProps.formatType, _thisDayOfMonth)
              const _dateCount = dateKeyz && dateKeyz[_thisDate]

              let _opp;
              let lblColor;
              let lblTitle;

              if (itemsData) {
                const _mmm = _.find(itemsData, { matchDate: _thisDate })
                if (_mmm) {
                  const { matches } = _mmm
                  if (matches && matches.length === 1) {
                    const match = matches[0]
                    const { home, away } = match
                    const { id: id_home, name: name_home } = home
                    const { id: id_away, name: name_away } = away
                    if (teamKey === id_home && name_away) {
                      _opp = createInitials(name_away)
                      lblTitle = 'vs. ' + name_away
                      lblColor = 'teal'
                    } else if (teamKey === id_away && name_home) {
                      _opp = createInitials(name_home)
                      lblTitle = '@ ' + name_home
                      lblColor = 'orange'
                    }
                  }
                }
              }

              let sel;

              if (!fixed_dates) {
                try {
                  if (selectedDates && selectedDates.length > 0) {
                    sel = selectedDates.includes(_thisDate)
                  } else {
                    sel = (_selDate === _thisDate)
                  }
                } catch (error) {
                  console.error(error)
                }
              }

              if (fixed_dates && (getArrayIndex(fixed_dates, _thisDate) < 0)) {
                ow.push(<div key={uniqueKey('cm', m, w, d)} className={'non-sel'}>{dateSpan(dd, null)}</div>)
              } else {
                let dc = _dateCount
                if (_opp) { dc = _opp }
                if (sel) {
                  let cn = dc ? 'norm-count' : 'norm'
                  cn += ' sel'
                  if (crossoverOn) { cn += ' co' }
                  if (_currentDate === _thisDate) { cn += ' current-date' }
                  ow.push(<div key={uniqueKey('cm', m, w, d)} className={cn} onClick={e => handleDateClick(_thisDate)}>{dateSpan(dd, dc, lblColor, lblTitle)}</div>)
                } else {
                  let cn = dc ? 'norm-count' : 'norm'
                  if (dateKeys) { cn += ' dk' }
                  if (_currentDate === _thisDate) { cn += ' current-date' }
                  ow.push(<div key={uniqueKey('cm', m, w, d)} className={cn} onClick={e => handleDateClick(_thisDate)}>{dateSpan(dd, dc, lblColor, lblTitle)}</div>)
                }
              }
            } else {
              ow.push(<div key={uniqueKey('cm', m, w, d)} className='nd'>{'--'}</div>)
              owdn++
            }
          } else {
            ow.push(<div key={uniqueKey('cm', m, w, d)} className='nd'>{'--'}</div>)
            owdn++
          }
        }
        if (owdn < 7) {
          cds.push([...ow])
        }
      }
    }
    return cds
  }

  return <div key={uniqueKey('cm')} className="calendar-container">
    {calDates()}
  </div>
}

export default CalMonth