import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { sendFcmMessage } from '../../../projectSpecific/sports/cnr/reducers/SportsDataReducer';
import AppNotificationsManager, { appNotificationTypes } from '../../appNotifications/AppNotificationsManager';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { _currentUserIdProp } from '../../cnr/reducers/AppUserReducer';
import { _appSidebarContentTypes } from '../../cnr/reducers/FrameworkReducer';
import { refreshPageWithCacheClear } from '../../common/actions';
import { uniqueKey } from '../../common/keys';
// import { copyCodeToClipboard } from '../../common_web/copy';
import { gEnums } from '../../enums/globalEnums';
import { fsfn_notifications } from '../../functions/fbNotifications';
import { _genericTopicKey } from '../../messages/FcmList';
import { currentHelpers } from '../../redirection/current';

const NavSideBottomMenu = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { settings, states, handlers, fns, navigate } = parentContext ?? {}
  const { appSettings_state, appUser_state, page_state, paps_state, serviceWorker_state, storage_state } = states ?? {}
  const { appSettings_handlers } = handlers ?? {}
  const { currentAppVersion, newVersionAvailable } = serviceWorker_state ?? {}
  const { appUsers, notificationRequestGranted } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { email, fcmTokens, isSuperAdminDeveloper } = appUserAccess ?? {}
  const { appConfigSettings } = appSettings_state ?? {}
  const { appNames, isMeProd } = appConfigSettings ?? {}
  const { appNameSc } = appNames ?? {}

  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}

  const { page_fns } = fns ?? {}

  const { allowSidebarView } = props

  const _appVersion = isMeProd ? currentAppVersion : currentAppVersion + '*'

  // papsContext 
  const { pathViews, rootPaths, platform } = paps_state ?? {}
  const { name: platform_name } = platform ?? {}

  const _fcmToken = fcmTokens && platform_name ? fcmTokens[platform_name] : null

  const { events: eventKey } = pathViews ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { eventOptions, clientOptions, appNotifications, styles, appInstallation, sideMenu } = aps_global ?? {}
  const { allowNotifications } = appNotifications ?? {}
  const { sideMenus } = styles ?? {}
  const { iconsLeft } = sideMenu ?? {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const {
    allowBackToClient,
    allowCookieClearing,
    allowNotificationCheck,
    allowPermissionsReset,
    allowSendUserId,
    allowAppLinkSharing,
    helpPdf,
    showHelp,
    showLyft,
    showQrCode,
    showUber,
    showUserImage,
    showWeather,
    showWifi,
    useOptionsDropdown,
    weatherCountryCode,
    weatherZip,
    wifiNetwork,
    wifiPassword,
  } = _appOptions ?? {}

  const { allowAppInstallation, allowManualAppInstallation, showAddToHomeScreenHelp } = appInstallation ?? {}

  const { appUrls: homeAppUrls } = homeSettings_global ?? {}
  const { appUrls: appUrlz } = homeAppUrls ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { isMobileDevice } = framework_state ?? {}

  const [showNotifications, setShowNotifications] = useState()
  const [helpUrl, setHelpUrl] = useState()
  const [showStaticMis, setShowStaticMis] = useState()
  const [showFcm, setShowFcm] = useState()
  const [appName, setAppName] = useState()

  useEffect(() => {
    const { globalFiles } = storage_state ?? {}
    const { pdf } = globalFiles ?? {}
    const _helpPdf = pdf && pdf[helpPdf]
    const { urls } = _helpPdf ?? {}
    const { full } = urls ?? {}
    setHelpUrl(full)
    setAppName(appNameSc)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const textAreaRef = useRef()

  const handleShowHelp = () => window.open(helpUrl, '_blank')

  const handleBackToClient = () => {
    if (rootPaths && rootPaths.clients) {
      const pn = rootPaths.clients.substring(0, rootPaths.clients.length - 1)
      navigate(pn)
    }
  }
  // FrZi8TCLcOSTKr8L2n5s 
  // const handleShare = () => copyCodeToClipboard(textAreaRef)
  const handleAppSharing = () => framework_handlers.handleShow_appSidebarContent(_appSidebarContentTypes.appSharing)
  const handleShowWifi = () => framework_handlers.handleShow_appSidebarContent(_appSidebarContentTypes.wifi)
  const handleShowNotificationCheck = () => framework_handlers.handleShow_appSidebarContent(_appSidebarContentTypes.notificationCheck)
  const handleShowStaticMis = () => setShowStaticMis(!showStaticMis)

  const handleAddToTopic = () => fsfn_notifications.subscribeToTopic(_fcmToken, _genericTopicKey)
  // const handleAddToTopic = () => fsfn_notifications.sendNotificationToSelf(_fcmToken, _genericTopicKey)

  const handleClearCookies = () => {
    localStorage.clear()
    framework_handlers.handleShow_appSidebar()
  }

  const handleResetPermissions = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        console.log('Notification permission reset to:', permission);
      });
    }
  }

  const handleSendUserId = () => {
    const _currentUserId = currentHelpers.storageItem_get(_currentUserIdProp)
    if (_currentUserId) {
      sendFcmMessage(_currentUserId)
    }
  }

  const handleShowHomeScreenPrompt = () => {
    const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'
    // remove the item first
    window.localStorage.removeItem(addToHomeScreenItem)
    appSettings_handlers.handleShow_homeScreenPrompt(true)
    framework_handlers.handleShow_appSidebar()
  }

  const handleShowHomeScreenHelpPrompt = () => {
    appSettings_handlers.handleShowHomeScreenHelp()
    framework_handlers.handleShow_appSidebar()
  }

  const handleNotifications = () => {
    !showNotifications && framework_handlers.handleShow_appSidebar()
    setShowNotifications(!showNotifications)
  }

  const handleGetLatest = () => refreshPageWithCacheClear()
  const handleShowFcm = () => setShowFcm(!showFcm)
  const handleLinkClick = (url) => { window.open(url, '_blank') }

  const lyftLink = page_fns.getLink(appUrlz, 'lyft', isMobileDevice)
  const uberLink = page_fns.getLink(appUrlz, 'uber', isMobileDevice)
  const weatherLink = page_fns.getLink(appUrlz, 'weather', isMobileDevice)


  const _shows_nav = {
    homescreenHelp: showAddToHomeScreenHelp,
    installation: allowAppInstallation && allowManualAppInstallation,
    appLinkSharing: allowAppLinkSharing,
    lyft: showLyft && lyftLink,
    notifications: allowNotifications,
    uber: showUber && uberLink,
    userQrOrImage: showQrCode || showUserImage,
    weather: showWeather && weatherLink && weatherZip,
    wifi: showWifi && wifiNetwork && wifiPassword
  }

  const getItemStyle = () => {
    const miStyle = {
      paddingTop: sideMenus && sideMenus.apply && sideMenus.padding ? sideMenus.padding + 'em' : null,
      paddingBottom: sideMenus && sideMenus.apply && sideMenus.padding ? sideMenus.padding + 'em' : null,
      fontSize: sideMenus && sideMenus.fontSize && sideMenus.fontSize ? sideMenus.fontSize + 'em' : null,
    }
    return miStyle
  }

  const menuIcon = (name, oc, color) => <Icon size='large' key={uniqueKey('nvsm', _.camelCase(name))} name={name} color={color} onClick={() => oc && oc()} />

  const linkMenuItem = (caption, url, replace, replaceWith, icon) => {
    const sty = getItemStyle()
    let itemCaption = _.startCase(caption)
    if (replace && replaceWith) {
      url = url.replace(replace, replaceWith)
      if (weatherCountryCode) { url = url.replace('US', weatherCountryCode) }
      itemCaption += ' (' + replaceWith + ')'
    }
    return <Menu.Item key={uniqueKey('nbsm', caption)} style={sty} onClick={() => handleLinkClick(url)}>{itemCaption}{menuIcon(icon)}</Menu.Item>
  }

  const menuItem = (text, sty, icon, oc) => <Menu.Item style={sty} onClick={() => oc()}>{menuIcon(icon)}{text}</Menu.Item>
  const menuItemIcon = (text, sty, icon, oc, color) => <Menu.Item style={sty} onClick={() => oc && oc()}>{menuIcon(icon, oc, color)}{text}</Menu.Item>
  const menuItemIcons = (text, sty, icons, oc, color) => {
    const _icons = []
    icons.forEach(i => {
      _icons.push(menuIcon(i.name, i.oc, i.color))
    })
    return <Menu.Item style={sty}>{_icons}{text}</Menu.Item>
  }
  const menuItemFcm = (sty) => <Menu.Item
    style={sty}
    className='fcmt'
    onClick={() => isSuperAdminDeveloper && handleAddToTopic()}
  >{_fcmToken.tokenKey.substring(0, 40) + '...'}
  </Menu.Item>

  const menuItems_nav = () => {
    const sty = getItemStyle()
    const _navItems = []
    if (_shows_nav.wifi) { _navItems.push(menuItem('Wifi', sty, 'wifi', handleShowWifi)) }
    if (_shows_nav.notifications) { _navItems.push(menuItem('Notifications', sty, 'bell outline', handleNotifications)) }
    if (_shows_nav.appLinkSharing) { _navItems.push(menuItemIcon('App Sharing', sty, 'qrcode', handleAppSharing, 'blue')) }
    if (_shows_nav.lyft) { _navItems.push(linkMenuItem('lyft', lyftLink, null, null, 'lyft')) }
    if (_shows_nav.uber) { _navItems.push(linkMenuItem('uber', uberLink, null, null, 'uber')) }
    if (_shows_nav.weather) { _navItems.push(linkMenuItem('weather', weatherLink, 'ZIPCODE', weatherZip, 'cloud')) }
    if (_shows_nav.installation) { _navItems.push(menuItem('Add to Home Screen', sty, 'home', handleShowHomeScreenPrompt)) }
    if (_shows_nav.homescreenHelp) { _navItems.push(menuItem('Add to Home Screen Instructions', sty, 'help', handleShowHomeScreenHelpPrompt)) }
    return _navItems
  }

  const menuItems_device = () => {

    const sty = getItemStyle()
    const _navItems = []

    let _tokenColor = 'grey'
    if (_fcmToken) {
      if (notificationRequestGranted) {
        _tokenColor = 'green'
      } else {
        _tokenColor = 'blue'
      }
    }

    const _icons = []

    _icons.push({ name: 'refresh', oc: handleGetLatest, color: newVersionAvailable ? 'green' : 'blue' })
    _icons.push({ name: platform.icon, oc: _fcmToken ? handleShowFcm : null, color: _tokenColor })

    if (platform.device === 'desktop') { _icons.push({ name: 'desktop' }) }

    _navItems.push(menuItemIcons('Version: ' + _appVersion, sty, _icons))
    _navItems.push(menuItemIcon(email, sty, 'user'))
    _navItems.push(menuItemIcon(appName, sty, 'home'))

    if (showFcm) {
      _navItems.push(menuItemFcm())
    }

    return _navItems
  }

  const menuItems_options = () => {
    const sty = getItemStyle()
    const _navItems = []
    if (allowCookieClearing) { _navItems.push(menuItemIcon('Clear Site Cookies', sty, 'erase', handleClearCookies, 'blue')) }
    if (allowPermissionsReset) { _navItems.push(menuItemIcon('Reset Permissions', sty, 'refresh', handleResetPermissions, 'blue')) }
    if (allowSendUserId) { _navItems.push(menuItemIcon('Send User Id', sty, 'send', handleSendUserId, 'blue')) }
    if (allowNotificationCheck) { _navItems.push(menuItemIcon('Check Notification Permission ', sty, 'check circle outline', handleShowNotificationCheck, 'blue')) }
    if (showHelp) { _navItems.push(menuItemIcon('Help', sty, 'help', handleShowHelp, 'blue')) }
    if ((allowBackToClient && eventKey)) { _navItems.push(menuItemIcon('Client', sty, 'arrow left', handleBackToClient, 'blue')) }
    if (useOptionsDropdown) {
      return <React.Fragment>
        <Menu.Item>
          <Menu.Header onClick={() => handleShowStaticMis()}>{'Options'}<Icon name={showStaticMis ? 'caret down' : 'caret right'} style={{ float: 'right' }} /></Menu.Header>
        </Menu.Item>
        {showStaticMis && <Menu.Menu>
          {_navItems}
        </Menu.Menu>}
      </React.Fragment>
    } else {
      return _navItems
    }
  }

  const navMenu = () => {
    // showNotificationCheck && requestNotificationPermissionAgain()
    if (allowSidebarView === gEnums.viewPermissionTypes.allow) {
      let cn = 'inh oth'
      if (iconsLeft) { cn += ' icons-left' }
      const _menuItems = menuItems_nav()
      return _menuItems.length > 0 ? <Menu borderless vertical fluid inverted={true} className={cn}>
        {_menuItems}
      </Menu>
        : <div></div>
    }
    return <div></div>
  }

  const navMenu_device = () => {
    if (allowSidebarView === gEnums.viewPermissionTypes.allow) {
      let cn = 'inh oth'
      if (iconsLeft) { cn += ' icons-left' }
      const _menuItems = menuItems_device()
      const _menuItems_options = menuItems_options()
      return _menuItems.length > 0 ? <Menu borderless vertical fluid inverted={true} className={cn}>
        {_menuItems_options}
        {_menuItems}
      </Menu>
        : <div></div>
    }
    return <div></div>
  }

  if (showNotifications) {
    return <AppNotificationsManager appNotificationType={appNotificationTypes.notifications} handleCancel={handleNotifications} />
  } else {
    return <React.Fragment>
      {navMenu()}
      {navMenu_device()}
    </React.Fragment>
  }
}

export default NavSideBottomMenu