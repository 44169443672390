import _ from 'lodash'
import React from 'react'
import { Button, Segment, Sidebar } from 'semantic-ui-react'
import { sidebarMenuTypes, sidebarSliderTypes } from '../cnr/reducers/SidebarReducer'
import { uniqueKey } from '../common/keys'
import MenuBaseSlider from '../components/menus/MenuBaseSlider'
import { calSidebar, sidebarBase } from '../components/menus/menuSlider'

/**
 * 
 * @param {object} props (content, dimmed, inverted, sidebar_handlers, sidebar_items, sidebarType, style) 
 * @param content: main content
 * @param dimmed: boolean
 * @param inverted: boolean
 * @param sidebar_handlers: handlers
 * @param sidebar_items: items in the sidebar
 * @param sidebarType: type
 * @param style: style
 * @returns 
 */
const MenuSidebars = (props) => {

  const { content, dimmed, inverted, sidebar_handlers, sidebar_items, sidebarType, style, cnn, cnh, useLabels } = props

  const handleGenericClick = (index, item, keyAs) => sidebar_handlers.handleGenericItemClick(index, item, keyAs, false)

  const sbmt = (index) => {
    switch (index) {
      case 0:
        return sidebarMenuTypes.one
      case 1:
        return sidebarMenuTypes.two
      case 2:
        return sidebarMenuTypes.three
      case 3:
        return sidebarMenuTypes.four
      case 4:
        return sidebarMenuTypes.five
      case 5:
        return sidebarMenuTypes.six
      default:
        return sidebarMenuTypes.one
    }
  }

  const sbmti = (smt) => {
    switch (smt) {
      case sidebarMenuTypes.one:
        return 0
      case sidebarMenuTypes.two:
        return 1
      case sidebarMenuTypes.three:
        return 2
      case sidebarMenuTypes.four:
        return 3
      default:
        return sidebarMenuTypes.one
    }
  }

  if (sidebar_items) {
    Object.keys(sidebar_items).forEach(key => {
      sidebar_items[key].index = sbmti(sidebar_items[key].smt)
    })
  }

  const _sidebar_items = sidebar_items ? _.sortBy(sidebar_items, 'index') : []

  // eslint-disable-next-line 
  const sidebarBases = () => _sidebar_items.map((sbi, index) => {
    const { sidebarSliderType, useStartCase } = sbi
    if (sbi.items) {
      switch (sidebarSliderType) {
        case sidebarSliderTypes.calendar:
          return calSidebar(sbi, sidebar_handlers, sbmt(index), false, props)
        default:
          return sidebarBase(sbi, sidebar_handlers, sbmt(index), { sidebarType, useStartCase })
      }
    }
  })

  // eslint-disable-next-line
  const sidebarSliders = () => _sidebar_items.map((sbi, index) => {
    const { items, sidebarSliderType, hidden } = sbi
    if (items) {
      switch (sidebarSliderType) {
        case sidebarSliderTypes.labeled:
          return !hidden ? buttons(sbi, handleGenericClick, index) : null
        default:
          const mbsProps = {
            sidebarItem: sbi, sidebar_handlers, type: sbmt(index),
            opts: { sidebarType, inverted: inverted, useLabels }
          }
          return <MenuBaseSlider key={uniqueKey('mbs', index)} mbsProps={mbsProps} />
      }
    }
  })

  let cn = 'push-pushable ' + sidebarType
  if (inverted) { cn += ' inverted' }
  if (cnn) { cn += ' ' + cnn }

  let ch = 'push-header'
  if (cnh) { ch += ' ' + cnh }

  const sidebar = () => <Sidebar.Pushable key={uniqueKey('sbp-' + sidebarType)} className={cn}>
    {sidebarBases()}
    <Sidebar.Pusher key={uniqueKey('sp', sidebarType)} className={'push-push'} dimmed={dimmed} style={style ? style : null}>
      <SegmentI keyy={uniqueKey('pn', sidebarType)} className={'push-container'}>
        <SegmentI keyy={uniqueKey('ph', sidebarType)} className={ch} sidebarType={sidebarType}>
          {sidebarSliders()}
        </SegmentI>
        <SegmentI keyy={uniqueKey('segi', props.sidebarType)} className={'push-content'}>
          {content}
        </SegmentI>
      </SegmentI>
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  return sidebar()

}

const SegmentI = (props) => {
  let cn = 'seg-nbm'
  if (props.className) { cn += ' ' + props.className }
  return <Segment key={props.keyy} inverted={props.inverted} basic className={cn} >
    {props.children}
  </Segment>
}

const buttons = (sbi, handleItemClick, index) => {

  const { useStartCase, items, keyAs, selected } = sbi
  const { item: item_selected } = selected ? selected : {}

  // index, key, clickType, directItem
  const oc = (item, index) => handleItemClick(index, item, keyAs, false)

  const getItem = (i => {
    let _item = i
    switch (i) {
      case 'roundOf32':
        _item = 'R32'
        break;
      case 'roundOf16':
        _item = 'R16'
        break;
      case 'quarterFinals':
        _item = 'quarters'
        break;
      case 'semiFinals':
        _item = 'semis'
        break;
      default:
      // nothing
    }
    return _item
  })

  const _buttons = items.map((item, index) => {
    const _item = getItem(item)
    return <Button key={uniqueKey('ms', 'b', index)} className={item_selected === item ? 'btn-sel' : null} color={item_selected === item ? 'blue' : null} onClick={() => { oc(item, index) }}>{useStartCase ? _.startCase(_item) : _item}</Button>
  })

  return <div
    key={uniqueKey('ms', 'bg', index)}
    className='ms-button-group' >
    <Button.Group size={'mini'} >
      {_buttons}
    </Button.Group>
  </div>
}

export default MenuSidebars