import _ from 'lodash';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { gEnums } from '../../enums/globalEnums';
import { currentHelpers } from '../../redirection/current';
import { createRefPath, createRefPath_client } from '../appData/appRefPaths';
import { fs_update_doc } from '../appData/fsData';
import { fsfn_notifications } from '../../functions/fbNotifications';

/**
 * Updates the `profiles` collection (viewKey) with the `name` and `path` of the selected item.
 * @param {function} dispatch 
 * @param {object} appUser 
 * @param {object} paps_state 
 * @param {string} pageDataCaption 
 */
export const updateProfilePageFavorite = (dispatch, appUser, appUsers, paps_state, pageDataCaption, favItem) => {

  const { platform } = paps_state
  const { name: platform_name } = platform ?? {}

  const { profileData, uid } = appUser ?? {}
  const { fcmTokens } = profileData ?? {}
  const fcmToken = fcmTokens ? fcmTokens[platform_name] : {}
  const { tokenKey } = fcmToken ?? {}

  const { pathViews, view, viewKey, pathName } = paps_state ?? {}
  const { key: key_fav, name: name_fav, pathName: pathName_fav } = favItem ? favItem : {}
  const favCollectionKey = key_fav ? favItem[key_fav] : null

  const _view = key_fav ? key_fav : view
  const _viewKey = favCollectionKey ? favCollectionKey : viewKey
  const _pageDataCaption = name_fav ? name_fav : pageDataCaption
  const _pathName = pathName_fav ? pathName_fav : pathName

  const appUserAccess = getAppUserAccess(appUsers)

  let { appUserAccessType, email, pageFavs, pageFavz } = appUserAccess ?? {}

  const { districts, sports } = pathViews ?? {}

  if (!pageFavz) { pageFavz = [] }

  if (pageFavz.includes(_viewKey)) {
    pageFavz = _.without(pageFavz, _viewKey);
  } else {
    pageFavz.push(_viewKey)
  }

  if (!pageFavs) { pageFavs = {} }
  if (!pageFavs[_view]) { pageFavs[_view] = {} }

  let _favKey;
  let unsubscribe;

  if (pageFavs[_view][_viewKey]) {
    delete pageFavs[_view][_viewKey]
    _favKey = _viewKey
    unsubscribe = true
  } else {
    pageFavs[_view][_viewKey] = {
      name: _pageDataCaption,
      pathName: _pathName
    }
    if (districts) { pageFavs[_view][_viewKey].districts = districts }
    if (sports) { pageFavs[_view][_viewKey].sports = sports }
    _favKey = _viewKey
    unsubscribe = false
  }

  const d = {
    pageFavs,
    pageFavz
  }

  let _refPath;

  const cb = async (res) => {
    console.log('res', res)
    // const { profileData } = appUser ?? {}
    // const { fcmTokens } = profileData ?? {}
    // const tokenKeys = _.map(fcmTokens, 'tokenKey');
    // console.log('tokenKeys', tokenKeys)
    if (tokenKey) {
      const rest = await fsfn_notifications.subscribeToTopic(tokenKey, _favKey, unsubscribe, uid)
      console.log('rest', rest)
    }

    dispatch(res)
  }

  if (appUserAccessType) {
    switch (appUserAccessType) {
      case gEnums.appUserAccessTypes.admin:
      case gEnums.appUserAccessTypes.superAdmin:
        _refPath = createRefPath(['profiles', profileData.id])
        break;
      default:
        _refPath = createRefPath_client(pathViews, ['profiles', email])
    }
    fs_update_doc(_refPath, d, cb)
  } else {
    currentHelpers.storageItem_set('pageFavs', JSON.stringify(pageFavs))
  }
}

// fhP4MyWV4SSan4z694zlDR:APA91bElGmamcbnmMetO4c1omWrYdqG9HcxAdLS_HYKuhcN__Ncv-PayS5T-rxs84_yQrRJRnWdR8nnTKTi3JrBNqtgcKuy6PmpZ4XcRuwZtlOaRrs7CuwTUQZs4VpBa1FIZwT9eOlVD
// fhP4MyWV4SSan4z694zlDR:APA91bElGmamcbnmMetO4c1omWrYdqG9HcxAdLS_HYKuhcN__Ncv-PayS5T-rxs84_yQrRJRnWdR8nnTKTi3JrBNqtgcKuy6PmpZ4XcRuwZtlOaRrs7CuwTUQZs4VpBa1FIZwT9eOlVD