import _ from 'lodash';
import { appOptions_left } from "../../optionLists/appOptionsLeft";
import { currentHelpers } from "../../redirection/current";

export const rts = {
  handle_triggerAction: 'handle_triggerAction',
  handleCloseCurrentMenuType: 'handleCloseCurrentMenuType',
  handleDatabaseOpen: 'handleDatabaseOpen',
  handleGoToClient: 'handleGoToClient',
  handleGoToHome: 'handleGoToHome',
  handleInit_leftMenu: 'handleInit_leftMenu',
  handleOpen_otherSite: 'handleOpen_otherSite',
  handleOpenGs: 'handleOpenGs',
  handleOpenSettings: 'handleOpenSettings',
  handleSet_currentMenuType: 'handleSet_currentMenuType',
}

export const leftMenuTypes = {
  actions: 'actions',
  admins: 'admins',
  appAdmins: 'appAdmins',
  appInfos: 'appInfos',
  appUsers: 'appUsers',
  confirmation: 'confirmation',
  confirmAction: 'confirmAction',
  dashboards: 'dashboards',
  dataSources: 'dataSources',
  devices: 'devices',
  externals: 'externals',
  input: 'input',
  confirmInput: 'confirmInput',
}

export const leftMenuTriggerTypes = {
  altDatabase: 'altDatabase',
  appDataDocuments: 'appDataDocuments',
  appDataLinks: 'appDataLinks',
  appProfiles: 'appProfiles',
  appUserAccess: 'appUserAccess',
  auth: 'auth',
  confirmAction: 'confirmAction',
  dashboard: 'dashboard',
  dataOverride: 'dataOverride',
  dataSource: 'dataSource',
  demoApp: 'demoApp',
  device: 'device',
  frameworkLeft: 'frameworkLeft',
  gotoClient: 'gotoClient',
  gotoDev: 'gotoDev',
  gotoHome: 'gotoHome',
  gotoSite: 'gotoSite',
  help: 'help',
  openDb: 'openDb',
  openGs: 'openGs',
  openStorage: 'openStorage',
  search: 'search',
  settings: 'settings',
  settingsPreview: 'settingsPreview',
  staticViews: 'staticViews',
  toggle: 'toggle',
  triggerAction: 'triggerAction'
}

export const leftMenuReducer = (state, action) => {

  const { type, dispatch } = action

  const _handlers = leftMenuHandlers(dispatch)

  switch (type) {

    case rts.handleInit_leftMenu:
      const _leftOptions = getLeftOptions(action, _handlers)
      return { ...state, leftOptions: _leftOptions }

    case rts.handleSet_currentMenuType:
      const { altOptions } = action
      const { lmt } = altOptions ?? {}
      return { ...state, currentMenuType: action.options ? action.options.lmt : null, triggerActionType: null, altTrigger: lmt }

    case rts.handle_triggerAction:
      return { ...state, triggerActionType: action.options ? action.options.lmt : null, frt: action ? action.options.frt : null }

    case rts.handleCloseCurrentMenuType:
      return { ...state, currentMenuType: null }

    case rts.handleDatabaseOpen:
      const item_hp = action.eventKey ? 'homePageSeen-' + action.eventKey : 'homePageSeen'
      currentHelpers.storageItem_set(item_hp, true);
      return { ...state }

    case rts.handleGoToHome:
      const item_w = action.eventKey ? 'waiverSeen-' + action.eventKey : 'waiverSeen'
      currentHelpers.storageItem_set(item_w, true);
      return { ...state }

    case rts.handleGoToClient:
      return { ...state, messagingSupported: action.messagingSupported }

    default:
      return { ...state }
  }
}

export const leftMenuHandlers = (dispatch) => {
  return {
    handle_triggerAction: (options) => { dispatch({ type: rts.handle_triggerAction, dispatch, options }) },
    handleCloseCurrentMenuType: () => { dispatch({ type: rts.handleCloseCurrentMenuType, dispatch }) },
    handleDatabaseOpen: (eventKey) => { dispatch({ type: rts.handleDatabaseOpen, eventKey }) },
    handleGoToClient: (messagingSupported) => { dispatch({ type: rts.handleGoToClient, messagingSupported }) },
    handleGoToHome: (eventKey) => { dispatch({ type: rts.handleGoToHome, eventKey }) },
    handleInit_leftMenu: (settings) => { dispatch({ type: rts.handleInit_leftMenu, dispatch, settings }) },
    handleSet_currentMenuType: (options, altOptions) => { dispatch({ type: rts.handleSet_currentMenuType, dispatch, options, altOptions }) },
  }
}

export const leftInitialState = (initState) => {
  const _leftOptions = {}
  _.each(appOptions_left, (alo, key) => {
    _leftOptions[key] = []
  })
  return { ...initState, appOptions: appOptions_left, leftOptions: _leftOptions }
};

const getLeftOptions = (action, _handlers) => {

  const { settings } = action
  const { allowAdmin, allowSideSettings } = settings

  if (allowSideSettings) {
    const _leftOptions = {}
    _.each(appOptions_left, (alo, key) => {
      _leftOptions[key] = alo(_handlers, settings)
    })
    return _leftOptions
  } else {
    const _leftOptions = {
      device: appOptions_left.device(_handlers),
      devices: appOptions_left.devices(_handlers),
    }
    if (allowAdmin) {
      _leftOptions.appUser = appOptions_left.appUser(_handlers, settings)
      _leftOptions.appUsers = appOptions_left.appUsers(_handlers)
    }
    return _leftOptions
  }
}