import _ from 'lodash';
import { gEnums } from '../../../../global/enums/globalEnums';
import { isOdd } from '../../../../global/common/filtering';
import { createFsDocKey, fs_db } from '../../../../global/firestoreData/appData/fsAppData';
import { createRefPath, createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../../../../global/firestoreData/appData/fsData';
import { fsfn_updateRegistration } from '../../../../global/functions/fbRegistration';
import { fsfn_updateWillCallTicket } from '../../../../global/functions/fbTicketing';
import { isLocalhost, isMeProd } from '../../../../project/appConfiguration';
import { getTicketing } from '../../dbActions/getTicketing';
import { updateTicketing } from '../../dbActions/updateTicketing';

// https://us-central1-me-mobile-4410b.cloudfunctions.net/updateTicket?client=PwbtTs9DLDu9aq8NAfh2&eventKey=2021%20MLS%20CUP&registrationId=6&ticketKey=1&subTicketKey=7aEjiCkQ0OBqfxbe103L&direction=exit
// https://us-central1-me-production-57ad6.cloudfunctions.net/updateTicket?client=x5r47Qn26xzi6dl5A7OH&eventKey=0sCKQPlELgk6ukR0IWIJ&registrationId=619E63672FCCC&ticketKey=159&subTicketKey=DNbdT9k1Y8xMNkggyzB4&direction=enter
// https://us-central1-me-production-57ad6.cloudfunctions.net/updateTicketAssignments?data={}

export const _useTicketingSubCollection = false
export const _globalTicketingName = '_ticketing'

const _globalWillCallName = 'willCall'

export const ticketKeyTypes = {
  direction: 'direction',
  eventKey: 'eventKey',
  clientKey: 'clientKey',
  registrationId: 'registrationId',
  ticketKey: 'ticketKey',
  subTicketKey: 'subTicketKey',
}

export const scannedTicketsStatusTypes = {
  inValid: 'inValid',
  nonEvent: 'nonEvent',
  valid: 'valid',
  entered: 'entered',
}

export const ticketingTypes = {
  createUserTickets: 'createUserTickets',
  groupTicketsByPhoneNumber: 'groupTicketsByPhoneNumber',
  getCurrentAppUserTickets: 'getCurrentAppUserTickets',
  handleAppUserChange: 'handleAppUserChange',
  handleAssign: 'handleAssign',
  handleClearContacts: 'handleClearContacts',
  handleCloseSubTicket: 'handleCloseSubTicket',
  handleForward: 'handleForward',
  handleGetContact: 'handleGetContact',
  handleGetWillCallByKey: 'handleGetWillCallByKey',
  handleKillScannedInfo: 'handleKillScannedInfo',
  handleMaxChange: 'handleMaxChange',
  handleScan: 'handleScan',
  handleScanned: 'handleScanned',
  handleSelectedLookupTicket: 'handleSelectedLookupTicket',
  handleSelectSubTicket: 'handleSelectSubTicket',
  handleSetContact: 'handleSetContact',
  handleShowAssign: 'handleShowAssign',
  handleShowLookup: 'handleShowLookup',
  handleShowPresent: 'handleShowPresent',
  handleShowPrint: 'handleShowPrint',
  handleShowReassign: 'handleShowReassign',
  handleShowScanner: 'handleShowScanner',
  handleShowSend: 'handleShowSend',
  handleShowTicket: 'handleShowTicket',
  handleTicketChange: 'handleTicketChange',
  handleTicketColors: 'handleTicketColors',
  handleTicketFiler: 'handleTicketFiler',
  handleTicketImage: 'handleTicketImage',
  handleUpdated: 'handleUpdated',
  handleUpdateRegistration: 'handleUpdateRegistration',
  handleUpdateTicketAssignments: 'handleUpdateTicketAssignments',
  handleUpdateTicketingItem: 'handleUpdateTicketingItem',
  handleUpdateWillCallTicket: 'handleUpdateWillCallTicket',
  handleWillCallClick: 'handleWillCallClick',
  initTickets: 'initTickets',
  initWillCall: 'initWillCall',
  initWillCallPhoneNumber: 'initWillCallPhoneNumber',
  setAllTickets: 'setAllTickets',
  setAllTicketsAlt: 'setAllTicketsAlt',
  setCurrentAppUserTickets: 'setCurrentAppUserTickets',
  setPhoneLookupTickets: 'setPhoneLookupTickets',
  setTickets: 'setTickets',
  setWillCallTickets: 'setWillCallTickets',
  setWillCallTicketz: 'setWillCallTicketz',
}

export const ticketingReducer = (state, action) => {

  const { pathViews: pathViews_state, tickets, assignedTickets, ticketKey, currentTicket, subTicketsSelected, presentedTicketsSelected, appTicketing, accessLevel } = state
  const { ticketingCollectionName, sendSmsOrEmailOnAssignment } = appTicketing ?? {}
  const { status } = currentTicket ?? {}
  const { max, given } = status ?? {}
  const remaining = max ? max - given : 0
  const { type, pathViews, ticketingCollectionName: tcn } = action
  const _assignedTickets = { ...assignedTickets }

  const _ticketingCollectionName = tcn ? tcn : ticketingCollectionName

  const { setTickets, setAllTickets, setAllTicketsAlt, handleUpdated, setWillCallTickets, setWillCallTicketz, handleUpdateTicketingItem, handleScanned, handleSetContact, handleShowScanner } = ticketingHandlers(action.dispatch)

  switch (type) {

    case ticketingTypes.handleTicketColors:
      return { ...state, ticketColors: action.ticketColors }

    // gets the initial ticket data
    case ticketingTypes.initTickets:
      if (_ticketingCollectionName) {
        fs_db.get_data_any({ pathViews, collectionName: _ticketingCollectionName, callback: setTickets })
        getTicketing(pathViews, setAllTickets, setAllTicketsAlt)
      }
      return { ...state }

    // gets the initial ticket data for willCall
    case ticketingTypes.initWillCall:
      if (_ticketingCollectionName) {
        fs_db.get_data_any({ pathViews, collectionName: _ticketingCollectionName, callback: setTickets })
        getTicketing(pathViews, setAllTickets, setAllTicketsAlt)
      }
      return { ...state }

    // gets the initial ticket data for willCall with a phoneNumber
    case ticketingTypes.initWillCallPhoneNumber:
      fs_db.get_rootData(_globalWillCallName, true, action.phoneNumber, null, null, setWillCallTicketz)
      // }
      return { ...state }

    case ticketingTypes.handleGetContact:
      getContactList(handleSetContact)
      return { ...state }

    case ticketingTypes.handleSetContact:
      return { ...state, contacts: action.contacts }

    case ticketingTypes.setTickets:
      return { ...state, tickets: action.tickets }

    case ticketingTypes.setAllTickets:
      const allGroups = groupTicketsByWillCallKey(pathViews_state, action.assignedTickets, tickets)
      const { groupedWillCalls, groupedAppUsers } = allGroups ? allGroups : []
      const ast = action.assignedTickets
      ticketInfo(ast)
      return { ...state, assignedTickets: action.assignedTickets, groupedWillCalls, groupedAppUsers }

    case ticketingTypes.getCurrentAppUserTickets:
      const { email: email_appUser, phoneNumber: phoneNumber_appuser, appUserSessionKey } = action.appUserAccess ?? {}

      const _appUserWillCallEmail = (appUserSessionKey === action.viewKey) && email_appUser && state.groupedWillCalls && state.groupedWillCalls[email_appUser] ? state.groupedWillCalls[email_appUser] : null
      const _appUserWillCallPhoneNumber = (appUserSessionKey === action.viewKey) && phoneNumber_appuser && state.groupedWillCalls && state.groupedWillCalls[phoneNumber_appuser] ? state.groupedWillCalls[phoneNumber_appuser] : null

      const hasWillCallEmail = _appUserWillCallEmail ? true : false
      const hasWillCallPhoneNumber = _appUserWillCallPhoneNumber ? true : false
      const currentAppUserTickets = getUsersAssignedTickets(tickets, assignedTickets, action.viewKey)

      return { ...state, currentAppUserTickets: currentAppUserTickets, currentAppUserHasWillCallEmail: hasWillCallEmail, currentAppUserHasWillCallPhoneNumber: hasWillCallPhoneNumber, currentTicketUserKey: action.viewKey }

    case ticketingTypes.handleGetWillCallByKey:
      const { willCallKey, willCallKeyPathView, updating } = action
      ammendWillCallTicketsByWillCallKey(state, willCallKey, willCallKeyPathView, setWillCallTickets)
      return { ...state, willCallUserKey: action.willCallKey, updating: updating }

    case ticketingTypes.setWillCallTickets:
      return { ...state, willCallTickets: action.willCallTickets, willCallOwner: action.willCallOwner, updating: false }

    case ticketingTypes.setWillCallTicketz:
      return { ...state, wct: action.wct }

    case ticketingTypes.setAllTicketsAlt:
      const _assignedTicketz = assignedTickets ? { ...assignedTickets, ...action.assignedTickets } : action.assignedTickets
      return { ...state, assignedTickets: _assignedTicketz }

    // triggered when the ticket changes on the ticketing manager page
    case ticketingTypes.handleTicketChange:
      const _ct = _assignedTickets ? _assignedTickets[action.ticketKey] : {}
      const _appUserTickets = _ct ? _ct.userTickets : null
      return { ...state, ticketKey: action.ticketKey, currentTicket: _ct, appUserTickets: _appUserTickets }

    case ticketingTypes.handleTicketImage:
      return { ...state, ticketImage: action.ticketImage }

    // triggered when the max number of tickets changes on the ticketing manager page
    case ticketingTypes.handleMaxChange:
      if (ticketKey) {
        if (!_assignedTickets[ticketKey]) { _assignedTickets[ticketKey] = { status: {} } }
        _assignedTickets[ticketKey].status.max = action.value
        ticketInfo(_assignedTickets)
        return { ...state, assignedTickets: _assignedTickets, currentTicket: _assignedTickets[ticketKey] }
      }
      return { ...state }

    // triggered when the number of tickets given to a user changes on the ticketing manager page
    case ticketingTypes.handleAppUserChange:
      const { value, itemKey } = action.data
      if (value <= remaining) {
        if (ticketKey) {
          const currentTicket = tickets[ticketKey]
          const { name } = currentTicket ?? {}
          initUserTicket(_assignedTickets, ticketKey, itemKey)
          _assignedTickets[ticketKey].userTickets[itemKey].allotment = parseInt(value)
          _assignedTickets[ticketKey].ticketName = name
          // create a subTicket eqauling the allotment number
          const subTicks = _assignedTickets[ticketKey].userTickets[itemKey].subTickets
          _assignedTickets[ticketKey].userTickets[itemKey].subTickets = createUserSubTickets(value, ticketKey, subTicks)
          ticketInfo(_assignedTickets)
          return { ...state, assignedTickets: _assignedTickets, appUserTickets: _assignedTickets[ticketKey].userTickets }
        }
      }

      return { ...state }


    case ticketingTypes.setCurrentAppUserTickets:
      return { ...state, currentAppUserTickets: action.currentAppUserTickets }

    // updates the tickets to the database
    case ticketingTypes.handleUpdateTicketAssignments:
      // updateTicketing(ticketKey, pathViews_state, action.assignedTickets, null, tickets, appTicketing, handleUpdated)
      return { ...state, updating: true }

    case ticketingTypes.handleUpdateWillCallTicket:
      fsfn_updateWillCallTicket(state, action, appTicketing).then(response_data => {
        const { success, error } = response_data ?? {}
        console.log('success', success, error)
        handleShowScanner()
      }).catch(error => {
        console.error(error)
      })
      return { ...state, showScanner: !state.showScanner, scannedData: null }

    case ticketingTypes.handleUpdateRegistration:
      fsfn_updateRegistration(action.pathViews)
      return { ...state }

    case ticketingTypes.handleScan:
      if (action.data) {
        getScannedTicket(state, action, handleScanned)
      }
      return { ...state }

    // occurs AFTER the ticket info has been scanned
    case ticketingTypes.handleScanned:

      const { selectedLookupTicketKey } = state
      const { allowInOut } = appTicketing ?? {}
      const scannedTicket = action.userTicket
      const { entered, ticketKey: ticketKey_scannedTicket } = scannedTicket ?? {}
      const allowIn = (scannedTicket && !entered) || (entered === false)
      const allowOut = allowInOut && scannedTicket && entered && (entered === true)
      const validEventTicket = ticketKey_scannedTicket === selectedLookupTicketKey

      let scannedTicketStatus;

      if (!validEventTicket) {
        scannedTicketStatus = scannedTicketsStatusTypes.nonEvent
      } else {
        scannedTicketStatus = entered ? scannedTicketsStatusTypes.entered : scannedTicketsStatusTypes.valid
      }

      const scannedInfo = {
        actualTicket: action.actualTicket,
        allowIn,
        allowOut,
        scannedData: action.scannedData,
        scannedTicket,
        scannedTicketStatus,
        ticketUserKey: action.ticketUserKey,
        validEventTicket,
      }

      return {
        ...state,
        userTicket: action.userTicket,
        scannedInfo,
      }

    // handles the selection of a subTicket
    case ticketingTypes.handleSelectSubTicket:
      const { isWillCall } = state
      const { subTicketInfo } = action
      const { subTicketKey, subTicket: selectedSubTicket } = subTicketInfo
      const { assignedTo } = selectedSubTicket ?? {}
      if (isWillCall) {
        return { ...state, subTicketInfo: subTicketInfo }
      } else {
        const sts = subTicketsSelected ? [...subTicketsSelected] : []
        if (sts.includes(subTicketKey)) {
          _.remove(sts, function (n) {
            return n === subTicketKey;
          });
        } else {
          sts.push(subTicketKey)
        }
        const pts = presentedTicketsSelected ? [...presentedTicketsSelected] : []
        if (!assignedTo || (accessLevel >= gEnums.authLevels.admin.value)) {
          if (pts.includes(subTicketKey)) {
            _.remove(pts, function (n) {
              return n === subTicketKey;
            });
          } else {
            pts.push(subTicketKey)
          }
        }
        return { ...state, subTicketsSelected: sts, presentedTicketsSelected: pts }
      }

    case ticketingTypes.handleClearContacts:
      return { ...state, contacts: null }

    case ticketingTypes.handleCloseSubTicket:
      // scannedData: action.data
      return { ...state, subTicketInfo: null }

    case ticketingTypes.handleAssign:
      // const { phoneNumber: _phoneNumber, email: _email } = action 
      const smsEmailData = sendSmsOrEmailOnAssignment && !action.unassign ? getSmsEmailData(state, action) : {}
      getDataToUpdateForAssignment(state, action, smsEmailData, handleUpdateTicketingItem)
      return { ...state, updating: true }

    case ticketingTypes.handleForward:
      const _smsEmaiData = sendSmsOrEmailOnAssignment && !action.unforward ? getSmsEmailData(state, action) : {}
      ammendWillCallSubTicket(action, assignedTickets, action.subTicket, _smsEmaiData, handleUpdateTicketingItem)
      return { ...state }

    case ticketingTypes.handleUpdateTicketingItem:
      const { dataToUpdate } = action
      updateTicketing(action.ticketKey, pathViews_state, dataToUpdate, action.smsEmailData, tickets, appTicketing, handleUpdated)
      return { ...state }

    case ticketingTypes.handleUpdated:
      return {
        ...state,
        showScanner: !state.showScanner,
        scannedData: null,
        subTicketsSelected: null,
        presentedTicketsSelected: null,
        showAssign: null,
        showSend: null,
        showReassign: null,
        updating: false
      }

    case ticketingTypes.handleShowScanner:
      return { ...state, showScanner: !state.showScanner, scannedData: null, scannedInfo: null, updating: false }

    case ticketingTypes.handleKillScannedInfo:
      return { ...state, scannedInfo: null }

    case ticketingTypes.handleWillCallClick:
      return { ...state, willCallTicket: action.willCallTicket }

    case ticketingTypes.handleShowTicket:
      if (state.showTicket) {
        return { ...state, showTicket: state.showTicket ? null : action.ticketKey, subTicketsSelected: null, presentedTicketsSelected: null, showPresent: false, showAssign: null }
      } else {
        return { ...state, showTicket: state.showTicket ? null : action.ticketKey }
      }

    case ticketingTypes.handleShowSend:
      return { ...state, showSend: state.showSend ? null : action.ticketKey }

    case ticketingTypes.handleShowAssign:
      return { ...state, showAssign: state.showAssign ? null : action.ticketKey, showPresent: null }

    case ticketingTypes.handleShowPresent:
      return { ...state, showPresent: !state.showPresent, showAssign: null }

    case ticketingTypes.handleShowPrint:
      return { ...state, showPrint: !state.showPrint, showAssign: null }

    case ticketingTypes.handleShowLookup:
      return { ...state, showLookup: !state.showLookup, showScanner: null, willCallUserKey: null }

    case ticketingTypes.setPhoneLookupTickets:
      return { ...state, phoneLookupTickets: action.phoneLookupTickets, showScanner: null }

    case ticketingTypes.handleSelectedLookupTicket:
      if (tickets && assignedTickets && action.ticketKey && tickets[action.ticketKey] && assignedTickets[action.ticketKey]) {
        const at = assignedTickets[action.ticketKey]
        const t = tickets[action.ticketKey]
        const slt = { ...t, ...at }
        return { ...state, selectedLookupTicketKey: action.ticketKey, selectedLookupTicket: slt }
      }
      return { ...state }

    case ticketingTypes.handleShowReassign:
      return { ...state, showReassign: !state.showReassign, reassignInfo: { ticket: action.ticket, subTicketKey: action.subTicketKey } }

    case ticketingTypes.handleTicketFiler:
      return { ...state, filterType: action.filterType === state.filterType ? null : action.filterType }

    case ticketingTypes.createUserTickets:
      createUserTickets(pathViews_state, state, tickets)
      createCollectionTicketing(pathViews_state, state, tickets)
      return { ...state }

    case ticketingTypes.groupTicketsByPhoneNumber:
      groupTicketsByPhoneNumber(pathViews_state, state, tickets)
      return { ...state }

    default:
      return { ...state }
  }
}

export const ticketingInitialState = (initState) => {
  return { ...initState, willCallTicketPath: getTicketPath(initState) }
};

export const ticketingHandlers = (dispatch) => {
  return {
    createUserTickets: () => { dispatch({ type: ticketingTypes.createUserTickets, dispatch }) },
    groupTicketsByPhoneNumber: () => { dispatch({ type: ticketingTypes.groupTicketsByPhoneNumber, dispatch }) },
    getCurrentAppUserTickets: (viewKey, email, phoneNumber, appUserAccess) => { dispatch({ type: ticketingTypes.getCurrentAppUserTickets, dispatch, viewKey, email, phoneNumber, appUserAccess }) },
    handleAppUserChange: (data) => { dispatch({ type: ticketingTypes.handleAppUserChange, dispatch, data }) },
    handleAssign: (ticket, ticketKey, phoneNumber, email, contact, unassign, subTicketSelected) => { dispatch({ type: ticketingTypes.handleAssign, dispatch, ticket, ticketKey, phoneNumber, email, contact, unassign, subTicketSelected }) },
    handleClearContacts: () => { dispatch({ type: ticketingTypes.handleClearContacts, dispatch }) },
    handleCloseSubTicket: (appUserTickets) => { dispatch({ type: ticketingTypes.handleCloseSubTicket, dispatch, appUserTickets }) },
    handleForward: (ticket, subTicket, phoneNumber, email, unforward) => { dispatch({ type: ticketingTypes.handleForward, dispatch, ticket, subTicket, phoneNumber, email, unforward }) },
    handleGetContact: () => { dispatch({ type: ticketingTypes.handleGetContact, dispatch }) },
    handleGetWillCallByKey: (willCallKey, willCallKeyPathView, updating) => { dispatch({ type: ticketingTypes.handleGetWillCallByKey, dispatch, willCallKey, willCallKeyPathView, updating }) },
    handleKillScannedInfo: () => { dispatch({ type: ticketingTypes.handleKillScannedInfo, dispatch }) },
    handleMaxChange: (value) => { dispatch({ type: ticketingTypes.handleMaxChange, dispatch, value }) },
    handleScan: (data) => { dispatch({ type: ticketingTypes.handleScan, dispatch, data }) },
    handleScanned: (scannedData, actualTicket, userTicket, ticketUserKey) => { dispatch({ type: ticketingTypes.handleScanned, dispatch, scannedData, actualTicket, userTicket, ticketUserKey }) },
    handleSelectedLookupTicket: (ticketKey) => { dispatch({ type: ticketingTypes.handleSelectedLookupTicket, dispatch, ticketKey }) },
    handleSelectSubTicket: (subTicketInfo) => { dispatch({ type: ticketingTypes.handleSelectSubTicket, dispatch, subTicketInfo }) },
    handleSetContact: (contacts) => { dispatch({ type: ticketingTypes.handleSetContact, dispatch, contacts }) },
    handleShowAssign: (ticketKey) => { dispatch({ type: ticketingTypes.handleShowAssign, dispatch, ticketKey }) },
    handleShowLookup: () => { dispatch({ type: ticketingTypes.handleShowLookup, dispatch }) },
    handleShowPresent: () => { dispatch({ type: ticketingTypes.handleShowPresent, dispatch }) },
    handleShowPrint: () => { dispatch({ type: ticketingTypes.handleShowPrint, dispatch }) },
    handleShowReassign: (ticket, subTicketKey) => { dispatch({ type: ticketingTypes.handleShowReassign, dispatch, ticket, subTicketKey }) },
    handleShowScanner: () => { dispatch({ type: ticketingTypes.handleShowScanner, dispatch }) },
    handleShowSend: (ticketKey) => { dispatch({ type: ticketingTypes.handleShowSend, dispatch, ticketKey }) },
    handleShowTicket: (ticketKey) => { dispatch({ type: ticketingTypes.handleShowTicket, dispatch, ticketKey }) },
    handleTicketChange: (ticketKey) => { dispatch({ type: ticketingTypes.handleTicketChange, dispatch, ticketKey }) },
    handleTicketColors: (ticketColors) => { dispatch({ type: ticketingTypes.handleTicketColors, dispatch, ticketColors }) },
    handleTicketFiler: (filterType) => { dispatch({ type: ticketingTypes.handleTicketFiler, dispatch, filterType }) },
    handleTicketImage: (ticketImage) => { dispatch({ type: ticketingTypes.handleTicketImage, dispatch, ticketImage }) },
    handleUpdated: () => { dispatch({ type: ticketingTypes.handleUpdated, dispatch }) },
    handleUpdateRegistration: (pathViews) => { dispatch({ type: ticketingTypes.handleUpdateRegistration, dispatch, pathViews }) },
    handleUpdateTicketAssignments: (itemKey, assignedTickets) => { dispatch({ type: ticketingTypes.handleUpdateTicketAssignments, dispatch, itemKey, assignedTickets }) },
    handleUpdateTicketingItem: (ticketKey, dataToUpdate, smsEmailData) => { dispatch({ type: ticketingTypes.handleUpdateTicketingItem, dispatch, ticketKey, dataToUpdate, smsEmailData }) },
    handleUpdateWillCallTicket: (ticketKey, subTicketKey, registrationId, credit) => { dispatch({ type: ticketingTypes.handleUpdateWillCallTicket, dispatch, ticketKey, subTicketKey, credit, registrationId }) },
    handleWillCallClick: (willCallTicket) => { dispatch({ type: ticketingTypes.handleWillCallClick, dispatch, willCallTicket }) },
    initTickets: (pathViews) => { dispatch({ type: ticketingTypes.initTickets, dispatch, pathViews }) },
    initWillCall: (pathViews, ticketingCollectionName) => { dispatch({ type: ticketingTypes.initWillCall, dispatch, pathViews, ticketingCollectionName }) },
    initWillCallPhoneNumber: (phoneNumber) => { dispatch({ type: ticketingTypes.initWillCallPhoneNumber, dispatch, phoneNumber }) },
    setAllTickets: (assignedTickets) => { dispatch({ type: ticketingTypes.setAllTickets, dispatch, assignedTickets }) },
    setAllTicketsAlt: (assignedTickets, subKey) => { dispatch({ type: ticketingTypes.setAllTicketsAlt, dispatch, assignedTickets, subKey }) },
    setCurrentAppUserTickets: (currentAppUserTickets) => { dispatch({ type: ticketingTypes.setCurrentAppUserTickets, dispatch, currentAppUserTickets }) },
    setPhoneLookupTickets: (phoneLookupTickets) => { dispatch({ type: ticketingTypes.setPhoneLookupTickets, dispatch, phoneLookupTickets }) },
    setTickets: (tickets) => { dispatch({ type: ticketingTypes.setTickets, dispatch, tickets }) },
    setWillCallTickets: (willCallTickets, willCallOwner, willCallKey) => { dispatch({ type: ticketingTypes.setWillCallTickets, dispatch, willCallTickets, willCallOwner, willCallKey }) },
    setWillCallTicketz: (wct) => { dispatch({ type: ticketingTypes.setWillCallTicketz, dispatch, wct }) },
  }
}

const initUserTicket = (assignedTickets, ticketKey, itemKey) => {
  if (!assignedTickets[ticketKey]) { assignedTickets[ticketKey] = {} }
  if (!assignedTickets[ticketKey].userTickets) { assignedTickets[ticketKey].userTickets = {} }
  if (!assignedTickets[ticketKey].userTickets[itemKey]) { assignedTickets[ticketKey].userTickets[itemKey] = {} }
}

const getSmsEmailData = (state, action) => {

  const { appTicketing } = state
  const { sendSmsOrEmailOnAssignment, smsMessageText, emailSubjectText, emailBodyText, emailSender } = appTicketing ?? {}
  const { phoneNumber: _phoneNumber, email: _email } = action

  let _smsData;
  let _emailData;

  if (sendSmsOrEmailOnAssignment && !action.unassign) {
    if (_phoneNumber) {
      _smsData = { to: _phoneNumber, text: inviteText(state.willCallTicketPath, null, smsMessageText, false) }
    }
    if (_email) {
      _emailData = {
        email: {
          recipient: _email,
          sender: emailSender ? emailSender : 'support@meetingevolution.net',
          subject: emailSubjectText,
          body: inviteText(state.willCallTicketPath, null, emailBodyText, true),
        }
      }
    }
  }

  return {
    smsData: _smsData,
    emailData: _emailData
  }
}

const createUserSubTickets = (count, ticketKey, subTicks) => {
  if (count && count > 0) {
    const userTickets = []
    for (var i = 1; i <= count; i++) {
      const st = _.find(subTicks, { subTicketIndex: i })
      if (st) {
        userTickets.push({ ...st })
      } else {
        userTickets.push({
          entered: false,
          subTicketKey: createFsDocKey('subTicket'),
          subTicketIndex: i,
          ticketKey,
        })
      }
    }
    return userTickets
  }
  return {}
}

const getUsersAssignedTickets = (tickets, assignedTickets, viewKey) => {
  let currentAppUserTickets = null
  Object.keys(assignedTickets).forEach(ticketKey => {
    const assignedTicket = assignedTickets[ticketKey]
    const { userTickets } = assignedTicket ?? {}
    const mainTicket = tickets[ticketKey]
    if (userTickets) {
      const _userTicket = userTickets ? userTickets[viewKey] : {}
      if (_userTicket) {
        const { subTickets } = _userTicket
        if (subTickets && subTickets.length > 0) {
          if (!currentAppUserTickets) { currentAppUserTickets = {} }
          currentAppUserTickets[ticketKey] = {
            ...mainTicket,
            _itemKey: ticketKey,
            subTickets: subTickets
          }
        }
      }
    }
  })
  return currentAppUserTickets
}

/** Callsback all the tickets assigneTo or forwardedTo the willCallKey or willCallKey2(if it exists) */
const ammendWillCallTicketsByWillCallKey = (state, willCallKey1, willCallKey2, callback) => {

  const { groupedWillCalls, tickets, selectedLookupTicketKey } = state
  const _willCallKeyPathView = willCallKey2 ? willCallKey2 : null
  const _key = _willCallKeyPathView ? _willCallKeyPathView : willCallKey1

  const willCallItem = _key ? groupedWillCalls[_key] : null

  if (willCallItem) {
    const _tickets = getWillCallBy(tickets, willCallItem, _key, _willCallKeyPathView, selectedLookupTicketKey)
    if (callback) {
      callback(_tickets, null, _key)
    } else {
      return _tickets
    }
  } else {
    if (callback) {
      callback(null)
    } else {
      return {}
    }
  }
}

/** Retuns all the tickets assigneTo or forwardedTo the willCallKey */
const getWillCallBy = (tickets, willCallItem, willCallKey, willCallKeyPathView, selectedLookupTicketKey) => {
  const _tickets = {}
  Object.keys(willCallItem).forEach(key => {
    const wci = willCallItem[key]
    const { tickets: wcTickets } = wci ?? {}
    Object.keys(wcTickets).forEach(wctKey => {
      const wct = wcTickets[wctKey]
      const { ticketKey, subTicketKey, subTicketIndex, entered, assignedTo, registrationId, forwardedTo } = wct

      // make lowerCase to eliminate type mismatch
      const _willCallKey = willCallKey ? willCallKey.toLowerCase() : null
      const _assignedTo = assignedTo ? assignedTo.toLowerCase() : null
      const _forwardedTo = forwardedTo ? forwardedTo.toLowerCase() : null

      const ticket = tickets ? tickets[ticketKey] : null
      let allowTicket = false
      if (willCallKeyPathView) {
        if (_willCallKey === _forwardedTo || _willCallKey === _assignedTo) {
          allowTicket = true
          if (selectedLookupTicketKey && selectedLookupTicketKey !== ticketKey) {
            allowTicket = false
          }
        }
      } else {
        allowTicket = true
      }
      if (ticket && allowTicket) {
        if (!_tickets[ticketKey]) { _tickets[ticketKey] = { ...ticket, subTickets: [] } }
        const _ticket = { ...ticket }
        _ticket._itemKey = ticketKey
        const userTicket = { ticketKey, subTicketKey, subTicketIndex, assignedTo, entered, registrationId, forwardedTo }
        _tickets[ticketKey]._itemKey = ticketKey
        _tickets[ticketKey].subTickets.push(userTicket)
      }
    })
  })
  return _tickets
}

const groupTicketsByWillCallKey = (pathViews, assignedTickets, tickets) => {

  const _userTickets = {}
  const willCalls_key = {}
  const willCalls_key_full = {}

  // loop the assignedTickets
  Object.keys(assignedTickets).forEach(key => {

    const assignedTicket = assignedTickets[key]
    const ticket = tickets ? tickets[key] : {}
    const { name: fullTicketName } = ticket ?? {}
    const { name: ticketName, userTickets } = assignedTicket ?? {}
    const _ticketName = ticketName ? ticketName : fullTicketName

    if (userTickets) {
      // loop the userTickets
      Object.keys(userTickets).forEach(userKey => {

        const userTicket = userTickets[userKey]

        if (userTicket) {

          const { subTickets } = userTicket

          // loop the user's subTickets
          if (subTickets && _.isArray(subTickets)) {

            // create the userTicket for the userKey
            if (!_userTickets[userKey]) { _userTickets[userKey] = { tickets: {} } }
            if (!_userTickets[userKey].tickets[key]) {
              _userTickets[userKey].tickets[key] = {
                ...ticket,
                subTickets: subTickets
              }
            }

            subTickets.forEach(subTicket => {

              const { assignedTo, forwardedTo, ticketKey, subTicketKey } = subTicket ?? {}

              if (assignedTo) {
                const { events: eventKey, clients: clientKey } = pathViews ?? {}
                if (!willCalls_key_full[assignedTo]) { willCalls_key_full[assignedTo] = { tickets: {} } }

                if (!willCalls_key[assignedTo]) { willCalls_key[assignedTo] = {} }
                if (!willCalls_key[assignedTo][eventKey]) { willCalls_key[assignedTo][eventKey] = { tickets: {} } }
                const tkey = ticketKey + '_' + subTicketKey
                willCalls_key[assignedTo][eventKey].tickets[tkey] = { ...subTicket, ticketName: _ticketName }
                willCalls_key_full[assignedTo].tickets[tkey] = { ...subTicket, ticketName: _ticketName, eventKey, clientKey }
              }

              if (forwardedTo) {
                const { events: eventKey, clients: clientKey } = pathViews ?? {}
                if (!willCalls_key_full[forwardedTo]) { willCalls_key_full[forwardedTo] = { tickets: {} } }

                if (!willCalls_key[forwardedTo]) { willCalls_key[forwardedTo] = {} }
                if (!willCalls_key[forwardedTo][eventKey]) { willCalls_key[forwardedTo][eventKey] = { tickets: {} } }
                const tkey = ticketKey + '_' + subTicketKey
                willCalls_key[forwardedTo][eventKey].tickets[tkey] = { ...subTicket, ticketName: _ticketName }
                willCalls_key_full[forwardedTo].tickets[tkey] = { ...subTicket, ticketName: _ticketName, eventKey, clientKey }
              }
            })
          }
        }
      })
    }
  })

  return {
    groupedWillCalls: willCalls_key,
    groupedAppUsers: _userTickets
  }

  // return willCalls_key

}

const updateTicketingForAssignment = (state, ticketKey, registrationId, subTicketKey, assignedTo) => {
  const { assignedTickets } = state ?? {}
  if (assignedTickets && assignedTickets[ticketKey] && assignedTickets[ticketKey].userTickets && assignedTickets[ticketKey].userTickets[registrationId] && assignedTickets[ticketKey].userTickets[registrationId][subTicketKey]) {
    const ust = assignedTickets[ticketKey].userTickets[registrationId][subTicketKey]
    ust.assignedTo = assignedTo
    const dataToUpdate = {
      [ticketKey]: {
        userTickets: {
          [registrationId]: {
            [subTicketKey]: ust
          }
        }
      }
    }
    return dataToUpdate
  }
}

const getDataToUpdateForAssignment = (state, action, smsEmailData, callback) => {

  const { assignedTickets, currentTicketUserKey, subTicketsSelected } = state ?? {}
  const { ticketKey, phoneNumber, email, contact, unassign, subTicketSelected, userTicket } = action ?? {}
  const { ticketKey: ticketKey_sub, registrationId, subTicketKey } = userTicket ?? {}
  const contactName = contact && contact.name ? contact.name : null

  if (userTicket) {
    const dataToUpdate = updateTicketingForAssignment(state, ticketKey_sub, registrationId, subTicketKey, email ? email : phoneNumber)
    callback(ticketKey, dataToUpdate)
  } else {
    const _assignedTickets = { ...assignedTickets }
    const assignedTicket = _assignedTickets ? _assignedTickets[ticketKey] : null
    if (assignedTicket) {
      const { userTickets } = assignedTicket ?? {}
      if (userTickets && (subTicketsSelected || subTicketSelected)) {
        const userSubTicket = userTickets[currentTicketUserKey]
        if (userSubTicket) {
          const { subTickets, allotment } = userSubTicket
          if (subTickets && _.isArray(subTickets)) {
            subTickets.forEach(subTicket => {
              delete subTicket._itemKey
              if (subTicketsSelected.includes(subTicket.subTicketKey)) {
                if (unassign) {
                  delete subTicket.assignedTo
                  delete subTicket.assignedToName
                  delete subTicket.forwardedTo
                } else {
                  subTicket.assignedTo = email ? email : phoneNumber
                  if (contactName) { subTicket.assignedToName = contactName }
                }
              }
            })
          }
          const dataToUpdate = {
            [ticketKey]: {
              userTickets: {
                [currentTicketUserKey]:
                {
                  allotment,
                  subTickets: subTickets
                }
              }
            }
          }
          callback(ticketKey, dataToUpdate, smsEmailData)
        }
      }
    }
  }
}

const getAssignedTicketSubTickets = (assignedTickets, ticketKey, userKey) => {
  if (assignedTickets
    && assignedTickets[ticketKey]
    && assignedTickets[ticketKey]['userTickets']
    && assignedTickets[ticketKey]['userTickets'][userKey]
    && assignedTickets[ticketKey]['userTickets'][userKey]['subTickets']) {
    return assignedTickets[ticketKey]['userTickets'][userKey]['subTickets']
  }
}

const ammendWillCallSubTicket = (action, assignedTickets, subTicket, smsEmailData, callback) => {
  const { phoneNumber, email, unforward } = action ?? {}
  const { ticketKey, registrationId, subTicketKey } = subTicket
  const subTickets = getAssignedTicketSubTickets(assignedTickets, ticketKey, registrationId)
  if (subTickets) {
    const subTicket = _.find(subTickets, { subTicketKey: subTicketKey })
    if (subTicket) {
      if (unforward) {
        delete subTicket.forwardedTo
      } else {
        subTicket.forwardedTo = email ? email : phoneNumber
      }
      const dataToUpdate = {
        [ticketKey]: {
          userTickets: {
            [registrationId]: {
              subTickets: subTickets
            }
          }
        }
      }
      callback(ticketKey, dataToUpdate, smsEmailData)
    }
  }
}


// https://events.mlssoccer.com/index.php/615b5181ce264-mobile-attendance-enter.html?619E63C6C2FA3~159~SuEmUYICHmpwDcCtGzXR~XX
// https://events.mlssoccer.com/index.php/615b5181ce264-mobile-attendance-enter.html?619E63C6C2Fa3~159~9AMJVv7jiLzyTYE6I8AG~XX


/** Gets the scanned ticket */
const getScannedTicket = (state, action, handleScanned) => {

  const stupidScan = true

  const { tickets, assignedTickets, scannedData } = state ?? {}
  const { data } = action ?? {}

  const sd = scannedData ? scannedData : data
  let _scannedData;

  if (sd.indexOf('~') > 0) {
    const _sds = sd.split('~')
    if (_sds) {
      _scannedData = {}
      if (stupidScan && _sds.length === 4) {
        _scannedData['registrationId'] = _sds[0]
        _scannedData['ticketKey'] = _sds[1]
        _scannedData['subTicketKey'] = _sds[2]
      } else {
        _sds.forEach((sdi, index) => {
          if (!isOdd(index)) {
            _scannedData[_sds[index]] = _sds[index + 1]
          }
        })
      }
    }
  } else {
    _scannedData = sd
  }

  const { ticketKey, subTicketKey } = _scannedData

  if (tickets && ticketKey && tickets[ticketKey]) {
    const _actualTicket = tickets[ticketKey]
    _actualTicket._itemKey = ticketKey
    if (subTicketKey && assignedTickets && assignedTickets[ticketKey] && assignedTickets[ticketKey].userTickets) {
      const _userTickets = assignedTickets[ticketKey].userTickets
      if (_userTickets) {
        Object.keys(_userTickets).forEach(ticketUserKey => {

          const userTicket = _userTickets[ticketUserKey]
          const { subTickets } = userTicket ?? {}
          const _userTicket = _.find(subTickets, { subTicketKey: subTicketKey })
          if (_userTicket) {
            _userTicket._itemKey = subTicketKey
            if (handleScanned) {
              // scannedData, actualTicket, userTicket, ticketUserKey
              handleScanned(_scannedData, _actualTicket, _userTicket, ticketUserKey)
            } else {
              return assignedTickets
            }
          }
        })
      }
    }
  }
}

const getContactList = async (callback) => {

  var api = (navigator.contacts || navigator.mozContacts);

  if (api && !!api.select) { // new Chrome API

    try {
      const props = ['name', 'tel', 'icon', 'email']
      const opts = { multiple: false };
      const contacts = await navigator.contacts.select(props, opts);
      callback(contacts);
    } catch (ex) {
      callback([]);
      // Handle any errors here.
    }
  }
}

const ticketInfo = (assignedTickets) => {
  if (assignedTickets) {
    Object.keys(assignedTickets).forEach(ticketKey => {
      const assignedTicket = assignedTickets[ticketKey]
      let _allotment = 0
      let entered = 0
      let assignedTo = 0
      let _max = 0
      if (assignedTicket) {
        const { userTickets, status } = assignedTicket ?? {}
        const { max } = status ?? {}
        _max = max
        if (userTickets) {
          Object.keys(userTickets).forEach(userKey => {
            const userTicket = userTickets[userKey]
            if (userTicket) {
              const { allotment, subTickets } = userTicket
              _allotment += parseInt(allotment)
              if (subTickets && _.isArray(subTickets)) {
                subTickets.forEach(subTicket => {
                  const { entered: u, assignedTo: at } = subTicket ?? {}
                  if (u) { entered++ }
                  if (at) { assignedTo++ }
                  subTicket.registrationId = userKey
                })
              }
            }
          })
        }
      }
      assignedTicket.status = {
        allotment: _allotment,
        entered,
        assignedTo,
        max: _max
      }
    })
  }
}

const getTicketPath = (state) => {
  const { rootPaths } = state
  let _baseUrl = isMeProd() ? 'https://mobile.events.meetingevolution.net/#' : 'https://mobiledev.events.meetingevolution.net/#'
  if (isLocalhost()) { _baseUrl = 'http://localhost:3000/#' }
  const tp = _baseUrl + rootPaths.events + 'willCall'
  return tp
}

const inviteText = (willCallTicketPath, willCallNumber, smsMessageText, isEmail) => {
  let text = smsMessageText ? smsMessageText + '\r\n' : 'You have been invited to an event. Here is your link to your will call ticket(s):\r\n'
  const wctp = willCallTicketPath.replace(/\s/g, '%20')
  text += wctp
  if (isEmail) {
    text += 'Email'
  } else {
    text += 'Phone'
  }
  if (willCallNumber) { text += '/' + willCallNumber }

  return text
}

const createCollectionTicketing = (pathViews_state, ticketing_state, tickets) => {

  const { assignedTickets } = ticketing_state

  const _ticketsBaseRef = createRefPath_event(pathViews_state, ['_altTicketing'])

  // loop the assignedTickets
  Object.keys(assignedTickets).forEach(ticketKey => {

    // const ticket = tickets[ticketKey]
    // const { name: ticketName } = ticket ?? {}

    const assignedTicket = assignedTickets[ticketKey]
    const { userTickets } = assignedTicket

    const ticketInfo = assignedTicket
    delete ticketInfo.userTickets

    const _ticketRef = createRefPath([ticketKey], _ticketsBaseRef) // _ticketsBaseRef.doc(ticketKey)
    fs_set_doc(_ticketRef, ticketInfo).then(res => {
      Object.keys(userTickets).forEach(userKey => {
        const userTicket = userTickets[userKey]
        const _colRef = createRefPath(['ticketHolders', userKey], _ticketRef)
        fs_set_doc(_colRef, userTicket)
        // const collRef = _ticketRef.collection('ticketHolders').doc(userKey)
        // collRef.set(userTicket).then(ress => {

        // })
      })
    })

    // update the ticket with KEY
    // _ticketRef.set(ticketInfo).then(res => {
    //   Object.keys(userTickets).forEach(userKey => {
    //     const userTicket = userTickets[userKey]
    //     const collRef = _ticketRef.collection('ticketHolders').doc(userKey)
    //     collRef.set(userTicket).then(ress => {

    //     })
    //   })
    // })
  })
}

const createUserTickets = (pathViews_state, ticketing_state, tickets) => {

  const user_tickets = {}
  const { assignedTickets } = ticketing_state

  // loop the assignedTickets
  Object.keys(assignedTickets).forEach(ticketKey => {

    const ticket = tickets[ticketKey]
    const { name: ticketName } = ticket ?? {}

    const assignedTicket = assignedTickets[ticketKey]
    const { userTickets } = assignedTicket

    // loop the userTickets
    Object.keys(userTickets).forEach(uk => {

      const userTicket = userTickets[uk]
      const { subTickets } = userTicket

      if (!user_tickets[uk]) { user_tickets[uk] = { userKey: uk, tickets: {} } }
      if (!user_tickets[uk].tickets[ticketKey]) { user_tickets[uk].tickets[ticketKey] = { ticketName, subTickets: [] } }

      user_tickets[uk].tickets[ticketKey].subTickets = subTickets

      // user_tickets[uk][ticketKey].subTickets = subTickets
    })
  })

  if (user_tickets) {
    Object.keys(user_tickets).forEach(key => {
      const user_ticket = user_tickets[key]
      const { tickets } = user_ticket
      const _userRef = createRefPath_event(pathViews_state, ['_user_ticketing', key])
      let dataToUpdate = user_ticket
      delete dataToUpdate.tickets

      fs_set_doc(_userRef, dataToUpdate).then(res => {
        if (tickets) {
          Object.keys(tickets).forEach(tKey => {
            const ticket = tickets[tKey]
            const _updateRef = createRefPath(['tickets', tKey], _userRef)
            // const updateRef = collectionRef.doc(tKey)
            fs_set_doc(_updateRef, ticket).then(res => {
              console.log('c updated')
            })
          })
        }
      })
    })
  }
}

const groupTicketsByPhoneNumber = (pathViews_state, ticketing_state, tickets) => {

  const { clients: clientKey, events: eventKey } = pathViews_state

  const willCalls = {}
  const { assignedTickets } = ticketing_state

  Object.keys(assignedTickets).forEach(key => {
    const assignedTicket = assignedTickets[key]
    const ticket = tickets[key]
    const { name: ticketName } = ticket ?? {}
    const { userTickets } = assignedTicket ?? {}
    if (userTickets) {
      Object.keys(userTickets).forEach(userKey => {
        const userTicket = userTickets[userKey]
        if (userTicket) {
          const { subTickets } = userTicket
          // loop the user's subTickets
          subTickets.forEach((subTicket) => {
            // @ts-ignore
            const { assignedTo: willCallKey, ticketKey, subTicketKey } = subTicket ?? {}
            if (willCallKey) {
              // @ts-ignore
              if (!willCalls[willCallKey]) {
                willCalls[willCallKey] = {
                  willCallKey,
                  tickets: {}
                }
              }
              const tkey = ticketKey + '_' + subTicketKey
              willCalls[willCallKey].tickets[tkey] = { ...subTicket, eventKey, clientKey }
              if (ticketName) {
                willCalls[willCallKey].tickets[tkey].ticketName = ticketName
              }
            }
          })
        }
      })
    }
  })
}