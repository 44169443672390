import { getAuth, updateProfile } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { convertSnapshot } from "../../cnr/contexts/contextHelpers";
import { dispatchProps } from "../../cnr/reducers/reducerHelpers/dispatchProps";
import { createInitials } from "../../common/dataAdjust";
import { gEnums } from "../../enums/globalEnums";
import { createRefPath, createRefPath_client } from "../../firestoreData/appData/appRefPaths";
import { fs_set_doc } from "../../firestoreData/appData/fsData";
import { fs_db } from "../../firestoreData/appData/fsAppData";

const profileCollection = 'clients'
const _allowProfileUpdate = true

/** returns a reference for `clients/clientKey/profiles` */
export const getClientProfileRef = (pathViews) => {
  const fs = fs_db.get_fs()
  return collection(fs, createRefPath([profileCollection, pathViews[profileCollection], 'profiles']));
}

/** returns a reference for `clients/clientKey/appAdmins` */
export const getAppAdminRef = (pathViews) => {
  const fs = fs_db.get_fs()
  return collection(fs, createRefPath([profileCollection, pathViews[profileCollection], 'appAdmins']));
}

export const updateAppProfileDirect = async (appUserSession, pathViews, profileData, callback) => {

  let updateData = {}
  const { appUserAccessType, email } = appUserSession ?? {}

  let _profileType;

  switch (appUserAccessType) {
    case gEnums.appUserAccessTypes.admin:
    case gEnums.appUserAccessTypes.superAdmin:
    case gEnums.appUserAccessTypes.superAdminDeveloper:
      _profileType = gEnums.profileTypes.adminProfiles
      break;
    default:
      _profileType = gEnums.profileTypes.clientProfiles
      break
  }

  switch (_profileType) {
    case gEnums.profileTypes.adminProfiles:
      updateData = {
        displayName: profileData.displayName,
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        initials: profileData.initials,
      }
      const updateProps_admin = ['firstName', 'lastName', 'initials', 'email', 'phoneNumber', 'street', 'city', 'state', 'zip', 'displayName', 'aboutMe', 'bio', 'locationSettings', 'notificationSettings', 'permissionSettings']
      ammendUpdateData(updateProps_admin, profileData, updateData)
      if (profileData.aboutMe) { updateData.aboutMe = profileData.aboutMe }
      if (profileData.bio) { updateData.bio = profileData.bio }
      if (profileData.notificationSettings) { updateData.notificationSettings = profileData.notificationSettings }
      if (profileData.permissionSettings) { updateData.permissionSettings = profileData.permissionSettings }
      if (profileData.profileUpdated) { updateData.profileUpdated = profileData.profileUpdated }
      break;

    default:
      updateData = {}
      const updateProps = ['firstName', 'lastName', 'initials', 'email', 'phoneNumber', 'street', 'city', 'state', 'zip', 'displayName',
        'aboutMe', 'bio',
        'hideAboutMeFromApp', 'hideAddressFromApp', 'hideBioFromApp', 'hideEmailFromApp', ' hidePhoneNumberFromApp',
        'locationSettings', 'notificationSettings', 'permissionSettings', 'disclaimerResponses',
        'appUserPrivacy'
      ]
      ammendUpdateData(updateProps, profileData, updateData)
      if (!profileData.displayName && updateData.firstName && updateData.lastName) { updateData.displayName = updateData.firstName + ' ' + updateData.lastName }
      if (!updateData.initials && updateData.displayName) { updateData.initials = createInitials(updateData.displayName) }
      if (profileData.profileUpdated) { updateData.profileUpdated = profileData.profileUpdated }
      break;
  }

  let _baseRef;

  switch (_profileType) {
    case gEnums.profileTypes.adminProfiles:
      _baseRef = createRefPath(['profiles'])
      break;
    default:
      _baseRef = createRefPath_client(pathViews, ['profiles'])
  }

  const _fs = fs_db.get_fs()
  const cr = collection(_fs, _baseRef)
  const q = query(cr, where("email", "==", email));

  getDocs(q).then(data => {
    if (!data.empty) {
      const itemData = convertSnapshot(data, false, { returnFirstObject: true })
      const { _itemKey } = itemData
      const _setRef = createRefPath([_itemKey], _baseRef)
      if (_allowProfileUpdate) {
        updateAuthProfile(profileData)
        fs_set_doc(_setRef, updateData, true, callback)
      } else {
        console.log('_setRef', _setRef, updateData)
      }

    } else {
      const _addRef = createRefPath([email], _baseRef)
      updateData.email = email
      if (_allowProfileUpdate) {
        updateAuthProfile(profileData)
        fs_set_doc(_addRef, updateData, callback)
      } else {
        console.log('_addRef', _addRef, updateData)
      }
    }
  }).catch(error => {
    callback({ type: dispatchProps.error, error })
  })
}

const updateAuthProfile = (profileData) => {
  const auth = getAuth()
  const currentUser = auth.currentUser
  if (!currentUser.displayName) {
    // update profile if display name is missing  
    updateProfile(auth.currentUser, {
      displayName: profileData.firstName + ' ' + profileData.lastName
    }).then(() => {
      // return { success: true, signInResponseType: signInResponseTypes.signInSuccess }
      // ...
    }).catch((error) => {
      // return { success: true, signInResponseType: signInResponseTypes.signInSuccess }
      // ...
    });
  } else {
    // resp = { success: true, signInResponseType: signInResponseTypes.signInSuccess }
  }
}

const ammendUpdateData = (updateProps, profileData, updateData) => {
  updateProps.forEach(prop => {
    if (profileData[prop]) { updateData[prop] = profileData[prop] }
  })
}