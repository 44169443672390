import _ from 'lodash';
import { consoleStart } from '../../../App';
import { _appUrlPaths, getAppConfigSettings, getFbConfig } from '../../../project/appConfiguration';
import { mobileDeviceTypes, parentStyleOptions } from '../../devices';
import { gEnums } from '../../enums/globalEnums';
import { updateSettings } from '../../firestoreData/settings/updateSettings';
import { fsfn_settings } from '../../functions/fbSettingsFunctions';
import { getAppSettingsFromDb, getHCEAppSettings } from '../../viewSettings/actions/getSettings';
import { openExternal } from '../../viewSettings/helpers/settingsLinks';
import { grts, responseHandlers } from './reducerHelpers/dispatchProps';
import { fsGet_timeStamps } from '../../firestoreData/updates/subUpdates/fsTimeStamps';

const _useSettingsDelay = false

const triggerUrlTypes = {
  auth: 'auth',
  cloudDataConsole: 'cloudDataConsole',
  cloudStorageConsole: 'cloudStorageConsole',
  credentials: 'credentials',
  database: 'database',
  databaseFunctions: 'databaseFunctions',
  databaseSettings: 'databaseSettings',
  databaseStorage: 'databaseStorage',
  development: 'development',
  emailConfirmation: 'emailConfirmation',
  googleAnalytics: 'googleAnalytics',
  production: 'production',
  realtimeDatabase: 'realtimeDatabase',
  serviceAccount: 'serviceAccount',
  serviceApiAccount: 'serviceApiAccount',
  signInDemo: 'signInDemo',
}

const rts = {
  handleAmmend_appSettings: 'handleAmmend_appSettings',
  handleAmmend_hceAppSettings: 'handleAmmend_hceAppSettings',
  handleAmmend_modifiedAppSettings: 'handleAmmend_modifiedAppSettings',
  handleCommit_settings: 'handleCommit_settings',
  handleCommitSettingsOn: 'handleCommitSettingsOn',
  handleCopySettings: 'handleCopySettings',
  handleDatabaseRefresh: 'handleDatabaseRefresh',
  handleDataSourceType: 'handleDataSourceType',
  handleGet_hceSettings_all: 'handleGet_hceSettings_all',
  handleGet_hceSettings: 'handleGet_hceSettings',
  handleGet_timeStamps: 'handleGet_timeStamps',
  handleOpenSettingsItem: 'handleOpenSettingsItem',
  handlePropOverride: 'handlePropOverride',
  handleSandboxOn: 'handleSandboxOn',
  handleSet_timeStamps: 'handleSet_timeStamps',
  handleSetAppChange: 'handleSetAppChange',
  handleSetDevice: 'handleSetDevice',
  handleSettingsClose: 'handleSettingsClose',
  handleSettingsOff: 'handleSettingsOff',
  handleShow_appDashboard: 'handleShow_appDashboard',
  handleShow_homeScreenPrompt: 'handleShow_homeScreenPrompt',
  handleShow_pageViewer: 'handleShow_pageViewer',
  handleShowHomeScreenHelp: 'handleShowHomeScreenHelp',
  handleSplash: 'handleSplash',
  handleStartSplash: 'handleStartSplash',
  handleThemeSelect: 'handleThemeSelect',
  handleToggle_altDatabaseOn: 'handleToggle_altDatabaseOn',
  handleToggle_authOn: 'handleToggle_authOn',
  handleToggle_dataOverrideOn: 'handleToggle_dataOverrideOn',
  handleToggle_dbOrigin: 'handleToggle_dbOrigin',
  handleToggle_globalPreviewOn: 'handleToggle_globalPreviewOn',
  handleToggle_helpOn: 'handleToggle_helpOn',
  handleToggle_highlightThemesOn: 'handleToggle_highlightThemesOn',
  handleToggle_pagePreviewOn: 'handleToggle_pagePreviewOn',
  handleToggle_settingsLeftOn: 'handleToggle_settingsLeftOn',
  handleToggle_settingsRightOn: 'handleToggle_settingsRightOn',
  handleToggle_sortingOn: 'handleToggle_sortingOn',
  handleUpdateAppReady: 'handleUpdateAppReady',
  handleUpdateAppSettingsBlank: 'handleUpdateAppSettingsBlank',
  handleUpdateHomeSettings: 'handleUpdateHomeSettings',
  handleError_app: 'handleError_app',
  updateSettingsSuccess: 'updateSettingsSuccess',
  ...grts
}

export const appSettingsInitialState = (props) => {
  const { hp, allowSettingsListen, authUid, cache_settings } = props ?? {}
  const mds = mobileDeviceTypes()
  const appConfigSettings = getAppConfigSettings()
  return {
    allAppSettings: null, //cache_settings ? cache_settings : null,
    allowSettingsListen,
    altDatabaseOn: false,
    appConfigSettings,
    appReady: false,
    appSettingsOn: false,
    authUid,
    currentDevice: getDevice(mds, 'samsung'),
    dataRefreshOn: 1,
    dbType: 'dev',
    desktopOn: false,
    helpOn: false,
    highlightThemesOn: false,
    hp,
    parentStyleOptions: parentStyleOptions(),
    sandboxOn: false,
    settingsPreviewOn: false,
    showSplash: true,
    showStartSplash: true,
    sp: null,
    updateProps: null,
    view: null,
  }
};

export const appSettingsReducer = (state, action) => {

  const { type, settings, settingsDocName, pathViews, pathname, dispatch } = action
  const allAppSettings = Object.assign({}, state.allAppSettings)

  const { handleSet_timeStamps, handleAmmend_hceAppSettings, handleAmmend_appSettings, updateSuccess } = appSettingsHandlers(dispatch)

  switch (type) {

    case rts.handleError_app:
      console.log('action', action)
      return { ...state, appError: action.appError }

    case rts.handleToggle_dbOrigin:
      return { ...state, dbOrigin: action.dbOrigin }

    case rts.handleShow_appDashboard:
      return { ...state, showAppDashboard: !state.showAppDashboard, selectedDataMode: action.selectedDataMode }

    case rts.handleCopySettings:
      return { ...state, copiedData: action.copiedData }

    case rts.handleUpdateAppSettingsBlank: {
      allAppSettings[action.docName] = {}
      return {
        ...state,
        allAppSettings: allAppSettings,
      };
    }

    case rts.handleGet_hceSettings_all:
      const prps2 = { ...action.apsProps, handleAmmend_hceAppSettings, handleAmmend_appSettings }
      getHCEAppSettings(prps2)
      return { ...state }

    // get the base settings from the database
    case rts.handleGet_hceSettings:
      const prps = { ...action.apsProps, handleAmmend_hceAppSettings, handleAmmend_appSettings }
      const { settingsDocKeys } = action.apsProps ?? {}
      getAppSettingsFromDb(prps, action.useSwCache, action.cache_settings)
      return { ...state, settingsDocKeys }

    case rts.handleAmmend_hceAppSettings:
      return {
        ...state,
        allAppSettings: action.allAppSettings,
        appUpdated: false,
        appReady: true,
        showSplash: true
      }

    case rts.handleAmmend_modifiedAppSettings:
      return {
        ...state,
        appSettings: state.modifiedAppSettings,
        modifiedAppSettings: null,
      }

    case rts.handleGet_timeStamps:
      fsGet_timeStamps(pathViews, handleSet_timeStamps)
      return state

    case rts.handleSet_timeStamps:
      return { ...state, timeStamps: action.timeStamps }

    // this updates the appSettings for the current settingsDocName (event/client/home)
    case rts.handleAmmend_appSettings:

      // settings from action 
      const _dsks = action.settingsDocKeys ? action.settingsDocKeys : state.settingsDocKeys

      let fbErrorCode;

      if (settings.error) {
        console.log('error', settings.error)
        switch (settings.error.code) {
          case 'resource-exhausted':
            fbErrorCode = settings.error.code
            console.log('fbErrorCode', fbErrorCode)
            break;
          default:
          // nothing 
        }
      }

      const { global } = settings ?? {}
      const { projectOverview, dataOptions, projectOptions } = global ?? {}
      const { projectType } = projectOverview ?? {}

      ammendProjctOptions(global)
      const { _availableAddTypes, _availableSubActionTypes } = getSubActionTypes(projectType, projectOptions, dataOptions)

      if (_availableAddTypes) { dataOptions.availableAddTypes = _availableAddTypes }
      if (_availableSubActionTypes) { dataOptions.availableSubActionTypes = _availableSubActionTypes }

      allAppSettings[settings.settingsDocName] = {
        global: settings.global,
        viewItems: settings.viewItems,
        views: settings.views,
        settingsDocKey: settings.settingsDocKey,
        settingsDocName: settings.settingsDocName,
        isGlobalStatic: settings.isGlobalStatic,
        isViewStatic: settings.isViewStatic,
        status: settings.status,
        time_stamp: settings.time_stamp,
        time_stamp_date: settings.time_stamp ? new Date(settings.time_stamp) : null,
        missingSettings: settings.missingSettings,
        error: settings.error,
        currentPathName: pathname,
      }

      const _appSettings = checkSettings(allAppSettings, _dsks)
      const { hceSettings } = _appSettings ?? {}
      const { home } = hceSettings ?? {}
      const { global: global_home } = home ?? {}
      const { appLanding: appLanding_global, appShortUrls: appShortUrls_global } = global_home ?? {}

      let _currentAppSettings;
      let _modifiedAppSettings;

      if (state.appSettings && _appSettings) {
        const { settingsChanged } = checkEqual_settings(state, _appSettings)
        if (settingsChanged && _useSettingsDelay) {
          _modifiedAppSettings = _appSettings
          _currentAppSettings = state.appSettings
          return {
            ...state,
            currentPathName: pathname,
            modifiedAppSettings: _modifiedAppSettings,
            appSettings: _currentAppSettings,
          }
        } else {
          _currentAppSettings = _appSettings
        }
      } else {
        _currentAppSettings = _appSettings
      }

      return {
        ...state,
        currentPathName: pathname,
        settingsDocKeys: _dsks,
        allAppSettings: allAppSettings,
        appSettings: _currentAppSettings,
        appUpdated: false,
        appReady: true,
        showSplash: true,
        appLanding_global,
        appShortUrls_global,
        fbErrorCode,
      };

    case rts.handleUpdateHomeSettings:
      const { dataItem, dataKey } = action
      const { home: settings_home } = allAppSettings ?? {}
      const { global: home_global } = settings_home ?? {}
      if (home_global && home_global[dataKey]) {
        home_global[dataKey] = dataItem
        const dataToUpdate = {
          [dataKey]: dataItem
        }
        updateSettings.updateHomeGlobalSettingsToDb(dataToUpdate, updateSuccess)
      }
      return { ...state, updating: true }

    case rts.handleUpdateAppReady:
      return {
        ...state,
        appReady: true,
      };
    case rts.handleToggle_sortingOn:
      return {
        ...state,
        sortingOn: !state.sortingOn,
      }

    case rts.handleToggle_helpOn:
      return {
        ...state,
        helpOn: !state.helpOn,
      }

    case rts.handleSettingsOff:
      if (state.appSettingsOn) {
        return {
          ...state,
          appSettingsOn: false,
          settingsPreviewOn: false
        }
      } else {
        return { ...state }
      }

    case rts.handleToggle_settingsLeftOn:
      return {
        ...state,
        settingsLeftOn: !state.settingsLeftOn,
        settingsRightOn: false,
        pullRight: false
      }

    case rts.handleToggle_settingsRightOn:
      return {
        ...state,
        settingsRightOn: !state.settingsRightOn,
        settingsLeftOn: false,
        pullRight: false
      }

    case rts.handleSettingsClose:
      return {
        ...state,
        settingsLeftOn: false,
        settingsRightOn: false,
      }

    case rts.handleSandboxOn:
      return {
        ...state,
        sandboxOn: !state.sandboxOn,
      }

    case rts.handleCommitSettingsOn:
      return {
        ...state,
        commitSettingsOn: !state.commitSettingsOn,
      }

    case rts.handleToggle_authOn:
      return { ...state, authOn: !state.authOn }

    case rts.handleToggle_dataOverrideOn:
      return {
        ...state,
        dataOverrideOn: !state.dataOverrideOn,
      }

    case rts.handlePropOverride:
      return {
        ...state,
        propOverrideOn: action.overrideOn,
      }

    case rts.handleDatabaseRefresh:
      return {
        ...state,
        dataRefreshOn: state.dataRefreshOn === 1 ? 2 : 1,
      }

    case rts.handleDataSourceType:
      return {
        ...state,
        tempDataSource: action.dataSourceType,
        tempGoogleSheetsId: action.googleSheetId
      }

    case rts.handleCommit_settings:
      fsfn_settings.updateAppSettings(settingsDocName, settings).then(response_data => {
        const { success, error } = response_data ?? {}
        dispatch({ type: success ? rts.updateSuccess : rts.updateError, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: rts.updateError, error })
      })
      return {
        ...state,
      }

    case rts.handleToggle_highlightThemesOn:
      return {
        ...state,
        highlightThemesOn: !state.highlightThemesOn,
      }

    case rts.handleToggle_altDatabaseOn:
      return {
        ...state,
        altDatabaseOn: !state.altDatabaseOn,
      }

    case rts.handleThemeSelect:
      return {
        ...state,
        themeSelected: state.themeSelected && state.themeSelected === action.parentSettingKey ? null : action.parentSettingKey,
      }

    case rts.handleToggle_globalPreviewOn:
      return {
        ...state,
        settingsPreviewOn: !state.settingsPreviewOn,
      }

    case rts.handleToggle_pagePreviewOn:
      return {
        ...state,
        settingsPreviewOn: false,
      }

    case rts.handleSetDevice:
      const x = getDevice(mobileDeviceTypes(), action.deviceValue)
      return {
        ...state,
        desktopOn: x && x.key === 'desktop' ? true : false,
        desktopMobileOn: x && x.key === 'desktop-mobile' ? true : false,
        currentDevice: x ? x : state.currentDevice,
      }

    case rts.handleSplash:
      return {
        ...state,
        showSplash: false
      }

    case rts.handleStartSplash:
      return {
        ...state,
        showStartSplash: false
      }

    case rts.handleSetAppChange:
      return {
        ...state,
        appUpdated: action.updated
      }

    case rts.handleShow_homeScreenPrompt:
      return {
        ...state,
        showHomeScreenPrompt: action.show,
      }

    case rts.handleShowHomeScreenHelp:
      return {
        ...state,
        showHomeScreenHelp: !state.showHomeScreenHelp,
      }

    case rts.handleShow_pageViewer:
      return { ...state, showPageViewer: !state.showPageViewer, selectedViewKey: action.selectedViewKey }

    // case rts.handleShowSportsViewer:
    //   return { ...state, showSportsViewer: !state.showSportsViewer }

    case rts.handleOpenSettingsItem:
      const { triggerUrl, productionSettings } = action
      switch (triggerUrl) {
        case triggerUrlTypes.emailConfirmation:
          if (pathViews.events) {
            const dummyPath = 'http://localhost:3000/?apiKey=AIzaSyDHbf1FjfK5gM89jIv6ZcP9_9QY-cX2ufc&oobCode=XgX0Ao1Nuqnn88PeLUc4_zvQ6D8JfHZ8OH8NEgPnrYYAAAGM5NSVcw&emailSignInConfirmation=true&testing=true&mode=signIn&lang=en#'
            const emailPath = '/clients/' + pathViews.clients + '/events/' + pathViews.events + '/landing'
            const fullPath = dummyPath + emailPath
            window.open(fullPath, '_blank')
          }
          break;

        case triggerUrlTypes.signInDemo:
          console.log('OPEN SIGN IN DEMO')
          break;

        case triggerUrlTypes.development:
          let suffix_dev;
          if (pathViews.events) {
            suffix_dev = 'clients/' + pathViews.clients + '/events/' + pathViews.events
          } else if (pathViews.clients) {
            suffix_dev = 'clients/' + pathViews.clients
          }
          window.open(_appUrlPaths.dev + suffix_dev, '_blank')
          break;

        case triggerUrlTypes.production:
          const { clientKey, eventKey } = productionSettings ?? {}
          let suffix_prod;
          if (eventKey) {
            suffix_prod = 'clients/' + clientKey + '/events/' + eventKey
          } else if (clientKey) {
            suffix_prod = 'clients/' + clientKey
          }
          window.open(_appUrlPaths.prod + suffix_prod, '_blank')
          break;
        case triggerUrlTypes.auth:
          openExternal.firebaseAuth()
          break;
        case triggerUrlTypes.database:
          openExternal.db(pathViews)
          break;
        case triggerUrlTypes.realtimeDatabase:
          openExternal.realtimeDb(pathViews)
          break;
        case triggerUrlTypes.databaseSettings:
          openExternal.settingsDb(pathViews, action.nonLandingView)
          break;
        case triggerUrlTypes.credentials:
          openExternal.credentials()
          break;
        case triggerUrlTypes.databaseFunctions:
          openExternal.functions()
          break;
        case triggerUrlTypes.databaseStorage:
          openExternal.db(pathViews, true)
          break;
        case triggerUrlTypes.cloudStorageConsole:
          openExternal.cloudStorageConsole(pathViews)
          break;
        case triggerUrlTypes.cloudDataConsole:
          openExternal.cloudDataConsole(pathViews)
          break;
        case triggerUrlTypes.googleAnalytics:
          openExternal.googleAnalyticsLink(pathViews)
          break;
        case triggerUrlTypes.serviceAccount:
        case triggerUrlTypes.serviceApiAccount:
          const trueConfig = getFbConfig()
          const { projectId } = trueConfig
          const openApi = triggerUrl === triggerUrlTypes.serviceApiAccount
          let url = openExternal.serviceLink(projectId, openApi)
          window.open(url, '_blank')
          break;
        default:
        // nothing
      }
      return { ...state, }

    case rts.updateSettingsSuccess:
      return {
        ...state,
        commitSettingsOn: !state.commitSettingsOn,
      }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return { ...state, updateSuccess: true, showConfirmationQuestion: false }

    case rts.updateError:
      return { ...state, updateSuccess: false, updateError: true }

    default:
      return { ...state };
  }
}

export const appSettingsHandlers = (dispatch) => {

  return {
    handleToggle_dbOrigin: (dbOrigin) => { dispatch({ type: rts.handleToggle_dbOrigin, dispatch, dbOrigin }) },
    handleShow_appDashboard: (selectedDataMode) => { dispatch({ type: rts.handleShow_appDashboard, dispatch, selectedDataMode }) },
    handleCopySettings: (copiedData) => { dispatch({ type: rts.handleCopySettings, dispatch, copiedData }) },
    handleToggle_previewOn: (isGlobal) => {
      if (isGlobal) {
        dispatch({ type: rts.handleToggle_globalPreviewOn })
      } else {
        dispatch({ type: rts.handleToggle_pagePreviewOn })
      }
    },
    handleCommit_settings: (settingsDocName, settings) => { dispatch({ type: rts.handleCommit_settings, dispatch, settingsDocName, settings }) },
    handleCommitSettingsOn: () => { dispatch({ type: rts.handleCommitSettingsOn, dispatch }) },
    handleToggle_authOn: () => { dispatch({ type: rts.handleToggle_authOn, dispatch }) },
    handleToggle_dataOverrideOn: () => { dispatch({ type: rts.handleToggle_dataOverrideOn, dispatch }) },
    handlePropOverride: (overrideOn) => { dispatch({ type: rts.handlePropOverride, dispatch, overrideOn }) },
    handleDatabaseRefresh: () => { dispatch({ type: rts.handleDatabaseRefresh, dispatch }) },
    handleDataSourceType: (dataSourceType, googleSheetId) => { dispatch({ type: rts.handleDataSourceType, dispatch, dataSourceType, googleSheetId }) },
    updateSuccess: () => { dispatch({ type: rts.updateSuccess, dispatch }) },

    handleToggle_altDatabaseOn: () => { dispatch({ type: rts.handleToggle_altDatabaseOn, dispatch }) },
    handleToggle_helpOn: () => { dispatch({ type: rts.handleToggle_helpOn, dispatch }) },
    handleToggle_highlightThemesOn: () => { dispatch({ type: rts.handleToggle_highlightThemesOn, dispatch }) },
    handleSandboxOn: () => { dispatch({ type: rts.handleSandboxOn, dispatch }) },
    handleSetDevice: (deviceValue) => { dispatch({ type: rts.handleSetDevice, dispatch, deviceValue }) },
    handleSettingsClose: () => { dispatch({ type: rts.handleSettingsClose, dispatch }) },
    handleToggle_settingsLeftOn: () => { dispatch({ type: rts.handleToggle_settingsLeftOn, dispatch }) },
    handleSettingsOff: () => { dispatch({ type: rts.handleSettingsOff, dispatch }) },
    handleToggle_settingsOn: () => { dispatch({ type: rts.handleToggle_settingsOn, dispatch }) },
    handleToggle_settingsRightOn: () => { dispatch({ type: rts.handleToggle_settingsRightOn, dispatch }) },
    handleToggle_sortingOn: () => { dispatch({ type: rts.handleToggle_sortingOn, dispatch }) },
    handleSplash: () => { dispatch({ type: rts.handleSplash, dispatch }) },
    handleStartSplash: () => { dispatch({ type: rts.handleStartSplash, dispatch }) },
    handleThemeSelect: (parentSettingKey) => { dispatch({ type: rts.handleThemeSelect, dispatch, parentSettingKey }) },

    handleSetAppChange: (updated) => { dispatch({ type: rts.handleSetAppChange, dispatch, updated }) },
    handleError_app: (appError) => { dispatch({ type: rts.handleError_app, dispatch, appError }) },
    handleUpdateAppReady: () => { dispatch({ type: rts.handleUpdateAppReady, dispatch }) },
    handleAmmend_modifiedAppSettings: () => { dispatch({ type: rts.handleAmmend_modifiedAppSettings, dispatch }) },
    handleAmmend_appSettings: (settings, pathViews, settingsDocKeys, pathname) => { dispatch({ type: rts.handleAmmend_appSettings, dispatch, settings, pathViews, settingsDocKeys, pathname }) },
    handleAmmend_hceAppSettings: (allAppSettings, pathViews) => { dispatch({ type: rts.handleAmmend_hceAppSettings, dispatch, allAppSettings, pathViews }) },
    handleUpdateHomeSettings: (dataItem, dataKey) => { dispatch({ type: rts.handleUpdateHomeSettings, dispatch, dataItem, dataKey }) },
    handleUpdateAppSettingsBlank: (docName) => { dispatch({ type: rts.handleUpdateAppSettingsBlank, dispatch, docName }) },
    updateSettingsSuccess: () => { dispatch({ type: rts.updateSettingsSuccess, dispatch }) },

    handleGet_timeStamps: (pathViews) => { dispatch({ type: rts.handleGet_timeStamps, dispatch, pathViews }) },
    handleSet_timeStamps: (timeStamps) => { dispatch({ type: rts.handleSet_timeStamps, dispatch, timeStamps }) },

    handleShow_pageViewer: (selectedViewKey) => { dispatch({ type: rts.handleShow_pageViewer, dispatch, selectedViewKey }) },
    handleOpenSettingsItem: (triggerUrl, pathViews, nonLandingView, productionSettings) => { dispatch({ type: rts.handleOpenSettingsItem, dispatch, triggerUrl, pathViews, nonLandingView, productionSettings }) },
    handleShow_homeScreenPrompt: (show) => { dispatch({ type: rts.handleShow_homeScreenPrompt, dispatch, show }) },
    handleShowHomeScreenHelp: () => { dispatch({ type: rts.handleShowHomeScreenHelp, dispatch }) },
    handleGet_hceSettings_all: (apsProps) => { dispatch({ type: rts.handleGet_hceSettings_all, dispatch, apsProps }) },
    handleGet_hceSettings: (apsProps, useSwCache, cache_settings) => { dispatch({ type: rts.handleGet_hceSettings, dispatch, apsProps, useSwCache, cache_settings }) },
    ...responseHandlers(dispatch)
  }
}

const getDevice = (mobileDeviceTypes, value) => _.find(mobileDeviceTypes, { 'value': value })

export const checkSettings = (allAppSettings, settingsDocKeys) => {

  let settingsReady = true;
  let settingsError = null;
  let missingSettings = null

  const _hceSettings = {}
  if (allAppSettings && settingsDocKeys) {
    Object.keys(settingsDocKeys).forEach(sdnKey => {
      const sdn = settingsDocKeys[sdnKey]
      if (allAppSettings[sdn]) {
        _hceSettings[sdnKey] = allAppSettings[sdn]
      }
    })
  }

  Object.keys(settingsDocKeys).forEach(sdk => {
    if (!_hceSettings[sdk]) { settingsReady = false }
    if (_hceSettings[sdk] && _hceSettings[sdk].error) { settingsError = _hceSettings[sdk].error }
    if (_hceSettings[sdk] && _hceSettings[sdk].missingSettings) { missingSettings = _hceSettings[sdk].missingSettings }
  })

  if (settingsReady && consoleStart) { console.log('_hceSettings', _hceSettings) }

  return { settingsReady, hceSettings: _hceSettings, settingsError, missingSettings }

}

const ammendProjctOptions = (global) => {

  const { pageOptions, projectOptions } = global ?? {}

  const { allowGeoList, allowGeoLocation, allowFeeds, allowImageLinks, allowTwitter, allowVideoLinks, allowMerchandise, allowTickets } = projectOptions ?? {}

  if (allowGeoList || allowGeoLocation || allowFeeds || allowImageLinks || allowTwitter || allowVideoLinks || allowMerchandise || allowTickets) {
    const _pageOptions = pageOptions ? pageOptions : {}
    if (allowGeoList) { _pageOptions.allowGeoList = allowGeoList }
    if (allowGeoLocation) { _pageOptions.allowGeoLocation = allowGeoLocation }
    if (allowFeeds) { _pageOptions.allowFeeds = allowFeeds }
    if (allowImageLinks) { _pageOptions.allowImageLinks = allowImageLinks }
    if (allowTwitter) { _pageOptions.allowTwitter = allowTwitter }
    if (allowVideoLinks) { _pageOptions.allowVideoLinks = allowVideoLinks }
    if (allowMerchandise) { _pageOptions.allowMerchandise = allowMerchandise }
    if (allowTickets) { _pageOptions.allowTickets = allowTickets }
    global.pageOptions = _pageOptions
  }

}

const getSubActionTypes = (projectType, projectOptions, dataOptions) => {

  const { availableAddTypes: availableAddTypes_x, availableSubActionTypes: availableSubActionTypes_x } = projectOptions ?? {}
  const { availableAddTypes, availableSubActionTypes } = dataOptions ?? {}

  const aats = gEnums.availableAddTypes
  const asats = gEnums.availableSubActionTypes

  switch (projectType) {
    case gEnums.projectTypes.golf:
      return {
        _availableAddTypes: availableAddTypes ? availableAddTypes : [aats.golfMatch, aats.golfTournament],
        _availableSubActionTypes: availableSubActionTypes ? availableSubActionTypes :
          [
            asats.createGolfTournament,
            asats.createRoundMatches,
            asats.importGolfTournament,
            asats.createGolfLineups,
            asats.assignStartHole]
      }

    case gEnums.projectTypes.sports:
      return {
        _availableAddTypes: availableAddTypes ? availableAddTypes : null,
        _availableSubActionTypes: availableSubActionTypes ? availableSubActionTypes :
          [
            // asats.backupSeasons,
            asats.createAlignment,
            asats.createAllStarTeams,
            asats.createHistory,
            asats.createPlayoff,
            asats.createRankings,
            asats.createRoster,
            asats.createSchedule,
            asats.createScheduleDates,
            asats.createSchools,
            asats.createSeason,
            asats.createSportsTeams,
            asats.createStaff,
            asats.createTournament,
            asats.editSeason,
            asats.editTryoutRoster,
            asats.nominatePlayers,
            asats.submitRankings,
            asats.updateDistricts,
            asats.updateLeagueSports,
            asats.updateMatchRealtimeScore,
            asats.updateMatchScore,
            asats.updateOrganizations,
            asats.updateOrganizationSports,
            asats.updateSchoolSports,
          ]
      }

    default:
      return {
        _availableAddTypes: availableAddTypes,
        _availableSubActionTypes: availableSubActionTypes,
      }
  }

}

const checkEqual_settings = (state, _appSettings) => {

  if (state.appSettings && _appSettings) {

    const { hceSettings: hce_state } = state.appSettings
    const { hceSettings: hce } = _appSettings
    const { event_key: event_state } = hce_state ?? {}
    const { event_key: event } = hce ?? {}
    const { global: global_state, viewItems: viewItems_state, views: views_state } = event_state ?? {}
    const { global, viewItems, views } = event ?? {}

    const equal = {
      global: global_state && global ? _.isEqualWith(global_state, global, customComparer) : true,
      viewItems: viewItems_state && viewItems ? _.isEqualWith(viewItems_state, viewItems, customComparer) : true,
      views: views_state && views ? _.isEqualWith(views_state, views, customComparer) : true,
    };

    const differences = {
      global: !equal.global ? getDifferences(global_state, global) : null,
      viewItems: !equal.viewItems ? getDifferences(viewItems_state, viewItems) : null,
      views: !equal.views ? getDifferences(views_state, views) : null,
    };

    const settingsChanged = !equal.global || !equal.viewItems || !equal.views

    // if (settingsChanged) {
    //   console.log('differences', differences)
    //   console.log('equal', equal)
    // }

    return { settingsChanged, equal, differences }
  }
}

const getDifferences = (obj1, obj2) => {
  const differences = {};

  const keys = _.union(_.keys(obj1), _.keys(obj2));
  keys.forEach(key => {
    if (!_.isEqualWith(obj1[key], obj2[key], customComparer)) {
      differences[key] = { obj1: obj1[key], obj2: obj2[key] };
    }
  });
  return differences;
};

const customComparer = (obj1, obj2) => {
  return _.isEqual(_.sortBy(_.toPairs(obj1)), _.sortBy(_.toPairs(obj2)));
};