import { deleteAppAdminProfileAndAuth } from "../../../auth/signInActions/deleteAppAdmin";
import { gEnums } from "../../../enums/globalEnums";
import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps";
import { createRefPath_event } from "../../appData/appRefPaths";
import { fs_delete_doc } from "../../appData/fsData";
import { deletePromise } from '../../promises/deletePromise';
import { updateDocumentHelpers } from "./fsUpdate_document";

// IMPORTANT: Delete document
/** Deletes a document or documents from a collection */
export const fsUpdate_deleteCollectionItems = (props) => {

  const { baseRef, ufProps, pathViews, dispatch } = props

  const { directCollection } = baseRef
  let { vit, itemData, viewKey, selectedItems, dataRestrictions, appDataSource } = ufProps ?? {}
  const { useAppDataDocuments, updateAppDataDocuments } = appDataSource ?? {}

  let _updateAppDataDocuments = updateAppDataDocuments || useAppDataDocuments

  if (!viewKey && itemData && itemData._itemKey) { viewKey = itemData._itemKey }
  const coll = directCollection ? directCollection : vit

  const _refPath = createRefPath_event(pathViews, [coll, viewKey])

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.deleteData)) {
    console.log('ufProps', ufProps)
    console.log('_refPath', _refPath)
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  switch (vit) {
    case gEnums.profileSelectTypes.profiles:
      deleteAppAdminProfileAndAuth(dispatch, itemData, pathViews)
      break;

    default:
      // return false 
      if (selectedItems) {
        if (_updateAppDataDocuments) {
          updateDocumentHelpers.delete_itemInDocuments(vit, selectedItems, pathViews)
        }
        deletePromise(_refPath, vit, selectedItems).then(() => {
          dispatch({ type: dispatchProps.success, dispatch })
        }).catch(err => {
          dispatch({ type: dispatchProps.error, dispatch })
        });
      } else {
        if (_updateAppDataDocuments) {
          const _selectedItems = { [viewKey]: {} }
          updateDocumentHelpers.delete_itemInDocuments(vit, _selectedItems, pathViews)
        }
        fs_delete_doc(_refPath, dispatch)
      }
  }
}