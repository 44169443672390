import _ from 'lodash'
import { manifestSizesT } from '../global/components/imaging/createManifestIcons'
import { getAppIcon, getAppTitle } from '../project/appConfiguration'

export const addIndexHtmlLinks = (aps_global, manifest_state) => {

  const { manifestIcons } = manifest_state ?? {}
  const { manifest } = aps_global ?? {}
  const { appleTouchIcon72, appleTouchIcon120, appleTouchIcon152, appleStartUpImage } = manifest ?? {}
  let { name } = manifest ?? {}

  const { appTitle, fixed } = getAppTitle()
  const appIcon = getAppIcon()

  const appIcons = {
    icon72: _.find(manifestIcons, { size: appleTouchIcon72 ? appleTouchIcon72 : manifestSizesT[72] }),
    icon120: _.find(manifestIcons, { size: appleTouchIcon120 ? appleTouchIcon120 : manifestSizesT[128] }),
    icon152: _.find(manifestIcons, { size: appleTouchIcon152 ? appleTouchIcon152 : manifestSizesT[152] }),
    icon192: _.find(manifestIcons, { size: appleStartUpImage ? appleStartUpImage : manifestSizesT[192] })
  }

  if (appIcons.icon192) { appIcons.icon192 = 'icon-192x192.png' }

  if (name === 'name') { name = appTitle }

  if (fixed && appTitle) {
    document.title = appTitle
  } else {
    document.title = name ? name : appTitle
  }

  // brower 
  // this is the icon of the browser 
  ammendLink(document, 'app_icon', appIcons.icon72, appIcon)

  // for IOS
  ammendLink(document, 'apple_icon_72', appIcons.icon72, appIcon)
  ammendLink(document, 'apple_icon_120', appIcons.icon120, appIcon)
  ammendLink(document, 'apple_icon_152', appIcons.icon152, appIcon)
  ammendLink(document, 'app_startup_image_apple', appIcons.icon192, appIcon)
}

/** Adds a link to web page */
const ammendLink = (document, elemId, appIcon, appIconDef) => {
  const elem = document.getElementById(elemId)
  if (elem) {
    if (appIcon && appIcon.fileUrl) {
      elem.setAttribute('href', appIcon.fileUrl)
    } else {
      elem.setAttribute('href', appIconDef)
    }
  }
}

export const addCssFile = (cssFile) => {
  const elem = document.getElementById('added_css')
  if (elem) {
    // elem.setAttribute('href', '/altCss/' + cssFile)
  }
  // const link = document.createElement('style');
  // link.rel = 'stylesheet';
  // // link.type = 'text/css';
  // link.href = 'cssAlt/' + cssFile;
  // console.log('link', link)
  // document.getElementsByTagName('HEAD')[0].appendChild(link);
}

// https://medium.com/@alshakero/how-to-setup-your-web-app-manifest-dynamically-using-javascript-f7fbee899a61