
import { initializeApp } from 'firebase/app';
import { fbAppConfigs } from '../../../project/appConfigs';
import { _projectAppNames, getAppName } from '../../../project/appConfiguration';
import { grts } from './reducerHelpers/dispatchProps';
import { fs_db } from '../../firestoreData/appData/fsAppData';

const _usePojo = false

const rts = {
  handleInit_database: 'handleInit_database',
  handleAmmend_refPaths: 'handleAmmend_refPaths',
  ...grts
}

export const databaseReducer = (state, action) => {

  let { type, projectAppName } = action ?? {}

  switch (type) {

    case rts.handleInit_database:

      const { appName: appName_true } = getAppName(true)
      const appName = _usePojo ? _projectAppNames.pojo : _projectAppNames.meetingevolution
      const _appName = projectAppName ? projectAppName : appName

      let _environment;

      switch (_appName) {
        case _projectAppNames.meetingevolution:
        case _projectAppNames.thumbstat:
        case _projectAppNames.cardnudge:
        case _projectAppNames.pojo:
          _environment = 'dev'
          break;

        default:

      }

      const _config = fbAppConfigs[_appName][_environment]
      const fsInit = initializeApp(_config, _appName)
      const fsdb = fs_db.get_fs(fsInit)

      console.log('_appName', _appName)

      return { ...state, fsdb }

    case rts.handleAmmend_refPaths:
      const _refPaths = [...state.refPaths]
      _refPaths.push(action.refPath)
      return { ...state, refPaths: _refPaths }


    default:
      return { ...state }
  }
}

export const databaseHandlers = (dispatch) => {
  return {
    handleInit_database: (projectAppName) => { dispatch({ type: rts.handleInit_database, dispatch, projectAppName }) },
    handleAmmend_refPaths: (refPath) => { dispatch({ type: rts.handleAmmend_refPaths, dispatch, refPath }) },
  }
}

export const databaseInitialState = (initState) => {
  return { ...initState, refPaths: [] }
};
