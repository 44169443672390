import { createRefPath, createRefPath_client } from '../appData/appRefPaths';
import { fs_db } from "../appData/fsAppData";
import { fs_update_doc } from "../appData/fsData";
import { _fcmCollectionName, _profileCollectionName, _useFcmCollection } from "./getProfile";

/**
 * profileData = {email, _itemKey}
 * @param {object} fcmTokens 
 * @param {object} profileData 
 * @param {object} pathViews 
 * @param {function} callback 
 * @returns 
 */
export const updateAppUserFcmTokenToDatabase = async (fcmTokens, profileData, pathViews, callback) => {
  if (profileData) {
    return updateProfileToken(fcmTokens, profileData, pathViews, callback)
  }
}

/** Updates a profile token for the user */
const updateProfileToken = async (fcmTokens, profileData, pathViews, callback) => {

  const { email, _itemKey } = profileData ?? {}

  const allowStandAlone = true
  let isSmartPhone;
  let result;

  if (window.matchMedia('(display-mode: standalone)').matches) {
    isSmartPhone = true
  } else {
    isSmartPhone = false
  }

  if (isSmartPhone || allowStandAlone) {

    if (_useFcmCollection) {
      const _refPath_fcm = createRefPath([_fcmCollectionName, _itemKey]) // profiles/profileId
      const xxx = await fs_update_doc(_refPath_fcm, { fcmTokens: fcmTokens })
      console.log('xxx', xxx)
    }

    if (_itemKey) {
      const profile = await fs_db.get_data({ refPath: [_profileCollectionName, _itemKey], opts: { returnFirstObject: true } }) // profiles/_itemKey
      if (profile) {
        const _refPath = createRefPath([_profileCollectionName, profile.id]) // profiles/profileId
        result = await fs_update_doc(_refPath, { fcmTokens: fcmTokens })
        return result
      }
    }

    if (email) {
      result = await updateClientProfileToken(fcmTokens, email, pathViews)
      return result
    }
  } else {
    return;
  }
}

const updateClientProfileToken = async (fcmTokens, email, pathViews) => {

  if (email) {
    const _refPath = createRefPath_client(pathViews, [_profileCollectionName, email])
    try {
      const result = await fs_update_doc(_refPath, { fcmTokens: fcmTokens })
      return result
    } catch (error) {
      console.log('Client token NOT updated ', error)
    }
  }
} 