import React, { useContext, useEffect, useReducer } from "react";
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sportsOrganizationHandlers, sportsOrganizationInitialState, sportsOrganizationReducer } from "../reducers/SportsOrganizationReducer";
import { FirestoreContext } from "../../../../global/cnr/contexts/FirestoreContext";
import { _cacheNames, _cacheTypes } from "../../../../global/cnr/reducers/ServiceWorkerReducer";

export const SportsOrganizationContext = React.createContext()

export default function SportsOrganizationProvider(props) {

  const { directReturn } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, serviceWorker_state } = states ?? {}
  const { serviceWorker_handlers } = handlers ?? {}
  const { database_fns } = fns ?? {}

  const { cacheApp } = serviceWorker_state ?? {}
  const { cachedItems } = cacheApp ?? {}
  const { cache_data } = cachedItems ?? {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)
  const { firestore_fns } = firestoreContext ?? {}

  // appUserContext
  const { appUser } = appUser_state ?? {}
  const { uid } = appUser ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}
  const { organizations: organizations_key } = pathViews ?? {}
  const view = 'organizations'

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_global } = pageSettings ?? {}
  const { projectOptions } = aps_global ?? {}
  let { useAppDarkMode } = projectOptions ?? {}

  const { viewItems: viewItems_page } = aps_page ?? {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ?? {}
  let { dataParents, useSeasonals } = dataSource ?? {}

  if (!dataParents) {
    dataParents = ['orgnaizations', 'districts']
  }

  const _cacheName = _cacheNames.cache_data + '-' + organizations_key
  const _cacheData = cache_data && cache_data[organizations_key]

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const init_state = {
    firestore_fns,
    useAppDarkMode,
    viewItem,
    useSeasonals,
    uid,
    ownerPath,
    inverted: false,
    cssOpts: { name: 'ssc-normal' },
    _cacheData,
    database_fns
  }

  const [sportsOrganization_state, sportsOrganization_dispatch] = useReducer(sportsOrganizationReducer, init_state, sportsOrganizationInitialState)
  const sportsOrganization_handlers = sportsOrganizationHandlers(sportsOrganization_dispatch, sportsOrganization_state)
  const { organization_data } = sportsOrganization_state ?? {}

  useEffect(() => {
    if (pathViews.organizations && dataParents) {
      sportsOrganization_handlers.handleInit_sportsOrganization(pathViews, dataParents)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.organizations])

  useEffect(() => {
    if (organization_data && !_cacheData) {
      serviceWorker_handlers.handleSend_messageToServiceWorker({
        cacheName: _cacheName,
        cacheType: 'organization_data',
        cacheData: organization_data,
      }, _cacheTypes.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [organization_data])

  if (sportsOrganization_state) {
    if (directReturn) {
      return { sportsOrganization_handlers, sportsOrganization_state }
    } else {
      return <SportsOrganizationContext.Provider
        value={{ sportsOrganization_state, sportsOrganization_handlers, appComponent: 'sportsOrganization' }}>
        {props.children}
      </SportsOrganizationContext.Provider>
    }
  } else {
    return <SportsOrganizationContext.Provider
      value={{}}>
      {props.children}
    </SportsOrganizationContext.Provider>
  }
}