import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { AppUserManagerContext } from '../../cnr/contexts/AppUserManagerContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { appFormTypes } from '../../enums/appFormTypes';
import { clickOriginTypes, gEnums } from '../../enums/globalEnums';
import { createRefPath, createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import AppForm from '../forms/appForms/AppForm';

const UserProfile = (props) => {

  const profileType = gEnums.profileTypes.clientProfiles

  const { clickOriginType, forProfile } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { appUser_handlers } = handlers ?? {}
  const { pathViews } = paps_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkLeftType } = framework_state ?? {}

  // appUserManagerContext
  const appUserManagerContext = useContext(AppUserManagerContext)
  const { appUserManager_state, appUserManager_handlers } = appUserManagerContext ?? {}
  const { selectedAppUser } = appUserManager_state ?? {}
  const { email, _itemKey, displayName } = selectedAppUser ?? {}

  // local state 
  const [profileData, setProfileData] = useState()
  const [updating, setUpdating] = useState()

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
    case clickOriginTypes.framework:
      _fromDataViewer = true
      break;
    default:
      if (frameworkLeftType) {
        _fromDataViewer = true
      }
    //nothing
  }

  useEffect(() => {
    const cb = (pd) => setProfileData(pd)
    let _refPath;
    if (pathViews.clients) {
      _refPath = createRefPath_client(pathViews, ['profiles', email])
    } else {
      _refPath = createRefPath(['profiles', _itemKey])
    }
    fs_db.get_doc({ refPath: _refPath, callback: cb })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelled = () => {
    setUpdating()
    appUserManager_handlers.handleCancel()
  }

  const handleUpdate = () => {
    setUpdating(true)
    appUser_handlers.handleUpdateAppUserProfile(profileData, profileType, handleCancelled)
  }

  const handleUpdateProfileData = (d) => setProfileData(d)

  console.log('profileData', profileData)

  const appUserForm = () => <AppForm
    appFormType={appFormTypes.appUserProfile}
    parentData={profileData}
    handleUpdateParentData={handleUpdateProfileData}
    handleSave={handleUpdate}
    updating={updating}
    keepParent={true}
  />

  const wrapper_form = () => <Wrapper
    header={_fromDataViewer && <div><Icon style={{ marginRight: '1em' }} name='arrow left' color={'blue'} onClick={(e) => { appUserManager_handlers.handleCancel(e) }} />{displayName}</div>}
    content={appUserForm()}
    wrapperType={wrapperTypes.paddedHeader}
  ></Wrapper>

  const content = () => {
    if (_fromDataViewer) {
      return wrapper_form()
    } else {
      return appUserForm()
    }
  }

  const fpw = () => forProfile ? appUserForm() : <FullPageWrapper
    topperCaption={'Profile'}
    topperCaption2={email}
    content={profileData && appUserForm()}
    handleCancel={appUserManager_handlers.handleCancel && handleCancelled}>
  </FullPageWrapper>

  return _fromDataViewer ? content() : fpw()

}

export default UserProfile