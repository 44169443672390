import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Menu, Sidebar } from 'semantic-ui-react';
import { DisplayContext } from '../../../global/cnr/contexts/DisplayContext';
import { PapsContext } from '../../../global/cnr/contexts/PapsContext';
import { DimmerPending } from '../../../global/components/alerts/DimmerWrap';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import DataSidebar from '../../../global/components/swipers/DataSidebar';
import StepWizard from '../../../global/components/wizard/StepWizard';
import { gEnums } from '../../../global/enums/globalEnums';
import { openExternal } from '../../../global/viewSettings/helpers/settingsLinks';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { SportsSeasonContext } from '../cnr/contexts/SportsSeasonContext';
import { createPlayoffHandlers, createPlayoffInitialState, createPlayoffReducer } from '../cnr/reducers/CreatePlayoffReducer';
import { createScheduleTypes } from '../cnr/reducers/CreateSportsReducer';
import LeagueStandings from '../elements/standings/LeagueStandings';
import { CreateSportsContext } from './CreateSports';
import CreateSectionDates from './subs/CreateSectionDates';
import CreateSeeds, { seedTypes } from './subs/CreateSeeds';
import CreateTournamentSchedule, { tournamentTypes } from './subs/CreateTournamentSchedule';
import JsonViewer from '../../../global/components/viewers/JsonViewer';
import TeamMatchCompare from './subs/TeamMatchCompare';
import { getFullPlayoffInfo } from '../cnr/reducers/reducerHelpers/createSportsData';
import { sportsShowTypes } from '../cnr/reducers/SportsSeasonReducer';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { getAppUserAccess } from '../../../global/auth/appUserAccessPermissions';
import { _sportCollections } from '../cnr/reducers/SportsSidebarReducer';

const stepTypes = {
  playoffLevels: 'playoffLevels',
  playoffDates: 'playoffDates',
  playoffTeams: 'playoffTeams',
  playoffSeeds: 'playoffSeeds',
  playoffBrackets: 'playoffBrackets',
  playoffSchedule: 'playoffSchedule',
}

export const CreatePlayoffContext = createContext();

const CreatePlayoff = (props) => {

  const _asat = gEnums.availableSubActionTypes
  const { handleCancel, fromDashboard, fromApp } = props
  const _useWizard = fromDashboard ? false : false

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { database_fns } = fns ?? {}
  const { pathViews } = paps_state ?? {}

  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdminDeveloper } = appUserAccess ?? {}

  // displayContext
  const displayContext = useContext(DisplayContext);

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { details_info, latestSeason, sportsKey, sportsButtonProps, parentDataKeys, sportPermissions } = sportsSeason_state ?? {}
  const { playoffMatches: playoffMatches_key, matches_seasonal: pk_matches_seasonal } = parentDataKeys ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state } = createSportsContext ?? {}
  const { teams, subActionType, item_state } = createSports_state ?? {}

  const _latestSeason = latestSeason ? latestSeason : props.latestSeason

  const init_state = {
    pathViews,
    subActionType,
    teams,
    item_state,
    latestSeason: _latestSeason,
    sportsKey,
    details_info,
    sportPermissions,
    database_fns,
  }
  const [createPlayoff_state, createPlayoff_dispatch] = useReducer(createPlayoffReducer, init_state, createPlayoffInitialState)
  const createPlayoff_handlers = createPlayoffHandlers(createPlayoff_dispatch, createPlayoff_state)
  const { seededTeams, playoff_info, updating } = createPlayoff_state ?? {}
  const { playoffLevel, playoffLevelTeams, playoffDates_selected, playoffTeams, playoffDates_ready, playoffDatesAreReady, playoffDates_current, parentDataKey } = playoff_info ?? {}
  const { currentCount, currentNeeded } = playoffDates_current ?? {}
  const _playoffLevelReady = playoffDates_ready && playoffLevel && playoffDates_ready[playoffLevel]

  const _parentDataKey = playoffMatches_key ? playoffMatches_key : parentDataKey

  const componentContexts = { displayContext }

  const steps = [stepTypes.playoffLevels, , stepTypes.playoffSeeds, stepTypes.playoffDates, stepTypes.playoffBrackets]

  // local state   
  const [mode_right, setMode_right] = useState()
  const [levels, setLevels] = useState()
  const [step, setStep] = useState({ index: 0, name: steps[0] })
  const stepCount = steps.length

  // get the playoff info from the database
  useEffect(() => {
    createPlayoff_handlers.handleGet_playoffInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (playoffLevelTeams) {
      setLevels(Object.keys(playoffLevelTeams).sort().reverse())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleSavePlayoffTournament = () => {
    createPlayoff_handlers.handleStartUpdate()
    createPlayoff_handlers.handleSeasonal_savePlayoffTournament(playoffLevel, latestSeason)
  }

  const handleButtonClick = () => {
    const clickType = step.name
    createPlayoff_handlers.handleStartUpdate()
    // createPlayoff_handlers.handleSeasonal_updatePlayoffTournament(clickType, latestSeason)
  }

  const handleMode_none = (mr) => setMode_right()
  const handleMode_right = (opts) => setMode_right(opts ? opts.modeRight : step.name)

  const handleOpenDb = () => openExternal.firebaseDb(pathViews, _sportCollections._matches_seasonal, pk_matches_seasonal, false, false, false, ['matches', 'playoffs'])
  const handleOpenDbp = () => openExternal.firebaseDb(pathViews, '_playoffs', _parentDataKey, false, false, false, ['matches'])

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes.playoffTeams:
        return playoffTeams && playoffTeams.length > 0 ? true : false
      case stepTypes.playoffSeeds:
        switch (subActionType) {
          case _asat.createTournament:
            return seededTeams && Object.keys(seededTeams).length > 0 ? true : false
          case _asat.createPlayoff:
            return playoffLevelTeams && Object.keys(playoffLevelTeams).length > 0 ? true : false
          default:
            return false
        }
      case stepTypes.playoffDates:
        return playoffDatesAreReady

      case stepTypes.playoffBrackets:
        return true
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const playoff_levels = () => {
    const menuItems = _.map(levels, (level) => (
      <Menu.Item color={'blue'} active={level === playoffLevel} onClick={(e) => { createPlayoff_handlers.handleAmmend_level(level) }}>{level}</Menu.Item>
    ))
    return <Menu vertical fluid inverted>
      {menuItems}
    </Menu>
  }

  const select_playoffTeams = () => <LeagueStandings componentContexts={componentContexts} menuDisplayType={gEnums.menuDisplayTypes.menuButtons} forPlayoff={true} />
  const create_seeds = () => <CreateSeeds seedType={seedTypes.playoff} menuDisplayType={gEnums.menuDisplayTypes.menuButtons} />
  const create_scheduleDates = () => <CreateSectionDates createScheduleType={createScheduleTypes.playoff} menuDisplayType={gEnums.menuDisplayTypes.menuButtons} />

  const create_tournamentSchedule = (asGrid) => <CreateTournamentSchedule
    tournamentType={tournamentTypes.newPlayoff}
    asGrid={asGrid}
    menuDisplayType={gEnums.menuDisplayTypes.menuButtons}
  />

  const buttons_sidebar = () => {
    const clickOptions = { clickType: step.name }
    switch (step.name) {
      case stepTypes.playoffTeams:
        return [
          { oc: handleMode_right, ...sportsButtonProps, color: sportPermissions.any && sportPermissions.playoffTeams ? 'blue' : 'olive', caption: 'Update ' + _.startCase(step.name), clickOptions },
          { oc: handleOpenDb, ...sportsButtonProps, color: 'orange', caption: 'Open Database (Matches)', fl: true },
          { oc: handleOpenDbp, ...sportsButtonProps, color: 'orange', caption: 'Open Database (Playoff Matches)', fl: true }
        ]
      case stepTypes.playoffSeeds:
        // handleButtonClick
        return [{ oc: handleMode_right, ...sportsButtonProps, color: sportPermissions.any && sportPermissions.playoffSeeds ? 'green' : 'olive', caption: 'Update ' + _.startCase(step.name), clickOptions }]
      case stepTypes.playoffDates:
        return [{ oc: handleMode_right, ...sportsButtonProps, color: sportPermissions.any && sportPermissions.playoffDates ? 'green' : 'olive', caption: 'Update ' + _.startCase(step.name), clickOptions }]
      case stepTypes.playoffBrackets:
        return [{ oc: handleSavePlayoffTournament, icon: 'save', color: sportPermissions.any && sportPermissions.playoffBrackets ? 'green' : 'olive', caption: 'Update ' + _.startCase(step.name), clickOptions }]
      default:
        break;
    }
  }

  const wrapperContent_sidebar = (content, header) => {
    const _buttons = buttons_sidebar()
    return <Wrapper
      header={header}
      content={content}
      footer={_buttons && <UiSaveButtons others={_buttons} />}
      wrapperType={wrapperTypes.padded}
      wrapperCss={{ container: 'app-dark-mode' }}
    />
  }

  const wrapper_sidebar = (content) => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(mode_right ? true : false)}
    <Sidebar.Pusher dimmed={mode_right ? true : false} className={'h100'}>
      {wrapperContent_sidebar(content)}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const stepCaption = () => {
    const name = _.startCase(step.name)
    switch (step.name) {
      case stepTypes.playoffDates:
        return playoffDates_selected ? name + ' (' + playoffDates_selected.length + ')' : name
      case stepTypes.playoffTeams:
        return name
      default:
        return name
    }
  }

  const stepCaption2 = () => {
    switch (step.name) {
      case stepTypes.playoffDates:
        return <div style={{ textAlign: 'center', paddingBottom: '1em' }}>{'Count: '}{currentCount}{' of '}{currentNeeded}</div>
      default:
      // nothing
    }
  }

  const buttons_footer_right = () => {
    const btns = []
    btns.push({ oc: handleSavePlayoffTournament, caption: _.startCase(sportsShowTypes.updatePlayoffMatches) + ' (' + playoffLevel + ')', icon: 'save', color: sportPermissions.any && sportPermissions.playoffBrackets ? 'green' : 'olive', opts: { modeRight: sportsShowTypes.updatePlayoffMatches } })
    return btns
  }

  const footer_right = () => <UiSaveButtons others={buttons_footer_right()} />

  const content_right = () => {
    let _content;
    let _header = mode_right;
    switch (mode_right) {
      case sportsShowTypes.compareSchedules:
        _content = <TeamMatchCompare sportsShowType={mode_right} inViewer={true} />
        break;
      case sportsShowTypes.compareTeams:
        _content = <TeamMatchCompare sportsShowType={mode_right} inViewer={true} />
        break;
      case sportsShowTypes.updatePlayoffMatches:
        const playoffMatches = getFullPlayoffInfo(playoff_info, playoffLevel)
        _content = <JsonViewer json={playoffMatches} name={'Playoff Matches (' + playoffLevel + ')'} />
        break;
      case stepTypes.playoffTeams:
        _content = <JsonViewer json={playoffTeams} name={step.name} />
        break;
      case stepTypes.playoffSeeds:
        _content = <JsonViewer json={playoffTeams} name={step.name} />
        break;
      case stepTypes.playoffDates:
        _content = <JsonViewer json={playoffTeams} name={step.name} />
        break;
      case stepTypes.playoffBrackets:
        _content = <JsonViewer json={playoffTeams} />
        break;
      default:
        break;
    }
    return <Wrapper
      header={_.startCase(_header)}
      content={_content}
      footer={footer_right()}
      wrapperType={wrapperTypes.paddedHeaderAndFooter}
    ></Wrapper>
  }

  const sidebar_right = (visible) => <DataSidebar visible={visible} content={visible && content_right()} onHide={handleMode_none} direction={'right'} />

  const content_playoffs = () => {
    let cn = 'playoff-create-container'
    if (isSuperAdminDeveloper) { cn += ' five' }
    return <div className={cn}>
      {wrapperContent_sidebar(playoff_levels(), 'Levels')}
      {playoffLevel && wrapperContent_sidebar(select_playoffTeams(), 'Standings')}
      {playoffLevel && wrapperContent_sidebar(create_seeds(), 'Seeds')}
      {playoffLevel && isSuperAdminDeveloper && wrapperContent_sidebar(create_scheduleDates(), 'Dates')}
      {playoffLevel && wrapperContent_sidebar(create_tournamentSchedule(true), 'Bracket')}
    </div>
  }

  const buttons_footer = () => {
    const btns = []
    playoffLevel && isSuperAdminDeveloper && btns.push({ oc: handleMode_right, caption: _.startCase(sportsShowTypes.compareTeams), icon: 'arrow right', fl: true, opts: { modeRight: sportsShowTypes.compareTeams } })
    playoffLevel && btns.push({ oc: handleMode_right, caption: _.startCase(sportsShowTypes.compareSchedules), icon: 'arrow right', fl: true, opts: { modeRight: sportsShowTypes.compareSchedules } })
    isSuperAdminDeveloper && playoffLevel && btns.push({ oc: handleMode_right, caption: _.startCase(sportsShowTypes.updatePlayoffMatches) + ' (' + playoffLevel + ')', icon: 'arrow right', disabled: !_playoffLevelReady, opts: { modeRight: sportsShowTypes.updatePlayoffMatches } })
    return btns
  }

  const content = () => {
    if (_useWizard) {
      switch (step.name) {
        case stepTypes.playoffLevels:
          return wrapper_sidebar(playoff_levels())
        case stepTypes.playoffTeams:
          return wrapper_sidebar(select_playoffTeams())
        case stepTypes.playoffSeeds:
          return wrapper_sidebar(create_seeds())
        case stepTypes.playoffDates:
          return wrapper_sidebar(create_scheduleDates())
        case stepTypes.playoffBrackets:
          return wrapper_sidebar(create_tournamentSchedule(true))
        default:
          break;
      }
    } else {
      return wrapper_sidebar(
        <Wrapper
          content={content_playoffs()}
          footer={<UiSaveButtons others={buttons_footer()} />}
          wrapperType={wrapperTypes.paddedFooter}
        ></Wrapper>
      )
    }
  }

  const fpw = () => <FullPageWrapper
    content={content()}
    fullMode={true}
    handleCancel={handleCancel}
  />

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    handleCancel={handleCancel}
    fullMode={fromDashboard ? true : false}
    setStep={setStep}
    step={step}
    stepCaption={stepCaption()}
    stepCaption2={stepCaption2()}
    steps={steps}
    topperCaption={'Create Playoff'}
    topperCaption2={_parentDataKey}
    updating={updating}
    useTopper={true}
  ></StepWizard>

  const content_playoff = () => {
    if (_useWizard) {
      return stepWizard()
    } else {
      return fromApp ? fpw() : content()
    }
  }

  const wrapper = () => <CreatePlayoffContext.Provider value={{ createPlayoff_state, createPlayoff_handlers }}>
    {content_playoff()}
  </CreatePlayoffContext.Provider>

  return step && playoff_info ? wrapper() : <DimmerPending />

}

export default CreatePlayoff