import React from 'react';
import PapsProvider from '../cnr/contexts/PapsContext';
import AppView from './AppView';
import ManifestProvider from '../cnr/contexts/ManifestContext';
import SettingsDataProvider from '../cnr/contexts/SettingsDataContext';
import DataWrapperProvider from '../cnr/contexts/DataWrapperContext';
import SearchParamProvider from '../cnr/contexts/SearchParamContext';
import AppUsersProvider from '../cnr/contexts/AppUsersContext';
import ServiceWorkerProvider from '../cnr/contexts/ServiceWorkerContext';

/** Starting point fo the Page
@componentContexts [StartContext, AuthContext, AppSettingContext]  
*/
function AppRoutes() {

  return (
    <SearchParamProvider>
      <PapsProvider>
        <SettingsDataProvider>
          <ManifestProvider>
            <AppUsersProvider>
              <DataWrapperProvider>
                <AppView />
              </DataWrapperProvider>
            </AppUsersProvider>
          </ManifestProvider>
        </SettingsDataProvider>
      </PapsProvider >
    </SearchParamProvider>
  )
}

export default AppRoutes