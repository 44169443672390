import _ from 'lodash'
import { seasonalHelpers } from '../../../projectSpecific/sports/cnr/reducers/reducerHelpers/seasonalSportsData'
import { updateGlobal, updatePageItemDataFilter } from '../../../projectSpecific/sports/dbActions/globals'
import { convertArraysToStrings, convertHelpers, hasValue } from '../../common/convert'
import { dataDiffTypes, getDataDiff, getDataNew } from "../../common/dataDiff"
import { dataFix } from '../../common/dataFix'
import { formatItem } from '../../common/dateFormatting'
import { propHelpers } from '../../common/tester'
import { copyObj } from '../../common_web/copy'
import { gEnums } from '../../enums/globalEnums'
import { updateHiddenData } from '../../firestoreData/hiddenData/updateHiddenData'
import { updateSettings } from "../../firestoreData/settings/updateSettings"
import { saveToStaticViews } from '../../firestoreData/staticViews/updateStaticViews'
import { addDataItemsToCollectionDatabase } from '../../firestoreData/updates/subUpdates/addDataItemsToCollection'
import { updateLinkHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_dataLInks'
import { fsUpdate_updateExistingCollection } from '../../firestoreData/updates/subUpdates/fsUpdate_updateExistingCollection'
import { fsfn_deleteEventCollection } from '../../functions/fbDelete'
import { fsfn_transfer } from '../../functions/fbTransfer'
import { fsfn_backupDataCollection } from '../../functions/fbUpdate'
import { dataManagementTypes } from '../../viewSettings/enums/itemActionTypes'
import { dispatchConfirmationTypes, dispatchProps, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps'
import { updateDocumentHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_document'

const rts = {
  handleActiveTab: 'handleActiveTab',
  handleAppItemData: 'handleAppItemData',
  handleApplyChanges: 'handleApplyChanges',
  handleBackupData: 'handleBackupData',
  handleBackupSeasonal: 'handleBackupSeasonal',
  handleCancelAction: 'handleCancelAction',
  handleCancelSelectedItem: 'handleCancelSelectedItem',
  handleCommit: 'handleCommit',
  handleConvertProp: 'handleConvertProp',
  handleCopy: 'handleCopy',
  handleCopyText: 'handleCopyText',
  handleCount: 'handleCount',
  handleDataActionItems: 'handleDataActionItems',
  handleDataActionSelect: 'handleDataActionSelect',
  handleDatabaseOpen: 'handleDatabaseOpen',
  handleDeleteDataItems: 'handleDeleteDataItems',
  handleDiffUpdate: 'handleDiffUpdate',
  handleDiffUpdates: 'handleDiffUpdates',
  handleFixArrays: 'handleFixArrays',
  handleFixDates: 'handleFixDates',
  handleGetDiff: 'handleGetDiff',
  handleGetNew: 'handleGetNew',
  handleGetPropAdjustments: 'handleGetPropAdjustments',
  handleGoogleData: 'handleGoogleData',
  handleHideData: 'handleHideData',
  handleMerge: 'handleMerge',
  handleOpenGoogleSheets: 'handleOpenGoogleSheets',
  handleProdData: 'handleProdData',
  handlePushDataToProduction: 'handlePushDataToProduction',
  handleRegData: 'handleRegData',
  handleRowSelect: 'handleRowSelect',
  handleSaveDataToDb: 'handleSaveDataToDb',
  handleSaveToStaticViews: 'handleSaveToStaticViews',
  handleSaveUrlLink: 'handleSaveUrlLink',
  handleSelect_dataActionOption: 'handleSelect_dataActionOption',
  handleSelectedItem: 'handleSelectedItem',
  handleSettingsFormUpdate: 'handleSettingsFormUpdate',
  handleShowCommit_dataManagment: 'handleShowCommit_dataManagment',
  handleShowExport: 'handleShowExport',
  handleShowHelp: 'handleShowHelp',
  handleTabChange: 'handleTabChange',
  handleUpdate_collection: 'handleUpdate_collection',
  handleUpdate_moveTo: 'handleUpdate_moveTo',
  handleUpdate_databaseFromGoogleSheet: 'handleUpdate_databaseFromGoogleSheet',
  handleUpdate_dataToDocument: 'handleUpdate_dataToDocument',
  handleUpdate_appDataLinks: 'handleUpdate_appDataLinks',
  handleUpdate_existingCollection: 'handleUpdate_existingCollection',
  handleUpdate_global: 'handleUpdate_global',
  handleUpdate_googleLink: 'handleUpdate_googleLink',
  handleUpdate_newData: 'handleUpdate_newData',
  handleUpdate_pageItemDataFilter: 'handleUpdate_pageItemDataFilter',
  handleViewDataMode: 'handleViewDataMode',
  handleShowRestoreCommit: 'handleShowRestoreCommit',
  ...grts,
}

export const propModTypes = {
  convertBackFromId: 'convertBackFromId',
  convertToArray: 'convertToArray',
  convertToId: 'convertToId',
  convertToString: 'convertToString',
  convertToWysiwyg: 'convertToWysiwyg',
  createCollection: 'createCollection',
  createGlobalData: 'createGlobalData',
  delete: 'delete',
  fixArray: 'fixArray',
  fixBooleans: 'fixBooleans',
  fixDates: 'fixDates',
  fixEmails: 'fixEmails',
  fixRelationships: 'fixRelationships',
  fixTime: 'fixTime',
  startCase: 'startCase',
  updateStaticView: 'updateStaticView',
}

export const backupModes = {
  backup: 'backup',
  restore: 'restore'
}

export const viewDataModes = {
  none: 'none',
  dataConstraints: 'dataConstraints',
  dataEdit: 'dataEdit',
  dataSource: 'dataSource',
  filter: 'filter',
  fixSettings: 'fixSettings',
  mergeSettings: 'mergeSettings',
  recreateDataAndSettings: 'recreateDataAndSettings',
  recreateDataCollection: 'recreateDataCollection',
  recreateDataCollections: 'recreateDataCollections',
  recreateDataDocuments: 'recreateDataDocuments',
  recreateSettings: 'recreateSettings',
  updateDataCollection: 'updateDataCollection',
  updateDataCollections: 'updateDataCollections',
  updateNewDataCollection: 'updateNewDataCollection',
  viewJson: 'viewJson',
  viewMerge: 'viewMerge',
  viewSettings: 'viewSettings',
  viewSettingsMerged: 'viewSettingsMerged',
}

export const dvLabelColors = {
  existingItems: 'blue',
  googleData: 'orange',
  array: 'teal',
  value: 'green'
}

export const dbSaveTypes = {
  appData: 'appData',
  diffData: 'diffData',
  fixed: 'fixed',
  googleSheetsData: 'googleSheetsData',
  newData: 'newData'
}

export const dataMergeTypes = {
  existingValue: 'existingValue',
  newValue: 'newValue',
}

export const mergeDirectionTypes = {
  existing_new: 'existing_new',
  new_existing: 'new_existing',
}

const getCurrentItems = (state, diffData, fixType) => {
  const { viewListData } = state
  const { diffItems, googleData } = diffData ? diffData : {}
  switch (fixType) {
    case dataDiffTypes.existingItems:
      return viewListData
    case dataDiffTypes.googleData:
      return googleData
    case dataDiffTypes.diffItems:
      return diffItems
    default:
      break;
  }
}

export const dataManagementReducer = (state, action) => {

  // firestore_handlers
  const { database_fns, logging, productionSettings, pathViews, page_fns, dataRestrictions, viewItem, viewListData, staticViews_app, diffData, newData, dateFormat, viewKey } = state
  const { dispatch, opts } = action
  const { dbSaveType, toProd, fixType, dataPropName, mergeWith, mergeDirectionType } = opts ?? {}
  const _counts = { ...state.counts }
  const _diffData = diffData ? diffData : {}
  const currentItems = getCurrentItems(state, _diffData, fixType)

  const { regData, googleData, prodData } = state ?? {}

  switch (action.type) {

    case rts.handleCommit:
      return { ...state, commit: true }

    case rts.handleGetPropAdjustments:
      return { ...state, propAdjustmentTypes: getPropAdjustmentTypes() }

    case rts.handleConvertProp:

      const { option } = action
      const { modType } = option
      const p = {
        appData: action.appData,
        dateFormat: action.dateFormat,
        option: action.option,
        staticViews: action.staticViews,
      }
      const { list } = convertProp(p)

      switch (modType) {
        case propModTypes.createGlobalData:
        case propModTypes.createCollection:
          if (!option) {
            return { ...state, option, selectedList: null }
          } else {
            return { ...state, option }
          }
        default:
          return { ...state, fixedDataList: list }
      }

    case rts.handleMerge:

      let _dm;

      switch (mergeWith) {
        case dataManagementTypes.registrationData:
          if (regData && viewListData) {
            _dm = getDataDiff(viewListData, regData, staticViews_app, false, mergeDirectionType)
          }
          break;
        case dataManagementTypes.googleSheetsData:
          if (googleData && viewListData) {
            _dm = getDataDiff(viewListData, googleData, staticViews_app, false, mergeDirectionType)
          }
          break;
        case dataManagementTypes.productionData:
          if (prodData && viewListData) {
            _dm = getDataDiff(viewListData, prodData, staticViews_app, true, mergeDirectionType)
          }
          break;
        default:
        // nothing
      }

      return { ...state, diffData: _dm, mergeWith, mergeDirectionType, updating: false }

    case rts.handleGetDiff:

      let _dd;

      switch (mergeWith) {
        case dataManagementTypes.registrationData:
          if (regData && viewListData) {
            _dd = getDataDiff(viewListData, regData, staticViews_app)
          }
          break;
        case dataManagementTypes.googleSheetsData:
          if (googleData && viewListData) {
            _dd = getDataDiff(viewListData, googleData, staticViews_app)
          }
          break;
        case dataManagementTypes.productionData:
          if (prodData && viewListData) {
            _dd = getDataDiff(viewListData, prodData, staticViews_app, true)
          }
          break;
        default:
        // nothing
      }
      return { ...state, diffData: _dd, mergeWith, mergeDirectionType }

    case rts.handleGetNew:

      let _nd;
      switch (mergeWith) {
        case 'regData':
          if (regData && viewListData) {
            _nd = getDataNew(viewListData, regData)
          }
          break;
        case 'googleData':
          if (googleData && viewListData) {
            _nd = getDataNew(viewListData, googleData)
          }
          break;
        default:
        // nothing
      }
      return { ...state, newData: _nd, mergeWith }

    case rts.handleCancelAction:
      return { ...state, fixedData: null, diffData: null }

    case rts.handleAppItemData:
      const json_e = _.isArray(action.viewListData) ? action.viewListData : _.sortBy(action.viewListData)
      const dataCount = json_e.length
      return {
        ...state,
        appData: action.viewListData,
        viewItem_preview: { ...action.viewItem },
        viewItem_previewTemp: { ...action.viewItem },
        currentViewItem: action.viewItem,
        ammendedItem: getAmmendedViewItem(state.aps_viewItems, action.viewListData, action.viewItem.key),
        dataCount,
      }

    case rts.handleProdData:
      return {
        ...state,
        prodData: action.prodData,
      }

    case rts.handleRegData:
      return {
        ...state,
        regData: action.regData,
      }

    case rts.handleSelect_dataActionOption:
      return {
        ...state,
        dataManagementType: action.dataManagementType ? action.dataManagementType : state._defaultMenuItem
      }

    case rts.handleGoogleData:
      return {
        ...state,
        googleData: action.googleItemData,
      }

    case rts.handleActiveTab:
      let _tabData;
      switch (action.activeIndex) {
        case 0:
          _tabData = state.appData
          break;
        case 1:
          _tabData = state.regData
          break;
        case 2:
          _tabData = state.googleData
          break;
        case 3:
          _tabData = state.diffData
          break;
        default:

      }
      return { ...state, activeTab: action.activeIndex, tabData: _tabData }

    case rts.handleApplyChanges:
      return { ...state, viewItem_preview: state.viewItem_previewTemp }

    case rts.handleDiffUpdates:
      const { dataMergeType } = action
      Object.keys(diffData).forEach(key => {
        const diffItem = diffData[key]
        Object.keys(diffItem).forEach(diKey => {
          const diffProp = diffItem[diKey]
          if (diffProp) {
            if (diffProp[dataMergeType]) {
              diffItem[diKey] = diffProp[dataMergeType]
            } else {
              switch (dataMergeType) {
                case dataMergeTypes.newValue:
                  if (hasValue(diffProp[dataMergeTypes.existingValue])) {
                    diffItem[diKey] = diffProp[dataMergeTypes.existingValue]
                  }
                  break;
                case dataMergeTypes.existingValue:
                  if (hasValue(diffProp[dataMergeTypes.newValue])) {
                    diffItem[diKey] = diffProp[dataMergeTypes.newValue]
                  }
                  break;
                default:
                // nothing
              }
            }
          }
        })
      })
      return { ...state, diffData: _diffData, saveReady: checkReady(_diffData) }

    case rts.handleDiffUpdate:
      return { ...state, diffData: action.diffData }

    case rts.handleCancelSelectedItem:
      return { ...state, selectedItem: null }

    case rts.handleCopy:
      return { ...state }

    case rts.handleCopyText:
      return { ...state }

    case rts.handleCount:
      _counts[action.key] = action.count
      return { ...state, counts: _counts }

    case rts.handleDatabaseOpen:
      return { ...state }

    case rts.handleFixArrays:
      if (currentItems) {
        convertArraysToStrings(currentItems, dataPropName)
        _counts['fixedData'] = Object.keys(currentItems).length
        return { ...state, fixedData: currentItems, counts: _counts }
      }
      return { ...state }

    case rts.handleFixDates:
      if (currentItems) {
        Object.keys(currentItems).forEach(key => {
          const item = currentItems[key]
          Object.keys(item).forEach(propKey => {
            if (propKey.toLowerCase().endsWith('date')) {
              item[propKey] = formatItem(dateFormat, item[propKey])
            }
          })
        })
        _counts['fixedData'] = Object.keys(currentItems).length
        return { ...state, fixedData: currentItems, counts: _counts }
      }

      return { ...state }

    case rts.handleSettingsFormUpdate:
      const _viewItem_previewTemp = { ...state.viewItem_previewTemp }
      _viewItem_previewTemp[state.viewDataMode] = action.data
      if (action.save) {
        updateSettings.updateViewViewItemSettingsDB(pathViews, state.viewItem_preview, viewKey, dispatch)
      }
      return { ...state, viewItem_preview: _viewItem_previewTemp, viewItem_previewTemp: _viewItem_previewTemp }

    case rts.handleOpenGoogleSheets:
      return { ...state }

    case rts.handleRowSelect:
      return { ...state, selectedItem: action.item, viewDataMode: viewDataModes.dataEdit }

    case rts.handleSaveDataToDb:

      const uecProps = { pathViews, collectionName: viewItem.key, dispatch, restrict: false, updateOnly: true }

      switch (dbSaveType) {
        case dbSaveTypes.diffData:
          uecProps.dataList = dataFix.removeEmpties(diffData)
          fsUpdate_updateExistingCollection(uecProps)
          break;

        case dbSaveTypes.newData:
          uecProps.dataList = dataFix.removeEmpties(newData)
          fsUpdate_updateExistingCollection(uecProps)
          break;

        case dbSaveTypes.appData:
          uecProps.dataList = diffData
          if (toProd) {
            fsfn_transfer.transferDataToProd(uecProps.pathViews, uecProps.collectionName, uecProps.itemKey, productionSettings, uecProps.dataList, false, logging).then(response_data => {
              const { success, error } = response_data ?? {}
              dispatch({ type: success ? dispatchProps.success : dispatchProps.success, error })
            }).catch(error => {
              console.error(error)
              dispatch({ type: dispatchProps.error, error })
            })
          }
          break;
        case dbSaveTypes.googleSheetsData:
          break;
        default:
        // nothing
      }
      return { ...state, updating: true }

    case rts.handleSelectedItem:
      return { ...state }

    case rts.handleTabChange:
      return { ...state }

    case rts.handleUpdate_newData:
      const { jsonItem, existing, key } = action.opts
      const { _itemKey } = jsonItem ? jsonItem : {}
      if (diffData && diffData[_itemKey] && diffData[_itemKey][key]) {
        if (existing) {
          diffData[_itemKey][key] = diffData[_itemKey][key].existingValue
        } else {
          diffData[_itemKey][key] = diffData[_itemKey][key].newValue
        }
      }
      return { ...state, diffData: _diffData, saveReady: checkReady(_diffData) }

    case rts.handleViewDataMode:
      return { ...state, viewDataMode: action.opts ? action.opts.viewDataMode : null }

    case rts.handleUpdate_dataToDocument:
      updateDocumentHelpers.update_dataToDocument(pathViews, action.subDataCollectionName, action.realData, dataRestrictions, dispatch)
      return { ...state }

    case rts.handleUpdate_appDataLinks:
      updateLinkHelpers.update_appLinkedData(pathViews, action.appDataLinks, dispatch)
      return { ...state }

    case rts.handleUpdate_collection:
      const ucProps = { pathViews, collectionName: action.collectionName, dataList: action.dataItems, dispatch, updateOnly: action.updateOnly, merge: action.merge, clearCollection: action.clearData }
      fsUpdate_updateExistingCollection(ucProps)
      return { ...state }

    case rts.handleUpdate_global:
      updateGlobal(pathViews, action.viewItemKey, action.dataList, dispatch)
      return { ...state }

    case rts.handleUpdate_moveTo:
      fsfn_transfer.transferDirect_path(action.projectIds, action.paths, dispatch)
      return { ...state }

    case rts.handleSaveToStaticViews:
      saveToStaticViews(pathViews, viewItem, action.dataList, action.staticName, null, dataRestrictions, dispatch)
      return { ...state }

    case rts.handleUpdate_pageItemDataFilter:
      updatePageItemDataFilter(action.isFilter, pathViews, action.viewItemKey, action.filterName, action.selectedFilters, dataRestrictions, dispatch)
      return { ...state }

    case rts.handleUpdate_existingCollection:
      fsUpdate_updateExistingCollection({ pathViews, collectionName: action.collectionName, updateOnly: action.updateOnly, dataList: action.dataList, dataRestrictions, dispatch })
      return { ...state, updating: true, confirmation: null }

    case rts.handleHideData:
      updateHiddenData(dispatch, pathViews, action.viewItem, action.viewListData, page_fns, action.unhide, rts.handleSuccess, rts.handleError)
      return { ...state }

    case rts.handlePushDataToProduction:
      fsfn_transfer.transferDataToProd(pathViews, [action.collectionName], action.itemKey, productionSettings, action.dataToTransfer, true).then(response_data => {
        const { success, error } = response_data ?? {}
        dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, collectionName: action.viewItemKey })
      }).catch(error => {
        dispatch && dispatch({ type: dispatchProps.error, collectionName: error })
      })
      return { ...state, updating: true }

    case rts.handleBackupData:
      fsfn_backupDataCollection(pathViews, action.viewItemKey, action.backupMode, dispatch)
      // backupData(pathViews, action.viewItemKey, action.viewListData, dataRestrictions, dispatch)
      return { ...state, updating: true }

    case rts.handleBackupSeasonal:
      const { dataSource } = viewItem ?? {}
      const { dataParents } = dataSource ?? {}
      seasonalHelpers.backupSeasonal(database_fns, pathViews, dataParents, null, dataRestrictions, dispatch)
      return { ...state, updating: true }

    case rts.handleUpdate_databaseFromGoogleSheet:
      // selectedItems, view, showReplaceConfirmation 
      if (action.showReplaceConfirmation) {
        fsfn_deleteEventCollection(pathViews, action.view, 100, logging).then(response_data => {
          const { success, error } = response_data ?? {}
          if (success) {
            addDataItemsToCollectionDatabase(pathViews, action.view, action.selectedItems, false, dispatch)
          } else {
            dispatch && dispatch({ type: dispatchProps.error, error })
          }
        }).catch(error => {
          dispatch && dispatch({ type: dispatchProps.error, error })
        })
      } else {
        addDataItemsToCollectionDatabase(pathViews, action.view, action.selectedItems, false, dispatch)
      }
      return { ...state }

    case rts.handleSaveUrlLink:
    case rts.handleUpdate_googleLink:
      // const _ufsd = firestore_handlers.handleUpdate_firestoreData
      const ufProps = getRequestProps(action.type, state, action)
      // _ufsd(dispatch, paps_state, ufProps)
      return { ...state }

    case rts.handleDataActionItems:
      return { ...state, dataActionItems: action.dataActionKeys }

    case rts.handleDataActionSelect:
      return { ...state, dataManagementType: action.dataManagementType }

    case rts.handleShowCommit_dataManagment:
      return { ...state, showCommitDataManagment: action.dataManagementType ? getCommit(action.dataManagementType, action.opts) : null, commitOpts: action.opts }

    case rts.handleShowHelp:
      return { ...state, showHelp: !state.showHelp }

    case rts.handleShowExport:
      return { ...state, showExport: !state.showExport }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const dataManagementHandlers = (dispatch, state) => {

  return {
    handleActiveTab: (activeIndex) => { dispatch({ type: rts.handleActiveTab, dispatch, activeIndex }) },
    handleAppItemData: (viewItem, viewListData) => { dispatch({ type: rts.handleAppItemData, viewItem, viewListData }) },
    handleApplyChanges: () => { dispatch({ type: rts.handleApplyChanges, dispatch }) },
    handleBackupData: (viewItemKey, backupMode) => { dispatch({ type: rts.handleBackupData, dispatch, viewItemKey, backupMode }) },
    handleBackupSeasonal: () => { dispatch({ type: rts.handleBackupSeasonal, dispatch }) },
    handleCancelAction: (opts) => { dispatch({ type: rts.handleCancelAction, dispatch, opts }) },
    handleCancelSelectedItem: () => { dispatch({ type: rts.handleCancelSelectedItem, dispatch }) },
    handleCommit: () => { dispatch({ type: rts.handleCommit, dispatch }) },
    handleConvertProp: (option, dateFormat, appData, staticViews) => { dispatch({ type: rts.handleConvertProp, dispatch, option, dateFormat, appData, staticViews }) },
    handleCopy: () => { dispatch({ type: rts.handleCopy, dispatch }) },
    handleCopyText: () => { dispatch({ type: rts.handleCopyText, dispatch }) },
    handleCount: (key, count) => { dispatch({ type: rts.handleCount, dispatch, key, count }) },
    handleDataActionItems: (dataActionKeys) => { dispatch({ type: rts.handleDataActionItems, dispatch, dataActionKeys }) },
    handleDataActionSelect: (dataManagementType) => { dispatch({ type: rts.handleDataActionSelect, dispatch, dataManagementType }) },
    handleDatabaseOpen: () => { dispatch({ type: rts.handleDatabaseOpen, dispatch }) },
    handleDiffUpdate: (diffData) => { dispatch({ type: rts.handleDiffUpdate, dispatch, diffData }) },
    handleDiffUpdates: (dataMergeType) => { dispatch({ type: rts.handleDiffUpdates, dispatch, dataMergeType }) },
    handleFixArrays: (opts) => { dispatch({ type: rts.handleFixArrays, dispatch, opts }) },
    handleFixDates: (opts) => { dispatch({ type: rts.handleFixDates, dispatch, opts }) },
    handleGetDiff: (opts) => { dispatch({ type: rts.handleGetDiff, dispatch, opts }) },
    handleGetNew: (opts) => { dispatch({ type: rts.handleGetNew, dispatch, opts }) },
    handleGetPropAdjustments: () => { dispatch({ type: rts.handleGetPropAdjustments, dispatch }) },
    handleGoogleData: (googleItemData) => { dispatch({ type: rts.handleGoogleData, dispatch, googleItemData }) },
    handleHideData: (viewItem, viewListData, secretProps, unhide) => { dispatch({ type: rts.handleHideData, dispatch, viewItem, viewListData, secretProps, unhide }) },
    handleMerge: (opts) => { dispatch({ type: rts.handleMerge, dispatch, opts }) },
    handleOpenGoogleSheets: () => { dispatch({ type: rts.handleOpenGoogleSheets, dispatch }) },
    handleProdData: (prodData) => { dispatch({ type: rts.handleProdData, dispatch, prodData }) },
    handlePushDataToProduction: (collectionName, itemKey, dataToTransfer) => { dispatch({ type: rts.handlePushDataToProduction, dispatch, collectionName, itemKey, dataToTransfer }) },
    handleRegData: (regData) => { dispatch({ type: rts.handleRegData, dispatch, regData }) },
    handleRowSelect: (item) => { dispatch({ type: rts.handleRowSelect, dispatch, item }) },
    handleSaveDataToDb: (opts) => { dispatch({ type: rts.handleSaveDataToDb, dispatch, opts }) },
    handleSaveToStaticViews: (dataList, staticName) => { dispatch({ type: rts.handleSaveToStaticViews, dispatch, dataList, staticName }) },
    handleSaveUrlLink: (urlLink, view, viewKey) => { dispatch({ type: rts.handleSaveUrlLink, dispatch, urlLink, view, viewKey }) },
    handleSelect_dataActionOption: (dataManagementType) => { dispatch({ type: rts.handleSelect_dataActionOption, dispatch, dataManagementType }) },
    handleSelectedItem: () => { dispatch({ type: rts.handleSelectedItem, dispatch }) },
    handleSettingsFormUpdate: (data, save) => { dispatch({ type: rts.handleSettingsFormUpdate, dispatch, data, save }) },
    handleShowCommit_dataManagment: (dataManagementType, opts) => { dispatch({ type: rts.handleShowCommit_dataManagment, dispatch, dataManagementType, opts }) },
    handleShowExport: (dataManagementType, opts) => { dispatch({ type: rts.handleShowExport, dispatch, dataManagementType, opts }) },
    handleShowHelp: (dataManagementType, opts) => { dispatch({ type: rts.handleShowHelp, dispatch, dataManagementType, opts }) },
    handleTabChange: () => { dispatch({ type: rts.handleTabChange, dispatch }) },
    handleUpdate_collection: (collectionName, dataItems, clearData, updateOnly, merge) => { dispatch({ type: rts.handleUpdate_collection, dispatch, collectionName, dataItems, clearData, updateOnly, merge }) },
    handleUpdate_databaseFromGoogleSheet: (selectedItems, view, showReplaceConfirmation) => { dispatch({ type: rts.handleUpdate_databaseFromGoogleSheet, dispatch, selectedItems, view, showReplaceConfirmation }) },
    handleUpdate_dataToDocument: (subDataCollectionName, realData) => { dispatch({ type: rts.handleUpdate_dataToDocument, dispatch, subDataCollectionName, realData }) },
    handleUpdate_appDataLinks: (appDataLinks) => { dispatch({ type: rts.handleUpdate_appDataLinks, dispatch, appDataLinks }) },
    handleUpdate_existingCollection: (collectionName, dataList, updateOnly) => { dispatch({ type: rts.handleUpdate_existingCollection, dispatch, collectionName, dataList, updateOnly }) },
    handleUpdate_global: (viewItemKey, dataList, asStaticView) => { dispatch({ type: rts.handleUpdate_global, dispatch, viewItemKey, dataList, asStaticView }) },
    handleUpdate_googleLink: (googleLinkData) => { dispatch({ type: rts.handleUpdate_googleLink, dispatch, googleLinkData }) },
    handleUpdate_moveTo: (projectIds, paths) => { dispatch({ type: rts.handleUpdate_moveTo, dispatch, projectIds, paths }) },
    handleUpdate_newData: (opts) => { dispatch({ type: rts.handleUpdate_newData, dispatch, opts }) },
    handleUpdate_pageItemDataFilter: (isFilter, viewItemKey, filterName, selectedFilters) => { dispatch({ type: rts.handleUpdate_pageItemDataFilter, dispatch, isFilter, viewItemKey, filterName, selectedFilters }) },
    handleViewDataMode: (opts) => { dispatch({ type: rts.handleViewDataMode, dispatch, opts }) },
    ...responseHandlers(dispatch),
    // handleAppSidebar: () => { dispatch({ type: rts.handleAppSidebar, dispatch }) },
  }
}

export const dataManagementInitialState = (initState) => {
  return { ...initState, activeTab: 0, counts: { existingItems: 0, googleData: 0, diffData: 0 } }
};


const checkReady = (mis) => {
  let ready = true
  if (mis) {
    Object.keys(mis).forEach(key => {
      const _dataItem = mis[key]
      Object.keys(_dataItem).forEach(diKey => {
        if (_dataItem[diKey].existingValue || _dataItem[diKey].newValue) {
          ready = false
        }
      })
    })
  }
  return ready
}

const getRequestProps = (type, state, action) => {
  const { viewItem } = state
  switch (type) {

    case rts.handleSaveUrlLink:
      return {
        dataUpdateType: gEnums.dataUpdateTypes.updateImageLinks,
        urlLink: action.urlLink,
        view: action.view,
        viewKey: action.viewKey
      }

    case rts.handleUpdate_googleLink:
      const { formData, currentPageData } = action.googleLinkData
      return {
        dataUpdateType: gEnums.dataUpdateTypes.updateDoc,
        itemData: {
          googleSheets: {
            googleSheetsKey: formData.googleSheetsKey
          }
        },
        vit: viewItem.key,
        viewKey: currentPageData.id
      }
    default:
      break;
  }
}

const getCommit = (dataManagementType) => {
  const _c = { caption: '', text: '' }
  switch (dataManagementType) {
    case dataManagementTypes.productionData:
      _c.caption = 'Please Confirm'
      _c.text = 'Are you sure you want to push the `Development Data` to `Production`? The current data in `Production` will be replaced by the `Development Data` data and cannot be undone.'
      _c.updateCaption = 'Push to Production'
      break;
    case dataManagementTypes.googleSheetsData:
      _c.caption = 'Please Confirm'
      _c.text = 'Are you sure you want to commit the `Google Data` to `Development`? The current data in the App will be replaced by the `Google Sheets Data` and cannot be undone.'
      _c.updateCaption = 'Save to Development'
      break;
    case dataManagementTypes.registrationData:
      _c.caption = 'Please Confirm'
      _c.text = 'Are you sure you want to commit the `Registrion Data` to `Development`? The current data in the App will be replaced by the `Registration Data` and cannot be undone.'
      _c.updateCaption = 'Save to Development'
      break;
    default:
      _c.caption = 'Please Confirm ' + dataManagementType
      _c.text = 'Are you sure?'
      _c.updateCaption = 'Save???'
      break;
  }
  return _c
}

const getPropAdjustmentTypes = () => {
  const _pa = []
  _pa.push({ modType: propModTypes.convertBackFromId, icon: 'list ol', color: 'blue' })
  _pa.push({ modType: propModTypes.convertToArray, icon: 'list ol', color: 'blue' })
  _pa.push({ modType: propModTypes.convertToId, icon: 'list ol', color: 'blue' })
  _pa.push({ modType: propModTypes.convertToString, icon: 'list ol', color: 'blue' })
  _pa.push({ modType: propModTypes.convertToWysiwyg, icon: 'list ol', color: 'blue' })
  _pa.push({ modType: propModTypes.createCollection, icon: 'wizard', color: 'blue' })
  _pa.push({ modType: propModTypes.createGlobalData, icon: 'wizard', color: 'blue' })
  _pa.push({ modType: propModTypes.delete, icon: 'delete', color: 'red' })
  _pa.push({ modType: propModTypes.fixArray, icon: 'list ol', color: 'blue' })
  _pa.push({ modType: propModTypes.fixBooleans, icon: 'check', color: 'blue' })
  _pa.push({ modType: propModTypes.fixDates, icon: 'calendar', color: 'blue' })
  _pa.push({ modType: propModTypes.fixEmails, icon: 'mail', color: 'blue' })
  _pa.push({ modType: propModTypes.fixRelationships, icon: 'recycle', color: 'blue' })
  _pa.push({ modType: propModTypes.fixTime, icon: 'time', color: 'blue' })
  _pa.push({ modType: propModTypes.startCase, icon: 'font', color: 'blue' })
  _pa.push({ modType: propModTypes.updateStaticView, icon: 'wizard', color: 'blue' })
  return _pa
}

const convertProp = (props) => {

  const {
    appData,
    dateFormat,
    option,
    staticViews,
  } = props

  const { propKey, modType } = option

  if (modType && propKey && appData) {

    const _propKey = propKey.toLowerCase()

    let _staticView;

    let _appData = { ...appData }

    switch (modType) {
      case propModTypes.createGlobalData:
      case propModTypes.createCollection:
        const list = createList(appData, propKey)
        return {
          option,
          list
        }

      default:
        Object.keys(_appData).forEach(key => {

          const _dataItem = _appData[key]
          const dataPropValue = _dataItem[propKey]

          switch (modType) {

            case propModTypes.startCase:
              if (dataPropValue && !_.isArray(dataPropValue) && !_.isObject(dataPropValue)) {
                _dataItem[propKey] = _.startCase(_dataItem[propKey])
              }
              break;

            case propModTypes.delete:
              delete _dataItem[propKey]
              break;

            case propModTypes.convertToArray:
              if (dataPropValue) {
                if (!_.isArray(dataPropValue)) {
                  _dataItem[propKey] = [dataPropValue]
                }
              }
              break;

            case propModTypes.convertToString:
              if (dataPropValue) {
                if (_.isArray(dataPropValue)) {
                  _dataItem[propKey] = dataPropValue[0]
                }
              }
              break;

            // case propModTypes.convertToWysiwyg:
            //   if (dataPropValue) {
            //     _dataItem[propKey] = convertToWYSIWYG(dataPropValue)
            //   }
            //   break;

            case propModTypes.convertToId:
              if (staticViews && propKey && staticViews[propKey]) {
                _staticView = staticViews[propKey]
                const xxx = _.findKey(_staticView, { name: dataPropValue })
                _dataItem[propKey] = xxx
              }
              break;

            case propModTypes.convertBackFromId:
              if (staticViews && propKey && staticViews[propKey]) {
                _staticView = staticViews[propKey]
                const item = _staticView[dataPropValue]
                const name = convertHelpers.getItemName(item)
                _dataItem[propKey] = name
              }
              break;

            case propModTypes.fixArray:
              if (dataPropValue) {
                if (!_.isArray(dataPropValue)) {
                  _dataItem[propKey] = dataPropValue[0]
                } else {
                  _dataItem[propKey] = [dataPropValue]
                }
              }
              break;

            case propModTypes.fixBooleans:
              if (dataPropValue) {
                if (propHelpers.isYesNoProp(dataPropValue)) {
                  _dataItem[propKey] = dataPropValue === 'yes' || dataPropValue === 'Yes' ? true : false
                }
              }
              break;

            case propModTypes.fixDates:
              if (dataPropValue && propHelpers.isDateProp(_propKey)) {
                _dataItem[propKey] = formatItem(dateFormat, dataPropValue)
              }
              break;

            case propModTypes.fixRelationships:
              const propKey_s = _propKey + 's'
              if (staticViews && propKey_s && staticViews[propKey_s]) {
                _staticView = staticViews[propKey_s]
                if (_staticView[dataPropValue]) {
                  _dataItem[propKey_s] = [dataPropValue]
                }
              }
              delete _dataItem[_propKey]
              break;

            case propModTypes.fixTime:
              if (dataPropValue && propHelpers.isTimeProp(_propKey)) {
                let _selectedTime = _dataItem[propKey]
                _selectedTime = _selectedTime && _selectedTime.length === 8 ? _selectedTime.substring(_selectedTime, 5) : _selectedTime
                _selectedTime = _selectedTime && _selectedTime.length === 4 ? '0' + _selectedTime : _selectedTime
                _dataItem[propKey] = _selectedTime
              }
              break;

            case propModTypes.fixEmails:
              if (dataPropValue && propHelpers.isEmailProp(_propKey)) {
                _dataItem[propKey] = _dataItem[propKey].toLowerCase()
              }
              break;

            default:
            // nothing
          }
        })
        return {
          option,
          list: _appData
        }
      // setSelectedOption(option)
      // setFixedDataList(_appData)
    }
  }
}

const createList = (items, dataFieldName) => {
  const _items = []
  Object.keys(items).forEach(key => {
    const d = items[key]
    const n = dataFieldName ? d[dataFieldName] : convertHelpers.getItemName(d)
    if (n) {
      if (_.isArray(n)) {
        n.forEach(d => {
          if (!_items.includes(d)) {
            _items.push(d)
          }
        })
      } else {
        if (!_items.includes(n)) {
          _items.push(n)
        }
      }
    }
  })
  return _items
}

const getAmmendedViewItem = (aps_viewItems, viewListData, key_viewItem) => {
  if (aps_viewItems && aps_viewItems[key_viewItem]) {
    const aps_vi = copyObj(aps_viewItems[key_viewItem])
    const propKeys = []
    const { props: props_vi } = aps_vi ?? {}
    if (viewListData && props_vi) {
      Object.keys(props_vi).forEach(pk => {
        const pr_vi = props_vi[pk]
        const { data: data_vi } = pr_vi ?? {}
        const { formItemType } = data_vi ?? {}
        switch (formItemType) {
          case gEnums.formItemTypes.select:
            Object.keys(viewListData).forEach(pdk => {
              const pdi = viewListData[pdk]
              const pdii = pdi[pk]
              if (pdii && _.isArray(pdii)) {
                data_vi.formItemType = gEnums.formItemTypes.dropdown
                if (!propKeys.includes(pk)) {
                  propKeys.push(pk)
                }
              }
            })
            break;
          default:
          // nothing
        }
      })
    }
    if (propKeys.length > 0) {
      return { ammendedViewItem: aps_vi, ammendedKeys: propKeys }
    }
  }
} 