import React, { useState } from 'react'
import { Label } from 'semantic-ui-react'
import { createInitials } from '../../../../global/common/dataAdjust'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'

export const Keypad = (props) => {

  const { setScoresOn, maxValues, maxDigits, setNumber, match } = props
  const { home, away } = match ?? {}
  const { name: name_home } = home ?? {}
  const { name: name_away } = away ?? {}

  const maxLength = (maxValues * maxDigits) + 1

  const [pickedPad, setPickedPad] = useState()
  const [selectedPad, setSelectedPad] = useState('away')
  const [padValue, setPadValue] = useState('')
  const [padValue1, setPadValue1] = useState('')
  const [padValue2, setPadValue2] = useState('')

  const handleUpdate = () => props.handleUpdate(padValue)
  const handleClearScores = () => {
    setPadValue('')
    setPadValue1('')
    setPadValue2('')
  }

  const handleButtonClick = (value) => {

    if (pickedPad) {
      switch (pickedPad) {
        case 'away':
          let av = padValue1
          av += value
          if (av.length > maxDigits) { return false }
          setPadValue1(av)
          break;
        case 'home':
          let hv = padValue2
          hv += value
          if (hv.length > maxDigits) { return false }
          setPadValue2(hv)
          break;
        default:
          break;
      }
    } else {
      let pv = padValue
      pv += value
      if (pv.length > maxLength) { return false }
      if (pv.length === 2) { pv += '-' }
      const pvs = pv.split('-')

      setPadValue1(pvs[0])
      if (pvs.length === 2) { setPadValue2(pvs[1]) }

      setPadValue(pv)
      switch (pv.length) {
        case 0:
        case 1:
          setSelectedPad('away')
          break;
        default:
          setSelectedPad('home')
          break;
      }
    }
  }

  const handlePick = (value) => {
    setPickedPad(value)
    setSelectedPad(value)
  }

  const handleClose = (value) => setScoresOn()
  const handleClear = (value) => {

    if (pickedPad) {
      switch (pickedPad) {
        case 'away':
          setPadValue1('')
          break;
        case 'home':
          setPadValue2('')
          break;
        default:
          break;
      }
    } else {
      setPadValue('')
      setPadValue1('')
      setPadValue2('')
    }
  }

  const teamInitials = (name) => {
    let tt = createInitials(name)
    if (tt.length === 1) { tt = name.substring(0, 4) }
    return tt
  }

  const pad = (value, oc) => <div color={'blue'} onClick={() => { oc(value) }}>{value}</div>

  const scoreRow = (divs, start, end) => {
    for (var i = start; i <= end; i++) {
      divs.push(pad(i, handleButtonClick))
    }
  }

  const scoreGrid = () => {
    const divs = []
    scoreRow(divs, 1, 3)
    scoreRow(divs, 4, 6)
    scoreRow(divs, 7, 9)
    divs.push(pad('X', handleClear))
    divs.push(pad(0, handleButtonClick))
    divs.push(pad('<', handleClear))

    return <div className={'scorepad-container'}>
      {divs}
    </div>
  }

  const header = () => <div className={'scorepad-header'}>
    <div>{teamInitials(name_away)}</div>
    <div><Label color={selectedPad === 'away' ? 'green' : 'grey'} size={'big'} className={pickedPad === 'away' ? 'selected' : null} onClick={() => { handlePick('away') }}>{padValue1}</Label></div>
    <div><Label color={'blue'} >{'Set: '}{setNumber}</Label></div>
    <div><Label color={selectedPad === 'home' ? 'green' : 'grey'} size={'big'} className={pickedPad === 'home' ? 'selected' : null} onClick={() => { handlePick('home') }}>{padValue2}</Label></div>
    <div>{teamInitials(name_home)}</div>
  </div>

  const footer = () => {
    const btns = []
    btns.push({ oc: handleClose, caption: 'Close', icon: 'delete', fl: true, color: 'black' })
    btns.push({ oc: handleClearScores, caption: 'Clear', icon: 'ban', fl: true, color: 'grey' })
    btns.push({ oc: handleUpdate, caption: 'Update Set', icon: 'check' })
    return <UiSaveButtons others={btns} />
  }

  const content = () => <Wrapper
    header={header()}
    content={scoreGrid()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  return content()

}
