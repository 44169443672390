import React, { createContext, useEffect, useReducer, useState } from 'react';
import { databaseHandlers, databaseInitialState, databaseReducer } from '../reducers/DatabaseReducer';
import { _projectAppNames, getAppName } from '../../../project/appConfiguration';
import { fs_db } from '../../firestoreData/appData/fsAppData';

export const DatabaseContext = createContext();

const DatabaseProvider = (props) => {

  const init_state = databaseInitialState({})
  const [database_state, dispatch] = useReducer(databaseReducer, init_state);
  const database_handlers = databaseHandlers(dispatch)
  const { fsdb } = database_state

  const [database_fns, setDatabase_fns] = useState()

  useEffect(() => {
    database_handlers.handleInit_database()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dbfn = {
      ...fs_db,
      get_fs: (init_alt) => fs_db.get_fs(init_alt, fsdb),
      get_collection: (props) => fs_db.get_collection(props, fsdb),
      get_data: (props) => fs_db.get_data(props, fsdb),
      get_doc: (props) => fs_db.get_doc(props, fsdb)
    }
    setDatabase_fns(dbfn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsdb]);

  // useEffect(() => {
  //   console.log('fsdb', fsdb)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fsdb]);

  return (
    <DatabaseContext.Provider value={{ database_handlers, database_state, database_fns }}>
      {props.children}
    </DatabaseContext.Provider>
  )
}

export default DatabaseProvider