import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { SettingParentContext } from '../cnr/contexts/SettingParentContext';
import { SettingsAreaContext } from '../cnr/contexts/SettingsAreaContext';
import ViewSettingsProvider from '../cnr/contexts/ViewSettingsContext';
import { viewSettingsHandlers, viewSettingsInitialState, viewSettingsReducer } from '../cnr/reducers/ViewSettingsReducer';
import { uniqueKey } from '../common/keys';
import { appIconTypes } from '../enums/appIconTypes';
import { iconColorTypes } from '../enums/settingsIconTypes';
import { fsUpdate_timeStamps } from '../firestoreData/updates/subUpdates/fsTimeStamps';
import Wrapper from '../wrappers/Wrapper';
import ViewSettingsSwiper from './ViewSettingsSwiper';
import MenuSettingsClose from './components/menus/MenuSettingsClose';
import SettingsMenuSubHeader from './components/menus/SettingsMenuSubHeader';

/**
 * 
 * @returns The main content of all of the viewSettings, nested under the ViewSettingsSidebar
 */
const ViewSettings = () => {

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { states, handlers, settings } = settingsParentContext ?? {}
  const { appUser_state, paps_state, page_state, appSettings_state } = states ?? {}
  const { navigate } = handlers ?? {}
  const { homeSettings } = settings ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { settingsOptions: settingsOptions_home } = global_home ?? {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext)
  const { settingsArea_state, settingsArea_handlers } = settingsAreaContext ?? {}
  const { isGlobal, isSolo, selectedGroupItem, startSwipeItems } = settingsArea_state ?? {}
  const { handleCloseGroupItem } = settingsArea_handlers ?? {}

  const allowUpdates = true

  const { appUser } = appUser_state ?? {}
  const { pathViews, view, isMobileDevice, appArea, settingsDocName } = paps_state
  const { sandboxOn } = appSettings_state ?? {}
  const { pageSettings, trueView } = page_state ?? {}
  const { aps_page, aps_global, aps_viewItems, aps_views } = pageSettings
  const { useDarkMode } = settingsOptions_home ?? {}

  const settings_init = {
    gvvi: {
      global: { ...aps_global },
      viewItems: { ...aps_viewItems },
      views: { ...aps_views },
    },
    page: aps_page ? { ...aps_page } : {},
  }

  // local state
  const initState = {
    allowUpdates,
    appUser,
    isGlobal,
    isMobileDevice,
    settingsParentContext,
    selectedGroupItem,
    settings_init,
    startSwipeItems,
    view,
  }

  // reducer
  const [viewSettings_state, viewSettings_dispatch] = useReducer(viewSettingsReducer, initState, viewSettingsInitialState);
  const viewSettings_handlers = viewSettingsHandlers(viewSettings_dispatch, viewSettings_state, isGlobal)

  const { confirmation, updating, viewSettingsHaveChanged, isSolo: solo_settings, _timestamp } = viewSettings_state

  const trueSolo = solo_settings || isSolo ? true : false

  // local state
  const [confirmOn, setConfirmOn] = useState()

  const handleSettingsUpdate = () => viewSettings_handlers.handleUpdate_dbSettings(trueSolo)

  const updateTimeStamp = () => fsUpdate_timeStamps(pathViews, 'settings', _timestamp)

  useEffect(() => {
    if (confirmation) {
      setConfirmOn(true)
      const timer = setTimeout(() => setConfirmOn(false), confirmation.success === true ? 2000 : 3000);
      return () => {
        updateTimeStamp()
        clearTimeout(timer)
      };
    }
    // eslint-disable-next-line
  }, [confirmation]);

  // IMPORTANT: Settings - get AVP here   
  // if (!aps_page) { return <div>ViewSettings New pending</div> }

  let globalName = _.startCase(appArea)
  const settingsCaption = isGlobal ? globalName + ' Settings' : 'Page Settings'
  let pathCaption = isGlobal ? settingsDocName : trueView

  const closeSettings = () => {
    if (handleCloseGroupItem) { handleCloseGroupItem() }
    if (!handleCloseGroupItem) { navigate(-1) }
  }

  const confirmButton = {
    caption: 'Saved',
    ready: true,
    primary: allowUpdates ? true : false,
    color: iconColorTypes.success,
    keyy: 'ai-save',
    icon: 'check circle outline',
  }

  let saveColor = sandboxOn ? 'blue' : iconColorTypes.save
  if (!allowUpdates) { saveColor = iconColorTypes.deny }

  const saveButton = {
    caption: 'Save Settings',
    ready: viewSettingsHaveChanged,
    onClick: handleSettingsUpdate,
    primary: allowUpdates ? true : false,
    color: updating ? iconColorTypes.fetching : saveColor,
    keyy: 'ai-save',
    icon: updating ? 'cog' : 'save',
    title: 'Save',
    loading: updating,
    nb: updating,
    className: 'blink_me_2'
  }

  /** The buttons for the top header in viewSettings, left or right */
  const rightButtons = () => {
    const b = []
    if (confirmation && confirmation.success && confirmOn) {
      b.push(confirmButton)
    } else {
      b.push(saveButton)
    }
    return b
  }

  const viewSettingsSwiper = () => <ViewSettingsProvider viewSettings_state={viewSettings_state} viewSettings_handlers={viewSettings_handlers}>
    <ViewSettingsSwiper key={uniqueKey('vs', 'vss')} trueSolo={trueSolo} />
  </ViewSettingsProvider>

  const menuHeader = (iconLeft, iconLeftColor, ocLeft, title, subTitle, labelTitle, rbs) => {
    const x = { iconLeft, iconLeftColor, ocLeft, title, subTitle, labelTitle, rbs }
    return <SettingsMenuSubHeader key={uniqueKey('msh', title)} {...x} />
  }

  let wcn = 'setz vs'
  if (trueSolo) { wcn += ' app' }
  const ccn = 'ct-vs'

  if (trueSolo) {
    return <Wrapper
      header={menuHeader(appIconTypes.arrowLeft, 'blue', closeSettings, settingsCaption, pathCaption, (isGlobal ? 'app' : 'view'), rightButtons())}
      content={viewSettingsSwiper()}
      footer={<MenuSettingsClose useDarkMode={useDarkMode} />}
      updating={updating}
      css={{ container: wcn, content: ccn }}
    >
    </Wrapper>
  } else {
    return <Wrapper
      header={menuHeader(appIconTypes.arrowLeft, 'blue', closeSettings, settingsCaption, pathCaption, (isGlobal ? 'app' : 'view'), rightButtons())}
      content={viewSettingsSwiper()}
      updating={updating}
      css={{ container: wcn, content: ccn }}
    >  </Wrapper>
  }
}

export default ViewSettings