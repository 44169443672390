import { dataFix } from '../../common/dataFix';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../firestoreData/appData/fsData';
import { _settingsFs } from './getSettings';

/** Updates the `settings_globalPageItems' collection
 * @collection settings_globalPageItems
 * @doc all
 */
export const globalPageItems_update = (previewInfo, dispatch) => {
  const d = { viewItems: dataFix.removeEmpties(previewInfo) }
  fs_set_doc(['settings_global_pageItems', 'all'], d, false, dispatch)
}

/** This will create settings_globalPageItems from all the existing viewItems for ALL the settings */
export const globalPageItems_preview = (dispatch) => {
  fs_db.get_data({ refPath: [_settingsFs.root] }).then(_allSettings => {
    const vis = {}
    Object.keys(_allSettings).forEach(key => {
      const setting = _allSettings[key]
      if (setting && setting.viewItems) {
        Object.keys(setting.viewItems).forEach(viKey => {
          if (viKey.indexOf('golf') < 0 && viKey.indexOf('lab') < 0 && viKey.indexOf('run') < 0 && viKey.indexOf('holes') < 0) {
            const globalVi = setting.viewItems[viKey]
            // check validity
            if (globalVi && globalVi.propSections && globalVi.dataConnection) {
              vis[viKey] = { ...globalVi }
              delete vis[viKey].data
              delete vis[viKey].side
            }
          }
        })
      }
    })
    const d = { viewItems: vis }
    dispatch({ type: 'showPreview', previewInfo: d });
  })
}