import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Icon, Label, Menu, Segment } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { updateArrayInclusion } from '../../../../global/common/sorting'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext'
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer'
import MatchScheduleWithProvider from '../../elements/matches/MatchSchedule'
import { CreatePlayoffContext } from '../CreatePlayoff'

const TeamMatchCompare = (props) => {

  const { inViewer, sportsShowType: sportsShowType_props } = props ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { teams_info, sportsShowType, sportCompares } = sportsSeason_state ?? {}
  const { handleShow_sportsElem } = sportsSeason_handlers ?? {}
  const { teams } = teams_info ?? {}
  const { levelRecords, teamMatches, directMatches, commonMatches, opponentMatches, teamResults } = sportCompares ?? {}

  const createPlayoffContext = useContext(CreatePlayoffContext)
  const { createPlayoff_state } = createPlayoffContext ?? {}
  const { playoff_info } = createPlayoff_state ?? {}
  const { playoffLevel, playoffLevelTeams } = playoff_info ?? {}
  const _playoffLevelTeams = playoffLevelTeams && playoffLevel && playoffLevelTeams[playoffLevel] ? playoffLevelTeams[playoffLevel] : null

  let _sportsShowType = sportsShowType_props ? sportsShowType_props : sportsShowType

  const [teamOptions, setTeamOptions] = useState()
  const [selectedTeams, setSelectedTeams] = useState([])
  const [topperCaption2, setTopperCaption2] = useState([])
  const [selectedTeamSchedules, setSelectedTeamSchedules] = useState([])

  useEffect(() => {

    const _teamOptions = []
    if (teams) {
      const _teams = _.sortBy(teams, 'name')
      _teams.forEach(team => {
        const { name, _itemKey } = team ?? {}
        _teamOptions.push({ key: _itemKey, text: name, value: _itemKey })
      })
    }

    switch (_sportsShowType) {
      case sportsShowTypes.compareTeams:
        setTopperCaption2('Common Opponents')
        setTeamOptions(_teamOptions)
        break;

      case sportsShowTypes.matchesRemaining:
        setTeamOptions(_teamOptions)
        break;

      case sportsShowTypes.compareSchedules:
        setTopperCaption2('Team Schedules')
        !_playoffLevelTeams && setTeamOptions(_teamOptions)
        break;

      case sportsShowTypes.compareSections:
        setTopperCaption2('Non Section Match Records')
        break;

      case sportsShowTypes.fullStandings:
        setTopperCaption2('Full Standings')
        break;
      default:
      // nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [teams]);

  useEffect(() => {
    if (selectedTeams) {
      switch (_sportsShowType) {
        case sportsShowTypes.compareSections:
          sportsSeason_handlers.handleCalc_sectionCompares()
          break;
        case sportsShowTypes.compareTeams:
          if (selectedTeams && selectedTeams.length === 2) {
            sportsSeason_handlers.handleCalc_teamCompares(selectedTeams)
          } else {
            sportsSeason_handlers.handleCalc_teamCompares()
          }
          break;
        case sportsShowTypes.matchesRemaining:
          sportsSeason_handlers.handleCalc_matchesRemaining(selectedTeams)
          break;
        default:
        // nothing 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedTeams]);

  useEffect(() => {
    if (_playoffLevelTeams) {
      switch (_sportsShowType) {
        case sportsShowTypes.matchesRemaining:
        case sportsShowTypes.compareSchedules:
          const keys = _.map(_playoffLevelTeams, item => item._itemKey)
          setSelectedTeams(keys)
          break;
        default:
          setSelectedTeams([])
        // nothing
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_playoffLevelTeams]);

  const handleSelectTeam = (teamKey) => {
    const _selectedTeamSchedules = [...selectedTeamSchedules]
    updateArrayInclusion(_selectedTeamSchedules, teamKey)
    setSelectedTeamSchedules(_selectedTeamSchedules)
  }

  const handleTeamChange = (e, data) => {
    switch (_sportsShowType) {
      case sportsShowTypes.compareSchedules:
        setSelectedTeams(data.value)
        break;
      case sportsShowTypes.compareTeams:
        if (data.value && data.value.length > 2) {
          setSelectedTeams(data.value.slice(0, 2))
        } else {
          setSelectedTeams(data.value)
        }
        break;
      case sportsShowTypes.matchesRemaining:
        if (data.value && data.value.length > 5) {
          setSelectedTeams(data.value.slice(0, 5))
        } else {
          setSelectedTeams(data.value)
        }
        break;
      default:
      // nothing 
    }
  }

  const teamSchedules = () => {
    const mis = []
    if (teams) {
      selectedTeams.forEach(st => {
        const team = teams[st]
        const { name, _itemKey } = team ?? {}
        const _selected = selectedTeamSchedules.includes(_itemKey)
        mis.push(<Menu.Item key={uniqueKey('tsi', _itemKey)}>
          <Menu.Header onClick={(e) => { handleSelectTeam(_itemKey) }}>
            <div>{name}</div>
            <Icon name={_selected ? 'caret down' : 'arrow right'} style={{ float: 'right' }}></Icon>
          </Menu.Header>
          {_selected && <Menu.Menu>
            <Menu.Item key={uniqueKey('tsi', _itemKey, 's')} >
              <MatchScheduleWithProvider matchScheduleType={matchScheduleTypes.overview} teamKey={_itemKey} noMid={true} />
            </Menu.Item>
          </Menu.Menu>}
        </Menu.Item>)
      })
    }
    return <Menu vertical inverted fluid className='match-team-schedules'>
      {mis}
    </Menu>
  }

  const opponentSchedule = () => {
    const opps = []
    if (opponentMatches) {
      Object.keys(opponentMatches).forEach(key => {
        const team = teams[key]
        const { name } = team ?? {}
        const _commonMatches = opponentMatches[key]
        if (name && _commonMatches) {
          opps.push(<Segment.Group>
            <Segment>{name}</Segment>
            <Segment><MatchScheduleWithProvider componentContexts={{}} commonMatches={_commonMatches} /></Segment>
          </Segment.Group>)
        }
      })
    }
    return opps
  }

  const teamsSchedule = () => {
    const opps = []
    if (teamMatches) {
      _.each(teamMatches, (_teamMatches, key) => {
        const team = teams[key]
        const { name } = team ?? {}
        if (name && _teamMatches) {
          opps.push(<Segment.Group>
            <Segment>{name}</Segment>
            <Segment><MatchScheduleWithProvider componentContexts={{}} commonMatches={_teamMatches} /></Segment>
          </Segment.Group>)
        }
      })
    }
    return opps
  }

  const teamsResults = () => {
    const segs = []
    if (teamResults) {
      _.each(teamResults, (tr, key) => {
        const { wp } = tr
        const _team = teams[key]
        const { name } = _team
        segs.push(<Segment>{name}{' '}{wp.toFixed(3)}</Segment>)
      })
    }
    return <Segment.Group>
      <Segment>{'Winning Percentages (common)'}</Segment>
      {segs}
    </Segment.Group>
  }

  const schedules = () => <div className='match-team-container'>
    {teamsResults()}
    <Segment.Group>
      <Segment>{'Direct'}</Segment>
      <Segment><MatchScheduleWithProvider componentContexts={{}} commonMatches={directMatches} /></Segment>
    </Segment.Group>
    {opponentSchedule()}
  </div>

  const records_level = () => {
    const groups = []
    _.each(levelRecords, (lr, lrKey) => {
      groups.push(<Segment>{lrKey}</Segment>)
      delete lr.Non
      const _lr = _.orderBy(lr, ['wp'], ['desc'])
      _.each(_lr, (sr, srKey) => {
        switch (srKey) {
          case 'Non':
            break;
          default:
            const { l, w, tm, wp, sectionKey } = sr ?? {}
            const _lr = lrKey + ' - ' + _.startCase(sectionKey)
            groups.push(
              <Segment.Group>
                <Segment>{_lr}</Segment>
                <Segment>
                  <Label color={'black'}>{tm}</Label>
                  <Label color={'green'}>{w}</Label>
                  <Label color={'red'}>{l}</Label>
                  <Label color={'grey'}>{wp}</Label>
                </Segment>
              </Segment.Group>
            )
        }
      })
    })
    return groups
  }

  const ddTeams = () => <Dropdown
    placeholder='Teams'
    fluid
    multiple
    selection
    options={teamOptions}
    value={selectedTeams}
    onChange={handleTeamChange}
  />

  const content = () => {
    switch (_sportsShowType) {
      case sportsShowTypes.compareSchedules:
        return teamSchedules()
      case sportsShowTypes.compareSections:
        return levelRecords ? records_level() : <div></div>
      case sportsShowTypes.compareTeams:
        return (commonMatches || directMatches) ? schedules() : <div></div>
      case sportsShowTypes.matchesRemaining:
        return teamMatches ? teamsSchedule() : <div></div>
      default:
      // nothing 
    }
  }

  const header = () => {
    if (teamOptions) {
      return ddTeams()
      // } else {
      //   return <MatchFilter />
    }
  }

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    wrapperType={wrapperTypes.padded}
    wrapperCss={{ container: 'app-dark-mode' }}
  ></Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleShow_sportsElem}
    topperCaption={_.startCase(_sportsShowType)}
    topperCaption2={topperCaption2}
    wrapperCss={{ container: 'app-dark-mode' }}
  ></FullPageWrapper>

  return inViewer ? wrapper() : fpw()
}

export default TeamMatchCompare