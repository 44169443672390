import _ from 'lodash'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Form, Icon, Label, Message, Segment } from 'semantic-ui-react'
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions'
import { PageContext } from '../../../../global/cnr/contexts/PageContext'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer'
import { uniqueKey } from '../../../../global/common/keys'
import { removeArrayItem, sortObj } from '../../../../global/common/sorting'
import NoData from '../../../../global/components/alerts/NoData'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import DragDropper, { dragDropperTypes } from '../../../../global/dragNDrops/DragDropper'
import { currentHelpers } from '../../../../global/redirection/current'
import MenuSidebars from '../../../../global/sidebars/MenuSidebars'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { ammendTeamName } from '../../cnr/reducers/SportsMatchReducer'
import { CreateSportsContext } from '../../create/CreateSports'
import { getSectionColor } from '../../create/sectionColors'

const tKey = 'ts'
const sidebarType = 'rankings'

const SportsRankings = (props) => {

  const { allowRanking, handleActionPreview, sortedRankings, selectedLevel, selectedWeek, rankings_info_data } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state } = states
  const { storageItemPage } = paps_state ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isAdminOrSuper } = appUserAccess ?? {}

  const pageContext = useContext(PageContext)
  const { page_fns } = pageContext ?? {}
  const { pushSimple } = page_fns ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { inverted, rankings_info, teams_info, seasonal_info, sports_info } = sportsSeason_state ?? {}
  let { currents } = sportsSeason_state ?? {}
  const { sportLevels } = seasonal_info ?? {}``
  const { rankings: rnks, lastestRanking } = rankings_info ?? {}
  const { teams } = teams_info ?? {}
  const { ignoreRankings } = sports_info ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_handlers } = createSportsContext ?? {}

  // sidebar_state 
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers, storageItemPage }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { dimmed, sidebar_items } = sidebar_state ?? {}
  const { setInits } = sidebar_handlers ?? {}
  const { ms_weeks, ms_levels } = sidebar_items ?? {}

  const [allTeams, setAllTeams] = useState()
  const [rankings] = useState(rnks ? sortObj(rnks) : null)
  const [sortedLevels, setSortedLevels] = useState()
  const [showPaste, setShowPaste] = useState()
  const [pasteValue, setPasteValue] = useState()

  let _rankings = sortedRankings ? sortedRankings : rankings
  const { rankings: rankings_data } = rankings_info_data ?? {}
  if (rankings_data) { _rankings = rankings_data }

  const showRankStatus = sortedRankings ? false : true

  const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }
  const _initPropsN = allowRanking ? { sidebarSliderType: sidebarSliderTypes.normal } : { sidebarSliderType: sidebarSliderTypes.normal }

  const hrs = !showRankStatus ? ['#', 'Team'] : ['#', '+/-', 'Team', 'P', 'B', 'C']

  const ammendWeeks = (wr, count) => {
    for (var w = 1; w <= count; w++) {
      wr.push('Week ' + w)
    }
  }

  useEffect(() => {
    if (!rnks && props.rankings) {
      sportsSeason_handlers && sportsSeason_handlers.handleUpdate_sportsSeasonSubData('rankings', props.rankings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    const wr = []

    if (selectedWeek) {
      wr.push(selectedWeek.item)
    } else {
      if (_rankings) {
        const c = Object.keys(_rankings).length
        ammendWeeks(wr, c)
      }
    }

    const sls = selectedLevel ? [selectedLevel.item] : sportLevels
    removeArrayItem(sls, 'Non')

    if (!currents) { currents = {} }
    currents.levels = sls && currentHelpers.getStorageItem('ms_levels', sls, storageItemPage)
    currents.weeks = wr && currentHelpers.getStorageItem('ms_weeks', wr, storageItemPage)

    setInits([
      { smt: sidebarMenuTypes.one, items: sls, currents, as: 'levels', ..._initProps },
      { smt: sidebarMenuTypes.two, items: wr, currents, as: 'weeks', ..._initPropsN },
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_rankings, selectedWeek, lastestRanking]);

  useEffect(() => {
    if (teams) {
      sportsSeason_fns.updateTeamBests(rnks, teams)
      const all_teams = {}
      // create all_teams
      Object.keys(teams).forEach((key, index) => {
        const team = teams[key]
        if (!all_teams[key]) { all_teams[key] = team }
      })

      setAllTeams(all_teams)

      const lts = {}
      const levelTeams = _.groupBy(teams, 'levels')
      Object.keys(levelTeams).forEach(key => {
        const teams = levelTeams[key]
        const teams_s = sportsSeason_fns.sortLevelStandings(teams)
        const sts = teams_s.map((team, index) => ({
          id: team.key,
          name: team.name,
          record: team.record,
          sections: team.sections,
          levels: team.levels,
          position: index + 1
        }))
        lts[key] = sts
      })
      setSortedLevels(lts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_levels && ms_levels.selected]);

  const handleShowPaste = () => setShowPaste(!showPaste)

  const handlePaste = (e, { value }) => {
    // Split the input string by line breaks and remove empty lines
    const lines = value.split('\n').filter(line => line.trim() !== '');

    // Extract team numbers and names into an array of objects
    const teamList = lines.map((line, index) => {
      const match = line.match(/(\d+)\.\s(.+)/);
      if (match) {
        const position = index + 1  //parseInt(match[1]);
        const name = match[2].trim();
        const _name = ammendTeamName(name)
        return { position, name: _name };
      }
      return null;
    }).filter(item => item !== null);

    if (teamList) {
      const dataItems = []
      teamList.forEach(item => {
        const { position, name } = item
        const _team = _.find(allTeams, { name: name })
        if (_team) {
          dataItems.push({ ..._team, position })
        } else {
          dataItems.push({ name: '???', position })
        }
      })
      const sls = { ...sortedLevels }
      sls[ms_levels.selected.item] = dataItems
      setSortedLevels(sls)
      createSports_handlers && createSports_handlers.handleSet_sortedLevels(sls, ms_levels.selected, ms_weeks.selected)
      handleActionPreview && handleActionPreview({ sortedLevels: sls, selectedWeek: ms_weeks.selected.item, selectedLevel: ms_levels.selected.item })
    }
    handleShowPaste()
  }

  const handleGotoTeam = (team) => {
    if (teams) {
      const _team = _.findKey(teams, { name: team })
      if (_team) {
        pushSimple({ key: 'teams' }, _team)
      }
    }
  }

  const handleSorted = (sortedGroups) => {
    if (sortedGroups) {
      const { sortedItems } = sortedGroups ?? {}
      const { dataItems } = sortedItems ?? {}
      const sls = { ...sortedLevels }
      sls[ms_levels.selected.item] = dataItems
      setSortedLevels(sls)
      createSports_handlers && createSports_handlers.handleSet_sortedLevels(sls, ms_levels.selected, ms_weeks.selected)
      handleActionPreview && handleActionPreview({ sortedLevels: sls, selectedWeek: ms_weeks.selected.item, selectedLevel: ms_levels.selected.item })
    }
  }

  const dotLabel = (dotValue, value, color) => <div className='dot-label'>
    <Label circular size='mini' color={color} style={value ? { marginRight: '1em' } : null} >{dotValue}</Label>
    {value}
  </div>

  const headerRow = () => (
    hrs.map((key, index) => {
      switch (key) {
        case 'P':
          return dotLabel('P', null, 'green')
        case 'B':
          return dotLabel('B', null, 'blue')
        case 'C':
          return dotLabel('C', null, 'teal')
        default:
          return <div key={uniqueKey('sr', 'hr', index)}>{key}</div>
      }
    })
  )

  const tableHeader = (keyy) => (
    <div key={uniqueKey('sr', 'th', keyy)} className={'table-rankings-header'}>
      {headerRow()}
    </div>
    // <Table.Header key={uniqueKey('sr', 'th', keyy)}>
    //   <Table.Row>
    //     {headerRow()}
    //   </Table.Row>
    // </Table.Header>
  )

  const rankLabel = (diff) => {
    if (!diff || diff === 0) {
      return <Label color={'blue'} size={'mini'}><Icon name={'caret right'} />{0}</Label>
    } else {
      let color = (diff < 0) ? 'red' : 'green'
      let icon = (diff < 0) ? 'caret down' : 'caret up'
      let value = Math.abs(diff)
      return <Label color={color} size={'mini'}><Icon name={icon} />{value}</Label>
    }
  }

  const legend = () => (
    <Segment  >
      {dotLabel('P', 'Previous', 'green')}
      {dotLabel('B', 'Best', 'blue')}
      {dotLabel('C', 'Current', 'teal')}
    </Segment>
  )

  const teamRows = (teamArray) => {

    // eslint-disable-next-line 
    return teamArray.map((t, index) => {

      const teamName = t
      const rankOrder = index + 1

      const rankedTeam = _.find(allTeams, { name: teamName })

      if (rankedTeam) {
        const weekIndex = ms_weeks.selected.index
        const twr = rankedTeam.weeklyRankings
        const ranks = {
          best: (twr.best) ? twr.best : '-',
          current: twr.byWeek ? twr.byWeek[weekIndex] : '-',
          previous: (weekIndex > 0) && twr.byWeek[weekIndex - 1] ? twr.byWeek[weekIndex - 1] : '-'
        }

        let rankColor = 'grey'
        let rankL = rankLabel()

        if (ranks.previous) {
          if (ranks.previous === '-') {
            rankL = <Label color={'yellow'} size={'mini'}><Icon name={'caret right'} />{'-'}</Label>
          } else {
            if (rankOrder < ranks.previous) {
              rankColor = 'green'
              rankL = rankLabel(ranks.previous - rankOrder)
            } else if (rankOrder > ranks.previous) {
              rankColor = 'red'
              rankL = rankLabel(ranks.previous - rankOrder)
            } else {
              rankColor = 'grey'
              rankL = rankLabel(ranks.previous - rankOrder)
            }
          }

        }

        if (ms_weeks.selected.index === 0) {
          ranks.previous = '-'
          rankColor = 'grey'
          rankL = rankLabel()
        }

        ranks.previousRank = rankOrder

        if (!showRankStatus) {
          return <div>
            <div>
              <Label size='medium' >{rankOrder}</Label>
            </div>
            <div>
              {teamName}
            </div>
          </div>
        } else {
          return <div key={tKey + '-' + rankOrder + '-' + index} onClick={(e) => handleGotoTeam(t)}>
            <div> <Label size='medium' color={'black'}>{rankOrder}</Label></div>
            <div>{rankL}</div>
            <div>{teamName}</div>
            <div>{ranks.previous}</div>
            <div>{ranks.best}</div>
            <div>{ranks.current}</div>
          </div>
        }
      }
    })
  }

  const tableBody = (teamArray, keyy) => <div key={uniqueKey('sr', 'tb', keyy)} className={'table-rankings-teams'}>
    {teamRows(teamArray)}
  </div>

  const weekLevelTable = (keyy, teamArray) => <div key={uniqueKey('sr', 'wlt', keyy)} className={'table-rankings mtable'}  >
    {tableHeader(keyy)}
    {tableBody(teamArray, keyy)}
  </div>

  const lblSize = 'small'
  const indexLabel = (index) => <Label size={lblSize} color={'black'}>{index}</Label>
  const sectionLabel = (section) => <Label size={lblSize} color={getSectionColor(section)}>{section}</Label>
  const sectionRankLabel = (section, rank) => <Label size={lblSize} color={getSectionColor(section)}>{rank}</Label>
  const numberLabelWith = (index, text, section, sectionRank) => <Label color={'grey'}>{indexLabel(index)}{text} {sectionRankLabel(section, sectionRank ? sectionRank : '-')} {sectionLabel(section)}</Label>

  const form_paste = () => <Form>
    <Form.TextArea value={pasteValue} className={'text-area-paste'} key={uniqueKey('vip', 'ta')} onChange={handlePaste} placeholder='paste here...' />
  </Form>

  const menuMenuItems = (dataItems) => {
    const ds = []
    Object.keys(dataItems).forEach((key, index) => {
      const di = dataItems[key]
      const { name, record, sections } = di
      const { display, sectionRank } = record ?? {}
      const caption = name + ' ' + display
      let position = index + 1
      if (position > 10) { position = 'NR' }
      let cn = 'drag-number'
      ds.push(<div className={cn}>{numberLabelWith(position, caption, sections, sectionRank)}</div>)
    })
    return ds
  }

  const message = () => <Message
    warning
    size={'mini'}
    icon='exclamation'
    content={'The rankings for ' + ms_levels.selected.item + ' - ' + ms_weeks.selected.item + ' already exists'}
  />

  const teamSortContainer = () => {
    let exists = false;
    if (rnks && rnks[ms_weeks.selected.item] && rnks[ms_weeks.selected.item][ms_levels.selected.item]) {
      exists = true
    }
    const levelTeams = sortedLevels[ms_levels.selected.item]
    const x = _.sortBy(levelTeams, 'position')
    const dndGroups = {
      sortedItems: {
        dataItems: x,
        elements: menuMenuItems(x)
      }
    }

    return <Segment basic>
      {exists && message()}
      <DragDropper
        dndType={dragDropperTypes.simple}
        dndGroups={dndGroups}
        handleSorted={handleSorted}
        isLabelSort={true}
        allowSort={true}
        portalCn={'msmi four menu'}
      />
    </Segment>
  }

  const wrapper_paste = () => <Wrapper
    content={form_paste()}
  ></Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper_paste()}
    topperCaption={'Paste Rankings'}
  ></FullPageWrapper>

  const content = () => {
    if (showPaste) {
      return fpw()
    } else {
      return teamSortContainer()
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons save={{ oc: handleShowPaste, caption: 'Show Paste' }} />}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper >

  const weekTables = () => {
    const s = []
    if (_rankings) {
      Object.keys(_rankings).forEach((weekKey, weekIndex) => {
        const rankings_week = _rankings[weekKey]
        Object.keys(rankings_week).forEach(levelKey => {
          const ranking_level_week = rankings_week[levelKey]
          const keyy = weekKey + '_' + levelKey
          if ((ms_weeks.selected && ms_weeks.selected.item === weekKey) && (ms_levels && ms_levels.selected.item === levelKey)) {
            s.push(weekLevelTable(keyy, ranking_level_week))
          }
        })
      })
    }
    if (s.length === 0) { s.push(<Segment>{'No rankings for the selected level/week.'}</Segment>) }
    s.push(legend())
    return s
  }

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={allowRanking ? wrapper() : weekTables()}
    dimmed={dimmed}
    inverted={inverted}
    cnn={'srr'}
  />

  if (ignoreRankings && !isAdminOrSuper) {
    return <NoData fullCaption={'Ranking are not activated at this time.'} />
  } else {
    if (allTeams && ms_levels && ms_levels.items && ms_weeks && ms_weeks.items && ms_weeks.items.length > 0) {
      try {
        return menuSidebars()
      } catch (error) {
        console.log('e', error)
        return <div>Error</div>
      }
    } else {
      return <NoData fullCaption={'No Rankings'} />
    }
  }
}

export default SportsRankings 