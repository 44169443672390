import { serverTimestamp } from "firebase/firestore";
import { createRefPath_event } from '../../appData/appRefPaths';
import { fs_db_update } from '../../appData/fsData';
import { fs_db } from "../../appData/fsAppData";
import { _cacheNames } from "../../../cnr/reducers/ServiceWorkerReducer";
import { sendMessageToServiceWorker } from "../../../cnr/reducers/reducerHelpers/serviceWorkerHelpers";

/**
 * updates the _timeStamps/timeStamp document
 * @param {*} pathViews 
 * @param {*} vit 
 * @param {*} _timestamp 
 */
export const fsUpdate_timeStamps = (pathViews, vit, _timestamp) => {

  const cb = () => {
    switch (vit) {
      case 'settings':
        break;
      default:
        const settings = {
          cacheName: _cacheNames.cache_data + '-' + pathViews.events,
          cacheType: '_timeStamps',
          cacheData: { [vit]: _timestamp },
          merge: true
        }
        sendMessageToServiceWorker(settings)
    }
  }

  // updates the _timeStamps collection
  const refPath = createRefPath_event(pathViews, ['_timeStamps', 'timeStamps'])
  const dataToUpdate = {
    [vit]: _timestamp ? _timestamp : serverTimestamp()
  }
  fs_db_update.set_doc(refPath, dataToUpdate, true, cb)
}

export const fsUpdate_timeStamp = (pathViews, vit, _timestamp) => {
  // updates the _timeStamps collection
  const refPath = createRefPath_event(pathViews, ['_timeStamps', 'timeStamps'])
  const dataToUpdate = {
    [vit]: _timestamp ? _timestamp : serverTimestamp()
  }
  // fs_db_update.set_doc(refPath, dataToUpdate, true)
}

export const fsGet_timeStamps = (pathViews, callback) => {
  const refPath = createRefPath_event(pathViews, ['_timeStamps', 'timeStamps'])
  fs_db.get_data({ refPath, callback, opts: { ignoreId: true, returnFirstObject: true } })
}
