import React, { useContext, useEffect, useState } from 'react';
import { AppFormsContext } from '../../../../global/cnr/contexts/AppFormsContext';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import FormForm from '../../../../global/components/forms/FormForm';
import { getFormPropz } from '../../../../global/components/forms/props/formPropz';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { _sportsFormProps, SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { gEnums } from '../../../../global/enums/globalEnums';
import { CreateSportsContext } from '../CreateSports';
import { appFormTypes } from '../../../../global/enums/appFormTypes';
import { createSportsFormProps } from '../../formProps/createFormProps';

const EditSeasonal = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { handleCancel, subActionType, fromDashboard } = props

  // createSportsContext
  const sportsSeasonContext = useContext(SportsSeasonContext);
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { latestSeason, details_info, sports_info, sportsButtonProps, parentDataKeys, sportPermissions } = sportsSeason_state ?? {}
  const { details } = details_info ?? {}
  const { details: details_key } = parentDataKeys ?? {}

  console.log('sportsSeason_state', sportsSeason_state)

  let appFormType;
  let _allowUpdate;

  switch (subActionType) {
    case _asat.editSeasonDetails:
      appFormType = appFormTypes.createSeason
      _allowUpdate = sportPermissions && sportPermissions.any && sportPermissions.details
      break;
    case _asat.sportsSettings:
      _allowUpdate = sportPermissions && sportPermissions.any && sportPermissions.sportsSettings
      break;
    default:
      appFormType = appFormTypes.editSeason
      _allowUpdate = sportPermissions && sportPermissions.any && sportPermissions.sports
      break;
  }


  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_handlers } = createSportsContext ?? {}

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state, appForms_fns } = appFormsContext ?? {}
  const { appForms } = appForms_state ?? {}
  const { getForms } = appForms_fns ?? {}


  const [formProps, setFormProps] = useState()
  const [currentFormData, setCurrentFormData] = useState({})

  const handleLocalPropChange = (data_form) => setCurrentFormData(data_form)

  const form_handlers = { handleFormDataChange_local: handleLocalPropChange }

  useEffect(() => {
    console.log('sportsSeason_state', sportsSeason_state)

    if (!appFormType) {
      const appFormAlt = createSportsFormProps({ formPropType: 'sportsSettings' })
      const fpp = { form_handlers, viewProps: appFormAlt }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)

    } else if (appForms && appForms[appFormType]) {

      const appForm = appForms[appFormType]

      let _data_current = {}

      switch (subActionType) {
        case _asat.editSeasonDetails:
          _data_current = { ...details }
          break;

        default:
          _data_current = { ...sports_info }
          break;
      }
      setCurrentFormData(_data_current)
      const fpp = { form_handlers, viewProps: appForm, data_current: _data_current }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
    } else {
      getForms()
    }

    // eslint-disable-next-line 
  }, [subActionType]);

  const cb_update = () => handleCancel()

  const handleUpdateSeason = () => {
    let dataToUpdate = {}
    switch (subActionType) {

      case _asat.editSeasonDetails:
      case _asat.sportsSettings:
        Object.keys(currentFormData).forEach(sdi => {
          if (currentFormData[sdi]) {
            dataToUpdate[sdi] = currentFormData[sdi]
          }
        })
        delete dataToUpdate._deleteProps
        createSports_handlers.handleDbUpdate_seasonal(subActionType, dataToUpdate, latestSeason)
        break;

      default:
        dataToUpdate = { ...currentFormData }
        sportsSeason_handlers.handleDbUpdate_sportsDoc(dataToUpdate, cb_update)
        break;
    }
  }

  const form = () => formProps ? <FormForm
    formAttributes={{ autoComplete: "off" }}
    formProps={formProps}
  /> : <div></div>


  const wrapper = () => <Wrapper
    content={form()}
    footer={<UiSaveButtons others={[{ oc: handleUpdateSeason, caption: 'Update', icon: 'save', color: _allowUpdate ? 'green' : 'teal' }]} />}
    wrapperType={wrapperTypes.padded}
  />

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Edit Season'}
    topperCaption2={details_key}
    handleCancel={handleCancel}
  ></FullPageWrapper>


  return fromDashboard ? wrapper() : fpw()

}

export default EditSeasonal