import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { uniqueKey } from '../../../../global/common/keys'
import { gEnums } from '../../../../global/enums/globalEnums'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext'
import { SportsSeasonContext, _resultsProp, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext'
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer'
import { matchCalcs } from '../../helpers/match'
import MatchOveriew from './MatchOveriew'
import MatchRealtimeScoring, { scoreTypes } from './MatchRealtimeScoring'
import MatchScheduleWithProvider from './MatchSchedule'
import MatchScoreTable from './MatchScoreTable'
import MatchSidebar from './MatchSidebar'

const _noResultCaption = 'No Results'

const _icons = {
  home: 'home',
  away: 'bus',
  time: 'clock outline',
  date: 'calendar outline',
  location: 'map marker alternate',
}

export const MatchSummaryContext = createContext();

const MatchSummary = (props) => {

  const { componentContexts } = props ?? {}
  const latestMatchesCount = 5

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states
  const { page_fns } = fns
  const { appUser } = appUser_state ?? {}
  const { loggedIn } = appUser ?? {}
  const { pathViews } = paps_state ?? {}
  const { pushSimple } = page_fns ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_styles } = pageSettings ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { matches_info, useAppDarkMode, gameScoringType, sportsShowType } = sportsSeason_state ?? {}
  const { matches, playoffMatches, matches_realtime } = matches_info ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, matchAllows } = sportsMatch_state ?? {}
  const { edit: _allowEdit, scores: _allowScores, matchAccessLevel } = matchAllows ?? {}

  //sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  const { showRealtimeScoring } = sportsRtMatch_state ?? {}
  const { handleShow_rtMatch } = sportsRtMatch_handlers ?? {}

  const [showMatchResults, setShowMatchResults] = useState()

  const { _itemKey, key, home, away, startDate, startTime, sections, levels, results_edit, results_realtime, isPlayoff, poolKey, location } = match_true ? match_true : {}
  const results = match_true ? match_true[_resultsProp] : undefined;
  const { name: location_name } = location ? location : {}

  const _sectionCaption = isPlayoff && poolKey ? _.startCase(poolKey) : _.startCase(sections)

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionButton] : {}

  let _rstz = results_edit ? results_edit : results
  if (results_realtime && !complete) { _rstz = results_realtime }
  const { complete, scoreDisplays, players } = _rstz ?? {}
  const { normal } = scoreDisplays ?? {}

  const [match_result_realtime, setMatch_result_realtime] = useState()
  const { realTimeMatch, realTimeScorer } = match_result_realtime ?? {}

  useEffect(() => {
    switch (sportsShowType) {
      case sportsShowTypes.realtimeMatch:
        handleShow_rtMatch()
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsShowType]);

  useEffect(() => {
    let tm = matches && matches[pathViews.matches] ? matches[pathViews.matches] : null
    if (!tm) { tm = playoffMatches && playoffMatches[pathViews.matches] ? playoffMatches[pathViews.matches] : {} }
    if (tm) {
      matchCalcs.calcVballMatch(tm, gameScoringType)
      sportsMatch_handlers.handleSet_trueMatch(tm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, playoffMatches]);

  useEffect(() => {
    if (matches_realtime) {
      const realtimeMatches = matches_realtime ? matches_realtime[key] : {}
      const _allResults = []
      let realTimeScorer;
      if (realtimeMatches) {
        Object.keys(realtimeMatches).forEach(rmKey => {
          const match_rt = realtimeMatches[rmKey]
          const { displayName, results } = match_rt ?? {}
          realTimeScorer = displayName
          _allResults.push(results)
        })
        if (_allResults && _allResults.length > 0) {
          setMatch_result_realtime({ realTimeMatch: _allResults[0], realTimeScorer })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, _itemKey]);

  const handleClick = (team) => team && pushSimple && sportsSeason_handlers.handleGoToTeam(pushSimple, team)

  const handleShowMatchScoreTable = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    const opts = { sportsShowType: sportsShowTypes.updateResults, matchKey: _itemKey }
    sportsSeason_handlers.handleShow_sportsElem(opts)
  }

  const icon = (name) => <Icon name={name} color={'blue'} size={'small'} />

  const matchPlayers = () => players ? <div className={'summary-split'}>
    <div className={'matches-latest-team'}>Team 1</div>
    <div className={'matches-latest-team'}>Team 2</div>
  </div> : 'No Top Players Selected'

  const overview = () => <div className={'team-overview ms'}>
    {complete && <MatchOveriew
      useAppDarkMode={useAppDarkMode}
      caption={'Top Players'}
      content={matchPlayers()}
      cn={'match-players'}
    />}
    <MatchOveriew
      caption={'Team Form'}
      content={<div className={'summary-split'}>
        <div className={'matches-latest-team'}><MatchScheduleWithProvider matchScheduleType={matchScheduleTypes.latest} componentContexts={componentContexts} latestMatchesCount={latestMatchesCount} forOverview={true} teamKey={away.id} /></div>
        <div className={'matches-latest-team'}><MatchScheduleWithProvider matchScheduleType={matchScheduleTypes.latest} componentContexts={componentContexts} latestMatchesCount={latestMatchesCount} forOverview={true} teamKey={home.id} /></div>
      </div>}
    />
  </div>

  const matchContent = () => {
    let resultCn = 'ms-result'
    if (_allowScores) { resultCn += ' ae' }
    if (matchAccessLevel) { resultCn += ' mal-' + matchAccessLevel }
    return home && away ?
      <div className='ms-summary-full'>
        <div className={'ms-summary'}>
          <div key={uniqueKey('ms', 'mc', 1)} className={'ms-home'} onClick={() => { handleClick(home) }}>{home.name}{icon(_icons.home)}</div>
          <div key={uniqueKey('ms', 'mc', 2)} className={'ms-away'} onClick={() => { handleClick(away) }}>{icon(_icons.away)}{away.name}</div>
          <div key={uniqueKey('ms', 'mc', 3)} className={'ms-date'}>{icon(_icons.date)}{startDate}</div>
          <div key={uniqueKey('ms', 'mc', 4)} className={'ms-time'}>{icon(_icons.time)}{startTime}</div>
          <div key={uniqueKey('ms', 'mc', 5)} className={'ms-level'}>{'Level: '}{levels}</div>
          <div key={uniqueKey('ms', 'mc', 6)} className={'ms-section'}>{_sectionCaption}</div>
          <div key={uniqueKey('ms', 'mc', 7)} className={'ms-location'}>{icon(_icons.location)}{location_name ? location_name : home.name}</div>
          <div key={uniqueKey('ms', 'mc', 8)} className={resultCn} onClick={(e) => { _allowScores && handleShowMatchScoreTable(e) }}>{normal ? normal : _noResultCaption}</div>
          <div key={uniqueKey('ms', 'mc', 9)} className={'ms-score'}><MatchScoreTable match_edit={match_true} /></div>
        </div>
        <MatchSummaryContext.Provider value={{ matchSummary_state: { isSummary: true } }}>
          {overview()}
        </MatchSummaryContext.Provider>
      </div>
      : <div></div>
  }

  const sidebar = () => <MatchSidebar
    allowPopup={loggedIn && _allowEdit}
    content={matchContent()}
    handleShowPopupContents={handleShow_rtMatch}
    sidebarContent={<MatchRealtimeScoring
      match_edit={match_true}
      scoreType={scoreTypes.realtime}
      viewKey={match_true.key}
    />}
    styleAndClass={styleAndClass}
    visible={showRealtimeScoring}
  />

  return match_true ? sidebar() : <div></div>

}

export default MatchSummary