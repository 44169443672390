import React, { createContext, Suspense, useContext } from 'react';
import SportsDistrictProvider from '../../../projectSpecific/sports/cnr/contexts/SportsDistrictContext';
import SportsOrganizationProvider from '../../../projectSpecific/sports/cnr/contexts/SportsOrganizationContext';
import SportsSeasonProvider from '../../../projectSpecific/sports/cnr/contexts/SportsSeasonContext';
import SuspenseDimmer from '../../components/alerts/SuspenseDimmer';
import { gEnums } from '../../enums/globalEnums';
import { ParentContext } from './ParentContext';
import TrackerProvider from './TrackerContext';

// GOOD TO GO
const GolfTournamentProvider = React.lazy(() => import('../../../projectSpecific/golf/cnr/contexts/GolfTournamentContext'));

/** AppContext */
export const PageComponentContext = createContext();

/**
 * Wrapper for specific
 * @param {object} props 
 * @returns 
 */
const PageComponentProvider = (props) => {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}
  const { organizations: organizationsKey, districts: districtsKey, sports: sportsKey } = pathViews ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems } = pageSettings ?? {}

  const component_contents = []
  let component_content;

  // see if any of the viewItems have a `componentContent`
  Object.keys(pathViews).forEach(pv => {
    let viewItem_g;
    if (aps_viewItems && aps_viewItems[pv]) {
      viewItem_g = aps_viewItems[pv]
      const { pageSettings } = viewItem_g ?? {}
      const { componentContent, useComponentContent } = pageSettings ?? {}
      if (componentContent && useComponentContent) {
        component_content = componentContent
        component_contents.push({ name: pv, component: componentContent })
      }
    }
  })

  const providerContext = () => {

    switch (component_content) {

      case gEnums.componentContentTypes.golfTournament:
        return <Suspense fallback={<SuspenseDimmer origin={'PageComponentContext'} />}>
          <GolfTournamentProvider fromPage={true} >
            {props.children}
          </GolfTournamentProvider>
        </Suspense>

      case gEnums.componentContentTypes.sportsOrganization:
        return <Suspense fallback={<SuspenseDimmer origin={'PageComponentContext'} />}>
          <SportsOrganizationProvider organizationsKey={organizationsKey}>
            {props.children}
          </SportsOrganizationProvider>
        </Suspense>

      case gEnums.componentContentTypes.sportsDistrict:
        return <Suspense fallback={<SuspenseDimmer origin={'PageComponentContext'} />}>
          <SportsOrganizationProvider organizationsKey={organizationsKey}>
            <SportsDistrictProvider districtsKey={districtsKey}>
              {props.children}
            </SportsDistrictProvider>
          </SportsOrganizationProvider>
        </Suspense>

      case gEnums.componentContentTypes.sportsSeason:
        return <Suspense fallback={<SuspenseDimmer origin={'PageComponentContext'} />}>
          <TrackerProvider>
            <SportsOrganizationProvider organizationsKey={organizationsKey}>
              <SportsDistrictProvider districtsKey={districtsKey}>
                <SportsSeasonProvider sportsKey={sportsKey}>
                  {props.children}
                </SportsSeasonProvider>
              </SportsDistrictProvider>
            </SportsOrganizationProvider>
          </TrackerProvider>
        </Suspense>

      default:
        return <PageComponentContext.Provider value={{}}>
          {props.children}
        </PageComponentContext.Provider>
    }
  }

  return providerContext()

}

export default PageComponentProvider