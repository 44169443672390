import { serverTimestamp } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { _checkComponentTimes } from '../../../App';
import { Disclaimers } from '../../../Disclaimers';
import { allAppKeys, allowAppStorageAndFunctions } from '../../../project/appConfiguration';
import { appUserAccessPermissions, getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { allowMovement } from '../../components/map/mapHelper';
import { gEnums } from '../../enums/globalEnums';
import { updateAppUserFcmTokenToDatabase } from '../../firestoreData/profiles/updateToken';
import { currentHelpers } from '../../redirection/current';
import { _currentUserIdProp, appUserHandlers, appUserReducer, appUserReducerInitialState } from '../reducers/AppUserReducer';
import { AppUsersContext } from './AppUsersContext';
import { AuthContext } from './AuthContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { StartContext } from './StartContext';

const _useDisclaimers = false

/** 
@provides appUser_state
@provides appUser_handlers 
*/
export const AppUserContext = createContext();

const AppUserProvider = (props) => {

  // parentContext not available  
  const startContext = useContext(StartContext)
  const { start_fns } = startContext ?? {}

  // authContext
  const authContext = useContext(AuthContext)
  const { auth_state } = authContext ?? {}
  const { currentAuthUser } = auth_state ?? {}
  const { presenceStatus } = currentAuthUser ?? {}

  // papsContext
  const appUsersContext = useContext(AppUsersContext)
  const { appUsers_state } = appUsersContext ?? {}
  const { eventAppUsers } = appUsers_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state, navigate } = papsContext ?? {}
  const { hostName, pathViews, platform, view, sharePathFull } = paps_state ?? {}
  const { isStandAlone } = platform ?? {}

  // papsContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { redirectToAppUserPage, appUserCollection, allowTokenCapture, allowPresenceTracking, allowDesktopToken, ignoreTokenAlert, allowCurrentUserTracking } = aps_appUserSettings ?? {}
  const { appSignIn, appLocationTracking, appSettings } = aps_global ?? {}
  const { forceDisclaimers, useCookiesDisclaimer, useSmsDisclaimer, usePushDisclaimer, useEmailDisclaimer } = appSignIn ?? {}
  const { allowLocationTracking, allowMovementTracking, movementDistance } = appLocationTracking ?? {}
  const { useProductionRedirect } = appSettings ?? {}

  let _allowFcmPush = allowTokenCapture && (allowDesktopToken || isStandAlone)

  // local state
  // platform, accessRequests, pageContext
  const initState = appUserReducerInitialState({ currentAuthUser, aps_appUserSettings, pathViews, view })
  const [appUser_state, appUser_dispatch] = useReducer(appUserReducer, initState);
  const appUser_handlers = appUserHandlers(appUser_dispatch, appUser_state)

  const { appUserLocation, appUserChecked, appUser, appUsers, tokenAsked, accessClone } = appUser_state ?? {}
  const { profileData, loggedIn } = appUser ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserAccessType, appUserSessionKey, isAdminOrSuper, disclaimerResponses } = appUserAccess ?? {}

  _checkComponentTimes && start_fns.useTimeMeasurement('AppUserProvider', appUserChecked)

  const _forceDisclaimers = forceDisclaimers && !isAdminOrSuper && !disclaimerResponses && (useCookiesDisclaimer || useSmsDisclaimer || usePushDisclaimer || useEmailDisclaimer)

  const [_appUser_fns, setAppUserFns] = useState({})
  const [askForToken, setAskForToken] = useState()
  const [newLocation, setNewLocation] = useState()

  // navigator.geolocation.watchPosition(function (position) {
  //   const { coords } = position
  //   const _location = {
  //     latitude: coords.latitude,
  //     longitude: coords.longitude
  //   }
  //   setNewLocation(_location)
  // });

  useEffect(() => {
    const _appUser_fns = appUserAccessPermissions(appUsers, appUser, appUserCollection, eventAppUsers)
    setAppUserFns(_appUser_fns)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUsers, appUserCollection, accessClone, eventAppUsers])

  useEffect(() => {
    if (appUserAccessType < gEnums.accessLevels.appDataAdmin.value) {
      if (useProductionRedirect && hostName && hostName.indexOf('mobiledev') >= 0) {
        const _sharePathFull = sharePathFull.replace('mobiledev', 'mobile')
        window.location.href = _sharePathFull
      }
    }
    // eslint-disable-next-line
  }, [appUserAccessType, useProductionRedirect, hostName, pathViews.events]);

  useEffect(() => {
    if (allowCurrentUserTracking) {
      let storedUserId = currentHelpers.storageItem_get(_currentUserIdProp);
      if (!storedUserId) {
        appUser_handlers.handleSave_currentAppUserId()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {
    if (allowPresenceTracking) {
      appUser_handlers.handleUpdate_presenceStatus(presenceStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [presenceStatus])

  // get the profile for the appUser
  useEffect(() => {
    appUser_handlers.handleInit_currentAuthUser(currentAuthUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuthUser])

  // get the profile for the appUser
  useEffect(() => {
    appUser_handlers.handleSet_eventAppUsers(eventAppUsers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventAppUsers])

  useEffect(() => {
    if (appUserLocation && newLocation && allowLocationTracking && allowMovementTracking) {
      const _movementDistance = movementDistance * 100
      const allow = allowMovement(appUserLocation, newLocation, _movementDistance, true)
      if (allow) {
        appUser_handlers.handleAmmend_newLocation(newLocation)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLocation])

  useEffect(() => {
    if (loggedIn && !tokenAsked && _allowFcmPush) {
      setAskForToken(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  useEffect(() => {
    if (askForToken) {
      setAskForToken()
      checkTokens(profileData, appUserAccess, platform, pathViews, _allowFcmPush, ignoreTokenAlert).then(result => {
        appUser_handlers.handleAmmend_fcmTokens(result)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askForToken])

  useEffect(
    () => {
      const isLanding = view === 'events' || view === 'landing'
      if (redirectToAppUserPage && isLanding && appUserSessionKey && appUserCollection && !isAdminOrSuper) {
        if (appUserAccessType < gEnums.accessLevels.appDataAdmin.value) {
          const path = '/clients/' + pathViews.clients + '/events/' + pathViews.events + '/' + appUserCollection + '/' + appUserSessionKey
          navigate(path)
        }
      }
    },
    // eslint-disable-next-line
    [view, redirectToAppUserPage, appUserSessionKey, appUserCollection]
  )

  const provider = () => {
    if (loggedIn && _forceDisclaimers && _useDisclaimers) {
      return <Disclaimers appUser_handlers={appUser_handlers} />
    } else {
      return <AppUserContext.Provider value={{
        appUser_state,
        appUser_handlers,
        appUser_fns: _appUser_fns,
      }} >
        {props.children}
      </AppUserContext.Provider >
    }
  }

  return appUserChecked ? provider() : <div></div>
}

export default AppUserProvider

/**
 * 
 * @returns A boolean indicating whether the appUser has allowed notfications
 */
const requestPermissionGranted = async () => {
  if (Notification.permission === 'granted') {
    return true
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        return true
      } else {
        return false;
      }
    });
  }
}

const checkTokens = async (profileData, appUserAccess, platform, pathViews, allowFcmPush, ignoreTokenAlert) => {

  const { name: platform_name } = platform ?? {}
  const { fcmTokens } = appUserAccess ?? {}
  const _currentTokens = { ...fcmTokens }
  const _currentToken = fcmTokens ? fcmTokens[platform_name] : null
  const { tokenKey: currentTokenKey } = _currentToken ?? {}

  const appKeys = allAppKeys()
  const { publicVapidKey } = appKeys ?? {}

  const notificationRequestGranted = await requestPermissionGranted(publicVapidKey);

  if (notificationRequestGranted) {
    try {

      try {

        let allowMessaging = allowAppStorageAndFunctions()

        if (allowMessaging) {
          const messaging = getMessaging()
          const newToken = await getToken(messaging, { vapidKey: publicVapidKey })

          let updateFcm;

          if (!_currentToken) {
            // token does not exist. Add
            _currentTokens[platform_name] = {
              tokenKey: newToken,
              timestamp: serverTimestamp(),
            }
            updateFcm = true
          } else {
            // token is different that curren. Edit
            if (currentTokenKey !== newToken) {
              _currentTokens[platform_name] = {
                tokenKey: newToken,
                timestamp: serverTimestamp(),
              }
              updateFcm = true
            }
          }

          if (updateFcm && allowFcmPush) {

            const cb = (res) => {
              return { fcmTokens: _currentTokens, notificationRequestGranted }
            }

            console.log('Adding token  ', newToken)

            updateAppUserFcmTokenToDatabase(_currentTokens, profileData, pathViews, cb)

          } else {
            return { fcmTokens: _currentTokens, notificationRequestGranted }
          }
        }


      } catch (error) {
        console.error(error)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return { fcmTokens: {}, notificationRequestGranted: null, messaging: null, token: null }
}