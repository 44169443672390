import _ from 'lodash';
import { copyObj } from '../../../../global/common_web/copy';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { fsfn_sheets } from '../../../../global/functions/fbSheetsFuntions';
import { openExternal } from '../../../../global/viewSettings/helpers/settingsLinks';
import { updateTheMatch } from '../../dbActions/updateMatch';
import { matchCalcs } from '../../helpers/match';
import { _resultsProp } from '../contexts/SportsSeasonContext';
import { ammendTeamName } from './SportsMatchReducer';
import { matchUpdateTypes } from './SportsSeasonReducer';
import { updateParentKeyData_sports } from './reducerHelpers/parentKeySportsData';
import { _sportCollections } from './SportsSidebarReducer';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';

const rts = {
  handleChange_gameScore: 'handleChange_gameScore',
  handleChange_score: 'handleChange_score',
  handleChange_scores: 'handleChange_scores',
  handleChange_set: 'handleChange_set',
  handleUpdate_matchPending: 'handleUpdate_matchPending',
  handleCreate_matchesFromList: 'handleCreate_matchesFromList',
  handleSelect_athletes: 'handleSelect_athletes',
  handleSet_editMatch: 'handleSet_editMatch',
  handleSet_latestSeason: 'handleSet_latestSeason',
  handleSet_matchDataUpdates: 'handleSet_matchDataUpdates',
  handleShow_editMatch: 'handleShow_editMatch',
  handleShowScore: 'handleShowScore',
  handleUpdate_matchResults: 'handleUpdate_matchResults',

  handleEditChange_score: 'handleEditChange_score',
  handleEditChange_set: 'handleEditChange_set',
  handleEditChange_setScores: 'handleEditChange_setScores',
  handleEditConfirm_match: 'handleEditConfirm_match',
  handleEditConfirm_set: 'handleEditConfirm_set',
  handleShow_matchPending: 'handleShow_matchPending',
  handleSet_matchPopupOpen: 'handleSet_matchPopupOpen',
  handleAmmend_resultsCurrent: 'handleAmmend_resultsCurrent',
  handleAmmend_editMatch: 'handleAmmend_editMatch',
}

export const sportsEditMatchReducer = (state, action) => {

  const {
    appUserAccess,
    gameScoringType,
    latestSeason,
    match_edit,
    match_true,
    pathViews,
    playoffMatches,
    sortedAthletes,
    sportsKey,
    editSettings,
    matchPendingUpdate,
    sportsSeason_handlers,
    rtSettings,
    realtimeMatch,
    pk_matches_seasonal,
    sportPermissions,
    database_fns
  } = state

  const { type, dispatch } = action
  const { allowEditMatchData, allowEditMatchUpdates } = editSettings ?? {}

  const _realtimeMatch = realtimeMatch ? { ...realtimeMatch } : null
  const { rtMatchData, allowRtMatchUpdates } = rtSettings ?? {}

  const { handleUpdate_sportsSeasonTs } = sportsSeason_handlers ?? {}

  const { handleEditChange_score, handleEditChange_set } = sportsEditMatchHandlers(dispatch)
  const _match_edit = match_edit ? { ...match_edit } : null
  let { results_edit } = match_edit ? match_edit : {}
  const { setNumber: setNumber_current } = results_edit ?? {}


  switch (type) {

    case rts.handleSet_editMatch:
      const me = { ...action.match_edit }
      return { ...state, match_edit: me, matchAllows: action.matchAllows }

    case rts.handleShow_matchPending:
      return { ...state, matchPendingUpdate: action.actionProps, matchPopupOpen: false }

    case rts.handleSet_matchPopupOpen:
      return { ...state, matchPopupOpen: !state.matchPopupOpen }

    case rts.handleAmmend_resultsCurrent:
      const cme = { ...state.match_edit }
      cme.results_edit = action.resultsCurrent
      return { ...state, match_edit: cme }

    case rts.handleAmmend_editMatch:
      const cm = { ...action.match_edit }
      matchCalcs.calcVballMatch(cm, gameScoringType)
      const results = cm ? cm[_resultsProp] : undefined;
      if (action.ammendResults || 1 === 1) {
        // cm.results
        cm.results_edit = results
      }
      return { ...state, match_edit: cm }

    case rts.handleSelect_athletes:
      let sa;
      if (action.currentAthletes) {
        const ath = {}
        Object.keys(action.currentAthletes).forEach(key => {
          ath[key] = action.currentAthletes[key]
          ath[key].name = ath[key].firstName + ' ' + ath[key].lastName
        })
        sa = _.sortBy(ath, 'position')
      }

      return { ...state, currentAthletes: action.currentAthletes, sortedAthletes: sa }

    case rts.handleUpdate_matchResults:
      const updateProps = { match_edit, sortedAthletes, results_edit, playoffMatches }
      const matchProps = {
        actionProps: updateProps,
        appUserInfo: appUserAccess,
        callback: action.callback,
        latestSeason,
        matchUpdateType: matchUpdateTypes.updateScore,
        pathViews,
        sportsKey,
        sportPermissions,
      }
      console.log('matchProps', matchProps)
      action.callback(matchProps)
      // updateTheMatch(matchProps)
      return { ...state }

    case rts.handleResponse_matchResults:
      return { ...state, matchUpdateType: null }

    case rts.handleChange_score:
      _match_edit.results_edit = results_edit
      if (!results_edit.scores) { results_edit.scores = {} }
      results_edit.scores[action.setNumber] = action.value
      matchCalcs.calcVballMatch(_match_edit, gameScoringType)
      return { ...state, match_edit: _match_edit }

    case rts.handleChange_scores:
      const _setNumber = action.setNumber ? action.setNumber : setNumber_current
      if (!results_edit.scores) { results_edit.scores = {} }
      if (!results_edit.scores[_setNumber]) {
        results_edit.scores[_setNumber] = {
          away: 0,
          home: 0
        }
      }
      results_edit.scores[_setNumber][action.team] = action.value
      _match_edit.results_edit = results_edit
      matchCalcs.calcVballMatch(_match_edit, gameScoringType)
      return { ...state, match_edit: _match_edit }

    case rts.handleChange_set:
      results_edit.setNumber = action.value
      _match_edit.results_edit = results_edit
      matchCalcs.calcVballMatch(_match_edit, gameScoringType)
      return { ...state, match_edit: _match_edit }

    case rts.handleUpdate_matchPending:

      matchPendingUpdate.match_edit = match_edit

      const { matchUpdateType } = matchPendingUpdate
      const actionProps = matchPendingUpdate

      switch (matchUpdateType) {

        case matchUpdateTypes.notifyMatch:
          // const sendProps = appUserAccess ? { profileId: appUserAccess.profileId, fcmTokens: appUserAccess.fcmTokens } : {}
          // const topicData = getNotificationData(notificationTypes.notification, pathViews, { topic: viewKey }, { title: 'Match Results', body: scoreDisplay_full, clickPath: pathName })
          // fsfn_sendNotification(sendProps, topicData, pathViews)
          break;

        case matchUpdateTypes.addToHistory:
          const { startDate, levels } = match_edit
          const results = match_edit ? match_edit[_resultsProp] : undefined;
          const { winner, loser } = results ?? {}
          if (winner && loser) {
            const d = new Date(startDate)
            const year = d.getFullYear()
            const data = {
              [levels]: {
                [year]: {
                  winner: winner.name,
                  runnerUp: loser.name
                }
              }
            }
            updateParentKeyData_sports(database_fns, sportPermissions, '_history', pathViews, 'history', data, null, sportsKey)
          }
          break;

        case matchUpdateTypes.addConsolationMatch:
          console.log('actionProps', actionProps)
          break;

        case matchUpdateTypes.openDatabase:
          const { _matchCollectionKey, isPlayoff } = match_true
          const dbCollection = isPlayoff ? 'playoffMatches' : 'matches'
          openExternal.firebaseDb(pathViews, _sportCollections._matches_seasonal, pk_matches_seasonal, false, false, false, [dbCollection, _matchCollectionKey])
          break;

        case matchUpdateTypes.updateGsScore:
          fsfn_sheets.updateGoogleSheetRow(match_edit)
          break;

        // case matchUpdateTypes.deleteMatch:
        case matchUpdateTypes.cancelMatch:
        case matchUpdateTypes.confirmMatchDate:
        case matchUpdateTypes.deleteRealtimeMatch:
        case matchUpdateTypes.deleteScore:
        case matchUpdateTypes.postponeMatch:
        case matchUpdateTypes.resetByeMatch:
        case matchUpdateTypes.updateMatchDetails:
        case matchUpdateTypes.updateScore:
          actionProps.realtimeMatch = _realtimeMatch
          const matchPropsOther = {
            matchUpdateType: matchUpdateType,
            pathViews,
            latestSeason,
            sportsKey,
            actionProps: actionProps,
            appUserInfo: appUserAccess,
            callback: handleUpdate_sportsSeasonTs,
            allowRtMatchUpdates: rtMatchData,
            sportPermissions,
          }

          updateTheMatch(matchPropsOther)
          break;

        case matchUpdateTypes.updatePendingMatch:
          break;

        case matchUpdateTypes.updatePrePendingMatch:
          break;

        default:
          break;
      }

      return { ...state }

    case rts.handleSet_matchDataUpdates:
      const _mpdu = copyObj(action.matchDataUpdates)
      Object.keys(_mpdu).forEach(k => {
        delete _mpdu[k].
          _existingInfo

      })
      return { ...state, matchDataUpdates: _mpdu }



    case rts.handleCreate_matchesFromList:
      return { ...state, matchUpdates: createMatchesFromList(action.data) }

    case rts.handleShow_editMatch:
      if (action.match_edit) {
        return { ...state, showRealtimeScoringSelected: !state.showRealtimeScoringSelected, match_edit_selected: action.match_edit }
      } else {
        return { ...state, showRealtimeScoring: !state.showRealtimeScoring }
      }

    case rts.handleEditChange_score:
      if (_match_edit) {
        if (!_match_edit.scores) { _match_edit.scores = {} }
        if (!_match_edit.setNumber) { _match_edit.setNumber = 1 }
        if (!_match_edit.scores[_match_edit.setNumber]) {
          _match_edit.scores[_match_edit.setNumber] = createNewSet()
        }
        _match_edit.scores[_match_edit.setNumber][action.team] = action.value
      }
      // calculates the match and updates the realtime database
      calcMatchAndUpdateEditDb(allowEditMatchUpdates, { [_resultsProp]: _match_edit }, match_true)

      return { ...state, match_edit: _match_edit }

    case rts.handleChange_gameScore:
      _match_edit.results_edit = results_edit
      _match_edit.results_edit.score = action.score
      _match_edit.results_edit.scores = action.scores
      return { ...state, match_edit: _match_edit }

    case rts.handleEditChange_set:
      // changes the set and creates a blank new set
      if (_match_edit) {
        _match_edit.setNumber = action.setNumber
        if (!_match_edit.scores[_match_edit.setNumber]) {
          _match_edit.scores[_match_edit.setNumber] = createNewSet()
        }
      }
      return { ...state, match_edit: _match_edit }

    case rts.handleEditChange_setScores:
      const _rtm = action.match_edit
      return { ...state, match_edit: _rtm }

    case rts.handleEditConfirm_set:
      if (_match_edit) {
        let _setNumber = _match_edit.setNumber
        _setNumber++
        _match_edit.setNumber = _setNumber
        if (!_match_edit.scores[_setNumber]) {
          _match_edit.scores[_setNumber] = createNewSet()
        }
      }
      return { ...state, match_edit: _match_edit }

    case rts.handleEditConfirm_match:
      if (_match_edit) {
        console.log('_match_edit', _match_edit)
      }
      return { ...state }


    case rts.handleSet_latestSeason:
      return { ...state, latestSeason: action.latestSeason }

    case rts.handleShowScore:
      action.e && action.e.preventDefault()
      action.e && action.e.stopPropagation()
      return { ...state, showScore: !state.showScore }


    default:
      return { ...state }
  }
}

export const sportsEditMatchHandlers = (dispatch) => {
  return {
    handleAmmend_editMatch: (match_edit, ammendResults) => { dispatch({ type: rts.handleAmmend_editMatch, match_edit, ammendResults }) },
    handleAmmend_resultsCurrent: (resultsCurrent) => { dispatch({ type: rts.handleAmmend_resultsCurrent, resultsCurrent }) },
    handleChange_gameScore: (score, scores) => { dispatch({ type: rts.handleChange_gameScore, score, scores }) },
    handleChange_score: (value, setNumber) => { dispatch({ type: rts.handleChange_score, value, setNumber }) },
    handleChange_scores: (value, team, setNumber) => { dispatch({ type: rts.handleChange_scores, value, team, setNumber }) },
    handleChange_set: (value) => { dispatch({ type: rts.handleChange_set, value }) },
    handleUpdate_matchPending: () => { dispatch({ type: rts.handleUpdate_matchPending }) },
    handleEditChange_score: (value, team) => { dispatch({ type: rts.handleEditChange_score, dispatch, value, team }) },
    handleEditChange_set: (setNumber) => { dispatch({ type: rts.handleEditChange_set, dispatch, setNumber }) },
    handleEditChange_setScores: (match_edit) => { dispatch({ type: rts.handleEditChange_setScores, match_edit }) },
    handleEditConfirm_match: (value) => { dispatch({ type: rts.handleEditConfirm_match, dispatch, value }) },
    handleEditConfirm_set: (value) => { dispatch({ type: rts.handleEditConfirm_set, dispatch, value }) },
    handleSelect_athletes: (currentAthletes) => { dispatch({ type: rts.handleSelect_athletes, currentAthletes }) },
    handleSet_editMatch: (match_edit, matchAllows) => { dispatch({ type: rts.handleSet_editMatch, match_edit, matchAllows }) },
    handleSet_latestSeason: (latestSeason) => { dispatch({ type: rts.handleSet_latestSeason, latestSeason }) },
    handleSet_matchDataUpdates: (matchDataUpdates) => { dispatch({ type: rts.handleSet_matchDataUpdates, matchDataUpdates }) },
    handleSet_matchPopupOpen: () => { dispatch({ type: rts.handleSet_matchPopupOpen }) },
    handleShow_editMatch: (match_edit) => { dispatch({ type: rts.handleShow_editMatch, match_edit }) },
    handleShow_matchPending: (actionProps) => { dispatch({ type: rts.handleShow_matchPending, actionProps }) },
    handleShowScore: (e) => { dispatch({ type: rts.handleShowScore, e }) },
    handleUpdate_matchResults: (callback) => { dispatch({ type: rts.handleUpdate_matchResults, callback }) },

  }
}

export const sportsEditMatchInitialState = (init_state) => {
  return { ...init_state }
};


export const matchNeedsUpdated = (matchResults, results) => {
  if (matchResults && matchResults.score && results.score)
    if (matchResults.score.home !== results.score.home || matchResults.score.away !== results.score.away) {
      return true
    }
}

export const fixTeamNames = (matches) => {
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    const { home, away } = match ?? {}
    match.home = ammendTeamName(home)
    match.away = ammendTeamName(away)
  })
}

/**
 * 
 * @param {boolean} allowEditMatchUpdates 
 * @param {object} match 
 * @param {object} match_true 
 * @param {object} pathViews 
 * @param {object} appUserInfo 
 * @param {number} latestSeason 
 * @description calculates the match and updates the realtime database
 */
const calcMatchAndUpdateEditDb = (allowEditMatchUpdates, match, match_true) => {
  matchCalcs.calcVballMatch(match)
  // if (allowEditMatchUpdates) {
  //   updateCurrentRealtimeMatchScoreToEditDb(allowEditMatchUpdates, pathViews, current_props, appUserInfo, latestSeason)
  // } else {
  //   console.log('calcAndUpdate not permitted', allowEditMatchUpdates, current_props)
  // }
}

const createNewSet = () => {
  return {
    home: 0,
    away: 0
  }
}


/**
 * 
 * @param {object} action 
 * @param {function} handleEditChange_score 
 * @param {function} handleEditChange_set 
 * @description ammends the realtime score, then triggers either handleEditChange_score or handleEditChange_set
 */
const handleRealTimeChange = (action, handleEditChange_score, handleEditChange_set) => {

  const { match_edit, editType, add, currentSetNumber } = action
  const _realtimeMatch = { ...match_edit }
  const { setNumber } = _realtimeMatch
  let _setNumber = setNumber ? setNumber : 0
  if (currentSetNumber) { _setNumber = currentSetNumber }

  switch (editType) {
    case 'away':
    case 'home':
      const sn = setNumber
      if (!_realtimeMatch.scores) { _realtimeMatch.scores = {} }
      if (!_realtimeMatch.scores[sn]) {
        _realtimeMatch.scores[sn] = {
          away: 0,
          home: 0
        }
      }
      let teamScore = _realtimeMatch.scores[sn][editType]
      if (add) {
        teamScore++
      } else {
        teamScore--
      }
      if (teamScore < 0) { teamScore = 0 }
      handleEditChange_score(teamScore, editType)
      break;

    case 'set':
      if (add) {
        _setNumber++
      } else {
        _setNumber--
      }
      if (_setNumber < 1) { teamScore = 1 }
      if (_setNumber > 5) { teamScore = 5 }
      handleEditChange_set(_setNumber)
      break;

    default:
      break;
  }
}

const createMatchesFromList = (props) => {

  const { itemData: matchListData, teams, matches_season } = props

  const matches_updated = {
    section: {},
    nonSection: {},
  }

  const matches_created = {
    section: {},
    nonSection: {},
    nonUpdated: [],
    nonTeam: [],
    city: [],
    matchesToUpdate: null,
  }

  const matches_all = {}

  fixTeamNames(matchListData)

  // loop the matches_season in the matchData (list)
  if (matchListData) {

    Object.keys(matchListData).forEach(key => {

      // get the match from the list
      const match_item = matchListData[key]
      const { away, home, awayScore, homeScore, startDate } = match_item

      // if there is a home and awau team
      if (home && away) {

        const _homeName = ammendTeamName(home)
        const _awayName = ammendTeamName(away)

        const match_info = {
          home_existing: _.find(teams, { name: _homeName }),
          away_existing: _.find(teams, { name: _awayName }),
          matchSeasonFound: null,
          matchSeasonFoundKey: null,
        }

        match_info.matchSeasonFound = _.find(matches_season, function (m) {
          return (((m.home && m.away && match_info.home_existing && match_info.away_existing) && (m.home.id === id_home) && (m.away.id === match_info.away_existing.id)));
        })

        match_info.matchSeasonFoundKey = _.findKey(matches_season, function (m) {
          return (((m.home && m.away && match_info.home_existing && match_info.away_existing) && (m.home.id === id_home) && (m.away.id === match_info.away_existing.id)));
        })

        const _home_existing = _.find(teams, { name: _homeName })
        const _away_existing = _.find(teams, { name: _awayName })

        const { id: id_home, sections: section_home, levels: level_home } = _home_existing ? _home_existing : {}
        const { id: id_away, sections: section_away, levels: level_away } = _away_existing ? _away_existing : {}

        const isSectionMatch = (level_home === level_away) && (section_home === section_away)
        const isLevelMatch = (level_home === level_away)

        let _homeScore;
        let _awayScore;

        try {
          _homeScore = parseInt(homeScore)
          _awayScore = parseInt(awayScore)
          _homeScore = _.isNumber(_homeScore) && !isNaN(_homeScore) ? _homeScore : null
          _awayScore = _.isNumber(_awayScore) && !isNaN(_awayScore) ? _awayScore : null
        } catch (error) {

        }

        // find the match from the existing matches_season list
        const _matchSeasonFound = _.find(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const _matchSeasonFoundKey = _.findKey(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const isValidScore = _.isNumber(_homeScore) && !isNaN(_homeScore) && _.isNumber(_awayScore) && !isNaN(_awayScore) ? true : false

        // if match is found, update the score
        // if the match is not found, it is most likely a non-section match
        if (_matchSeasonFound && _matchSeasonFoundKey) {

          const results = {}

          let matchNeedsUpdating = false

          // check for a valid score

          if (isValidScore) {
            results.score = {
              home: parseInt(homeScore),
              away: parseInt(awayScore),
            }

            if (matchNeedsUpdated(_matchSeasonFound.results, results)) {
              matchNeedsUpdating = true
            }

            if (!_matchSeasonFound.results || matchNeedsUpdating) {
              // add the match to the updated object
              const _matchUpdate = { results: results }
              if (_matchSeasonFound.startDate !== startDate) {
                _matchUpdate.startDate = startDate
              }

              matches_all[_matchSeasonFoundKey] = _matchUpdate

            }
          } else {
            if ((_matchSeasonFound.startDate !== startDate)) {
              const _matchUpdate = { startDate: startDate }
              matches_all[_matchSeasonFoundKey] = _matchUpdate
              if (_matchSeasonFound.sections === 'Non') {
                matches_updated.nonSection[_matchSeasonFoundKey] = _matchUpdate
              } else {
                matches_updated.section[_matchSeasonFoundKey] = _matchUpdate
              }
            }
          }
        } else {
          // if the match is NOT found, create it.
          if (_home_existing && _away_existing) {
            switch (match_item.section) {
              case 'City League':
                // matches_all.push(match_item)
                matches_created.city.push(match_item)
                break;
              default:
                const _nsMatch = {
                  bestOf: true,
                  setCount: 5,
                  startDate: formatItem(formatTypes.fullDate, startDate),
                  startTime: '7:00 PM',
                  sections: isSectionMatch ? level_home : 'Non',
                  levels: isLevelMatch ? level_home : 'Non',
                  home: createTeam(_home_existing),
                  away: createTeam(_away_existing),
                  teams: [_home_existing._itemKey, _away_existing._itemKey]
                }
                _nsMatch.results = {}
                if (isValidScore) {
                  _nsMatch.results.score = {
                    home: parseInt(homeScore),
                    away: parseInt(awayScore),
                  }
                } else {
                  _nsMatch.results = {}
                }
                // create a new key for the match
                if (isSectionMatch) {
                  matches_created.section[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                } else {
                  matches_created.nonSection[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                }
              // }
            }
          } else {
            matches_created.nonTeam.push(match_item)
          }
        }
      }
    })

    // add the matches that have been found (matchesCreated.updated) to the matchesToUpdate array
    const _matchUpdates = {
      toCreate: {
        section: {},
        nonSection: {},
        all: {},
      },
      toUpdate: {
        section: {},
        nonSection: {},
        all: {},
      }
    }

    Object.keys(matches_updated.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_updated.section[key]
      const { results, startDate } = m ? m : {}
      if (results) { _matchUpdates.toUpdate.section[_mk + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.section[_mk + '.startDate'] = startDate };
      if (results) { _matchUpdates.toUpdate.all[_mk + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.all[_mk + '.startDate'] = startDate };
    })

    Object.keys(matches_updated.nonSection).forEach(key => {
      const _mk = 'matchesNonSection.' + key
      const _mk2 = 'matches.' + key
      const m = matches_updated.nonSection[key]
      const { results, startDate } = m ? m : {}
      if (results) { _matchUpdates.toUpdate.nonSection[_mk + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.nonSection[_mk + '.startDate'] = startDate };
      if (results) { _matchUpdates.toUpdate.all[_mk2 + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.all[_mk2 + '.startDate'] = startDate };
    })

    Object.keys(matches_created.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.section[key]
      const results = m ? m[_resultsProp] : undefined;
      m.results = results
      _matchUpdates.toCreate.section[key] = m;
      _matchUpdates.toCreate.all[_mk] = m;
    })

    Object.keys(matches_created.nonSection).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.nonSection[key]
      const results = m ? m[_resultsProp] : undefined;
      m.results = results
      _matchUpdates.toCreate.nonSection[key] = m;
      _matchUpdates.toCreate.all[_mk] = m;
    })

    return _matchUpdates

  }
}


const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}
