import { updateNotificationsDirect } from '../firestoreData/notifications/notificationsData';
import { callFunction, callFunctionDirect } from "./fbAll";

export const _allowNotificationSend = true

const notificationGenericTypes = {
  notificationGeneric: 'notificationGeneric',
}

export const notificationFunctionTypes = {
  addTopicGroups: 'addTopicGroups',
  addTopicToFcmTokens: 'addTopicToFcmTokens',
  getClientTopics: 'getClientTopics',
  removeTokensFromTopics: 'removeTokensFromTopics',
  sendCustomEmail: 'sendCustomEmail',
  sendCustomTokenEmail: 'sendCustomTokenEmail',
  sendGenericPush: 'sendGenericPush',
  sendLinkEmail: 'sendLinkEmail',
  sendNotificationByData: 'sendNotificationByData',
  sendScheduledNotifications_gcs_direct: 'sendScheduledNotifications_gcs_direct',
  sendSms: 'sendSms',
  subscribeToTopic: 'subscribeToTopic',
}

export const notificationMethodTypes = {
  firebaseApi: 'firebaseApi',
  fetch: 'fetch',
  correspond: 'correspond',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(notificationGenericTypes.notificationGeneric, callData)
  return data
}

export const fsfn_notifications = {
  addTopicGroups: (appProfileData, unsubscribe, logging) => fsfn_addTopicGroups(appProfileData, unsubscribe, logging),
  addTopicToFcmTokens: (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => fsfn_addTopicToFcmTokens(fcmTokens, topic, unsubscribe, pathViews, useEventKey),
  checkNotification_direct: (testOnly) => fsfn_checkNotification_direct(testOnly),
  getClientTopics: (data) => fsfn_getClientTopics(data),
  removeTokensFromTopics: (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => fsfn_removeTokensFromTopics(fcmTokens, topic, unsubscribe, pathViews, useEventKey),
  sendCustomTokenEmail: (notificationData) => fsfn_sendCustomTokenEmail(notificationData),
  sendEmail: (notificationData) => fsfn_sendEmail(notificationData),
  sendLinkEmail: (notificationData) => fsfn_sendLinkEmail(notificationData),
  sendNotificationByData: (notificationMethodType, notificationData, pathViews, tokens, token, topic, callback, logging, callbackSelf, itemKey, useEventKey) => fsfn_sendNotificationByData(notificationMethodType, notificationData, pathViews, tokens, token, topic, callback, logging, callbackSelf, itemKey, useEventKey),
  sendNotificationToSelf: (props) => sendNotificationToSelf(props),
  sendScheduledNotifications_gcs_direct: () => fsfn_sendScheduledNotifications_gcs_direct(),
  sendSms: (data, logging, callback) => fsfn_sendSms(data, logging, callback),
  subscribeToTopic: (token, topicKey, unsubscribe, itemKey) => fsfn_subscribeToTopic(token, topicKey, unsubscribe, itemKey),
}


/**
 * 
 * @param {object} data  
 */
const fsfn_subscribeToTopic = async (token, topicKey, unsubscribe, itemKey) => {
  try {
    const callData = {
      notificationFunctionType: notificationFunctionTypes.subscribeToTopic,
      token,
      topicKey,
      unsubscribe,
      showLogs: true,
      itemKey
    }
    console.log('response_data', callData)
    const response_data = await callFsFunction(callData) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error('sendNotification_hourly - error', error)
  }
}

/**
 * 
 * @param {object} data  
 */
const fsfn_checkNotification_direct = async (testOnly) => {
  try {
    const callData = { testOnly: testOnly, showLogs: true }
    await callFunctionDirect('checkNotification_direct', callData)
  } catch (error) {
    console.error('sendNotification_hourly - error', error)
  }
}

/**
 * 
 * @param {object} data  
 */
const fsfn_sendScheduledNotifications_gcs_direct = async () => {
  try {
    const data = {
      notificationFunctionType: notificationFunctionTypes.sendScheduledNotifications_gcs_direct,
      showLogs: true
    }
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error('fsfn_getClientTopics - error', error)
  }
}

/**
 * 
 * @param {object} data 
 * @deprecated
 */
const fsfn_getClientTopics = async (data) => {
  try {
    data.notificationFunctionType = notificationFunctionTypes.getClientTopics
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error('fsfn_getClientTopics - error', error)
  }
}

/** sends a notifcation to the appUsers tokens
 * {notificationData, appUserAccess, paps_state, callback: handleShowNormal} */
const sendNotificationToSelf = async (props) => {
  const { notificationData, appUserAccess, pathViews, callback, logging } = props
  return fsfn_sendNotificationByData(notificationData, pathViews, appUserAccess.fcmTokens, null, null, callback, logging, appUserAccess._itemKey)
}

/** sends a notifcation to the given tokens
 * {notificationData, appUserAccess, paps_state, callback: handleShowNormal} */
/**
 * 
 * @param {object} notificationData 
 * @param {string} pathViews 
 * @param {array} tokens 
 * @param {function} callback 
 * @param {object} _logging 
 * @param {function} callbackSelf 
 * @description sends a notifcation to the given tokens
 * @callback - sends a notifcation to the given tokens
 */
const fsfn_sendNotificationByData = async (notificationMethodType, notificationData, pathViews, tokens, token, topic, callback, _logging, callbackSelf, itemKey, useEventKey) => {
  try {

    // fhP4MyWV4SSan4z694zlDR:APA91bElGmamcbnmMetO4c1omWrYdqG9HcxAdLS_HYKuhcN__Ncv-PayS5T-rxs84_yQrRJRnWdR8nnTKTi3JrBNqtgcKuy6PmpZ4XcRuwZtlOaRrs7CuwTUQZs4VpBa1FIZwT9eOlVD
    // fhP4MyWV4SSan4z694zlDR:APA91bElGmamcbnmMetO4c1omWrYdqG9HcxAdLS_HYKuhcN__Ncv-PayS5T-rxs84_yQrRJRnWdR8nnTKTi3JrBNqtgcKuy6PmpZ4XcRuwZtlOaRrs7CuwTUQZs4VpBa1FIZwT9eOlVD

    const callData = {
      notificationFunctionType: notificationFunctionTypes.sendGenericPush,
      token: tokens ? tokens[0] : null,
      notificationType: 'data',
      notification: { ...notificationData },
      notificationMethodType,
      tokens,
      token,
      topic,
      itemKey,
      showLogs: true, //logging && logging.allowLogging && logging.logNotifications,
      pathViews,
      useEventKey,
      data: {
        ...notificationData,
        icon: 'https://firebasestorage.googleapis.com/v0/b/me-mobile-4410b.appspot.com/o/FCMImages%2FDark%20Bell.png?alt=media&token=10b5d859-2f11-4d42-8d4d-d7d80b185ec4'
      }
    }
    callData.data.title += '!'
    console.log('callData', callData)
    if (_allowNotificationSend) {
      const response_data = await callFsFunction(callData) // OK
      callback && updateNotificationsDirect(pathViews, notificationData, response_data, callback)
      callbackSelf && callbackSelf(response_data)
      return response_data
    } else {
      console.log('NS - fsfn_sendNotificationByData', callData)
    }
  } catch (error) {
    console.error('#fsfn_sendNotificationByData - error', error)
  }
}

/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
const fsfn_sendEmail = async (notificationData) => {
  try {
    notificationData.notificationFunctionType = notificationFunctionTypes.sendCustomEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
const fsfn_sendCustomTokenEmail = async (notificationData) => {
  try {
    console.log('fsfn_sendCustomTokenEmail', notificationData)
    notificationData.notificationFunctionType = notificationFunctionTypes.sendCustomTokenEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}
/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
const fsfn_sendLinkEmail = async (notificationData) => {
  try {
    notificationData.notificationFunctionType = notificationFunctionTypes.sendLinkEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} data 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
const fsfn_sendSms = async (data, logging, callback) => {
  try {
    const { to, text } = data
    const _showLogs = logging && logging.allowLogging && logging.logSms
    const sendData = { notificationFunctionType: notificationFunctionTypes.sendSms, to: to, text, showLogs: true }
    const response_data = await callFsFunction(sendData) // OK  
    console.log('response_data', response_data)
    callback && callback(response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} appProfileData 
 * @param {boolean} unsubscribe 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
const fsfn_addTopicGroups = async (appProfileData, unsubscribe, logging) => {
  try {
    const data = { notificationFunctionType: notificationFunctionTypes.addTopicGroups, appProfileData, unsubscribe, showLogs: logging && logging.allowLogging && logging.logNotificationTopics }
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Add the fcmTokens in to the topic
 * @param {array} fcmTokens 
 * @param {string} topic 
 * @param {boolean} unsubscribe 
 * @returns response_data {success, result, error}
 */
const fsfn_addTopicToFcmTokens = async (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => {
  try {
    const data = { notificationFunctionType: notificationFunctionTypes.addTopicToFcmTokens, fcmTokens, topic, unsubscribe, pathViews, useEventKey, showLogs: true }
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

const fsfn_removeTokensFromTopics = async (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => {
  try {
    const data = {
      notificationFunctionType: notificationFunctionTypes.removeTokensFromTopics,
      fcmTokens,
      topic,
      unsubscribe,
      pathViews,
      useEventKey,
      showLogs: true
    }
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/** Sends a notification to a topic */
// export const sendNotification_topic = async (notificationData, appUserAccess, paps_state) => {
//   const { rootPaths } = paps_state ?? {}
//   const { events } = rootPaths ? rootPaths : {}
//   let topicPath = events ? events.replace(/\//g, '_') : null
//   const { topicOriginGroup, topicOriginGroupKey } = notificationData
//   topicPath += topicOriginGroup + '_' + topicOriginGroupKey
//   const sendProps = appUserAccess ? { profileId: appUserAccess.profileId } : {}
//   const _notificationData = getNotificationData(notificationTypes.notification, {}, { topic: topicPath }, { title, body })
//   const result = await fsfn_sendNotification(sendProps, _notificationData, pathViews)
//   return result
// }


// Payment Initiated
// Your payment has been submitted.
// Your confirmation number is 1156833167
// Account Paid:	0017971496
// Amount Due:
// $520.76
// Due Date:
// Sep 01, 2024
// Card Type:	VISA
// Card Info:	********7639 Visa Debit
// Name on Card:	PHILIP A OKEEFFE
// Billing Address:	2040 HAMPSTEAD DR
// PITTSBURGH PA 15235
// Total Amount Paid:	$520.76
// Pay Date:	Sep 1, 2024