
import { grts, responseReducers } from './reducerHelpers/dispatchProps';
import { logEvent, setAnalyticsCollectionEnabled } from "firebase/analytics";

const allowLogging = {
  event: false,
  path: true
}

export const appViewPaths = ['home', 'dashboard', 'profile'];

const rts = {
  handleLogging_event: 'handleLogging_event',
  handleLogging_path: 'handleLogging_path',
  handleInit_analytics: 'handleInit_analytics',
  ...grts
}

export const analyticsReducer = (state, action) => {

  const { analytics, allowGoogleAnalytics } = state
  const { type } = action

  switch (type) {

    case rts.handleInit_analytics:
      const { analytics: _analytics, allowGoogleAnalytics: _allowGoogleAnalytics, isSuperAdmin } = action
      const _allow = _allowGoogleAnalytics && !isSuperAdmin ? true : false
      setAnalyticsCollectionEnabled(_analytics, _allow); // or true based on your logic 
      console.log('allow analytics', _allow)
      return { ...state, analytics: _analytics, allowGoogleAnalytics: _allow }

    case rts.handleLogging_event:
      if (allowGoogleAnalytics && allowLogging.event) {
        console.log('handleLogging_event', action)
        logEvent(analytics, action.eventName, action.params);
      }
      return { ...state }


    case rts.handleLogging_path:
      if (allowGoogleAnalytics && allowLogging.path) {
        console.log('handleLogging_path', action)
        logEvent(analytics, 'page_view', { path_name: action.pathName });
      }
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const analyticsInitialState = (initState) => {
  return { ...initState }
};

export const analyticsHandlers = (dispatch) => {
  return {
    handleLogging_event: (eventName, params) => { dispatch({ type: rts.handleLogging_event, dispatch, eventName, params }) },
    handleLogging_path: (pathName) => { dispatch({ type: rts.handleLogging_path, dispatch, pathName }) },
    handleInit_analytics: (analytics, allowGoogleAnalytics, isSuperAdmin) => { dispatch({ type: rts.handleInit_analytics, dispatch, analytics, allowGoogleAnalytics, isSuperAdmin }) },
  }
}