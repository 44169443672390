import _ from 'lodash';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { copyObj } from '../../../../global/common_web/copy';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { updateTheMatch } from '../../dbActions/updateMatch';
import { _resultsProp } from '../contexts/SportsSeasonContext';
import { matchUpdateTypes } from './SportsSeasonReducer';
import { _sportCollections } from './SportsSidebarReducer';

export const _matchAccessLevels = {
  team: 'team',
  sport: 'sport',
  school: 'school',
  admin: 'admin'
}

export const _useShowMatchOptions = true

const rts = {
  handleCreate_matchesFromList: 'handleCreate_matchesFromList',
  handleSet_latestSeason: 'handleSet_latestSeason',
  handleSet_matchAllowances: 'handleSet_matchAllowances',
  handleSet_matchDataUpdates: 'handleSet_matchDataUpdates',
  handleSet_rtMatch: 'handleSet_rtMatch',
  handleSet_realtimeMatchData: 'handleSet_realtimeMatchData',
  handleSet_trueMatch: 'handleSet_trueMatch',
  handleShow_matchLocation: 'handleShow_matchLocation',
  handleShow_matchScoreTable: 'handleShow_matchScoreTable',
  handleShow_matchTime: 'handleShow_matchTime',
  handleShow_rtScoring: 'handleShow_rtScoring',
  handleShow_score: 'handleShow_score',
  handleShow_scoreEdit: 'handleShow_scoreEdit',
  handleShow_matchOptions: 'handleShow_matchOptions',
  handleUpdate_rtMatch: 'handleUpdate_rtMatch',
}

export const sportsMatchReducer = (state, action) => {

  const {
    appUserAccess,
    latestSeason,
    match_toEdit,
    match_true,
    pathViews,
    playoffMatches,
    sortedAthletes,
    sportsKey,
    sportsSeason_handlers,
    rtSettings,
    sportPermissions,
  } = state

  const { type } = action

  const { allowRtMatchUpdates } = rtSettings ?? {}

  const { handleUpdate_sportsSeasonTs } = sportsSeason_handlers ?? {}

  let { results_edit } = match_toEdit ?? {}

  if (!results_edit) { results_edit = {} }
  switch (type) {

    case rts.handleShow_scoreEdit:
      return { ...state, match_toEdit: copyObj(action.match), showMatchScoreTable: false, editFromRt: action.editFromRt }

    case rts.handleShow_matchOptions:
      if (_useShowMatchOptions) {
        return { ...state, showMatchOptions: !state.showMatchOptions, matchItemKey: action.match._itemKey }
      } else {
        return { ...state, match_toEdit: copyObj(action.match), showMatchScoreTable: false, editFromRt: action.editFromRt }
      }

    case rts.handleSet_latestSeason:
      return { ...state, latestSeason: action.latestSeason }

    case rts.handleUpdate_rtMatch:
      const updatePropsRt = { match_toEdit: match_true, sortedAthletes, playoffMatches, match_rt: action.match_rt }
      const matchPropsRt = {
        actionProps: updatePropsRt,
        allowRtMatchUpdates,
        appUserInfo: appUserAccess,
        callback: handleUpdate_sportsSeasonTs,
        latestSeason,
        matchUpdateType: matchUpdateTypes.updateScore,
        pathViews,
        sportsKey,
        sportPermissions
      }
      updateTheMatch(matchPropsRt)
      return { ...state }

    case rts.handleSet_rtMatch:
      return { ...state, match_rt: action.match_rt }

    case rts.handleSet_realtimeMatchData:
      return { ...state, realtimeMatchData: action.realtimeMatchData }

    case rts.handleSet_matchAllowances:
      const { matchAllows } = action ?? {}
      const { full, scores, edit, matchAccessLevel } = matchAllows ?? {}
      const _matchAllows = {
        full: full,
        edit: full || edit,
        scores: full || edit || scores,
        matchAccessLevel
      }
      return { ...state, matchAllows: _matchAllows }

    case rts.handleSet_matchDataUpdates:
      const _mpdu = copyObj(action.matchDataUpdates)
      Object.keys(_mpdu).forEach(k => {
        delete _mpdu[k].
          _existingInfo

      })
      return { ...state, matchDataUpdates: _mpdu }

    case rts.handleSet_trueMatch:
      return { ...state, match_true: action.match_true }

    case rts.handleShow_matchLocation:
      return { ...state, showMatchLocation: action.match }

    case rts.handleShow_matchTime:
      return { ...state, showMatchTime: state.showMatchTime ? null : action.match }

    case rts.handleShow_score:
      action.e && action.e.preventDefault()
      action.e && action.e.stopPropagation()
      return { ...state, showScore: !state.showScore, match_toEdit: null }

    case rts.handleShow_matchScoreTable:
      return { ...state, scoresOn: false, showMatchScoreTable: !state.showMatchScoreTable }

    case rts.handleShow_rtScoring:
      return { ...state, showRtScoring: !state.showRtScoring }


    case rts.handleCreate_matchesFromList:
      return { ...state, matchUpdates: createMatchesFromList(action.data) }

    default:
      return { ...state }
  }
}

export const sportsMatchHandlers = (dispatch) => {
  return {
    handleCreate_matchesFromList: (data) => { dispatch({ type: rts.handleCreate_matchesFromList, data }) },
    handleSet_latestSeason: (latestSeason) => { dispatch({ type: rts.handleSet_latestSeason, latestSeason }) },
    handleSet_matchAllowances: (matchAllows) => { dispatch({ type: rts.handleSet_matchAllowances, matchAllows }) },
    handleSet_matchDataUpdates: (matchDataUpdates) => { dispatch({ type: rts.handleSet_matchDataUpdates, matchDataUpdates }) },
    handleSet_rtMatch: (match_rt) => { dispatch({ type: rts.handleSet_rtMatch, match_rt }) },
    handleSet_realtimeMatchData: (realtimeMatchData) => { dispatch({ type: rts.handleSet_realtimeMatchData, realtimeMatchData }) },
    handleSet_trueMatch: (match_true) => { dispatch({ type: rts.handleSet_trueMatch, dispatch, match_true }) },
    handleShow_matchLocation: (match) => { dispatch({ type: rts.handleShow_matchLocation, dispatch, match }) },
    handleShow_matchScoreTable: (match) => { dispatch({ type: rts.handleShow_matchScoreTable, match }) },
    handleShow_matchTime: (match) => { dispatch({ type: rts.handleShow_matchTime, dispatch, match }) },
    handleShow_rtScoring: () => { dispatch({ type: rts.handleShow_rtScoring }) },
    handleShow_score: (e) => { dispatch({ type: rts.handleShow_score, e }) },
    handleShow_scoreEdit: (match, editFromRt) => { dispatch({ type: rts.handleShow_scoreEdit, match, editFromRt }) },
    handleShow_matchOptions: (match) => { dispatch({ type: rts.handleShow_matchOptions, match }) },
    handleUpdate_rtMatch: (match_rt) => { dispatch({ type: rts.handleUpdate_rtMatch, match_rt }) },
  }
}

export const sportsMatchInitialState = (init_state) => {
  return { ...init_state, currentAthletes: {}, matchAllows: { scores: false, edit: false, full: false } }
};

export const ammendTeamName = (name) => {
  const _name = name ? name.trim() : name
  switch (_name) {
    case 'Geibel Catholic':
      return 'Geibel'
    case 'Canon-McMillian':
    case 'Canon-McMillan':
      return 'Canon McMillan'
    case 'Apollo-Ridge':
      return 'Apollo Ridge'
    case 'Beaver Area':
      return 'Beaver'
    case 'Mount Pleasant':
      return "Mt. Pleasant"
    case 'Waynesburg Central':
      return "Waynesburg"
    case 'South Side Beaver':
      return 'South Side Area'
    case 'Neighborhood A.':
      return 'Neighborhood Academy'
    case 'Shaler':
      return 'Shaler Area'
    case 'Pine-Richland':
      return 'Pine Richland'
    case 'Beaver C.C.':
      return 'Beaver County Christian'
    case 'Hillcrest Academy':
      return 'Hillcrest Christian Academy'
    case 'Greensburg C.C.':
      return 'Greensburg Central Catholic'
    case 'O.L. Sacred Heart':
    case 'Our Lady of Sacred Heart':
      return 'O.L.S.H.'
    case 'Sto-Rox':
      return _name
    case 'Union':
      return 'Union Area'
    case 'Jefferson-Morgan':
      return 'Jefferson Morgan'
    default:
      return _name ? _name.replace('-', ' ') : _name
  }
}

export const matchNeedsUpdated = (matchResults, results) => {
  if (matchResults && matchResults.score && results.score)
    if (matchResults.score.home !== results.score.home || matchResults.score.away !== results.score.away) {
      return true
    }
}

export const fixTeamNames = (matches) => {
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    const { home, away } = match ?? {}
    match.home = ammendTeamName(home)
    match.away = ammendTeamName(away)
  })
}

const createMatchesFromList = (props) => {

  const { itemData: matchListData, teams, matches_season } = props

  const matches_updated = {
    section: {},
    nonSection: {},
  }

  const matches_created = {
    section: {},
    nonSection: {},
    nonUpdated: [],
    nonTeam: [],
    city: [],
    matchesToUpdate: null,
  }

  const matches_all = {}

  fixTeamNames(matchListData)

  // loop the matches_season in the matchData (list)
  if (matchListData) {

    Object.keys(matchListData).forEach(key => {

      // get the match from the list
      const match_item = matchListData[key]
      const { away, home, awayScore, homeScore, startDate } = match_item

      // if there is a home and awau team
      if (home && away) {

        const _homeName = ammendTeamName(home)
        const _awayName = ammendTeamName(away)

        const match_info = {
          home_existing: _.find(teams, { name: _homeName }),
          away_existing: _.find(teams, { name: _awayName }),
          matchSeasonFound: null,
          matchSeasonFoundKey: null,
        }

        match_info.matchSeasonFound = _.find(matches_season, function (m) {
          return (((m.home && m.away && match_info.home_existing && match_info.away_existing) && (m.home.id === id_home) && (m.away.id === match_info.away_existing.id)));
        })

        match_info.matchSeasonFoundKey = _.findKey(matches_season, function (m) {
          return (((m.home && m.away && match_info.home_existing && match_info.away_existing) && (m.home.id === id_home) && (m.away.id === match_info.away_existing.id)));
        })

        const _home_existing = _.find(teams, { name: _homeName })
        const _away_existing = _.find(teams, { name: _awayName })

        const { id: id_home, sections: section_home, levels: level_home } = _home_existing ? _home_existing : {}
        const { id: id_away, sections: section_away, levels: level_away } = _away_existing ? _away_existing : {}

        const isSectionMatch = (level_home === level_away) && (section_home === section_away)
        const isLevelMatch = (level_home === level_away)

        let _homeScore;
        let _awayScore;

        try {
          _homeScore = parseInt(homeScore)
          _awayScore = parseInt(awayScore)
          _homeScore = _.isNumber(_homeScore) && !isNaN(_homeScore) ? _homeScore : null
          _awayScore = _.isNumber(_awayScore) && !isNaN(_awayScore) ? _awayScore : null
        } catch (error) {

        }

        // find the match from the existing matches_season list
        const _matchSeasonFound = _.find(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const _matchSeasonFoundKey = _.findKey(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const isValidScore = _.isNumber(_homeScore) && !isNaN(_homeScore) && _.isNumber(_awayScore) && !isNaN(_awayScore) ? true : false

        // if match is found, update the score
        // if the match is not found, it is most likely a non-section match
        if (_matchSeasonFound && _matchSeasonFoundKey) {

          const results = {}

          let matchNeedsUpdating = false

          // check for a valid score

          if (isValidScore) {
            results.score = {
              home: parseInt(homeScore),
              away: parseInt(awayScore),
            }

            if (matchNeedsUpdated(_matchSeasonFound.results, results)) {
              matchNeedsUpdating = true
            }

            if (!_matchSeasonFound.results || matchNeedsUpdating) {
              // add the match to the updated object
              const _matchUpdate = { results: results }
              if (_matchSeasonFound.startDate !== startDate) {
                _matchUpdate.startDate = startDate
              }

              matches_all[_matchSeasonFoundKey] = _matchUpdate

            }
          } else {
            if ((_matchSeasonFound.startDate !== startDate)) {
              const _matchUpdate = { startDate: startDate }
              matches_all[_matchSeasonFoundKey] = _matchUpdate
              if (_matchSeasonFound.sections === 'Non') {
                matches_updated.nonSection[_matchSeasonFoundKey] = _matchUpdate
              } else {
                matches_updated.section[_matchSeasonFoundKey] = _matchUpdate
              }
            }
          }
        } else {
          // if the match is NOT found, create it.
          if (_home_existing && _away_existing) {
            switch (match_item.section) {
              case 'City League':
                // matches_all.push(match_item)
                matches_created.city.push(match_item)
                break;
              default:
                const _nsMatch = {
                  bestOf: true,
                  setCount: 5,
                  startDate: formatItem(formatTypes.fullDate, startDate),
                  startTime: '7:00 PM',
                  sections: isSectionMatch ? level_home : 'Non',
                  levels: isLevelMatch ? level_home : 'Non',
                  home: createTeam(_home_existing),
                  away: createTeam(_away_existing),
                  teams: [_home_existing._itemKey, _away_existing._itemKey]
                }
                _nsMatch.results = {}
                if (isValidScore) {
                  _nsMatch.results.score = {
                    home: parseInt(homeScore),
                    away: parseInt(awayScore),
                  }
                } else {
                  _nsMatch.results = {}
                }
                // create a new key for the match
                if (isSectionMatch) {
                  matches_created.section[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                } else {
                  matches_created.nonSection[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                }
              // }
            }
          } else {
            matches_created.nonTeam.push(match_item)
          }
        }
      }
    })

    // add the matches that have been found (matchesCreated.updated) to the matchesToUpdate array
    const _matchUpdates = {
      toCreate: {
        section: {},
        nonSection: {},
        all: {},
      },
      toUpdate: {
        section: {},
        nonSection: {},
        all: {},
      }
    }

    Object.keys(matches_updated.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_updated.section[key]
      const { results, startDate } = m ? m : {}
      if (results) { _matchUpdates.toUpdate.section[_mk + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.section[_mk + '.startDate'] = startDate };
      if (results) { _matchUpdates.toUpdate.all[_mk + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.all[_mk + '.startDate'] = startDate };
    })

    Object.keys(matches_updated.nonSection).forEach(key => {
      const _mk = 'matchesNonSection.' + key
      const _mk2 = 'matches.' + key
      const m = matches_updated.nonSection[key]
      const { results, startDate } = m ? m : {}
      if (results) { _matchUpdates.toUpdate.nonSection[_mk + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.nonSection[_mk + '.startDate'] = startDate };
      if (results) { _matchUpdates.toUpdate.all[_mk2 + '.results'] = results };
      if (startDate) { _matchUpdates.toUpdate.all[_mk2 + '.startDate'] = startDate };
    })

    Object.keys(matches_created.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.section[key]
      const results = m ? m[_resultsProp] : undefined;
      m.results = results
      _matchUpdates.toCreate.section[key] = m;
      _matchUpdates.toCreate.all[_mk] = m;
    })

    Object.keys(matches_created.nonSection).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.nonSection[key]
      const results = m ? m[_resultsProp] : undefined;
      m.results = results
      _matchUpdates.toCreate.nonSection[key] = m;
      _matchUpdates.toCreate.all[_mk] = m;
    })

    return _matchUpdates

  }
}

const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}
