import React, { createContext, useContext, useReducer } from 'react';
import '../../../cssAlt/AppM.css';
import { UiItemContext } from '../../../global/cnr/contexts/UiItemContext';
import { uniqueKey } from '../../../global/common/keys';
import { copyObj } from '../../../global/common_web/copy';
import { gEnums } from '../../../global/enums/globalEnums';
import { SportsDistrictContext } from '../cnr/contexts/SportsDistrictContext';
import { SportsSeasonContext } from '../cnr/contexts/SportsSeasonContext';
import { createSportsHandlers, createSportsInitialState, createSportsReducer } from '../cnr/reducers/CreateSportsReducer';
import { TryoutList } from '../elements/athletes/TryoutList';
import BackupSeasons from './BackupSeasons';
import CreateRoster from './subs/CreateRoster';
import EditSeasonal from './subs/EditSeasonal';
import SportsDataPaste from './subs/SportsDataPaste';
import ValidateSports from './subs/ValidateSports';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';

const CreateActiveSports = React.lazy(() => import('./CreateActiveSports'));
const CreateAllStarTeams = React.lazy(() => import('./CreateAllStarTeams'));
const CreatePlayoff = React.lazy(() => import('./CreatePlayoff'));
const CreateRankings = React.lazy(() => import('./CreateRankings'));
const CreateSeason = React.lazy(() => import('./CreateSeason'));
const CreateSportsTeams = React.lazy(() => import('./CreateSportsTeams'));
const CreateTournament = React.lazy(() => import('./CreateTournament'));

const _teamItemsToRemove = ['id', 'position', 'record', '_itemKey']

export const CreateSportsContext = createContext();

const CreateSports = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { subActionType, viewItem, handleActionPreview, commitSub, handleCancel, fromDashboard, fromApp } = props

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { database_fns } = fns ?? {}
  const { pathViews } = paps_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { schools: schools_district } = sportsDistrict_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { teams_info, sportsKey, latestSeason, parentDataKeys, sportPermissions } = sportsSeason_state ?? {}
  const { teams, teams_fromMatches } = teams_info ?? {}

  const _teams = teams ? copyObj(teams) : {}

  // ammnend the teams
  if (_teams) {
    Object.keys(_teams).forEach(tk => {
      _teamItemsToRemove.forEach(ti => {
        // delete _teams[tk][ti]
      })
    })
  }

  const _latestSeason = latestSeason ? latestSeason : props.latestSeason

  const init_state = {
    parentDataKeys,
    pathViews,
    subActionType,
    selectedAthletes: [],
    teams: _teams,
    teams_info,
    item_state,
    latestSeason: _latestSeason,
    teams_fromMatches,
    sportsKey,
    sportPermissions,
    database_fns,
  }
  const [createSports_state, createSports_dispatch] = useReducer(createSportsReducer, init_state, createSportsInitialState)
  const createSports_handlers = createSportsHandlers(createSports_dispatch, createSports_state)

  const content = () => {

    switch (subActionType) {

      // case _asat.backupSeasons:
      //   return <BackupSeasons viewItem={viewItem} handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.editSeason:
      case _asat.editSeasonDetails:
      case _asat.sportsSettings:
        return <EditSeasonal key={uniqueKey('cs', 'season-edit')} handleCancel={handleCancel} subActionType={subActionType} fromDashboard={fromDashboard} />

      case _asat.nominatePlayers:
      case _asat.createAllStarTeams:
        return <CreateAllStarTeams viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} subActionType={subActionType} fromDashboard={fromDashboard} />

      case _asat.createHistory:
        return <CreateAllStarTeams viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} fromDashboard={fromDashboard} />

      case _asat.createSeason:
        return <CreateSeason viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} alignmentOnly={true} handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.createSportsTeams:
        return <CreateSportsTeams viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} alignmentOnly={true} fromDashboard={fromDashboard} />

      case _asat.createSportsHandlers:
        return <CreateSeason viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} alignmentOnly={true} handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.createPlayoff:
        return <CreatePlayoff viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} handleCancel={handleCancel} fromDashboard={fromDashboard} fromApp={fromApp} />

      case _asat.createRoster:
        return <CreateRoster subActionType={subActionType} />

      case _asat.editTryoutRoster:
        return <TryoutList handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.createStaff:
        return <SportsDataPaste subActionType={subActionType} fromDashboard={fromDashboard} />

      case _asat.createRankings:
      case _asat.submitRankings:
        return <CreateRankings handleActionPreview={handleActionPreview} commitSub={commitSub} handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.createTournament:
        return <CreateTournament viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} fromDashboard={fromDashboard} />

      case _asat.updateDistricts:
      case _asat.updateOrganizations:
        return <CreateActiveSports subActionType={subActionType} handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.updateOrganizationSports:
      case _asat.updateLeagueSports:
      case _asat.updateSchoolSports:
        return <CreateActiveSports subActionType={subActionType} handleCancel={handleCancel} fromDashboard={fromDashboard} />

      case _asat.sportsDashboard:
        return <ValidateSports fromDashboard={fromDashboard} />

      default:
        return <div>{'subActionType is not recognized'}</div>
    }
  }

  const wrapper = () => <CreateSportsContext.Provider value={{ createSports_state, createSports_handlers, viewItem, handleActionPreview, item_handlers }}>
    {content()}
  </CreateSportsContext.Provider>

  return wrapper()
}

export default CreateSports