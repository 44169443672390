import _ from 'lodash';
import { createFsDocKey, fs_db } from '../../../../../global/firestoreData/appData/fsAppData';
import { getParentKeyData_sports } from './parentKeySportsData';
import { convertHelpers } from '../../../../../global/common/convert';
import { isOdd } from '../../../../../global/common/filtering';
import { sectionCountTypes } from '../CreateSportsReducer';
import { seasonalUpdateTypes } from './seasonalSportsData';
import { createScheduledMatches } from '../../../dbActions/updateSeasonSchedule';
import { copyObj } from '../../../../../global/common_web/copy';
import { dataFix } from '../../../../../global/common/dataFix';
import { reorderDateStrings } from '../../../../../global/common/sorting';

/**
 * Returns data from `_global.levels`, `_global.sections`, `schools` and `teams` 
 * @param {object} pathViews 
 * @param {function} callback 
 */
export const getData_createSeasonInit = (database_fns, pathViews, sportsKey, callback) => {

  const datas = {}

  const dataItems = {
    levels: { isGlobal: true, dataParents: null }, // _globals.levels
    sections: { isGlobal: true, dataParents: null },// _globals.sections
    schools: { isGlobal: false, isSub: true, dataParents: ['organizations', 'districts'] }, // _schools with parentKeys (organizations, districts)
    teams: { isGlobal: false, dataParents: ['organizations', 'districts', 'sports'] }, // teams with parentKeys (organizations, districts, sports)
  }

  const cb_datas = () => {
    let valid = true
    Object.keys(dataItems).forEach(key => {
      if (!datas[key]) {
        valid = false
      }
    })

    if (valid) {
      callback(datas)
    }
  }

  const cb = (data, opts) => {
    const { cbProps, collectionName } = opts ?? {}
    const { collectionName: collectionName_cbProps } = cbProps ?? {}
    const _collectionName = collectionName ? collectionName : collectionName_cbProps
    datas[_collectionName] = data ?? {}
    cb_datas()
  }

  const cb_pk = (data, opts) => {
    const { cbProps, collectionName } = opts ?? {}
    const { collectionName: collectionName_cbProps } = cbProps ?? {}
    const _collectionName = collectionName ? collectionName : collectionName_cbProps
    datas[_collectionName] = data[_collectionName] ?? {}
    cb_datas()
  }

  Object.keys(dataItems).forEach(key => {
    const _dataItem = dataItems[key]
    const { isGlobal, isSub, dataParents } = _dataItem ?? {}
    if (isGlobal) {
      // levels, sections
      fs_db.get_eventData_global(pathViews, key, true, cb, false, { collectionName: key })
    } else {
      if (isSub) {
        // schools 
        const pkdProps = {
          pathViews,
          dataItem: { collectionName: key },
          dataParents,
          callback: cb_pk,
          cbProps: { collectionName: key },
          database_fns,
        }
        getParentKeyData_sports(pkdProps)
      } else {
        // teams
        fs_db.get_data_any({ pathViews, collectionName: key, callback: cb, opts: { sportsKey, dataParents, cbProps: { collectionName: key } } })
      }
    }
  })

}

export const getSelectedTeamsFromSchools = (schools, teams) => {
  const selected = {}
  const nonSelected = {}
  const selectedIndexes = []
  if (schools && teams) {
    Object.keys(schools).forEach(sk => {
      const teamKey = _.findKey(teams, function (t) { return t.parentKeys && t.parentKeys['schools'] === sk; })
      if (teamKey) {
        selected[teamKey] = teams[teamKey]
        selectedIndexes.push(sk)
      } else {
        nonSelected[teamKey] = teams[teamKey]
      }
    })
  }
  return { selected, nonSelected, selectedIndexes }
}

export const ammendPlayoffSeeding = (playoffLevelTeams) => {

  const seedTests = []
  const max = 15
  const halfMax = 7

  for (let i = 0; i <= halfMax; i++) {
    seedTests.push({ h: i, a: max - i })
  }

  Object.keys(playoffLevelTeams).forEach(k => {
    const teams = playoffLevelTeams[k]
    seedTests.forEach(st => {
      const h = teams[st.h]
      const a = teams[st.a]
      if (h) { h.badSeed = false }
      if (a) { a.badSeed = false }
      if (h && a) {
        h.badSeed = h.sections === a.sections ? h.sections : null
        a.badSeed = h.sections === a.sections ? h.sections : null
      }
    })
  })
}

export const cleanUpPlayoffSeeding = (playoffLevelTeams) => {

  const seedTests = []
  const max = 15
  const halfMax = 7

  for (let i = 0; i <= halfMax; i++) {
    seedTests.push({ h: i, a: max - i })
  }

  Object.keys(playoffLevelTeams).forEach(k => {
    const teams = playoffLevelTeams[k]
  })
}

export const ammendSomething = (state, action) => {
  // teams_all
  const { pathViews, teams_info, schools_info, teamsFromSchools } = state
  const { teams_existing } = action ?? {}
  const _data = updateTeamsSelected(teams_info, schools_info, teams_existing, teamsFromSchools)
  console.log('_data', _data)
  if (teamsFromSchools) {
    const { teams: _teamsExisting1 } = getTeamsFromSelectedData(_data, pathViews, teamsFromSchools ? false : true)
    const du1 = { ...state.seasonal_current, teams: _teamsExisting1 }
    return { ...state, schools_info: _data, seasonal_current: du1 }
  } else {
    const { teams: _teamsExisting2 } = getTeamsFromSelectedData(_data, pathViews, teamsFromSchools ? false : true)
    const du2 = { ...state.seasonal_current, teams: _teamsExisting2 }
    return { ...state, teams_info: _data, seasonal_current: du2 }
  }
}

export const createLevelsAndSections = (levels, sections, _levels_info) => {

  const _levels = Object.assign({}, levels)
  const _sections = Object.assign({}, sections)

  if (_levels && _sections) {
    const ls = {}
    _.each(_levels, (level, lk) => {
      ls[level._itemKey] = { name: level.name, checked: false, sections: {} }
      Object.keys(_sections).forEach(sk => {
        const section = _sections[sk]
        if (section.name !== 'Non-Section') {
          ls[level._itemKey].sections[section._itemKey] = { name: section.name, checked: false }
        }
      })
    })
    const _existing = _.cloneDeep(ls)
    const _next = _.cloneDeep(ls)
    _levels_info.existing = _existing
    _levels_info.next = _next
    const _tc = getTeamCountOptions(_.pickBy(_next, { checked: true }))
    return { levels_info: _levels_info, teamCounts: _tc }
  }
  return {}
}

export const ammendSportsDataFromResult = (_sportsData, action) => {

  const { dataResult } = action
  const { levels, schools, sections, teams } = dataResult ?? {}

  if (levels) {
    convertHelpers.createItemKeys(levels)
    _sportsData['levels'] = levels
  }

  if (sections) {
    convertHelpers.createItemKeys(sections)
    _sportsData['sections'] = _.sortBy(sections, '_itemKey')
  }

  if (teams) {
    _sportsData['teams'] = teams
    _sportsData['teams_district_sports'] = teams
  }

  if (schools) {
    getSchoolsWithTeams(schools, teams)
    _sportsData['schools'] = schools
  }

  const x = getSelectedTeamsFromSchools(schools, teams)

  const _school_data = getSportsDataInfo(schools, x.selectedIndexes)
  const _teams = teams ? teams : _school_data.existing
  const _hasTeams = teams ? true : false

  return { _teams, _hasTeams, _school_data }
}

export const ammendLevelSectionsFromCounts = (levels_info, sectionCounts, levelKey, sectionCount) => {
  const { next } = levels_info
  const _next = { ...next }
  if (sectionCounts && !_.isNumber(sectionCounts)) {
    Object.keys(sectionCounts).forEach(lk => {
      const _sectionCount = sectionCounts[lk]
      ammendNext(_next, lk, _sectionCount)
    })
  } else if (levelKey && sectionCount) {
    ammendNext(_next, levelKey, sectionCount)
  }
  levels_info.next = _next
}

export const getTeamsFromLevelSections = (levels_info, seasonal_current) => {
  const _teams = seasonal_current && seasonal_current.teams ? seasonal_current.teams : {}
  const { next } = levels_info
  if (next) {
    Object.keys(next).forEach(levelKey => {
      const level = next[levelKey]
      const { sections } = level ?? {}
      if (sections) {
        Object.keys(sections).forEach(sectionKey => {
          const section = sections[sectionKey]
          const { teams } = section ?? {}
          if (teams) {
            Object.keys(teams).forEach((teamKey, index) => {
              const team = teams[teamKey]
              const { id, _itemKey, position, name } = team ?? {}
              const _id = _itemKey ? _itemKey : id
              _teams[_id] = {
                levels: levelKey,
                sections: sectionKey,
                position: _.isNaN(position) || _.isUndefined(position) ? index : position,
                name
              }
            })
          }
        })
      }
    })
  }
  return { teams: _teams }
}

export const getRankingData = (action) => {
  const { actionProps } = action
  const { selectedLevel, selectedWeek, sortedLevels } = actionProps
  const sortedLevel = sortedLevels[selectedLevel]
  const teams = []

  sortedLevel.forEach((sl, index) => {
    if (index < 10) { teams.push(sl.name) }
  })

  const data_rankings = {}
  data_rankings['rankings' + '.' + selectedWeek + '.' + selectedLevel] = teams;

  return data_rankings
}

export const getSectionCounts = (levels_info) => {
  const _sectionCounts = {}
  const { next } = levels_info
  if (next) {
    Object.keys(next).forEach(levelKey => {
      const level = next[levelKey]
      const { sectionCount } = level ?? {}
      if (sectionCount > 0) {
        _sectionCounts[levelKey] = sectionCount
      }
    })
  }
  return _sectionCounts
}

/**
 * 
 * @param {object} teams 
 * @param {object} lsts 
 * @returns updates the levels/sections with their teams
 */
export const ammendLstsFromTeams = (teams, lsts, forHomeAway) => {
  const homeAways = {}
  let ready_alignment = false;
  let groupedLevels = _.groupBy(teams, 'levels')
  if (lsts && groupedLevels) {
    Object.keys(groupedLevels).forEach(lvKey => {
      const level = groupedLevels[lvKey]
      const selectedLevel = _.find(lsts, { name: lvKey })
      if (selectedLevel) {
        const _sections = []
        selectedLevel.checked = true
        const { sections } = selectedLevel
        let groupedSections = _.groupBy(level, 'sections')
        if (groupedSections) {
          Object.keys(groupedSections).forEach(sKey => {
            let selectedSection = _.find(sections, { name: sKey })
            if (!selectedSection) {
              selectedSection = _.find(sections, { name: _.startCase(sKey) })
            }
            if (selectedSection) {
              selectedSection.checked = true
              _sections.push(_.camelCase(sKey))
              const _teams = _.filter(teams, { levels: lvKey, sections: sKey })
              const _levelSectionKey = lvKey + '_' + sKey
              selectedSection.teams = _teams
              ready_alignment = true
              homeAways[_levelSectionKey] = true
            }
          })
        }
      }
    })
  }
  if (forHomeAway) {
    return { ready_alignment, homeAways }
  } else {
    return ready_alignment
  }
}

export const addTeamInfoToTeamRosters = (rosters, existingTeams) => {
  const _mergedData = []
  // adding the parentKey info to each of the data items
  Object.keys(rosters).forEach(rdKey => {
    const rosterGroup = rosters[rdKey]
    const { parentKeys } = rosterGroup
    const { teams } = parentKeys ?? {}
    const rdiData = rosterGroup['athletes']
    Object.keys(rdiData).forEach(keyy => {
      if (teams) { rdiData[keyy].teams = teams }
      if (existingTeams) {
        const t = _.find(existingTeams, { id: teams })
        if (t) {
          const { name, levels, sections } = t
          if (levels) { rdiData[keyy].levels = levels }
          if (sections) { rdiData[keyy].sections = sections }
          if (name) { rdiData[keyy].teams = name }
          rdiData[keyy].key = keyy
        }
      }
      _mergedData.push(rdiData[keyy])
    })
  })
  return _mergedData
}

export const ammendTeam = (teamKey, team, sportsYears) => {
  let { parentKeys } = team
  if (!parentKeys) { parentKeys = {} }
  parentKeys.sportsYears = sportsYears
  return {
    levels: team.levels,
    sections: team.sections,
    schools: team.schools,
    name: team.name,
    parentKeys: team.parentKeys,
    id: team.id ? team.id : teamKey,
    _itemKey: team.id ? team.id : teamKey,
    position: team.position
  }
}

export const getTeamCountOptions = (activeLevelsAndSections) => {
  const optsA = []
  if (activeLevelsAndSections) {
    Object.keys(activeLevelsAndSections).forEach(key => {
      const ls = activeLevelsAndSections[key]
      if (ls && ls.sections)
        Object.keys(ls.sections).forEach(skey => {
          const section = ls.sections[skey]
          const { teams } = section ?? {}
          if (teams) {
            if (teams.length > 1) {
              if (!optsA.includes(teams.length)) {
                optsA.push(teams.length)
              }
            }
          }
        })

    })
  } else {
    for (var i = 3; i <= 10; i++) {
      optsA.push(i + ' Teams')
    }
  }
  return optsA.sort()
}

export const ammendCurrentSeasonInfo = (season_info, crossoverOn) => {
  const _season_info = { ...season_info }
  const { scheduleDatesInfo, crossSectionDates, sectionDates } = _season_info
  const { currentSectionCount } = scheduleDatesInfo
  const seasonDates = crossoverOn ? crossSectionDates : sectionDates
  if (seasonDates && seasonDates[currentSectionCount]) {
    scheduleDatesInfo.currentDates = seasonDates[currentSectionCount]
  }
}

/**
* 
* @param {object} state 
* @param {object} action  
* }
*/
export const getCurrentSeasonInfo = (state, action) => {

  const { season_info, currentSectionCount, crossoverOn } = state

  const { seasonDateProps, currentSectionCount: currentSectionCount_a } = action ?? {}
  const { selectedDates } = seasonDateProps ?? {}

  const { sectionDates, crossSectionDates, sectionDatesReady } = season_info ?? {}

  const _seasonDates = crossoverOn ? crossSectionDates : sectionDates

  const _currentSectionCount = currentSectionCount_a ? currentSectionCount_a : currentSectionCount

  if (selectedDates && selectedDates.length > 0) {
    _seasonDates[_currentSectionCount] = selectedDates ? selectedDates.sort() : []
    // sectionDates[_currentSectionCount] = selectedDates ? selectedDates.sort() : []
  }

  // if (crossSectionDates && crossSectionDates.length > 0) {
  //   crossSectionDates[_currentSectionCount] = crossSectionDates ? crossSectionDates.sort() : []
  // }

  let needed = _currentSectionCount * 2
  if (!isOdd(_currentSectionCount)) { needed = needed - 2 }

  if (sectionDates[_currentSectionCount]) {
    sectionDatesReady[_currentSectionCount] = sectionDates[_currentSectionCount].length === needed
  }

  let _scheduleDatesAreReady = true;
  if (sectionDatesReady) {
    Object.keys(sectionDatesReady).forEach(key => {
      if (!sectionDatesReady[key]) {
        _scheduleDatesAreReady = false
      }
    })
  }

  season_info.sectionDatesReady = sectionDatesReady

  if (crossoverOn) {
    season_info.crossSectionDates = _seasonDates
  } else {
    season_info.sectionDates = _seasonDates
  }

  season_info.scheduleDatesAreReady = _scheduleDatesAreReady

  if (crossoverOn) {
    const _crossSectionDates = _seasonDates[_currentSectionCount]
    const _crossSectionDates_sorted = reorderDateStrings(_crossSectionDates)
    season_info.scheduleDatesInfo = {
      currentSectionCount: _currentSectionCount,
      currentNeeded: needed,
      currentCount: _crossSectionDates_sorted ? _crossSectionDates_sorted.length : 0,
      currentDates: _crossSectionDates_sorted,
    }
  } else {
    const _sectionDates = _seasonDates[_currentSectionCount]
    const _sectionDates_sorted = reorderDateStrings(_sectionDates)
    season_info.scheduleDatesInfo = {
      currentSectionCount: _currentSectionCount,
      currentNeeded: needed,
      currentCount: _sectionDates_sorted ? _sectionDates_sorted.length : 0,
      currentDates: _sectionDates_sorted,
    }
  }

  // const _sectionDates = sectionDates[_currentSectionCount]
  // const _sectionDates_sorted = reorderDateStrings(_sectionDates)

  // const _crossSectionDates = crossSectionDates[_currentSectionCount]
  // const _crossSectionDates_sorted = reorderDateStrings(_crossSectionDates)



  // season_info.scheduleDatesInfo = crossoverOn ?
  //   {
  //     currentSectionCount: _currentSectionCount,
  //     currentNeeded: needed,
  //     currentCount: _crossSectionDates_sorted ? _crossSectionDates_sorted.length : 0,
  //     currentDates: _crossSectionDates_sorted,
  //   }
  //   :
  //   {
  //     currentSectionCount: _currentSectionCount,
  //     currentNeeded: needed,
  //     currentCount: _sectionDates_sorted ? _sectionDates_sorted.length : 0,
  //     currentDates: _sectionDates_sorted,
  //   }

  return season_info

}

/**
 * 
 * @param {object} state (currentLevelKey,playoff_info )
 * @param {object} action (playoffDateProps, levelKey)
 * @returns 
 */
export const ammendCurrentPlayoffInfo = (playoff_info, playoffGroupKey, action) => {

  const { playoffDateProps } = action ?? {}
  const { selectedDates } = playoffDateProps ?? {}

  let _playoff_info = copyObj(playoff_info)
  if (!_playoff_info) { _playoff_info = {} }
  const { playoffDates_selected, playoffDates_needed } = _playoff_info ?? {}

  if (selectedDates) {
    playoffDates_selected[playoffGroupKey] = selectedDates
  }

  _playoff_info.playoffDatesAreReady = ammendPlayoffDatesReady(_playoff_info)

  if (playoffGroupKey) {
    _playoff_info.playoffDates_current = {
      currentNeeded: playoffDates_needed[playoffGroupKey],
      currentCount: playoffDates_selected[playoffGroupKey] ? playoffDates_selected[playoffGroupKey].length : 0,
      currentDates: playoffDates_selected[playoffGroupKey] ? playoffDates_selected[playoffGroupKey] : [],
      currentLevelKey: playoffGroupKey
    }
  }

  return _playoff_info

}

const ammendPlayoffDatesReady = (playoff_info) => {
  let _playoffDatesReady = true
  const { playoffLevelTeams, playoffDates_selected, playoffDates_ready, playoffDates_needed } = playoff_info ?? {}
  const playoffLevelKeys = playoffLevelTeams ? Object.keys(playoffLevelTeams) : []
  playoffLevelKeys.forEach(plk => {
    playoffDates_ready[plk] = playoffDates_selected && playoffDates_selected[plk] && playoffDates_needed && (playoffDates_selected[plk].length === playoffDates_needed[plk])
    if (!playoffDates_ready[plk]) {
      _playoffDatesReady = false
    }
  })
  return _playoffDatesReady
}

/**
 * 
 * @param {object} teams_seasonal 
 * @param {object} sports_data 
 * @param {object} sportsDetails 
 * @param {object} levels_info 
 * @param {boolean} forSeason 
 * @returns 
 */
export const getSelectedTeams = (teams_seasonal, state, levels_info, forSeason) => {

  const { sportsDetails, sports_data } = state
  const { existing, next } = levels_info
  const { schools: schools_sd } = sports_data

  const { sportsYears } = sportsDetails ?? {}

  let playoff_teamz = _.filter(teams_seasonal, function (st) { return st.record && st.record.playoffTeam })

  const _selectedSchoolIndexes = []
  const _selectedTeams = {}
  const _selectedTeamIndexes = []

  let _homeAways;
  let ready_levels;

  const _teams = {}

  if (teams_seasonal) {
    Object.keys(teams_seasonal).forEach((teamKey) => {
      _teams[teamKey] = ammendTeam(teamKey, teams_seasonal[teamKey], sportsYears)
    })
  }

  if (forSeason) {
    Object.keys(_teams).forEach((teamKey) => {
      _selectedTeamIndexes.push(teamKey)
      _selectedTeams[teamKey] = _teams[teamKey]
    })

    if (schools_sd) {
      Object.keys(_teams).forEach((teamKey) => {
        const t = _teams[teamKey]
        const { name: n } = t
        const selectedSchoolIndex = _.findIndex(schools_sd, { name: n })
        _selectedSchoolIndexes.push(selectedSchoolIndex)
      })
    }

    ammendLstsFromTeams(_teams, existing, true)

    const { ready_alignment, homeAways } = ammendLstsFromTeams(_teams, next, true)

    ready_levels = ready_alignment
    _homeAways = homeAways

  } else {
    Object.keys(_teams).forEach((teamKey, index) => {
      if (index < 16) {
        _selectedTeamIndexes.push(index)
        _selectedTeams[teamKey] = _teams[teamKey]
      }
    })
  }

  return {
    _selectedSchoolIndexes,
    _selectedTeamIndexes,
    _selectedTeams,
    homeAways: _homeAways,
    levels_info,
    playoff_teamz,
    ready_levels,
  }
}

export const getSportsDataInfo = (schoolsOrTeams, _selectedIndexes, teams_seasonal) => {

  const sportsData = {
    existing: {},
    selected: {},
    nonSelected: {},
  }

  if (schoolsOrTeams) {
    // loop the schools or teams
    _.each(schoolsOrTeams, (si, key) => {
      const { name, _itemKey, _teamKey } = si
      const _key = _itemKey ? _itemKey : key
      const checked = _selectedIndexes && _selectedIndexes.includes(_key)
      sportsData.existing[_key] = { key, name, checked, _itemKey: _key, _teamKey, disabled: _teamKey ? false : false }
    })
  }

  if (sportsData.existing) {
    _.each(sportsData.existing, (item, key) => {
      const { checked, _itemKey } = item
      const ts = teams_seasonal && teams_seasonal[_itemKey]
      const _selOrNon = checked ? sportsData.selected : sportsData.nonSelected
      _selOrNon[_itemKey] = { ...item, checked: checked }
      if (ts) {
        const { levels, sections, position } = ts
        _selOrNon[_itemKey].levels = levels
        _selOrNon[_itemKey].sections = sections
        _selOrNon[_itemKey].position = position
      }
    })
    sportsData.existing = _.sortBy(sportsData.existing, 'name')
    sportsData.selected = _.sortBy(sportsData.selected, 'name')
    sportsData.nonSelected = _.sortBy(sportsData.nonSelected, 'name')
  }

  return sportsData

}

export const createTheSeasonMatches = (next, season_info, homeAway, gridMatches, gameScoringType, sectionCrossovers) => {

  const _existingTeams = {}
  let xx = _.pickBy(next, { checked: true })
  if (xx) {
    Object.keys(xx).forEach(key => {
      const ls = xx[key]
      if (ls && ls.sections)
        Object.keys(ls.sections).forEach(skey => {
          const section = ls.sections[skey]
          const { checked, teams } = section ?? {}
          if (checked && teams) {
            if (teams.length > 1) {
              teams.forEach((team) => {
                _existingTeams[team.id] = team
                _existingTeams[team.id].position = team.position
              })
            }
          }
        })
    })
  }
  const { allMatches, crossoverMatches } = createScheduledMatches(_existingTeams, season_info, homeAway, gridMatches, gameScoringType, sectionCrossovers)
  return { seasonMatches: allMatches, crossoverMatches, seasonTeams: _existingTeams }
}

export const getSelectedPlayoffInfo = (playoffInfo, playoffInfoType) => {

  let _playoffInfoType;

  switch (playoffInfoType) {
    case seasonalUpdateTypes.playoffDates:
      _playoffInfoType = 'playoffDates'
      break;
    case seasonalUpdateTypes.playoffTeams:
    case seasonalUpdateTypes.playoffSeeds:
      _playoffInfoType = 'playoffLevelTeams'
      break;
    case seasonalUpdateTypes.playoffBracket:
      _playoffInfoType = 'matches'
      break;
    default:
      break;
  }

  let selectedInfo = playoffInfo && _playoffInfoType && playoffInfo[_playoffInfoType] ? playoffInfo[_playoffInfoType] : null

  switch (playoffInfoType) {
    case seasonalUpdateTypes.playoffSeeds:
      const _selectedInfo = {}
      Object.keys(selectedInfo).forEach(levelKey => {
        _selectedInfo[levelKey] = []
        const teams_level = selectedInfo[levelKey] // arrayOfTeams
        if (teams_level) {
          teams_level.forEach(team => {
            const { id, levels, sections, name, position, seed } = team
            const _info = { id, levels, sections, name, position }
            if (_.isNumber(seed)) { _info.seed = seed }
            _selectedInfo[levelKey].push(_info)
          })
        }
      })
      selectedInfo = _selectedInfo
      break;
    case seasonalUpdateTypes.playoffDates:
      selectedInfo = playoffInfo.playoffDates_selected
      break;

    default:
    // nothing
  }

  if (selectedInfo) {
    return {
      dtu: { ['playoffInfo.' + _playoffInfoType]: selectedInfo },
      dta: { playoffInfo: { [_playoffInfoType]: selectedInfo } }
    }
  }
}

export const getFullPlayoffInfo = (playoffInfo, playoffLevel) => {

  const { matches: playoffMatches, tournamentMatches } = playoffInfo ?? {}

  const _pms = dataFix.removeEmpty(playoffMatches)

  if (playoffLevel) {
    return tournamentMatches[playoffLevel]
  } else {
    return _pms
  }
  // if (playoffMatches) {
  //   cleanUpData(playoffMatches)
  // }

  // if (playoffTournaments) {
  //   return {
  //     ['playoffInfo.playoffDates']: playoffDates_selected,
  //     ['playoffInfo.playoffLevelTeams']: playoffLevelTeams,
  //     ['playoffMatches']: _pms,
  //   }

  // }
}


const ammendNext = (next, levelKey, sectionCount) => {
  if (next && next[levelKey]) {
    next[levelKey][sectionCountTypes.checked] = sectionCount > 0 // boolean
    next[levelKey][sectionCountTypes.sectionCount] = sectionCount > 0 ? sectionCount : false // boolean
    if (next[levelKey].sections) {
      Object.keys(next[levelKey].sections).forEach(sk => {
        const section = next[levelKey].sections[sk]
        const { name } = section
        const _nameIndex = name.replace('Section ', '')
        section[sectionCountTypes.checked] = parseInt(_nameIndex) <= sectionCount
      })
    }
  }
}

/**
 * Adds a `_teamKey` prop to each school that has a team (team.parentKeys.schools)
 * @param {*} schools 
 * @param {*} teams 
 */
const getSchoolsWithTeams = (schools, teams) => {
  if (teams && schools) {
    _.each(teams, (team, tk) => {
      if (team && team.parentKeys && team.parentKeys.schools) {
        if (schools[team.parentKeys.schools]) {
          schools[team.parentKeys.schools]._teamKey = tk
        }
      }
    })
  }
  console.log('getSchoolsWithTeams', schools)
}

/**
 * 
 * @param {object} data 
 * @param {object} pathViews 
 * @param {boolean} forSeason 
 * @returns an object with `teams` object created from the data.selected object
 */
const getTeamsFromSelectedData = (data, pathViews, forSeason) => {
  const { selected } = data
  const _teams = {}
  if (selected) {
    Object.keys(selected).forEach(key => {
      const selectedItem = selected[key]
      const { name, _itemKey } = selectedItem
      if (forSeason) {
        _teams[_itemKey] = {
          name,
          sections: 0,
          levels: 0,
          position: 0,
        }
      } else {
        const teamKey = createFsDocKey()
        _teams[teamKey] = {
          name,
          parentKeys: {
            organizations: pathViews.organizations,
            districts: pathViews.districts,
            schools: _itemKey,
            sports: pathViews.sports,
          }
        }
      }

    })
  }
  return { teams: _teams }
}

const updateTeamsSelected = (teams_info, schools_info, teamz_existing, teamsFromSchools) => {

  const _data = teamsFromSchools ? { ...schools_info } : { ...teams_info }

  const { existing: teams_existing } = teams_info ?? {}
  const { existing: schools_existing } = schools_info ?? {}

  const _existingTeams = teamsFromSchools ? schools_existing : teams_existing

  const _selected = {}
  const _nonSelected = {}
  const _existing = {}

  if (teamz_existing) {
    if (teamz_existing) {
      Object.keys(teamz_existing).forEach((key) => {
        const _team = teamz_existing[key]
        const { name, _itemKey, checked } = _team
        _existing[_itemKey] = { name, checked, _itemKey }
        if (checked) {
          _selected[_itemKey] = { name, checked: true, _itemKey }
        } else {
          _nonSelected[_itemKey] = { name, checked: false, _itemKey }
        }
      })
    }
  } else {
    if (_existingTeams) {
      Object.keys(_existingTeams).forEach((key) => {
        const _team = _existingTeams[key]
        const { name, _itemKey, checked } = _team
        _existing[_itemKey] = { name, checked: false, _itemKey }
        if (checked) {
          _selected[_itemKey] = { name, checked: true, _itemKey }
        } else {
          _nonSelected[_itemKey] = { name, checked: false, _itemKey }
        }
      })
    }
  }

  _data.selected = _selected
  _data.nonSelected = _nonSelected
  _data.existing = _existing
  _data._existing = _.sortBy(_existing, 'name')

  console.log('_data', _data)

  return _data

}