import React, { useContext, useEffect, useReducer } from "react";
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sportsHandlers, sportsInitialState, sportsReducer } from "../reducers/SportsReducer";
import { _cacheNames } from "../../../../global/cnr/reducers/ServiceWorkerReducer";

export const SportsContext = React.createContext()

export default function SportsProvider(props) {

  const { directReturn } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, serviceWorker_state } = states ?? {}
  const { database_fns } = fns ?? {}

  const { cacheApp } = serviceWorker_state ?? {}
  const { cachedItems } = cacheApp ?? {}
  const { cache_data } = cachedItems ?? {}

  // appUserContext
  const { appUser } = appUser_state ?? {}
  const { uid } = appUser ?? {}

  // papsContext 
  const { view, pathViews } = paps_state ?? {}

  const _cacheName = _cacheNames.cache_data + '-' + pathViews.events
  const _cacheData = cache_data && cache_data[pathViews.events]

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page } = pageSettings ?? {}

  const { viewItems: viewItems_page } = aps_page ?? {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ?? {}
  const { dataParents, useSeasonals } = dataSource ?? {}

  // organizations/districts/sports
  // organizations/sports

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const init_state = {
    pathViews,
    viewItem,
    dataParents,
    useSeasonals,
    uid,
    ownerPath,
    inverted: false,
    cssOpts: { name: 'ssc-normal' },
    _cacheData,
    database_fns
  }

  const [sports_state, sports_dispatch] = useReducer(sportsReducer, init_state, sportsInitialState)
  const sports_handlers = sportsHandlers(sports_dispatch, sports_state)

  useEffect(() => {
    sports_handlers.handleSportsInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  if (sports_state) {
    if (directReturn) {
      return { sports_handlers, sports_state }
    } else {
      return <SportsContext.Provider
        value={{ sports_state, sports_handlers, appComponent: 'sports' }}>
        {props.children}
      </SportsContext.Provider>
    }
  } else {
    return <SportsContext.Provider
      value={{}}>
      {props.children}
    </SportsContext.Provider>
  }
}