import { getBaseRef } from './getBaseRef';
import { getWhereConditionals } from './getRefConditionals';

// https://firebase.google.com/docs/firestore/query-data/query-cursors

/**
 * 
 * @param {object} propz 
 * @param {object} menu 
 * @returns the props needed for the retrieval of the data
 */
export const getRefPropz = (propz, menu) => {
  const { stateProps, actionProps } = propz
  const { isAppData, useSingleCollectionDocuments, uivi, view, viewItem_g, viewItem, viewKey } = actionProps
  const { nonLandingView, timeZone, _useStartDataCollections } = stateProps ?? {}
  const refProps = getRefProps(propz)
  const dProps = { refProps, uivi, viewKey, isAppData, view, timeZone, useSingleCollectionDocuments, _useStartDataCollections }
  const dataCaptionProps = { menu, viewItem, viewItem_g, nonLandingView }
  return { dProps, dataCaptionProps }
}

/**
 * 
 * @param {object} grpp 
 * @returns Creates the ref for firestore
 */
export const getRefProps = (grpp) => {

  const { stateProps, actionProps, } = grpp

  const {
    appUserAccess,
    aps_viewItems,
    dataLimit,
    dataOverrideOn,
    googleSheetsId,
    hideInactiveItems_do,
    limitDevelopmentData,
    logging,
    nonLandingView,
    pathViews,
    page_fns,
    paps_state,
    _useStartDataCollections,
  } = stateProps ?? {}

  const {
    alphabetValue,
    currentPageData,
    pathName,
    searchingValue,
    singleDirect,
    useSingleCollectionDocuments,
    uivi,
    viewItem_g,
    viewItem,
    dataConstraints_preview,
    dataConstraints_temp,
    viewKey,
  } = actionProps ?? {}

  // page_fns 
  const { getGviDeps } = page_fns ?? {}

  const { eventPageKey, view } = paps_state ?? {}

  const { dataSource, dataConstraints, display, key: key_viewItem, grouping } = viewItem ?? {}
  let { sortProp } = display ?? {}
  let dataFilters_alt;

  if (!sortProp) { sortProp = 'name' }

  const { altDataCollectionName, useSubDataCollection, subDataCollectionName } = dataSource ?? {}

  if (altDataCollectionName && aps_viewItems && aps_viewItems[altDataCollectionName]) {
    const viewItem_dataFilter = aps_viewItems[altDataCollectionName]
    const { dataFilters: dfa } = viewItem_dataFilter
    dataFilters_alt = dfa
  }

  let { uiData: globalUiData, data: globalData, dataConnection, dataFilters } = viewItem_g ?? {}
  const { useParentKeysOnly } = dataConnection ?? {}
  if (!globalUiData && globalData) { globalUiData = globalData }

  let trueUivi = uivi

  // change the uivi in needed
  // if (dataCollectionName) { trueUivi = dataCollectionName }
  if (altDataCollectionName) { trueUivi = altDataCollectionName }

  const viDeps = getGviDeps(trueUivi)
  const { dependencies: deps } = viDeps

  switch (trueUivi) {
    case 'home':
      if (deps) { deps.pop() }
      break;
    default:
    // nothing
  }

  let singleDataItem = false

  // Get the base ref based on dependencies 
  const baseRef = getBaseRef(viDeps, pathViews, null, null, logging)
  let { showDataLinkType } = logging ?? {}

  showDataLinkType = true

  let { refPath, refPaths } = baseRef

  // this sets the ref 
  const firestoreRefs = []

  const whereOpts = { priors: [], posts: [], dataKeys: [] }

  let orders;
  let _dataLimit;

  // LOOK
  if (limitDevelopmentData) {
    _dataLimit = dataLimit ? dataLimit : 10
  }

  // if useSubDataCollection, add the subDataCollectionName to the ref
  if (useSubDataCollection && subDataCollectionName) {
    refPaths.push(subDataCollectionName)
  } else {
    if (useSingleCollectionDocuments) {
      refPaths.push('_' + trueUivi)
      refPaths.push(trueUivi)
    } else {
      refPaths.push(trueUivi)
    }
  }

  // see if this is a document
  if (singleDirect) {
    singleDataItem = true
    refPath += '/' + trueUivi + '/' + viewKey
  } else {
    let _dcs = dataConstraints_preview ? dataConstraints_preview : dataConstraints
    if (dataConstraints_temp) { _dcs = dataConstraints_temp }
    getWhereConditionals({
      alphabetValue, // data_state
      appUserAccess, // appUser_state
      currentPageData, //pageData_state 
      dataFilters_alt, // aps_viewItems
      dataFilters, // viewItem_g
      dataOverrideOn, // appSettings_state
      dataConstraints: _dcs,
      dataSource, // viewItem
      grouping, // viewItem
      hideInactiveItems_do,
      logging, // aps_global
      nonLandingView, // paps_state
      orders, // lodal
      pathViews, // paps_state 
      searchingValue,
      sortProp, // viewItem.display 
      trueUivi, // props from 
      viewKey, // paps_state
      whereOpts, // local,
      _useStartDataCollections
    })

    let getSingleItem = false

    if (viewKey && (key_viewItem === nonLandingView) && !useParentKeysOnly) {
      getSingleItem = true
    }

    if (getSingleItem) {
      singleDataItem = true
      refPath += '/' + trueUivi + '/' + viewKey
      if (viewKey) { refPaths.push(viewKey) }
    }
  }

  let refKey = pathName ? pathName.replace(/\//g, '_') : ''

  return {
    dataLimit: _dataLimit,
    firestoreRefs,
    googleSheetsId,
    orders,
    refPath,
    refKey,
    refPaths,
    singleDataItem,
    whereOpts,
    eventPageKey,
    view,
  }
}

export const getStartEnd = (ai) => {
  let afiStart = 'A'
  let afiEnd = 'B'
  afiStart = String.fromCharCode(parseInt(ai))
  afiEnd = String.fromCharCode(parseInt(ai) + 1)
  return {
    start: afiStart,
    end: afiEnd
  }
}