import { deleteField } from 'firebase/firestore';
import _ from 'lodash';
import { _appVersion } from '../../cnr/contexts/ServiceWorkerContext';
import { dispatchProps, grts } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { _deleteItemProps, _deleteViProps, dataFix } from '../../common/dataFix';
import { getNow } from '../../common/dateFormatting';
import { copyObj } from '../../common_web/copy';
import { _settingsFs, _useSettingsSeparation } from '../../viewSettings/actions/getSettings';
import { createRefPath, createRefPath_event } from '../appData/appRefPaths';
import { doc_set, fs_set_doc, fs_update_doc } from "../appData/fsData";

const _allowUpdates = true
const _allowUpdates_fromSidebar = true
const _useGlobalAlt = false
const _settingsGlobalDocument = 'global'
const _settingsGlobalAltDocument = '_global'

// const separateSaveViewItems = false
// https://howtofirebase.com/firebase-security-rules-88d94606ce4a

export const updateSettings = {
  cleanUpSettings: (viewKey, views, viewItems) => cleanUpSettings(viewKey, views, viewItems),
  createNewViewAndViewItemsSettingsDB: (settingsProps, pathViews, callback) => createNewViewAndViewItemsSettingsDB(settingsProps, pathViews, callback),
  createNewViewsAndViewItemsSettingsToDb: (pathViews, projectSettings, callback) => createNewViewsAndViewItemsSettingsToDb(pathViews, projectSettings, callback),
  updateFromSidebar: (settingsDocName, view, data, appUser, callback) => settingsUpdate_fromSidebar(settingsDocName, view, data, appUser, callback),
  updateGlobalFromSidebar: (settingsDocName, data, appUser, callback) => settingsUpdate_globalFromSidebar(settingsDocName, data, appUser, callback),
  updateGlobalAndViewsDBSettings: (fullDbSettings, paps_state, sandboxOn, appUser, callback) => updateGlobalAndViewsDBSettings(fullDbSettings, paps_state, sandboxOn, appUser, callback),
  updateHomeGlobalSettingsToDb: (dataToUpdate, callback, useAlt) => updateHomeGlobalSettingsToDb(dataToUpdate, callback, useAlt),
  updateNewViewToViewsSettingsDB: (viewKey, views, pathViews, callback) => updateNewViewToViewsSettingsDB(viewKey, views, pathViews, callback),
  updateSettings_viewItemsOnly: (pathViews, viewItems) => updateSettings_viewItemsOnly(pathViews, viewItems),
  updateSettingsDocsToDatabase: (allAppSettings, response) => updateSettingsDocsToDatabase(allAppSettings, response),
  updateSettingsToDB: (data, dispatch) => updateSettingsToDB(data, dispatch),
  updateViewItemsToDb: (pathViews, viewItems, ammendedKeys) => updateViewItemsToDb(pathViews, viewItems, ammendedKeys),
  updateViewViewItemSettingsDB: (pathViews, viewItem, viewKey, callback) => updateViewViewItemSettingsDB(pathViews, viewItem, viewKey, callback),
}

export const dv = (value) => {
  const allDocs = {}
  value.forEach(doc => { m(allDocs, doc) })
  return allDocs
}

const cleanUpSettings = (viewKey, views, viewItems) => {

  const removes = []

  const _viewItems = copyObj(views)
  const _views = copyObj(viewItems)

  delete _viewItems[viewKey]
  removes.push({ 'viewItem': viewKey })

  // clean up views > viewItems
  Object.keys(_views).forEach(viewKey => {
    const view = _views[viewKey]
    if (view && view.viewItems) {
      delete view.viewItems[viewKey]
      removes.push({ 'views': viewKey })
    }
  })

  Object.keys(_viewItems).forEach(viKey => {
    const viewItem = _viewItems[viKey]
    if (viewItem && viewItem.props) {
      delete viewItem.props[viewKey]
      removes.push({ 'props': viewKey })
    }
  })

  delete _views[viewKey]

  return { views: _views, viewItems: _viewItems }
}

/**
 * This updates (usings Set) the settings to the database
 * @param {object} data (settings_temp, papsProps, isGlobal, sandboxOn, appUser, specificView, appSettingsChanges)
 * @param {function} dispatch 
 * called from fbSettingsFunctions
 */
const updateSettingsToDB = async (data, dispatch) => {

  const _replaceFull = true

  const { settings_temp, papsProps, isGlobal, sandboxOn, appUser, specificView, appSettingsChanges } = data ?? {}
  const { gvvi, page } = settings_temp ?? {}
  const { displayName, uid } = appUser ?? {}

  let _global;
  let _viewItems;
  let _views;

  if (isGlobal) {
    _global = gvvi.global
    _viewItems = gvvi.viewItems
    _views = gvvi.views
  } else {
    _global = page
    _viewItems = page.viewItems
  }

  const settings_name = _useSettingsSeparation ? _settingsFs.root : _settingsFs.root + '_dev'

  const { pathViews, rootPaths, view, viewKey, landingView, settingsDocName } = papsProps ?? {}

  const newGlobal = _global ? copyObj(_global) : null
  const newViewItems = _viewItems ? copyObj(_viewItems) : null
  const newViews = _views ? copyObj(_views) : null

  const { googleSheets, parentDefaults } = newGlobal ?? {}

  if (googleSheets) {
    let dbPath = rootPaths ? rootPaths.events : null
    dbPath = dbPath ? dbPath.substring(1) : null
    googleSheets.dbPath = dbPath ? dbPath : 'unknown'
  }

  if (parentDefaults) {
    // remove these items from the save
    const { useClientThemes, useHomeTransitions, useClientUiElements, useClientUiSections } = parentDefaults
    if (useClientThemes) {
      delete newGlobal.themedItems
      delete newGlobal.themeColors
    }
    if (useHomeTransitions) { delete newGlobal.transitionItems }
    if (useClientUiElements) { delete newGlobal.uiElements }
    if (useClientUiSections) { delete newGlobal.uiSections }
  }

  let trueViewKey = viewKey ? view : view + '_list'
  trueViewKey = getTrueView(pathViews, view, trueViewKey, landingView, viewKey)

  if (specificView) { trueViewKey = specificView }

  // ************ 
  const _refPath = []
  let _ref_path;

  if (sandboxOn && appUser && appUser.uid) {
    _refPath.push(settings_name + '_sandbox', settingsDocName)
  } else {
    _refPath.push(settings_name, settingsDocName)
    _ref_path = createRefPath([_settingsFs.root, settingsDocName])
  }

  // if (newViewItems) { cleanUpData(newViewItems, null, true, true) }

  let dataUpdate;

  if (isGlobal) {
    if (newViews) {
      dataUpdate = {
        global: newGlobal,
        viewItems: newViewItems,
      }
    } else {
      dataUpdate = {
        global: newGlobal,
        viewItems: newViewItems
      }
    }
  } else {
    // the views. will only update the `views` part of the document
    if (_useSettingsSeparation) {
      dataUpdate = {
        views: { [trueViewKey]: { ..._global, viewItems: newViewItems } },
      }
    } else {
      const x = 'views.' + trueViewKey
      dataUpdate = { [x]: { ..._global, viewItems: newViewItems } }
    }
  }

  dataUpdate = dataFix.removeNulls(dataUpdate)

  if (dataUpdate.views) {
    Object.keys(dataUpdate.views).forEach(key => {
      const view = dataUpdate.views[key]
      const { viewItems } = view ?? {}
      ammendDeleteFields(viewItems, _replaceFull)
    })
  }

  ammendDeleteFields(dataUpdate.viewItems, _replaceFull)

  const _timestamp = getNow()

  dataUpdate.status = {
    uid,
    displayName,
    appVersion: _appVersion,
    lastUpdate: _timestamp
  }

  if (_useSettingsSeparation) {
    settingsUpdate(_ref_path, dataUpdate, isGlobal, trueViewKey, _replaceFull).then(res => {
      appSettingsChanges && appSettingsChanges.statusChanged && updateEventStatus(dataUpdate, pathViews)
      dispatch && dispatch({ type: dispatchProps.success, dispatch, res, _timestamp })
    }).catch(error => {
      console.error(error)
      dispatch && dispatch({ type: dispatchProps.error, dispatch, error })
    })
  } else {
    fs_update_doc(_refPath, dataUpdate, dispatch)
    appSettingsChanges && appSettingsChanges.statusChanged && updateEventStatus(dataUpdate, pathViews)
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {object} viewItems 
 * @param {array} ammendedKeys 
 */
const updateViewItemsToDb = async (pathViews, viewItems, ammendedKeys) => {
  const settings_name = _useSettingsSeparation ? _settingsFs.root : _settingsFs.root + '_dev'
  const baseRef = createRefPath([settings_name, pathViews.events])
  const _refPath = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
  fs_set_doc(_refPath, viewItems, true, false, true)
}

/**
 * Updates the settings (fuil). This is using `doc_set`. This will replace the existing settings for `view` and `viewItems`
 * @param {object} pathViews 
 * @param {object} viewItems 
 * @param {object} views 
 * @param {function} callback 
 */
const createNewViewsAndViewItemsSettingsToDb = async (pathViews, projectSettings, callback) => {
  createNewViewsAndViewItemsSettings(pathViews, projectSettings).then(res => {
    callback && callback({ type: dispatchProps.success, callback, res })
  })
}

/**
 * Updates the settings/home/settings/global document
 * @param {object} dataToUpdate 
 * @param {function} callback 
 */
const updateHomeGlobalSettingsToDb = (dataToUpdate, callback, useAlt) => {
  if (_allowUpdates) { //asdfasfasfd 
    const _settingsRef = createRefPath([_settingsFs.root, 'home', _settingsFs.collection, (_useGlobalAlt || useAlt) ? _settingsGlobalAltDocument : _settingsGlobalDocument])
    fs_update_doc(_settingsRef, dataToUpdate, callback)
  } else {
    callback({ success: false })
  }
}

/**
 * This will update the global, viewItems and views documents to the database
 * @param {object} allAppSettings 
 * @param {function} response 
 */
const updateSettingsDocsToDatabase = async (allAppSettings, response) => {
  settingsDocsPromise(allAppSettings).then(res => {
    response({ type: grts.updateSuccess })
  }).catch(error => {
    response({ type: grts.updateError, error })
  })
}


/**
 * Updates 3 parts of the settings document (views/viewKey, views/viewKey+_list, viewItems/viewKey)
 * @param {string} viewKey 
 * @param {object} view 
 * @param {object} viewList 
 * @param {object} viewItem 
 * @param {object} pathViews 
 * @param {function} callback 
 */
const createNewViewAndViewItemsSettingsDB = async (settingsProps, pathViews, callback) => {

  const settings_name = _settingsFs.root

  const _baseRef = createRefPath([settings_name, pathViews.events, _settingsFs.collection])

  updateViSettingsPromises(settingsProps, _baseRef).then(res => {
    console.log('res', res)
  }).catch(error => {
    console.error(error);
  })

}

/**
 * Updates a settings item via dot notation. ('views.' + _view + '.viewItems.' + viewItem.key)
  * @param {object} pathViews 
 * @param {object} viewItem 
 * @param {string} viewKey 
 * @param {function} callback 
 */
const updateNewViewToViewsSettingsDB = async (viewKey, views, pathViews, callback) => {

  const settings_name = _settingsFs.root
  const _refPath = []
  _refPath.push(settings_name, pathViews.events)

  const viewItem = views[viewKey]
  const viewItem_list = views[viewKey + '_list']

  const dataUpdate = {
    ['views.' + viewKey]: viewItem,
    ['views.' + viewKey + '_list']: viewItem_list,
  }

  fs_update_doc(_refPath, dataUpdate, callback)
}

/**
 * Updates a setting's item via dot notation. ('views.' + _view + '.viewItems.' + viewItem.key)
* @param {object} pathViews 
 * @param {object} viewItem 
 * @param {string} viewKey 
 * @param {function} callback 
 */
const updateViewViewItemSettingsDB = async (pathViews, viewItem, viewKey, callback) => {

  const settings_name = _useSettingsSeparation ? _settingsFs.collection : _settingsFs.root

  let _refPath = []

  if (_useSettingsSeparation) {
    _refPath = createRefPath([settings_name, pathViews.events, _settingsFs.collection, 'views'])
    const _vv = !viewKey ? viewItem.key + '_list' : viewItem.key
    const dataUpdateN = { [_vv]: viewItem }
    fs_update_doc(_refPath, dataUpdateN, callback)
  } else {
    _refPath = createRefPath([settings_name + '_dev', pathViews.events])
    const _view = !viewKey ? viewItem.key + '_list' : viewItem.key
    const x = 'views.' + _view + '.viewItems.' + viewItem.key
    const dataUpdate = { [x]: viewItem }
    fs_update_doc(_refPath, dataUpdate, callback)
  }
}

const updateGlobalAndViewsDBSettings = async (fullDbSettings, paps_state, sandboxOn, appUser, callback) => {

  const dataUpdate = copyObj(fullDbSettings)

  let { settingsDocName } = paps_state ?? {}

  // ************ 
  let _docRootPath;

  _docRootPath = createRefPath([_settingsFs.root, settingsDocName])

  if (fullDbSettings.global) { dataFix.cleanUpData(fullDbSettings.global) }
  if (fullDbSettings.views) { dataFix.cleanUpData(fullDbSettings.views) }

  // if (docRoot.FE) { console.log(docRoot.FE.segments) }

  console.log('_docRootPath', _docRootPath)
  console.log('dataUpdate', dataUpdate)


  // fs_update_doc(_docRootPath, dataUpdate, callback)

}

const updateSettings_viewItemsOnly = async (pathViews, viewItems) => {

  const settings_name = _settingsFs.root

  const promises = []

  const baseRef = createRefPath([settings_name, pathViews.events])

  // settings/key/settings/viewItems
  const rp_vi = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
  promises.push(doc_set(rp_vi, viewItems))

  return Promise.all(promises)

}

/** 
 * Updates the settings (fuil). This is using `doc_set`. This will replace the existing settings for `view` and `viewItems`
 * @param {object} pathViews 
 * @param {object} viewItems 
 * @param {object} views 
 * @param {function} callback 
 * @returns 
 */
const createNewViewsAndViewItemsSettings = async (pathViews, projectSettings, callback) => {

  const { viewItems, views } = projectSettings ?? {}
  const settings_name = _settingsFs.root

  let _viewItems = dataFix.removeNulls(viewItems)
  let _views = dataFix.removeNulls(views)

  _viewItems = dataFix.removeUnderscoreKeys(_viewItems)
  _views = dataFix.removeUnderscoreKeys(_views)

  _viewItems = dataFix.removeKeys(_viewItems, [..._deleteViProps, ..._deleteItemProps])
  _views = dataFix.removeKeys(_views, [..._deleteViProps, ..._deleteItemProps])

  const promises = []

  const baseRef = createRefPath([settings_name, pathViews.events])

  // settings/key/settings/viewItems
  const rp_vi = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
  promises.push(doc_set(rp_vi, _viewItems))

  // settings/key/settings/views
  const rp_v = createRefPath([_settingsFs.collection, 'views'], baseRef)
  promises.push(doc_set(rp_v, _views))

  return Promise.all(promises)
}

/**
 * 
 * @param {object} settingsProps 
 * @param {string} baseRef 
 * @returns 
 */
const updateViSettingsPromises = async (settingsProps, baseRef) => {

  const { viewKey, view, viewList, viewItem } = settingsProps

  dataFix.cleanUpData(viewItem)
  dataFix.cleanUpData(view)
  dataFix.cleanUpData(viewList)

  const promises = []

  const _refPath_viewItems = createRefPath(['viewItems'], baseRef)
  const _refPath_views = createRefPath(['views'], baseRef)

  const dataUpdate_viewItems = {
    [viewKey]: viewItem,
  }

  const dataUpdate_views = {
    [viewKey]: view,
    [viewKey + '_list']: viewList,
  }

  promises.push(fs_update_doc(_refPath_viewItems, dataUpdate_viewItems, null, true))
  promises.push(fs_update_doc(_refPath_views, dataUpdate_views, null, true))

  return Promise.all(promises)
}

/**
 * 
 * @param {object} viewItems 
 */
const ammendDeleteFields = (viewItems, replaceFull) => {
  if (viewItems) {
    _.each(viewItems, (viewItem, key) => {
      const { props, propSections } = viewItem ?? {}
      if (viewItem.key && _.isObject(viewItem.key) && viewItem.key._methodName) {
        viewItem.key = key
      }
      if (viewItem._deleteMe || viewItem.remove) {
        viewItems[key] = replaceFull ? '_deleteMe' : deleteField()
      } else {
        _.each(viewItem, (viewItemProp, propKey) => {
          if (_.isObject(viewItemProp) && (viewItemProp._deleteMe || viewItemProp.remove)) {
            viewItem[propKey] = replaceFull ? '_deleteMe' : deleteField()
          }
        })
      }
      if (propSections) {
        _.each(propSections, (propSection, psKey) => {
          if (_.isObject(propSection) && (propSection._deleteMe || propSection.remove)) {
            viewItem.propSections[psKey] = replaceFull ? '_deleteMe' : deleteField()
          }
        })
      }
      if (props) {
        _.each(props, (prop, pKey) => {
          if (_.isObject(prop) && (prop._deleteMe || prop.remove)) {
            viewItem.props[pKey] = replaceFull ? '_deleteMe' : deleteField()
          }
        })
      }
    })
  }
}

/**
 * Updates the event status in the database 
 * @param {object} dataUpdate 
 * @param {object} pathViews 
 */
const updateEventStatus = (dataUpdate, pathViews) => {
  const { global } = dataUpdate ?? {}
  const { appStatus } = global ?? {}
  if (appStatus) {
    const _refPath = createRefPath_event(pathViews)
    const data = global.appStatus
    fs_update_doc(_refPath, data)
  }
}

/**
 * Updates (using Set) to the `settings` documents (main, settings/global, settings/views, settings/viewItems) in the database
 * @param {*} baseRef 
 * @param {*} dataUpdate 
 * @param {*} isGlobal  
 * @param {*} trueViewKey 
 * @returns 
 */
const settingsUpdate = async (baseRef, dataUpdate, isGlobal, trueViewKey, replaceFull) => {

  const merge = true
  let _deletePaths;

  const promises = []

  const { status, global, viewItems, views } = dataUpdate

  promises.push(fs_set_doc(baseRef, status, merge, false, true))

  if (isGlobal) {
    const rp_g = createRefPath([_settingsFs.collection, _settingsGlobalDocument], baseRef)
    promises.push(fs_set_doc(rp_g, global, merge, false, true))

    const rp_vi = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)

    const { deletePaths } = constructUpdatePaths(viewItems)
    _deletePaths = deletePaths
    promises.push(fs_set_doc(rp_vi, viewItems, merge, false, true))
    promises.push(fs_update_doc(rp_vi, _deletePaths, null, true))

  } else {
    const rp_v = createRefPath([_settingsFs.collection, 'views'], baseRef)
    const _data = { [trueViewKey]: views[trueViewKey] }
    promises.push(fs_set_doc(rp_v, _data, merge, false, true))

  }

  return Promise.all(promises)
}

function constructUpdatePaths(obj) {
  const updatePaths = {};
  const deletePaths = {};

  function traverse(currentObj, currentPath = '') {
    _.forOwn(currentObj, (value, key) => {
      const newPath = currentPath ? `${currentPath}.${key}` : key;
      if (value === '_deleteMe') {
        deletePaths[newPath] = deleteField();
      } else if (_.isObject(value) && !_.isArray(value)) {
        traverse(value, newPath);
      } else {
        updatePaths[newPath] = value;
      }
    });
  }

  traverse(obj);
  return { updatePaths, deletePaths };
}

const settingsUpdate_fromSidebar = async (settingsDocName, trueViewKey, data, appUser, callback) => {

  const updateSb = () => {
    const merge = true
    const { displayName, uid } = appUser ?? {}

    const status = {
      uid,
      displayName,
      appVersion: _appVersion,
      lastUpdate: getNow()
    }

    const baseRef = createRefPath([_settingsFs.root, settingsDocName])

    const promises = []
    if (_allowUpdates_fromSidebar) {
      promises.push(fs_set_doc(baseRef, status, merge, false, true))
    } else {
      console.log('settingsUpdate_fromSidebar', baseRef, status)
    }

    const rp_v = createRefPath([_settingsFs.collection, 'views'], baseRef)
    const _data = { [trueViewKey]: data }
    console.log('rp_v', rp_v)
    console.log('_data', _data)

    if (_allowUpdates_fromSidebar) {
      promises.push(fs_set_doc(rp_v, _data, merge, false, true))
    } else {
      console.log('settingsUpdate_fromSidebar', rp_v, _data)
    }
    return Promise.all(promises)
  }

  updateSb().then(res => {
    callback && callback()
  })

}

const settingsUpdate_globalFromSidebar = async (settingsDocName, data, appUser, callback) => {

  const updateSb = () => {
    const merge = true
    const { displayName, uid } = appUser ?? {}

    const status = {
      uid,
      displayName,
      appVersion: _appVersion,
      lastUpdate: getNow()
    }

    const baseRef = createRefPath([_settingsFs.root, settingsDocName])

    const promises = []
    promises.push(fs_set_doc(baseRef, status, merge, false, true))

    const rp_v = createRefPath([_settingsFs.collection, 'global'], baseRef)
    promises.push(fs_set_doc(rp_v, data, merge, false, true))
    return Promise.all(promises)
  }

  updateSb().then(res => {
    callback()
  })

}

/**
 * This will update the global, viewItems and views documents to the database
 * @param {object} allAppSettings 
 * @param {function} response 
 */
const settingsDocsPromise = async (allAppSettings) => {
  const promises = []
  // loop the allAppSettings
  Object.keys(allAppSettings).forEach(settingsKey => {
    const baseRef = createRefPath([_settingsFs.root, settingsKey])
    const appSettings = allAppSettings[settingsKey]
    const { global, viewItems, views } = appSettings
    if (global && views && viewItems) {
      // loop the docs within appSettings
      Object.keys(appSettings).forEach(docKey => {
        const _settingsRef = createRefPath([_settingsFs.collection, docKey], baseRef)
        const appSetting = dataFix.removeAllEmpties(appSettings[docKey])
        promises.push(doc_set(_settingsRef, appSetting))
      })
    }
  })
  return Promise.all(promises)
}

const m = (o, doc, path) => {
  o[doc.id] = {
    ...doc.data(),
    id: doc.id
  }
}

const getTrueView = (pathViews, _view, trueView, landingView, _viewKey) => {
  if (trueView === landingView) {
    if (pathViews.events) {
      trueView = 'events'
    } else if (pathViews.clients) {
      trueView = 'clients'
    }
  }
  return trueView
} 