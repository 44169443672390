import _ from 'lodash';
import React, { useContext } from 'react';
import { Icon, Label, Segment } from 'semantic-ui-react';
import { SwiperContext } from '../../../../global/cnr/contexts/SwiperContext';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import PendingWait from '../../../../global/components/alerts/pendings/PendingWait';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { _matchLsTypes } from '../../cnr/reducers/SportsDataReducer';
import MatchScheduleHeader from './MatchScheduleHeader';
import MatchWrapper, { sportsMatchViewTypes } from './MatchWrapper';
import MatchCount from './MatchCount';

const _colors = {
  section: 'black',
  matchCount: 'blue',
  matchZeroCount: 'black',
  matchCompleted: 'blue',
  matchEqual: 'green',
}

const MatchLevelDates = (props) => {

  const { showHeader } = props ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext);
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { matches_info } = sportsSeason_state ?? {}
  const { levelUpcomingMatches } = matches_info ?? {}

  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_fns } = seasonMatchesContext ?? {}

  // swiperContext
  const swiperContext = useContext(SwiperContext)
  const { swiper_handlers } = swiperContext ?? {}

  let _today = new Date();
  const _dateCaption = formatItem(formatTypes.fullDate, _today)

  const clns = {
    ldm: 'level-date-matches schedule-section-teams',
    ldmh: 'level-date-matches-header',
    ldmmt: 'level-date-matches-mt'
  }

  const handleLevelClick = () => swiper_handlers && swiper_handlers.handleSet_tabIndexByKey('matches')

  const lbl = (index, value, color, circular, size) => <Label key={uniqueKey('mld', 'lm', 'lbl', index)} size={size} color={color} circular={circular}>{value}</Label>

  // const labelLabel = (matchCount, completedMatchesCount, endCaption) => {

  //   const color = 'black'
  //   const color_count = matchCount > 0 ? _colors.matchCount : _colors.matchZeroCount
  //   let color_remaining = completedMatchesCount > 0 ? _colors.matchCompleted : _colors.matchZeroCount
  //   if (matchCount > 0 && (matchCount === completedMatchesCount)) {
  //     color_count = _colors.matchEqual
  //     color_remaining = _colors.matchEqual
  //   }
  //   const cnl = matchCount > 0 ? 'gt0' : 'g0'
  //   const cnr = completedMatchesCount > 0 ? 'gt0' : 'g0'

  //   return <Label color={color} style={{ padding: '.0em' }} size='small' >
  //     <Label size='tiny' color={color_count} style={{ marginRight: '1em' }} className={clns.ldmmt + ' ' + cnl}>
  //       {matchCount}
  //     </Label>
  //     {endCaption}
  //     <Label size='tiny' color={color_remaining} style={{ marginLeft: '1em' }} className={clns.ldmmt + ' ' + cnr}>
  //       {completedMatchesCount}
  //     </Label>
  //   </Label>
  // }

  const levelMatches = (lkey, lucm) => {

    if (lucm) {

      const levelCaption = _.startCase(lkey)

      const { subCaption, matches, hasMatches, matchCount } = lucm
      const endCaption = matchCount === 1 ? 'match today' : 'matches today'


      if (hasMatches) {

        const sortedMatchesX = _.orderBy(matches, ['_results.complete', 'home.name'], ['desc', 'asc']);
        const completedMatchesCount = _.countBy(matches, _.property('_results.complete')).true || 0;

        const matchCounts = { matchCount, endCaption, completedMatchesCount }

        // @ts-ignore
        const lms = sortedMatchesX.map(m => {

          const { isPlayoff, _status } = m
          const { isBye } = _status ?? {}

          let wcnn = seasonMatches_fns.getMatchCn(m)

          if (!isBye) {
            if (isPlayoff) {
              return <MatchWrapper key={uniqueKey('mld', 'mw', lkey, m._itemKey)} match={m} sportsMatchViewType={sportsMatchViewTypes.singlePool} wcn={wcnn} fromLevelDates={true} />
            } else {
              return <MatchWrapper key={uniqueKey('mld', 'mw', lkey, m._itemKey)} match={m} sportsMatchViewType={sportsMatchViewTypes.single} wcn={wcnn} fromLevelDates={true} />
            }
          }
        })

        return <div key={uniqueKey('mld', 'lm', lkey)} className={clns.ldm}>
          <div key={uniqueKey('mld', 'lm', 'lc', lkey)} className={clns.ldmh} onClick={() => { handleLevelClick(lkey) }}>
            <div>{lbl(1, levelCaption, _colors.section)}</div>
            <div><MatchCount matchCounts={matchCounts} /> </div>
            {/* <div>{labelLabel(matchCount, completedMatchesCount, endCaption)} </div> */}
            <div><Icon name={'arrow right'}></Icon></div>
          </div>
          <div className='schedule-section-teams mld'>
            {lms}
          </div>
        </div >
      } else {
        const matchCounts = { matchCount, endCaption, completedMatchesCount: 0, clns }
        return <div key={uniqueKey('mld', 'lm', lkey)} className={clns.ldm}>
          <div key={uniqueKey('mld', 'lm', 'lc', lkey)} className={clns.ldmh} onClick={() => { handleLevelClick(lkey) }}>
            <div>{lbl(1, levelCaption, _colors.section)}</div>
            <div><MatchCount matchCounts={matchCounts} /> </div>
            {/* <div>{labelLabel(matchCount, 0, endCaption)} </div> */}
            <div><Icon name={'arrow right'}></Icon></div>
          </div>
          <Segment basic>
            <div>{subCaption}</div>
          </Segment>
        </div>
      }
    }
  }

  const matchGrids = () => {

    let dateDiv;
    const sectionDivs = []
    const nonSectionDivs = []

    if (levelUpcomingMatches) {
      Object.keys(levelUpcomingMatches).map(lkey => {
        const lucm = levelUpcomingMatches[lkey]
        dateDiv = <div>{_dateCaption}</div>
        switch (lkey) {
          case _matchLsTypes.non:
          case _matchLsTypes.nonDistrict:
          case _matchLsTypes.tournament:
            nonSectionDivs.unshift(levelMatches(lkey, lucm))
            break;
          default:
            sectionDivs.unshift(levelMatches(lkey, lucm))
        }
      })
    }

    return showHeader ?
      <div>
        <MatchScheduleHeader todayOnly={true} />
        <div className={'levels-date-matches'}>
          {dateDiv}
          {sectionDivs}
          {nonSectionDivs}
          {/* <SectionsLegend /> */}
        </div>
      </div>
      :
      <div className={'levels-date-matches'}>
        {dateDiv}
        {sectionDivs}
        {nonSectionDivs}
        {/* <SectionsLegend /> */}
      </div>
  }

  return levelUpcomingMatches ? matchGrids() : <PendingWait />

}

export default MatchLevelDates
